import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTypography from "../../../../../../../../../components/CustomTypography/CustomTypography";
import mapPreview from "../../../../../../../../../utils/icons/mapImage.png";
import { useStyles } from "./styles";
import TypeChip from "../../../../../../../../../components/TypeChip/TypeChip";
import { DATA_TYPES } from "../../../../../../../../../utils/constants/dataType";
import fileCopyIcon from "../../../../../../../../../utils/icons/copy-icon.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import clsx from "clsx";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { deleteLibraryBasemap } from "../../../../../../../../../actions/basemaps";
import { addAppBasemapThunk } from "../../../../../../../../../actions/apps";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/styles";
import { getApp } from "../../../../../../../../../selectors/appData";
import { addAppBasemap } from "../../../../../../../../../reducers/appData/appData";
import { handleError } from "../../../../../../../../../utils/networkErrorUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { handleDeleteLibraryBasemap } from "../../../../../../../../../reducers/basemaps";
import toastr from "../../../../../../../../../components/CustomToastr/CustomToastr";
import EditBasemapModal from "../EditBasemapModal/EditBasemapModal";
import OverflowTip from "../../../../../../../../../components/OverflowTip/OverflowTip";
import { unwrapResult } from "@reduxjs/toolkit";
import BasemapThumbnail from "../../../../../../../../../components/BasemapThumbnail/BasemapThumbnail";

const BasemapCard = ({ basemap, basemapAdded }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const currentApp = useSelector(getApp);

    const dispatch = useDispatch();

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onCloseEditModal = () => {
        setEditModalOpen(false);
    };

    const onOpenEditModal = () => {
        onMenuClose();
        setEditModalOpen(true);
    };

    const onAddBasemap = () => {
        const basemapId = basemap.id;
        const appId = currentApp.id;

        dispatch(addAppBasemapThunk({ appId, basemapId }))
            .then(unwrapResult)
            .then(() => {
                dispatch(addAppBasemap(basemap));
            })
            .catch((err) => handleError(err));
    };

    const onDeleteLibraryBasemap = (event) => {
        onMenuClose();
        const toastrConfirmOptions = {
            onOk: () =>
                dispatch(deleteLibraryBasemap(basemap.id))
                    .then((res) => {
                        dispatch(handleDeleteLibraryBasemap(basemap.id));
                    })
                    .catch((err) => {
                        handleError(err);
                    }),
            onCancel: () => {}
        };
        toastr.confirm(
            `This action will delete the basemap from all the applications in which it is used. Are you sure you want to delete basemap: ${basemap.title}?`,
            toastrConfirmOptions
        );
    };

    return (
        <div className={classes.basemapCard}>
            <BasemapThumbnail className={clsx(classes.image, classes.marginRight)} basemapUrl={basemap.url} basemapType={basemap.type} />

            <div className={classes.basemapDetailes}>
                <div className={clsx(classes.topSection)}>
                    <div className={classes.relative}>
                        <OverflowTip variant="body2" textWeight="semibold" className={classes.basemapTitle}>
                            {basemap.title}
                        </OverflowTip>
                        {basemap.isDefault && (
                            <CustomTypography variant="caption" className={classes.absolute} color={theme.customColors.mutedColor} textWeight="semibold">
                                Default
                            </CustomTypography>
                        )}
                    </div>

                    <div className={clsx(classes.marginRight, classes.marginLeftAuto)}>
                        <TypeChip type={basemap.type === DATA_TYPES.vector ? DATA_TYPES.vector : DATA_TYPES.raster} variant="large" />
                    </div>
                    <div className={clsx(classes.marginRight)}>
                        {basemapAdded ? (
                            <div className={classes.addedTag}>
                                <CheckCircleIcon color="success" className={classes.checkIcon} />
                                <CustomTypography variant="body2" color={theme.customColors.successColor} textWeight="bold" onClick={onAddBasemap}>
                                    Added
                                </CustomTypography>
                            </div>
                        ) : (
                            <Button variant="text" size="small" startIcon={<AddCircleIcon size="small" />} onClick={onAddBasemap} data-testid={addBasemapToAppTestId}>
                                Add
                            </Button>
                        )}
                    </div>
                    <Tooltip title={basemap.isDefault ? "These actions are not allowed for default basemaps" : ""}>
                        <div>
                            <IconButton
                                className={clsx(classes.menuButton, classes.marginRight)}
                                onClick={onMenuClick}
                                size="large"
                                disabled={basemap.isDefault}
                                data-testid={menuButtonTestId}
                            >
                                <MoreHoriz fontSize="small" />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
                <div className={classes.urlContainer}>
                    <CustomTypography variant="caption" className={classes.basemapUrl} color="primary" textWeight="semibold">
                        {basemap.url}
                    </CustomTypography>
                    <Tooltip title="Copy to clipboard">
                        <Button
                            variant="text"
                            size="small"
                            className={clsx(classes.copyButton, classes.marginRight)}
                            onClick={() => {
                                navigator.clipboard.writeText(basemap.url);
                                toastr.success("The text has been copied");
                            }}
                            data-testid={copyBasemapUrlTestId}
                        >
                            <img alt="" src={fileCopyIcon} />
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                <MenuItem onClick={onOpenEditModal} className="menu-button" data-testid={editBasemapTestId}>
                    <EditIcon className="icon" />
                    Edit basemap
                </MenuItem>
                {/*Functionality not implemented 

                <MenuItem className="menu-button">
                    <VisibilityIcon className="icon" />
                    Preview
                </MenuItem> */}
                <MenuItem onClick={onDeleteLibraryBasemap} className="menu-button" data-testid={deleteBasemapTestId}>
                    <DeleteIcon className="icon" />
                    Remove
                </MenuItem>
            </Menu>

            <EditBasemapModal open={editModalOpen} handleClose={onCloseEditModal} basemap={basemap} />
        </div>
    );
};
export default BasemapCard;

const addBasemapToAppTestId = "qa-app-edit-view-settings-add-basemap-tool-add-basemap-to-app";
const editBasemapTestId = "qa-app-edit-view-settings-add-basemap-tool-edit-basemap";
const deleteBasemapTestId = "qa-app-edit-view-settings-add-basemap-tool-delete-basemap";
const copyBasemapUrlTestId = "qa-app-edit-view-settings-add-basemap-tool-copy-basemap-url";
const menuButtonTestId = "qa-app-edit-view-settings-add-basemap-tool-basemap-menu-button";
