import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { Provider } from "react-redux";
import { store } from "./store/store";

import dayjs from "dayjs";
import { BrowserRouter as Router } from "react-router-dom";
import "./utils/dayjs/customLocale";

import StylingProviders from "./app/providers/StylingProviders/StylingProviders";

import { AuthenticationProvider } from "app/components/AuthenticationProvider/AuthenticationProvider";

const utc = require("dayjs/plugin/utc");
const relativeTime = require("dayjs/plugin/relativeTime");
const localizedFormat = require("dayjs/plugin/localizedFormat");
const isBetween = require("dayjs/plugin/isBetween");

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <StylingProviders>
                <AuthenticationProvider>
                    <App />
                </AuthenticationProvider>
            </StylingProviders>
        </Provider>
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.registerTileCache();
