import baseAtlasApi from "store/baseAtlasApi";
import { Group } from "./model/Group";
import { atlasApiTags as tags } from "store/atlasApiTags";
import { ResourceWithPermission } from "./model/ResourceWithPermission";
import { GisUser } from "features/users/model/GisUser";

export const groupsApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAllGroups: build.query<Group[], void>({
            query: () => ({ url: "group", method: "GET" }),
            providesTags: [tags.groupTags.GROUP_LIST]
        }),
        fetchGroupById: build.query<Group, string>({
            query: (groupId) => ({ url: `group/${groupId}`, method: "GET" }),
            providesTags: (_0, _1, groupId) => [{ type: tags.groupTags.GROUP, id: groupId }]
        }),
        fetchGroupUsers: build.query<GisUser[], string>({
            query: (groupId) => ({ url: `group/${groupId}/users`, method: "GET" }),
            providesTags: (_0, _1, groupId) => [{ type: tags.groupTags.USERS, id: groupId }]
        }),
        fetchGroupDatasetsPermissions: build.query<ResourceWithPermission[], string>({
            query: (groupId) => ({ url: `group/${groupId}/datasets`, method: "GET" }),
            providesTags: (_0, _1, groupId) => [{ type: tags.groupTags.DATASETS_PERMISSIONS, id: groupId }]
        }),
        fetchGroupRastersPermissions: build.query<ResourceWithPermission[], string>({
            query: (groupId) => ({ url: `group/${groupId}/rasters`, method: "GET" }),
            providesTags: (_0, _1, groupId) => [{ type: tags.groupTags.RASTERS_PERMISSIONS, id: groupId }]
        }),
        fetchGroupApplicationsPermissions: build.query<ResourceWithPermission[], string>({
            query: (groupId) => ({ url: `group/${groupId}/applications`, method: "GET" }),
            providesTags: (_0, _1, groupId) => [{ type: tags.groupTags.APPLICATIONS_PERMISSIONS, id: groupId }]
        }),
        createGroup: build.mutation<Group, string>({
            query: (name) => ({ url: "group", method: "POST", data: { name } }),
            invalidatesTags: [tags.groupTags.GROUP_LIST]
        }),
        updateGroup: build.mutation<Group, Pick<Group, "id" | "name">>({
            query: ({ id, name }) => ({
                url: `group/${id}`,
                method: "PUT",
                data: { name }
            }),
            invalidatesTags: (_0, _1, arg) => [{ type: tags.groupTags.GROUP_LIST }, { type: tags.groupTags.GROUP, id: arg.id }]
        }),
        deleteGroup: build.mutation<void, string>({
            query: (groupId) => ({
                url: `group/${groupId}`,
                method: "DELETE"
            }),
            invalidatesTags: [tags.groupTags.GROUP_LIST]
        }),
        addGroupUser: build.mutation<void, { groupId: string; userEmail: string }>({
            query: ({ groupId, userEmail }) => ({
                url: `group/${groupId}/user/${userEmail}`,
                method: "POST"
            }),
            invalidatesTags: (_0, _1, arg) => [{ type: tags.groupTags.USERS, id: arg.groupId }]
        }),
        bulkAddUsersToGroups: build.mutation<void, { groupIds: string[]; userEmails: string[] }>({
            query: ({ groupIds, userEmails }) => ({
                url: "group/bulk",
                method: "POST",
                data: { groupIds, userEmails }
            }),
            invalidatesTags: (_0, _1, arg) => arg.groupIds.map((groupId) => ({ type: tags.groupTags.USERS, id: groupId }))
        }),
        removeGroupUser: build.mutation<void, { groupId: string; userEmail: string }>({
            query: ({ groupId, userEmail }) => ({
                url: `group/${groupId}/user/${userEmail}`,
                method: "DELETE"
            }),
            invalidatesTags: (_0, _1, arg) => [{ type: tags.groupTags.USERS, id: arg.groupId }]
        })
    })
});

export const {
    useFetchAllGroupsQuery,
    useFetchGroupByIdQuery,
    useFetchGroupUsersQuery,
    useFetchGroupDatasetsPermissionsQuery,
    useFetchGroupRastersPermissionsQuery,
    useFetchGroupApplicationsPermissionsQuery,
    useCreateGroupMutation,
    useDeleteGroupMutation,
    useUpdateGroupMutation,
    useAddGroupUserMutation,
    useRemoveGroupUserMutation,
    useBulkAddUsersToGroupsMutation
} = groupsApi;

export const invalidateAppsDatasetsRastersCache = () =>
    groupsApi.util.invalidateTags([tags.groupTags.APPLICATIONS_PERMISSIONS, tags.groupTags.DATASETS_PERMISSIONS, tags.groupTags.RASTERS_PERMISSIONS]);
