import { makeStyles } from "@mui/styles";

export const useBasemapRowStyles = makeStyles((theme: any) => ({
    icon: {
        marginLeft: 8
    },
    isDefaultIcon: {
        background: `radial-gradient(${theme.customColors.successColor} 50%,transparent 0,transparent)`,
        color: theme.customColors.successColorLight
    },
    isNotDefaultIcon: {
        background: `radial-gradient(${theme.customColors.errorColor} 50%,transparent 0,transparent)`,
        color: theme.customColors.errorColorLight
    },
    thumbnail: {
        height: "100%"
    }
}));
