import { useState, useEffect } from "react";

export const useCheckVerticalOverflow = (ref, callback) => {
    const [isOverflow, setIsOverflow] = useState();

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const element = entries[0];
            if (element.target.scrollHeight && element.target.clientHeight) setIsOverflow(element.target.scrollHeight > element.target.clientHeight);
        });
        const oldRef = ref.current;
        observer.observe(oldRef);
        return () => oldRef && observer.unobserve(oldRef);
    }, [callback, ref]);

    return isOverflow;
};
