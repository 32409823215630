import { Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/MoreHoriz";
import { useMenuButtonCellStyles } from "./styles";
import StickyCell from "../StickyCell/StickyCell";

const MenuButtonCell = ({ onMenuClick }) => {
    const classes = useMenuButtonCellStyles();
    return (
        <StickyCell className={classes.stickyContainer}>
            <div className={classes.iconContainer} onClick={onMenuClick}>
                <Tooltip id="tooltip-top" title="Options" placement="bottom">
                    <div className={classes.buttonContainer}>
                        <MenuIcon className={classes.menuIcon} data-testid={menuButtonTestId} />
                    </div>
                </Tooltip>
            </div>
        </StickyCell>
    );
};

export default MenuButtonCell;

// I've set the test id here because there shouldn't be more than one column containing the MenuButtonCell
const menuButtonTestId = "qa-custom-table-menu-button";
