import { FC, useEffect, useState } from "react";
import { Button } from "@mui/material";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import UpdateIcon from "@mui/icons-material/Save";
import { setAppThumbnail } from "actions/apps";
import { useParams } from "react-router-dom";
import toastr from "components/CustomToastr/CustomToastr";
import { handleError } from "utils/networkErrorUtils";
import { CircularProgress } from "@mui/material";
import PreviewableImage from "components/PreviewableImage/PreviewableImage";
import config from "config";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getClientId, getProjectId } from "features/core/selectors";
import { useStyles } from "./styles";
import { v4 as uuidv4 } from "uuid";
import useAuthentication from "app/hooks/useAuthentication";

type Props = {
    mapRef: any;
    disabled: boolean;
};

type Params = {
    appId: string;
    clientId: string;
    projectId: string;
};

const desiredAspectRatio = 16 / 9;

const ThumbnailSection: FC<Props> = ({ mapRef, disabled }) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [imageTag, setImageTag] = useState(uuidv4()); // this is used to refresh the image when the thumbnail is updated
    const [token, setToken] = useState("");

    const clientId = useAppSelector(getClientId);
    const projectId = useAppSelector(getProjectId);

    const { getAccessToken } = useAuthentication();

    const { appId } = useParams<Params>();

    useEffect(() => {
        getAccessToken().then((token) => setToken(token));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createImage = () => {
        setLoading(true);

        // This is done on a map rerender so that we do not have to use preserveDrawingBuffer: true which is a performance drawback
        mapRef.current.once("render", () => {
            const mapCanvas = mapRef.current.getCanvas(document.querySelector(".mapboxgl-canvas"));

            const dimensions = getBoundingBoxDimensions(mapCanvas, desiredAspectRatio);

            const finalCanvas = document.createElement("canvas");
            finalCanvas.height = dimensions.height;
            finalCanvas.width = dimensions.width;

            const canvasContext = finalCanvas.getContext("2d");

            canvasContext?.drawImage(mapCanvas, dimensions.x, dimensions.y);

            finalCanvas.toBlob((blob) => {
                !!blob && uploadImage(blob);
            }, "image/webp");
        });

        mapRef.current.triggerRepaint(); // needed to trigger a rerender
    };

    const getBoundingBoxDimensions = (mapCanvas: any, aspectRatio: number) => {
        const inputWidth = mapCanvas.width;
        const inputHeight = mapCanvas.height;
        const inputAspectRatio = inputWidth / inputHeight;

        let desiredWidth = inputWidth;
        let desiredHeight = inputHeight;

        if (inputAspectRatio > aspectRatio) {
            desiredWidth = inputHeight * aspectRatio;
        } else if (inputAspectRatio < aspectRatio) {
            desiredHeight = inputWidth / aspectRatio;
        }

        const x = (desiredWidth - inputWidth) * 0.5;
        const y = (desiredHeight - inputHeight) * 0.5;

        return {
            width: desiredWidth,
            height: desiredHeight,
            x,
            y
        };
    };
    const uploadImage = (blob: Blob) => {
        const formData = new FormData();
        formData.append("file", blob, `${appId}.webp`);
        setAppThumbnail(appId, formData)
            .then(() => {
                toastr.success("Thumbnail updated");
                setImageTag(uuidv4());
            })
            .catch((err) => handleError(err))
            .finally(() => setLoading(false));
    };

    const getImageUrl = () => {
        return `${config.apiUrl}api/application/${appId}/thumbnail?key=${token}&ClientId=${clientId}&ProjectId=${projectId}&tag=${imageTag}`;
    };

    return (
        <>
            <InfoTextSection paddingBottom={2} iconSize="extra-small" textVariant="body2" textWeight="semibold">
                The thumbnail of an application acts as a preview image showcasing what that application contains. Style and fit the map until you're satisfied with how it looks,
                then click the button to set the thumbnail.
            </InfoTextSection>

            {token && <PreviewableImage key={Date.now()} className={classes.thumbnailImage} imageUrl={getImageUrl()} />}

            <Button disabled={loading || disabled} className="update-button" variant="text" color="primary" onClick={createImage} data-testid={updateThumbnailButtonTestId}>
                {loading ? <CircularProgress className="left-icon" size={20} /> : <UpdateIcon className="left-icon" fontSize="small" />}
                Set Thumbnail
            </Button>
        </>
    );
};

export default ThumbnailSection;

const updateThumbnailButtonTestId = "qa-app-edit-view-settings-update-thumbnail-button";
