import { makeStyles } from "@mui/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";

export const useStyles = makeStyles((theme) => ({
    uploadContainer: {
        border: "1px solid" + theme.customColors.borderColor,
        borderRadius: 3,
        height: 160,
        width: 400,
        marginTop: 20
    },
    pointerEventNone: {
        pointerEvents: "none"
    },
    flexBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    dragging: {
        background: theme.customColors.secondaryColor
    },
    buttonSpacing: {
        marginTop: 8
    },
    progressBar: {
        height: 10,
        width: 350,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.customColors.borderColor
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5
        }
    },
    notVisible: {
        display: "none"
    }
}));
