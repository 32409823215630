import { FC } from "react";
import { getApp } from "selectors/appData";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import DigitizeIcon from "@mui/icons-material/Timeline";
import PrintIcon from "@mui/icons-material/Print";
import Measure from "@mui/icons-material/SquareFoot";
import { CustomIcons } from "utils/customIcons";
import { setAppDetails } from "reducers/appData/appData";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { ComponentProps } from "../AppSettingsView";

const icons = {
    search: <SearchIcon fontSize="small" />,
    print: <PrintIcon fontSize="small" />,
    measure: <Measure fontSize="small" />,
    digitize: <DigitizeIcon fontSize="small" />,
    legend: CustomIcons.legend
};

const ViewerSettingsSection: FC<ComponentProps> = ({ disabled }) => {
    const app = useAppSelector(getApp);
    const dispatch = useAppDispatch();

    const onToolToggled = (tool: any) => {
        if (disabled) return;

        const newTool = { ...tool, enabled: !tool.enabled };

        const newTools = app.tools.map((x: any) => {
            if (x.name === newTool.name) {
                return newTool;
            }
            return x;
        });

        dispatch(setAppDetails({ app: { ...app, tools: newTools } }));
    };

    function onWidgetsToggled(widget: any) {
        if (disabled) return;

        const newWidget = { ...widget, enabled: !widget.enabled };

        const newWidgets = app.widgets.map((x: any) => {
            if (x.name === newWidget.name) {
                return newWidget;
            }
            return x;
        });

        dispatch(setAppDetails({ app: { ...app, widgets: newWidgets } }));
    }

    const onSidebarCollapsedChanged = (e: any) => {
        const target = e.target as HTMLInputElement;

        if (target.value === undefined) return;

        dispatch(setAppDetails({ app: { ...app, sidebarCollapsed: target.value === "true" } }));
    };

    const getToolsAndWidgetsDataTestId = (title: string) => {
        return toolsAndWidgetsTestId + title.toLocaleLowerCase().replaceAll(" ", "-");
    };

    const getClassName = (enabled: boolean) => {
        let toolClassName = "tool";
        if (enabled) {
            toolClassName += " enabled";
        }

        if (disabled) {
            toolClassName += " component-disabled";
        }
        return toolClassName;
    };
    return (
        <>
            <div className="section-content">
                <div className="subtitle">Tools and Widgets</div>
                <div className="enabled-tools">
                    {app.tools.map((tool: any) => {
                        return (
                            <div className={getClassName(tool.enabled)} onClick={() => onToolToggled(tool)} key={tool.name} data-testid={getToolsAndWidgetsDataTestId(tool.name)}>
                                {tool.enabled && <CheckIcon className="left-icon" fontSize="small" />}
                                {tool.name}
                                <div className="icon">{icons[tool.name]}</div>
                            </div>
                        );
                    })}
                    {app.widgets.map((widget: any) => {
                        return (
                            <div
                                className={getClassName(widget.enabled)}
                                onClick={() => onWidgetsToggled(widget)}
                                key={widget.name}
                                data-testid={getToolsAndWidgetsDataTestId(widget.name)}
                            >
                                {widget.enabled && <CheckIcon className="left-icon" fontSize="small" />}
                                {widget.name}
                                <div className="icon">{icons[widget.name]}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="subtitle">Sidebar Layout</div>
                <FormControl>
                    <RadioGroup row onClick={onSidebarCollapsedChanged}>
                        <FormControlLabel
                            value={true}
                            control={
                                <Radio disabled={disabled} size="small" checked={app.sidebarCollapsed} inputProps={{ "data-testid": sidebarCollapsedTestId } as DataTestIdProps} />
                            }
                            label={
                                <CustomTypography variant="body2" textWeight="semibold">
                                    Sidebar Collapsed
                                </CustomTypography>
                            }
                        />
                        <FormControlLabel
                            value={false}
                            control={
                                <Radio disabled={disabled} size="small" checked={!app.sidebarCollapsed} inputProps={{ "data-testid": sidebarExpandedTestId } as DataTestIdProps} />
                            }
                            label={
                                <CustomTypography variant="body2" textWeight="semibold">
                                    Sidebar Expanded
                                </CustomTypography>
                            }
                        />
                    </RadioGroup>
                </FormControl>
            </div>
        </>
    );
};

export default ViewerSettingsSection;

const toolsAndWidgetsTestId = "qa-app-edit-view-settings-tools-and-widgets-";
const sidebarCollapsedTestId = "qa-app-edit-view-settings-sidebar-collapsed";
const sidebarExpandedTestId = "qa-app-edit-view-settings-sidebar-expanded";

type DataTestIdProps = React.InputHTMLAttributes<HTMLInputElement> & {
    "data-testid"?: string;
};
