import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import CollapsibleCard from "components/CollapsibleCard/CollapsibleCard";
import { useEffect, useState } from "react";
import AppsSimpleTableView from "features/app/components/AppsSimpleTableView/AppsSimpleTableView";
import { useParams } from "react-router-dom";
import { getDatasetAssociatedApps } from "actions/datasets";
import { handleError } from "utils/networkErrorUtils";
import { DETAILS_TAB } from "../../datasetEditView";

type Params = {
    datasetId: string;
};

const AppsSection = () => {
    const [isAppsOpen, setIsAppsOpen] = useState(false);

    const toggleAppsSection = () => {
        setIsAppsOpen(!isAppsOpen);
    };

    const [apps, setApps] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const { datasetId } = useParams<Params>();

    useEffect(() => {
        getDatasetAssociatedApps(datasetId)
            .then((res) => setApps(res))
            .catch((err) => handleError(err))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="shadow-section scroll">
                <CollapsibleCard paddingInline={2} onToggleClick={toggleAppsSection} title="Applications" isToggled={isAppsOpen} unmountOnExit>
                    <InfoTextSection iconSize="extra-small" textVariant="body2" textWeight="semibold" sx={{ marginBottom: 2 }}>
                        The dataset is included in the applications below.
                    </InfoTextSection>

                    <AppsSimpleTableView apps={apps} loading={loading} tab={DETAILS_TAB} />
                </CollapsibleCard>
            </div>
        </>
    );
};

export default AppsSection;
