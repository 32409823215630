import { CACHE_STATES } from "../constants/cacheStates";

type cacheStateColorDict = {
    [state: string]: "success" | "warning" | "error";
};
export const cacheStateColor: cacheStateColorDict = {
    [CACHE_STATES.cached]: "success",
    [CACHE_STATES.caching]: "warning",
    [CACHE_STATES.uncached]: "error"
};
