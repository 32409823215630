import { makeStyles } from "@mui/styles";

const useUploadProgressStyles = makeStyles((theme: any) => ({
    warningProgress: {
        backgroundColor: theme.palette.warning.main
    },
    errorProgress: {
        backgroundColor: theme.palette.error.main
    },
    flexGrow: {
        flexGrow: 1
    },
    progress: {
        display: "flex",
        position: "relative",
        backgroundColor: theme.customColors.fieldBackgroundColor
    },
    progressBar: {
        height: 32,
        borderRadius: 3,

        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: theme.customColors.successColorLight
        }
    },

    error: {
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: theme.customColors.errorColorLight
        }
    },

    upload: {
        borderBottom: "1px solid" + theme.customColors.borderColor,
        padding: "20px 0",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "&:last-of-type": {
            border: "none"
        }
    },
    section: {
        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            bottom: -20
        }
    },
    uploadButton: {
        marginLeft: "auto",
        background: theme.customColors.secondaryColor,
        borderRadius: 3
    },
    buttonExtraSpacing: {
        marginRight: 20
    },
    status: {
        textTransform: "capitalize",
        color: theme.customColors.successColor,
        position: "absolute",
        left: theme.spacing(1),
        height: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "center"
    },

    errorStatus: {
        color: theme.customColors.errorColor
    }
}));

export default useUploadProgressStyles;
