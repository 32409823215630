import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    buttonSpacing: {
        marginRight: 20
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
        width: "100%",
        padding: "20px",
        marginTop: "auto",
        alignItems: "center",
        boxShadow: "0 2px 14px 0 rgba(0, 0, 0, 0.25)",
        height: theme.customSpacing.datasetsBottomSectionHeight,
        overflow: "initial"
    }
}));
