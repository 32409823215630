import useUploadContainerStyles from "./styles";
import clsx from "clsx";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomTypography from "../CustomTypography/CustomTypography";
import { Button } from "@mui/material";
import selectFromComp from "../../utils/icons/select_from_comp.svg";
import { useState } from "react";
import { useTheme } from "@mui/styles";

type Props = {
    acceptedFormats: string[];
    fileHandler: (files: FileList) => void;
}

const UploadContainer = ({acceptedFormats, fileHandler}: Props) => {
    const [dragging, setDragging] = useState(false);
    const classes = useUploadContainerStyles();
    const theme : any= useTheme();
    
    const onDragLeave = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
    }
    
    const onDragOver = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        setDragging(true);
    }
    
    const onDrop = (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        fileHandler(e.dataTransfer.files);
        setDragging(false);
    }
    
    const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.target.files && fileHandler(e.target.files);
    }
    
    return (
        <div
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={clsx(classes.uploadContainer, classes.flexBox, { [classes.dragging]: dragging })}
        >
            <div className={clsx(classes.flexBox, { [classes.pointerEventNone]: dragging })}>
                <CloudUploadIcon fontSize="large" color="primary" />

                <CustomTypography variant="subtitle1" textWeight="bold">
                    {!dragging ? "Drag and drop file here" : "Drop file here"}
                </CustomTypography>
                {!dragging && (
                    <CustomTypography variant="subtitle1" textWeight="semibold" color={theme.customColors.appTextColorLight}>
                        - or -
                    </CustomTypography>
                )}
                {!dragging && (
                    <Button
                        className={classes.buttonSpacing}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            document?.getElementById("file-button")?.click();
                        }}
                        startIcon={<img alt="" src={selectFromComp} />}
                        data-testid={selectFileButtonTestId}
                    >
                        Select from computer
                    </Button>
                )}
                <input
                    accept={acceptedFormats.reduce((acc, current) => `${acc},${current}`, "")}
                    className={classes.notVisible}
                    multiple={true}
                    onChange={onFileChanged}
                    id="file-button"
                    type="file"
                    data-testid={uploadFileInputTestId}
                />
            </div>
        </div>
    );
}

export default UploadContainer;

const uploadFileInputTestId = "qa-upload-container-upload-file-input";
const selectFileButtonTestId = "qa-upload-container-select-file-button";
