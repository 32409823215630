import { Button, Tooltip } from "@mui/material";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import fileCopyIcon from "../../../../../utils/icons/copy-icon.svg";
import CollapsibleCard from "components/CollapsibleCard/CollapsibleCard";
import dayjs from "dayjs";
import { fromNowWithCheck } from "../../../../../utils/timeUtils";
import { permisionPrettyNames } from "utils/constants/permissionTypes";
import { useState } from "react";
import { Dataset } from "models/Dataset";
import { useDatasetEditViewStyles } from "../../styles";
import clsx from "clsx";

const InfoSection = ({ dataset }: { dataset: Dataset }) => {
    const classes = useDatasetEditViewStyles();
    const [isInfoOpen, setIsInfoOpen] = useState(true);

    const toggleInfoSection = () => {
        setIsInfoOpen(!isInfoOpen);
    };
    return (
        <div className="shadow-section scroll">
            <CollapsibleCard innerPadding={0} paddingInline={2} onToggleClick={toggleInfoSection} title="Info" isToggled={isInfoOpen} unmountOnExit>
                <div className={clsx(classes.field, classes.flexField, classes.lightGrayBorder)}>
                    <ReadOnlyField
                        label={"Creation Date"}
                        text={fromNowWithCheck(dayjs(dataset.createdUtc))}
                        tooltipTitle={dayjs(dataset.createdUtc).format("LLL")}
                        tooltip={true}
                    />
                    <ReadOnlyField label={"Modified"} text={fromNowWithCheck(dayjs(dataset.modifiedUtc))} tooltipTitle={dayjs(dataset.modifiedUtc).format("LLL")} tooltip={true} />
                </div>
                <div className={clsx(classes.field, classes.flexField, classes.lightGrayBorder)}>
                    <ReadOnlyField label="Schema Name" text={dataset.schemaName} />
                    <ReadOnlyField label="Table Name" text={dataset.tableName} />
                </div>
                <div className={clsx(classes.field, classes.copyable, classes.lightGrayBorder)}>
                    <ReadOnlyField label="ID" text={dataset.id} />
                    <Tooltip title="Copy to clipboard">
                        <Button
                            className={classes.copyButton}
                            onClick={() => {
                                navigator.clipboard.writeText(dataset.id);
                                toastr.success("The text has been copied");
                            }}
                            data-testid={copyDatasetIdButtonTestId}
                        >
                            <img alt="" src={fileCopyIcon} />
                        </Button>
                    </Tooltip>
                </div>
                <div className={clsx(classes.field, classes.flexField, classes.lightGrayBorder)}>
                    <ReadOnlyField label="Rows" text={dataset.rowCount} />
                    <ReadOnlyField label="Size" text={dataset.databaseSize} />
                </div>
                <div className={clsx(classes.field, classes.flexField, classes.lightGrayBorder)}>
                    <ReadOnlyField label="CRS" text={dataset.projection} />
                    <ReadOnlyField label="Permission" text={permisionPrettyNames[dataset.permissionType]} />
                </div>
            </CollapsibleCard>
        </div>
    );
};

export default InfoSection;

const copyDatasetIdButtonTestId = "qa-dataset-edit-view-info-tab-copy-dataset-id-button";
