import { useParams } from "react-router";
import { Box } from "@mui/material";
import AppsSimpleTableView from "features/app/components/AppsSimpleTableView/AppsSimpleTableView";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import { useFetchAssociatedAppsQuery } from "features/rasters/api";
import { APPS_TAB } from "../RasterEditView";

type Params = {
    rasterId: string;
};

const AppsTab = () => {
    const { rasterId } = useParams<Params>();
    const { data: apps = [], isFetching } = useFetchAssociatedAppsQuery(rasterId);

    return (
        <>
            <InfoTextSection iconSize="extra-small" textVariant="body2" textWeight="semibold" sx={{ marginBottom: 2 }}>
                The raster is included in the applications below.
            </InfoTextSection>

            <Box mx={-2}>
                <AppsSimpleTableView apps={apps} loading={isFetching} tab={APPS_TAB} />
            </Box>
        </>
    );
};

export default AppsTab;
