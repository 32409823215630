import layerGroupSettings from "../constants/layerGroupSettings";

const isNewDepthValid = (hitDepth, droppedDepth, dropAsChild) => {
    let finalDepth = hitDepth + droppedDepth;
    if (!dropAsChild) {
        finalDepth -= 1;
    }
    return finalDepth < layerGroupSettings.MAX_DEPTH;
};

export default isNewDepthValid;
