import { createSelector } from "@reduxjs/toolkit";
import appLayersLimit from "../utils/constants/appLayersLimit";
import { mappingHOF, dictGetterHOF } from "./common";
import { RootState } from "store/models/RootState";

const getLayerVisibilityMap = (state: RootState) => state.appData.layerVisibilityMap;
export const getSelectedLayer = (state: RootState) => state.appData.selectedLayer;
export const getSelectedGroupId = (state: RootState) => state.appData.selectedGroupId;
export const getSelectedTool = (state: RootState) => state.appData.selectedTool;
export const getLayerStylesMap = (state: RootState) => state.appData.layerStylesMap;
export const getAppLayersCount = (state: RootState) => state.appData.appLayersCount;
export const getLayerStyleErrorsMap = (state: RootState) => state.appData.layerStyleErrorsMap;
export const getApp = (state: RootState) => state.appData.app;
export const getAppPermissionType = (state: RootState) => state.appData.app.permissionType;
export const getAppBasemaps = (state: RootState) => state.appData.app.basemaps;
export const getIncludedDatasets = (state: RootState) => state.appData.includedDatasets;
export const getIncludedRasters = (state: RootState) => state.appData.includedRasters;
export const getLayerGroups = (state: RootState) => state.appData.layerGroups;
export const getLastSaveDatasetCount = (state: RootState) => state.appData.lastSaveDatasetCount;
export const getHasUnsavedChanges = (state: RootState) => state.appData.hasUnsavedChanges;
export const getFetching = (state: RootState) => state.appData.fetching;

//these return mapping functions that take a key as arg and return the corresponding value from the dict
export const getLayerErrorStatus = dictGetterHOF(getLayerStyleErrorsMap);
export const getLayerVisibility = dictGetterHOF(getLayerVisibilityMap);
export const getStylesOfLayer = dictGetterHOF(getLayerStylesMap);

export const isLayerLimitReached = mappingHOF(getAppLayersCount, (layerCount: number) => {
    return layerCount >= appLayersLimit;
});

export const appHasStyleErrorsSelector = createSelector(getLayerStyleErrorsMap, (layerStyleErrorsMap) => {
    return Object.values(layerStyleErrorsMap).reduce((acc, hasError) => acc || hasError, false);
});

export const getDefaultBasemapSelector = createSelector([getAppBasemaps], (basemaps) => basemaps?.[0]);

export const getLayerMovedFlag = (state: RootState) => state.appData.layerMovedFlag;
