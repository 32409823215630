import { createContext, FCWC, useState } from "react";
import { FilterType } from "../types/FilterType";

type TableContextValue = {
    filters: FilterType<any>[];
    registerFilter: <TValue, TFilter extends FilterType<TValue>>(filter: TFilter) => void;
    activateFilter: <TValue>(name: string, selectedValues: TValue[]) => void;
    deactivateFilter: (name: string) => void;
    clearFilters: () => void;
};

export const TableContext = createContext<TableContextValue>({
    filters: [],
    registerFilter: () => {},
    activateFilter: () => {},
    deactivateFilter: () => {},
    clearFilters: () => {}
});

export const TableContextProvider: FCWC = ({ children }) => {
    const [filters, setFilters] = useState<FilterType<any>[]>([]);

    const registerFilter = <T,>(filter: FilterType<T>) =>
        setFilters((oldFilters) => {
            const registeredFilterIndex = oldFilters.findIndex((f) => f.name === filter.name);

            if (registeredFilterIndex === -1) return [...oldFilters, filter];

            const previousFilter = oldFilters[registeredFilterIndex];

            oldFilters[registeredFilterIndex] = { ...filter, active: previousFilter.active, selected: previousFilter.selected };

            return [...oldFilters];
        });

    const activateFilter = <T,>(name: string, selectedValues: T[]) =>
        setFilters((oldFilters) => {
            const filter = oldFilters.find((f) => f.name === name);

            if (Boolean(filter)) {
                (filter as FilterType<T>).active = true;
                (filter as FilterType<T>).selected = selectedValues;

                return [...oldFilters];
            }

            return oldFilters;
        });

    const deactivateFilter = (name: string) =>
        setFilters((oldFilters) =>
            oldFilters.map((filter) => {
                if (filter.name === name) {
                    filter.selected = filter.defaultValue;
                    filter.active = false;
                }

                return filter;
            })
        );

    const clearFilters = () => {
        setFilters(
            filters.map((filter) => {
                filter.selected = filter.defaultValue;
                filter.active = false;
                return filter;
            })
        );
    };

    const contextValue: TableContextValue = {
        filters,
        registerFilter,
        activateFilter,
        deactivateFilter,
        clearFilters
    };

    return <TableContext.Provider value={contextValue}>{children}</TableContext.Provider>;
};
