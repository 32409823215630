import axios from "axios";
import config from "../config";

export const axiosClient = axios.create({
    baseURL: config.apiUrl + "api/",
    timeout: 300000,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: true
});

export const generateCancelToken = () => axios.CancelToken.source();

export default axiosClient;
