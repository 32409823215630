import { makeStyles } from "@mui/styles";

export const useTableViewStyles = makeStyles((theme) => ({
    searchBarWidth: {
        maxWidth: "35%"
    },
    tableContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto"
    }
}));
