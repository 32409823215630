import { MetadataProperty } from "models/metadataSchema/MetdataProperty";
import { createSelector } from "reselect";
import { RootState } from "store/models/RootState";
import { createParameterizedSelector, forwardArg } from "./common";

export const getMetadataSchema = (state: RootState) => state.metadataSchema.schema;
export const getIsMetadataLoading = (state: RootState) => state.metadataSchema.loading;

export const getOtherPropertiesNamesSelector = createParameterizedSelector([getMetadataSchema, forwardArg], (schema: MetadataProperty[], name: string) =>
    schema.filter((property) => property.name !== name).map((property) => property.name)
);

export const getPropertiesNamesSelector = createSelector(getMetadataSchema, (schema) => schema.map((property) => property.name));
