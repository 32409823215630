import { Avatar, MenuList, Popover, Tooltip } from "@mui/material";
import useAuthentication from "app/hooks/useAuthentication";
import ClientsPicker from "features/core/components/ClientsPicker/ClientsPicker";
import { getCurrentUser } from "features/auth/selectors";
import { FC, MouseEventHandler, useState } from "react";
import { useStyles } from "./styles";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getActiveCoreClients } from "features/core/selectors";
import { useParams } from "react-router-dom";
import Version from "./Version/Version";
import SignOut from "./SignOut/SignOut";
import { useTheme } from "@mui/styles";
import config from "config";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type Props = {
    collapsed: boolean;
};

type Params = {
    clientId: string;
};

export const MENU_ITEM_HEIGHT = 48;

const UserMenu: FC<Props> = ({ collapsed }) => {
    const classes = useStyles();
    const theme: any = useTheme();

    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement) | null>(null);
    const [isAccountClicked, setisAccountClicked] = useState(false);

    const open = Boolean(anchorEl);

    const { getUserEmail } = useAuthentication();
    const currentUser = useAppSelector(getCurrentUser);
    const activeCoreClients = useAppSelector(getActiveCoreClients);

    const { clientId } = useParams<Params>();

    const activeCoreClient = activeCoreClients.find((x) => x.id === clientId);

    const id = open ? "user-menu" : undefined;

    const onAccountclick: MouseEventHandler<HTMLDivElement> = (e) => {
        setAnchorEl(e.currentTarget);
        setisAccountClicked(!isAccountClicked);
    };

    const handleCloseAccountMenu = () => {
        setAnchorEl(null);
        setisAccountClicked(!isAccountClicked);
    };

    const initialsAvatar = () => {
        if (currentUser) {
            return (currentUser.firstName[0] + currentUser.lastName[0]).toUpperCase();
        } else {
            const email = getUserEmail();
            if (email && email.length >= 2) return (email[0] + email[1]).toUpperCase();
            return "User";
        }
    };

    return (
        <>
            <Tooltip title="Account" placement="bottom">
                <div className={collapsed ? classes.accountButtonCollapsed : classes.accountButton} onClick={onAccountclick}>
                    <Avatar className={classes.smallAvatar}>{initialsAvatar()}</Avatar>
                    {!collapsed && (
                        <>
                            <div className={classes.smallProfileInfo}>
                                <CustomTypography className={classes.overflowHidden} variant="body2">
                                    {!!currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : getUserEmail()}
                                </CustomTypography>
                                <CustomTypography variant="caption" className={classes.overflowHidden} color={theme.customColors.mutedColor}>
                                    {activeCoreClient?.name}
                                </CustomTypography>
                            </div>
                            <ArrowDropDownIcon fontSize="small" />
                        </>
                    )}
                </div>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseAccountMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                keepMounted
            >
                <MenuList id="user-menu-aria" sx={{ padding: 0 }}>
                    <div className={classes.largeProfileInfo}>
                        <Avatar className={classes.largeAvatar}>{initialsAvatar()}</Avatar>

                        {currentUser && <CustomTypography variant="body2">{`${currentUser.firstName} ${currentUser.lastName}`}</CustomTypography>}
                        <CustomTypography variant="caption" color={theme.customColors.mutedColor}>
                            {!!currentUser ? currentUser.username : getUserEmail()}
                        </CustomTypography>

                        <a href={config.coreAppUrl + "my-profile/account"} target="_blank" rel="noreferrer" className={classes.myAccount}>
                            My Account
                        </a>
                    </div>

                    <ClientsPicker activeCoreClients={activeCoreClients} clientId={clientId} />

                    <Version />

                    <SignOut onSignOut={() => setAnchorEl(null)} />
                </MenuList>
            </Popover>
        </>
    );
};

export default UserMenu;
