import React from "react";
import OverflowCell from "../OverflowCell/OverflowCell";
import styles from "../../../../../scss/themeVariables.module.scss";
import { useNameCellStyles } from "./styles";
import { CELL_SIZES } from "../../../../../utils/constants/cellSizes";

const NameCell = ({ children, size, cellSize, color }) => {
    const classes = useNameCellStyles();

    return (
        <OverflowCell cellSize={cellSize} color={color} className={classes.nameCell} size={size}>
            {children}
        </OverflowCell>
    );
};

NameCell.defaultProps = {
    cellSize: CELL_SIZES.medium,
    size: "medium",
    color: styles.primaryColor
};

export default NameCell;
