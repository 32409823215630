import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    datasetHistory: {
        gridArea: "history",
        padding: 16
    },
    searchBar: {
        paddingLeft: 0
    },
    customTable: {
        height: "auto"
    }
}));
