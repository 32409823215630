import { createSlice } from "@reduxjs/toolkit";
import { resetProjectData } from "actions/globalActions";
import * as types from "../actions/actionTypes";
import { CACHE_STATUSES } from "../utils/constants/cacheStates";

const initialState = {
    open: false,
    notifications: [
        // {name:"test",tileGoal:100,tileCount:0,id:'123'}
    ],
    unseenNotifications: false
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        toggleNotifications: (state) => {
            state.unseenNotifications = false;
            state.open = !state.open;
        },
        removeNotification: (state, { payload: generationProcessId }) => {
            state.notifications = state.notifications.filter((notif) => notif.generationProcessId !== generationProcessId);
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(types.SIGNALR_PROGRESS_UPDATE, (state, { payload: notification }) => {
                const notificationIndex = state.notifications.findIndex((n) => n.generationProcessId === notification.generationProcessId);
                if (notificationIndex !== -1) {
                    state.notifications[notificationIndex] = { ...state.notifications[notificationIndex], ...notification };
                } else {
                    state.notifications.unshift(notification);
                }
                if (!state.unseenNotifications) state.unseenNotifications = !state.open && notificationIndex === -1;
            })
            .addCase(types.SIGNALR_ERROR_UPDATE, (state, { payload: notification }) => {
                const notificationIndex = state.notifications.findIndex((notif) => notif.generationProcessId === notification.generationProcessId);
                if (notificationIndex !== -1) {
                    state.notifications[notificationIndex] = { ...state.notifications[notificationIndex], ...notification, error: true, cacheStatus: CACHE_STATUSES.uncached };
                } else {
                    state.notifications.unshift({ ...notification, error: true, cacheStatus: CACHE_STATUSES.uncached });
                }
                state.unseenNotifications = !state.open;
            })
            .addCase(types.CANCEL_GENERATE_DATASET_CACHE, (state, { payload: generationProcessId }) => {
                const notification = state.notifications.find((notif) => notif.generationProcessId === generationProcessId);
                if (notification) notification.cancelling = true;
            })
            .addCase(types.CANCEL_GENERATE_DATASET_CACHE_COMPLETE, (state, { payload: { generationProcessId } }) => {
                const notification = state.notifications.find((notif) => notif.generationProcessId === generationProcessId);
                if (notification) {
                    notification.cacheStatus = CACHE_STATUSES.uncached;
                    notification.cancelling = false;
                    notification.wasCancelled = true;
                }
                state.unseenNotifications = !state.open;
            })
            .addCase(resetProjectData, () => initialState)
});

export const { toggleNotifications, removeNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
