import { FC } from "react";
import { Resource } from "../UserGroupsDrawer";
import { Box } from "@mui/material";
import GroupDatasetsPermissionsContainer from "features/groups/components/GroupEditView/components/GroupDatasetsPermissionsContainer/GroupDatasetsPermissionsContainer";
import GroupRastersPermissionsContainer from "features/groups/components/GroupEditView/components/GroupRastersPermissionsContainer/GroupRastersPermissionsContainer";
import GroupApplicationsPermissionsContainer from "features/groups/components/GroupEditView/components/GroupApplicationsPermissionsContainer/GroupApplicationsPermissionsContainer";

type Props = {
    groupId: string;
    page: Resource;
    onChangesMade: () => void;
};

const EditIndividualPermissions: FC<Props> = ({ groupId, page, onChangesMade }) => {
    return (
        <Box sx={{ height: "500px" }}>
            {page === Resource.Datasets && <GroupDatasetsPermissionsContainer groupId={groupId} onChangesMade={onChangesMade} />}
            {page === Resource.Rasters && <GroupRastersPermissionsContainer groupId={groupId} onChangesMade={onChangesMade} />}
            {page === Resource.Applications && <GroupApplicationsPermissionsContainer groupId={groupId} onChangesMade={onChangesMade} />}
        </Box>
    );
};

export default EditIndividualPermissions;
