import { isGroup } from "@emblautec/rescursive-array-extensions";

const attachVisibilityCounts = (resources, visibleLayersMap) => {
    let mappedData = [];
    let visibleLayersCount = 0;
    let totalLayersCount = 0;

    resources.forEach((resource) => {
        if (!isGroup(resource)) {
            totalLayersCount += 1;
            if (visibleLayersMap[resource.resourceId]) visibleLayersCount += 1;
            mappedData.push(resource);
        } else {
            const countResults = attachVisibilityCounts(resource.layers, visibleLayersMap);
            mappedData.push({ ...resource, visibleLayersCount: countResults.visibleLayersCount, totalLayersCount: countResults.totalLayersCount, layers: countResults.mappedData });

            visibleLayersCount += countResults.visibleLayersCount;
            totalLayersCount += countResults.totalLayersCount;
        }
    });
    return { totalLayersCount, visibleLayersCount, mappedData };
};

const mapVisibilityCounts = (resources, visibleLayersMap) => {
    const { mappedData } = attachVisibilityCounts(resources, visibleLayersMap);
    return mappedData;
};

export default mapVisibilityCounts;
