import CustomModal from "../../../../../../components/CustomModal/CustomModal";
import GroupEditView from "../../../GroupEditView/GroupEditView";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";
import { useFetchGroupByIdQuery } from "features/groups/api";

type GroupEditModalProps = {
    open: boolean;
    handleClose: () => void;
    groupId: string;
};

const GroupEditModal = ({ open, handleClose, groupId }: GroupEditModalProps) => {
    const { data: group, isLoading: isGroupDataLoading, isError: isGroupDataError, refetch: refetchGroupData } = useFetchGroupByIdQuery(groupId, { skip: !open });

    return (
        <CustomModal
            handleClose={handleClose}
            isOpen={open}
            dialogTitle={"Edit Group"}
            hideActionContainer
            dialogTitleProps={dialogTitleProps}
            closeIconProps={closeIconProps}
            maxWidth="md"
        >
            <LoadingPlaceholder loading={isGroupDataLoading}>
                <ErrorPlaceholder error={isGroupDataError} onTryAgain={refetchGroupData}>
                    {!!group && <GroupEditView group={group} />}
                </ErrorPlaceholder>
            </LoadingPlaceholder>
        </CustomModal>
    );
};

export default GroupEditModal;

const dialogTitleProps = { "data-testid": "qa-group-edit-modal-header" };
const closeIconProps = { "data-testid": "qa-group-edit-modal-close-icon" };
