import { TableCell, Tooltip } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../CustomTypography/CustomTypography";
import { useStyles } from "./styles";

const TooltipCell = ({ tooltipTitle, children, size = "medium", paddingRight = "" }) => {
    const classes = useStyles();

    return (
        <TableCell size={size} sx={{ paddingRight: { paddingRight } }}>
            <Tooltip id="tooltip-top" title={tooltipTitle}>
                <span className={classes.cell}>{children}</span>
            </Tooltip>
        </TableCell>
    );
};

TooltipCell.defaultProps = {
    tooltipTitle: "",
    children: <CustomTypography>-</CustomTypography>
};

export default TooltipCell;
