import { useDispatch } from "react-redux";
import { Raster } from "../models/Raster";
import { addMapLayer, addMapSource, fitMapBounds, removeMapLayer, removeMapSource } from "reducers/map";
import { makeSourceFromRaster } from "utils/creators/SourceCreators";
import { CACHE_STATUSES } from "utils/constants/cacheStates";
import { IsResourceModified } from "utils/localStorageUtils";

const useMapManager = () => {
    const dispatch = useDispatch();

    const addLayerAndSource = (raster: Raster) => {
        dispatch(addMapSource(makeSourceFromRaster(raster)));
        dispatch(
            addMapLayer({
                sourceId: raster.id,
                layerId: raster.id,
                resourceId: raster.id,
                sourceName: raster.name,
                type: "raster",
                sourceMinZoom: raster.minZoom,
                sourceMaxZoom: raster.maxZoom,
                minZoom: raster.minZoom,
                maxZoom: 24
            })
        );
        const options = {
            padding: { top: 45, bottom: 45, left: 45, right: 45 },
            animate: false
        };
        dispatch(fitMapBounds({ bounds: [raster.bounds.coordinates[0][0], raster.bounds.coordinates[0][2]], options }));
    };

    const addRasterLayer = (raster: Raster) => {
        if (raster.cacheStatus !== CACHE_STATUSES.cached) return;

        const modifiedDate = new Date(raster.modifiedUtc);

        const isModified = IsResourceModified(raster.id, modifiedDate);
        if (isModified) {
            caches.delete(raster.id).then(() => {
                localStorage.setItem(raster.id, raster.modifiedUtc);
                dispatch(removeMapLayer(raster.id));
                dispatch(removeMapSource(raster.id));
                addLayerAndSource(raster);
            });
        } else {
            addLayerAndSource(raster);
        }
    };

    const removeRasterLayer = (raster: Raster) => {
        dispatch(removeMapLayer(raster.id));
        dispatch(removeMapSource(raster.id));
    };

    return {
        addRasterLayer,
        removeRasterLayer
    };
};

export default useMapManager;
