import React, { useState, useMemo } from "react";
import { useTheme } from "@mui/styles";

import CsvHandler from "./components/csvHandler";
import CsvUploadInfo from "./components/csvUploadInfo";
import toastr from "../../CustomToastr/CustomToastr";
import { LinearProgress, Button } from "@mui/material";
import CustomTypography from "../../CustomTypography/CustomTypography";
import clsx from "clsx";
import { useStyles } from "./styles";
import DndUploadContainer from "../../DndUploadContainer/DndUploadContainer";
import selectFromComp from "../../../utils/icons/select_from_comp.svg";

const FileLoader = ({ title, acceptedFiles, changeFile, onError, uploading, uploadProgressPercentage }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [dragging, setDragging] = useState(false);
    const [file, setFile] = useState(null);
    const [requiresAdditionalInfo, setRequiresAdditionalInfo] = useState(false);
    const [additionalInfoError, setAdditionalInfoError] = useState(false);

    const acceptedFileTypes = useMemo(() => acceptedFiles.join(","), [acceptedFiles]);

    const onFileDragOver = (e) => {
        e.preventDefault();
        !dragging && setDragging(true);
    };

    const onFileDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const onFileDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        handleFile(e.dataTransfer.files[0]);
    };

    const onFileChanged = (e) => {
        if (e.target.files.length === 0) return;
        handleFile(e.target.files[0]);
    };

    const handleFile = (file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        switch (fileExtension) {
            case "kml":
            case "zip":
            case "geojson":
                loadFile(file);
                break;
            case "csv":
                setRequiresAdditionalInfo(true);
                setFile(file);
                break;
            default:
                toastr.error("Invalid file type");
        }
    };

    const onInfoNextClick = (options) => {
        new CsvHandler().convertToGeoJson(file, options).then((data) => {
            if (data.features.length === 0) {
                setAdditionalInfoError(true);
            } else {
                setRequiresAdditionalInfo(false);
                changeFile(file, options);
            }
        });
    };

    const onCancel = () => {
        setRequiresAdditionalInfo(false);
        setFile(null);
    };

    const loadFile = (file) => {
        setFile(file);
        changeFile(file, {});
    };

    return (
        <>
            {!file ? (
                !requiresAdditionalInfo ? (
                    <DndUploadContainer
                        dragging={dragging}
                        acceptedFormats={acceptedFiles}
                        onFileDrop={onFileDrop}
                        onFileDragLeave={onFileDragLeave}
                        onFileDragOver={onFileDragOver}
                        onFileChanged={onFileChanged}
                    />
                ) : (
                    <CsvUploadInfo error={additionalInfoError} onCancel={onCancel} onNext={onInfoNextClick} />
                )
            ) : (
                <div
                    className={clsx(classes.uploadContainer, classes.flexBox, { [classes.dragging]: dragging })}
                    onDrop={onFileDrop}
                    onDragOver={onFileDragOver}
                    onDragLeave={onFileDragLeave}
                >
                    <div className={clsx(classes.flexBox, { [classes.pointerEventNone]: dragging })}>
                        {!uploading && <CustomTypography variant="h3">Selected file:</CustomTypography>}

                        <CustomTypography variant="h2" textWeight="bold" color={theme.customColors.appTextColorLight}>
                            {file.name}
                        </CustomTypography>
                        {uploading && (
                            <>
                                <CustomTypography variant="body1" className={classes.percentage}>
                                    {uploadProgressPercentage}%
                                </CustomTypography>
                                <LinearProgress className={classes.progressBar} variant="determinate" value={uploadProgressPercentage} />
                            </>
                        )}
                        {!dragging && !uploading && (
                            <Button
                                className={classes.buttonSpacing}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    document.getElementById("file-button").click();
                                }}
                                startIcon={<img alt="" src={selectFromComp} />}
                                data-testid={changeFileButtonTestId}
                            >
                                Change file
                            </Button>
                        )}
                    </div>
                    <div>
                        <input
                            accept={acceptedFileTypes}
                            className={classes.notVisible}
                            multiple={false}
                            id="file-button"
                            type="file"
                            onChange={onFileChanged}
                            data-testid={uploadFileInputTestId}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default FileLoader;

const uploadFileInputTestId = "qa-file-loader-upload-file-input";
const changeFileButtonTestId = "qa-file-loader-change-file-button";
