export const layerJss = (theme) => ({
    contextMenuBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        backgroundColor: "#e3e4e5",
        color: "black"
    },
    grow: {
        flexGrow: 1
    },
    grayIcon: {
        color: "rgba(0, 0, 0, 0.54)",
        paddingLeft: 5
    },
    switch: {
        marginLeft: -12,
        marginRight: 8
    },
    iconSpaceRight: { marginRight: 8 },
    layerTextContainer: {
        width: "calc(100% - 82px)",
        userSelect: "none",
        textTransform: "capitalize"
    },
    layerText: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main
        }
    },
    zoomIndicator: {
        padding: 8,
        backgroundColor: "#004b76",
        color: "white",
        fontSize: 12
    }
});
