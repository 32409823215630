import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import CreateAppModal from "./components/CreateAppModal/CreateAppModal";
import { getApps as getAppsAction } from "actions/apps";
import appSorting from "./components/appSorting";
import AppRow from "./components/AppRow/AppRow";
import TableView from "components/TableView/TableView.js";
import { getApps, getFetching, getLoading } from "selectors/apps";
import AppPublishModal from "../appEditView/components/AppEditFooter/AppPublishModal";

type AppDataForPublishType = {
    name: string;
    id: string;
};

const AppsListView = () => {
    const [modelOpen, setModelOpen] = useState(false);
    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const [appDataForPublish, setAppDataForPublish] = useState<AppDataForPublishType>({ name: "", id: "" });

    const apps = useSelector(getApps);
    const fetching = useSelector(getFetching);
    const loading = useSelector(getLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAppsAction());
    }, []);

    const columns = appSorting;

    const onClickAddApp = () => {
        setModelOpen(true);
    };

    const onModelClose = () => {
        setModelOpen(false);
    };

    const onPublishModalOpen = (appData: AppDataForPublishType) => {
        setAppDataForPublish(appData);
        setPublishModalOpen(true);
    };

    const onPublishModalClose = () => {
        setPublishModalOpen(false);
    };

    const generateItem = (app: any) => <AppRow key={app.id} app={app} onPublishModalOpen={onPublishModalOpen} />;

    const filterItem = (item: any, query: string) => {
        return item.name.toLowerCase().includes(query.toLowerCase());
    };

    return (
        <div className="sidebar-container apps-overview">
            <CreateAppModal open={modelOpen} handleClose={onModelClose} />
            <AppPublishModal open={publishModalOpen} handleClose={onPublishModalClose} appId={appDataForPublish.id} />
            <div className="header">
                <Typography variant="h2" className="subtitle" data-testid={headerTestId}>
                    Applications
                </Typography>
                <Button color="primary" variant="contained" onClick={onClickAddApp} data-testid={addAppButtonTestId}>
                    <AddIcon className="button-icon" fontSize="small" />
                    Add App
                </Button>
            </div>
            {loading && <LinearProgress className="no-margin-progress" />}
            <TableView
                searchPlaceholder={"Search apps"}
                data={apps}
                fetchingData={fetching}
                columns={columns}
                generateItem={generateItem}
                filterFunction={filterItem}
                hideBottomBar={undefined}
                additionalStatusColumn={true}
            />
        </div>
    );
};

export default AppsListView;

const addAppButtonTestId = "qa-apps-list-view-add-app-button";
const headerTestId = "qa-header";
