import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useDragAndDropStyles } from "../../../../../utils/styles/dragAndDrop";
import isNewDepthValid from "../../../../../utils/layerGroups/isNewDepthValid";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import layerGroupSettings from "../../../../../utils/constants/layerGroupSettings";
import * as appDataActions from "../../../../../reducers/appData/appData";
import { addDatasetToAppThunk, addRasterToAppThunk } from "../../../../../actions/apps";
import { isGroup } from "@emblautec/rescursive-array-extensions";
import clsx from "clsx";
import withStyles from "@mui/styles/withStyles";
import { AddDataDNDJss } from "./styles.js";
import { useParams } from "react-router-dom";
import { handleError } from "../../../../../utils/networkErrorUtils";
import { DATA_TYPES } from "../../../../../utils/constants/dataType";
import findDepth from "../../../../../utils/layerGroups/findDepth";
import { unwrapResult } from "@reduxjs/toolkit";

const AddDataDND = ({ classes, children, data, vListIndex, markIndexForRecomputation, depth, isDraggedOver, selected, disabled }) => {
    const dragAndDropClasses = useDragAndDropStyles();

    const [isBeingDragged, setIsBeingDragged] = useState(false);
    const [eventTarget, setEventTarget] = useState(null);
    const [dragOver, setDragOver] = useState(isDraggedOver);
    const [dragAboveMiddle, setDragAboveMiddle] = useState(null);

    const { appId } = useParams();

    const dispatch = useDispatch();

    const onDragStart = (e) => {
        e.stopPropagation();
        const isGroupType = isGroup(data);

        const dropData = {
            resourceId: data.resourceId,
            type: isGroupType ? "group" : "layer",
            depth: isGroupType ? findDepth(data.layers) : 0,
            vListIndex,
            chipType: data.type
        };

        e.dataTransfer.setData("text/plain", JSON.stringify(dropData));

        setIsBeingDragged(true);
        markIndexForRecomputation(vListIndex);

        return false;
    };

    const onDragOver = (event) => {
        event.stopPropagation();

        if (isBeingDragged) {
            return false;
        }

        event.preventDefault();

        const rect = data.type === "layer" ? event.target.getBoundingClientRect() : event.currentTarget.getBoundingClientRect();

        setDragAboveMiddle(event.clientY < rect.y + rect.height / 2);

        setDragOver(true);
        setEventTarget(event.target);

        return true;
    };

    const onDragLeave = (event) => {
        event.preventDefault();
        if (eventTarget === event.target) {
            setDragOver(false);
        }
    };

    const onDrop = (event, asChild) => {
        event.preventDefault();
        event.stopPropagation();
        const dropData = JSON.parse(event.dataTransfer.getData("text"));

        setDragOver(false);

        if (dropData.type === "data") {
            onIncludeData(dropData, asChild);
            return;
        }

        dataReordering(dropData, asChild);
    };

    const onIncludeData = (dropData, asChild) => {
        const dataset = dropData.data;
        const raster = dropData.data;

        const addToAppThunk = dropData.data.chipType === DATA_TYPES.vector ? addDatasetToAppThunk({ appId, dataset }) : addRasterToAppThunk({ appId, raster });

        dispatch(addToAppThunk)
            .then(unwrapResult)
            .then(() => {
                dataReordering(dropData, asChild);
                toastr.success(`${dropData.data.chipType === DATA_TYPES.vector ? "Dataset" : "Raster"} added`);
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const dataReordering = (dropData, asChild) => {
        //You can't drop something on itself
        if (dropData.resourceId === data.resourceId) {
            return;
        }

        if (!isNewDepthValid(depth, dropData.depth, asChild)) {
            toastr.error(`You can't nest groups deeper than ${layerGroupSettings.MAX_DEPTH} levels`);
            return;
        }
        markIndexForRecomputation(vListIndex);
        markIndexForRecomputation(dropData.vListIndex);
        dispatch(appDataActions.moveResource({ resourceId: dropData.resourceId, destinationId: data.resourceId, moveAsChild: asChild, moveAbove: dragAboveMiddle }));
    };

    const onDragEnd = (e) => {
        e.stopPropagation();
        setIsBeingDragged(false);
    };

    const itemStyle = clsx({
        layer: !isGroup(data),
        selected: !!selected,
        [dragAndDropClasses.dragIndicatorAbove]: !isBeingDragged && !!dragOver && dragAboveMiddle,
        [classes.depthZeroSpacing]: !isGroup(data) && depth === 0,
        [classes.rootContainer]: isGroup(data) && depth === 0,
        [classes.isBeingDragged]: isBeingDragged
    });

    return (
        <div
            className={itemStyle}
            draggable={!disabled}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDragStart={onDragStart}
            onDragEnter={onDragOver}
            onDrop={onDrop}
            onDragEnd={onDragEnd}
            id={data.resourceId}
        >
            {children}
        </div>
    );
};

export default withStyles(AddDataDNDJss)(AddDataDND);
