import BooleanIndicator from "components/BooleanIndicator/BooleanIndicator";
import { ColorTableEntry } from "components/ColorPaletteEditor/ColorPalette";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";

type CPTVisualizationProps = {
    cpt: ColorTableEntry[];
};

const CPTVisualization = ({ cpt }: CPTVisualizationProps) => {
    const gradient = `linear-gradient(to right, ${cpt.map((entry) => entry.color).join(", ")})`;

    return cpt && cpt.length > 0 ? (
        <div
            style={{
                width: "100%",
                height: "36px",
                backgroundImage: gradient
            }}
        />
    ) : (
        <ReadOnlyField label="Color Palette" text={<BooleanIndicator boolValue={false} />} />
    );
};

export default CPTVisualization;
