import { Backdrop, Dialog, DialogTitle, IconButton, Step, StepLabel, Stepper } from "@mui/material";
import UploadView from "../UploadView/UploadView";
import { acceptedRasterFormats, acceptedVectorFormats } from "../../utils";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import MetadataView from "../MetadataView/MetadataView";
import { useAppDispatch } from "../../../../store/hooks/useAppDispatch";
import UploadInfoView from "../UploadInfoView/UploadInfoView";
import { Upload, UploadStatus } from "../../models/Upload";
import CloseIcon from "@mui/icons-material/Close";
import { addManyUploads, removeAllUploads } from "../../slice";
import { useAppSelector } from "../../../../store/hooks/useAppSelector";
import { getHasVectorUploads } from "../../selectors";

type Props = {
    open: boolean;
    onClose: () => void;
};

enum StepEnum {
    INFO_UPLOAD,
    UPLOAD_DATA,
    FILL_METADATA
}

const stepTitles = {
    [StepEnum.INFO_UPLOAD]: "Info",
    [StepEnum.UPLOAD_DATA]: "Upload data",
    [StepEnum.FILL_METADATA]: "Fill-in metadata"
};

const UploaderModal = ({ open, onClose }: Props) => {
    const [currentStep, setCurrentStep] = useState(StepEnum.INFO_UPLOAD);
    const dispatch = useAppDispatch();
    const hasVectorUploads = useAppSelector(getHasVectorUploads);
    const steps = hasVectorUploads ? [StepEnum.INFO_UPLOAD, StepEnum.UPLOAD_DATA, StepEnum.FILL_METADATA] : [StepEnum.INFO_UPLOAD, StepEnum.UPLOAD_DATA];

    const onDialogClose = () => {
        onClose();
        dispatch(removeAllUploads());
        setCurrentStep(StepEnum.INFO_UPLOAD);
    };

    const fileHandler = (files: FileList) => {
        let addedUploads: Upload[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const fileName = file.name.split(".")[0];
            const extension = `.${file.name.split(".").pop()?.toLowerCase() ?? ""}`;

            switch (extension) {
                case ".geojson":
                case ".zip":
                case ".gpkg":
                case ".csv":
                    addedUploads = [
                        ...addedUploads,
                        {
                            id: "",
                            name: fileName,
                            file,
                            extension,
                            type: "vector",
                            status: UploadStatus.Pending
                        }
                    ];
                    break;
                case ".mbtiles":
                case ".tif":
                case ".tiff":
                    addedUploads = [
                        ...addedUploads,
                        {
                            id: "",
                            name: fileName,
                            file,
                            extension,
                            type: "raster",
                            status: UploadStatus.Pending
                        }
                    ];
                    break;
                default:
                    break;
            }
        }

        dispatch(addManyUploads(addedUploads));

        if (currentStep === StepEnum.INFO_UPLOAD && addedUploads.length > 0) {
            setCurrentStep(StepEnum.UPLOAD_DATA);
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            onClose={onDialogClose}
            PaperProps={{ "data-testid": modalTestId } as React.HTMLAttributes<HTMLElement>}
        >
            <DialogTitle>Add Data</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onDialogClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8
                }}
            >
                <CloseIcon />
            </IconButton>

            <Stepper
                activeStep={currentStep}
                sx={{
                    paddingBlock: 2,
                    paddingInline: 1.5,
                    borderTop: `1px solid`,
                    // TODO: change to customColors.borderColor:
                    borderTopColor: (theme) => theme.palette.grey[300]
                }}
            >
                {steps.map((step) => (
                    <Step key={stepTitles[step]}>
                        <StepLabel>{stepTitles[step]}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <SwipeableViews index={currentStep}>
                <div hidden={currentStep !== StepEnum.INFO_UPLOAD}>
                    {currentStep == StepEnum.INFO_UPLOAD && <UploadInfoView fileHandler={fileHandler} acceptedFormats={[...acceptedVectorFormats, ...acceptedRasterFormats]} />}
                </div>

                <div hidden={currentStep !== StepEnum.UPLOAD_DATA}>
                    {currentStep == StepEnum.UPLOAD_DATA && (
                        <UploadView
                            fileHandler={fileHandler}
                            onClose={onDialogClose}
                            acceptedFormats={[...acceptedVectorFormats, ...acceptedRasterFormats]}
                            onNextClick={() => setCurrentStep(StepEnum.FILL_METADATA)}
                        />
                    )}
                </div>

                <div hidden={currentStep !== StepEnum.FILL_METADATA}>{currentStep == StepEnum.FILL_METADATA && <MetadataView onClose={onDialogClose} />}</div>
            </SwipeableViews>
        </Dialog>
    );
};

export default UploaderModal;

const modalTestId = "qa-modal";
