import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    resources: {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        padding: "0 20px",
        paddingBottom: 0,
        overflowY: "auto"
    },
    resource: {
        display: "flex",
        justifyContent: "space-between"
    },
    name: {
        width: "60%"
    },
    noPermision: {
        margin: "0 auto"
    },
    divider: {
        marginTop: 12
    }
}));
