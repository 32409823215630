import Button from "@mui/material/Button";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import CollapsibleCard from "components/CollapsibleCard/CollapsibleCard";
import * as appDataActions from "reducers/appData/appData";
import { getApp, getAppPermissionType } from "selectors/appData";
import BasemapPicker from "./BasemapPicker/BasemapPicker";
import toastr from "components/CustomToastr/CustomToastr";
import { setSelectedTool } from "reducers/appData/appData";
import fileCopyIcon from "utils/icons/copy-icon.svg";
import { getCurrentUser } from "features/auth/selectors";
import PublishUnpublishAppSection from "./SettingsSections/PublishUnpublishAppSection";
import MapBoundsSection from "./SettingsSections/MapBoundsSection";
import MapLanguagesSection from "./SettingsSections/MapLanguagesSection";
import ProjectionsSection from "./SettingsSections/ProjectionsSection";
import ViewerSettingsSection from "./SettingsSections/ViewerSettingsSection";
import clsx from "clsx";
import { GisRole } from "features/users/model/GisRole";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import ChangeAppVisibilitySection from "./SettingsSections/ChangeAppVisibilitySection";
import SearchBarSection from "./SettingsSections/SearchBarSection";
import ThumbnailSection from "./SettingsSections/ThumbnailSection";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { PermissionType } from "features/groups/model/PermissionType";
import EmailNotificationsSection from "./SettingsSections/EmailNotificationsSection";
import { permissions } from "utils/constants/permissionTypes";
import RestrictedViewSection from "./SettingsSections/RestrictedViewSection";

const enum SectionId {
    publish,
    public,
    mapBounds,
    restrictedView,
    viewerSettings,
    searchBar,
    projections,
    mapLanguages,
    basemaps,
    thumbnail,
    emailNotifications
}

export type ComponentProps = {
    disabled: boolean;
};

type Section = {
    id: SectionId;
    title: string;
    Component: FC<ComponentProps>;
    visible: boolean;
    scrollable: boolean;
    disabled: boolean;
    unmountOnExit?: boolean; // default true
};

const LAST_TOGGLED_SECTION_KEY = "LAST_TOGGLED_SECTION";

type Props = {
    mapRef: any;
};

const AppSettingsView: FC<Props> = ({ mapRef }) => {
    const currentUser = useAppSelector(getCurrentUser);
    const [toggledSection, setToggledSection] = useState<SectionId | null>(null);

    const scrollToRef = useRef<null | HTMLDivElement>(null);

    const app = useAppSelector(getApp);
    const featureFlags = useAppSelector(getFeatureFlags);
    const permissionType = useAppSelector(getAppPermissionType);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const lastToggledSection = parseInt(localStorage.getItem(LAST_TOGGLED_SECTION_KEY) ?? "");
        handleSectionToggle(!lastToggledSection ? SectionId.viewerSettings : lastToggledSection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => scrollToRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }), 500);
        return () => clearTimeout(timeout);
    }, [toggledSection]);

    const handleSectionToggle = (sectionId: SectionId) => {
        localStorage.setItem(LAST_TOGGLED_SECTION_KEY, sectionId.toString());
        setToggledSection(toggledSection === sectionId ? null : sectionId);
        toggledSection === SectionId.basemaps && dispatch(setSelectedTool(null));
    };

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        dispatch(appDataActions.setAppName(newName));
    };

    const sections: Section[] = useMemo<Section[]>(
        () => [
            {
                id: SectionId.publish,
                title: `Application is ${app.isPublished ? "published" : "unpublished"}`,
                Component: PublishUnpublishAppSection,
                visible: app.permissionType >= permissions.WRITE.value,
                scrollable: false,
                disabled: false
            },
            {
                id: SectionId.public,
                title: `Application is ${app.public ? "public" : "private"}`,
                Component: ChangeAppVisibilitySection,
                visible: currentUser?.role === GisRole.Admin && featureFlags.PUBLIC,
                scrollable: false,
                disabled: false
            },
            {
                id: SectionId.mapBounds,
                title: "Map Bounds",
                Component: MapBoundsSection,
                visible: true,
                scrollable: false,
                disabled: permissionType < PermissionType.WRITE_READ
            },
            {
                id: SectionId.restrictedView,
                title: "Restricted View",
                Component: RestrictedViewSection,
                visible: currentUser?.role === GisRole.Admin,
                scrollable: false,
                disabled: false
            },
            {
                id: SectionId.viewerSettings,
                title: "Viewer Settings",
                Component: ViewerSettingsSection,
                visible: true,
                scrollable: false,
                disabled: permissionType < PermissionType.WRITE_READ
            },
            {
                id: SectionId.searchBar,
                title: "Search Bar",
                Component: SearchBarSection,
                visible: currentUser?.role === GisRole.Admin,
                scrollable: false,
                disabled: false
            },
            {
                id: SectionId.projections,
                title: `Projection (${app.configJson.projections.length})`,
                Component: ProjectionsSection,
                visible: true,
                scrollable: false,
                disabled: permissionType < PermissionType.WRITE_READ
            },
            {
                id: SectionId.mapLanguages,
                title: `Map Languages (${app.languages.length})`,
                Component: MapLanguagesSection,
                visible: true,
                scrollable: false,
                disabled: permissionType < PermissionType.WRITE_READ
            },
            {
                id: SectionId.basemaps,
                title: `Basemaps (${app.basemaps.length})`,
                Component: BasemapPicker,
                visible: true,
                scrollable: true,
                disabled: permissionType < PermissionType.WRITE_READ
            },
            {
                id: SectionId.thumbnail,
                title: "Thumbnail",
                Component: ({ disabled }) => <ThumbnailSection mapRef={mapRef} disabled={disabled} />,
                visible: true,
                scrollable: false,
                disabled: permissionType < PermissionType.WRITE_READ
            },
            {
                id: SectionId.emailNotifications,
                title: "Email Notifications",
                Component: EmailNotificationsSection,
                visible: featureFlags.NOTIFICATIONS,
                scrollable: true,
                disabled: false,
                unmountOnExit: false
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [app.published, currentUser, app.configJson.projections.length, app.languages.length, app.basemaps.length, permissionType]
    );

    return (
        <div className="app-settings-view">
            <div className="settings-container">
                <div className="field copyable">
                    <TextField
                        placeholder="Name of application"
                        label="Application Name"
                        variant="filled"
                        fullWidth
                        value={app.name}
                        onChange={onNameChange}
                        id="filled-required"
                        inputProps={{
                            "data-testid": appNameInputTestId
                        }}
                        disabled={permissionType < PermissionType.WRITE_READ}
                    />
                    <Tooltip title="Copy to clipboard">
                        <Button
                            className="copy-button"
                            onClick={() => {
                                navigator.clipboard.writeText(app.name);
                                toastr.success("The text has been copied");
                            }}
                            data-testid={copyAppNameButtonTestId}
                        >
                            <img alt="" src={fileCopyIcon} />
                        </Button>
                    </Tooltip>
                </div>
                {sections.map(
                    (section) =>
                        section.visible && (
                            <div key={section.id} className={clsx("section", section.scrollable && "scroll")} ref={toggledSection === section.id ? scrollToRef : null}>
                                <CollapsibleCard
                                    onToggleClick={() => handleSectionToggle(section.id)}
                                    title={section.title}
                                    isToggled={toggledSection === section.id}
                                    unmountOnExit={section.unmountOnExit ?? true}
                                    maxHeight={section.scrollable ? 600 : undefined}
                                    innerPadding={undefined}
                                    paddingBottom={undefined}
                                >
                                    <section.Component disabled={section.disabled} />
                                </CollapsibleCard>
                            </div>
                        )
                )}
            </div>
        </div>
    );
};

export default AppSettingsView;

const appNameInputTestId = "qa-app-edit-view-settings-app-name-input";
const copyAppNameButtonTestId = "qa-app-edit-view-settings-copy-app-name-button";
