import { useMemo, useState } from "react";
import { generateCancelToken } from "../../actions/apiClient";
import { CancelTokenSource } from "axios";

const useComponentCancelToken = (): [cancelToken: CancelTokenSource, regenerateCancelToken: () => void] => {
    const [flag, setFlag] = useState(true);

    const regenerateToken = () => setFlag((f) => !f);

    const cancelToken = useMemo(() => generateCancelToken(), [flag]);

    return [cancelToken, regenerateToken];
};

export default useComponentCancelToken;
