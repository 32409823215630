import CustomModal from "components/CustomModal/CustomModal";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { DprLocationMapping } from "features/dprIntegration/models/DprLocationMapping";
import { useStyles } from "./styles";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useParams } from "react-router-dom";
import { useFetchDatasetValuesByColumnQuery } from "features/datasets/api";
import { Collapse, List, Stack } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { TransitionGroup } from "react-transition-group";
import DprCustomAutocomplete, { DprCustomGroupedAutocomplete, GroupOption } from "../DprCustomAutocomplete/DprCustomAutocomplete";
import { useFetchLocationNamesQuery, useFetchStatusTrackerRowsQuery } from "features/dprIntegration/dprApi";

type Props = {
    open: boolean;
    handleClose: () => void;
    onConfirm: (locationMappings: DprLocationMapping[]) => void;
    existingLocationMappings: DprLocationMapping[];
    locationColumnId: number | null;
};

type Params = {
    datasetId: string;
};

const ConfigureLocationNamesModal: FC<Props> = ({ open, handleClose, onConfirm, existingLocationMappings, locationColumnId }) => {
    const classes = useStyles();

    const [locationMappings, setLocationMappings] = useState<DprLocationMapping[]>(existingLocationMappings);

    const scrollToBottomRef = useRef<HTMLDivElement>(null);

    const { datasetId } = useParams<Params>();

    const {
        data: gisLocations = [],
        isLoading: gisLocationsLoading,
        isError: gisLocationsError
    } = useFetchDatasetValuesByColumnQuery({ datasetId, columnId: locationColumnId ?? -1 }, { skip: !locationColumnId });

    const { data: dprActivityLocations = [], isLoading: dprActivityLocationsLoading, isError: dprActivityLocationsError } = useFetchLocationNamesQuery();
    const { data: dprStatusTrackerLocations = [], isLoading: dprStatusTrackerLocationsLoading, isError: dprStatusTrackerLocationsError } = useFetchStatusTrackerRowsQuery();

    useEffect(() => {
        setLocationMappings(existingLocationMappings);
    }, [existingLocationMappings, open]);

    useEffect(() => {
        scrollToBottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [locationMappings]);
    const configureLocationNames = () => {
        onConfirm(locationMappings);
        handleClose();
    };

    const onChange = (locationMapping: DprLocationMapping) => {
        const existingIndex = locationMappings.findIndex((x) => x.id === locationMapping.id);
        if (existingIndex === -1) {
            setLocationMappings([...locationMappings, locationMapping]);
            return;
        }

        const newArr = [...locationMappings];
        newArr.splice(existingIndex, 1, locationMapping);
        setLocationMappings(newArr);
    };

    const getNextId = () => {
        if (locationMappings.length === 0) return 1;
        return Math.max(...locationMappings.map((x) => x.id)) + 1;
    };

    const dprLocationsOptions: GroupOption[] = useMemo(() => {
        return dprActivityLocations
            .map((x) => ({
                option: x,
                group: "Activity Locations"
            }))
            .concat(
                dprStatusTrackerLocations.map((x) => ({
                    option: x,
                    group: "Status Tracker Locations"
                }))
            );
    }, [dprActivityLocations, dprStatusTrackerLocations]);

    const renderLocationMapping = (locationMapping: DprLocationMapping | null) => {
        const id = locationMapping?.id ?? getNextId();

        const modifiedLocationMapping = locationMapping ?? { id, dprName: "", gisName: "" };

        return (
            <Collapse key={!locationMapping ? -1 : id}>
                <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
                    <DprCustomGroupedAutocomplete
                        id={`dpr-location-${id}`}
                        labelName="DPR location name"
                        value={locationMapping?.dprName ?? ""}
                        options={dprLocationsOptions}
                        isLoading={dprActivityLocationsLoading || dprStatusTrackerLocationsLoading}
                        isError={dprActivityLocationsError && dprStatusTrackerLocationsError}
                        onChange={(newValue) => onChange({ ...modifiedLocationMapping, dprName: newValue })}
                        dataTestId={selectDprLocationNameTestId + id}
                    />

                    <ArrowRightAltIcon />

                    <DprCustomAutocomplete
                        id={`gis-location-${id}`}
                        labelName="Location name"
                        value={locationMapping?.gisName ?? ""}
                        options={gisLocations}
                        isLoading={gisLocationsLoading}
                        isError={gisLocationsError}
                        onChange={(newValue) => onChange({ ...modifiedLocationMapping, gisName: newValue })}
                        dataTestId={selectGisLocationNameTestId + id}
                    />
                </Stack>
            </Collapse>
        );
    };

    return (
        <CustomModal
            handleClose={handleClose}
            isOpen={open}
            onConfirm={configureLocationNames}
            disabled={locationMappings.some((x) => x.dprName === "" || x.gisName === "")}
            dialogTitle={
                <>
                    <SettingsIcon sx={{ fontSize: "inherit" }} />
                    Configure location names
                </>
            }
            dialogType={"confirm"}
            dialogTitleProps={{ ...dialogTitleProps, className: classes.dialogTitle }}
            closeIconProps={closeIconProps}
            cancelButtonProps={cancelButtonProps}
            confirmButtonProps={confirmButtonProps}
            PaperProps={{ sx: { height: "70%" } }}
        >
            <CustomTypography variant="subtitle2" textWeight="bold" className={classes.description} fontSize={12}>
                In case of naming discrepancies, please ensure location names align with the ones on DPR platform.
            </CustomTypography>
            <List>
                <TransitionGroup>
                    {locationMappings.map((x) => renderLocationMapping(x))}
                    {renderLocationMapping(null)}
                </TransitionGroup>
            </List>
            <div ref={scrollToBottomRef}></div>
        </CustomModal>
    );
};

export default ConfigureLocationNamesModal;

const dialogTitleProps = { "data-testid": "qa-dpr-integration-configure-location-names-modal-header" };
const closeIconProps = { "data-testid": "qa-dpr-integration-configure-location-names-modal-close-icon" };
const cancelButtonProps = { "data-testid": "qa-dpr-integration-configure-location-names-modal-cancel-button" };
const confirmButtonProps = { "data-testid": "qa-dpr-integration-configure-location-names-modal-confirm-button" };

const selectDprLocationNameTestId = "qa-dpr-integration-configure-dpr-location-name-";
const selectGisLocationNameTestId = "qa-dpr-integration-configure-gis-location-name-";
