import "rc-slider/assets/index.css";
import { FC, useMemo, useState } from "react";

import CustomTypography from "../../../components/CustomTypography/CustomTypography";
import AddPropertyModal from "../editSettingsViews/metadata/AddPropertyModal/AddPropertyModal";

import AddIcon from "@mui/icons-material/AddCircle";
import StorageIcon from "@mui/icons-material/Storage";
import MapIcon from "@mui/icons-material/Map";
import DataObjectIcon from "@mui/icons-material/DataObject";

import { Button } from "@mui/material";
import { StyledTab, StyledTabs } from "../../../components/CustomTabs/CustomTabs";
import EditMetadataSchemaView from "../editSettingsViews/metadata/EditMetadataSchemaView";
import EditSchemasView from "../editSettingsViews/schemas/editSchemasView";
import AddDatabaseSchemaModal from "../editSettingsViews/schemas/AddDatabaseSchemaModal/AddDatabaseSchemaModal";
import EditBasemapsView from "../editSettingsViews/basemaps/EditBasemapsView";
import UploadBasemapModal from "../../../features/settings/components/UploadBasemapModal/UploadBasemapModal";

const settings = [
    {
        name: "Metadata schemas",
        addButtonText: "Add Metadata Schema",
        IconComponent: DataObjectIcon,
        EditViewComponent: EditMetadataSchemaView,
        AddModalComponent: AddPropertyModal
    },
    {
        name: "Database schemas",
        addButtonText: "Add Database Schema",
        IconComponent: StorageIcon,
        EditViewComponent: EditSchemasView,
        AddModalComponent: AddDatabaseSchemaModal
    },
    {
        name: "Basemaps",
        addButtonText: "Add Basemap",
        IconComponent: MapIcon,
        EditViewComponent: EditBasemapsView,
        AddModalComponent: UploadBasemapModal
    }
];

const SettingsView: FC = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const settingElements = () =>
        settings.map((item, index) => {
            return (
                <StyledTab
                    data-testid={`qa-${item.name.toLocaleLowerCase().replaceAll(" ", "-")}-tab`}
                    label={item.name}
                    icon={<item.IconComponent className="icon" fontSize="small" />}
                    iconPosition="start"
                    key={index}
                />
            );
        });

    const EditViewComponent = settings[tabIndex].EditViewComponent;
    const AddModalComponent = settings[tabIndex].AddModalComponent;

    return (
        <div className="sidebar-container settings-view">
            <div className="header">
                <CustomTypography data-testid={headerTestId} variant="h2">
                    Settings
                </CustomTypography>
                <Button color="primary" variant="contained" data-testid={buttonTestId} onClick={() => setModalOpen(true)}>
                    <AddIcon fontSize="small" className="button-icon" />
                    {settings[tabIndex].addButtonText}
                </Button>
            </div>
            <StyledTabs value={tabIndex} onChange={(_: any, val: number) => setTabIndex(val)} indicatorColor="primary">
                {settingElements()}
            </StyledTabs>

            <EditViewComponent />

            <AddModalComponent setModalOpen={setModalOpen} open={modalOpen} />
        </div>
    );
};

export default SettingsView;

const headerTestId = "qa-settings-header";
const buttonTestId = "qa-settings-action-button";
