import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { resetProjectData } from "actions/globalActions";
import { MetadataProperty } from "models/metadataSchema/MetdataProperty";
import { addMetadataPropertyThunk, deleteMetadataPropertyThunk, getMetadataSchemaThunk, updateMetadataPropertyThunk } from "../actions/metadataSchema";

type SliceState = {
    loading: boolean;
    schema: MetadataProperty[];
};

const initialState: SliceState = {
    loading: false,
    schema: []
};

const metadataSchemaSlice = createSlice({
    name: "metadataSchema",
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        const thunks: [AnyAsyncThunk, ...AnyAsyncThunk[]] = [getMetadataSchemaThunk, updateMetadataPropertyThunk, addMetadataPropertyThunk, deleteMetadataPropertyThunk];

        return builder
            .addCase(resetProjectData, () => initialState)
            .addMatcher(isFulfilled(...thunks), (state, { payload }) => {
                state.schema = payload.data;
            })
            .addMatcher(isPending(...thunks), (state) => {
                state.loading = true;
            })
            .addMatcher(isAnyOf(isFulfilled(...thunks), isRejected(...thunks)), (state) => {
                state.loading = false;
            });
    }
});

export const { reset } = metadataSchemaSlice.actions;
export default metadataSchemaSlice.reducer;
