import Tooltip from "@mui/material/Tooltip";
import { useLocation, useRouteMatch } from "react-router-dom";

import Button from "@mui/material/Button";

import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import MenuIcon from "@mui/icons-material/Menu";

import { useStyles } from "./styles";

import { GISLocation, LOCATIONS, checkCurrentUserRoles, checkFeatureFlag } from "utils/routeUtils";
import { getCurrentUser } from "features/auth/selectors";
import clsx from "clsx";
import * as navigationActions from "actions/navigation";
import { getNavigationState } from "selectors/navigation";
import ButtonLink from "../ButtonLink/ButtonLink";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getFeatureFlags } from "features/featureFlags/selectors";

const NavigationMenu = () => {
    const classes = useStyles();

    const dispatch = useAppDispatch();
    const location = useLocation();
    const match = useRouteMatch();

    const currentUser = useAppSelector(getCurrentUser);
    const isNavigationOpen = useAppSelector(getNavigationState);
    const featureFlags = useAppSelector(getFeatureFlags);

    const renderNavItem = (item: GISLocation) => {
        return (
            <ButtonLink
                to={match.url + item.route}
                className={clsx(
                    classes.link,
                    isNavigationOpen ? classes.justifyStart : classes.justifyCenter,
                    location.pathname.startsWith(match.url + item.route) && classes.isActive
                )}
            >
                <span className={classes.icon}>{item.icon}</span>
                <span className={classes.text}>{isNavigationOpen && item.name}</span>
            </ButtonLink>
        );
    };

    return (
        <div className="navigation-menu">
            <div className="header" onClick={() => dispatch(navigationActions.toggleNavigation())}>
                {isNavigationOpen ? (
                    <Button className={clsx(classes.button, classes.justifyStart)}>
                        <div className="header__item">
                            <span className="item__icon">
                                <DoubleArrowIcon className="icon rotate180" />
                            </span>
                            <span className="item__label">Navigation</span>
                        </div>
                    </Button>
                ) : (
                    <Button className={classes.button}>
                        <div className="header__item">
                            <MenuIcon className="icon" />
                        </div>
                    </Button>
                )}
            </div>
            <div className="container">
                {LOCATIONS.map((item) => {
                    return checkCurrentUserRoles(item, currentUser) && checkFeatureFlag(item, featureFlags) ? (
                        <div className={classes.itemWrapper} key={item.name} data-testid={createItemTestId(item)}>
                            {!isNavigationOpen ? (
                                <Tooltip
                                    title={item.name}
                                    placement="right"
                                    PopperProps={{
                                        disablePortal: false
                                    }}
                                >
                                    {renderNavItem(item)}
                                </Tooltip>
                            ) : (
                                <>{renderNavItem(item)}</>
                            )}
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default NavigationMenu;

const createItemTestId = (item: GISLocation) => `qa-${item.name.toLowerCase()}`;
