import CloseIcon from "@mui/icons-material/Close";
import { getDatasets } from "selectors/datasets";
import useAddDataToolStyles from "./styles";
import DatasetsTable from "./components/DatasetsTable/DatasetsTable";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { DATA_TYPES } from "utils/constants/dataType";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@mui/material";
import { useAppSelector } from "store/hooks/useAppSelector";
import { FC, useState } from "react";
import { TableContextProvider } from "components/CustomTable/providers/TableContextProvider";
import UploaderModal from "features/upload/components/UploaderModal/UploaderModal";
import { useFetchRastersQuery } from "../../../../../features/rasters/api";

type Props = {
    onClose: () => void;
    canDragAndDrop?: boolean;
};

const AddDataTool: FC<Props> = ({ onClose, canDragAndDrop = true }) => {
    const classes = useAddDataToolStyles();
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const datasets = useAppSelector(getDatasets);
    const { data: rasters = [] } = useFetchRastersQuery();
    const concatenatedData = datasets.filter((x: any) => x.cacheStatus === 2).concat(rasters);

    const tableData = concatenatedData.map((dataset: any) => {
        if (!dataset.geometryType && !dataset.schemaName) {
            return { ...dataset, geometryType: "-", schemaName: "-", chipType: DATA_TYPES.raster };
        }

        return { ...dataset, chipType: DATA_TYPES.vector };
    });

    return (
        <>
            <div className={classes.addDataTool}>
                <div className={classes.header}>
                    <CustomTypography variant="subtitle1" textWeight="bold">
                        Add Data
                    </CustomTypography>
                    <div onClick={onClose} className={classes.closeButton} data-testid={closeButtonTestId}>
                        <CloseIcon />
                    </div>
                </div>
                <TableContextProvider>
                    <DatasetsTable data={tableData} canDragAndDrop={canDragAndDrop} />
                </TableContextProvider>
                <IconButton className={classes.addButton} onClick={() => setIsUploaderOpen(true)} data-testid={addDataButtonTestId}>
                    <AddCircleIcon color="primary" className={classes.addIcon} />
                </IconButton>
            </div>
            <UploaderModal open={isUploaderOpen} onClose={() => setIsUploaderOpen(false)} />
        </>
    );
};

export default AddDataTool;

const addDataButtonTestId = "qa-app-edit-view-add-tool-add-data-button";
const closeButtonTestId = "qa-app-edit-view-add-tool-close-button";
