import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApp } from "../../../../selectors/appData";
import useComponentCancelToken from "../../../../utils/customHooks/useComponentCancelToken";
import mapVisibilityCounts from "../../../../utils/layerGroups/mapVisibilityCounts";
import { getDatasets } from "../../../../actions/datasets";
import { addMapSources, fitMapBounds, initMapResources, removeAllMapLayers, removeAllSources, replaceMapLayers, resetMapFlags } from "../../../../reducers/map";
import axios from "axios";
import { getStyleConfig } from "../../../../selectors/style";
import { getApp as getAppAction } from "../../../../actions/apps";
import { clearAppData, setAppDetails, setLastSaveDatasetCount } from "../../../../reducers/appData/appData";
import { useHistory, useParams } from "react-router-dom";
import { makeSourceFromDataset, makeSourceFromMap, makeSourceFromRaster } from "../../../../utils/creators/SourceCreators";
import { makePaintFromStyle } from "../../../../utils/creators/PaintCreators";
import { makeLayoutFromStyle } from "../../../../utils/creators/LayoutCreators";
import { makeZoomRange } from "../../../../utils/creators/ZoomRangeCreators";
import { makeMapLayerFromStyle } from "../../../../utils/creators/MapLayerCreators";
import { getStyleConfig as getStyleConfigThunk } from "../../../../actions/config";
import { IsResourceModified } from "utils/localStorageUtils";
import { TOOLS, WIDGETS } from "../../../../utils/constants/appDefaults";

export const useAppInit = () => {
    const [loading, setLoading] = useState(true);

    const cancelToken = useComponentCancelToken();

    const storeApp = useSelector(getApp);
    const styleConfig = useSelector(getStyleConfig);

    const dispatch = useDispatch();

    const history = useHistory();

    const { clientId, projectId } = useParams();

    const initApp = (appId) => {
        const appPromise = dispatch(getAppAction({ applicationId: appId, cancelToken: cancelToken.token }));

        //The style config from the bootsrap isn't always available if a user opens directly the app. In this case we also get it.
        //If it is available, we provide the already existent one. This logic can result in 2 style fetches at the same time, but it shouldn't matter.
        let styleConfigPromise = new Promise((resolve) => {
            resolve(styleConfig);
        });

        if (styleConfig === null) {
            styleConfigPromise = dispatch(getStyleConfigThunk()).then((res) => res.payload);
        }

        dispatch(getDatasets());

        Promise.all([appPromise, styleConfigPromise])
            .then((res) => {
                let appRes = res[0].payload;
                const styleConfig = res[1];
                setDetails(appRes, styleConfig);
            })
            .catch((e) => {
                if (!axios.isCancel(e)) {
                    history.push(`/${clientId}/${projectId}/applications`);
                }
            });
        return () => {
            dispatch(removeAllMapLayers());
            dispatch(removeAllSources());
            dispatch(resetMapFlags());
            dispatch(clearAppData());
            cancelToken.cancel("Canceled");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const setDetails = (app, styleConfig) => {
        if (!app.mapBounds) {
            app.mapBounds = storeApp.mapBounds;
        }
        //Build a map of added datasets
        const includedDatasets = app.maps
            .flatMap((x) => x.datasets)
            .reduce((a, b) => {
                a[b.id] = { included: true, name: b.name };
                return a;
            }, {});

        const includedRasters = app.rasters.reduce((a, b) => {
            a[b.id] = { included: true, name: b.name };
            return a;
        }, {});

        let configJson = initAppConfig(app);

        const tools = TOOLS.map((t) =>
            app.tools.some((tool) => t.name === tool.name)
                ? {
                      ...t,
                      enabled: true
                  }
                : { ...t, enabled: false }
        );

        const widgets = WIDGETS.map((w) =>
            app.widgets.some((widget) => w.name === widget.name)
                ? {
                      ...w,
                      enabled: true
                  }
                : { ...w, enabled: false }
        );

        addSources(app.maps, app.rasters).then((sources) => {
            const { layerGroups, layerVisibilityMap, layerStylesMap } = loadStyles(configJson.layerGroups, sources, styleConfig);
            layerGroups.forGroupsRecursive((g) => (g.options.isCollapsed = g.options.collapsed));

            dispatch(
                setAppDetails({
                    app: { ...app, tools, widgets, configJson },
                    layerGroups,
                    layerVisibilityMap,
                    layerStylesMap,
                    includedDatasets,
                    includedRasters,
                    hasUnsavedChanges: false
                })
            );
            setLoading(false);
            dispatch(
                fitMapBounds({
                    bounds: app.mapBounds,
                    options: {
                        padding: {
                            top: 45,
                            bottom: 45,
                            left: 45,
                            right: 45
                        },
                        animate: false
                    }
                })
            );
        });
    };

    const initAppConfig = (app) => {
        if (app.configJson == null) return storeApp.configJson;

        const configJson = {
            layerGroups: app.configJson.layerGroups || [],
            projections: app.configJson.projections || storeApp.configJson.projections
        }; //Add or remove new and old tools from the config

        return configJson;
    };

    const addSources = (maps, rasters) => {
        const sources = [];
        const promises = [];

        maps.forEach((map) => {
            const modifiedDate = new Date(map.modifiedUtc);

            if (IsResourceModified(map.id, modifiedDate)) {
                promises.push(caches.delete(map.id));
                localStorage.setItem(map.id, map.modifiedUtc);
            }

            sources.push(makeSourceFromMap(map));

            if (map.cacheStatus !== 2) {
                map.datasets.forEach((dataset) => {
                    sources.push(makeSourceFromDataset(dataset));
                });
            }
        });

        rasters.forEach((raster) => {
            sources.push(makeSourceFromRaster(raster));
        });

        return Promise.all(promises).then((x) => {
            dispatch(addMapSources(sources));
            return sources;
        });
    };

    const loadStyles = (layerGroups, sources, styleConfig) => {
        let previousStyleId = null;
        const mapSourcesMap = sources.reduce((acc, source) => {
            acc[source.id] = source;
            return acc;
        }, {});
        const layerVisibilityMap = {};
        const layerStylesMap = {};
        const mapLayers = [];
        const paints = {};
        const layouts = {};
        const zoomRanges = {};

        const processStyle = (layer, style) => {
            //If the layers map is not cached it will be undefined
            //In that case we will use the datasets tiles instead
            const sourceId = mapSourcesMap[layer.sourceId] ? layer.sourceId : layer.resourceId;

            const sourceInfo = { sourceId, sourceName: layer.sourceName };
            const metadata = { resourceId: layer.resourceId, previousStyleId };
            const mapLayer = makeMapLayerFromStyle(style, sourceInfo, metadata);

            mapLayers.push(mapLayer);

            paints[style.styleId] = makePaintFromStyle(style, styleConfig);

            layouts[style.styleId] = makeLayoutFromStyle(style, styleConfig, layer.options.enabled);

            zoomRanges[style.styleId] = makeZoomRange(style.styleId, style.minZoom, style.maxZoom);

            previousStyleId = style.styleId;
        };

        let layerCount = 0;
        const processedLayerGroups = layerGroups
            .mapGroupsRecursive((group) => ({
                ...group,
                collapsed: group.options.collapsed
            }))
            .filterLayersRecursive((layer) => layer.styles)
            .mapLayersRecursive((layer) => {
                layerCount++;
                layerVisibilityMap[layer.resourceId] = layer.options.enabled;

                for (let i = 0; i < layer.styles.length; i++) {
                    let style = layer.styles[i];
                    processStyle(layer, style);
                }

                layerStylesMap[layer.resourceId] = [...layer.styles];
                delete layer.styles;
                return layer;
            });
        dispatch(setLastSaveDatasetCount(layerCount));
        dispatch(
            initMapResources({
                layers: mapLayers,
                paints,
                layouts,
                zoomRanges
            })
        );
        return {
            layerGroups: mapVisibilityCounts(processedLayerGroups, layerVisibilityMap),
            layerVisibilityMap,
            layerStylesMap
        };
    };

    const layerGroupsToMapLayers = (layerGroups, sources, layerStylesMap) => {
        let previousStyleId = null;
        let layerCount = 0;

        const mapLayers = [];
        const mapSourcesMap = sources.reduce((acc, source) => {
            acc[source.id] = source;
            return acc;
        }, {});

        layerGroups.forLayersRecursive((layer) => {
            layerCount++;

            layerStylesMap[layer.resourceId].forEach((style) => {
                //If the layers map is not cached it will be undefined
                //In that case we will use the datasets tiles instead
                const sourceId = mapSourcesMap[layer.sourceId] ? layer.sourceId : layer.resourceId;
                const sourceInfo = { sourceId, sourceName: layer.sourceName };
                const metadata = { resourceId: layer.resourceId, previousStyleId };
                const mapLayer = makeMapLayerFromStyle(style, sourceInfo, metadata);

                mapLayers.push(mapLayer);

                previousStyleId = style.styleId;
            });
        });
        dispatch(setLastSaveDatasetCount(layerCount));
        dispatch(replaceMapLayers(mapLayers));
    };

    return { loading, initApp, layerGroupsToMapLayers };
};
