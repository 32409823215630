import { Button, Tooltip } from "@mui/material";
import PropertiesSection from "../PropertiesSection/PropertiesSection";

import toastr from "../../../../../components/CustomToastr/CustomToastr";
import fileCopyIcon from "../../../../../utils/icons/copy-icon.svg";
import ZoomSection from "../ZoomSection/ZoomSection";
import ReadOnlyField from "../../../../../components/ReadOnlyField/ReadOnlyField";
import { useDatasetEditViewStyles } from "../../styles";
import clsx from "clsx";
import DefaultInfoText from "../DefaultInfoText/DefaultInfoText";

const AdvancedTab = ({ bbox, setColumnsDataChanged, setTileDataChanged, tileName }) => {
    const classes = useDatasetEditViewStyles();

    return (
        <div className={classes.page}>
            <DefaultInfoText />

            <div className={clsx(classes.field, classes.copyable)}>
                <div className={clsx(classes.field, classes.flexField, classes.fullWidth, classes.lightGrayBorder)}>
                    <ReadOnlyField label="Tile Name" text={tileName} />
                    <Tooltip title="Copy to clipboard">
                        <Button
                            className={classes.copyButton}
                            onClick={() => {
                                navigator.clipboard.writeText(tileName);
                                toastr.success("The text has been copied");
                            }}
                            data-testid={copyTileNameButtonTestId}
                        >
                            <img alt="" src={fileCopyIcon} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <ZoomSection bbox={bbox} setTileDataChanged={setTileDataChanged} />
            <PropertiesSection setColumnsDataChanged={setColumnsDataChanged} />
        </div>
    );
};

export default AdvancedTab;

const copyTileNameButtonTestId = "qa-dataset-edit-view-advanced-tab-copy-tile-name-button";
