import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import { getColorString } from "../../../../../../utils/metadataUtils";

import ColorPopover from "../ColorPopover/ColorPopover";

const SingleTagEditor = ({ tag, onEdit, onDelete, error, errorHelper }) => {
    const onColorChange = (color) => {
        onEdit({ ...tag, color: getColorString(color) });
    };

    const onNameChange = (e) => {
        onEdit({ ...tag, name: e.target.value });
    };

    return (
        <div className="tag-create-container">
            <TextField value={tag.name} label="Tag name" variant="outlined" onChange={onNameChange} error={error} helperText={error ? errorHelper : ""} />
            <ColorPopover color={tag.color} onColorChanged={onColorChange} />
            <div className="add-tag-btn" onClick={onDelete}>
                <DeleteIcon />
            </div>
        </div>
    );
};

export default SingleTagEditor;
