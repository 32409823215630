import React, { Component } from "react";
import { connect } from "react-redux";
import GlMap from "@emblautec/mapbox-gl";
import config from "../../config";
import * as mapActions from "../../reducers/map";
import ResizeObserver from "resize-observer-polyfill";
import { validate } from "@emblautec/mapbox-gl/dist/style-spec/index.es";
import * as appActions from "../../reducers/appData/appData";
import { DATA_TYPES } from "../../utils/constants/dataType";
import { END_POINT } from "../../utils/constants/endPointName";
import { getDefaultBasemapSelector, getLayerStyleErrorsMap } from "../../selectors/appData";
import { getBasemapStyle, removeInvalidLayers } from "./utils/basemapUtils";
import { HelperLayers } from "./constants/helperLayers";
import { withRouter } from "react-router";
import blankBasemapStyle from "./constants/blankBasemapStyle";
import { getToken } from "../../features/auth/selectors";
import { getClientId, getProjectId } from "features/core/selectors";
import { CACHE_STATUSES } from "utils/constants/cacheStates";
// Quick fix for https://github.com/mapbox/mapbox-gl-js/issues/10173 (might be fixed in the future, so this can be removed)
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
GlMap.workerClass = require("worker-loader!@emblautec/mapbox-gl/dist/mapbox-gl-csp-worker").default;

var MAPBOX_APIKEY = config.mapboxApiKey;

const defaultBasemap = {
    type: "vector",
    url: config.basemap
};

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapLoaded: false,
            selectedFeatures: [],
            //mapBounds doubles down the fitBounds state from redux in order to circumvent the render race condition
            mapBounds: null //{bbox, options}
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this._resize);
        this.initMap();
    }

    initMap() {
        GlMap.accessToken = MAPBOX_APIKEY;

        const { pathname } = this.props.location;
        const applicationAlreadySelected = pathname.includes("/applications/edit/");

        const transformRequestResourceTypes = ["Tile", "SpriteJSON", "SpriteImage"];

        const glMapOptions = {
            useWebGL2: true,
            container: "map",
            zoom: 1.5,
            center: [15, 40],
            style: applicationAlreadySelected ? blankBasemapStyle : config.basemap,
            maxZoom: 24,
            attributionControl: false,
            transformRequest: (url, resourceType) => {
                if (transformRequestResourceTypes.includes(resourceType) && url.startsWith(config.apiUrl)) {
                    return {
                        url: url + "?key=" + this.props.authToken + `&ClientId=${this.props.clientId}` + `&ProjectId=${this.props.projectId}`
                    };
                }
            }
        };

        this.map = new GlMap.Map(glMapOptions);

        this.map.addControl(
            new GlMap.AttributionControl({
                customAttribution: '<a class="map-attribution" href="https://lautec.com/" target="_blank">© LAUTEC</a>'
            })
        );

        this.map.on("load", () => this._mapLoad());
        this.map.on("data", (e) => this.onMapDataEvent(e));

        !!this.props.mapRef && (this.props.mapRef.current = this.map);

        const resizeObserver = new ResizeObserver(() => {
            setTimeout(() => {
                this._resize();
            }, 25);
        });

        let map = document.getElementById("map");
        resizeObserver.observe(map);
    }

    componentWillUnmount() {
        let map = document.getElementById("map");
        map.removeEventListener("resize", this._resize);
        this.map.remove();
    }

    onMapDataEvent = (e) => {
        let style = this.map.getStyle();

        if (style.sprite !== config.apiUrl + "api/sprite/") {
            style.sprite = config.apiUrl + "api/sprite/";
            this.map.setStyle(style);
        }
    };

    _mapLoad = () => {
        this.map.on("moveend", () => this.updatePosition());

        if (this.props.defaultBasemap) {
            this.changeStyle(this.props.defaultBasemap);
        }

        this.addSources(this.props.mapState.sources);
        this.addLayers(this.props.mapState.layers);
        this.changePaints(Object.values(this.props.mapState.paints));
        this.changeLayouts(Object.values(this.props.mapState.layouts));
        this.changeLayerZoomRanges(this.props.mapState.zoomRanges);

        this.setState({
            mapLoaded: true
        });
    };

    _resize = () => {
        this.map.resize();
    };

    updatePosition = () => {
        let bounds = this.map.getBounds();

        this.props.setPosition({
            zoom: this.map.getZoom(),
            bounds: [
                [bounds._sw.lng, bounds._sw.lat],
                [bounds._ne.lng, bounds._ne.lat]
            ]
        });
    };

    _onViewportChange = (viewport) => this.setState({ viewport });

    _onStyleChange = (mapStyle) => this.setState({ mapStyle });

    _onMapClick = (e) => { };

    addSources(sources) {
        for (let i = 0; i < sources.length; i++) {
            let source = sources[i];

            let endpointName = source.type === DATA_TYPES.raster ? END_POINT.raster : END_POINT.vector;
            this.map.addSource(source.id, {
                type: source.type === DATA_TYPES.raster ? DATA_TYPES.raster : DATA_TYPES.vector,
                tiles: [config.apiUrl + `api/${endpointName}/${source.id}/{z}/{x}/{y}`],
                bounds: source.bounds,
                minzoom: source.minZoom,
                maxzoom: source.maxZoom
            });
        }
    }

    removeSources(previousSources, currentSources) {
        let sourcesMap = currentSources.reduce((a, b, index) => {
            a[b.id] = index;
            return a;
        }, {});

        for (let i = 0; i < previousSources.length; i++) {
            let source = previousSources[i];

            if (!sourcesMap.hasOwnProperty(source.id)) {
                this.map.removeSource(source.id);
            }
        }
    }

    addLayers(layers) {
        // Find the index of the first symbol layer in the map style
        let FirstSymbolId;
        let mapLayers = this.map.getStyle().layers;

        for (let i = 0; i < mapLayers.length; i++) {
            if (mapLayers[i].type === "symbol") {
                FirstSymbolId = mapLayers[i].id;
                break;
            }
        }
        const symbolLayers = layers.filter((x) => x.type === "symbol");
        const normalLayers = layers.filter((x) => x.type !== "symbol");
        const arrangedLayers = [...normalLayers, ...symbolLayers];
        //Draw layers in reverse order
        for (let i = 0; i < arrangedLayers.length; i++) {
            const layer = arrangedLayers[i];
            const vectorLayer = {
                id: layer.layerId,
                type: layer.type,
                source: layer.sourceId,
                "source-layer": layer.sourceName,
                minzoom: layer.minZoom,
                maxzoom: layer.maxZoom
            };

            if (this.props.layerStylesErrorMap?.[layer.resourceId]?.[layer.layerId]) continue;

            if (layer.type !== "symbol") {
                this.map.addLayer(vectorLayer, FirstSymbolId);
                FirstSymbolId = layer.layerId;
                if (!this.map.firstLayer) this.map.firstLayer = vectorLayer.id;
            } else {
                this.map.addLayer(vectorLayer);
            }
        }
    }

    buildStyleForValidation(listOfLayers, listOfSources, paintsDict, layoutsDict) {
        const layers = listOfLayers.map((layer) => {
            const paint = paintsDict[layer.layerId].properties.reduce((acc, paintProp) => {
                if (layer.type === DATA_TYPES.raster && paintProp.name === "fill-color") return acc; //fill-color is an improvization for legend
                acc[paintProp.name] = paintProp.value;
                return acc;
            }, {});

            const layout = layoutsDict[layer.layerId].properties.reduce((acc, layoutProp) => {
                acc[layoutProp.name] = layoutProp.value;
                return acc;
            }, {});

            return {
                type: layer.type,
                id: layer.layerId,
                source: layer.sourceId,
                "source-layer": layer.sourceName,
                paint,
                layout
            };
        });

        const sources = listOfSources.reduce((acc, source) => {
            acc[source.id] = { type: source.type };
            return acc;
        }, {});

        return {
            version: 8,
            glyphs: "http://fonts.openmaptiles.org/{fontstack}/{range}.pbf", //needed for text-field property validation
            layers,
            sources
        };
    }

    registerLayerErrors(errors, layers) {
        const layerStyleErrorsMap = {}; // This refers to app layers, not map layers

        const addedStyles = {};

        errors.forEach(({ message }) => {
            const layerText = message.split(":")[0];

            // This is done for the situation when we have layers[128].paint.line-width[5]
            const layerNumberSection = layerText.split(".")[0];

            const numberBetweenSquareBracketsExpression = /\[(.*?)\]/;
            const layerIndex = layerNumberSection.match(numberBetweenSquareBracketsExpression)[1];

            const erronousLayerId = layers[layerIndex].layerId;
            const erronousResourceId = layers[layerIndex].resourceId;

            if (addedStyles[erronousLayerId]) return;

            addedStyles[erronousLayerId] = true;

            const currentLayerStyleErrorsMap = layerStyleErrorsMap[erronousResourceId];

            if (currentLayerStyleErrorsMap) {
                layerStyleErrorsMap[erronousResourceId][erronousLayerId] = true;
            } else {
                layerStyleErrorsMap[erronousResourceId] = { [erronousLayerId]: true };
            }
        });
        this.props.setLayerStyleErrorsMap(layerStyleErrorsMap);
    }

    removeLayers(previousLayers, currentLayers) {
        let LayersMap = currentLayers.reduce((a, b, index) => {
            a[b.layerId] = index;
            return a;
        }, {});

        for (let i = 0; i < previousLayers.length; i++) {
            let layer = previousLayers[i];

            if (!LayersMap.hasOwnProperty(layer.layerId)) {
                this.map.removeLayer(layer.layerId);
            }
        }
    }

    changeLayers(previousLayers, currentLayers) {
        // let previousLayersMap = previousLayers.reduce((a, b, index) => {
        //     a[b.layerId] = index;
        //     return a;
        // }, {});

        for (let i = 0; i < currentLayers.length; i++) {
            let layer = currentLayers[i];
            let previousLayer = previousLayers[i];

            if (layer.changed) {
                this.map.removeLayer(layer.layerId);

                var vectorSource = {
                    id: layer.layerId,
                    type: layer.type,
                    source: layer.sourceId,
                    "source-layer": layer.sourceName,
                    paint: {}
                };

                if (layer.drawBefore !== null) {
                    this.map.addLayer(vectorSource, layer.drawBefore);
                } else {
                    this.map.addLayer(vectorSource);
                }

                const paint = this.props.mapState.paints[layer.layerId] || { layerId: layer.layerId, properties: [] };
                const layout = this.props.mapState.layouts[layer.layerId] || { layerId: layer.layerId, properties: [] };
                const zoomRange = this.props.mapState.zoomRanges[layer.layerId] || { layerId: layer.layerId, minZoom: 0, maxZoom: 24 };

                this.changePaints([paint]);
                this.changeLayouts([layout]);
                this.changeLayerZoomRanges([zoomRange]);
            } else if (previousLayer && layer.layerId !== previousLayer.layerId) {
                this.map.moveLayer(layer.layerId, i === 0 ? null : currentLayers[i - 1].layerId);
            }
        }
    }

    changePaints(paints) {
        for (let k = 0; k < paints.length; k++) {
            const paint = paints[k];

            for (let i = 0; i < paint.properties.length; i++) {
                const paintProperty = paint.properties[i];
                paintProperty.title !== "Legend" && this.map.setPaintProperty(paint.layerId, paintProperty.name, paintProperty.value);
            }
        }

        if (!this.props.mapState.addedInitialPaints && paints.length) {
            this.props.addedInitialPaints();
        }
    }

    changeLayouts(layouts) {
        for (let k = 0; k < layouts.length; k++) {
            let layout = layouts[k];
            for (let i = 0; i < layout.properties.length; i++) {
                let layoutProperty = layout.properties[i];
                try {
                    this.map.setLayoutProperty(layout.layerId, layoutProperty.name, layoutProperty.value);
                } catch (styleErr) {
                    console.log({ styleErr });
                }
            }
        }
        if (!this.props.mapState.addedInitialLayouts && layouts.length) {
            this.props.addedInitialLayouts();
        }
    }

    changeLayerZoomRanges(layerZooms) {
        for (let k = 0; k < layerZooms.length; k++) {
            let layerZoom = layerZooms[k];
            this.map.setLayerZoomRange(layerZoom.layerId, layerZoom.minZoom, layerZoom.maxZoom);
        }
    }

    jumpTo(options) {
        this.map.jumpTo(options);
    }

    fitBounds(bbox, options) {
        this.map.fitBounds(bbox, options);
    }

    addSourcesToStyle(newStyle, sources) {
        sources.forEach((source) => {
            let endpointName = source.type === DATA_TYPES.raster ? END_POINT.raster : END_POINT.vector;
            newStyle.sources[source.id] = {
                type: source.type === DATA_TYPES.raster ? DATA_TYPES.raster : DATA_TYPES.vector,
                tiles: [config.apiUrl + `api/${endpointName}/${source.id}/{z}/{x}/{y}`],
                bounds: source.bounds,
                minzoom: source.minZoom,
                maxzoom: source.maxZoom
            };
        });
    }

    addLayersToStyle(newStyle, mapLayers, paints, layouts) {
        const layers = mapLayers.map((l) => {
            const layer = {
                id: l.layerId,
                type: l.type,
                source: l.sourceId,
                "source-layer": l.sourceName,
                minzoom: l.minZoom,
                maxzoom: l.maxZoom,
                paint: paints[l.layerId].properties.reduce((acc, p) => {
                    if (p.title === "Legend") return acc;
                    acc[p.name] = p.value;
                    return acc;
                }, {}),
                layout: layouts[l.layerId].properties.reduce((acc, l) => {
                    acc[l.name] = l.value;
                    return acc;
                }, {})
            };
            return layer;
        });

        const geometricLayers = layers.filter((l) => l.type !== "symbol");
        const geometricStartIndex = newStyle.layers.findIndex((l) => l.id === HelperLayers.GeometricStartLayer);

        newStyle.layers.splice(geometricStartIndex, 0, ...geometricLayers.reverse());

        const symbolLayers = layers.filter((l) => l.type === "symbol");
        const styleStartIndex = newStyle.layers.findIndex((l) => l.id === HelperLayers.SymbolStartLayer);

        newStyle.layers.splice(styleStartIndex, 0, ...symbolLayers.reverse());
    }

    changeStyle(basemap) {
        getBasemapStyle(basemap).then((newStyle) => {
            const { sources, layers, paints, layouts } = this.props.mapState;
            this.addSourcesToStyle(newStyle, sources);
            this.addLayersToStyle(newStyle, layers, paints, layouts);
            removeInvalidLayers(newStyle);

            this.validateStyles();

            this.map.setStyle(newStyle);
        });
    }

    validateStyles() {
        const { layers, sources, paints, layouts } = this.props.mapState;
        const styleForValidation = this.buildStyleForValidation(layers, sources, paints, layouts);
        const errors = validate(styleForValidation);

        this.registerLayerErrors(errors, layers);
    }

    // Each style id flag that disappeared from the prev props represents a style that was fixed
    processErronousStylesChanges(prevLayerStylesErrorMap) {
        const currentLayerStylesErrorMap = this.props.layerStylesErrorMap;

        const fixedStylesIdMap = {};
        const pastErronousLayerKeys = Object.keys(prevLayerStylesErrorMap);

        pastErronousLayerKeys.forEach((invalidLayerKey) => {
            const pastErronousStyleKeys = Object.keys(prevLayerStylesErrorMap[invalidLayerKey]);

            // The entire app layer entry was removed, therefore all its styles were fixed
            if (currentLayerStylesErrorMap[invalidLayerKey] === undefined) {
                pastErronousStyleKeys.forEach((erronousStyleKey) => {
                    fixedStylesIdMap[erronousStyleKey] = true;
                });
                return;
            }
            // The entry wasn't deleted, therefore only a couple of styles were fixed

            pastErronousStyleKeys.forEach((pesk) => {
                if (currentLayerStylesErrorMap[invalidLayerKey][pesk] === undefined) fixedStylesIdMap[pesk] = true;
            });
        });

        // We add the fixed layer to the map
        if (Object.keys(fixedStylesIdMap).length) {
            const { layers, paints, layouts, zoomRanges } = this.props.mapState;

            const fixedLayers = layers.filter(({ layerId }) => fixedStylesIdMap[layerId]);

            this.addLayers(fixedLayers);
            this.changePaints(fixedLayers.map(({ layerId }) => paints[layerId]));
            this.changeLayouts(fixedLayers.map(({ layerId }) => layouts[layerId]));
            this.changeLayerZoomRanges(fixedLayers.map(({ layerId }) => zoomRanges[layerId]));
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.mapState.addedInitialLayouts &&
            this.props.mapState.addedInitialPaints &&
            (!prevProps.mapState.addedInitialLayouts || !prevProps.mapState.addedInitialPaints) &&
            Object.keys(this.props.layerStylesErrorMap).length === 0
        ) {
            this.validateStyles();
        }

        if (!this.state.mapLoaded) {
            return;
        }

        if (this.props.defaultBasemap?.url !== prevProps.defaultBasemap?.url) {
            this.changeStyle(this.props.defaultBasemap || defaultBasemap);
        }

        if (prevProps.mapState.sources.length < this.props.mapState.sources.length) {
            let newSources = this.props.mapState.sources.slice(prevProps.mapState.sources.length, this.props.mapState.sources.length)
            this.addSources(newSources);
        }

        if (prevProps.mapState.layers.length < this.props.mapState.layers.length) {
            this.addLayers(this.props.mapState.layers.slice(prevProps.mapState.layers.length, this.props.mapState.layers.length));
        }

        if (prevProps.mapState.layers.length > this.props.mapState.layers.length) {
            this.removeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        }

        if (prevProps.mapState.sources.length > this.props.mapState.sources.length) {
            this.removeSources(prevProps.mapState.sources, this.props.mapState.sources);
        }

        if (prevProps.mapState.layers !== this.props.mapState.layers && prevProps.mapState.layers.length !== 0) {
            this.changeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        }

        if (this.props.layerStylesErrorMap !== prevProps.layerStylesErrorMap) {
            this.processErronousStylesChanges(prevProps.layerStylesErrorMap);
        }

        if (prevProps.mapState.paints !== this.props.mapState.paints) {
            this.changePaints(Object.values(this.props.mapState.paints));
        }

        if (prevProps.mapState.layouts !== this.props.mapState.layouts) {
            this.changeLayouts(Object.values(this.props.mapState.layouts));
        }

        if (prevProps.mapState.zoomRanges !== this.props.mapState.zoomRanges) {
            this.changeLayerZoomRanges(Object.values(this.props.mapState.zoomRanges));
        }

        if (prevProps.mapState.jumpLocation !== this.props.mapState.jumpLocation) {
            this.jumpTo(this.props.mapState.jumpLocation);
        }

        if (this.state.mapBounds !== this.props.mapState.fitBounds) {
            this.fitBounds(this.props.mapState.fitBounds.bbox, this.props.mapState.fitBounds.options);
            this.setState({ mapBounds: this.props.mapState.fitBounds });
        }
    }

    render() {
        return <div id="map">{this.props.children}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        mapState: state.map,
        authToken: getToken(state),
        defaultBasemap: getDefaultBasemapSelector(state),
        layerStylesErrorMap: getLayerStyleErrorsMap(state),
        clientId: getClientId(state),
        projectId: getProjectId(state)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setPosition: (position) => dispatch(mapActions.setMapPosition(position)),
        setLayerStyleErrorsMap: (layerStyleIdsMap) => dispatch(appActions.setLayerStyleErrorsMap(layerStyleIdsMap)),
        addedInitialPaints: () => dispatch(mapActions.addedInitialPaints()),
        addedInitialLayouts: () => dispatch(mapActions.addedInitialLayouts())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Map));
