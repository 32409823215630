import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    activeUser: {
        border: `2px solid ${theme.customColors.successColor}`
    },
    inactiveUser: {
        border: `2px solid ${theme.customColors.errorColor}`
    },
    roleRadioGroup: {
        flexWrap: "nowrap",
        justifyContent: "flex-end"
    },
    searchFieldContainer: {
        padding: "12px 0 16px 0",
        maxWidth: 400
    },
    searchField: {
        minWidth: 340,
        "& .MuiFilledInput-input": {
            padding: 0,
            paddingLeft: 8
        },
        "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart": {
            marginTop: "0 !important"
        }
    }
}));
