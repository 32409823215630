import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Grid, IconButton, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import UploadIcon from "@mui/icons-material/Upload";
import RetryIcon from "@mui/icons-material/Replay";
import { UploadHandle } from "features/upload/models/UploadHandle";
import { useUploadRasterMutation } from "features/upload/api";
import { getPercentageCompleted } from "../../../utils";
import TypeChip from "components/TypeChip/TypeChip";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import UploadProgress from "../UploadProgress/UploadProgress";
import { isApiError } from "store/customBaseQuery";
import useComponentCancelToken from "app/hooks/useComponentCancelToken";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { removeUpload, updateUpload } from "../../../slice";
import { UploadStatus } from "../../../models/Upload";

type Props = {
    name: string;
    extension: ".tiff" | ".tif" | ".mbtiles";
    file: File;
};

const RasterUpload = forwardRef<UploadHandle, Props>(({ name, file, extension }: Props, ref) => {
    const [progress, setProgress] = useState(0);
    const [rasterName, setRasterName] = useState(name);
    const [uploadRaster, { isLoading, error, isError, reset }] = useUploadRasterMutation();
    const [cancelToken, regenerateCancelToken] = useComponentCancelToken();
    const dispatch = useAppDispatch();

    const isDoneUploading = progress === 100;
    const isPending = !isLoading && !isDoneUploading;

    const uploadProgressHandler = (e: ProgressEvent) => {
        const loaded = e.loaded;
        const total = e.total;

        setProgress(getPercentageCompleted(loaded, total));
    };

    const onRasterUpload = () => {
        if (isLoading || isDoneUploading) return;

        dispatch(updateUpload({ id: name, changes: { status: UploadStatus.Uploading } }));

        uploadRaster({
            name: rasterName,
            file,
            extension,
            cancelToken: cancelToken.token,
            uploadProgressHandler
        })
            .unwrap()
            .then((raster) =>
                dispatch(
                    updateUpload({
                        id: name,
                        changes: { status: UploadStatus.Uploaded, id: raster.id }
                    })
                )
            )
            .catch(() => dispatch(updateUpload({ id: name, changes: { status: UploadStatus.Failed } })));
    };

    const onUploadCancel = () => {
        cancelToken.cancel("Upload cancelled");
    };

    useImperativeHandle(ref, () => ({
        startUpload: onRasterUpload,
        cancelUpload: onUploadCancel
    }));

    const onUploadRetry = () => {
        setProgress(0);
        setRasterName(name);
        reset();
        regenerateCancelToken();
    };

    return (
        <Grid container spacing={1} mt={1} alignItems="center">
            <Grid item xs={"auto"} marginTop={1}>
                <TypeChip type="raster" variant="large" />
            </Grid>

            {isPending && !isError && (
                <>
                    <Grid item xs>
                        <TextField
                            label="Raster Name"
                            inputProps={{ autoComplete: "no" }}
                            variant="filled"
                            size="small"
                            fullWidth
                            placeholder="Raster Name"
                            value={rasterName}
                            onChange={(e) => setRasterName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs></Grid>
                </>
            )}

            {(isLoading || isDoneUploading || isError) && (
                <>
                    <Grid item xs={4}>
                        <ReadOnlyField label="Raster Name" text={rasterName} />
                    </Grid>
                    <Grid item xs>
                        <UploadProgress
                            progress={progress}
                            isLoading={isLoading}
                            error={isError}
                            errorMessage={isApiError(error) ? error.message : "An unexpected error occured"}
                        />
                    </Grid>
                </>
            )}

            <Grid item xs="auto" sx={{ "&:empty": { paddingLeft: 0, flex: 0 } }}>
                {isPending && !isError && (
                    <IconButton size="small" color="primary" onClick={onRasterUpload}>
                        <UploadIcon />
                    </IconButton>
                )}

                {isLoading && (
                    <IconButton size="small" color="error" onClick={onUploadCancel}>
                        <CancelIcon />
                    </IconButton>
                )}

                {isError && (
                    <IconButton size="small" color="primary" onClick={onUploadRetry}>
                        <RetryIcon />
                    </IconButton>
                )}

                {(isPending || isError) && (
                    <IconButton size="small" color="error" onClick={() => dispatch(removeUpload(name))}>
                        <DeleteIcon />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
});

export default RasterUpload;
