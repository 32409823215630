import { GisUser } from "./model/GisUser";
import { CoreUser } from "./model/CoreUser";
import { CoreRole } from "./model/CoreRole";
import { GisUserWithGroupPermissions } from "./model/GisUserWithGroupPermissions";
import baseAtlasApi from "../../store/baseAtlasApi";
import { atlasApiTags as tags } from "store/atlasApiTags";
import axiosClient from "../../actions/apiClient";

export const exportApplicationPermissions = () =>
    axiosClient
        .get(`user/exportPermissions/applications/`, {
            responseType: "blob"
        })
        .then((res: any) => res.data);

export const usersApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        fetchExistingUsers: build.query<GisUser[], void>({
            query: () => ({ url: "user/existing", method: "GET" }),
            providesTags: [tags.userTags.GIS_USER]
        }),
        fetchPendingUsers: build.query<CoreUser[], void>({
            query: () => ({ url: "user/pending", method: "GET" })
        }),
        fetchUserByEmail: build.query<GisUserWithGroupPermissions, string>({
            query: (email) => ({ url: `user/${email}`, method: "GET" }),
            providesTags: (result) => (result ? [{ type: tags.userTags.USER_DETAILS, id: result.username }] : [])
        }),
        updateUserByEmail: build.mutation<GisUser, Pick<GisUser, "username" | "role">>({
            query: ({ username, role }) => ({
                url: `user/${username}`,
                method: "PUT",
                data: { role }
            }),
            async onQueryStarted({ username, role }, { dispatch, queryFulfilled }) {
                const updateUserWithGroups = dispatch(
                    usersApi.util.updateQueryData("fetchUserByEmail", username, (draft) => {
                        draft.role = role;
                    })
                );

                const updateExisting = dispatch(
                    usersApi.util.updateQueryData("fetchExistingUsers", undefined, (draft) => {
                        const user = draft.find((u) => u.username === username);

                        if (user) {
                            user.role = role;
                        }
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    updateUserWithGroups.undo();
                    updateExisting.undo();
                }
            }
        }),
        bulkCreateUsers: build.mutation<GisUser[], GisUser[]>({
            query: (users) => ({
                url: "user/bulk",
                method: "POST",
                data: { users }
            }),
            async onQueryStarted(users, { dispatch, queryFulfilled }) {
                const updateExistingUsers = dispatch(
                    usersApi.util.updateQueryData("fetchExistingUsers", undefined, (draft) => [
                        ...draft,
                        ...users.map<GisUser>((u) => ({ ...u, coreAccountType: CoreRole.ClientUser }))
                    ])
                );

                const updatePendingUsers = dispatch(
                    usersApi.util.updateQueryData("fetchPendingUsers", undefined, (draft) =>
                        draft.filter((coreUser) => !users.some((gisUser) => gisUser.username === coreUser.username))
                    )
                );

                try {
                    await queryFulfilled;
                } catch {
                    updateExistingUsers.undo();
                    updatePendingUsers.undo();
                }
            }
        })
    })
});

export const {
    useFetchExistingUsersQuery,
    useFetchPendingUsersQuery,
    useFetchUserByEmailQuery,
    useUpdateUserByEmailMutation,
    useBulkCreateUsersMutation,
    usePrefetch: useUserPrefetch
} = usersApi;

export const invalidateGisUserCache = () => usersApi.util.invalidateTags([tags.userTags.GIS_USER]);

export const invalidateUserDetailsCache = () => usersApi.util.invalidateTags([tags.userTags.USER_DETAILS]);

export const invalidateUserDetailsCacheByEmail = (email: string) =>
    usersApi.util.invalidateTags([
        {
            type: tags.userTags.USER_DETAILS,
            id: email
        }
    ]);
