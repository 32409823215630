import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import baseAtlasApi from "./baseAtlasApi";
import baseDprApi from "./baseDprApi";

type WindowWithReduxDev = typeof window & { __REDUX_DEVTOOLS_EXTENSION__: boolean };

// As an alternative to using the default middlewares, we need to enforce a tuple constraint on the middleware array
// and change the type of the thunk middlewares
// Ex: middleware: [thunk as ThunkMiddleware<RootState, AnyAction>, authMiddleware, signalRMiddleware] as const,
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            immutableCheck: false,
            serializableCheck: false
        }).concat(baseAtlasApi.middleware, baseDprApi.middleware),
    devTools: process.env.NODE_ENV !== "production" && (window as WindowWithReduxDev).__REDUX_DEVTOOLS_EXTENSION__
});
