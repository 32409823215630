import { CACHE_STATES } from "./constants/cacheStates";

export function toCacheStatusString(cacheStatus) {
    switch (cacheStatus) {
        case 0:
            return CACHE_STATES.uncached;
        case 1:
            return CACHE_STATES.caching;
        case 2:
            return CACHE_STATES.cached;
        default:
            return CACHE_STATES.uncached;
    }
}
