import { Box, Button, FormControlLabel, Switch } from "@mui/material";
import { FC, useState } from "react";
import { Handle, Range } from "rc-slider";
import RcTooltip from "rc-tooltip";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import UpdateIcon from "@mui/icons-material/Save";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getApp } from "selectors/appData";
import { APP_HIGH_PERSPECTIVE_CONFIG } from "utils/constants/appDefaults";
import { getMapPosition } from "selectors/map";
import { setAppRestrictedView } from "reducers/appData/appData";

type ApplicationBoundingBox = {
    id: number;
    maxZoom: number;
    minZoom: number;
    mapBounds: number[][];
    enabled: boolean;
};

const MIN_ZOOM = 0;
const MAX_ZOOM = 24;

const RestrictedViewSection: FC = () => {
    const app = useAppSelector(getApp);
    const appRestrictedView = app.restrictedView;
    const mapPosition = useAppSelector(getMapPosition);

    const [restrictedView, setRestrictedView] = useState({
        minZoom: appRestrictedView ? appRestrictedView.minZoom : MIN_ZOOM,
        maxZoom: appRestrictedView ? appRestrictedView.maxZoom : MAX_ZOOM,
        mapBounds: appRestrictedView ? appRestrictedView.mapBounds : APP_HIGH_PERSPECTIVE_CONFIG.mapBounds,
        enabled: appRestrictedView ? appRestrictedView.enabled : false
    });

    const styles = useStyles();
    const dispatch = useAppDispatch();

    const handle = (props: any) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <RcTooltip key={index} prefixCls="rc-slider-tooltip" overlay={value} placement="top">
                <Handle value={value} {...restProps} />
            </RcTooltip>
        );
    };

    const boundsToString = (bounds: any) => {
        return `${bounds[0][0].toFixed(2)} ${bounds[0][1].toFixed(2)}, ${bounds[1][0].toFixed(2)} ${bounds[1][1].toFixed(2)}`;
    };

    const onCaptureBoundingBox = () => {
        dispatch(setAppRestrictedView({ ...restrictedView, mapBounds: mapPosition.bounds }));
        setRestrictedView((curr) => ({ ...curr, mapBounds: mapPosition.bounds }));
    };

    const onZoomChange = (value: any) => {
        dispatch(setAppRestrictedView({ ...restrictedView, minZoom: value[0], maxZoom: value[1] }));
        setRestrictedView((curr) => ({ ...curr, minZoom: value[0], maxZoom: value[1] }));
    };

    const onToggleEnable = () => {
        dispatch(setAppRestrictedView({ ...restrictedView, enabled: !restrictedView.enabled }));
        setRestrictedView((curr) => ({ ...curr, enabled: !curr.enabled }));
    };

    return (
        <>
            <FormControlLabel
                control={<Switch edge="end" checked={restrictedView.enabled} inputProps={{ "data-testid": zoomRangeId } as DataTestIdProps} onClick={onToggleEnable} />}
                label={<Box marginLeft={1}>Restrict View</Box>}
            />

            <CustomTypography variant="subtitle2" textWeight="bold">
                Zoom Level
            </CustomTypography>
            <div className={styles.sliderContainer}>
                <Range
                    max={24}
                    min={0}
                    handle={handle}
                    pushable={2}
                    value={[restrictedView.minZoom, restrictedView.maxZoom]}
                    onChange={onZoomChange}
                    disabled={!restrictedView.enabled}
                />
                <div className="slider-footer">
                    <span>
                        <CustomTypography variant="body2" textWeight="bold">
                            {restrictedView.minZoom}
                        </CustomTypography>
                    </span>
                    <span>
                        <CustomTypography variant="body2" textWeight="semibold">
                            {restrictedView.maxZoom}
                        </CustomTypography>
                    </span>
                </div>
            </div>

            <div className="flex">
                <div className="flex-grow">
                    <ReadOnlyField label={"Bounds"} text={boundsToString(restrictedView.mapBounds)} />
                </div>
            </div>

            <Button
                onClick={onCaptureBoundingBox}
                className={styles.maxBoundsButton}
                disabled={!restrictedView.enabled}
                variant="text"
                color="primary"
                data-testid={captureMaxBoundsId}
            >
                <UpdateIcon className="left-icon" fontSize="small" /> Capture Max Bounds
            </Button>
        </>
    );
};

export default RestrictedViewSection;

type DataTestIdProps = React.InputHTMLAttributes<HTMLInputElement> & {
    "data-testid"?: string;
};

const captureMaxBoundsId = "qa-restricted-view-capture-bounding-box-button";
const zoomRangeId = "qa-restricted-view-";
