import useCollapsibleCardStyles from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, Collapse, Box } from "@mui/material";
import { FCWC } from "react";

type Props = (
    | {
          title?: string;
          id?: never;
          Component?: never;
      }
    | {
          title?: never;
          id?: string;
          Component?: JSX.Element;
      }
) & {
    innerPadding?: number;
    maxHeight?: number;
    paddingBottom?: number;
    onToggleClick: () => void;
    isToggled: boolean;
    unmountOnExit?: boolean;
    paddingInline?: number;
};

const CollapsibleCard: FCWC<Props> = ({
    innerPadding = 2,
    paddingBottom = 2,
    paddingInline = 2,
    maxHeight,
    title,
    onToggleClick,
    isToggled,
    unmountOnExit,
    children,
    id,
    Component
}) => {
    const classes = useCollapsibleCardStyles();

    const getDataTestId = () => {
        const testId = title ?? id ?? "";
        return collapsibleCardTestId + testId.toLocaleLowerCase().replaceAll(" ", "-");
    };

    const TitleComponent = !!Component ? Component : <Box width="100%">{title ?? ""}</Box>;

    return (
        <div className={classes.collapsibleCard}>
            <div className={classes.collapsibleCardHeader} onClick={onToggleClick}>
                {TitleComponent}
                <ExpandMoreIcon data-testid={getDataTestId()} className={isToggled ? classes.expandedLeft : classes.collapsed} />
            </div>
            <Collapse in={isToggled} unmountOnExit={unmountOnExit}>
                <Divider className={classes.border} />
                <Box padding={innerPadding} paddingBottom={paddingBottom} maxHeight={maxHeight} paddingX={paddingInline}>
                    <div>{children}</div>
                </Box>
            </Collapse>
        </div>
    );
};

export default CollapsibleCard;

const collapsibleCardTestId = "qa-collapsible-card-";
