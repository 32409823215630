import { makeStyles } from "@mui/styles";

export const useDatasetRowStyles = makeStyles((theme) => ({
    cachedIcon: {
        width: 16
    },

    nameCell: {
        "&:hover": {
            textDecoration: "underline"
        }
    },
    icon: {
        marginLeft: 16
    },
    validMetadataIcon: {
        background: `radial-gradient(${theme.customColors.successColor} 50%,transparent 0,transparent)`,
        color: theme.customColors.successColorLight
    },
    invalidMetadataIcon: {
        background: `radial-gradient(${theme.customColors.errorColor} 50%,transparent 0,transparent)`,
        color: theme.customColors.errorColorLight
    },
    selectedRow: {
        backgroundColor: theme.customColors.secondaryColor
    },
    link: {
        display: "flex",
        width: "100%"
    }
}));
