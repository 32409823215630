import { isGroup } from "@emblautec/rescursive-array-extensions";
import React, { useEffect, useRef } from "react";
import { List, AutoSizer } from "react-virtualized";
import usePrevious from "../../../../../utils/customHooks/usePrevious";
import Group from "../group";
import Layer from "../layer";

const ROOT_GROUP_PADDING = 8;
const ROOT_GROUP_HEIGHT = 48;

const ROOT_GROUP_TOTAL_HEIGHT = ROOT_GROUP_PADDING + ROOT_GROUP_HEIGHT;

const GROUP_EDIT_HEIGHT = 43;

const GROUP_HEIGHT = 24;
const GROUP_MARGIN = 16;

const DROP_HERE_HELPER = 32;

const DROP_HERE_HELPER_MARGIN = 8;

const GROUP_TOTAL_HEIGHT = GROUP_HEIGHT + GROUP_MARGIN;

const ROOT_GROUP_CHILDREN_PADDING = 16;
const INNER_GROUP_CHILDREN_PADDING = 0; //it is actually an 8, but for some reson the mui-collapse-wrapperIn supresses it

const LAYER_HEIGHT = 24;
const LAYER_PADDING = 16;

const LAYER_TOTAL_HEIGHT = LAYER_HEIGHT + LAYER_PADDING;

const LAYER_BORDER = 8;

const VirtualizedLayerGroups = ({ layerGroups, selectedGroupId, shouldRecompute }) => {
    const list = useRef(null);
    const indexes = useRef([]);

    const prevSelectedGroupId = usePrevious(selectedGroupId);

    useEffect(() => {
        if (!!indexes.current.length) {
            indexes.current.forEach((index) => {
                recomputeRowHeights(index);
            });
            indexes.current = [];
        }
    }, [layerGroups]);

    useEffect(() => {
        let selectedGroupIndex = -1,
            prevSelectedGroupIndex = -1;

        for (let index = 0; index < layerGroups.length; index++) {
            const res = layerGroups[index];
            if (isGroup(res)) {
                const selectedGroup = res.layers.getRecursive(selectedGroupId);
                const prevSelectedGroup = res.layers.getRecursive(prevSelectedGroupId);
                if (selectedGroup || res.resourceId === selectedGroupId) selectedGroupIndex = index;
                if (prevSelectedGroup || res.resourceId === prevSelectedGroupId) prevSelectedGroupIndex = index;
            }
        }
        if (selectedGroupIndex !== -1) recomputeRowHeights(selectedGroupIndex);
        if (prevSelectedGroupIndex !== -1) recomputeRowHeights(prevSelectedGroupIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroupId]);

    useEffect(() => {
        for (let index = 0; index < layerGroups.length; index++) {
            const res = layerGroups[index];
            if (isGroup(res)) {
                recomputeRowHeights(index);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRecompute]);

    const recomputeRowHeights = (index) => list.current?.recomputeRowHeights(index);
    const markIndexForRecomputation = (index) => indexes.current.push(index);

    const renderResource = (resource, index) => {
        if (isGroup(resource)) {
            const vListGroupTools = { vListIndex: index, markIndexForRecomputation, recomputeRowHeights };
            return <Group group={resource} depth={0} vListGroupTools={vListGroupTools} key={resource.resourceId} />;
        } else {
            const vListLayerTools = { vListIndex: index, markIndexForRecomputation };
            return <Layer layer={resource} depth={0} vListLayerTools={vListLayerTools} key={resource.resourceId} />;
        }
    };

    const cellRenderer = ({ index, key, parent, style }) => {
        const renderedCell = renderResource(layerGroups[index], index);

        return (
            <div key={key} style={style} className="virtual-item">
                {renderedCell}
            </div>
        );
    };

    const getItemHeight = (resource) => {
        if (isGroup(resource)) {
            return getItemCountRecursive(resource);
        }
        return LAYER_TOTAL_HEIGHT + LAYER_BORDER;
    };

    const itemHeight = (params) => {
        if (params.index === layerGroups.length - 1) {
            //We add +4 to the last item in order to be able to display the blue hover line when dragging an object over it.
            return getItemHeight(layerGroups[params.index]) + 4;
        }
        return getItemHeight(layerGroups[params.index]);
    };

    function recurseItemCount(layerGroup, heightObj, depth = 0) {
        if (!layerGroup.layers.length) {
            heightObj.height += DROP_HERE_HELPER;
            if (depth > 0) heightObj.height += DROP_HERE_HELPER_MARGIN;
        }

        if (layerGroup.resourceId === selectedGroupId) {
            heightObj.height += GROUP_EDIT_HEIGHT;
        }

        //Stop counting if group is collapsed
        if (layerGroup.options.isCollapsed || !layerGroup.layers.length) {
            return;
        }

        heightObj.height += depth > 0 ? INNER_GROUP_CHILDREN_PADDING : ROOT_GROUP_CHILDREN_PADDING;

        for (let i = 0; i < layerGroup.layers.length; i++) {
            let layer = layerGroup.layers[i];

            if (isGroup(layer)) {
                heightObj.height += GROUP_TOTAL_HEIGHT;
                depth++;
                recurseItemCount(layer, heightObj, depth);
            } else {
                heightObj.height += LAYER_TOTAL_HEIGHT;
            }
        }
    }

    const getItemCountRecursive = (item) => {
        //initial height of a group(collapsed or not)
        let heightObj = { height: ROOT_GROUP_TOTAL_HEIGHT };

        //if it is not collapsed, we add the height of the divider
        if (!item.options.isCollapsed && item.layers.length) heightObj.height++;

        recurseItemCount(item, heightObj);
        return heightObj.height;
    };

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    height={height}
                    ref={list}
                    overscanRowCount={1}
                    rowHeight={itemHeight}
                    rowRenderer={cellRenderer}
                    rowCount={layerGroups.length}
                    width={width}
                    containerProps={{ "data-testid": layerGroupsListTestId }}
                />
            )}
        </AutoSizer>
    );
};

export default VirtualizedLayerGroups;

const layerGroupsListTestId = "qa-layer-groups-list-id";
