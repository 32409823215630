import { MetadataProperty } from "./models/MetadataProperty";
import { TagValue } from "./models/TagValue";
import { MetadataFields } from "./models/MetadataFields";
import { MetadataPropertyValue } from "./models/MetadataPropertyValue";

export const getMetadataPropertyDefaultValue = (metadataProperty: MetadataProperty) => {
    switch (metadataProperty.type) {
        case "text":
            return "";
        case "number":
            return "";
        case "date":
            return null;
        case "tag_list":
            return metadataProperty.value.map<TagValue>((t) => ({ id: t.id, enabled: false }));
    }
};

export const metadataFieldsToPropertyValues = (fields: MetadataFields, schema: MetadataProperty[]) => {
    const propertyNames = Object.keys(fields);

    return propertyNames.map<MetadataPropertyValue>((propertyName) => {
        const value = fields[propertyName];
        const propertySchema = schema.find((property) => property.name === propertyName);

        if (!propertySchema) throw new Error("Couldn't find metadata type");

        const id = propertySchema.id;
        const type = propertySchema.type;

        switch (type) {
            case "text":
                return { id, type, value: value as string };
            case "number":
                return { id, type, value: Number(value) };
            case "tag_list":
                return { id, type, value: value as TagValue[] };
            case "date":
                return { id, type, value: value as Date | null };
        }
    });
};
