import { useFetchGroupApplicationsPermissionsQuery } from "features/groups/api";
import { permissionTypes, permissions, resourceTypes } from "utils/constants/permissionTypes";
import ResourcePermissionsList from "features/groups/components/GroupEditView/components/ResourcePermissionsList/ResourcePermissionsList";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";

type GroupApplicationssPermissionsContainerProps = {
    groupId: string;
    onChangesMade: () => void;
};

const GroupApplicationsPermissionsContainer = ({ groupId, onChangesMade }: GroupApplicationssPermissionsContainerProps) => {
    const {
        data: appPermissions = [],
        isFetching: areAppPermissionsLoading,
        refetch: refetchAppPermissions,
        isError: isAppPermissionsError
    } = useFetchGroupApplicationsPermissionsQuery(groupId);

    const filteredPermissions = permissionTypes.filter((p) => p !== permissions.READ_WO_DOWNLOAD);

    return (
        <LoadingPlaceholder loading={areAppPermissionsLoading} linearProgress>
            <ErrorPlaceholder error={isAppPermissionsError} message="Encountered an error while getting your data" textVariant="h5" onTryAgain={refetchAppPermissions}>
                <ResourcePermissionsList
                    resources={appPermissions}
                    permissions={filteredPermissions}
                    groupId={groupId}
                    onChangesMade={onChangesMade}
                    resourceType={resourceTypes.APPLICATION}
                />
            </ErrorPlaceholder>
        </LoadingPlaceholder>
    );
};

export default GroupApplicationsPermissionsContainer;
