import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import { Box, Grid, TablePagination, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import UpArrow from "../utils/icons/arrow-up.svg";
import DownArrow from "../utils/icons/arrow-down.svg";
import CustomTypography from "../components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";

const ListView = ({
    searchPlaceholder,
    data,
    columns,
    filterFunction,
    generateItem,
    righthandSearchComponent = undefined,
    hideBottomBar,
    showContextMenuFiller,
    searchBarProps = undefined,
    tablePaginationProps = undefined,
    startSortingReverse = false
}) => {
    const classes = useStyles();

    const sortings = columns.reduce((a, b) => {
        a[b.name] = b.sortingFunction;
        return a;
    }, {});

    const [searchString, setsearchString] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [sorting, setSorting] = useState(columns.filter((x) => x.start)[0].name);
    const [sortingReverse, setSortingReverse] = useState(startSortingReverse);

    const [isBarVisible, setIsBarVisible] = useState(false);
    const bottomBarRef = React.createRef();

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const bottomBar = entries[0];
            if (bottomBar.target.scrollHeight && bottomBar.target.clientHeight && bottomBar.target.scrollWidth && bottomBar.target.clientWidth)
                setIsBarVisible(bottomBar.target.scrollHeight > bottomBar.target.clientHeight && !(bottomBar.target.scrollWidth > bottomBar.target.clientWidth));
        });
        const oldRef = bottomBarRef.current;
        observer.observe(oldRef);
        return () => oldRef && observer.unobserve(oldRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBarVisible]);

    useEffect(() => {
        setPage(0);
        setRowsPerPage(20);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function upArrowClass(column) {
        return `arrow-up ${sorting === column && !sortingReverse ? "permanently-visible" : "visible-on-hover"} ${sorting === column && sortingReverse ? "visible-on-hover" : ""}`;
    }

    function downArrowClass(column) {
        return `arrow-down ${sorting === column && sortingReverse ? "permanently-visible" : ""} ${sorting === column && !sortingReverse ? "visible-on-hover" : ""}`;
    }

    function onSetSort(column) {
        setSortingReverse(sorting === column.name ? !sortingReverse : false);
        setSorting(column.name);
    }

    function onSearchChanged(e) {
        if (searchString === "") {
            setPage(0);
        }

        setsearchString(e.target.value);
    }

    function onClearSearchText() {
        setsearchString("");
        setPage(0);
    }

    function onRowsPerPageChange(e) {
        setPage(Math.floor((page * rowsPerPage) / e.target.value));
        setRowsPerPage(e.target.value);
        bottomBarRef.current.scrollTop = 0;
    }

    function handlePageChange(page) {
        setPage(page);
        bottomBarRef.current.scrollTop = 0;
    }

    const getColumnDataTestId = (column) => {
        const columnName = column.name.toLocaleLowerCase().replaceAll(" ", "-");
        return columnTestId + columnName;
    };

    const filteredData = data.filter((x) => filterFunction(x, searchString));

    let sliceStart = page * rowsPerPage;
    let SliceEnd = page * rowsPerPage + rowsPerPage;

    let items = filteredData
        .sort(sortings[sorting](sortingReverse))
        .slice(sliceStart, SliceEnd)
        .map((item, index) => generateItem(item, index));

    return (
        <div className="list-view">
            <Grid container className={classes.searchContainer}>
                <Grid item xs>
                    <TextField
                        className={classes.searchField}
                        id="full-width"
                        inputProps={{ "data-testid": searchbarTestId, autoComplete: "off" }}
                        fullWidth
                        placeholder={searchPlaceholder}
                        value={searchString}
                        onChange={onSearchChanged}
                        variant="filled"
                        size="small"
                        hiddenLabel
                        {...searchBarProps}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon className={classes.searchIcon} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton className={`${classes.clearTextButton} ${searchString.length > 0 ? classes.visible : ""}`} onClick={onClearSearchText} size="large">
                                        <CloseIcon className={classes.searchIcon} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                {righthandSearchComponent && (
                    <Grid item xs={7} container alignContent="center" justifyContent="end">
                        {righthandSearchComponent}
                    </Grid>
                )}
            </Grid>
            <div className="pagination">
                <Box marginLeft="auto">
                    <TablePagination
                        sx={{ "& .MuiToolbar-root": { borderBottom: 0 } }}
                        rowsPerPageOptions={[10, 20, 30, 100, 250]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "Previous Page",
                            "data-testid": backIconButtonTestId,
                            ...tablePaginationProps?.backIconButtonProps
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next Page",
                            "data-testid": nextIconButtonTestId,
                            ...tablePaginationProps?.nextIconButtonProps
                        }}
                        SelectProps={{
                            "data-testid": rowsPerPageSelectTestId
                        }}
                        onPageChange={(e, page) => handlePageChange(page)}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Box>
            </div>
            <div className="scroll-container" ref={bottomBarRef}>
                <div className="sort-toolbar">
                    {columns.map((column, columnIndex) => {
                        return (
                            <Tooltip key={columnIndex} id="tooltip-top" title={`Sort by ${column.name}`} arrow>
                                <div
                                    className={(sorting === column.name ? "button active " : "button ") + column.type}
                                    onClick={() => onSetSort(column)}
                                    data-testid={getColumnDataTestId(column)}
                                >
                                    {column.icon ? (
                                        column.icon
                                    ) : (
                                        <CustomTypography variant="body2" textWeight="bold" {...column.otherProps}>
                                            {column.name}
                                        </CustomTypography>
                                    )}

                                    <div className="sort-icon">
                                        <img alt="" src={UpArrow} className={upArrowClass(column.name)} />
                                        <img alt="" src={DownArrow} className={downArrowClass(column.name)} />
                                    </div>
                                </div>
                            </Tooltip>
                        );
                    })}
                    {showContextMenuFiller && <div className="filler"></div>}
                </div>
                <div className="item-container">{items.length === 0 ? <div className="no-data-indicator">No data</div> : items}</div>
            </div>
            {isBarVisible && !hideBottomBar && <div className="bottom-container"></div>}
        </div>
    );
};

ListView.defaultProps = {
    hideBottomBar: false
};

export default ListView;

const searchbarTestId = "qa-searchbar";
const backIconButtonTestId = "qa-list-view-pagination-back-icon-button";
const nextIconButtonTestId = "qa-list-view-pagination-next-icon-button";
const rowsPerPageSelectTestId = "qa-list-view-pagination-rows-per-page-select";
const columnTestId = "qa-list-view-table-head-column-";
