import { AppBar, Toolbar } from "@mui/material";
import ProjectsPicker from "features/core/components/ProjectsPicker/ProjectsPicker";
import { useStyles } from "./styles";
import config from "config";

const Header = () => {
    const classes = useStyles();
    return (
        <AppBar position="static">
            <Toolbar className={classes.toolbar}>
                <a href={config.landingUrl} className={classes.link}>
                    <img className={classes.logo} src="/Logo.svg" alt="Lautec WindGIS"></img>
                </a>
                <ProjectsPicker />
            </Toolbar>
        </AppBar>
    );
};

export default Header;
