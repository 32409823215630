import AddIcon from "@mui/icons-material/Add";
import { TextField } from "@mui/material";
import { Formik } from "formik";
import { getColorString } from "../../../../../../utils/metadataUtils";
import { TagSchema } from "../../../../../../utils/validators/metadata";
import ColorPopover from "../ColorPopover/ColorPopover";

const TagsCreator = ({ tags, onAddTag }) => {
    const onColorChange = (formikProps) => (color) => formikProps.setFieldValue("color", getColorString(color));

    return (
        <div className="tag-creator">
            <div className="tag-list">
                <div className="tag-create-container">
                    <Formik initialValues={{ name: "", color: "red" }} onSubmit={onAddTag} validationSchema={TagSchema(tags)}>
                        {(formikProps) => (
                            <>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder="Add tag"
                                    value={formikProps.values.name}
                                    label="Tag name"
                                    error={!!formikProps.errors.name}
                                    variant="outlined"
                                    name="name"
                                    onChange={formikProps.handleChange}
                                    helperText={formikProps.errors.name}
                                />
                                <ColorPopover color={formikProps.values.color} onColorChanged={onColorChange(formikProps)} />
                                <div onClick={formikProps.submitForm} className="add-tag-btn">
                                    <AddIcon />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default TagsCreator;
