import { useState } from "react";
import { Button, Divider } from "@mui/material";
import PublishIcon from "@mui/icons-material/Public";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useAppEditFooterStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getAppCacheStatusSelector, getMapSources } from "../../../../../selectors/map";
import { appHasStyleErrorsSelector, getLastSaveDatasetCount, getApp, getLayerGroups, getLayerStylesMap, getHasUnsavedChanges } from "../../../../../selectors/appData";
import * as appsActions from "../../../../../actions/apps";
import * as appDataActions from "../../../../../reducers/appData/appData";
import config from "../../../../../config";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import { handleError } from "../../../../../utils/networkErrorUtils";
import CustomDialog from "../../../../../components/CustomModal/CustomModal";
import CustomTypography from "../../../../../components/CustomTypography/CustomTypography";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { PermissionType } from "features/groups/model/PermissionType";
import AppPublishModal from "./AppPublishModal";
import { IsResourceModified } from "../../../../../utils/localStorageUtils";
import { makeSourceFromDataset, makeSourceFromMap } from "../../../../../utils/creators/SourceCreators";
import { addMapSources } from "../../../../../reducers/map";

const AppEditFooter = () => {
    const classes = useAppEditFooterStyles();

    const [errorInfoDialogOpen, setErrorInfoDialogOpen] = useState(false);
    const [appPublishModalOpen, setAppPublishModalOpen] = useState(false);
    const mapSources = useSelector(getMapSources);
    const lastSaveDatasetCount = useSelector(getLastSaveDatasetCount);
    const cacheStatus = useSelector(getAppCacheStatusSelector);
    const app = useSelector(getApp);
    const layerGroups = useSelector(getLayerGroups);
    const layerStylesMap = useSelector(getLayerStylesMap);
    const appCanBeSaved = !useSelector(appHasStyleErrorsSelector);
    const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
    const { clientId, projectId } = useParams();

    const dispatch = useDispatch();

    const onNavigateToViewer = () => {
        const publicRoute = app.public ? "public/" : "";
        const url = config.viewerUrl;
        window.open(`${url}${clientId}/${projectId}/${publicRoute}${app.id}/map/layer-selector`, "_blank");
    };

    const onPublishApp = () => {
        setAppPublishModalOpen(true);
    };

    const saveAppAttempt = () => {
        if (appCanBeSaved) {
            saveApp();
        } else {
            setErrorInfoDialogOpen(true);
        }
    };

    const saveApp = () => {
        // //remove unneeded properties from layer
        function replacer(key, value) {
            if (key === "visible") {
                return undefined;
            }
            if (key === "totalLayersCount") {
                return undefined;
            }
            if (key === "visibleLayersCount") {
                return undefined;
            }
            return value;
        }

        let updatedApp = {
            name: app.name,
            applicationConfig: JSON.stringify(
                { ...app.configJson, layerGroups: layerGroups.mapLayersRecursive((layer) => ({ ...layer, styles: layerStylesMap[layer.resourceId] })) },
                replacer
            ),
            modifiedUtc: app.modifiedUtc,
            overwriteData: false,
            orderedBasemaps: app.basemaps.map((x) => x.id),
            languages: app.languages.map((l) => l.code),
            tools: app.tools.filter((t) => t.enabled).map((t) => t.name),
            widgets: app.widgets.filter((w) => w.enabled).map((w) => w.name),
            isSidebarCollapsed: app.sidebarCollapsed,
            mapBounds: app.mapBounds,
            restrictedView: app.restrictedView
        };

        handleSave(app.id, updatedApp);
    };

    const handleSave = (appId, updatedApp) => {
        dispatch(appsActions.updateApp({ applicationId: appId, request: updatedApp }))
            .then(unwrapResult)
            .then((res) => {
                toastr.success("App saved");
                res.maps.forEach((map) => {
                    const modifiedDate = new Date(map.modifiedUtc);
                    const sources = [];
                    if (IsResourceModified(map.id, modifiedDate)) {
                        caches.delete(map.id);
                        localStorage.setItem(map.id, map.modifiedUtc);
                    }

                    if (!mapSources.some((ms) => ms.id === map.id)) {
                        sources.push(makeSourceFromMap(map));
                    }
                    if (map.cacheStatus !== 2) {
                        map.datasets.forEach((dataset) => {
                            if (!mapSources.some((ms) => ms.id === dataset.id)) {
                                sources.push(makeSourceFromDataset(dataset));
                            }
                        });
                    }

                    dispatch(addMapSources(sources));
                });
            })
            .catch((err) => {
                if (err.response.status === 409) handleSaveOverwrite(appId, updatedApp);
                else handleError(err);
            });
    };

    const handleSaveOverwrite = (appId, updatedApp) => {
        const toastrConfirmOptions = {
            onOk: () => {
                updatedApp.overwriteData = true;
                handleSave(appId, updatedApp);
            },
            onCancel: () => { }
        };
        toastr.confirm("Other app changes appeared in the database. Do you want to overwrite them?", toastrConfirmOptions);
    };

    const saveAppDisabledReason = () => {
        switch (true) {
            case cacheStatus === 0: //We want to allow saving if one or more of the maps for the app was not cached succesfully
                return "";
            case cacheStatus === 1:
                return "Wait for the app to finish the caching process";
            case !hasUnsavedChanges:
                return "No changes were made";
            default:
                return "";
        }
    };

    const publishAppDisabledReason = () => {
        switch (true) {
            case lastSaveDatasetCount === 0:
                return "Latest saved app version didn't contain any layers, therefore it can't be published. Add some layers and save the app in order to publish it";
            case cacheStatus === 0:
                return "Save the app before publishing it";
            case cacheStatus === 1:
                return "Wait for the app to finish the caching process";
            default:
                return "";
        }
    };

    const handleDialogClose = () => {
        setErrorInfoDialogOpen(false);
    };

    return (
        <>
            <Tooltip title={app.permissionType < PermissionType.WRITE_READ ? "" : saveAppDisabledReason()}>
                <div>
                    <Button
                        disabled={app.permissionType < PermissionType.WRITE_READ || !!saveAppDisabledReason()}
                        className={classes.marginRight}
                        variant="contained"
                        color="primary"
                        onClick={saveAppAttempt}
                        data-testid={saveButtonTestId}
                    >
                        <SaveIcon className="left-icon" />
                        Save
                    </Button>
                </div>
            </Tooltip>
            <Tooltip title={publishAppDisabledReason()}>
                <div>
                    <Button
                        disabled={app.permissionType < PermissionType.WRITE_READ || !!publishAppDisabledReason()}
                        className={classes.marginRight}
                        variant="contained"
                        color="secondary"
                        onClick={onPublishApp}
                        data-testid={publishButtonTestId}
                    >
                        <PublishIcon className="left-icon" />
                        Publish
                    </Button>
                </div>
            </Tooltip>

            <Divider />
            <Tooltip title={!app.isPublished ? "You must first publish the app" : ""}>
                <div>
                    <Button disabled={!app.isPublished} onClick={onNavigateToViewer} variant="text" color="primary" data-testid={viewPublishedButtonTestId}>
                        <VisibilityIcon className="left-icon" />
                        View Published
                    </Button>
                </div>
            </Tooltip>
            <CustomDialog dialogType="info" isOpen={errorInfoDialogOpen} handleClose={handleDialogClose} onConfirm={handleDialogClose} dialogTitle="App contains styling errors">
                <CustomTypography textWeight="bold">
                    Before saving the app, fix the existing style errors. <br /> A red warning sign should be noticeable on layers that contain errors.
                </CustomTypography>
            </CustomDialog>
            <AppPublishModal open={appPublishModalOpen} handleClose={() => setAppPublishModalOpen(false)} appId={app.id} />
        </>
    );
};

export default AppEditFooter;

const saveButtonTestId = "qa-app-edit-view-save-button-tab";
const publishButtonTestId = "qa-app-edit-view-publish-button-tab";
const viewPublishedButtonTestId = "qa-app-edit-view-view-published-button-tab";
