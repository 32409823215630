import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    statusBadge: {
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: (props: { status: "published" | "unpublished" }) =>
            props.status === "published" ? theme.palette.success.main : theme.palette.warning.light,
    },
    statusBadgeContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
    }
}));