import { FC, MouseEventHandler, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DatasetHistory } from "features/fidu/models/DatasetHistory";
import { TableCell, TableRow } from "@mui/material";
import OverflowCell from "components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import styles from "scss/themeVariables.module.scss";
import TooltipCell from "components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import dayjs from "dayjs";
import { fromNowWithCheck } from "utils/timeUtils";
import { CELL_SIZES } from "utils/constants/cellSizes";
import { getHistoryRowCount } from "./historySortings";
import MenuButtonCell from "components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import CustomTypography from "components/CustomTypography/CustomTypography";

type Props = {
    history: DatasetHistory;
    onDetails: (history: DatasetHistory) => void;
    onDelete: (history: DatasetHistory) => void;
};
const HistoryRow: FC<Props> = ({ history, onDelete, onDetails }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (e: any) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const onMenuClose: MouseEventHandler = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onDeleteClick: MouseEventHandler<HTMLLIElement> = (e) => {
        onMenuClose(e);
        onDelete(history);
    };

    const onDetailsClick: MouseEventHandler = (e) => {
        onMenuClose(e);
        onDetails(history);
    };

    const renderDatasetMenu = () => (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
            <MenuItem onClick={onDetailsClick} className="menu-button" data-testid={detailsButtonTestId}>
                <InfoIcon className="icon" />
                <CustomTypography variant="subtitle1" textWeight="semibold">
                    Details
                </CustomTypography>
            </MenuItem>

            <MenuItem onClick={onDeleteClick} className="menu-button" data-testid={deleteButtonTestId}>
                <DeleteIcon className="icon" />
                <CustomTypography variant="subtitle1" textWeight="semibold">
                    Delete
                </CustomTypography>
            </MenuItem>
        </Menu>
    );

    return (
        <TableRow onClick={onDetailsClick} data-testid={historyRowTestId}>
            <OverflowCell overwrittenCellWidthValue={125} color={styles.primaryColor}>
                {history.fileName}
            </OverflowCell>

            <TableCell>{dayjs(history.timeStamp).format("MMMM DD YYYY h:mm:ss a")}</TableCell>

            <OverflowCell cellSize={CELL_SIZES.extraSmall}>{getHistoryRowCount(history)}</OverflowCell>

            <MenuButtonCell onMenuClick={onMenuClick} />

            {renderDatasetMenu()}
        </TableRow>
    );
};

export default HistoryRow;

const historyRowTestId = "qa-history-row";
const detailsButtonTestId = "qa-history-row-details-button";
const deleteButtonTestId = "qa-history-row-delete-button";
