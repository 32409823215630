import { FC } from "react";
import Popover from "@mui/material/Popover";
import { ChromePicker, ColorResult } from "react-color";

type Props = {
    anchorEl: Element | undefined;
    color: string;
    onChange?: (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => void;
    onClose?: (event: any, reason: string) => void;
};

const ColorPopover: FC<Props> = ({ anchorEl, color, onChange, onClose }) => {
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            PaperProps={{
                style: { marginLeft: 8, overflowY: "hidden", userSelect: "none" }
            }}
            anchorOrigin={{
                vertical: "center",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "left"
            }}
        >
            <ChromePicker color={color} onChangeComplete={onChange} onChange={onChange} />
        </Popover>
    );
};

export default ColorPopover;
