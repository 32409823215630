import { useFetchExistingUsersQuery } from "features/users/api";
import { useFetchGroupUsersQuery } from "../api";
import { useMemo } from "react";

const useGroupUsersManager = (groupId: string) => {
    const { data: usersData = [], isLoading: areUsersLoading, isError: isUsersError, refetch: refetchUsers } = useFetchExistingUsersQuery();
    const { data: groupUsersData, isLoading: areGroupUsersLoading, refetch: refetchGroupUsers, isError: isGroupUsersError } = useFetchGroupUsersQuery(groupId);

    const refetch = () => { refetchGroupUsers(); refetchUsers(); };

    const combinedUsersData = useMemo(() => {
        if (!groupUsersData) return [];

        const groupUsersLookup = new Set(groupUsersData.map(x => x.username));

        const membershipUsers = usersData.map(user => ({
            id: user.id,
            username: user.username,
            role: user.role,
            isMember: groupUsersLookup.has(user.username)
        }));

        return membershipUsers;
    }, [groupUsersData, usersData]);

    const isLoading = areUsersLoading || areGroupUsersLoading;
    const isError = isGroupUsersError || isUsersError;

    return {
        data: combinedUsersData,
        isLoading,
        isError,
        refetch
    };
};

export default useGroupUsersManager;