import { setToken } from "../../features/auth/slice";
import { AccountInfo, BrowserAuthError, ClientAuthError, ClientAuthErrorMessage, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import config from "config";
import { useRef } from "react";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import jwt_decode from "jwt-decode";

const isExpired = (date: Date | null) => {
    if (!date) return true;

    const currentTime = new Date();
    const msDifference = 30000;

    return currentTime.getTime() > date.getTime() - msDifference;
};

const getExpiresOnDate = (token: string) => {
    const jwtProperties: any = jwt_decode(token);

    return dayjs(jwtProperties.exp * 1000).toDate();
};

const useAuthentication = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const tokenRef = useRef<string | null>(null);
    const tokenExpiresOnRef = useRef<Date | null>(null);
    const dispatch = useAppDispatch();

    async function acquireToken() {
        let response = null;
        try {
            response = await instance.acquireTokenSilent({
                scopes: config.loginRequest.scopes,
                account: activeAccount as AccountInfo
            });
        } catch (error) {
            console.error("Authentication error: ", error);
            if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                await instance.acquireTokenRedirect({
                    scopes: config.loginRequest.scopes,
                    account: activeAccount as AccountInfo
                });
            }
            if (error instanceof ClientAuthError && error.errorCode === ClientAuthErrorMessage.multipleMatchingTokens.code) {
                localStorage.clear();
                await instance.acquireTokenRedirect({
                    scopes: config.loginRequest.scopes,
                    account: activeAccount as AccountInfo
                });
            }
            throw error;
        }
        return response;
    }

    async function getCurrentOrRefreshToken() {
        if (tokenRef.current !== null && !isExpired(tokenExpiresOnRef.current)) {
            return tokenRef.current;
        }

        const response = await acquireToken();
        if (response !== null) {
            tokenRef.current = response.accessToken;
            tokenExpiresOnRef.current = getExpiresOnDate(response.accessToken);
            // map component still uses token from redux
            dispatch(setToken(response.accessToken));

            return response.accessToken;
        }
        return "";
    }

    const getUserEmail = () => (activeAccount?.idTokenClaims?.["email"] as string) ?? "";

    return {
        getAccessToken: getCurrentOrRefreshToken,
        logoutRedirect: (): void => {
            instance.logoutRedirect();
        },
        getUserEmail
    };
};

export default useAuthentication;
