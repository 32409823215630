import { Checkbox, MenuItem, Select, Alert } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as datasetActions from "../../../../../actions/datasets";
import * as metadataActions from "../../../../../actions/metadataSchema";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import LoadingPlaceholder from "../../../../../components/LoadingPlaceholder/LoadingPlaceholder";
import { getDatasetsLoading } from "../../../../../selectors/datasets";
import { getIsMetadataLoading, getMetadataSchema } from "../../../../../selectors/metadataSchema";
import { metadataTypes, METADATA_SCHEMA_ID } from "../../../../../utils/constants/metadata";
import { getFilteredSchemaPropertyValues } from "../../../../../utils/metadataUtils";
import { datasetsBulkEditMetadataSchema } from "../../../../../utils/validators/dataset";
import MetadataPropertyField from "../MetadataPropertyField/MetadataPropertyField";
import useStyles from "./styles";

const EditMetadataModal = ({ open, onClose, datasetIds }) => {
    const classes = useStyles();

    const metadataLoading = useSelector(getIsMetadataLoading);
    const datasetsLoading = useSelector(getDatasetsLoading);
    const metadataSchema = useSelector(getMetadataSchema);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(metadataActions.getMetadataSchemaThunk(METADATA_SCHEMA_ID));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortedMetadataSchema = useMemo(() => {
        return [...metadataSchema].sort((prop1, prop2) => {
            return Number(prop2.mandatory) - Number(prop1.mandatory);
        });
    }, [metadataSchema]);

    const onSubmit = ({ selectedProperties, propertyValues }) => {
        const metadata = getFilteredSchemaPropertyValues(selectedProperties, metadataSchema, propertyValues);

        dispatch(datasetActions.bulkUpdateDatasetMetadataThunk({ datasetIds, metadata, metadataSchemaId: METADATA_SCHEMA_ID }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Updated metadata successfully");
                dispatch(datasetActions.getDatasets());
                onClose();
            })
            .catch(() => toastr.error("Failed to update metadata"));
    };

    const getMetadataInitialValue = (metadata) => {
        switch (metadata.type) {
            case metadataTypes.DATE:
                return null;
            case metadataTypes.TAG_LIST:
                return metadata.value;
            default:
                return "";
        }
    };

    const getFormikProps = ({ values, errors, setFieldValue, handleChange, setTouched, touched }, property) => ({
        // this is needed to avoid the error regarding a component changing
        // from uncontrolled input to controlled input (only happens during the first render
        // before the metadata schema is loaded)
        value: values.propertyValues[property.name] ?? getMetadataInitialValue(property),
        onChange: (e) => {
            setTouched({
                propertyValues: {
                    ...touched.propertyValues,
                    [property.name]: true
                }
            });

            if (property.type === metadataTypes.DATE || property.type === metadataTypes.TAG_LIST) {
                setFieldValue(`propertyValues.${property.name}`, e);
            } else {
                handleChange(e);
            }
        },
        // using this name formik will be able to handle updates on nested objects
        name: `propertyValues.${property.name}`,
        error: !!errors.propertyValues?.[property.name] && !!touched.propertyValues?.[property.name],
        helperText: !!touched.propertyValues?.[property.name] ? errors.propertyValues?.[property.name] : ""
    });

    const selectedDatasetsNumber = datasetIds.length;

    return (
        <Formik
            initialValues={{
                selectedProperties: metadataSchema.map((property) => property.id),
                propertyValues: sortedMetadataSchema.reduce((acc, property) => {
                    acc[property.name] = getMetadataInitialValue(property);
                    return acc;
                }, {})
            }}
            validationSchema={datasetsBulkEditMetadataSchema(metadataSchema)}
            validateOnMount
            enableReinitialize
            onSubmit={onSubmit}
        >
            {(formikProps) => (
                <CustomModal isOpen={open} handleClose={onClose} dialogTitle="Edit Metadata" dialogType="apply" disabled={!formikProps.isValid} onConfirm={formikProps.submitForm}>
                    <Alert severity="info">
                        The changes you make will be applied to all <br />
                        <strong>{selectedDatasetsNumber} selected datasets</strong>
                    </Alert>
                    <div className={classes.container}>
                        <LoadingPlaceholder loading={metadataLoading || datasetsLoading}>
                            <div className={classes.selectContainer}>
                                <Select
                                    name="selectedProperties"
                                    value={formikProps.values.selectedProperties}
                                    fullWidth
                                    multiple
                                    displayEmpty
                                    onChange={formikProps.handleChange}
                                    renderValue={() => "Metadata Properties"}
                                    placeholder="Metadata properties"
                                >
                                    {sortedMetadataSchema.map((property) => (
                                        <MenuItem key={property.id} value={property.id} className={classes.selectItem}>
                                            <Checkbox checked={formikProps.values.selectedProperties.includes(property.id)} />
                                            {property.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            {sortedMetadataSchema
                                .filter((property) => formikProps.values.selectedProperties.includes(property.id))
                                .map((property) => (
                                    <div key={property.id} className={classes.field}>
                                        <MetadataPropertyField property={property} {...getFormikProps(formikProps, property)} />
                                    </div>
                                ))}
                        </LoadingPlaceholder>
                    </div>
                </CustomModal>
            )}
        </Formik>
    );
};

export default EditMetadataModal;
