import { DragEventHandler, FC, useState } from "react";
import { useDatasetsTableStyles } from "./style";
import SearchBar from "components/SearchBar/SearchBar";
import CustomTable from "components/CustomTable/CustomTable";
import DatasetRow from "../DatasetRow/DatasetRow";
import { getAppLayersCount, getIncludedDatasets, getIncludedRasters, isLayerLimitReached } from "selectors/appData";
import { Box, Tooltip, Divider } from "@mui/material";
import appLayersLimit from "utils/constants/appLayersLimit";
import InfoIcon from "@mui/icons-material/Info";
import datasetColumns from "./datasetColumns";
import CustomTypography from "components/CustomTypography/CustomTypography";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import { handleError } from "utils/networkErrorUtils";
import { removeDatasetFromAppThunk, removeRasterFromAppThunk } from "actions/apps";
import toastr from "components/CustomToastr/CustomToastr";
import { useParams } from "react-router-dom";
import { DATA_TYPES } from "utils/constants/dataType";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import useRegisterSelectFilter from "components/CustomTable/hooks/useRegisterSelectFilter";
import StorageIcon from "@mui/icons-material/Storage";
import { useFilteredData } from "components/CustomTable/hooks/useFilteredData";

const LAYER_LIMIT_REACHED_TEXT = "Layer limit reached. In order to add new layers, try removing old ones, or create a new app.";
const LAYER_LIMIT_INFO_TEXT = "Due to performance reasons, there is a limit on the total amount of layers an app can include.";

const filterFunction = (item: any, query: string) => {
    return item.name.toLowerCase().includes(query.toLowerCase());
};

type Props = {
    data: any;
    canDragAndDrop: boolean;
};

type Params = {
    appId: string;
};

const DatasetsTable: FC<Props> = ({ data, canDragAndDrop }) => {
    const classes = useDatasetsTableStyles();

    const [searchText, setSearchText] = useState("");

    const includedDatasets = useAppSelector(getIncludedDatasets);
    const includedRasters = useAppSelector(getIncludedRasters);

    const layerLimitReached = useAppSelector(isLayerLimitReached);
    const appLayersCount = useAppSelector(getAppLayersCount);

    const dispatch = useAppDispatch();

    const { appId } = useParams<Params>();

    const filteredData = useFilteredData(data);

    useRegisterSelectFilter("Schema", "schemaName", true, data, <StorageIcon />);

    const onSearchChange = (newValue: string) => {
        setSearchText(newValue);
    };

    const onDragOver: DragEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let dropData = JSON.parse(event.dataTransfer.getData("text"));

        if (dropData.type === "layer") {
            onExcludeData(dropData);
            return;
        }
    };

    const onExcludeData = (data: any) => {
        const datasetId = data.resourceId;
        const rasterId = data.resourceId;

        // TODO: the ts-ignore should be removed after refactoring to ts the file where the thunks are
        // @ts-ignore
        const removeLayer: any = data.chipType === DATA_TYPES.vector ? removeDatasetFromAppThunk({ appId, datasetId }) : removeRasterFromAppThunk({ appId, rasterId });

        dispatch(removeLayer)
            .then(unwrapResult)
            .then(() => {
                toastr.success(`${data.chipType === DATA_TYPES.vector ? "Dataset" : "Raster"} removed`);
            })
            .catch((err: any) => {
                handleError(err);
            });
    };

    const generateItem = (data: any, index: number) => {
        return (
            <DatasetRow
                canDragAndDrop={canDragAndDrop}
                data={{ ...data, included: !!includedDatasets[data.id]?.included || !!includedRasters[data.id]?.included }}
                key={data.id}
                index={index}
            />
        );
    };

    const searchData = (items: any) => items.filter((entity: any) => filterFunction(entity, searchText));
    const filterIncluded = (items: any) => items.filter((item: any) => !includedDatasets[item.id]?.included && !includedRasters[item.id]?.included);

    return (
        <div className={classes.tableContainer}>
            <div className={classes.searchContainer}>
                <SearchBar searchPlaceholder="Search datasets" value={searchText} onChange={onSearchChange} className={classes.searchBar} noBg />
                <Box display="flex" alignItems="center" paddingRight={2.5}>
                    <CustomTypography className={classes.countText} color={layerLimitReached ? "error" : ""}>
                        {appLayersCount} / {appLayersLimit}
                    </CustomTypography>
                    <Tooltip className={classes.marginLeft} title={layerLimitReached ? LAYER_LIMIT_REACHED_TEXT : LAYER_LIMIT_INFO_TEXT}>
                        <InfoIcon fontSize="small" className={layerLimitReached ? classes.errorInfoColor : classes.infoColor} />
                    </Tooltip>
                </Box>
            </div>
            <InfoTextSection paddingX={2.5} iconSize="extra-small" textVariant="body2" textWeight="semibold">
                Drag and drop a dataset and place it in the desired group
            </InfoTextSection>
            <Divider className={classes.divider} />

            <div className={classes.list} onDrop={onDrop} onDragOver={onDragOver}>
                <CustomTable
                    data={searchData(filterIncluded(filteredData))}
                    columns={datasetColumns}
                    generateItem={generateItem}
                    tableSize="small"
                    fetchingData={false}
                    hideBottomBar
                    placePagginationToBottom
                    placePagginationToLeft
                ></CustomTable>
            </div>
        </div>
    );
};

export default DatasetsTable;
