import InfoIcon from "@mui/icons-material/Info";
import React, { useState } from "react";
import { useStyles } from "./styles.js";
import { useTheme } from "@mui/styles";
import CustomTypography from "../CustomTypography/CustomTypography.js";
import { Box } from "@mui/material";
import clsx from "clsx";

const InfoTextSection = (props) => {
    const { iconSize, textVariant, textWeight, textColor } = props;
    const classes = useStyles();
    const theme = useTheme();

    const extraSmallIcon = "extra-small";

    return (
        <Box className={classes.infoContainer} {...props}>
            <InfoIcon className={clsx(classes.infoIcon, { [classes.extraSmall]: iconSize == extraSmallIcon })} color="secondary" fontSize={iconSize} />
            <CustomTypography variant={textVariant} textWeight={textWeight} color={textColor || theme.customColors.appTextColorLight}>
                {props.children}
            </CustomTypography>
        </Box>
    );
};

export default InfoTextSection;
