import axios from "axios";
import config from "../config";

export const dprApiClient = axios.create({
    baseURL: config.dprApiUrl,
    timeout: 300000,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: false
});
