import { resetProjectData } from "actions/globalActions";
import * as types from "../actions/actionTypes";
import { makeAppCopyThunk, makeAppPublicThunk, updateApp, unpublishAppThunk } from "../actions/apps";
import { publishAppThunk } from "actions/applications";

const appsInit = {
    fetching: false,
    loading: false,
    isPanelClosed: false,
    apps: []
};

export function appsReducer(state = appsInit, action) {
    switch (action.type) {
        case types.FETCH_APPS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_APPS_COMPLETE:
            return {
                ...state,
                fetching: false,
                apps: action.result
            };
        case types.CREATE_APP:
            return {
                ...state,
                loading: true
            };
        case types.CREATE_APP_COMPLETE:
            return {
                ...state,
                loading: false,
                apps: [...state.apps, action.result]
            };
        case updateApp.pending.type:
            return {
                ...state,
                loading: true
            };
        case updateApp.fulfilled.type:
            return {
                ...state,
                loading: false,
                apps: updateArray(state.apps, action.payload)
            };
        case types.DELETE_APP:
            return {
                ...state,
                loading: true
            };
        case types.DELETE_APP_COMPLETE:
            return {
                ...state,
                loading: false,
                apps: removeFromArray(state.apps, action.result)
            };
        case types.APP_ACTION_FAILED:
            return {
                ...state,
                fetching: false,
                loading: false
            };
        case updateApp.rejected.type:
            return {
                ...state,
                fetching: false,
                loading: false
            };
        case types.PANEL_TOGGLE:
            return {
                ...state,
                isPanelClosed: !state.isPanelClosed
            };
        case makeAppCopyThunk.pending.type:
            return {
                ...state,
                loading: true
            };
        case makeAppCopyThunk.rejected.type:
            return {
                ...state,
                loading: false
            };
        case makeAppCopyThunk.fulfilled.type:
            return {
                ...state,
                apps: [...state.apps, action.payload],
                loading: false
            };
        case unpublishAppThunk.pending.type:
            return {
                ...state,
                loading: true
            };
        case unpublishAppThunk.rejected.type:
            return {
                ...state,
                loading: false
            };
        case unpublishAppThunk.fulfilled.type:
            return {
                ...state,
                loading: false,
                apps: updateArray(state.apps, action.payload)
            };
        case publishAppThunk.pending.type:
            return {
                ...state,
                loading: true
            };
        case publishAppThunk.rejected.type:
            return {
                ...state,
                loading: false
            };
        case publishAppThunk.fulfilled.type:
            return {
                ...state,
                loading: false,
                apps: updateArray(state.apps, action.payload)
            };
        case makeAppPublicThunk.pending.type:
            return {
                ...state,
                loading: true
            };
        case makeAppPublicThunk.rejected.type:
            return {
                ...state,
                loading: false
            };
        case makeAppPublicThunk.fulfilled.type:
            return {
                ...state,
                loading: false,
                apps: updateArray(state.apps, action.payload)
            };
        case types.UPDATE_APP_PUBLISHED:
            return {
                ...state,
                loading: false,
                apps: updateAppPublished(state.apps, action.result)
            };
        case resetProjectData.type:
            return appsInit;
        default:
            return state;
    }
}

function updateAppPublished(array, { id }) {
    return array.map((x) => {
        if (x.id == id) {
            x.isPublished = true;
        }
        return x;
    });
}

function removeFromArray(array, item) {
    return array.filter((x) => item.id !== x.id);
}

function updateArray(array, item) {
    return array.map((x) => {
        if (item.id === x.id) {
            return item;
        }
        return x;
    });
}
