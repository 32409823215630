import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "./apiClient";

export const getMetadataSchemaThunk = createAsyncThunk("getMetadataSchema", (id: number) => apiClient.get(`metadata/${id}`));

type UpdateMetadataPropertyThunkArg = { metadataSchemaId: number; metadataProperty: any };
export const updateMetadataPropertyThunk = createAsyncThunk("updateMetadataProperty", ({ metadataSchemaId, metadataProperty }: UpdateMetadataPropertyThunkArg) =>
    apiClient.put(`metadata/${metadataSchemaId}`, metadataProperty)
);

type AddMetadataPropertyThunkArg = { metadataSchemaId: number; metadataProperty: any };
export const addMetadataPropertyThunk = createAsyncThunk("addMetadataProperty", ({ metadataSchemaId, metadataProperty }: AddMetadataPropertyThunkArg) =>
    apiClient.post(`metadata/${metadataSchemaId}`, metadataProperty)
);

type DeleteMetadataPropertyThunkArg = { metadataSchemaId: number; metadataPropertyId: string };
export const deleteMetadataPropertyThunk = createAsyncThunk("deleteMetadataProperty", ({ metadataSchemaId, metadataPropertyId }: DeleteMetadataPropertyThunkArg) =>
    apiClient.delete(`metadata/${metadataSchemaId}/${metadataPropertyId}`)
);
