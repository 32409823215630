export const CACHE_STATES = {
    cached: "Cached",
    caching: "Caching",
    uncached: "Uncached"
};

export const CACHE_STATUSES = {
    cached: 2,
    caching: 1,
    uncached: 0
};
