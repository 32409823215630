import { TableCell, Checkbox } from "@mui/material";

const CheckboxCell = ({ checked, onChange, checkboxInputProps }) => {
    const onClick = (event) => event.stopPropagation();

    return (
        <TableCell>
            <Checkbox checked={checked} onChange={onChange} onClick={onClick} size="medium" inputProps={checkboxInputProps} />
        </TableCell>
    );
};

CheckboxCell.defaultProps = {
    checked: false
};

export default CheckboxCell;
