import usePermissionsManagement from "features/groups/hooks/usePermissionManager";
import ListView from "../../../../../../components/listView";
import ResourcePermissions from "../ResourcesPermissions/ResourcePermissions";
import { sortByNumber, sortByNumberReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";
import { ResourceWithPermission } from "features/groups/model/ResourceWithPermission";
import { PermissionType } from "features/groups/model/PermissionType";
import { Permission } from "utils/constants/permissionTypes";
import { ResourceType } from "features/groups/model/ResourceType";

const sortByName = (r1: ResourceWithPermission, r2: ResourceWithPermission) => sortByString(r1.name, r2.name);
const sortByNameReverse = (r1: ResourceWithPermission, r2: ResourceWithPermission) => sortByStringReverse(r1.name, r2.name);

const sortByPermission = (r1: ResourceWithPermission, r2: ResourceWithPermission) => sortByNumber(r1.permissionType, r2.permissionType);
const sortByPermissionReverse = (r1: ResourceWithPermission, r2: ResourceWithPermission) => sortByNumberReverse(r1.permissionType, r2.permissionType);

const nameColumnProps = { "data-testid": "qa-resource-permission-table-head-name" };
const permissionColumnProps = { "data-testid": "qa-resource-permission-table-head-permission" };

const resourcePermissionsColumns = [
    {
        name: "name",
        type: "name",
        start: true,
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByNameReverse : sortByName),
        otherProps: nameColumnProps
    },
    {
        name: "permission level",
        type: "permission-type",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByPermissionReverse : sortByPermission),
        otherProps: permissionColumnProps
    }
];

type ResourcePermissionsListProps = {
    groupId: string;
    resources: ResourceWithPermission[];
    permissions: Permission[];
    onChangesMade: () => void;
    resourceType: ResourceType;
};

const ResourcePermissionsList = ({ groupId, resources, permissions, onChangesMade, resourceType }: ResourcePermissionsListProps) => {
    const { handlePermissionChange } = usePermissionsManagement();

    const onPermissionsChanged = (resourceId: string, currentPermission: PermissionType, newPermission: PermissionType) =>
        handlePermissionChange(groupId, resourceId, currentPermission, newPermission, resourceType, onChangesMade);

    const resourceFilterFunction = (resource: ResourceWithPermission, searchedResource: string) => {
        return resource.name.toLowerCase().includes(searchedResource.toLowerCase());
    };
    const generateResource = (resource: ResourceWithPermission) => {
        return <ResourcePermissions key={resource.id} resource={resource} permissions={permissions} onPermissionChanged={onPermissionsChanged} />;
    };

    return (
        <ListView
            searchPlaceholder="Search for resource"
            data={resources}
            columns={resourcePermissionsColumns}
            filterFunction={resourceFilterFunction}
            generateItem={generateResource}
            hideBottomBar
            searchBarProps={searchBarProps as any}
            tablePaginationProps={tablePaginationProps as any}
            showContextMenuFiller={true}
        />
    );
};

export default ResourcePermissionsList;

const tablePaginationProps = {
    backIconButtonProps: { "data-testid": "qa-resource-permission-table-back-button" },
    nextIconButtonProps: { "data-testid": "qa-resource-permission-table-next-button" }
};

const searchBarProps = { "data-testid": "qa-resource-permission-list-searchbar" };
