import { useState } from "react";
import { Button, Typography, LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import clsx from "clsx";
import toastr from "components/CustomToastr/CustomToastr";
import { useDeleteRasterMutation, useFetchRastersQuery } from "features/rasters/api";
import { Raster } from "features/rasters/models/Raster";
import { getNavigationState } from "selectors/navigation";
import RasterRow from "./RasterRow/RasterRow";
import rastersSorting from "../../utils";
import TableView from "components/TableView/TableView";
import UploaderModal from "features/upload/components/UploaderModal/UploaderModal";
import { useRastersListViewStyles } from "./styles";
import { handleError } from "utils/networkErrorUtils";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { useAppSelector } from "store/hooks/useAppSelector";

const RastersListView = () => {
    const classes = useRastersListViewStyles();
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const isNavigationOpen = useAppSelector(getNavigationState);
    const featureFlags = useAppSelector(getFeatureFlags);

    const columns = [...rastersSorting];
    if (!featureFlags.CPT) {
        const cptColumnIndex = columns.findIndex((c) => c.name === "Color Palette");
        columns.splice(cptColumnIndex, 1);
    }

    const { data: rasters = [], isFetching, isError } = useFetchRastersQuery();
    const [deleteRaster] = useDeleteRasterMutation();

    const onDeleteRaster = (raster: Raster) => {
        const toastrConfirmOptions = {
            onOk: () =>
                deleteRaster(raster.id)
                    .then(() => toastr.success("Raster deleted"))
                    .catch((error) => handleError(error)),
            onCancel: () => {}
        };
        toastr.confirm("Delete Raster " + raster.name, toastrConfirmOptions);
    };

    const generateItem = (item: Raster, index: number) => <RasterRow raster={item} key={index} onDelete={onDeleteRaster} selected={undefined} />;

    const filterItem = (item: Raster, query: string) => item.name.toLowerCase().includes(query.toLowerCase());

    return (
        <>
            <div className={clsx("sidebar-container", classes.rasterOverview, { [classes.menuIsOpen]: isNavigationOpen })}>
                <div className="header">
                    <Typography variant="h6" className={classes.title} data-testid={headerTestId}>
                        Rasters
                    </Typography>
                    <Button variant="contained" color="primary" onClick={() => setIsUploaderOpen(true)} data-testid={addDatasetButtonTestId}>
                        <AddIcon className={classes.icon} fontSize="small" />
                        Add Dataset
                    </Button>
                </div>

                {isFetching && <LinearProgress className="no-margin-progress" />}

                <TableView
                    searchPlaceholder={"Search Rasters by Name"}
                    data={rasters}
                    fetchingData={isFetching}
                    columns={columns}
                    generateItem={generateItem}
                    filterFunction={filterItem}
                    hideBottomBar={undefined}
                    // TODO: the ts-ignore should be removed after refactoring to ts TableView.js
                    // @ts-ignore
                    errorText={isError && "Oops, this is not working properly right now. Please refresh the page or try again later"}
                />
            </div>
            <UploaderModal open={isUploaderOpen} onClose={() => setIsUploaderOpen(false)} />
        </>
    );
};

export default RastersListView;

const addDatasetButtonTestId = "qa-raster-list-view-add-dataset-button";
const headerTestId = "qa-header";
