import { useFormikContext } from "formik";
import { useMemo } from "react";
import { metadataTypes } from "../../../../../utils/constants/metadata";
import MetadataPropertyField from "../../../datasetsListView/components/MetadataPropertyField/MetadataPropertyField";
import { useDatasetEditViewStyles } from "../../styles";
import DefaultInfoText from "../DefaultInfoText/DefaultInfoText";

const MetadataTab = ({ metadata, setBasicDataChanged }) => {
    const classes = useDatasetEditViewStyles();

    const { values, errors, handleChange, setFieldValue } = useFormikContext();

    const sortedMetadata = useMemo(() => {
        return [...metadata].sort((prop1, prop2) => {
            return Number(prop2.mandatory) - Number(prop1.mandatory);
        });
    }, [metadata]);

    const formikProps = (metadata) => ({
        value: values[metadata.name] || "",
        onChange:
            metadata.type === metadataTypes.DATE || metadata.type === metadataTypes.TAG_LIST
                ? (val) => {
                      setBasicDataChanged(true);
                      setFieldValue(metadata.name, val);
                  }
                : (e) => {
                      setBasicDataChanged(true);
                      handleChange(e);
                  },
        name: metadata.name,
        error: !!errors[metadata.name],
        helperText: errors[metadata.name]
    });

    return (
        <div className={classes.page}>
            <DefaultInfoText />

            {sortedMetadata.map((item, index) => {
                return (
                    <div key={index} className={classes.field}>
                        <MetadataPropertyField property={item} {...formikProps(item)} />
                    </div>
                );
            })}
        </div>
    );
};

export default MetadataTab;
