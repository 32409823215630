import { FC, useEffect, useMemo, useState } from "react";
import * as schemaService from "../../../../actions/schemaService";
import ListView from "../../../../components/listView";

import toastr from "../../../../components/CustomToastr/CustomToastr";
import * as NetworkErrorUtils from "../../../../utils/networkErrorUtils";
import Schema from "./schema";
import { getSchemas, getAreSchemasFetching } from "../../../../selectors/schemas";
import { useAppDispatch } from "../../../../store/hooks/useAppDispatch";
import { useAppSelector } from "../../../../store/hooks/useAppSelector";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";

const EditSchemasView: FC = () => {
    const [selected, setSelected] = useState("");

    const schemas = useAppSelector(getSchemas);
    const areSchemasFetching = useAppSelector(getAreSchemasFetching);

    const dispatch = useAppDispatch();

    const columns = useMemo(
        () => [
            {
                type: "name",
                name: "schema name",
                sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByNameReverse : sortByName),
                start: true
            }
        ],
        []
    );

    useEffect(() => {
        dispatch(schemaService.getSchemas());
    }, []);

    const sortByName = (schema1: string, schema2: string) => schema1.localeCompare(schema2);

    const sortByNameReverse = (schema1: string, schema2: string) => schema2.localeCompare(schema1);

    const onDeleteSchema = (schema: string) => {
        dispatch(schemaService.deleteSchema(schema)).then(
            (res: any) => {
                toastr.success("Schema deleted");
            },
            (err: any) => NetworkErrorUtils.handleError(err)
        );
    };

    const generateItem = (schema: string, index: number) => {
        return <Schema schema={schema} selected={schema === selected} onSelect={setSelected} key={index} onDelete={onDeleteSchema} />;
    };

    const filterItem = (item: string, query: string) => {
        return item.toLowerCase().includes(query.toLowerCase());
    };

    return (
        <div className="setting-edit-view schemas">
            <div className="schema-container">
                <LoadingPlaceholder loading={areSchemasFetching} linearProgress />
                <ListView
                    searchPlaceholder={"Search database schemas"}
                    data={schemas}
                    columns={columns}
                    generateItem={generateItem}
                    filterFunction={filterItem}
                    showContextMenuFiller={true}
                    righthandSearchComponent={undefined}
                    searchBarProps={undefined}
                    tablePaginationProps={undefined}
                />
            </div>
        </div>
    );
};

export default EditSchemasView;
