import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";

import toastr from "../CustomToastr/CustomToastr";
import { handleError } from "../../utils/networkErrorUtils";

import * as datasetsActions from "../../actions/datasets";

import { Typography, LinearProgress, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import CustomModal from "../CustomModal/CustomModal";

const styles = () => ({});

class Downloader extends Component {
    constructor(props) {
        super(props);

        this.geojsonData = null;

        this.state = {
            format: "GeoJSON",
            formats: [
                { name: "GeoJSON", prettyName: "GeoJson" },
                { name: "ESRI Shapefile", prettyName: "Shapefile" },
                { name: "KML", prettyName: "Kml" },
                { name: "GPKG", prettyName: "GeoPackage" }
            ],
            downloading: false,
            downloadProgressPercentage: 0
        };
    }

    onFormatChanged = (format) => {
        this.setState({
            format: format
        });
    };

    onDownloadDataset = () => {
        this.setState({
            downloading: true
        });

        let config = {
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({
                    downloadProgressPercentage: percentCompleted
                });
            }
        };

        let fileName = this.makeFileName(this.props.dataset, this.state.format);

        this.props.downloadDataset(this.props.dataset.id, fileName, this.state.format, config).then(
            () => {
                toastr.success("Data downloaded");
                this.props.handleClose();
                this.setState({
                    downloading: false,
                    uploadProgressPercentage: 0
                });
            },
            (err) => {
                handleError(err);
                this.setState({
                    downloading: false,
                    uploadProgressPercentage: 0
                });
            }
        );
    };

    makeFileName(dataset, format) {
        switch (format) {
            case "GeoJSON":
            case "KML":
            case "ESRI Shapefile":
            case "GPKG":
                return `${dataset.name}.zip`;

            default:
                console.error("Invalid file type");
        }
    }
    getDataTestId = (item) => {
        const name = item.name.toLocaleLowerCase().replaceAll(" ", "-");
        return menuItemTestId + name;
    };
    render() {
        let menuitems = this.state.formats.map((item) => {
            return (
                <MenuItem onClick={() => this.onFormatChanged(item.name)} value={item.name} key={item.name} data-testid={this.getDataTestId(item)}>
                    {item.prettyName}
                </MenuItem>
            );
        });

        return (
            <CustomModal handleClose={this.props.handleClose} isOpen={this.props.open} onConfirm={this.onDownloadDataset} dialogTitle={"Download"} dialogType={"start"}>
                <div className="container">
                    <div className="page">
                        {!this.state.downloading && (
                            <div className="options">
                                <div className="section">
                                    <FormControl variant="filled" className="section" fullWidth>
                                        <InputLabel id="label">Data type</InputLabel>
                                        <Select labelId="label" label="Data type" value={this.state.format} inputProps={{ "data-testid": selectInputTestId }}>
                                            {menuitems}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        )}
                        {this.state.downloading && (
                            <div className="progress-page">
                                {this.state.downloadProgressPercentage === 0 && (
                                    <div>
                                        <Typography variant="h6" id="transition-modal-title" className="text">
                                            Generating File
                                        </Typography>
                                        <LinearProgress className="progress" variant="indeterminate" />
                                    </div>
                                )}
                                {this.state.downloadProgressPercentage > 0 && (
                                    <div className="downloading">
                                        <Typography variant="h6" id="transition-modal-title" className="text">
                                            Downloading
                                        </Typography>
                                        <Typography variant="body1" id="transition-modal-title" className="text">
                                            {this.props.dataset.name}
                                        </Typography>
                                        <Typography variant="body1" id="transition-modal-title" className="text">
                                            {this.state.downloadProgressPercentage}%
                                        </Typography>
                                        <LinearProgress className="progress" variant="determinate" value={this.state.downloadProgressPercentage} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </CustomModal>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        downloadDataset: (datasetId, fileName, format, config) => dispatch(datasetsActions.downloadDataset(datasetId, fileName, format, config))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Downloader));

const selectInputTestId = "qa-downloader-select-input";
const menuItemTestId = "qa-downloader-menu-item-";
