import "@emblautec/rescursive-array-extensions";
import "./App.css";
import "./scss/main.scss";
import "./utils/validators/utilFunctions.js";
import Bootstrap from "Bootstrap";
import ReduxToastr from "react-redux-toastr";
import { Redirect, Route, Switch } from "react-router-dom";
import ErrorView from "views/error/ErrorView";
import LandingView from "views/landing/LandingView";
import MainView from "views/main/MainView";
import SignOutView from "views/SignOutView/SignOutView";
//Webpack 5 doesn't include Buffer anymore and we need it for the style validation library
global.Buffer = global.Buffer || require("buffer").Buffer;

const App = () => (
    <div className="app">
        <Switch>
            <Route exact path="/:clientId/:projectId/error">
                <ErrorView />
            </Route>
            <Route path="/:clientId/:projectId">
                <Bootstrap>
                    <MainView />
                </Bootstrap>
            </Route>
            <Route exact path="/sign-out">
                <SignOutView />
            </Route>
            <Route exact path="/">
                <LandingView />
            </Route>
            <Redirect to="/" />
        </Switch>
        <ReduxToastr timeOut={4000} newestOnTop={true} position="top-right" transitionIn="fadeIn" transitionOut="fadeOut" closeOnToastrClick />
    </div>
);

export default App;
