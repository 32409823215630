import { FC, useState } from "react";
import { useFormikContext } from "formik";
import { Column } from "features/datasets/models/Column";
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import ConfigureLocationNamesModal from "../../ConfigureLocationNamesModal/ConfigureLocationNamesModal";
import { DprLocationMapping } from "features/dprIntegration/models/DprLocationMapping";

type Props = {
    onConfigureStatusColumn: (statusColumnId: number) => void;
    onConfigureLocationColumn: (locationColumnId: number) => void;
    onConfigureLocationMappings: (locationMappings: DprLocationMapping[]) => void;
    statusColumnId: number | null;
    locationColumnId: number | null;
    locationMappings: DprLocationMapping[];
};

const LocationAndStatus: FC<Props> = ({ onConfigureStatusColumn, onConfigureLocationColumn, onConfigureLocationMappings, statusColumnId, locationColumnId, locationMappings }) => {
    const {
        values: { columns }
    } = useFormikContext<{ columns: Column[] }>();

    const [configureLocationNamesModalOpen, setConfigureLocationNamesModalOpen] = useState(false);

    const getDataTestId = (select: string, itemId: number) => {
        return menuItemTestId + select + "-item-" + itemId;
    };

    const menuItems = (select: string) =>
        columns.map((x) => (
            <MenuItem key={select + x.id} value={x.id.toString()} data-testid={getDataTestId(select, x.id)}>
                {x.name}
            </MenuItem>
        ));

    return (
        <Stack gap={2} marginTop={2}>
            <FormControl variant="filled">
                <InputLabel id="status-column-label">Column name for status</InputLabel>

                <Select
                    labelId="status-column-label"
                    label="Column name for status"
                    value={statusColumnId?.toString() ?? ""}
                    onChange={(e) => onConfigureStatusColumn(Number.parseInt(e.target.value))}
                    inputProps={{ "data-testid": selectStatusColumnTestId }}
                >
                    {menuItems("status")}
                </Select>
            </FormControl>
            <FormControl variant="filled">
                <InputLabel id="location-column-label">Column name for location</InputLabel>
                <Select
                    labelId="location-column-label"
                    label="Column name for location"
                    value={locationColumnId?.toString() ?? ""}
                    onChange={(e) => onConfigureLocationColumn(Number.parseInt(e.target.value))}
                    inputProps={{ "data-testid": selectLocationColumnTestId }}
                >
                    {menuItems("location")}
                </Select>
            </FormControl>
            <Button
                onClick={() => setConfigureLocationNamesModalOpen(true)}
                variant="text"
                sx={{ width: "fit-content", paddingInline: 2, minHeight: "50px" }}
                disabled={!locationColumnId}
                data-testid={configureLocationNamesButtonTestId}
            >
                Configure location names
            </Button>

            <ConfigureLocationNamesModal
                open={configureLocationNamesModalOpen}
                handleClose={() => setConfigureLocationNamesModalOpen(false)}
                existingLocationMappings={locationMappings}
                onConfirm={(locationMappings) => onConfigureLocationMappings(locationMappings)}
                locationColumnId={locationColumnId}
            />
        </Stack>
    );
};

export default LocationAndStatus;

const selectStatusColumnTestId = "qa-dpr-integration-select-status-column";
const selectLocationColumnTestId = "qa-dpr-integration-select-location-column";

const menuItemTestId = "qa-dpr-integration-select-";

const configureLocationNamesButtonTestId = "qa-dpr-integration-configure-location-names-button";
