import * as Yup from "yup";
import { metadataTypes } from "../constants/metadata";

export const MIN_TAGS = 1;

const tagsCommon = Yup.string().required("Required").max(25, "Tag name is too long");

export const TagSchema = (tags) =>
    Yup.object().shape({
        name: tagsCommon.test("Tag name unicity test", "Tag names must be unique", (name) => !tags.some((t) => t.name === name))
    });

const getValueValidator = (type) =>
    type === metadataTypes.TAG_LIST
        ? Yup.array()
              .of(
                  Yup.object().shape({
                      name: tagsCommon
                  })
              )
              .unique({ name: "Tag names must be unique" }, (tag) => tag.name)
              .min(MIN_TAGS, "Must provide at least one tag")
              .nullable()
        : Yup.array().nullable();

export const MetadataPropertySchema = (propertiesNames) =>
    Yup.object().shape({
        name: Yup.string()
            .required("Required")
            .max(25, "Property name is too long")
            .test("Property name unicity test", "Property name is already in use", (name) => !propertiesNames.some((n) => n === name)),
        type: Yup.string(),
        value: Yup.mixed()
            .when("type", (type) => getValueValidator(type))
            .nullable()
    });
