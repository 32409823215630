import React, { useState } from "react";
import { Typography, TextField, FormHelperText, Button, Select, MenuItem } from "@mui/material";

let delimiters = [
    { value: ",", name: "Comma ," },
    { value: ":", name: "Colon :" },
    { value: ";", name: "Semi-Colon ;" },
    { value: " ", name: "Space " },
    { value: "\t", name: "Tab \\t" }
];

function CsvUploadInfo({ error, onCancel, onNext }) {
    const [latName, setLatName] = useState("");
    const [latNameError, setLatNameError] = useState(false);

    const [lngName, setLngName] = useState("");
    const [lngNameError, setLngNameError] = useState(false);

    const [delimiter, setDelimiter] = useState(",");

    const [projection, setProjection] = useState("");
    const [projectionError, setProjectionError] = useState(false);

    function onLatNameChanged(e) {
        let value = e.target.value;
        setLatName(value);
    }

    function onLngNameChanged(e) {
        let value = e.target.value;
        setLngName(value);
    }

    function onDelimiterChanged(value) {
        setDelimiter(value);
    }

    function onProjectionChanged(e) {
        let value = e.target.value;
        setProjection(value);
    }

    function onCancelClicked() {
        onCancel();
    }

    function onNextClicked() {
        let options = {
            latfield: latName,
            lonfield: lngName,
            delimiter: delimiter,
            projection: projection
        };
        if (validateInfo()) {
            onNext(options);
        }
    }

    function validateInfo() {
        let latError = latName === "";
        setLatNameError(latError);

        let lngError = lngName === "";
        setLngNameError(lngError);

        let projectionError = projection === "";
        setProjectionError(projectionError);

        return !latError && !lngError && !projectionError;
    }

    // function renderSchemaNames(schemaNames) {
    //     return schemaNames.map((schema, index) => {
    //         return (
    //             <MenuItem key={index} value={schema}>
    //                 {schema}
    //             </MenuItem>
    //         );
    //     });
    // }

    return (
        <div className="csv-upload-info">
            <div className="section">
                {error && <FormHelperText error>Latitude or longitude name invalid</FormHelperText>}
                <Typography variant="body1">X column name</Typography>
                <TextField
                    id="full-width"
                    inputProps={{ autoComplete: "no" }}
                    placeholder="Name of the X column"
                    value={lngName}
                    onChange={onLngNameChanged}
                    fullWidth
                    error={error || lngNameError}
                    margin="none"
                />
            </div>
            <div className="section">
                <Typography variant="body1">Y column name</Typography>
                <TextField
                    id="full-width"
                    inputProps={{ autoComplete: "no" }}
                    placeholder="Name of the Y column"
                    value={latName}
                    onChange={onLatNameChanged}
                    fullWidth
                    error={error || latNameError}
                    margin="none"
                />
            </div>

            <div className="section">
                <Typography variant="body1">Delimiter</Typography>
                <Select value={delimiter} fullWidth>
                    {delimiters.map((item) => {
                        return (
                            <MenuItem onClick={() => onDelimiterChanged(item.value)} value={item.value}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>

            <div className="section grow">
                <Typography variant="body1">Projection</Typography>
                <TextField
                    id="full-width"
                    inputProps={{ autoComplete: "no" }}
                    placeholder="eg. EPSG:4326"
                    value={projection}
                    onChange={onProjectionChanged}
                    fullWidth
                    error={projectionError}
                    margin="none"
                />
            </div>

            <div className="button-container">
                <Button color="primary" style={{ marginRight: 8 }} variant="outlined" onClick={onCancelClicked}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={onNextClicked}>
                    Next
                </Button>
            </div>
        </div>
    );
}

export default CsvUploadInfo;
