import { useRasterEditViewStyles } from "./styles";
import { IconButton } from "@mui/material";
import OverflowTip from "components/OverflowTip/OverflowTip";
import CacheStatusChip from "components/CacheStatusChip/CacheStatusChip";
import { StyledTab, StyledTabs } from "components/CustomTabs/CustomTabs";
import keyboardBackspace from "utils/icons/keyboard-backspace.svg";
import { CacheStatus } from "features/rasters/models/CacheStatus";
import { ChangeEvent, FC, useState } from "react";
import { GisRole } from "features/users/model/GisRole";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getCurrentUserRole } from "features/auth/selectors";
import InfoTab from "./Tabs/InfoTab";
import AppsTab from "./Tabs/AppsTab";
import { ColorPalette } from "components/ColorPaletteEditor/ColorPalette";
import { PermissionType } from "../../../groups/model/PermissionType";
import { GeoTiff } from "../../models/GeoTiff";
import { useHistory } from "react-router-dom";

type Props = {
    id: string;
    name: string;
    minZoom: number;
    maxZoom: number;
    permissionType: PermissionType;
    fileSize: number;
    createdUtc: string;
    modifiedUtc: string;
    colorPalette: ColorPalette;
    cacheStatus: CacheStatus;
    geoTiff?: GeoTiff;
    onBack: () => void;
    onNameChange: (name: string) => void;
    onColorPaletteChange: (newPalette: ColorPalette) => void;
};

export const INFO_TAB = "info";
export const APPS_TAB = "applications";

const RasterEditView: FC<Props> = ({
    id,
    name,
    minZoom,
    maxZoom,
    permissionType,
    fileSize,
    modifiedUtc,
    createdUtc,
    colorPalette,
    geoTiff,
    onBack,
    onNameChange,
    onColorPaletteChange,
    cacheStatus
}) => {
    const classes = useRasterEditViewStyles();

    const userRole = useAppSelector(getCurrentUserRole);

    const history = useHistory<any>();
    const initialTab = history.location.state?.tab;
    const [tab, setTab] = useState(initialTab ?? INFO_TAB);

    const changeTab = (e: ChangeEvent<{}>, value: "info" | "applications") => {
        setTab(value);
    };

    const getCacheStatus = () => {
        switch (cacheStatus) {
            case CacheStatus.Uncached:
                return "Uncached";
            case CacheStatus.Caching:
                return "Caching";
            case CacheStatus.Cached:
                return "Cached";
            default:
                return "Uncached";
        }
    };

    return (
        <div className="sidebar-container raster-details">
            <div className="header">
                <div className={classes.stylerHeader}>
                    <IconButton className={classes.backButton} onClick={onBack} size="large">
                        <img alt="" src={keyboardBackspace} />
                    </IconButton>
                    <OverflowTip variant="h2" className={classes.headerText}>
                        {name}
                    </OverflowTip>
                </div>
                <CacheStatusChip status={getCacheStatus()} expanded />
            </div>

            <StyledTabs value={tab} onChange={changeTab} TabIndicatorProps={<div />}>
                <StyledTab label={INFO_TAB} value={INFO_TAB} data-testid={infoTabTestId} />
                {userRole === GisRole.Admin && <StyledTab label={APPS_TAB} value={APPS_TAB} data-testid={appsTabTestId} />}
            </StyledTabs>
            <div className="container">
                <div className="page">
                    {tab === INFO_TAB && (
                        <InfoTab
                            id={id}
                            name={name}
                            minZoom={minZoom}
                            maxZoom={maxZoom}
                            permissionType={permissionType}
                            fileSize={fileSize}
                            createdUtc={createdUtc}
                            modifiedUtc={modifiedUtc}
                            colorPalette={colorPalette}
                            geoTiff={geoTiff}
                            onNameChange={onNameChange}
                            onColorPaletteChange={onColorPaletteChange}
                        />
                    )}
                    {tab === APPS_TAB && userRole === GisRole.Admin && <AppsTab />}
                </div>
            </div>
        </div>
    );
};

export default RasterEditView;

const infoTabTestId = "qa-raster-edit-view-info-tab";
const appsTabTestId = "qa-raster-edit-view-apps-tab";
