import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    preview: {
        cursor: "zoom-in"
    },
    dialog: {
        cursor: "zoom-out"
    },
    closeIcon: {
        position: "absolute",
        cursor: "pointer",
        right: "5px",
        top: "5px"
    },
    image: {
        cursor: "default"
    }
}));
