import GroupIcon from "@mui/icons-material/Group";
import { Checkbox, DialogContentText, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import { Group } from "features/groups/model/Group";
import { FC } from "react";
import { useStyles } from "./styles";

type Props = {
    onGroupToggle: (group: Group) => void;
    selectedGroups: Group[];
    groupsFetching: boolean;
    groups: Group[];
};

const PendingUsersGroupsAssignView: FC<Props> = ({ onGroupToggle, selectedGroups, groups, groupsFetching }) => {
    const classes = useStyles();

    return (
        <LoadingPlaceholder loading={groupsFetching} message="Fetching groups">
            <DialogContentText>Assign groups to the previously selected users</DialogContentText>
            <List>
                {groups.map((group) => (
                    <ListItem
                        className={classes.container}
                        key={group.id}
                        disablePadding
                        secondaryAction={
                            <Checkbox edge="end" checked={selectedGroups.some((g) => g.id === group.id)} onChange={() => onGroupToggle(group)} data-testid={checkbokTestId} />
                        }
                    >
                        <ListItemButton data-testid={groupTestId} onClick={() => onGroupToggle(group)}>
                            <ListItemIcon>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ noWrap: true }}>{group.name}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </LoadingPlaceholder>
    );
};

export default PendingUsersGroupsAssignView;

const groupTestId = "qa-pending-users-modal-group-item";
const checkbokTestId = "qa-pending-users-modal-group-checkbox";
