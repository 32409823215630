import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    listContainer: {
        maxHeight: 300,
        overflow: "auto",
        margin: "16px 0",
        border: "2px solid" + theme.customColors.borderColor,
        borderRadius: 5,
        padding: 12
    },
    modalContainer: {
        minWidth: 550
    }
}));

export default useStyles;
