import { makeStyles } from "@mui/styles";
import styles from "../../scss/themeVariables.module.scss";

export const useSearchBarStyles = makeStyles((theme) => ({
    searchContainer: {
        padding: "12px " + theme.customSpacing.generalSidePadding
    },
    grayBG: {
        backgroundColor: theme.customColors.appBgColor
    },
    searchIcon: {
        color: styles.darkGrey,
        width: 20
    },
    searchField: {
        minWidth: 340,
        "& .MuiFilledInput-input": {
            paddingBlock: 0,
            paddingLeft: 8
        },
        "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart": {
            marginTop: 0
        }
    },
    clearTextButton: {
        display: "none"
    },
    visible: {
        display: "flex"
    },
    cardSearchBar: {
        paddingLeft: 0
    }
}));
