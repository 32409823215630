import { TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import clsx from "clsx";
import React from "react";
import DownArrow from "../../../../utils/icons/arrow-down.svg";
import UpArrow from "../../../../utils/icons/arrow-up.svg";
import CustomTypography from "../../../CustomTypography/CustomTypography";
import { useCustomTableHeadStyles } from "./styles";

const TableHeader = ({ columns, sorting, onSetSort, sortingReverse, additionalActionColumn, additionalStatusColumn }) => {
    const classes = useCustomTableHeadStyles();

    const upArrowClass = (column) => {
        return clsx(classes.sortingArrow, sorting === column && sortingReverse && classes.hidden, sorting !== column && classes.hidden && classes.visibleOnHover);
    };

    const downArrowClass = (column) => {
        return clsx(classes.sortingArrow, sorting === column && !sortingReverse && classes.hidden, sorting !== column && classes.hidden);
    };

    const getDataTestId = (column) => {
        const columnName = column.name.toLocaleLowerCase().replaceAll(" ", "-");
        return columnTestId + columnName;
    };
    return (
        <TableHead>
            <TableRow>
                {additionalStatusColumn && <TableCell size="small" sx={{ padding: 0 }} />}
                {columns.map((column, columnIndex) => {
                    return (
                        <TableCell
                            sortDirection="asc"
                            key={columnIndex}
                            className={clsx(sorting === column.name && classes.active, classes.tableCell)}
                            {...(!!column.sortingFunction && { onClick: () => onSetSort(column), "data-testid": getDataTestId(column) })}
                        >
                            <Tooltip id="tooltip-top" title={!!column.sortingFunction ? `Sort by ${column.name}` : column.name} arrow>
                                <div className={`${classes.button} ${column.type} ${classes.tableCellContent}`}>
                                    {column.component ? (
                                        <div className={sorting === column.name || !column.sortingFunction ? classes.flex : classes.arrowPlaceholder}>{column.component}</div>
                                    ) : (
                                        <CustomTypography
                                            variant="body2"
                                            textWeight="bold"
                                            className={sorting === column.name || !column.sortingFunction ? classes.flex : classes.arrowPlaceholder}
                                        >
                                            {column.name}
                                        </CustomTypography>
                                    )}
                                    {!!column.sortingFunction && (
                                        <>
                                            <img src={UpArrow} className={upArrowClass(column.name)} alt="" />
                                            <img src={DownArrow} className={downArrowClass(column.name)} alt="" />
                                        </>
                                    )}
                                </div>
                            </Tooltip>
                        </TableCell>
                    );
                })}
                {additionalActionColumn && <TableCell size="small" />}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;

const columnTestId = "qa-custom-table-head-column-";
