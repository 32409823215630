import { useEditRasterMutation, useFetchRasterByIdQuery } from "features/rasters/api";
import { useHistory, useParams } from "react-router-dom";
import RasterEditView from "./RasterEditView";
import { Button, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { PermissionType } from "features/groups/model/PermissionType";
import SaveIcon from "@mui/icons-material/Save";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuAndModals from "./Modals/MenuAndModals";
import { handleError } from "utils/networkErrorUtils";
import toastr from "components/CustomToastr/CustomToastr";
import { useRasterEditViewStyles } from "./styles";
import { ColorPalette } from "components/ColorPaletteEditor/ColorPalette";
import { Formik, FormikProps } from "formik";
import useMapManager from "../../hooks/useMapManager";
import { CacheStatus } from "../../models/CacheStatus";

type Params = {
    clientId: string;
    projectId: string;
    rasterId: string;
};

type HistoryState = {
    prevLocation: string | undefined;
};

type FormValues = {
    name: string;
    colorPalette: ColorPalette;
};

const RasterEditViewContainer = () => {
    const classes = useRasterEditViewStyles();
    const { rasterId } = useParams<Params>();
    const { addRasterLayer, removeRasterLayer } = useMapManager();
    const { data: raster, isFetching } = useFetchRasterByIdQuery(rasterId, { skip: !rasterId });
    const [updateRaster] = useEditRasterMutation();

    useEffect(() => {
        if (raster && raster.cacheStatus === CacheStatus.Cached) {
            addRasterLayer(raster);
        }

        return () => {
            if (raster && raster.cacheStatus === CacheStatus.Caching) {
                removeRasterLayer(raster);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [raster?.cacheStatus]);

    useEffect(() => {
        if (raster?.id)
            return () => {
                removeRasterLayer(raster);
            };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [raster?.id]);

    const history = useHistory<HistoryState>();
    const prevLocation = history.location.state?.prevLocation;

    const { clientId, projectId } = useParams<Params>();

    const onBack = () => {
        if (prevLocation) {
            history.push(prevLocation);
        } else {
            history.push(`/${clientId}/${projectId}/rasters`);
        }
    };

    const onFormSubmit = (values: FormValues) => {
        const updatedColorPalette = { ...values.colorPalette };

        updatedColorPalette.colorTable = updatedColorPalette.colorTable.map((entry) => {
            if (entry.color.length === 9) {
                const alphaHex = entry.color.slice(-2);
                const alphaValue = parseInt(alphaHex, 16);
                const updatedColor = entry.color.slice(0, -2);
                return { ...entry, color: updatedColor, alpha: alphaValue };
            }
            return entry;
        });

        updateRaster({
            id: rasterId,
            name: values.name,
            colorPalette: updatedColorPalette
        })
            .catch((err: any) => handleError(err))
            .then((_) => toastr.success("Raster updated"));
    };

    const [actionsAnchor, setActionsAnchor] = useState<Element | null>(null);

    const onOpenActions = (e: any) => {
        setActionsAnchor(e.currentTarget);
    };

    const onCloseActions = () => {
        setActionsAnchor(null);
    };

    return isFetching || !raster ? (
        <LinearProgress className="no-margin-progress" />
    ) : (
        <div className="sidebar-container raster-details">
            <Formik initialValues={{ name: raster.name, colorPalette: raster.colorPalette }} onSubmit={onFormSubmit} enableReinitialize={true}>
                {(formikProps: FormikProps<FormValues>) => (
                    <>
                        <RasterEditView
                            id={rasterId}
                            name={formikProps.values.name}
                            minZoom={raster.minZoom}
                            maxZoom={raster.maxZoom}
                            permissionType={raster.permissionType}
                            fileSize={raster.fileSize}
                            createdUtc={raster.createdUtc}
                            modifiedUtc={raster.modifiedUtc}
                            colorPalette={formikProps.values.colorPalette}
                            cacheStatus={raster.cacheStatus}
                            geoTiff={raster.geoTiff}
                            onBack={onBack}
                            onNameChange={(name) => formikProps.setFieldValue("name", name)}
                            onColorPaletteChange={(colorPalette) => formikProps.setFieldValue("colorPalette", colorPalette)}
                        />
                        <div className="container container--bottom">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => formikProps.submitForm()}
                                className={classes.buttonSpacing}
                                data-testid={saveButtonTestId}
                                disabled={raster.permissionType < PermissionType.WRITE_READ || raster.cacheStatus === CacheStatus.Caching}
                            >
                                <SaveIcon className={classes.icon} />
                                Save Changes
                            </Button>
                            <Button variant="contained" color="secondary" onClick={onOpenActions} data-testid={actionsButtonTestId}>
                                <MoreHorizIcon className="button-icon" />
                                Actions
                            </Button>
                        </div>
                        <MenuAndModals raster={raster} actionsAnchor={actionsAnchor} setActionsAnchor={setActionsAnchor} onCloseActions={onCloseActions} />
                    </>
                )}
            </Formik>
        </div>
    );
};

export default RasterEditViewContainer;

const saveButtonTestId = "qa-raster-edit-view-save-button";
const actionsButtonTestId = "qa-raster-edit-view-actions-button";
