import baseAtlasApi from "../../store/baseAtlasApi";
import { MetadataProperty } from "./models/MetadataProperty";

export const metadataApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        fetchMetadataSchema: build.query<MetadataProperty[], void>({
            query: () => ({ url: "metadata/1", method: "GET" })
        })
    })
});

export const { useFetchMetadataSchemaQuery } = metadataApi;
