import { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import toastr from "../../../../../../components/CustomToastr/CustomToastr";
import { handleError } from "../../../../../../utils/networkErrorUtils";
import CustomModal from "../../../../../../components/CustomModal/CustomModal";
import { useCreateGroupMutation } from "features/groups/api";

type CreateGroupModalProps = {
    handleClose: () => void;
    open: boolean;
};

const CreateGroupModal = ({ handleClose, open }: CreateGroupModalProps) => {
    const [name, setName] = useState("");

    const [createGroup] = useCreateGroupMutation();

    const changeName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const validateInfo = () => {
        let nameValid = name !== "";
        return nameValid;
    };

    const createGroupHandler = () => {
        if (!validateInfo()) {
            return;
        }

        createGroup(name).then(
            () => {
                setName("");
                handleClose();
                toastr.success("Group created");
            },
            (err) => {
                handleError(err);
            }
        );
    };

    return (
        <div>
            <CustomModal
                handleClose={handleClose}
                isOpen={open}
                onConfirm={createGroupHandler}
                dialogTitle={"Create group"}
                dialogType={"create"}
                disabled={!name}
                dialogTitleProps={dialogTitleProps}
                closeIconProps={closeIconProps}
                cancelButtonProps={cancelButtonProps}
                confirmButtonProps={confirmButtonProps}
            >
                <TextField
                    id="filled-required"
                    placeholder="Name of the Group"
                    value={name}
                    onChange={changeName}
                    fullWidth
                    variant="filled"
                    label="Group Name"
                    data-testid={textFieldTestId}
                />
            </CustomModal>
        </div>
    );
};

export default CreateGroupModal;

const dialogTitleProps = { "data-testid": "qa-group-create-modal-header" };
const closeIconProps = { "data-testid": "qa-group-create-modal-close-icon" };
const cancelButtonProps = { "data-testid": "qa-group-create-modal-cancel-button" };
const confirmButtonProps = { "data-testid": "qa-group-create-modal-create-button" };
const textFieldTestId = "qa-group-create-modal-textfield";
