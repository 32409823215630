import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
    return {
        dialog: {
            width: "40%"
        },

        dialogTitle: { display: "flex", alignItems: "center", gap: "4px" },
        description: {
            color: theme.customColors.darkGrey
        },
        selectAllSubprojects: {
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1)
        },
        selectAllSubprojectsInfoIcon: {
            fontSize: "inherit",
            color: theme.customColors.darkGrey
        },
        inputLabel: {
            color: theme.customColors.darkGrey
        },
        flex: {
            display: "flex",
            justifyContent: "space-between"
        },
        clickable: {
            cursor: "pointer"
        },
        stepperContainer: {
            paddingTop: theme.spacing(2)
        }
    };
});
