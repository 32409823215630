import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
    return {
        configure: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30%"
        },
        configureIcon: {
            fontSize: 40,
            color: theme.customColors.darkGrey,
            marginBottom: theme.spacing(1)
        },
        configureDescription: {
            color: theme.customColors.darkGrey,
            marginBottom: theme.spacing(2)
        },
        configureButton: {
            minHeight: "50px",
            paddingInline: theme.spacing(2)
        }
    };
});
