import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { resetProjectData } from "actions/globalActions";
import { HealthEnum } from "features/health/model/HealthEnum";
import { fetchHealth } from "./actions";

type HealthState = {
    health: HealthEnum;
    fetching: boolean;
};

const initialState: HealthState = {
    health: HealthEnum.Unknown,
    fetching: false
};

const healthSlice = createSlice({
    name: "health",
    initialState,
    reducers: {
        setHealth: (state, { payload: health }: PayloadAction<HealthEnum>) => {
            state.health = health;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHealth.fulfilled, (state, { payload }) => {
                state.health = payload;
            })
            .addCase(fetchHealth.rejected, (state, { payload }) => {
                state.health = payload ?? HealthEnum.Unhealthy;
            })
            .addCase(fetchHealth.pending, (state) => {
                state.fetching = true;
            })
            .addCase(resetProjectData, () => initialState)
            .addMatcher(isAnyOf(fetchHealth.fulfilled, fetchHealth.rejected), (state) => {
                state.fetching = false;
            });
    }
});

export const { setHealth } = healthSlice.actions;

export default healthSlice.reducer;
