import { Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import DataReplacerModal from "../../../../../components/DataReplacer/DataReplacerModal/DataReplacerModal";
import DownloaderModal from "../../../../../components/downloader/downloader";
import ChangeSchemaModal from "../../../../../components/changeSchema/changeSchema";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import CachedIcon from "@mui/icons-material/Autorenew";
import { generateCache } from "../../../../../actions/datasets";
import { PermissionType } from "features/groups/model/PermissionType";

const cacheDatasetTooltipTitle = {
    0: "",
    1: "Dataset is already in the process of caching",
    2: "Dataset is already cached"
};

const MenuAndModals = ({ dataset, columns, actionsAnchor, setActionsAnchor, onCloseActions }) => {
    const [replacerOpen, setReplacerOpen] = useState(false);
    const [downloaderOpen, setDownloaderOpen] = useState(false);
    const [changeSchemaOpen, setChangeSchemaOpen] = useState(false);

    const dispatch = useDispatch();

    const onGenerateDataset = () => {
        dispatch(generateCache(dataset.id));
    };

    const onOpenReplacer = () => {
        setReplacerOpen(true);
        setActionsAnchor(null);
    };

    const onCloseReplacer = () => {
        setReplacerOpen(false);
    };

    const onOpenDownloader = () => {
        setDownloaderOpen(true);
    };

    const onCloseDownloader = () => {
        setDownloaderOpen(false);
    };

    const onOpenChangeSchema = () => {
        setChangeSchemaOpen(true);
    };

    const onCloseChangeSchema = () => {
        setChangeSchemaOpen(false);
    };

    return (
        <>
            <Menu
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                anchorEl={actionsAnchor}
                open={Boolean(actionsAnchor)}
                onClose={onCloseActions}
                PaperProps={{ square: true }}
            >
                <MenuItem onClick={onOpenReplacer} className="menu-button" data-testid={replaceButtonTestId} disabled={dataset.permissionType < PermissionType.WRITE_READ}>
                    <PublishIcon className="icon" />
                    <Typography variant="subtitle1">Replace Data</Typography>
                </MenuItem>
                <MenuItem onClick={onOpenDownloader} className="menu-button" data-testid={downloadButtonTestId} disabled={dataset.permissionType < PermissionType.READ}>
                    <GetAppIcon className="icon" />
                    <Typography variant="subtitle1">Download Data</Typography>
                </MenuItem>

                <MenuItem onClick={onOpenChangeSchema} className="menu-button" data-testid={changeSchemaButtonTestId} disabled={dataset.permissionType < PermissionType.OWNER}>
                    <EditIcon className="icon" />
                    <Typography variant="subtitle1">Change Schema</Typography>
                </MenuItem>

                <Tooltip title={dataset.permissionType >= PermissionType.WRITE_READ ? cacheDatasetTooltipTitle[dataset.cacheStatus] : ""}>
                    <div>
                        <MenuItem
                            onClick={onGenerateDataset}
                            disabled={dataset.cacheStatus !== 0 || dataset.permissionType < PermissionType.WRITE_READ}
                            className="menu-button"
                            data-testid={cacheDatasetButtonTestId}
                        >
                            <CachedIcon className={`icon flip`} />
                            <Typography variant="subtitle1">Cache Dataset</Typography>
                        </MenuItem>
                    </div>
                </Tooltip>
            </Menu>
            <DataReplacerModal open={replacerOpen} datasetId={dataset.id} columnDefinitions={columns} handleClose={onCloseReplacer} />
            <DownloaderModal open={downloaderOpen} dataset={dataset} handleClose={onCloseDownloader} />
            <ChangeSchemaModal open={changeSchemaOpen} datasetId={dataset.id} schemaName={dataset.schemaName} handleClose={onCloseChangeSchema} />
        </>
    );
};

export default MenuAndModals;

const replaceButtonTestId = "qa-dataset-edit-view-replace-button";
const downloadButtonTestId = "qa-dataset-edit-view-download-button";
const changeSchemaButtonTestId = "qa-dataset-edit-view-change-schema-button";
const cacheDatasetButtonTestId = "qa-dataset-edit-view-cache-dataset-button";
