import { useStyles } from "./styles";
import { FC, useState } from "react";
import DprIntegrationDescription from "../DprIntegrationDescription/DprIntegrationDescription";
import { DprDataset } from "features/dprIntegration/models/DprDataset";
import CollapsibleCard from "components/CollapsibleCard/CollapsibleCard";
import { Divider, Skeleton, Stack } from "@mui/material";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import FlagIcon from "@mui/icons-material/Flag";
import CustomTypography from "components/CustomTypography/CustomTypography";
import Milestones from "../ConfigureIntegration/Milestones/Milestones";
import AddMilestoneModal from "../AddMilestoneModal/AddMilestoneModal";
import { useCreateMilestoneMutation } from "features/dprIntegration/api";
import { DprProgressMilestone } from "features/dprIntegration/models/DprProgressMilestone";
import { useParams } from "react-router-dom";
import toastr from "components/CustomToastr/CustomToastr";

type Props = {
    dprDataset: DprDataset;
    addMilestoneModalOpen: boolean;
    setAddMilestoneModalOpen: (val: boolean) => void;
};

type Params = {
    datasetId: string;
};

const DprIntegration: FC<Props> = ({ dprDataset, addMilestoneModalOpen, setAddMilestoneModalOpen }) => {
    const classes = useStyles();

    const [locationAndStatusSectionOpen, setLocationAndStatusSectionOpen] = useState(false);

    const [createMilestone, { isLoading }] = useCreateMilestoneMutation();

    const { datasetId } = useParams<Params>();

    const onAdd = (newMilestone: DprProgressMilestone) => {
        return createMilestone({
            datasetId,
            displayName: newMilestone.displayName,
            group: newMilestone.group,
            name: newMilestone.name,
            milestoneType: newMilestone.milestoneType,
            statusTrackerName: newMilestone.statusTrackerName,
            subprojects: newMilestone.subprojects
        }).then((res) => {
            if ("error" in res) {
                toastr.error(res.error.message || "Failed to add the milestone");
                return Promise.reject();
            }

            toastr.success("Milestone added successfully");
        });
    };

    return (
        <>
            <DprIntegrationDescription className={classes.marginBottom} />
            <div className="shadow-section">
                <CollapsibleCard
                    innerPadding={0}
                    onToggleClick={() => setLocationAndStatusSectionOpen(!locationAndStatusSectionOpen)}
                    title={"Location & Status"}
                    isToggled={locationAndStatusSectionOpen}
                    unmountOnExit
                >
                    <Stack gap={2} mt={2}>
                        <ReadOnlyField label="Location column name" text={dprDataset.locationColumn.name} />

                        <ReadOnlyField label="Status column name" text={dprDataset.progressColumn.name} />
                    </Stack>
                </CollapsibleCard>
            </div>

            <Divider sx={{ mt: 2 }} />

            <div className={classes.title}>
                <FlagIcon className={classes.icon} />
                <CustomTypography variant="body1">Milestones</CustomTypography>
            </div>

            <Milestones milestones={dprDataset.milestones} />
            {isLoading && <Skeleton height={100} />}

            <AddMilestoneModal
                open={addMilestoneModalOpen}
                handleClose={() => setAddMilestoneModalOpen(false)}
                onAdd={onAdd}
                newBitPosition={-1} // computed on backend
                dialogType="save"
            />
        </>
    );
};

export default DprIntegration;
