import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addMetadataPropertyThunk } from "../../../../../actions/metadataSchema";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import CustomTypography from "../../../../../components/CustomTypography/CustomTypography";
import LoadingPlaceholder from "../../../../../components/LoadingPlaceholder/LoadingPlaceholder";
import { getIsMetadataLoading, getPropertiesNamesSelector } from "../../../../../selectors/metadataSchema";
import { metadataTypes, METADATA_SCHEMA_ID } from "../../../../../utils/constants/metadata";
import { lowerTagOpacity } from "../../../../../utils/metadataUtils";
import { MetadataPropertySchema } from "../../../../../utils/validators/metadata";
import TagsCreator from "../tags/TagsCreator/TagsCreator";
import { unwrapResult } from "@reduxjs/toolkit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useStyles } from "../styles";

const AddPropertyModal = ({ open, setModalOpen }) => {
    const classes = useStyles();

    const propertiesNames = useSelector(getPropertiesNamesSelector);
    const loading = useSelector(getIsMetadataLoading);

    const dispatch = useDispatch();

    if (!open) return null;

    const onMenuItemClick = (formikProps, type) => () => formikProps.setValues({ ...formikProps.values, type, value: type === metadataTypes.TAG_LIST ? [] : null }, false);

    const onSubmit = (property) =>
        dispatch(addMetadataPropertyThunk({ metadataProperty: property, metadataSchemaId: METADATA_SCHEMA_ID }))
            .then(unwrapResult)
            .then(() => setModalOpen(false))
            .catch(() => toastr.error("Failed to add property"));

    const PropertyValueEditor = ({ property, onValueChanged }) => {
        switch (property.type) {
            case metadataTypes.TAG_LIST:
                return (
                    <>
                        <TagsCreator tags={property.value} onAddTag={(tag) => onValueChanged([...property.value, tag])} />
                        <div className="tags">
                            {property.value.map((tag, index) => (
                                <div key={index} style={{ color: tag.color, backgroundColor: lowerTagOpacity(tag.color) }} className="tag">
                                    <span>{tag.name}</span>
                                    <CloseIcon className="remove-btn" onClick={() => onValueChanged([...property.value.filter((t) => t.name !== tag.name)])} />
                                </div>
                            ))}
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Formik
            initialValues={{ name: "", type: metadataTypes.TEXT, value: null, mandatory: false, hidden: false }}
            onSubmit={onSubmit}
            validationSchema={MetadataPropertySchema(propertiesNames)}
            validateOnMount
        >
            {(formikProps) => (
                <CustomModal
                    handleClose={() => setModalOpen(false)}
                    isOpen={open}
                    onConfirm={formikProps.submitForm}
                    disabled={!formikProps.isValid}
                    dialogTitle={"Add new property"}
                    dialogType={"add"}
                >
                    <div className="content">
                        <LoadingPlaceholder loading={loading} message="Adding metadata property">
                            <div className="property">
                                <FormControl className="type" variant="filled" fullWidth>
                                    <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                                    <Select value={formikProps.values.type} label="Name" variant="filled" data-testid={selectTestId}>
                                        <MenuItem onClick={onMenuItemClick(formikProps, metadataTypes.TEXT)} value={metadataTypes.TEXT} data-testid={textTestId}>
                                            Text
                                        </MenuItem>
                                        <MenuItem onClick={onMenuItemClick(formikProps, metadataTypes.NUMBER)} value={metadataTypes.NUMBER} data-testid={numberTestId}>
                                            Number
                                        </MenuItem>
                                        <MenuItem onClick={onMenuItemClick(formikProps, metadataTypes.DATE)} value={metadataTypes.DATE} data-testid={dateTestId}>
                                            Date
                                        </MenuItem>
                                        <MenuItem onClick={onMenuItemClick(formikProps, metadataTypes.TAG_LIST)} value={metadataTypes.TAG_LIST} data-testid={tagListTestId}>
                                            Tag List
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    id="filled-required"
                                    value={formikProps.values.name}
                                    name="name"
                                    inputProps={{ "data-testid": inputFieldTestId }}
                                    onChange={formikProps.handleChange}
                                    error={!!formikProps.errors.name && !!formikProps.touched.name}
                                    label="Name"
                                    variant="filled"
                                    onBlur={formikProps.handleBlur}
                                    helperText={formikProps.errors.name || ""}
                                />
                            </div>
                            <PropertyValueEditor property={formikProps.values} onValueChanged={(newValue) => formikProps.setFieldValue("value", newValue)} />
                            <Box display="flex" alignItems="center">
                                <Switch
                                    name="mandatory"
                                    checked={formikProps.values.mandatory}
                                    onChange={(e) => formikProps.setFieldValue("mandatory", e.target.checked, false)}
                                    data-testid={mandatorySwitchTestId}
                                />
                                <CustomTypography textWeight="semibold"> Mandatory </CustomTypography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                {formikProps.values.hidden ? (
                                    <VisibilityOffIcon
                                        className={classes.visibilityIcon}
                                        onClick={() => formikProps.setFieldValue("hidden", false)}
                                        data-testid={visibilityTestId}
                                    />
                                ) : (
                                    <VisibilityIcon className={classes.visibilityIcon} onClick={() => formikProps.setFieldValue("hidden", true)} data-testid={visibilityTestId} />
                                )}
                                <CustomTypography textWeight="semibold"> {formikProps.values.hidden ? "Hidden" : "Visible"} </CustomTypography>
                            </Box>
                        </LoadingPlaceholder>
                    </div>
                </CustomModal>
            )}
        </Formik>
    );
};

export default AddPropertyModal;

const selectTestId = "qa-add-metadata-modal-select";
const inputFieldTestId = "qa-add-metadata-modal-input-field";
const mandatorySwitchTestId = "qa-add-metadata-modal-mandatory";
const visibilityTestId = "qa-add-metadata-modal-visibility";
const textTestId = "qa-add-metadata-modal-text";
const numberTestId = "qa-add-metadata-modal-number";
const dateTestId = "qa-add-metadata-modal-date";
const tagListTestId = "qa-add-metadata-modal-taglist";
