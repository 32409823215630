import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import fileCopyIcon from "../../../../../../../utils/icons/copy-icon.svg";
import { Button, Tooltip } from "@mui/material";
import TypeChip from "../../../../../../../components/TypeChip/TypeChip";
import { DATA_TYPES } from "../../../../../../../utils/constants/dataType";
import { useStyles } from "./styles";

import toastr from "../../../../../../../components/CustomToastr/CustomToastr";
import OverflowTip from "../../../../../../../components/OverflowTip/OverflowTip";
import clsx from "clsx";
import BasemapThumbnail from "../../../../../../../components/BasemapThumbnail/BasemapThumbnail";

const BasemapCard = ({ basemap, basemapCount, deleteClick, disabled = false }) => {
    const classes = useStyles();

    const deleteDisabled = basemapCount === 1 || disabled;

    const onDeleteClick = () => {
        if (deleteDisabled) return;

        const toastrConfirmOptions = {
            onOk: () => deleteClick(basemap),
            onCancel: () => {}
        };
        toastr.confirm("Are you sure you want to remove basemap " + basemap.title + " from the app?", toastrConfirmOptions);
    };

    const getTooltipTitle = () => {
        if (disabled) return "";

        if (basemapCount === 1) return "There needs to be at least a basemap";

        return "Remove from app";
    };

    return (
        <div className="basemap">
            <DragIndicatorIcon className="drag-indicator" fontSize="small" />
            <div className="map-preview">
                <BasemapThumbnail basemapUrl={basemap.url} basemapType={basemap.type} />
            </div>
            <div className="basemap-details">
                <div className="basemap-title">
                    <OverflowTip variant="body2" textWeight="semibold">
                        {basemap.title}
                    </OverflowTip>
                </div>
                <div className="basemap-url">
                    <div>{basemap.url}</div>
                    <Tooltip title="Copy to clipboard">
                        <Button
                            className="copy-button"
                            onClick={() => {
                                navigator.clipboard.writeText(basemap.url);
                                toastr.success("The text has been copied");
                            }}
                            data-testid={copyBasemapUrlTestId}
                        >
                            <img alt="" src={fileCopyIcon} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <TypeChip type={basemap.type === DATA_TYPES.vector ? DATA_TYPES.vector : DATA_TYPES.raster} variant="large" />
            <Tooltip title={getTooltipTitle()}>
                <div className={clsx(classes.deleteBtn, { [classes.disabled]: deleteDisabled })} onClick={basemapCount !== 1 && onDeleteClick} data-testid={deleteBasemapTestId}>
                    <DeleteIcon color="error" />
                </div>
            </Tooltip>
        </div>
    );
};

export default BasemapCard;

const deleteBasemapTestId = "qa-app-edit-view-settings-delete-basemap";
const copyBasemapUrlTestId = "qa-app-edit-view-settings-copy-basemap-url";
