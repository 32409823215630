import { FC, MouseEventHandler, useEffect, useState } from "react";
import { useStyles } from "./styles";
import CollapsibleCard from "components/CollapsibleCard/CollapsibleCard";
import { StyledTab, StyledTabs } from "components/CustomTabs/CustomTabs";
import GroupWithPermissions from "../GroupWithPermissions/GroupWithPermissions";
import { GroupWithPermissions as GroupWithPermissionsType } from "features/groups/model/GroupWithPermissions";
import { Resource } from "../UserGroupsDrawer";
import { ResourceWithPermission } from "features/groups/model/ResourceWithPermission";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import EditIndividualPermissions from "../EditIndividualPermissions/EditIndividualPermissions";
import { useFetchUserByEmailQuery } from "../../../api";

type Props = {
    username: string;
    group: GroupWithPermissionsType;
    searchValue: string;
    searchBy: Resource;
};

const UserGroup: FC<Props> = ({ group, searchValue, searchBy, username }) => {
    const classes = useStyles();
    const [tab, setTab] = useState(Resource.Applications);
    const [isToggled, setIsToggled] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const { refetch } = useFetchUserByEmailQuery(username);

    useEffect(() => {
        if (!searchValue) return;

        if (searchBy === Resource.Groups) {
            setIsToggled(false);
            return;
        }

        setIsToggled(true);
        setTab(searchBy);
    }, [searchValue, searchBy]);

    const handleTabChange = (_: React.SyntheticEvent, newValue: Resource) => {
        setTab(newValue);
    };

    const getResourcesWithPermissions = () => {
        switch (tab) {
            case Resource.Applications:
                return group.applicationPermissions;
            case Resource.Datasets:
                return group.datasetPermissions;
            case Resource.Rasters:
                return group.rasterPermissions;
            default:
                return [];
        }
    };

    const filterFunction = () => {
        if (searchBy === Resource.Groups) {
            return (_: ResourceWithPermission) => true;
        }
        return (x: ResourceWithPermission) => x.name.toLocaleLowerCase().includes(searchValue.toLowerCase());
    };

    const resourcesWithPermissions = getResourcesWithPermissions().filter(filterFunction());

    const disableNavigation = !!searchValue && searchBy !== Resource.Groups;

    const editPermissionClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        setIsToggled(true);

        if (isEditing && changesMade) refetch();

        setIsEditing(!isEditing);
    };

    const EditPermissionsButton = () => (
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            {group.isIndividualGroup ? "Individual permissions" : group.name}
            {group.isIndividualGroup && (
                <Button onClick={editPermissionClick}>
                    <EditIcon fontSize="small" sx={{ marginRight: 1 }} /> {isEditing ? "Finish editing" : "Edit Permissions"}
                </Button>
            )}
        </Box>
    );

    return (
        <div className={classes.section}>
            <CollapsibleCard
                id={group.isIndividualGroup ? "Individual permissions" : group.name}
                onToggleClick={() => setIsToggled(!isToggled)}
                isToggled={isToggled}
                unmountOnExit
                innerPadding={0}
                paddingInline={0}
                Component={<EditPermissionsButton />}
            >
                <StyledTabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                    <StyledTab disabled={disableNavigation} value={Resource.Applications} label={Resource.Applications} data-testid={applicationsTabTestId} />
                    <StyledTab disabled={disableNavigation} value={Resource.Datasets} label={Resource.Datasets} data-testid={datasetsTabTestId} />
                    <StyledTab disabled={disableNavigation} value={Resource.Rasters} label={Resource.Rasters} data-testid={rastersTabTestId} />
                </StyledTabs>

                {isEditing ? (
                    <EditIndividualPermissions groupId={group.id} page={tab} onChangesMade={() => setChangesMade(true)} />
                ) : (
                    <GroupWithPermissions resourcesWithPermissions={resourcesWithPermissions} searching={disableNavigation} />
                )}
            </CollapsibleCard>
        </div>
    );
};

export default UserGroup;

const applicationsTabTestId = "qa-user-details-drawer-applications-tab";
const datasetsTabTestId = "qa-user-details-drawer-datasets-tab";
const rastersTabTestId = "qa-user-details-drawer-rasters-tab";
