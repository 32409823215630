import { TableCell } from "@mui/material";
import React, { forwardRef } from "react";
import { useStickyCellStyles } from "./styles";
import clsx from "clsx";

const StickyCell = (props, ref) => {
    const classes = useStickyCellStyles();
    return (
        <TableCell
            {...props}
            padding="none"
            className={clsx(classes.stickyCell, classes.container, props.className)}
            onClick={(e) => {
                e.stopPropagation();
            }}
            ref={ref}
        />
    );
};

export default forwardRef(StickyCell);
