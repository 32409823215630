export const atlasApiTags = {
    groupTags: {
        GROUP_LIST: "GroupsList" as const,
        GROUP: "Group" as const,
        USERS: "GroupUsers" as const,
        APPLICATIONS_PERMISSIONS: "ApplicationsPermissions" as const,
        RASTERS_PERMISSIONS: "RastersPermissions" as const,
        DATASETS_PERMISSIONS: "DatasetsPermissions" as const
    },
    rasterTags: {
        RASTER_LIST: "RasterList",
        RASTER: "Raster"
    },
    userTags: {
        CORE_USER: "CoreUser",
        GIS_USER: "GisUser",
        USER_DETAILS: "UserDetails"
    },
    dprDatasetTags: {
        DPR_DATASET: "DprDataset"
    }
};
