import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { SerializedError } from "@reduxjs/toolkit";

const axiosBaseQuery =
    (
        baseUrl: string,
        apiClient: AxiosInstance
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig["method"];
            responseType?: AxiosRequestConfig["responseType"];
            data?: AxiosRequestConfig["data"];
            params?: AxiosRequestConfig["params"];
            cancelToken?: AxiosRequestConfig["cancelToken"];
            onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
            onDownloadProgress?: AxiosRequestConfig["onDownloadProgress"];
            transformResponse?: AxiosRequestConfig["transformResponse"];
        },
        unknown,
        SerializedError
    > =>
    ({ url, method, responseType, data, params, cancelToken, onUploadProgress, onDownloadProgress, transformResponse }) =>
        new Promise((resolve, reject) => {
            apiClient
                .request({
                    url: baseUrl + url,
                    method,
                    responseType,
                    data,
                    params,
                    cancelToken,
                    onUploadProgress,
                    onDownloadProgress,
                    transformResponse
                })
                .then((result) => {
                    resolve({ data: result.data });
                })
                .catch((axiosError) => {
                    const err = axiosError as AxiosError;

                    const error: SerializedError = {
                        code: err.response?.status?.toString(),
                        message: err.response?.data.error || err.response?.data.title || err.response?.data || "An unexpected error occurred"
                    };
                    reject(error);
                });
        });

export const isApiError = (error: any): error is SerializedError => error !== undefined && "message" in error;

export default axiosBaseQuery;
