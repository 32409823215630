import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import BasemapCard from "./components/BasemapCard/BasemapCard";
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from "react-beautiful-dnd";
import AddBasemapTool from "./components/AddBasemapTool/AddBasemapTool";

import { deleteAppBasemap, moveAppBasemap, setSelectedTool } from "reducers/appData/appData";
import { APP_EDIT_TOOLS } from "utils/constants/appEditTools";
import { deleteAppBasemapThunk } from "actions/apps";
import { handleError } from "utils/networkErrorUtils";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC } from "react";
import { ComponentProps } from "../AppSettingsView";
import { useStyles } from "./styles";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getApp, getSelectedTool } from "selectors/appData";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { Basemap } from "models/basemap/Basemap";

const BasemapPicker: FC<ComponentProps> = ({ disabled }) => {
    const classes = useStyles();

    const selectedTool = useAppSelector(getSelectedTool);
    const currentApp = useAppSelector(getApp);

    const dispatch = useAppDispatch();

    const onOpenTool = (toolName: string) => {
        dispatch(setSelectedTool(toolName));
    };

    const generateBasemapCard = (item: Basemap, index: number) => {
        return (
            <div className="row" key={item.url}>
                <Draggable draggableId={item.url} index={index} isDragDisabled={disabled}>
                    {(provided) => (
                        <div className="draggable-section" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} data-testid={basemapCardTestId}>
                            <BasemapCard basemap={item} disabled={disabled} deleteClick={() => onDeleteBasemapFromApp(currentApp.id, item.id)} basemapCount={basemapCount} />
                        </div>
                    )}
                </Draggable>
            </div>
        );
    };

    const onDeleteBasemapFromApp = (appId: string, basemapId: string) => {
        // TODO: the ts-ignore should be removed after refactoring to ts the file where the thunks are
        // @ts-ignore
        dispatch(deleteAppBasemapThunk({ appId, basemapId }))
            .then(unwrapResult)
            .then((res) => {
                dispatch(deleteAppBasemap(basemapId));
            })
            .catch((err) => handleError(err));
    };

    const onDragEnd: OnDragEndResponder = (result) => {
        const { destination, source } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        dispatch(moveAppBasemap({ sourceIndex: source.index, destinationIndex: destination.index }));
    };

    const basemapCount = currentApp.basemaps.length;
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="basemap-picker">
                <Droppable droppableId={"basemap-droppable"} type="basemap" isDropDisabled={disabled}>
                    {(provided) => (
                        <div className="basemap-container" {...provided.droppableProps} ref={provided.innerRef}>
                            {currentApp.basemaps.map((basemap: Basemap, index: number) => generateBasemapCard(basemap, index))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                <div className="button-container">
                    <Button
                        className="button"
                        variant="text"
                        color="primary"
                        disabled={disabled}
                        onClick={() => onOpenTool(APP_EDIT_TOOLS.addBasemap)}
                        data-testid={addBasemapTestId}
                    >
                        <AddIcon className="left-icon" fontSize="small" />
                        Add Basemap
                    </Button>
                </div>
            </div>
            {selectedTool === APP_EDIT_TOOLS.addBasemap && (
                <div className={classes.tools}>
                    <AddBasemapTool />
                </div>
            )}
        </DragDropContext>
    );
};

export default BasemapPicker;

const basemapCardTestId = "qa-app-edit-view-settings-basemap-card";
const addBasemapTestId = "qa-app-edit-view-settings-add-basemap";
