import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListView from "components/listView";
import { Divider, FormControl, InputLabel, Popover, Select, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CustomModal from "components/CustomModal/CustomModal";
import { useStyles } from "./styles";
import { useFetchRastersQuery } from "../../../rasters/api";
import { getLibraryBasemaps } from "../../../../selectors/basemaps";
import { DATA_TYPES } from "../../../../utils/constants/dataType";
import RasterListItem from "../../../../views/apps/appEditView/components/rasterListItem";
import config from "config";
import { addLibraryBasemap, fetchLibraryBasemaps } from "../../../../actions/basemaps";
import { handleAddLibraryBasemap } from "../../../../reducers/basemaps";
import { handleError } from "../../../../utils/networkErrorUtils";

const UploadBasemapModal = ({ open, setModalOpen }) => {
    const titleRef = useRef();
    const [title, setTitle] = useState("");
    const [type, setType] = useState("vector");
    const { data: rasters = [] } = useFetchRastersQuery();
    const basemaps = useSelector(getLibraryBasemaps);
    const urlRef = useRef();

    const [url, setUrl] = useState("");
    const [urlErrorAnimation, setUrlErrorAnimation] = useState(false);

    const [errorReason, setErrorReason] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const [errors, setErrors] = useState({ url: false, title: false });

    const classes = useStyles();

    const rastersNotInBasemaps = useMemo(() => {
        const basemapMap = basemaps.reduce((a, b) => {
            const matches = b.url.match(/raster\/([a-z\d-]+)/);

            if (matches !== null) {
                const UUID = matches[1];

                a[UUID] = true;
            }

            return a;
        }, {});

        return rasters.filter((x) => !basemapMap[x.id]);
    }, [rasters, basemaps]);

    const dispatch = useDispatch();

    function onPasteUrl(event) {
        event.preventDefault();

        let paste = (event.clipboardData || window.clipboardData).getData("text");

        let split = paste.split("//");
        let protocol = split[0];
        let hostname = split[1];

        if (protocol === "http:") {
            setErrorReason(`http: is not supported. Please use https:`);
            setAnchorEl(event.currentTarget);
            setUrlErrorAnimation(true);

            return;
        }

        let urlPrefix = type === "raster" ? "https:" : "mapbox:";

        if (protocol !== urlPrefix) {
            setErrorReason(`Wrong format. expected ${urlPrefix}example.com/{z}/{x}/{y}`);
            setAnchorEl(event.currentTarget);
            setUrlErrorAnimation(true);

            return;
        }

        changeUrl(event, paste);
    }

    const onTitleChange = (e) => {
        const newTitle = e.target.value;

        const isTitleInUse = basemaps.some((x) => x.title.toLowerCase() === newTitle.toLowerCase());

        setErrors({ url: errors.url, title: isTitleInUse });

        if (isTitleInUse) {
            setErrorReason(`A basemap with this name already exists`);
            setAnchorEl(e.currentTarget);
        }

        setTitle(newTitle);
    };

    const onTypeChange = (e) => {
        const type = e.target.value;

        setUrl("");
        setType(type);
    };

    const onUrlChange = (e) => {
        const newUrl = e.target.value;

        changeUrl(e, newUrl);
    };

    const changeUrl = (e, url) => {
        const isUrlInUse = basemaps.some((x) => x.url.toLowerCase() === url.toLowerCase());

        setErrors({ url: isUrlInUse, title: errors.title });

        if (isUrlInUse) {
            setErrorReason(`A basemap with this url already exists`);
            setAnchorEl(e.currentTarget);
        }

        setUrl(url);
    };

    const resetState = () => {
        setTitle("");
        setType(DATA_TYPES.vector);
        setUrl("");
        setModalOpen(false);
    };

    const sortByName = (dataset1, dataset2) => {
        return dataset1.name.localeCompare(dataset2.name);
    };

    const sortByNameReverse = (dataset1, dataset2) => {
        return dataset2.name.localeCompare(dataset1.name);
    };

    const sortByModified = (dataset1, dataset2) => {
        return new Date(dataset2.modifiedUtc) - new Date(dataset1.modifiedUtc);
    };

    const sortByModifiedReverse = (dataset1, dataset2) => {
        return new Date(dataset1.modifiedUtc) - new Date(dataset2.modifiedUtc);
    };

    const generateItem = (item, index) => {
        return <RasterListItem raster={item} key={index} onClick={onSelectRaster} selected={url.includes(item.id)} />;
    };

    const filterItem = (item, query) => {
        return item.name.toLowerCase().includes(query.toLowerCase());
    };

    const onSelectRaster = (e, raster) => {
        setType(DATA_TYPES.raster);

        setTitle(raster.name);

        setErrors({ url: false, title: false });

        setUrl(`${config.apiUrl}api/raster/${raster.id}/{z}/{x}/{y}`);
    };

    const onCloseDialog = () => {
        setModalOpen(false);
    };

    const onAddBasemap = (e) => {
        if (title === "" || errors.title) {
            setErrors({ url: errors.url, title: true });
            setErrorReason(`Title is empty`);
            setAnchorEl(titleRef.current);
            return;
        }

        if (url === "" || errors.url) {
            setErrors({ url: true, title: errors.title });
            setErrorReason(url === "" ? `url is empty` : "url is already in use");
            setAnchorEl(urlRef.current);
            return;
        }

        const formData = {
            title: title,
            type: type,
            url: url
        };

        dispatch(addLibraryBasemap(formData))
            .then((res) => {
                dispatch(handleAddLibraryBasemap(formData));
                dispatch(fetchLibraryBasemaps());
            })
            .catch((err) => {
                handleError(err);
            });

        resetState();
    };

    const columns = [
        {
            type: "name",
            name: "Name",
            sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName),
            start: true
        },
        {
            type: "date",
            name: "Modified",
            sortingFunction: (sortingReverse) => (sortingReverse ? sortByModifiedReverse : sortByModified)
        }
    ];

    const popOverOpen = Boolean(anchorEl);

    return (
        <CustomModal handleClose={onCloseDialog} isOpen={open} onConfirm={onAddBasemap} dialogTitle={"Add Basemap"} dialogType={"add"}>
            <Popover
                open={popOverOpen}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    className: classes.errorPopover
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Typography sx={{ p: 2 }}>{errorReason}</Typography>
            </Popover>
            <div className={classes.modalContent}>
                <div className={classes.form}>
                    <div className="title">
                        <TextField
                            label="Title of basemap"
                            ref={titleRef}
                            error={errors.title}
                            value={title}
                            onChange={onTitleChange}
                            className="url"
                            variant="filled"
                            fullWidth
                            inputProps={{ "data-testid": titleTestId }}
                        ></TextField>
                    </div>
                    <div className={`flex ${classes.fieldsContainer}`}>
                        <div className={classes.selectField}>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel>Data Type</InputLabel>
                                <Select value={type} onChange={onTypeChange} className="type" data-testid={typeSelectTestId}>
                                    <MenuItem value="vector" data-testid={vectorTestId}>
                                        Vector (mapbox)
                                    </MenuItem>
                                    <MenuItem value="raster" data-testid={rasterTestId}>
                                        Raster
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex-grow">
                            <div></div>
                            <TextField
                                label="URL"
                                value={url}
                                ref={urlRef}
                                error={errors.url}
                                onChange={onUrlChange}
                                className={urlErrorAnimation ? classes.pulse : ""}
                                onAnimationEnd={() => setUrlErrorAnimation(false)}
                                variant="filled"
                                fullWidth
                                onPasteCapture={onPasteUrl}
                                inputProps={{ "data-testid": urlTestId }}
                            ></TextField>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className={classes.rasters}>
                    <ListView
                        searchPlaceholder={"Search rasters"}
                        searchWidth="12"
                        data={rastersNotInBasemaps}
                        columns={columns}
                        generateItem={generateItem}
                        filterFunction={filterItem}
                        hideBottomBar
                    ></ListView>
                </div>
            </div>
        </CustomModal>
    );
};
export default UploadBasemapModal;

const titleTestId = "qa-basemap-title-input";
const typeSelectTestId = "qa-basemap-type-select";
const rasterTestId = "qa-basemap-raster-type";
const vectorTestId = "qa-basemap-vector-type";
const urlTestId = "qa-basemap-url-input";
