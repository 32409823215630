import AppIcon from "@mui/icons-material/DesktopWindows";
import GroupIcon from "@mui/icons-material/Group";
import DatasetIcon from "@mui/icons-material/Layers";
import UserIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import RasterIcon from "@mui/icons-material/Wallpaper";
import { UserSession } from "features/auth/model/UserSession";
import { FeatureFlagName, FeatureFlags } from "features/featureFlags/slice";
import { GisRole } from "features/users/model/GisRole";
import ApplicationView from "../views/apps/AppsRootView/AppsRootView";
import DatasetsView from "../views/datasets/datasetsRootView/datasetsRootView";
import GroupsRootView from "../features/groups/components/GroupsRootView/GroupsRootView";
import RasterView from "../features/rasters/components/RastersRootView/rastersRootView";
import SettingsView from "../views/settings/settingsRootView/settingsRootview";
import UsersRootView from "../features/users/components/UsersRootView/UsersRootView";

export type GISLocation = {
    name: string;
    route: string;
    exact: boolean;
    component: any;
    allowedRoles: GisRole[];
    icon: JSX.Element;
    featureFlagName?: FeatureFlagName;
};

export const LOCATIONS: GISLocation[] = [
    {
        name: "Vector Data",
        route: "/datasets",
        exact: false,
        component: DatasetsView,
        allowedRoles: [GisRole.Admin, GisRole.User, GisRole.Uploader],
        icon: <DatasetIcon className="icon" />
    },
    {
        name: "Rasters",
        route: "/rasters",
        exact: false,
        component: RasterView,
        allowedRoles: [GisRole.Admin, GisRole.User],
        icon: <RasterIcon className="icon" />
    },
    {
        name: "Applications",
        route: "/applications",
        exact: false,
        component: ApplicationView,
        allowedRoles: [GisRole.Admin, GisRole.User],
        icon: <AppIcon className="icon" />
    },
    {
        name: "Groups",
        route: "/groups",
        exact: false,
        component: GroupsRootView,
        allowedRoles: [GisRole.Admin],
        icon: <GroupIcon className="icon" />
    },
    {
        name: "Users",
        route: "/users",
        exact: false,
        component: UsersRootView,
        allowedRoles: [GisRole.Admin],
        icon: <UserIcon className="icon" />
    },
    {
        name: "Settings",
        route: "/settings",
        exact: false,
        component: SettingsView,
        allowedRoles: [GisRole.Admin],
        icon: <SettingsIcon className="icon" />
    }
];

export const firstAllowedRoute = (role: GisRole | null) => {
    if (role) {
        return LOCATIONS.find((location) => location.allowedRoles.includes(role))?.route ?? "/datasets";
    } else {
        return "";
    }
};

export const checkCurrentUserRoles = (location: GISLocation, currentUser: UserSession | undefined) => {
    return !!currentUser && location.allowedRoles.includes(currentUser.role);
};

export const checkFeatureFlag = (location: GISLocation, featureFlags: FeatureFlags) => {
    if (!location.featureFlagName) return true;
    return !!featureFlags[location.featureFlagName];
};
