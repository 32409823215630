import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    typeChip: {
        lineHeight: "18px",
        fontWeight: 600
    },
    variantSmall: {
        fontSize: 12,
        height: 22
    },

    variantLarge: {
        fontSize: 13,
        height: 26
    },

    vector: {
        background: "#e4f5f2",
        color: "#3d7f74"
    },

    raster: {
        background: "#eaeaff",
        color: "#504ce1"
    }
}));
