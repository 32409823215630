import { useAddResourcePermissionMutation, useRemoveResourcePermissionMutation, useUpdateResourcePermissionMutation } from "features/permissions/permissionsApi";
import toastr from "components/CustomToastr/CustomToastr";
import { handleError } from 'utils/networkErrorUtils';
import { permissions } from "utils/constants/permissionTypes";
import { PermissionType } from "features/groups/model/PermissionType";
import { ResourceType } from "features/groups/model/ResourceType";

const usePermissionsManagement = () => {
    const [addResourcePermissionMutation] = useAddResourcePermissionMutation();
    const [updateResourcePermissionMutation] = useUpdateResourcePermissionMutation();
    const [removeResourcePermissionMutation] = useRemoveResourcePermissionMutation();

    const handlePermissionChange = async (groupId: string, resourceId: string, currentPermission: PermissionType, newPermission: PermissionType, resourceType: ResourceType, onChangesMade: () => void) => {

        if (currentPermission !== newPermission) {

            let promise;

            if (currentPermission === permissions.NONE.value) {
                promise = addResourcePermissionMutation({ groupId, resourceId, permissionType: newPermission, resourceType });
            } else if (newPermission === permissions.NONE.value) {
                promise = removeResourcePermissionMutation({ groupId, resourceId, resourceType });
            } else {
                promise = updateResourcePermissionMutation({ groupId, resourceId, permissionType: newPermission, resourceType });
            }

            try {
                await promise;
                toastr.success("Permission updated");
                onChangesMade();
            } catch (err) {
                handleError(err);
            }
        }
    };

    return {
        handlePermissionChange
    };
};

export default usePermissionsManagement;
