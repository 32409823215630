import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useStyles } from "../styles";
import InfoIcon from "@mui/icons-material/Info";
import { MATCH_ALL_MILESTONES_INFO_TEXT as MATCH_ALL_MILESTONES_INFO_TEXT } from "../../utils";
import { FC, useMemo } from "react";
import { useFetchSubprojectsQuery } from "features/dprIntegration/dprApi";
import { DprSubproject } from "features/dprIntegration/models/DprSubproject";

type Props = {
    selectAllSubprojects: boolean;
    setSelectAllSubprojects: (selectAllSubprojects: boolean) => void;
    subprojects: DprSubproject[];
    setSubprojects: (subprojects: DprSubproject[]) => void;
};

const SubprojectsStep: FC<Props> = ({ selectAllSubprojects, setSelectAllSubprojects, subprojects, setSubprojects }) => {
    const classes = useStyles();

    const { data: subprojectOptions = [], isLoading: subprojectOptionsLoading, isError: subprojectOptionsError } = useFetchSubprojectsQuery();

    const subprojectOptionsLookup = useMemo(
        () =>
            subprojectOptions.reduce((acc, val) => {
                acc[val.id] = val;
                return acc;
            }, {}),
        [subprojectOptions]
    );

    const onSubprojectChange = (event: SelectChangeEvent<number[]>) => {
        const {
            target: { value }
        } = event;

        // On autofill we get a stringified value.
        const selectedSubprojects = typeof value === "string" ? value.split(",").map((x) => Number.parseInt(x)) : value;

        setSubprojects(selectedSubprojects.map((x) => subprojectOptionsLookup[x]));
    };

    return (
        <>
            <CustomTypography variant="subtitle2" textWeight="bold" className={classes.description} fontSize={12}>
                2: Identify the subprojects:
            </CustomTypography>
            <FormControlLabel
                control={
                    <Checkbox
                        sx={{ ml: "11px", mr: 1 }}
                        size="small"
                        checked={selectAllSubprojects}
                        onChange={(e) => setSelectAllSubprojects(e.target.checked)}
                        data-testid={selectAllSubprojectsCheckboxTestId}
                    />
                }
                label={
                    <CustomTypography className={classes.selectAllSubprojects}>
                        Select all subprojects
                        <Tooltip title={MATCH_ALL_MILESTONES_INFO_TEXT}>
                            <InfoIcon className={classes.selectAllSubprojectsInfoIcon} />
                        </Tooltip>
                    </CustomTypography>
                }
            />

            <FormControl variant="filled">
                <InputLabel id="subproject-select-label-id" className={classes.inputLabel}>
                    Subproject(s)
                </InputLabel>
                <Select
                    disabled={selectAllSubprojects}
                    labelId="subproject-select-label-id"
                    multiple
                    value={subprojects.map((x) => x.id)}
                    onChange={onSubprojectChange}
                    renderValue={(selected) => selected.map((x) => subprojectOptionsLookup[x].name).join(", ")}
                    inputProps={{ "data-testid": selectSubprojectsTestId }}
                    fullWidth
                >
                    {subprojectOptions.map((subproject) => (
                        <MenuItem key={subproject.id} value={subproject.id} data-testid={selectSubprojectsOptionTestId + subproject.id}>
                            <Checkbox checked={subprojects.findIndex((x) => x.id === subproject.id) > -1} sx={{ mr: 1 }} />
                            <ListItemText primary={subproject.name} />
                        </MenuItem>
                    ))}
                    {(!subprojectOptions.length || subprojectOptionsLoading || subprojectOptionsError) && (
                        <MenuItem key="info-opt" value="info" disabled>
                            <ListItemText
                                primary={
                                    subprojectOptionsLoading ? (
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <CircularProgress size="16px" sx={{ mr: 1 }} /> Loading
                                        </Box>
                                    ) : (
                                        "No options available"
                                    )
                                }
                            />
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </>
    );
};

export default SubprojectsStep;

const selectAllSubprojectsCheckboxTestId = "qa-dpr-integration-add-milestone-modal-select-all-subprojects-checkbox";
const selectSubprojectsTestId = "qa-dpr-integration-add-milestone-modal-select-subprojects";
const selectSubprojectsOptionTestId = "qa-dpr-integration-add-milestone-modal-select-subprojects-option-";
