import { Route } from "react-router-dom";

const GuardedRoute = ({ component: Component, condition, ...rest }) => {
    if (condition === false) {
        return null;
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default GuardedRoute;
