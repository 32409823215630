import { createSlice } from "@reduxjs/toolkit";
import * as types from "../actions/actionTypes";
import { resetProjectData, toggleGroupLayers } from "../actions/globalActions";

const initialState = {
    jumpLocation: { center: [-122.420679, 37.772537], zoom: 10, bearing: 90, pitch: 0 },
    fitBounds: null,
    mapPosition: {
        zoom: 0,
        bounds: [
            [0, 0],
            [0, 0]
        ]
    },
    sources: [],
    layers: [],
    paints: {},
    layouts: {},
    zoomRanges: {},
    addedInitialPaints: false,
    addedInitialLayouts: false
};

const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        addMapSource: ({ sources }, { payload: newSource }) => {
            const matchingMapWithTheSameStatus = sources.some((s) => s.id === newSource.id && s.cacheStatus === newSource.cacheStatus);

            if (matchingMapWithTheSameStatus) return;

            const matchingSourceWithDifferentStatus = sources.find((s) => s.id === newSource.id && s.cacheStatus !== newSource.cacheStatus);
            if (matchingSourceWithDifferentStatus) {
                matchingSourceWithDifferentStatus.cacheStatus = newSource.cacheStatus;
                return;
            }
            sources.push(newSource);
        },
        addMapSources: ({ sources }, { payload: newSources }) => {
            sources.push(...newSources);
        },
        removeMapSource: ({ sources }, { payload: sourceId }) => {
            const sourceIndex = sources.findIndex((s) => s.id === sourceId);
            if (sourceIndex === -1) return;
            sources.splice(sourceIndex, 1);
        },
        setMapSourceUncached: ({ sources }, { payload: sourceId }) => {
            const source = sources.find((s) => s.id === sourceId);
            source.cacheStatus = 0;
        },
        removeAllSources: (state) => {
            state.sources = [];
        },
        initMapResources: (state, { payload: { layers, paints, layouts, zoomRanges } }) => {
            state.layers = layers;
            state.paints = paints;
            state.layouts = layouts;
            state.zoomRanges = zoomRanges;
        },
        addMapLayer: ({ layers }, { payload: newLayer }) => {
            layers.push(newLayer);
        },
        addMapLayers: ({ layers }, { payload: newLayers }) => {
            layers.push(...newLayers);
        },
        replaceMapLayers: (state, { payload: newLayers }) => {
            state.layers = newLayers;
        },
        updateMapLayer: ({ layers, paints, layouts }, { payload: updatedLayer }) => {
            updateArraySetChanged(layers, updatedLayer);
            delete paints[updatedLayer.layerId];
            delete layouts[updatedLayer.layerId];
        },
        moveLayer: (state, { payload: { layerId, beforeLayerId } }) => {
            const layers = state.layers;

            const movedLayerIndex = layers.findIndex((l) => l.layerId === layerId);
            const destinationIndex = layers.findIndex((l) => l.layerId === beforeLayerId);

            const layer = layers.splice(movedLayerIndex, 1)[0];

            layers.splice(destinationIndex, 0, layer);

            layers[destinationIndex].drawBefore = layers[destinationIndex - 1]?.layerId;
            if (layers[destinationIndex + 1]) {
                layers[destinationIndex + 1].drawBefore = layers[destinationIndex].layerId;
            }
        },
        removeMapLayer: ({ layers, paints, layouts, zoomRanges }, { payload: layerId }) => {
            const layerIndex = layers.findIndex((l) => l.layerId === layerId);
            if (layerIndex === -1) return;

            layers.splice(layerIndex, 1);
            delete paints[layerId];
            delete layouts[layerId];
            delete zoomRanges[layerId];
        },
        removeResourceMapLayers: (state, { payload: resourceId }) => {
            state.layers = state.layers.filter((l) => {
                if (l.resourceId === resourceId) {
                    delete state.paints[l.layerId];
                    delete state.layouts[l.layerId];
                    delete state.zoomRanges[l.layerId];
                    return false;
                }
                return true;
            });
        },
        removeAllMapLayers: (state) => {
            state.layers = [];
            state.paints = {};
            state.layouts = {};
            state.zoomRanges = {};
        },
        resetMapFlags: (state) => {
            state.addedInitialLayouts = false;
            state.addedInitialPaints = false;
        },
        addedInitialLayouts: (state) => {
            state.addedInitialLayouts = true;
        },
        addedInitialPaints: (state) => {
            state.addedInitialPaints = true;
        },
        addMapPaint: ({ paints }, { payload: { layerId, properties } }) => {
            paints[layerId] = { layerId, properties };
        },
        updateMapPaint: ({ paints }, { payload: { layerId, properties } }) => {
            paints[layerId] = { layerId, properties };
        },
        addMapLayout: ({ layouts }, { payload: { layerId, properties } }) => {
            layouts[layerId] = { layerId, properties };
        },
        updateMapLayout: ({ layouts }, { payload: { layerId, properties } }) => {
            layouts[layerId] = { layerId, properties };
        },
        updateMapProperty: ({ paints, layouts }, { payload: { layerId, property } }) => {
            const propertyDict = property.type === "layout" ? layouts : paints;
            const propIndex = propertyDict[layerId].properties.findIndex((p) => p.name === property.name);
            if (propIndex !== -1) {
                propertyDict[layerId].properties[propIndex] = property;
            } else {
                console.error("Property index could not be found");
            }
        },
        addMapZoomRange: ({ zoomRanges }, { payload: { layerId, minZoom, maxZoom } }) => {
            zoomRanges[layerId] = { layerId, minZoom, maxZoom };
        },
        updateMapZoomRange: ({ zoomRanges }, { payload: { layerId, minZoom, maxZoom } }) => {
            zoomRanges[layerId] = { layerId, minZoom, maxZoom };
        },
        jumpToMapLocation: (state, { payload: newJumpLocation }) => {
            state.jumpLocation = newJumpLocation;
        },
        fitMapBounds: (state, { payload: { bounds, options } }) => {
            state.fitBounds = { bbox: bounds, options };
        },
        setMapPosition: (state, { payload: newPosition }) => {
            state.mapPosition = newPosition;
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(types.GENERATE_APP_CACHE, ({ sources }) => {
                setUncachedMapsCacheStatusToCaching(sources);
            })
            .addCase(types.GENERATE_APP_CACHE_FAILED, ({ sources }) => {
                setCachingMapsCacheStatusToUncached(sources);
            })
            .addCase(types.SIGNALR_PROGRESS_UPDATE, ({ sources }, { payload: notification }) => {
                updateMapCacheStatuses(sources, notification);
            })
            .addCase(types.SIGNALR_ERROR_UPDATE, ({ sources }) => {
                setCachingMapsCacheStatusToUncached(sources);
            })
            .addCase(toggleGroupLayers, ({ layers, layouts }, { payload: { appLayersMap, newVisibility } }) => {
                const affectedLayersArray = layers.filter((lay) => appLayersMap[lay.resourceId]);
                affectedLayersArray.forEach((layer) => {
                    const visibilityProperty = layouts[layer.layerId]?.properties.find((prop) => prop.name === "visibility");
                    if (visibilityProperty) {
                        visibilityProperty.value = newVisibility ? "visible" : "none";
                    }
                });
            })
            .addCase(resetProjectData, () => initialState)
});

const setUncachedMapsCacheStatusToCaching = (sources) => changeMapsCacheStatus(sources, 0, 1);
const setCachingMapsCacheStatusToUncached = (sources) => changeMapsCacheStatus(sources, 1, 0);

const changeMapsCacheStatus = (sources, currentCacheStatus, desiredCacheStatus) => {
    sources.forEach((source) => {
        if (source.isMap && source.cacheStatus === currentCacheStatus) {
            source.cacheStatus = desiredCacheStatus;
        }
    });
};

const updateMapCacheStatuses = (sources, notification) => {
    sources.forEach((source) => {
        if (source.id === notification.resourceId) {
            source.cacheStatus = notification.cacheStatus;
        }
    });
};

const updateArraySetChanged = (array, item) => {
    array.forEach((x) => {
        if (item.layerId === x.layerId) {
            Object.assign(x, item);
            x.changed = true;
            return;
        }
        x.changed = false;
    });
};

export const {
    addMapSource,
    addMapSources,
    removeMapSource,
    setMapSourceUncached,
    removeAllSources,
    initMapResources,
    addMapLayer,
    addMapLayers,
    replaceMapLayers,
    updateMapLayer,
    moveLayer,
    removeMapLayer,
    removeResourceMapLayers,
    removeAllMapLayers,
    resetMapFlags,
    addedInitialLayouts,
    addedInitialPaints,
    addMapPaint,
    updateMapPaint,
    addMapLayout,
    updateMapLayout,
    updateMapProperty,
    addMapZoomRange,
    updateMapZoomRange,
    jumpToMapLocation,
    fitMapBounds,
    setMapPosition
} = mapSlice.actions;

export default mapSlice.reducer;
