import { Button, Divider } from "@mui/material";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import { DatePickerView } from "components/CustomDatePicker/types/DatePickerView";
import dayjs, { Dayjs } from "dayjs";
import { FC } from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import clsx from "clsx";
import { useStyles } from "./styles";

type Props = {
    values: [Dayjs, Dayjs];
    onChange: (values: [Dayjs, Dayjs]) => void;
    onClear: () => void;
    views?: DatePickerView[];
};

const CustomDateRangePicker: FC<Props> = ({ values, onChange, onClear, views = ["year", "month", "day"] }) => {
    const classes = useStyles();

    const onStartDateChange = (date: Dayjs) => onChange([date, values[1]]);
    const onEndDateChange = (date: Dayjs) => onChange([values[0], date]);

    // This Week

    const firstDayOfTheWeek = dayjs().day(0);
    const lastDayOfTheWeek = dayjs().day(6);

    const onThisWeekClick = () => onChange([firstDayOfTheWeek, lastDayOfTheWeek]);
    const isThisWeekSelected = values[0].isSame(firstDayOfTheWeek, "day") && values[1].isSame(lastDayOfTheWeek, "day");

    // This Month

    const firstDayOfThisMonth = dayjs().date(1);
    const lastDayOfThisMonth = dayjs().date(dayjs().daysInMonth());

    const onThisMonthClick = () => onChange([firstDayOfThisMonth, lastDayOfThisMonth]);
    const isThisMonthSelected = values[0].isSame(firstDayOfThisMonth, "day") && values[1].isSame(lastDayOfThisMonth, "day");

    // Last Month

    const lastMonth = dayjs().subtract(1, "months");
    const firstDayOfLastMonth = lastMonth.date(1);
    const lastDayOfLastMonth = lastMonth.date(lastMonth.daysInMonth());

    const onLastMonthClick = () => onChange([firstDayOfLastMonth, lastDayOfLastMonth]);
    const isLastMonthSelected = values[0].isSame(firstDayOfLastMonth, "day") && values[1].isSame(lastDayOfLastMonth, "day");

    // Last Year

    const lastYear = dayjs().subtract(1, "years");
    const firstDayOfLastYear = lastYear.month(0).date(1);
    const finalMonth = lastYear.month(11);
    const lastDayOfLastYear = finalMonth.date(finalMonth.daysInMonth());

    const onLastYearClick = () => onChange([firstDayOfLastYear, lastDayOfLastYear]);
    const isLastYearSelected = values[0].isSame(firstDayOfLastYear, "day") && values[1].isSame(lastDayOfLastYear, "day");

    return (
        <div className={classes.container}>
            <CustomDatePicker value={values[0]} onChange={onStartDateChange} views={views} name="start" maxDate={values[1]} />
            <Divider flexItem orientation="vertical" />
            <CustomDatePicker value={values[1]} onChange={onEndDateChange} views={views} name="end" minDate={values[0]} />
            <Divider flexItem orientation="vertical" />
            <div className={classes.buttonsContainer}>
                <Button className={classes.button} onClick={onClear} data-testid={clearTestId}>
                    <ClearAllIcon />
                    Clear
                </Button>
                <Button className={clsx(classes.button, isThisWeekSelected && classes.isSelected)} onClick={onThisWeekClick} data-testid={thisWeekTestId}>
                    <DateRangeIcon />
                    This week
                </Button>
                <Button className={clsx(classes.button, isThisMonthSelected && classes.isSelected)} onClick={onThisMonthClick} data-testid={thisMonthTestId}>
                    <CalendarMonthIcon />
                    This month
                </Button>
                <Button className={clsx(classes.button, isLastMonthSelected && classes.isSelected)} onClick={onLastMonthClick} data-testid={lastMonthTestId}>
                    <CalendarMonthIcon />
                    Last month
                </Button>
                <Button className={clsx(classes.button, isLastYearSelected && classes.isSelected)} onClick={onLastYearClick} data-testid={lastYearTestId}>
                    <CalendarViewMonthIcon />
                    Last year
                </Button>
            </div>
        </div>
    );
};

export default CustomDateRangePicker;

const clearTestId = "qa-date-range-clear-button";
const thisWeekTestId = "qa-date-range-week-button";
const thisMonthTestId = "qa-date-range-this-month-button";
const lastMonthTestId = "qa-date-range-last-month-button";
const lastYearTestId = "qa-date-range-last-year-button";
