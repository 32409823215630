import baseAtlasApi from "../../store/baseAtlasApi";

export const infoboxApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        createOrReplaceInfobox: build.mutation<void, { datasetId: string; applicationId: string | null; name: string }>({
            query: (request) => ({
                url: `infobox`,
                method: "PUT",
                data: request
            })
        }),
        deleteInfobox: build.mutation<void, { datasetId: string; applicationId: string | null }>({
            query: (request) => ({
                url: `infobox`,
                method: "DELETE",
                params: request
            })
        })
    })
});

export const { useCreateOrReplaceInfoboxMutation, useDeleteInfoboxMutation, usePrefetch: useDatasetsPrefetch } = infoboxApi;
