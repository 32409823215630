import { useFetchMetadataSchemaQuery } from "features/metadata/api";
import React, { useMemo } from "react";
import { getMetadataPropertyDefaultValue, metadataFieldsToPropertyValues } from "features/metadata/utils";
import { TagValue } from "features/metadata/models/TagValue";
import { useFormik } from "formik";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import MetadataValueEditor from "features/metadata/components/MetadataValueEditor/MetadataValueEditor";
import { DialogActions, DialogContent, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { MetadataFields } from "features/metadata/models/MetadataFields";
import { buildPropertyValuesSchema } from "features/metadata/validators/metadataValuesSchema";
import toastr from "components/CustomToastr/CustomToastr";
import * as datasetsActions from "../../../../actions/datasets";
import { GisRole } from "features/users/model/GisRole";
import { useBulkUpdateMetadataMutation } from "features/datasets/api";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getUploadedDatasetIds } from "../../selectors";
import { getCurrentUserRole } from "features/auth/selectors";

type Props = {
    onClose: () => void;
};

const MetadataView = ({ onClose }: Props) => {
    const uploadedDatasetIds = useAppSelector(getUploadedDatasetIds);
    const userRole = useAppSelector(getCurrentUserRole);
    const { data: schema = [], isLoading } = useFetchMetadataSchemaQuery();
    const [bulkUpdateMetadata] = useBulkUpdateMetadataMutation();
    const dispatch = useAppDispatch();

    const initialValues = useMemo(
        () =>
            schema.reduce<MetadataFields>((acc, metadataProperty) => {
                acc[metadataProperty.name] = getMetadataPropertyDefaultValue(metadataProperty);

                return acc;
            }, {}),
        [schema]
    );

    const validationSchema = useMemo(() => buildPropertyValuesSchema(schema), [schema]);

    const sortedSchema = useMemo(() => [...schema].sort((prop1, prop2) => Number(prop2.mandatory) - Number(prop1.mandatory)), [schema]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: (values) => {
            bulkUpdateMetadata({
                datasetIds: uploadedDatasetIds,
                metadata: metadataFieldsToPropertyValues(values, schema)
            })
                .unwrap()
                .then(() => {
                    toastr.success("Metadata updated successfully");
                    //TODO: After having the datasets feature to use
                    // rtk query, invalidate the tags there, or update
                    // the metadata status
                    dispatch(datasetsActions.getDatasets(userRole === GisRole.Uploader ? 3 : 1));
                })
                .catch(() => toastr.error("An error occurred while updating the metadata"))
                .finally(() => onClose());
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnMount: true
    });

    return (
        <>
            <DialogContent dividers>
                <Stack spacing={2} sx={{ maxWidth: 600, marginInline: "auto" }}>
                    <LoadingPlaceholder loading={isLoading} message="Loading schema">
                        {sortedSchema.map((property) => {
                            switch (property.type) {
                                case "text":
                                case "number":
                                    return (
                                        <MetadataValueEditor
                                            key={property.id}
                                            property={property}
                                            error={Boolean(formik.errors[property.name]) && Boolean(formik.touched[property.name])}
                                            type={property.type}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values[property.name] as string}
                                            helperText={formik.touched[property.name] ? formik.errors[property.name] : ""}
                                        />
                                    );
                                case "date":
                                    return (
                                        <MetadataValueEditor
                                            key={property.id}
                                            property={property}
                                            error={Boolean(formik.errors[property.name]) && Boolean(formik.touched[property.name])}
                                            type={property.type}
                                            onChange={(e) => formik.setFieldValue(property.name, String(e))}
                                            value={formik.values[property.name] as Date | null}
                                            helperText={formik.touched[property.name] ? formik.errors[property.name] : ""}
                                            onBlur={() => formik.setFieldTouched(property.name, true)}
                                        />
                                    );
                                case "tag_list":
                                    return (
                                        <MetadataValueEditor
                                            key={property.id}
                                            property={property}
                                            error={Boolean(formik.errors[property.name]) && Boolean(formik.touched[property.name])}
                                            type={property.type}
                                            onChange={(tags: TagValue[]) => formik.setFieldValue(property.name, tags)}
                                            value={(formik.values[property.name] as TagValue[]) ?? []}
                                            helperText={formik.touched[property.name] ? formik.errors[property.name] : ""}
                                            onBlur={() => formik.setFieldTouched(property.name, true)}
                                        />
                                    );
                            }
                        })}
                    </LoadingPlaceholder>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disabled={!formik.isValid} onClick={formik.submitForm}>
                    Set metadata
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </>
    );
};

export default MetadataView;
