import { Group } from "features/groups/model/Group";
import { sortByString, sortByStringReverse } from "utils/sortingFunctions";

const sortByName = (group1: Group, group2: Group) => sortByString(group1.name, group2.name);
const sortByNameReverse = (group1: Group, group2: Group) => sortByStringReverse(group1.name, group2.name);

const groupsSorting = [
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    }
];

export default groupsSorting;
