import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomTypography from "../../../../components/CustomTypography/CustomTypography";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { useTheme } from "@mui/styles";

export default function Schema({ schema, selected, onSelect, onDelete }) {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onDeleteClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();

        const toastrConfirmOptions = {
            onOk: () => onDelete(schema),
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to delete schema: ${schema}?`, toastrConfirmOptions);
    };

    return (
        <div className={selected ? "item selected" : "item"} onClick={() => onSelect(schema)} style={{ cursor: "default" }}>
            <div className="container">
                <div className="row-container">
                    <div className={"row-1"} data-testid="qa-schema-table-row">
                        <div className="name">
                            <CustomTypography variant="body2" textWeight="semibold" color={theme.customColors.primaryColor}>
                                {schema}
                            </CustomTypography>
                        </div>
                        <Tooltip id="tooltip-top" title="Options">
                            <div className="dataset-table-cell button" onClick={onMenuClick}>
                                <MenuIcon />
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                    <MenuItem onClick={onDeleteClick} className="menu-button" data-testid={deleteSchemaTestId}>
                        <DeleteIcon className="icon" />
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

const deleteSchemaTestId = "qa-delete-schema-menu-item";
