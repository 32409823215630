import { isGroup } from "@emblautec/rescursive-array-extensions";

const moveResourceActionFunction = (state, { payload: { resourceId, destinationId, moveAsChild, moveAbove } }) => {
    const resource = state.layerGroups.getRecursive(resourceId);

    //Substract from the counts of the old position parents
    if (isGroup(resource)) {
        state.layerGroups.forParentsRecursive(resourceId, (parent) => {
            parent.totalLayersCount -= resource.totalLayersCount;
            parent.visibleLayersCount -= resource.visibleLayersCount;
        });
    } else {
        state.layerGroups.forParentsRecursive(resourceId, (parent) => {
            parent.totalLayersCount -= 1;
            parent.visibleLayersCount -= state.layerVisibilityMap[resourceId] ? 1 : 0;
        });
    }

    if (!state.layerGroups.isChildOf(destinationId, resourceId)) {
        state.layerGroups.removeOneRecursive(resourceId);
        if (moveAsChild) {
            state.layerGroups.addChildRecursive(destinationId, resource);
        } else {
            state.layerGroups.addElementRecursive(destinationId, resource, moveAbove);
        }
    }

    //Add to the counts of the new position parents
    if (isGroup(resource)) {
        state.layerGroups.forParentsRecursive(resourceId, (parent) => {
            parent.totalLayersCount += resource.totalLayersCount;
            parent.visibleLayersCount += resource.visibleLayersCount;
        });
    } else {
        state.layerGroups.forParentsRecursive(resourceId, (parent) => {
            parent.totalLayersCount += 1;
            parent.visibleLayersCount += state.layerVisibilityMap[resourceId] ? 1 : 0;
        });
    }

    state.layerMovedFlag = !state.layerMovedFlag;
};

export default moveResourceActionFunction;
