import { Button } from "@mui/material";
import CustomTypography from "../CustomTypography/CustomTypography";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useStyles } from "./styles";

const ErrorPlaceholder = ({ children, error, message, textVariant, onTryAgain }) => {
    const { classes } = useStyles();
    return error ? (
        <div className={classes.container}>
            <CustomTypography className={classes.bottomMargin} variant={textVariant}>
                {message}
            </CustomTypography>
            {onTryAgain && (
                <Button onClick={onTryAgain} size="large" startIcon={<RefreshIcon />} color="primary" variant="contained">
                    Try Again
                </Button>
            )}
        </div>
    ) : (
        children
    );
};

ErrorPlaceholder.defaultProps = {
    message: "Encountered an error",
    textVariant: "body1"
};

export default ErrorPlaceholder;
