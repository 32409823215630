import { MetadataProperty } from "models/metadataSchema/MetdataProperty";
import { sortByBool, sortByBoolReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";

const sortByTypeReverse = (i1: MetadataProperty, i2: MetadataProperty) => sortByStringReverse(i1.type, i2.type);
const sortByType = (i1: MetadataProperty, i2: MetadataProperty) => sortByString(i1.type, i2.type);

const sortByNameReverse = (i1: MetadataProperty, i2: MetadataProperty) => sortByStringReverse(i1.name, i2.name);
const sortByName = (i1: MetadataProperty, i2: MetadataProperty) => sortByString(i1.name, i2.name);

const sortByMandatoryReverse = (i1: MetadataProperty, i2: MetadataProperty) => sortByBoolReverse(i1.mandatory, i2.mandatory);
const sortByMandatory = (i1: MetadataProperty, i2: MetadataProperty) => sortByBool(i1.mandatory, i2.mandatory);

const sortByHiddenReverse = (i1: MetadataProperty, i2: MetadataProperty) => sortByBoolReverse(i1.hidden, i2.hidden);
const sortByHidden = (i1: MetadataProperty, i2: MetadataProperty) => sortByBool(i1.hidden, i2.hidden);

export const metadataColumns = [
    {
        name: "Type",
        type: "text",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByTypeReverse : sortByType)
    },
    {
        name: "Name",
        type: "text",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    },
    {
        name: "Mandatory",
        type: "bool",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByMandatoryReverse : sortByMandatory)
    },
    {
        name: "Hidden",
        type: "bool",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByHiddenReverse : sortByHidden)
    }
];
