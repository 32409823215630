import { FC } from "react";
import { setAppConfig } from "reducers/appData/appData";
import { getApp } from "selectors/appData";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import ProjectionPicker from "../projectionPicker";
import { ComponentProps } from "../AppSettingsView";

const ProjectionsSection: FC<ComponentProps> = ({ disabled }) => {
    const app = useAppSelector(getApp);

    const dispatch = useAppDispatch();

    const onChangeProjections = (projections: any) => {
        let newConfigJson = { ...app.configJson };

        newConfigJson.projections = projections;
        dispatch(setAppConfig(newConfigJson));
    };

    return <ProjectionPicker projections={app.configJson.projections} onChange={onChangeProjections} disabled={disabled} />;
};

export default ProjectionsSection;
