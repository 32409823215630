import { createSelector } from "reselect";

//used to pass arguments to selectors
export const forwardArg = (_, arg) => arg;

//this returns a function that take a key as arg and return the corresponding value from the dict
export const dictGetterHOF = (dictSelector) => (key) => (state) => dictSelector(state)[key];

//this returns a function that takes the state and checks a condition, thus returning a boolean when used in useSelector
export const mappingHOF = (inputSelectors, mappingFunc) => (state) => {
    if (Array.isArray(inputSelectors)) {
        const parameters = inputSelectors.map((selector) => selector(state));
        return mappingFunc(...parameters);
    }
    return mappingFunc(inputSelectors(state));
};

export const createParameterizedSelector = (inputSelectors, mappingFunc) => {
    const selector = createSelector(inputSelectors, mappingFunc);
    return (param) => (state) => selector(state, param);
};
