import { Button, Tooltip } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/AddCircle";
import { getAppCacheStatusSelector } from "../../../../../selectors/map";
import SearchBar from "../../../../../components/SearchBar/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { addGroup, setSelectedTool } from "../../../../../reducers/appData/appData";
import { APP_EDIT_TOOLS } from "../../../../../utils/constants/appEditTools";
import { v4 as uuidv4 } from "uuid";
import { getApp, getAppPermissionType } from "selectors/appData";
import { PermissionType } from "features/groups/model/PermissionType";

const ActionsSection = ({ searchText, onSearchChange }) => {
    const cacheStatus = useSelector(getAppCacheStatusSelector);
    const permissionType = useSelector(getAppPermissionType);

    const dispatch = useDispatch();

    const addLayerGroup = () => {
        dispatch(
            addGroup({
                resourceId: uuidv4(),
                name: "New group",
                layers: [],
                options: {
                    collapsed: true
                },
                totalLayersCount: 0,
                visibleLayersCount: 0
            })
        );
    };

    const onOpenTool = (toolName) => {
        dispatch(setSelectedTool(toolName));
    };

    return (
        <>
            <div className="adding-buttons">
                <Button
                    onClick={addLayerGroup}
                    variant="text"
                    color="primary"
                    className="add-group-button"
                    data-testid={addGroupButtonTestId}
                    disabled={permissionType < PermissionType.WRITE_READ}
                >
                    <AddIcon className="left-icon" fontSize="small" />
                    <div>Add Group</div>
                </Button>
                <Tooltip title={cacheStatus === 1 ? "Wait for the app to finish the caching process" : ""}>
                    <div>
                        <Button
                            disabled={cacheStatus === 1 || permissionType < PermissionType.WRITE_READ}
                            color="primary"
                            variant="text"
                            onClick={() => onOpenTool(APP_EDIT_TOOLS.addData)}
                            data-testid={addDataButtonTestId}
                        >
                            <AddIcon className="left-icon" fontSize="small" />
                            Add Data
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <SearchBar searchPlaceholder="Search Layer" value={searchText} onChange={onSearchChange} noBg />
        </>
    );
};

export default ActionsSection;

const addGroupButtonTestId = "qa-app-edit-view-add-group-button-tab";
const addDataButtonTestId = "qa-app-edit-view-add-data-button-tab";
