import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import FeaturesTable from "../FeaturesTable/FeaturesTable";
import { useHistoryDetailsModalStyles } from "./styles";
import dayjs from "dayjs";
import { fetchDatasetHistoryEntryFeatures } from "features/fidu/actions";
import { FeatureProperties } from "features/fidu/models/Feature";
import { DatasetHistory } from "features/fidu/models/DatasetHistory";

type Props = {
    history: DatasetHistory;
    onClose: () => void;
};
const HistoryDetailsModal: FC<Props> = ({ history, onClose }) => {
    const classes = useHistoryDetailsModalStyles();

    const [featureProperties, setFeatureProperties] = useState<FeatureProperties[]>([]);

    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);

    useEffect(() => {
        setErr(null);
        setLoading(true);
        if (history)
            fetchDatasetHistoryEntryFeatures({ datasetId: history.datasetId, historyId: history.id })
                .then((res) => {
                    setFeatureProperties(res.properties);
                    setLoading(false);
                })
                .catch((err) => {
                    setErr(err);
                    setLoading(false);
                });
    }, [history.id]);

    const Loading = () => {
        return (
            <div className={classes.centerContent}>
                <CircularProgress />
            </div>
        );
    };

    const Error = () => {
        return (
            <div className={classes.centerContent}>
                <Typography>Encountered an error while retrieveing the features</Typography>
            </div>
        );
    };

    const TableOfFeatures = () => {
        return (
            <div className={classes.tableWrapper}>
                <FeaturesTable featureProperties={featureProperties} />
            </div>
        );
    };

    return (
        <Dialog open onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>{history.fileName}</DialogTitle>
            <DialogContent dividers className={classes.dialogHeight}>
                <Typography className={classes.sectionTitle}>Features uploaded on {dayjs(history.timeStamp).format("MMMM DD YYYY")}</Typography>
                {loading ? <Loading /> : err ? <Error /> : <TableOfFeatures />}
            </DialogContent>
        </Dialog>
    );
};

export default HistoryDetailsModal;
