import { geometryTypeToMapboxType } from "../GeometryUtils";
import { v4 as uuidv4 } from "uuid";
import { makeSourceFromDataset } from "../creators/SourceCreators";
import { makeMapLayerFromDataset } from "../creators/MapLayerCreators";
import { makeDefaultPaint } from "../creators/PaintCreators";
import { makeDefaultLayout } from "../creators/LayoutCreators";
import { makeZoomRange } from "../creators/ZoomRangeCreators";
import { makeDefaultStyle } from "../creators/StyleCreators";
import { makeAppLayerFromDataset } from "../creators/AppLayerCreators";
import { AbstractFactory } from "./AbstractAuxObjsFactory";

export class DatasetAuxObjectsFactory extends AbstractFactory {
    constructor(dataset) {
        super();
        this.resource = dataset;
        this.styleId = uuidv4();
    }

    makeStyle(styleConfig) {
        const layerType = geometryTypeToMapboxType(this.resource.geometryType);
        const minZoom = this.resource.minZoom;
        return makeDefaultStyle(this.styleId, minZoom, layerType, styleConfig);
    }

    makeSource() {
        return makeSourceFromDataset(this.resource);
    }

    makeMapLayer() {
        return makeMapLayerFromDataset(this.resource, this.styleId);
    }

    makePaint(styleConfig) {
        const layerType = geometryTypeToMapboxType(this.resource.geometryType);
        return makeDefaultPaint(this.styleId, layerType, styleConfig);
    }

    makeLayout(styleConfig) {
        const layerType = geometryTypeToMapboxType(this.resource.geometryType);
        return makeDefaultLayout(this.styleId, layerType, styleConfig);
    }

    makeZoomRange() {
        const MAP_MAX_ZOOM = 24;
        return makeZoomRange(this.styleId, this.resource.minZoom, MAP_MAX_ZOOM);
    }

    makeAppLayer(sourceId) {
        return makeAppLayerFromDataset(this.resource, sourceId);
    }
}
