import toastr from "../components/CustomToastr/CustomToastr";

export function handleError(err) {
    switch (err.response.status) {
        case 403:
            toastr.error("You do not have the required permission");
            break;
        case 400:
            const { error, errors } = err.response.data;
            if (error) {
                toastr.error(error);
            }
            if (errors) {
                //errors looks like this - errors: {ProperyNameWithErrors: ["Error1","Error2"]}
                const firstErrorMessage = errors[Object.keys(errors)[0]][0];
                toastr.error(firstErrorMessage);
            }
            break;
        case 405:
            toastr.error(err.response.data);
            break;
        case 409:
            toastr.error(err.response.data.error);
            break;
        case 500:
            toastr.error("Request failed");
            break;
        case 502:
            toastr.error("Service is down");
            break;
        default:
            toastr.error(err.message);
            break;
    }
}
