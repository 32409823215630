import { DatasetHistory } from "features/fidu/models/DatasetHistory";
import { Sorting } from "models/tableView/Sorting";
import { sortByDate, sortByDateReverse, sortByNumber, sortByNumberReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";

const sortByFileName = (h1: DatasetHistory, h2: DatasetHistory) => sortByString(h1.fileName, h2.fileName);
const sortByFileNameReverse = (h1: DatasetHistory, h2: DatasetHistory) => sortByStringReverse(h1.fileName, h2.fileName);

const sortByUploaded = (h1: DatasetHistory, h2: DatasetHistory) => sortByDate(new Date(h1.timeStamp), new Date(h2.timeStamp));
const sortByUploadedReverse = (h1: DatasetHistory, h2: DatasetHistory) => sortByDateReverse(new Date(h1.timeStamp), new Date(h2.timeStamp));

const sortByRowCount = (h1: DatasetHistory, h2: DatasetHistory) => sortByNumber(getHistoryRowCount(h1), getHistoryRowCount(h2));
const sortByRowCountReverse = (h1: DatasetHistory, h2: DatasetHistory) => sortByNumberReverse(getHistoryRowCount(h1), getHistoryRowCount(h2));

export const getHistoryRowCount = (history: DatasetHistory) => history.featuresEndId - history.featuresStartId + 1;

const historySortings: Sorting[] = [
    {
        type: "name",
        name: "File name",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByFileNameReverse : sortByFileName)
    },
    {
        type: "date",
        name: "Uploaded",
        start: true,
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByUploadedReverse : sortByUploaded)
    },
    {
        type: "text",
        name: "Row count",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByRowCountReverse : sortByRowCount)
    }
];

export default historySortings;
