import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { TwitterPicker } from "react-color";

const ColorPopover = ({ color, onColorChanged }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : null;

    return (
        <div>
            <div className="color-container" style={{ backgroundColor: color }} onClick={handleClick}></div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    style: { marginLeft: 8, overflowY: "hidden", userSelect: "none" }
                }}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
            >
                <TwitterPicker color={color} onChangeComplete={onColorChanged} />
            </Popover>
        </div>
    );
};

export default ColorPopover;
