import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    blueChip: {
        color: theme.customColors.primaryColor,
        fill: theme.customColors.primaryColor,
        backgroundColor: "inherit",
        padding: 0
    }
}));
