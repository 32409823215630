import { useState } from "react";
import { addManyUploads, removeAllUploads } from "../../slice";
import { Upload, UploadStatus } from "../../models/Upload";
import { Backdrop, Dialog, DialogTitle, IconButton, Step, StepLabel, Stepper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SwipeableViews from "react-swipeable-views";
import UploadInfoView from "../UploadInfoView/UploadInfoView";
import { useAppDispatch } from "../../../../store/hooks/useAppDispatch";
import { FiduUploadView } from "../FiduUploadView/FiduUploadView";

type Props = {
    open: boolean;
    onClose: () => void;
    datasetId: string;
};

enum StepEnum {
    INFO_UPLOAD,
    UPLOAD_DATA
}

const stepTitles = {
    [StepEnum.INFO_UPLOAD]: "Info",
    [StepEnum.UPLOAD_DATA]: "Upload data"
};

export const FiduUploaderModal = ({ open, onClose, datasetId }: Props) => {
    const [currentStep, setCurrentStep] = useState(StepEnum.INFO_UPLOAD);
    const dispatch = useAppDispatch();
    const onDialogClose = () => {
        onClose();
        dispatch(removeAllUploads());
        setCurrentStep(StepEnum.INFO_UPLOAD);
    };

    const fileHandler = (files: FileList) => {
        let addedUploads: Upload[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const fileName = file.name.split(".")[0];
            const extension = `.${file.name.split(".").pop()?.toLowerCase() ?? ""}`;

            switch (extension) {
                case ".geojson":
                case ".zip":
                case ".csv":
                    addedUploads = [
                        ...addedUploads,
                        {
                            id: "",
                            name: fileName,
                            file,
                            extension,
                            type: "append",
                            status: UploadStatus.Pending
                        }
                    ];
                    break;
                default:
                    break;
            }
        }

        dispatch(addManyUploads(addedUploads));

        if (currentStep === StepEnum.INFO_UPLOAD && addedUploads.length > 0) {
            setCurrentStep(StepEnum.UPLOAD_DATA);
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} onClose={onDialogClose}>
            <DialogTitle>Append data</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onDialogClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8
                }}
            >
                <CloseIcon />
            </IconButton>

            <Stepper
                activeStep={currentStep}
                sx={{
                    paddingBlock: 2,
                    paddingInline: 1.5,
                    borderTop: `1px solid`,
                    // TODO: change to customColors.borderColor:
                    borderTopColor: (theme) => theme.palette.grey[300]
                }}
            >
                {Object.values(StepEnum)
                    .filter((x) => !isNaN(Number(x)))
                    .map((step) => (
                        <Step key={stepTitles[step]}>
                            <StepLabel>{stepTitles[step]}</StepLabel>
                        </Step>
                    ))}
            </Stepper>

            <SwipeableViews index={currentStep}>
                <div hidden={currentStep !== StepEnum.INFO_UPLOAD}>
                    {currentStep == StepEnum.INFO_UPLOAD && <UploadInfoView fileHandler={fileHandler} acceptedFormats={[".zip", ".geojson", ".csv"]} />}
                </div>

                <div hidden={currentStep !== StepEnum.UPLOAD_DATA}>
                    {currentStep == StepEnum.UPLOAD_DATA && (
                        <FiduUploadView fileHandler={fileHandler} onClose={onDialogClose} datasetId={datasetId} acceptedFormats={[".zip", ".geojson", ".csv"]} />
                    )}
                </div>
            </SwipeableViews>
        </Dialog>
    );
};
