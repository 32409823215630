import { useAppSelector } from "../../../../store/hooks/useAppSelector";
import { getCsvUploads, getPendingUploads, getUploadingUploads, getUploads } from "../../selectors";
import { ChangeEvent, createRef, useRef } from "react";
import { UploadHandle } from "../../models/UploadHandle";
import { Box, Button, DialogActions, DialogContent, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AppendUpload } from "./AppendUpload/AppendUpload";
import CsvModal from "../UploadView/CsvModal/CsvModal";
import { acceptedRasterFormats, acceptedVectorFormats } from "../../utils";

type Props = {
    fileHandler: (files: FileList) => void;
    onClose: () => void;
    datasetId: string;
    acceptedFormats: [...typeof acceptedVectorFormats, ...typeof acceptedRasterFormats];
};

export const FiduUploadView = ({ fileHandler, onClose, datasetId, acceptedFormats }: Props) => {
    const uploads = useAppSelector(getUploads);
    const pendingUploads = useAppSelector(getPendingUploads);
    const uploadingUploads = useAppSelector(getUploadingUploads);
    const rowsRef = useRef<React.RefObject<UploadHandle>[]>([]);
    const isUploadAllEnabled = pendingUploads.length > 0;
    const isDoneEnabled = uploadingUploads.length === 0;
    const csvUploads = useAppSelector(getCsvUploads);

    rowsRef.current = uploads.map((_, i) => rowsRef.current[i] ?? createRef<UploadHandle>());

    const onUploadAll = () => rowsRef.current.forEach((row) => row.current?.startUpload());

    const onAddFiles = (e: ChangeEvent<HTMLInputElement>) => e.target.files && fileHandler(e.target.files);

    const onCancel = () => {
        rowsRef.current.forEach((row) => row.current?.cancelUpload());
        onClose();
    };

    return (
        <>
            <DialogContent dividers sx={{ minHeight: 300, maxHeight: `calc(100vh - 400px)` }}>
                <input id="uploadButton" type="file" accept={acceptedFormats.reduce((acc, current) => `${acc},${current}`, "")} multiple onChange={onAddFiles} hidden />
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography>{uploads.length} file(s)</Typography>
                    <Button color="secondary" variant="contained" size="small" startIcon={<AddIcon />} onClick={() => document?.getElementById("uploadButton")?.click()}>
                        Add files
                    </Button>
                </Box>
                {uploads.map((upload, i) => (
                    <div key={upload.name}>
                        {upload.type === "append" && (
                            <AppendUpload ref={rowsRef.current[i]} name={upload.name} datasetId={datasetId} file={upload.file} extension={upload.extension} />
                        )}
                    </div>
                ))}
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={onUploadAll} disabled={!isUploadAllEnabled}>
                    Upload all
                </Button>

                {isDoneEnabled ? <Button onClick={onClose}>Done</Button> : <Button onClick={onCancel}>Cancel</Button>}
            </DialogActions>
            {csvUploads.length > 0 && <CsvModal file={csvUploads[0].file} />}
        </>
    );
};
