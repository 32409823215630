export enum UploadStatus {
    Pending = "pending",
    Uploading = "uploading",
    Uploaded = "uploaded",
    Failed = "Failed"
}

export type Upload = {
    id: string;
    name: string;
    file: File;
    status: UploadStatus;
} & (
    | {
          extension: ".geojson" | ".csv" | ".zip" | ".gpkg";
          type: "vector";
      }
    | {
          extension: ".tiff" | ".tif" | ".mbtiles";
          type: "raster";
      }
    | {
          extension: ".geojson" | ".zip" | ".csv";
          type: "append";
      }
);
