import React from "react";

import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import ZoomIcon from "@mui/icons-material/Search";
import Palette from "@mui/icons-material/Palette";
import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as mapActions from "../../../../reducers/map";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { toggleAppLayer } from "../../../../actions/globalActions";
import { removeDatasetFromAppThunk, removeRasterFromAppThunk } from "../../../../actions/apps";
import { handleError } from "../../../../utils/networkErrorUtils";
import { unwrapResult } from "@reduxjs/toolkit";
import { DATA_TYPES } from "../../../../utils/constants/dataType";
import ButtonLink from "../../../../components/ButtonLink/ButtonLink";
import { makeStyles } from "@mui/styles";
import { getAppPermissionType } from "selectors/appData";
import { PermissionType } from "features/groups/model/PermissionType";

const useStyles = makeStyles(() => ({
    link: {
        display: "flex"
    }
}));

const Context = ({ anchorEl, open, layer, toggleCMenu, onLayerSelected, markForRecomputation }) => {
    const classes = useStyles();

    const permissionType = useSelector(getAppPermissionType);

    const history = useHistory();
    const dispatch = useDispatch();

    const { clientId, projectId, appId } = useParams();

    const closeContextMenu = (e) => {
        e.stopPropagation();
        toggleCMenu();
    };
    const zoomToLayer = (e) => {
        closeContextMenu(e);
        let bounds = [layer.bounds.coordinates[0][0], layer.bounds.coordinates[0][2]];
        dispatch(mapActions.fitMapBounds({ bounds, options: { padding: 100, animate: false } }));
        dispatch(toggleAppLayer(layer.resourceId, true));
    };

    const pathname = () => {
        const { resourceId, type } = layer;
        if (type === DATA_TYPES.raster) {
            return `/${clientId}/${projectId}/rasters/edit/` + resourceId;
        }
        return `/${clientId}/${projectId}/datasets/edit/` + resourceId;
    };

    const onRemoveLayer = (layer) => {
        markForRecomputation();
        if (layer.type === DATA_TYPES.raster) {
            removeRasterLayer(layer.resourceId);
        } else {
            removeDatasetLayer(layer.resourceId);
        }
    };

    const removeDatasetLayer = (datasetId) => {
        dispatch(removeDatasetFromAppThunk({ appId, datasetId }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Dataset removed");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const removeRasterLayer = (rasterId) => {
        dispatch(removeRasterFromAppThunk({ appId, rasterId }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Raster removed");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    function removeLayer(e, layer) {
        closeContextMenu(e);

        const toastrConfirmOptions = {
            onOk: () => onRemoveLayer(layer),
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to remove the dataset "${layer.name}" from the application?`, toastrConfirmOptions);
    }

    function changeLayerStyle(e, layer) {
        closeContextMenu(e);
        onLayerSelected(layer);
    }

    return (
        <Menu anchorEl={anchorEl} open={Boolean(open)} onClose={toggleCMenu}>
            <MenuItem onClick={zoomToLayer} className="menu-button" data-testid={zoomToLayerButtonTestId}>
                <ZoomIcon className="icon" />
                Zoom to layer
            </MenuItem>

            <MenuItem onClick={(e) => closeContextMenu(e)} className="menu-button">
                <ButtonLink to={{ pathname: pathname(), state: { prevLocation: history.location.pathname } }} className={classes.link} data-testid={editDatasetButtonTestId}>
                    <EditIcon className="icon" />
                    Edit dataset
                </ButtonLink>
            </MenuItem>

            <MenuItem onClick={(e) => removeLayer(e, layer)} className="menu-button" data-testid={removeDatasetButtonTestId} disabled={permissionType < PermissionType.WRITE_READ}>
                <Delete className="icon" />
                Remove dataset
            </MenuItem>
            <MenuItem
                onClick={(e) => changeLayerStyle(e, layer)}
                className="menu-button"
                data-testid={changeLayerStyleButtonTestId}
                disabled={permissionType < PermissionType.WRITE_READ}
            >
                <Palette className="icon" />
                Change layer style
            </MenuItem>
        </Menu>
    );
};

export default Context;

const zoomToLayerButtonTestId = "qa-app-edit-view-zoom-to-layer-button";
const editDatasetButtonTestId = "qa-app-edit-view-edit-dataset-button";
const removeDatasetButtonTestId = "qa-app-edit-view-remove-dataset-button";
const changeLayerStyleButtonTestId = "qa-app-edit-view-change-layer-style-button";
