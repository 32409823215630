import GeometryTypeIcon from "@mui/icons-material/Category";
import { sortByDate, sortByDateReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";
import { Sorting } from "models/tableView/Sorting";

const sortByName = (dataset1: any, dataset2: any) => sortByString(dataset1.name, dataset2.name);
const sortByNameReverse = (dataset1: any, dataset2: any) => sortByStringReverse(dataset1.name, dataset2.name);

const sortBySchema = (dataset1: any, dataset2: any) => sortByString(dataset1.schemaName, dataset2.schemaName);
const sortBySchemaReverse = (dataset1: any, dataset2: any) => sortByStringReverse(dataset1.schemaName, dataset2.schemaName);

const sortByType = (dataset1: any, dataset2: any) => sortByString(dataset1.geometryType, dataset2.geometryType);
const sortByTypeReverse = (dataset1: any, dataset2: any) => sortByStringReverse(dataset1.geometryType, dataset2.geometryType);

const sortByModified = (dataset1: any, dataset2: any) => sortByDate(new Date(dataset1.modifiedUtc), new Date(dataset2.modifiedUtc));
const sortByModifiedReverse = (dataset1: any, dataset2: any) => sortByDateReverse(new Date(dataset1.modifiedUtc), new Date(dataset2.modifiedUtc));

const datasetColumns: Sorting[] = [
    {
        name: "",
        type: ""
    },
    {
        type: "type",
        name: "Type",
        component: <GeometryTypeIcon fontSize="small" />,
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByTypeReverse : sortByType)
    },
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByNameReverse : sortByName)
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByModifiedReverse : sortByModified),
        start: true
    },
    {
        type: "schema",
        name: "Schema",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortBySchemaReverse : sortBySchema)
    }
];
export default datasetColumns;
