import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Menu, MenuItem, TableRow, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import MenuButtonCell from "../../../../../components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import NameCell from "../../../../../components/CustomTable/components/customCells/NameCell/NameCell";
import TooltipCell from "../../../../../components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import CustomTypography from "../../../../../components/CustomTypography/CustomTypography";
import { fromNowWithCheck } from "../../../../../utils/timeUtils";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { useDispatch, useSelector } from "react-redux";
import { deleteApp, generateCache, makeAppCopyThunk, makeAppPublicThunk, unpublishAppThunk } from "../../../../../actions/apps";
import { handleError } from "../../../../../utils/networkErrorUtils";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import { unwrapResult } from "@reduxjs/toolkit";
import ButtonLink from "../../../../../components/ButtonLink/ButtonLink";
import { makeStyles } from "@mui/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { getCurrentUser } from "../../../../../features/auth/selectors";
import { ADMIN_ROLE } from "utils/accountUtils";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import { getFeatureFlags } from "features/featureFlags/selectors";
import CustomChip from "components/CustomChip/CustomChip";
import OverflowCell from "components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import { permisionPrettyNames, permissions } from "utils/constants/permissionTypes";
import { CELL_SIZES } from "utils/constants/cellSizes";
import { PermissionType } from "features/groups/model/PermissionType";
import StatusBadge from "../../../../../components/StatusBadge/StatusBadge";
import { setAppPublishedStatus } from "reducers/appData/appData";

const useStyles = makeStyles((theme) => ({
    link: {
        display: "flex"
    },
    blueChip: {
        color: theme.customColors.primaryColor,
        fill: theme.customColors.primaryColor,
        backgroundColor: "inherit",
        padding: 0
    }
}));

const AppRow = ({ app, onPublishModalOpen }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useStyles();

    const currentUser = useSelector(getCurrentUser);
    const featureFlags = useSelector(getFeatureFlags);

    const dispatch = useDispatch();

    const onMenuClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onClickDeleteApp = (e) => onClickGeneric(e, onDeleteApp, "Delete application " + app.name);

    const onClickMakeCopy = (e) =>
        onClickGeneric(e, onMakeCopy, "You are about to make a copy of the application. This will duplicate everything except the permissions. Do you want to proceed?");

    const onClickGeneric = (e, onOk, confirmationMessage) => {
        onMenuClose(e);
        const toastrConfirmOptions = {
            onOk: onOk,
            onCancel: () => { }
        };
        toastr.confirm(confirmationMessage, toastrConfirmOptions);
    };

    const onDeleteApp = () => {
        dispatch(deleteApp(app.id))
            .then(() => {
                toastr.success("App deleted");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const onMakeCopy = (e) => {
        dispatch(makeAppCopyThunk({ appId: app.id }))
            .then(unwrapResult)
            .then((res) => {
                toastr.success("Application copied successfully");
                dispatch(generateCache(res.id));
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const togglePublish = (e) => {
        e.stopPropagation();
        if (app.isPublished) {
            // TODO: the ts-ignore should be removed after refactoring to ts the file where unpublishAppThunk is
            // @ts-ignore
            dispatch(unpublishAppThunk({ appId: app.id }))
                .then(unwrapResult)
                .then(() => {
                    dispatch(setAppPublishedStatus(false));
                    toastr.success(`Application is now unpublished`);
                })
                .catch((err) => handleError(err));
        } else {
            onPublishModalOpen({ name: app.name, id: app.id });
        }
    };

    const togglePublic = () => {
        dispatch(makeAppPublicThunk({ appId: app.id, ispublic: !app.public }))
            .then(unwrapResult)
            .then(() => {
                const message = app.public ? "private" : "public";
                toastr.success(`Application is now ${message}`);
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const togglePublicConfirmationMessage = app.public
        ? "You are about to make the application private. Do you want to proceed?"
        : "You are about to make the application public.  Anyone with the link can access public applications. Do you want to proceed?";

    const onClickTogglePublic = (e) => onClickGeneric(e, togglePublic, togglePublicConfirmationMessage);

    const pathname = "applications/edit/" + app.id;

    const getTooltipStatus = (app) => {
        return app.isPublished ? "Published" : "Unpublished";
    };

    return (
        <>
            <TableRow onClick={onMenuClose} component={ButtonLink} to={pathname} data-testid={rowButtonTestId}>
                <TooltipCell tooltipTitle={getTooltipStatus(app)} size="small" paddingRight="0px">
                    {app.isPublished && <StatusBadge status="published" />}
                    {!app.isPublished && <StatusBadge status="unpublished" />}
                </TooltipCell>

                <NameCell>
                    <span>{app.name}</span>
                    {app.public &&
                        <Tooltip id="tooltip-top" title="Public">
                            <span>
                                <CustomChip label="Public" Icon={PublicIcon} className={classes.blueChip} expanded={false} component="span" />
                            </span>
                        </Tooltip>
                    }
                </NameCell>

                <TooltipCell tooltipTitle={dayjs(app.createdUtc).format("LLL")}>
                    <CustomTypography variant="body2">
                        {fromNowWithCheck(dayjs(app.createdUtc))}
                    </CustomTypography>
                </TooltipCell>

                <TooltipCell tooltipTitle={dayjs(app.modifiedUtc).format("LLL")}>
                    <CustomTypography variant="body2">
                        {fromNowWithCheck(dayjs(app.modifiedUtc))}
                    </CustomTypography>
                </TooltipCell>

                <OverflowCell cellSize={CELL_SIZES.extraSmall}> {permisionPrettyNames[app.permissionType]} </OverflowCell>

                <MenuButtonCell onMenuClick={onMenuClick} />

                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                    <MenuItem onClick={onMenuClose} className="menu-button" data-testid={editButtonTestId}>
                        <ButtonLink to={pathname} className={classes.link}>
                            <EditIcon className="icon" />
                            Edit
                        </ButtonLink>
                    </MenuItem>
                    <MenuItem onClick={onClickMakeCopy} className="menu-button" data-testid={makeCopyButtonTestId} disabled={app.permissionType < PermissionType.READ}>
                        <ContentCopyRoundedIcon className="icon" />
                        Make Copy
                    </MenuItem>
                    {app.permissionType >= permissions.WRITE.value && (
                        <MenuItem onClick={togglePublish} className="menu-button" data-testid={enableDisableButtonTestId}>
                            {app.isPublished ? (
                                <>
                                    <VisibilityOff className="icon" />
                                    Unpublish
                                </>
                            ) : (
                                <>
                                    <Visibility className="icon" />
                                    Publish
                                </>
                            )}
                        </MenuItem>
                    )}

                    {currentUser?.role === ADMIN_ROLE && featureFlags.PUBLIC && (
                        <MenuItem onClick={onClickTogglePublic} className="menu-button" data-testid={publicButtonTestId}>
                            {app.public ? (
                                <>
                                    <PublicOffIcon className="icon" />
                                    Make private
                                </>
                            ) : (
                                <>
                                    <PublicIcon className="icon" />
                                    Make public
                                </>
                            )}
                        </MenuItem>
                    )}

                    <MenuItem onClick={onClickDeleteApp} className="menu-button" data-testid={deleteButtonTestId} disabled={app.permissionType < PermissionType.OWNER}>
                        <DeleteIcon className="icon" />
                        Delete
                    </MenuItem>
                </Menu>
            </TableRow>
        </>
    );
};

export default AppRow;

const rowButtonTestId = "qa-app-row-row-button-link";
const editButtonTestId = "qa-app-row-edit-button";
const makeCopyButtonTestId = "qa-app-row-make-copy-button";
const enableDisableButtonTestId = "qa-app-row-publish-unpublish-button";
const publicButtonTestId = "qa-app-row-public-button";
const deleteButtonTestId = "qa-app-row-delete-button";
