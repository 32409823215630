import React, { useCallback, useEffect, useState } from "react";
import { Button, debounce, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import ProjectionCard from "./ProjectionCard/ProjectionCard";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { useDispatch } from "react-redux";
import { getProjections } from "../../../../actions/config";

const ProjectionPicker = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [value, setValue] = React.useState({});
    const [inputValue, setInputValue] = React.useState("");
    const [, setAnchorEl] = useState(null);
    const [projectionOptions, setProjectionOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        getProjectionsBackendCall(inputValue);
    }, []);

    const objectIsEmpty = (obj) => !Object.keys(obj).length;

    const onInputChange = (_, newInputValue) => {
        setLoading(true);
        if (!objectIsEmpty(value)) setValue({});
        setInputValue(newInputValue);
        getProjectionsBackendCall(newInputValue);
    };

    const getProjectionsBackendCall = useCallback(
        debounce((searchText) => {
            dispatch(getProjections({ searchText })).then((res) => {
                setProjectionOptions(res.payload);
                setLoading(false);
            });
        }, 700),
        []
    );

    const onChange = (_, newValue) => {
        if (!newValue?.crs) return;
        setValue(newValue);
        setInputValue(getProjectionString(newValue));
    };

    const onMenuClose = (e) => {
        setAnchorEl(null);
    };

    const onAddProjection = () => {
        let projections = [...props.projections];
        if (!projections.some((projection) => projection.crs === value.crs)) {
            projections.push(value);
            props.onChange(projections);
        }
        setDialogOpen(false);
    };

    const onDeleteProjection = (projection, e) => {
        onMenuClose(e);
        let projections = props.projections.filter((x) => x !== projection);

        props.onChange(projections);
    };

    const getProjectionString = (proj) => `(${proj.crs}) ${proj.name}`;

    const generateProjectionCard = (item) => {
        return <ProjectionCard key={item.crs} onDeleteClick={() => onDeleteProjection(item)} projection={item} disabled={props.disabled} />;
    };

    return (
        <div className="projection-picker">
            <div className="projections">{props.projections.map((basemap, index) => generateProjectionCard(basemap, index))}</div>
            <div className="button-container">
                <Button className="button" variant="text" color="primary" onClick={() => setDialogOpen(true)} disabled={props.disabled} data-testid={addProjectionButtonTestId}>
                    <AddIcon className="left-icon" fontSize="small" />
                    Add Projection
                </Button>
            </div>

            <CustomModal
                handleClose={() => setDialogOpen(false)}
                isOpen={dialogOpen}
                onConfirm={onAddProjection}
                dialogTitle={"Add Projection"}
                dialogType={"add"}
                disabled={objectIsEmpty(value)}
            >
                <Autocomplete
                    autoHighlight
                    freeSolo
                    loading={loading}
                    disableClearable
                    options={projectionOptions}
                    filterOptions={(options, _) => options} // this removes the filtering done on the frontend side
                    getOptionLabel={getProjectionString}
                    onChange={onChange}
                    inputValue={inputValue}
                    onInputChange={onInputChange}
                    noOptionsText="No projections available"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="filled-required"
                            label="Projection"
                            variant="filled"
                            inputProps={{ ...params.inputProps, "data-testid": searchProjectionTestId }}
                        />
                    )}
                    isOptionEqualToValue={(a, b) => a.crs === b.crs}
                />
            </CustomModal>
        </div>
    );
};

export default ProjectionPicker;

const addProjectionButtonTestId = "qa-app-edit-view-settings-add-projection-button";
const searchProjectionTestId = "qa-app-edit-view-settings-search-projection";
