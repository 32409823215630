import {FC, useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomTypography from "components/CustomTypography/CustomTypography";
import {GisRole} from "../../model/GisRole";
import {useUserPrefetch} from "../../api";

type Props = {
    username: string;
    role: GisRole;
    onClick: () => void
}
const UserItem: FC<Props> = ({username, role, onClick}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const prefetchUser = useUserPrefetch("fetchUserByEmail");

    const onMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    return (
        <div className="item" data-testid={userRowTestId} onClick={() => onClick()}>
            <div className="container">
                <div className="row-container">
                    <div className={"row-1"}>
                        <div className="name">
                            <CustomTypography variant="body2" textWeight="semibold">
                                {username}
                            </CustomTypography>
                        </div>
                        <div className="roles">
                            <CustomTypography variant="body2">{role}</CustomTypography>
                        </div>

                        <Tooltip id="tooltip-top" title="Options">
                            <div className="button" data-testid={userRowMenuButtonTestId} onClick={onMenuClick}>
                                <MenuIcon/>
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                    <MenuItem
                        data-testid={userRowMenuEditButtonTestId}
                        onClick={() => onClick()}
                        className="menu-button"
                        onMouseEnter={() => prefetchUser(username)}
                    >
                        <EditIcon className="icon"/>
                        Edit
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

export default UserItem

const userRowTestId = "qa-users-list-view-user-row";
const userRowMenuButtonTestId = "qa-users-list-view-user-row-menu-button";
const userRowMenuEditButtonTestId = "qa-users-list-view-user-row-menu-edit-button";