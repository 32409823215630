import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./customBaseQuery";
import config from "../config";
import { dprApiClient } from "actions/dprApiClient";

const baseDprApi = createApi({
    reducerPath: "dprApi",
    baseQuery: axiosBaseQuery(config.dprApiUrl + "api/external/projects/{{clientId}}/{{projectId}}/", dprApiClient),
    endpoints: () => ({})
});

export default baseDprApi;

export const resetApiState = baseDprApi.util?.resetApiState;
