import { TableRow } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import NameCell from "components/CustomTable/components/customCells/NameCell/NameCell";
import TooltipCell from "components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { fromNowWithCheck } from "utils/timeUtils";
import ButtonLink from "components/ButtonLink/ButtonLink";
import PublicIcon from "@mui/icons-material/Public";
import CustomChip from "components/CustomChip/CustomChip";
import { useStyles } from "./styles";
import { useHistory, useParams } from "react-router";

type Props = {
    app: any;
    tab: string;
};

type Params = {
    clientId: string;
    projectId: string;
};

const AppRow: FC<Props> = ({ app, tab }) => {
    const classes = useStyles();

    const { clientId, projectId } = useParams<Params>();

    const history = useHistory();

    const pathname = `/${clientId}/${projectId}/applications/edit/${app.id}`;

    return (
        <TableRow component={ButtonLink} to={{ pathname: pathname, state: { prevLocation: history.location.pathname, tab } }} data-testid={rowButtonTestId}>
            <NameCell>
                <span>{app.name}</span> {app.public && <CustomChip label="Public" Icon={PublicIcon} className={classes.blueChip} expanded={false} component="span" />}
            </NameCell>

            <TooltipCell tooltipTitle={dayjs(app.modifiedUtc).format("LLL")}>
                <CustomTypography variant="body2">{fromNowWithCheck(dayjs(app.modifiedUtc))}</CustomTypography>
            </TooltipCell>
        </TableRow>
    );
};

export default AppRow;

const rowButtonTestId = "qa-app-row-row-button-link";
