import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    addBasemapTool: {
        width: 500,
        height: "100vh",
        backgroundColor: theme.customColors.appBgColorLight,
        overflow: "auto"
    },
    addIcon: {
        fontSize: 60
    },
    addButton: {
        position: "absolute",
        bottom: 15,
        right: 25,
        zIndex: 1
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `0 ${theme.customSpacing.generalSidePadding}`,
        height: theme.customSpacing.headerHeight
    },
    basemapsList: {
        padding: "0 20px",
        overflow: "auto",
        height: "calc(100% - 258px)", //40 filter section + 42 info text + 80 search field  + 48 header + 48 bottomBar
        boxSizing: "border-box"
    },
    sorting: { display: "flex", alignItems: "center", padding: "8px 20px" },
    sortingDropdown: {
        cursor: "pointer",
        textTransform: "capitalize",
        display: "flex",
        alignItems: "center"
    },
    sortinIcon: { marginRight: 4 },
    bottomBar: {
        minHeight: 48,
        borderTop: "1px solid #e3e4e5",
        backgroundColor: "#fff",
        boxSizing: "border-box"
    },
    closeButton: {
        cursor: "pointer"
    }
}));
