import CustomTypography from "components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import { FC } from "react";

type IconProps = {
    className: string;
};

type Props = {
    IconComponent: FC<IconProps>;
    title: string;
    description: string;
    className?: string;
};

const SectionHeader: FC<Props> = ({ IconComponent, title, description, className = "" }) => {
    const classes = useStyles();
    return (
        <div className={className}>
            <div className={classes.title}>
                <IconComponent className={classes.icon} />
                <CustomTypography textWeight="bold" variant="body1" fontSize={14}>
                    {title}
                </CustomTypography>
            </div>
            <CustomTypography variant="subtitle2" textWeight="bold" className={classes.description} fontSize={12}>
                {description}
            </CustomTypography>
        </div>
    );
};

export default SectionHeader;
