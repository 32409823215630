import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
    return {
        icon: {
            width: 24,
            marginRight: 10
        },
        title: {
            display: "flex",
            alignItems: "center"
        },
        description: {
            marginTop: theme.spacing(1),
            color: theme.customColors.darkGrey
        }
    };
});
