import { getClientId, getProjectId } from "features/core/selectors";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import * as types from "../../../actions/actionTypes";
import useAuthentication from "../../../app/hooks/useAuthentication";
import useSignalR from "../../../utils/customHooks/useSignalR";
import { CacheStatus } from "../../../features/rasters/models/CacheStatus";
import { updateRastersQueryData, updateRasterQueryDataById } from "../../../features/rasters/api";

enum TileGenerationType {
    Dataset,
    Map,
    Raster
}

type TileGenerationStatusUpdate = {
    resourceId: string;
    generationProcessId: string;
    tileGenerationType: TileGenerationType;
    name: string;
    cacheStatus: CacheStatus;
    fileSize?: number;
    minZoom?: number;
    maxZoom?: number;
    tileGoal?: number;
    tileCount?: number;
    completedUtc?: string;
};

const useProgressNotifications = () => {
    const clientId = useAppSelector(getClientId);
    const projectId = useAppSelector(getProjectId);
    const dispatch = useAppDispatch();
    const { getAccessToken } = useAuthentication();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tokenFactory = useCallback(() => getAccessToken(), []);
    const path = `progresshub?ClientId=${clientId}&ProjectId=${projectId}`;

    const { addEvent, removeEvent } = useSignalR(path, tokenFactory);

    useEffect(() => {
        addEvent("tileGenerationProgress", (data: TileGenerationStatusUpdate) => {
            dispatch({ type: types.SIGNALR_PROGRESS_UPDATE, payload: data });
            if (data.tileGenerationType === TileGenerationType.Raster) {
                dispatch(
                    updateRastersQueryData({
                        id: data.resourceId,
                        cacheStatus: data.cacheStatus,
                        minZoom: data.minZoom ?? 0,
                        maxZoom: data.maxZoom ?? 0,
                        fileSize: data.fileSize ?? 0,
                    })
                );

                dispatch(
                    updateRasterQueryDataById({
                        id: data.resourceId,
                        cacheStatus: data.cacheStatus,
                        minZoom: data.minZoom ?? 0,
                        maxZoom: data.maxZoom ?? 0,
                        fileSize: data.fileSize ?? 0
                    })
                );
            }
        });

        addEvent("tileGenerationError", (data) => {
            dispatch({ type: types.SIGNALR_ERROR_UPDATE, payload: data });
        });

        return () => {
            removeEvent("tileGenerationProgress");
            removeEvent("tileGenerationError");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useProgressNotifications;
