import { GeometryIcons } from "../customIcons";

export const getGeometryIcon = (geometryType) => {
    switch (geometryType) {
        case "POLYGON":
            return GeometryIcons.polygon;
        case "MULTIPOLYGON":
            return GeometryIcons.multipolygon;
        case "LINESTRING":
            return GeometryIcons.line;
        case "MULTILINESTRING":
            return GeometryIcons.multiline;
        case "POINT":
            return GeometryIcons.point;
        case "MULTIPOINT":
            return GeometryIcons.multipoint;
        default:
            return GeometryIcons.point;
    }
};
