export const makeAppLayerFromDataset = (dataset, sourceId) => {
    const appLayer = {
        name: dataset.name,
        sourceId,
        sourceName: dataset.tileName,
        resourceId: dataset.id,
        type: "vector",
        geometryType: dataset.geometryType,
        bounds: dataset.bounds,
        options: {
            enabled: false,
            downloadable: true
        },
        visible: true
    };
    return appLayer;
};

export const makeAppLayerFromRaster = (raster) => {
    const appLayer = {
        name: raster.name,
        sourceId: raster.id,
        sourceName: raster.name,
        resourceId: raster.id,
        type: "raster",
        geometryType: "raster",
        bounds: raster.bounds,
        options: {
            enabled: false,
            downloadableGeoTiff: Boolean(raster.geoTiff)
        },
        visible: true
    };

    return appLayer;
};
