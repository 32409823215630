import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDayJs from "@mui/lab/AdapterDayjs";
import { TextField, ButtonBase } from "@mui/material";
import CustomTypography from "../../../../../components/CustomTypography/CustomTypography";
import { metadataTypes } from "../../../../../utils/constants/metadata";

const MetadataPropertyField = ({ property, ...otherProps }) => {
    const onTagChange = (tag) => {
        const tags = otherProps.value.map((x) => {
            if (x.name === tag.name) {
                return {
                    ...x,
                    enabled: !x.enabled
                };
            }
            return x;
        });

        otherProps.onChange(tags);
    };

    const renderDateInput = () => (
        <LocalizationProvider dateAdapter={AdapterDayJs}>
            {/* 
            We use the Mobile Date picker because this doesn't allow the user to write dates manually. The normal date picker has some weird behaviour that it's better to avoid if possible
            */}
            <MobileDatePicker
                clearable
                label={property.name}
                renderInput={(props) => (
                    <TextField
                        variant="filled"
                        fullWidth
                        helperText={otherProps.helperText}
                        required={property.mandatory}
                        {...{
                            ...props,
                            inputProps: {
                                "data-testid": getDataTestId(),
                                ...props.inputProps
                            }
                        }}
                        error={otherProps.error}
                    />
                )}
                {...otherProps}
            />
        </LocalizationProvider>
    );

    const renderTagListInput = () => (
        <div className="field">
            <CustomTypography variant="body1" className="tag-list-header" color={!!otherProps.error ? "red" : ""}>
                {property.name + (property.mandatory ? " *" : "")}
            </CustomTypography>
            <div className="tag-list">
                {otherProps.value.map((tag) => (
                    <ButtonBase
                        key={tag.id}
                        className={tag.enabled ? "tag enabled" : "tag"}
                        onClick={() => onTagChange(tag)}
                        style={tag.enabled ? { borderColor: tag.color, backgroundColor: tag.color } : { borderColor: tag.color }}
                        data-testid={getDataTestId()}
                    >
                        <CustomTypography variant="caption" className="text">
                            {tag.name}
                        </CustomTypography>
                    </ButtonBase>
                ))}
            </div>
        </div>
    );

    const getDataTestId = () => {
        const name = property.type.toLocaleLowerCase().replaceAll(" ", "-");
        return metadataPropertyTestId + name;
    };

    switch (property.type) {
        case metadataTypes.TEXT:
        case metadataTypes.NUMBER:
            return (
                <TextField
                    id="filled-requried"
                    label={property.name}
                    variant="filled"
                    fullWidth
                    {...otherProps}
                    required={property.mandatory}
                    inputProps={{ "data-testid": getDataTestId() }}
                />
            );
        case metadataTypes.DATE:
            return renderDateInput();
        case metadataTypes.TAG_LIST:
            return renderTagListInput();
        default:
            return null;
    }
};

export default MetadataPropertyField;

const metadataPropertyTestId = "qa-dataset-edit-view-metadata-tab-metadata-property-";
