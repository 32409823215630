import { useEffect, useState } from "react";
import { Divider, Button, TextField, Typography, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { OptionType } from "components/CustomTable/types/OptionType";
import FilterDropdownMenu from "../FilterDropdownMenu/FilterDropdownMenu";
import { useStyles } from "./styles";

type Props<T> = {
    name: string;
    options: OptionType<T>[];
    isSearchable: boolean;
    selected: T[];
    isActive: boolean;
    onApply: (selectedValues: T[]) => void;
    onClear: () => void;
};

const SelectFilter = <T,>({ name, options, isSearchable, selected, isActive, onApply, onClear }: Props<T>) => {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState<string>("");

    const [currentOptions, setCurrentOptions] = useState<OptionType<T>[]>(options);

    useEffect(() => {
        setCurrentOptions(options);
    }, [options]);

    const onSelectAllClick = () => {
        onApply(currentOptions.map((x) => x.value));
    };

    const onInvertSelectionClick = () => {
        const newValues = currentOptions.map((x) => x.value).filter((x) => !selected.includes(x));
        onApply(newValues);
    };

    const onClearAllClick = () => {
        onClear();
    };

    const onOptionClick = (event: React.MouseEvent<HTMLDivElement>, option: T) => {
        const checked = !selected.includes(option);

        const newValues = checked ? [...selected, option] : selected.filter((opt) => opt !== option);

        onApply(newValues);

        if (newValues.length === 0) {
            onClear();
        } else {
            onApply(newValues);
        }
    };

    const onSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setCurrentOptions(options.filter((option) => option.displayName.includes(event.target.value)));
    };

    return (
        <FilterDropdownMenu isActive={isActive} label={name} selectedValuesCount={selected.length} type="select">
            <div className={classes.menu}>
                {isSearchable && (
                    <div className={classes.searchContainer}>
                        <TextField
                            fullWidth
                            size="small"
                            hiddenLabel
                            placeholder="Search"
                            variant="filled"
                            onKeyDown={(e) => e.stopPropagation()}
                            onChange={onSearchTermChange}
                            value={searchTerm}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                )}

                <Typography variant="body2" my={1}>
                    Select option(s):
                </Typography>

                <Divider />

                <div className={classes.actionButtons}>
                    <Button size="small" color="primary" disabled={selected.length === options.length} onClick={onSelectAllClick} data-testid={getSelectAllTestId(name)}>
                        All
                    </Button>

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Button
                        size="small"
                        color="primary"
                        disabled={selected.length < 1 || selected.length === options.length}
                        onClick={onInvertSelectionClick}
                        data-testid={getInvertTestId(name)}
                    >
                        Invert
                    </Button>

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Button size="small" color="primary" disabled={selected.length === 0} onClick={onClearAllClick} data-testid={getClearAllTestId(name)}>
                        Clear
                    </Button>
                </div>

                <Divider />

                <div className={classes.optionList}>
                    {currentOptions.map((option, index) => (
                        <div
                            key={index}
                            onClick={(event) => onOptionClick(event, option.value)}
                            className={classes.optionContainer + (selected.includes(option.value) ? " selected" : "")}
                            data-testid={getOptionTestId(name, option.displayName)}
                        >
                            {option.icon && option.icon}
                            <span className={classes.optionLabel}>{option.displayName}</span>
                        </div>
                    ))}
                </div>
            </div>
        </FilterDropdownMenu>
    );
};

export default SelectFilter;

const getOptionTestId = (name: string, option: string) => `qa-${name.toLocaleLowerCase()}-filter-${option.toLocaleLowerCase()}-option`;
const getSelectAllTestId = (name: string) => `qa-${name.toLocaleLowerCase()}-filter-select-all-button`;
const getInvertTestId = (name: string) => `qa-${name.toLocaleLowerCase()}-filter-invert-button`;
const getClearAllTestId = (name: string) => `qa-${name.toLocaleLowerCase()}-filter-clear-button`;
