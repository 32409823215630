import { TableCell } from "@mui/material";
import { FC, FCWC } from "react";
import { CELL_SIZES, getCellSizeWidth } from "../../../../../utils/constants/cellSizes";
import { useCurrentBreakpoint } from "../../../../../utils/customHooks/useCurrentBreakpoint";
import OverflowTip from "../../../../OverflowTip/OverflowTip";
import { useOverflowCellStyles } from "./styles";
import clsx from "clsx";
import CustomTypography from "../../../../CustomTypography/CustomTypography";

type Props = {
    cellSize?: string;
    className?: string;
    color?: string;
    size?: "small" | "medium";
    overwrittenCellWidthValue?: number | null;
};

const OverflowCell: FCWC<Props> = ({ children, cellSize, className, color, size, overwrittenCellWidthValue = null }) => {
    const currentBreakpoint = useCurrentBreakpoint();
    let cellWidth = overwrittenCellWidthValue ?? getCellSizeWidth(cellSize, currentBreakpoint);
    const classes = useOverflowCellStyles({ cellWidth });

    return (
        <TableCell size={size} className={clsx(className, classes.cellWidth)}>
            <OverflowTip variant="body2" color={color}>
                {children}
            </OverflowTip>
        </TableCell>
    );
};

OverflowCell.defaultProps = {
    cellSize: CELL_SIZES.medium,
    color: "inherit",
    size: "medium",
    children: <CustomTypography>-</CustomTypography>
};

export default OverflowCell;
