import { Radio } from "@mui/material";
import React, { useState } from "react";
import useIfMounted from "../../../../../../utils/customHooks/useIfMounted";
import OverflowTip from "../../../../../../components/OverflowTip/OverflowTip";
import { useStyles } from "features/groups/components/GroupEditView/styles";
import { Permission } from "utils/constants/permissionTypes";
import { PermissionType } from "features/groups/model/PermissionType";
import { ResourceWithPermission } from "features/groups/model/ResourceWithPermission";

type ResourcePermissionsType = {
    resource: ResourceWithPermission;
    permissions: Permission[];
    onPermissionChanged: (resourceId: string, currentPermission: PermissionType, newPermission: PermissionType) => Promise<void>;
};

const ResourcePermissions = ({ resource, permissions, onPermissionChanged }: ResourcePermissionsType) => {
    const classes = useStyles();
    const [updatingPermission, setUpdatingPermission] = useState(false);
    const execIfMounted = useIfMounted();

    const handlePermissionChange = (newPermission: Permission) => {
        setUpdatingPermission(true);
        onPermissionChanged(resource.id, resource.permissionType, newPermission.value).finally(() => execIfMounted(() => setUpdatingPermission(false)));
    };

    return (
        <div className={classes.resourcePermissionItem}>
            <div className="name">
                <OverflowTip data-testid={rowTestId}>{resource.name}</OverflowTip>
            </div>

            {permissions.map((permission, index) => {
                return (
                    <div key={index} className="checkbox-container">
                        <Radio
                            disabled={updatingPermission}
                            checked={resource.permissionType === permission.value}
                            onChange={() => handlePermissionChange(permission)}
                            data-testid={createRadioButtonTestId(permission)}
                        />
                        <div className="radio-label">{permission.name}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default ResourcePermissions;

const rowTestId = `qa-resource-permisssion-row`;
const createRadioButtonTestId = (permission: Permission) => `qa-resource-permisssion-${permission.name.toLowerCase()}-radio-button`;
