import { Autocomplete, AutocompleteRenderInputParams, Button, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { getApp, getFetching, getIncludedDatasets } from "selectors/appData";
import { useAppSelector } from "store/hooks/useAppSelector";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { getDatasetColumns } from "actions/datasets";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import { addAppSearchBarThunk, removeAppSearchBarThunk, updateAppSearchBarThunk } from "actions/apps";
import { unwrapResult } from "@reduxjs/toolkit";
import toastr from "components/CustomToastr/CustomToastr";
import { handleError } from "utils/networkErrorUtils";
import UpdateIcon from "@mui/icons-material/Save";
import { ComponentProps } from "../AppSettingsView";

type SearchDataset = {
    id: string;
    name: string;
};

const SearchBarSection: FC<ComponentProps> = ({ disabled }) => {
    const app = useAppSelector(getApp);
    const fetching = useAppSelector(getFetching);

    const includedDatasets = useAppSelector(getIncludedDatasets);

    const includedDatasetsObjectArray: SearchDataset[] = useMemo(
        () =>
            Object.keys(includedDatasets).map((x) => ({
                id: x,
                name: includedDatasets[x].name
            })),
        [includedDatasets]
    );

    const initialDatasetSearchValue: SearchDataset = {
        id: app.searchBar?.datasetId,
        name: app.searchBar?.datasetId && includedDatasets[app.searchBar.datasetId]?.name
    };
    const [datasetSearchValue, setDatasetSearchValue] = useState(initialDatasetSearchValue);

    const [columnOptions, setColumnOptions] = useState<string[]>([]);
    const initialColumnSearchValue = app.searchBar?.columnName ?? "";
    const [columnSearchValue, setColumnSearchValue] = useState<string>(initialColumnSearchValue);
    const [loadingColumns, setLoadingColumns] = useState(false);

    const initialValuesAreSelected = initialDatasetSearchValue.id === datasetSearchValue.id && initialColumnSearchValue === columnSearchValue;
    const columnNotSelected = !!datasetSearchValue.id && !columnSearchValue;
    const updateButtonDisabled = initialValuesAreSelected || columnNotSelected || fetching;

    const dispatch = useAppDispatch();

    useEffect(() => {
        app.searchBar?.datasetId && getColumns(app.searchBar.datasetId);
    }, []);

    const onChangeDatasetSearchValue = (_: any, newValue: string | SearchDataset | null) => {
        setColumnOptions([]);
        setColumnSearchValue("");
        if (newValue !== null) {
            getColumns((newValue as SearchDataset).id);
        }
        setDatasetSearchValue((newValue as SearchDataset) ?? { id: undefined, name: undefined });
    };

    const renderInput = (label: string, dataTestId: string, params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                id="filled-required"
                label={label}
                variant="filled"
                style={{ marginBottom: 10 }}
                inputProps={{ ...params.inputProps, "data-testid": dataTestId }}
            />
        );
    };

    const getColumns = (datasetId: string) => {
        setLoadingColumns(true);
        dispatch(getDatasetColumns(datasetId)).then((res) => {
            setLoadingColumns(false);
            setColumnOptions(res.result.map((x: any) => x.name));
        });
    };

    const updateSearchBar = () => {
        // TODO: the ts-ignore should be removed after refactoring to ts the file where the thunks are

        // added this check just as a safety net
        if (initialValuesAreSelected || columnNotSelected) return;

        let thunk;
        if (!app.searchBar?.datasetId || !app.searchBar?.columnName) {
            // @ts-ignore
            thunk = addAppSearchBarThunk({
                appId: app.id,
                datasetId: datasetSearchValue.id,
                columnName: columnSearchValue
            });
        } else if (!datasetSearchValue.id && !columnSearchValue) {
            // @ts-ignore
            thunk = removeAppSearchBarThunk({
                appId: app.id
            });
        } else {
            // @ts-ignore
            thunk = updateAppSearchBarThunk({
                appId: app.id,
                datasetId: datasetSearchValue.id,
                columnName: columnSearchValue
            });
        }

        dispatch(thunk)
            .then(unwrapResult)
            .then(() => {
                toastr.success(`Updated search bar configuration`);
            })
            .catch((err: any) => {
                handleError(err);
            });
    };

    return (
        <>
            <InfoTextSection paddingBottom={2} iconSize="extra-small" textVariant="body2" textWeight="semibold">
                A search bar will appear in the upper right corner of the map. This will simplify finding a feature on the map based on the value in the searchable column. Be aware
                that the changes will appear immediately on viewer.
            </InfoTextSection>

            <Autocomplete
                disabled={disabled}
                freeSolo
                autoHighlight
                options={includedDatasetsObjectArray}
                value={datasetSearchValue}
                getOptionLabel={(option) => (option as SearchDataset)?.name ?? ""}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.name}
                    </li>
                )}
                onChange={onChangeDatasetSearchValue}
                renderInput={(params) => renderInput("Dataset", searchDatasetTestId, params)}
                isOptionEqualToValue={(a, b) => a.id == b.id}
            />

            <Autocomplete
                disabled={disabled}
                freeSolo
                autoHighlight
                loading={loadingColumns}
                options={columnOptions}
                value={columnSearchValue}
                onChange={(_, newValue) => setColumnSearchValue(newValue ?? "")}
                renderInput={(params) => renderInput("Column", searchColumnTestId, params)}
            />

            <Button onClick={updateSearchBar} sx={{ marginTop: 1 }} disabled={updateButtonDisabled || disabled} variant="text" color="primary" data-testid={updateButtonTestId}>
                <UpdateIcon className="left-icon" fontSize="small" /> Update Search Bar
            </Button>
        </>
    );
};

export default SearchBarSection;

const searchDatasetTestId = "qa-app-edit-view-settings-search-dataset";
const searchColumnTestId = "qa-app-edit-view-settings-search-column";
const updateButtonTestId = "qa-app-edit-view-searchbar-update-button";
