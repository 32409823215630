import CheckIcon from "@mui/icons-material/Check";
import { Grid, MenuItem } from "@mui/material";
import ButtonLink from "../../../../components/ButtonLink/ButtonLink";
import CustomTypography from "../../../../components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";

type Props = {
    description: string;
    favIconUrl: string;
    name: string;
    moduleUrl: string;
    isAccessible: boolean;
};

const ModuleItem = ({ description, favIconUrl, name, moduleUrl, isAccessible }: Props) => {
    const classes = useStyles();

    return (
        <MenuItem className={classes.rootItem}>
            <Grid container className={classes.clickableContainer} wrap="nowrap" justifyContent="space-between" component={ButtonLink} to={{ pathname: moduleUrl }} target="_blank">
                <Grid item xs container alignItems="center">
                    <img className={classes.moduleIcon} src={favIconUrl} alt="module icon" />
                    <div>
                        <CustomTypography className={classes.moduleTitle}>{name}</CustomTypography>
                        <CustomTypography className={classes.moduleDescription}>{description}</CustomTypography>
                    </div>
                </Grid>

                <Grid item xs="auto" container wrap="nowrap" alignItems="center">
                    {isAccessible ? (
                        <>
                            <CheckIcon className={classes.checkedIcon} fontSize="small" />
                            <CustomTypography className={classes.activeText}> Active </CustomTypography>
                        </>
                    ) : (
                        <CustomTypography className={classes.noAccessBtn}>Find out more</CustomTypography>
                    )}
                </Grid>
            </Grid>
        </MenuItem>
    );
};

export default ModuleItem;
