import { Chip, Tooltip } from "@mui/material";
import { useState } from "react";
import { useStyles } from "./style";
import CollapsibleCard from "components/CollapsibleCard/CollapsibleCard";

import { useFormikContext } from "formik";
import { ScopeColumn } from "features/fidu/models/ScopeColumn";

const ColumnsSection = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(true);
    const { values } = useFormikContext<{ columns: ScopeColumn[] }>();

    const togglePropertiesSection = () => {
        setIsOpen(!isOpen);
    };

    const renderColumnTypeComponent = (text: string, label: string) => (
        <div className={classes.columnType}>
            {text}
            <Chip className={classes.chip} label={label} />
        </div>
    );
    const renderColumnType = (type: string) => {
        switch (type) {
            case "integer":
            case "bigint":
                return renderColumnTypeComponent("Number", "123");
            case "double precision":
                return renderColumnTypeComponent("Decimal", "1.23");

            case "character varying":
                return renderColumnTypeComponent("Text", "Abc");

            case "timestamp":
            case "timestamp with time zone":
                return renderColumnTypeComponent("Timestamp", "Date");

            case "date":
                return renderColumnTypeComponent("Date", "Date");
        }
    };

    const renderColumns = (columns: ScopeColumn[]) => {
        return columns.map((column) => {
            return (
                <div key={column.name} className={classes.columnsInfoContainer}>
                    <Tooltip id="tooltip-top" title={column.name}>
                        <div className={classes.columnName}>{column.name}</div>
                    </Tooltip>

                    <div className={classes.grow}></div>

                    {renderColumnType(column.type)}
                </div>
            );
        });
    };

    return (
        <div className="shadow-section">
            <CollapsibleCard maxHeight={200} paddingBottom={0} innerPadding={0} onToggleClick={togglePropertiesSection} title="Expected Columns" isToggled={isOpen} unmountOnExit>
                <div className={classes.columnsSection}>
                    <div className={classes.columnInfoHeader}>
                        <div className={classes.columnNameHeader}>Column Name</div>
                        <div className={classes.columnTypeHeader}>Column Type</div>
                    </div>
                    <div className={classes.columnsContainer}>{renderColumns(values.columns.filter((x) => x.name !== "ogc_fid"))}</div>
                </div>
            </CollapsibleCard>
        </div>
    );
};

export default ColumnsSection;
