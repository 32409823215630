import CustomDateRangePicker from "components/CustomDateRangePicker/CustomDateRangePicker";
import { Dayjs } from "dayjs";
import { FC } from "react";
import FilterDropdownMenu from "../FilterDropdownMenu/FilterDropdownMenu";

type Props = {
    name: string;
    selected: Dayjs[];
    isActive: boolean;
    onApply: (selectedValues: [Dayjs, Dayjs]) => void;
    onClear: () => void;
};

const DateRangeFilter: FC<Props> = ({ name, selected, isActive, onApply, onClear }) => {
    const onDateRangeChanged = (values: [Dayjs, Dayjs]) => {
        onApply(values);
    };

    return (
        <FilterDropdownMenu isActive={isActive} label={name} type="date">
            <CustomDateRangePicker values={[selected[0], selected[1]]} onChange={(values) => onDateRangeChanged(values)} onClear={onClear} />
        </FilterDropdownMenu>
    );
};

export default DateRangeFilter;
