import * as Yup from "yup";
//message path mapper is needed in order to be able to mimick the nested validations structure
Yup.addMethod(Yup.array, "unique", function (errorPayload, mapper) {
    return this.test("unique", errorPayload, function (list) {
        const mapOfValuesAppearances = list.reduce((acc, elem) => {
            const uniqueValue = mapper(elem);
            acc[uniqueValue] = acc[uniqueValue] ? acc[uniqueValue] + 1 : 1;
            return acc;
        }, {});
        const mapOfDuplicateIndexes = {};

        list.forEach((elem, index) => {
            if (mapOfValuesAppearances[mapper(elem)] > 1) {
                mapOfDuplicateIndexes[index] = errorPayload;
            }
        });

        if (Object.keys(mapOfDuplicateIndexes).length === 0) return true;

        return this.createError({ message: mapOfDuplicateIndexes });
    });
});
