import React from "react";
import { connect } from "react-redux";

import { Badge, IconButton, Tooltip } from "@mui/material";
import AlarmIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import { toggleNotifications } from "../../reducers/notifications";
import { useStyles } from "./styles.js";

const NotificationButton = (props) => {
    const classes = useStyles();

    return (
        <Tooltip title={props.notificationOpen ? "Close Notifications" : "Notifications"}>
            <IconButton className={classes.headerIconButton} onClick={() => props.toggleNotificationsOpen()}>
                <Badge invisible={!props.unseenNotifications} badgeContent={""} color="error">
                    {props.notificationOpen ? <CloseIcon /> : <AlarmIcon />}
                </Badge>
            </IconButton>
        </Tooltip>
    );
};

const mapStateToProps = (state) => ({
    unseenNotifications: state.notifications.unseenNotifications
});

const mapDispatchToProps = {
    toggleNotificationsOpen: toggleNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton);
