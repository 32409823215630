import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
    return {
        dialogTitle: { display: "flex", alignItems: "center", gap: "4px" },

        description: {
            marginTop: theme.spacing(1),
            color: theme.customColors.darkGrey
        }
    };
});
