import { v4 as uuidv4 } from "uuid";
import { makeSourceFromRaster } from "../creators/SourceCreators";
import { makeMapLayerFromRaster } from "../creators/MapLayerCreators";
import { makeDefaultPaint } from "../creators/PaintCreators";
import { makeDefaultLayout } from "../creators/LayoutCreators";
import { makeZoomRange } from "../creators/ZoomRangeCreators";
import { makeDefaultStyle } from "../creators/StyleCreators";
import { makeAppLayerFromRaster } from "../creators/AppLayerCreators";
import { AbstractFactory } from "./AbstractAuxObjsFactory";
import { STYLE_TYPES } from "../constants/layers/styleTypes";

export class RasterAuxObjectsFactory extends AbstractFactory {
    constructor(raster) {
        super();
        this.resource = raster;
        this.styleId = uuidv4();
    }

    makeStyle(styleConfig) {
        const minZoom = this.resource.minZoom;
        return makeDefaultStyle(this.styleId, minZoom, STYLE_TYPES.raster, styleConfig);
    }

    makeSource() {
        return makeSourceFromRaster(this.resource);
    }

    makeMapLayer() {
        return makeMapLayerFromRaster(this.resource, this.styleId);
    }

    makePaint(styleConfig) {
        return makeDefaultPaint(this.styleId, STYLE_TYPES.raster, styleConfig);
    }

    makeLayout(styleConfig) {
        return makeDefaultLayout(this.styleId, STYLE_TYPES.raster, styleConfig);
    }

    makeZoomRange() {
        const MAP_MIN_ZOOM = 0;
        const MAP_MAX_ZOOM = 24;
        return makeZoomRange(this.styleId, MAP_MIN_ZOOM, MAP_MAX_ZOOM);
    }

    makeAppLayer() {
        return makeAppLayerFromRaster(this.resource);
    }
}
