import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    username: {
        display: "flex",
        gap: 12,
        alignItems: "center"
    },
    drawer: {
        width: "40%",
        "@media screen and (max-width: 1400px)": {
            width: "50%"
        },
        "@media screen and (max-width: 1100px)": {
            width: "70%"
        },
        "@media screen and (max-width: 800px)": {
            width: "85%"
        }
    },
    content: {
        padding: 24,
        display: "flex",
        flexDirection: "column",
        gap: 20,
        height: "100%"
    },
    header: {
        display: "flex",
        padding: "12px 24px",
        boxShadow: "0 4px 12px 1px rgba(60,62,66,0.1)",
        alignItems: "center"
    },
    editRole: {
        paddingTop: 16
    },
    groups: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: 16
    }
}));
