import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Context from "./contextMenu";
import { layerJss } from "./jss/layerJss";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedLayer, getLayerVisibility, getLayerErrorStatus, getAppPermissionType } from "../../../../selectors/appData";
import * as appDataActions from "../../../../reducers/appData/appData";
import WarningIcon from "@mui/icons-material/WarningAmber";
import { Tooltip } from "@mui/material";
import { toggleAppLayer } from "../../../../actions/globalActions";
import AddDataDND from "./AddDataDND/AddDataDND";
import OverflowTip from "../../../../components/OverflowTip/OverflowTip";
import { PermissionType } from "features/groups/model/PermissionType";

function Layer({ classes, layer, depth, vListLayerTools: { vListIndex, markIndexForRecomputation } }) {
    const [contextOpen, setContextOpen] = useState(false);
    const selectedLayer = useSelector(getSelectedLayer);
    const dispatch = useDispatch();
    const anchorRef = React.useRef(null);

    const visible = useSelector(getLayerVisibility(layer.resourceId));
    const errorStatus = useSelector(getLayerErrorStatus(layer.resourceId));
    const permissionType = useSelector(getAppPermissionType);

    const toggleCMenu = (e) => {
        if (e) e.stopPropagation();
        setContextOpen((contextOpen) => !contextOpen);
        return null;
    };

    const toggleVisibility = (visible) => {
        dispatch(toggleAppLayer(layer.resourceId, visible));
    };

    const onLayerSelected = () => {
        if (permissionType < PermissionType.WRITE_READ) return;

        dispatch(appDataActions.setSelectedLayer(layer));
        if (!visible) toggleVisibility(true);
    };

    const selected = selectedLayer && layer.resourceId === selectedLayer.resourceId;

    return (
        <AddDataDND
            data={layer}
            vListIndex={vListIndex}
            markIndexForRecomputation={markIndexForRecomputation}
            depth={depth}
            selected={selected}
            disabled={permissionType < PermissionType.WRITE_READ}
        >
            <Switch
                className={classes.switch}
                edge="end"
                color={selected ? "default" : "primary"}
                checked={!!visible}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi", "data-testid": layerVisibilitySwitchTestId }}
                onClick={(e) => toggleVisibility(!visible)}
            />
            {errorStatus && (
                <Tooltip title="This layer has a style with invalid values.">
                    <WarningIcon className={classes.iconSpaceRight} color="error" fontSize="small" />
                </Tooltip>
            )}
            <div className={classes.layerTextContainer} onClick={onLayerSelected} data-testid={selectLayerTestId}>
                <OverflowTip variant="body2" color="inherit" className={permissionType >= PermissionType.WRITE_READ && classes.layerText} staticMouseTooltip>
                    {layer.name}
                </OverflowTip>
            </div>

            <span className={classes.grow}></span>

            <IconButton
                className={classes.contextMenuBtn}
                ref={anchorRef}
                aria-controls={contextOpen ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                    e.currentTarget.blur();
                    toggleCMenu();
                }}
                size="large"
                data-testid={layerContextMenuButtonTestId}
            >
                <MoreHoriz />
            </IconButton>
            <Context
                anchorEl={anchorRef.current}
                layer={layer}
                open={contextOpen}
                toggleCMenu={toggleCMenu}
                onLayerSelected={onLayerSelected}
                markForRecomputation={() => markIndexForRecomputation(vListIndex)}
            />
        </AddDataDND>
    );
}

export default withStyles(layerJss)(Layer);

const layerVisibilitySwitchTestId = "qa-app-edit-view-layer-visibility-switch";
const selectLayerTestId = "qa-app-edit-view-select-layer";
const layerContextMenuButtonTestId = "qa-app-edit-view-layer-context-menu-button";
