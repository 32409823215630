import { darken } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
    return {
        deleteIcon: {
            marginRight: theme.spacing(1),
            color: darken(theme.customColors.errorColor, 0.1)
        }
    };
});
