import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import LoopIcon from "@mui/icons-material/Loop";
import CustomChip from "../CustomChip/CustomChip";
import { useStyles } from "./styles";

type Status = "Cached" | "Caching" | "Uncached";

type CacheStatusChipProps = {
    status: Status;
    expanded?: boolean;
    size?: "small" | "large";
};

const iconMap: Record<Status, OverridableComponent<SvgIconTypeMap> & { muiName: string }> = {
    Cached: PublishedWithChangesIcon,
    Caching: LoopIcon,
    Uncached: SyncProblemIcon
};

const CacheStatusChip: React.FC<CacheStatusChipProps> = ({ status, size = "large", expanded = false }) => {
    const classes = useStyles();

    return <CustomChip label={status} Icon={iconMap[status]} size={size} className={`${classes.cacheStatusChip} status-${status}`} expanded={expanded} />;
};

export default CacheStatusChip;
