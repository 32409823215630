import { useEffect, useState } from "react";
import { useDeleteGroupMutation, useFetchAllGroupsQuery } from "features/groups/api";
import groupSorting from "./components/groupsSorting";
import { handleError } from "../../../../utils/networkErrorUtils";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { Group as GroupType } from "../../model/Group";
import CreateModal from "./components/CreateGroupModal/CreateGroupModal";
import GroupEditModal from "./components/GroupEditModal/GroupEditModal";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { Button, LinearProgress, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import { StyledTab, StyledTabs } from "components/CustomTabs/CustomTabs";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getNavigationState } from "selectors/navigation";
import TableView from "components/TableView/TableView";
import GroupRow from "./components/GroupRow/GroupRow";

const GroupsListView = () => {
    const classes = useStyles();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
    const isNavigationOpen = useAppSelector(getNavigationState);

    const columns = groupSorting;

    const { data: groups = [], isFetching, error } = useFetchAllGroupsQuery();

    const [deleteGroup] = useDeleteGroupMutation();

    useEffect(() => {
        if (error) {
            handleError(error);
        }
    }, [error]);

    const onDeleteGroup = (group: GroupType) => {
        const toastrConfirmOptions = {
            onOk: () => {
                deleteGroup(group.id)
                    .then(() => toastr.success("Group deleted"))
                    .catch((error) => handleError(error));
            },
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to delete group: ${group.name}?`, toastrConfirmOptions);
    };

    const generateItem = (group: GroupType, index: number) => {
        return <GroupRow group={group} key={index} onClick={(item: GroupType) => setSelectedGroupId(item.id)} onDeleteClick={onDeleteGroup} />;
    };

    const filterItem = (item: GroupType, query: string) => {
        return item.name.toLowerCase().includes(query.toLowerCase());
    };

    return (
        <div className={clsx("sidebar-container", classes.groupOverview, { [classes.menuIsOpen]: isNavigationOpen })}>
            <CreateModal open={createModalOpen} handleClose={() => setCreateModalOpen(false)} />
            {!!selectedGroupId && <GroupEditModal open={!!selectedGroupId} handleClose={() => setSelectedGroupId(null)} groupId={selectedGroupId} />}

            <div className="header">
                <CustomTypography variant="h2" data-testid={headerTestId}>
                    Groups
                </CustomTypography>
                <Button color="primary" variant="contained" onClick={() => setCreateModalOpen(true)} data-testid={addButtonTestId}>
                    <AddIcon fontSize="small" className="button-icon" />
                    Add Group
                </Button>
            </div>

            {isFetching && <LinearProgress className="no-margin-progress" />}

            <StyledTabs value={0} onChange={() => {}} indicatorColor="primary">
                <StyledTab label="Groups" data-testid={groupTestId} />

                <StyledTab
                    sx={{ cursor: "default" }}
                    disableRipple
                    data-testid={individualGroupTestId}
                    label={
                        <Tooltip title="The management of individual permissions has been relocated to the user drawer on the Users page">
                            <div>Individual Groups</div>
                        </Tooltip>
                    }
                />
            </StyledTabs>

            <TableView
                searchPlaceholder={"Search groups"}
                data={groups && groups.filter((x: GroupType) => !x.isIndividualGroup)}
                columns={columns}
                generateItem={generateItem}
                filterFunction={filterItem}
                fetchingData={isFetching}
                hideBottomBar={undefined}
                additionalActionColumn={true}
            />
        </div>
    );
};

export default GroupsListView;

const headerTestId = "qa-header";
const groupTestId = "qa-groups-tab";
const individualGroupTestId = "qa-individual-groups-tab";
const addButtonTestId = "qa-group-add-button";
