import React, { useEffect, useState } from "react";

import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateDatasetSchema } from "../../actions/datasets";
import { handleError } from "../../utils/networkErrorUtils";
import * as datasetsActions from "../../actions/datasets";
import toastr from "../../components/CustomToastr/CustomToastr";
import CustomModal from "../CustomModal/CustomModal";

export default function ChangeSchemaModal(props) {
    const dispatch = useDispatch();
    const [schemaName, setSchemaName] = useState(props.schemaName);
    const [schemaNames, setSchemaNames] = useState([]);

    useEffect(() => {
        dispatch(datasetsActions.getSchemaNames()).then(
            (res) => setSchemaNames(res.result),
            (err) => handleError(err)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSchemaName(props.schemaName);
    }, [props.schemaName]);

    const onUpdate = () => {
        dispatch(updateDatasetSchema(props.datasetId, schemaName)).then(
            () => {
                toastr.success("Schema Changed");
                props.handleClose();
            },
            (err) => {
                handleError(err);
            }
        );
    };

    const renderSchemaNames = (schemaNames) => {
        return schemaNames.map((schema, index) => (
            <MenuItem key={index} value={schema} data-testid={menuItemTestId}>
                {schema}
            </MenuItem>
        ));
    };

    const onSchemaNameChanged = (e) => {
        setSchemaName(e.target.value);
    };

    return (
        <CustomModal
            handleClose={props.handleClose}
            isOpen={props.open}
            onConfirm={onUpdate}
            dialogTitle={"Change Schema"}
            dialogType={"update"}
            disabled={schemaName === props.schemaName}
        >
            <FormControl variant="filled" className="section" fullWidth>
                <InputLabel id="schema-label">Schema</InputLabel>
                <Select labelId="schema-label" label="Schema" onChange={onSchemaNameChanged} value={schemaName} inputProps={{ "data-testid": selectInputTestId }}>
                    {renderSchemaNames(schemaNames)}
                </Select>
            </FormControl>
        </CustomModal>
    );
}

const selectInputTestId = "qa-change-schema-select-input";
const menuItemTestId = "qa-change-schema-menu-item";
