export const IsResourceModified = (resourceId: string, modifiedDate: Date) => {
    const localStorageValue = localStorage.getItem(resourceId);

    if (localStorageValue === null) {
        return true;
    }

    const savedDate = new Date(localStorageValue);
    return modifiedDate > savedDate;
};
