import AdapterDayJs from "@mui/lab/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { ChangeEvent } from "react";

type Props = {
    label: string;
    helperText?: string;
    required: boolean;
    dataTestId: string;
    value: Date | null;
    onChange: (e: ChangeEvent<HTMLInputElement> | null) => void;
    error: boolean;
};

const CustomMobileDatePicker = ({ label, required, value, helperText, onChange, error, dataTestId }: Props) => (
    <LocalizationProvider dateAdapter={AdapterDayJs}>
        <MobileDatePicker
            clearable
            label={label}
            renderInput={(props) => (
                <TextField
                    variant="filled"
                    fullWidth
                    helperText={helperText}
                    required={required}
                    {...{
                        ...props,
                        inputProps: {
                            "data-testid": dataTestId,
                            ...props.inputProps
                        }
                    }}
                    error={error}
                />
            )}
            value={value}
            onChange={onChange}
        />
    </LocalizationProvider>
);

export default CustomMobileDatePicker;
