import { ChangeEvent, createRef, useRef } from "react";
import { Box, Button, DialogActions, DialogContent, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VectorUpload from "./VectorUpload/VectorUpload";
import { UploadHandle } from "features/upload/models/UploadHandle";
import RasterUpload from "./RasterUpload/RasterUpload";
import CsvModal from "./CsvModal/CsvModal";
import { useFetchSchemasQuery } from "features/datasets/api";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getCsvUploads, getFailedUploads, getPendingUploads, getUploadedDatasetIds, getUploadingUploads, getUploads } from "../../selectors";

type Props = {
    fileHandler: (files: FileList) => void;
    acceptedFormats: string[];
    onClose: () => void;
    onNextClick: () => void;
};

const UploadView = ({ fileHandler, acceptedFormats, onClose, onNextClick }: Props) => {
    const uploads = useAppSelector(getUploads);
    const pendingUploads = useAppSelector(getPendingUploads);
    const uploadingUploads = useAppSelector(getUploadingUploads);
    const failedUploads = useAppSelector(getFailedUploads);
    const uploadedDatasetIds = useAppSelector(getUploadedDatasetIds);
    const csvUploads = useAppSelector(getCsvUploads);

    const rowsRef = useRef<React.RefObject<UploadHandle>[]>([]);
    const { data: schemas = [], isLoading } = useFetchSchemasQuery();

    const isUploadAllEnabled = pendingUploads.length > 0;
    const isFillMetaEnabled = uploadingUploads.length === 0 && uploadedDatasetIds.length > 0 && failedUploads.length === 0;
    const isDoneEnabled = uploadingUploads.length === 0;

    rowsRef.current = uploads.map((_, i) => rowsRef.current[i] ?? createRef<UploadHandle>());

    const onUploadAll = () => rowsRef.current.forEach((row) => row.current?.startUpload());

    const onAddFiles = (e: ChangeEvent<HTMLInputElement>) => e.target.files && fileHandler(e.target.files);

    const onCancel = () => {
        rowsRef.current.forEach((row) => row.current?.cancelUpload());
        onClose();
    };

    return (
        <>
            <DialogContent dividers sx={{ minHeight: 300, maxHeight: `calc(100vh - 400px)` }}>
                <LoadingPlaceholder loading={isLoading} message="Fetching schemas">
                    <input id="uploadButton" type="file" accept={acceptedFormats.reduce((acc, current) => `${acc},${current}`, "")} multiple onChange={onAddFiles} hidden />
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography>{uploads.length} file(s)</Typography>
                        <Button color="secondary" variant="contained" size="small" startIcon={<AddIcon />} onClick={() => document?.getElementById("uploadButton")?.click()}>
                            Add files
                        </Button>
                    </Box>
                    {uploads.map((upload, i) => (
                        <div key={upload.name}>
                            {upload.type === "vector" && (
                                <VectorUpload name={upload.name} file={upload.file} extension={upload.extension} schemas={schemas} ref={rowsRef.current[i]} />
                            )}
                            {upload.type === "raster" && <RasterUpload name={upload.name} file={upload.file} extension={upload.extension} ref={rowsRef.current[i]} />}
                        </div>
                    ))}
                </LoadingPlaceholder>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={onUploadAll} disabled={!isUploadAllEnabled}>
                    Upload all
                </Button>

                {isFillMetaEnabled && (
                    <Button variant="contained" onClick={onNextClick}>
                        Fill metadata
                    </Button>
                )}

                {isDoneEnabled ? <Button onClick={onClose}>Done</Button> : <Button onClick={onCancel}>Cancel</Button>}
            </DialogActions>
            {csvUploads.length > 0 && <CsvModal file={csvUploads[0].file} />}
        </>
    );
};

export default UploadView;
