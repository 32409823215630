import { useState } from "react";
import { useAppSelector } from "../../../../store/hooks/useAppSelector";
import { getCurrentUserRole, getIsCurrentUserClientOrSystemAdmin } from "../../../auth/selectors";
import { GisUser } from "../../model/GisUser";
import UserGroupsDrawer from "../UserGroupsDrawer/UserGroupsDrawer";
import PendingUsersModal from "../PendingUsersModal/PendingUsersModal";
import Typography from "@mui/material/Typography";
import { Badge } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LinearProgress from "@mui/material/LinearProgress";
import ListView from "../../../../components/listView";
import { exportApplicationPermissions, useFetchExistingUsersQuery, useFetchPendingUsersQuery } from "../../api";
import UserItem from "../UserItem/UserItem";
import { usersSorting } from "../../utils";
import Button from "@mui/material/Button";
import fileDownload from "js-file-download";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { GisRole } from "../../model/GisRole";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useParams } from "react-router-dom";
import { getClientById } from "features/core/selectors";

type Params = {
    clientId: string;
    projectId: string;
};

const UsersListView = () => {
    const [isPendingModalOpen, setIsPendingModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<GisUser | null>(null);
    const isCurrentUserClientOrSystemAdmin = useAppSelector(getIsCurrentUserClientOrSystemAdmin);
    const isCurrentUserAdmin = useAppSelector(getCurrentUserRole) === GisRole.Admin;
    const { data: existingUsers = [], isFetching } = useFetchExistingUsersQuery();

    const { data: pendingUsers = [] } = useFetchPendingUsersQuery();

    const { clientId, projectId } = useParams<Params>();
    const currentClient = useAppSelector(getClientById(clientId));
    const currentProject = currentClient?.projects?.find((x) => x.id === projectId);

    const onExportApplicationPermissions = () => {
        var fileName = `${currentClient?.name} ${currentProject?.name} Application Permissions.xlsx`;

        toastr.info("Your download should begin in a few seconds");
        exportApplicationPermissions()
            .then((res) => {
                fileDownload(res, fileName);
                toastr.success("Permissions exported successfully");
            })
            .catch(() => {
                toastr.error("Error downloading report");
            });
    };

    const onDrawerClose = () => setSelectedUser(null);

    const onPendingUsersModalClose = () => setIsPendingModalOpen(false);

    const generateItem = (user: GisUser) => <UserItem key={user.username} role={user.role} username={user.username} onClick={() => setSelectedUser(user)} />;

    const filterItem = (item: GisUser, query: string) => item.username.toLowerCase().includes(query.toLowerCase());

    return (
        <>
            <div className="sidebar-container users-overview">
                {selectedUser && <UserGroupsDrawer username={selectedUser.username} coreAccountType={selectedUser.coreAccountType} onClose={onDrawerClose}></UserGroupsDrawer>}

                <div className="header">
                    <Typography variant="h6" data-testid={headerTestId}>
                        Users
                    </Typography>
                    {isCurrentUserAdmin && (
                        <Button variant="contained" color="secondary" startIcon={<FileDownloadIcon />} sx={{ marginRight: "8px" }} onClick={onExportApplicationPermissions}>
                            Export app permissions
                        </Button>
                    )}
                    {isCurrentUserClientOrSystemAdmin && (
                        <Button variant="contained" color="primary" startIcon={<PersonIcon />} onClick={() => setIsPendingModalOpen(true)} data-testid={pendingUsersButtonTestId}>
                            Pending Users
                            <Badge
                                badgeContent={pendingUsers.length}
                                color="secondary"
                                sx={{
                                    "& .MuiBadge-badge": {
                                        position: "relative",
                                        transform: "none",
                                        marginLeft: 0.5
                                    }
                                }}
                            />
                        </Button>
                    )}
                </div>
                {isFetching && <LinearProgress className="no-margin-progress" />}
                <ListView
                    searchPlaceholder={"Search users"}
                    data={existingUsers}
                    columns={usersSorting}
                    generateItem={generateItem}
                    filterFunction={filterItem}
                    showContextMenuFiller={true}
                ></ListView>
            </div>
            {isPendingModalOpen && <PendingUsersModal open={isPendingModalOpen} onClose={onPendingUsersModalClose} />}
        </>
    );
};

export default UsersListView;

const pendingUsersButtonTestId = "qa-users-list-view-pending-users-button";
const headerTestId = "qa-header";
