export const makeDefaultPaint = (layerId, layerType, styleConfig) => {
    const properties = styleConfig[layerType];
    const paintProperties = properties.filter((x) => x.type === "paint");
    const paint = {
        layerId,
        properties: paintProperties
    };
    return paint;
};

export const makePaintFromStyle = (style, styleConfig) => {
    const properties = !!styleConfig ? JSON.parse(JSON.stringify(styleConfig[style.type])) : [];

    //Add any new paint/layout properties to style
    properties.forEach((property) => {
        const styleProperty = style.properties.find((x) => x.name === property.name);

        if (styleProperty) {
            property.value = styleProperty.value;
            property.expressionType = styleProperty.expressionType || "none";

            if (styleProperty.name === "raster-opacity") {
                styleProperty.propertyType = property.propertyType;
            }
        }
    });

    const paintProperties = properties.filter((x) => x.type === "paint");
    const paint = {
        layerId: style.styleId,
        properties: paintProperties
    };
    return paint;
};
