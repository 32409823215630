import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import config from "config";

type EventType = {
    methodName: string;
    handler: (args: any[]) => void;
};

type ReturnType = {
    addEvent: (methodName: string, handler: (...args: any[]) => void) => void;
    removeEvent: (methodName: string) => void;
};

const logLevel = process.env.NODE_ENV === "production" ? LogLevel.None : LogLevel.Information;

const initHubConnection = (path: string, tokenFactory?: () => string | Promise<string>) => {
    let connectionBuilder = new HubConnectionBuilder().configureLogging(logLevel).withAutomaticReconnect();

    if (tokenFactory) {
        connectionBuilder = connectionBuilder.withUrl(config.apiUrl + path, {
            accessTokenFactory: tokenFactory
        });
    } else {
        connectionBuilder = connectionBuilder.withUrl(config.apiUrl + path);
    }

    return connectionBuilder.build();
};

const useSignalR = (path: string, tokenFactory?: () => string | Promise<string>): ReturnType => {
    const [connection, setConnection] = useState<HubConnection>(() => initHubConnection(path, tokenFactory));
    const [events, setEvents] = useState<EventType[]>([]);

    useEffect(() => {
        if (!(connection.baseUrl === config.apiUrl + path)) {
            setConnection(initHubConnection(path, tokenFactory));
        }

        connection.start();

        return () => {
            connection.stop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, tokenFactory]);

    useEffect(() => {
        events.forEach((e) => connection.on(e.methodName, e.handler));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection]);

    const addEvent = (methodName: string, handler: (...args: any[]) => void) => {
        if (events.some((e) => e.methodName === methodName)) return;
        setEvents([...events, { methodName, handler }]);
        connection.on(methodName, handler);
    };

    const removeEvent = (methodName: string) => {
        setEvents(events.filter((e) => e.methodName !== methodName));
        connection.off(methodName);
    };

    return {
        addEvent,
        removeEvent
    };
};

export default useSignalR;
