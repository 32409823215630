import { createSelector } from "reselect";
import { uploadsAdapter } from "./slice";
import { UploadStatus } from "./models/Upload";
import { RootState } from "../../store/models/RootState";

export const getUploads = uploadsAdapter.getSelectors((state: RootState) => state.upload).selectAll;

const uploadingSelector = createSelector([getUploads], (uploads) => uploads.filter((u) => u.status === UploadStatus.Uploading));
const failedSelector = createSelector([getUploads], (uploads) => uploads.filter((u) => u.status === UploadStatus.Failed));
const pendingSelector = createSelector([getUploads], (uploads) => uploads.filter((u) => u.status === UploadStatus.Pending));
const uploadedDatasetIdsSelector = createSelector([getUploads], (uploads) => uploads.filter((u) => u.status === UploadStatus.Uploaded && u.type === "vector").map((u) => u.id));
const uploadedRasterIdsSelector = createSelector([getUploads], (uploads) => uploads.filter((u) => u.status === UploadStatus.Uploaded && u.type === "raster").map((u) => u.id));
const csvUploadsSelector = createSelector([getUploads], (uploads) => uploads.filter((u) => u.extension === ".csv"));
const hasVectorUploadsSelector = createSelector([getUploads], (uploads) => uploads.some((u) => u.type === "vector"));

export const getUploadingUploads = (state: RootState) => uploadingSelector(state);

export const getFailedUploads = (state: RootState) => failedSelector(state);

export const getUploadedDatasetIds = (state: RootState) => uploadedDatasetIdsSelector(state);

export const getUploadedRasterIds = (state: RootState) => uploadedRasterIdsSelector(state);

export const getCsvUploads = (state: RootState) => csvUploadsSelector(state);

export const getPendingUploads = (state: RootState) => pendingSelector(state);

export const getHasVectorUploads = (state: RootState) => hasVectorUploadsSelector(state);
