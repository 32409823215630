import { sortByBool, sortByBoolReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";
import { Basemap } from "../../../../models/basemap/Basemap";

const sortByTitleReverse = (i1: Basemap, i2: Basemap) => sortByStringReverse(i1.title, i2.title);
const sortByTitle = (i1: Basemap, i2: Basemap) => sortByString(i1.title, i2.title);

const sortByUrlReverse = (i1: Basemap, i2: Basemap) => sortByStringReverse(i1.url, i2.url);
const sortByUrl = (i1: Basemap, i2: Basemap) => sortByString(i1.url, i2.url);

const sortByTypeReverse = (i1: Basemap, i2: Basemap) => sortByStringReverse(i1.type, i2.type);
const sortByType = (i1: Basemap, i2: Basemap) => sortByString(i1.type, i2.type);

const sortByDefaulReverse = (i1: Basemap, i2: Basemap) => sortByBoolReverse(i1.isDefault, i2.isDefault);
const sortByDefault = (i1: Basemap, i2: Basemap) => sortByBool(i1.isDefault, i2.isDefault);

export const basemapsColumns = [
    {
        name: "Preview",
        type: "preview"
    },
    {
        name: "Title",
        type: "text",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByTitleReverse : sortByTitle),
        start: true
    },
    {
        name: "Url",
        type: "text",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByUrlReverse : sortByUrl)
    },
    {
        name: "Type",
        type: "text",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByTypeReverse : sortByType)
    },
    {
        name: "Default",
        type: "bool",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByDefaulReverse : sortByDefault)
    }
];
