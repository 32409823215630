import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    container: {
        width: "100%",
        display: "flex",
        gap: 16
    },
    searchBar: {
        flexGrow: 1
    }
});
