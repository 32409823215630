import * as types from "../actions/actionTypes";
import { CACHE_STATUSES } from "../utils/constants/cacheStates";
import { bulkUpdateDatasetMetadataThunk } from "../actions/datasets";
import { resetProjectData } from "actions/globalActions";
import { appendDatasetGeojsonThunk, appendDatasetZipThunk, deleteDatasetHistoryEntryThunk } from "features/fidu/actions";

const datasetsInit = {
    fetching: true,
    loading: false,
    datasets: [],
    selected: {}
};

export function datasetsReducer(state = datasetsInit, action) {
    switch (action.type) {
        case types.FETCH_DATASETS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASETS_COMPLETE:
            return {
                ...state,
                fetching: false,
                datasets: [...action.result]
            };
        case types.CREATE_DATASET_COMPLETE:
            return {
                ...state,
                datasets: [...state.datasets, action.result]
            };
        case types.DELETE_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false,
                datasets: state.datasets.filter((x) => x.id !== action.result)
            };
        case types.DATASET_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        case types.SIGNALR_PROGRESS_UPDATE:
            return {
                ...state,
                datasets: updateArrayCacheStatus(state.datasets, action.payload)
            };
        case types.SIGNALR_ERROR_UPDATE:
            return {
                ...state,
                datasets: updateArrayWithCacheStatus(state.datasets, action.payload.resourceId, 0)
            };
        case types.CANCEL_GENERATE_DATASET_CACHE_COMPLETE:
            return {
                ...state,
                datasets: updateArrayWithCacheStatus(state.datasets, action.payload.datasetId, 0)
            };
        case bulkUpdateDatasetMetadataThunk.pending:
            return { ...state, loading: true };
        case bulkUpdateDatasetMetadataThunk.fulfilled:
        case bulkUpdateDatasetMetadataThunk.rejected:
            return { ...state, loading: false };
        case resetProjectData.type:
            return datasetsInit;
        default:
            return state;
    }
}

const datasetDetailsInit = {
    fetching: true,
    dataset: {
        cacheStatus: CACHE_STATUSES.uncached,
        id: "",
        name: "",
        schemaName: "",
        tableName: "",
        minZoom: 0,
        maxZoom: 24,
        rowCount: 0,
        databaseSize: "",
        createdUtc: "",
        modifiedUtc: "",
        geometryType: "",
        metadata: [],
        validMetadata: false,
        bounds: "",
        tileName: "",
        projection: 0
    }
};

export function datasetDetailsReducer(state = datasetDetailsInit, action) {
    switch (action.type) {
        case types.FETCH_DATASET_DETAILS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASET_DETAILS_COMPLETE:
            return {
                ...state,
                fetching: false,
                dataset: action.result
            };
        case types.UPDATE_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false,
                dataset: {
                    ...state.dataset,
                    ...action.payload
                }
            };
        case types.UPDATE_DATASET_ADVANCED:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_DATASET_ADVANCED_COMPLETE:
            return {
                ...state,
                fetching: false,
                dataset: {
                    ...state.dataset,
                    cacheStatus: CACHE_STATUSES.uncached,
                    ...action.payload
                }
            };
        case types.REPLACE_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.REPLACE_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false,
                dataset: {
                    ...state.dataset,
                    cacheStatus: CACHE_STATUSES.caching,
                    ...action.payload
                }
            };
        case types.GENERATE_DATASET_CACHE:
            return {
                ...state,
                fetching: true
            };
        case types.GENERATE_DATASET_CACHE_COMPLETE:
            return {
                ...state,
                dataset: { ...state.dataset, cacheStatus: CACHE_STATUSES.caching },
                fetching: false
            };
        case types.DOWNLOAD_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.DOWNLOAD_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false
            };
        case types.LINK_DATASET_GOOGLE_DRIVE:
            return {
                ...state,
                fetching: true
            };
        case types.LINK_DATASET_GOOGLE_DRIVE_COMPLETE:
            return {
                ...state,
                fetching: false
            };

        case types.DATASET_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        case types.SIGNALR_PROGRESS_UPDATE:
            return {
                ...state,
                dataset: { ...state.dataset, cacheStatus: updateCacheStatus(state.dataset.cacheStatus, state.dataset.id, action.payload) }
            };
        case types.SIGNALR_ERROR_UPDATE:
            return {
                ...state,
                dataset: { ...state.dataset, cacheStatus: action.payload.resourceId === state.dataset.id ? CACHE_STATUSES.uncached : state.dataset.cacheStatus }
            };
        case types.CANCEL_GENERATE_DATASET_CACHE_COMPLETE:
            return {
                ...state,
                dataset: { ...state.dataset, cacheStatus: state.dataset.id === action.payload.datasetId ? 0 : state.dataset.cacheStatus },
                fetching: state.dataset.id === action.payload.datasetId ? false : state.fetching
            };
        case types.UPDATE_DATASET_SCHEMA:
            return {
                ...state,
                fetching: true
            };
        case types.UPDATE_DATASET_SCHEMA_COMPLETE:
            return {
                ...state,
                fetching: false,
                dataset: {
                    ...state.dataset,
                    schemaName: action.payload
                }
            };
        case deleteDatasetHistoryEntryThunk.fulfilled.type:
            return {
                ...state,
                dataset: action.payload.updatedDataset
            };
        case resetProjectData.type:
            return datasetDetailsInit;
        default:
            return state;
    }
}

function updateCacheStatus(cacheStatus, datasetId, notification) {
    if (datasetId === notification.resourceId) {
        return notification.cacheStatus;
    }
    return cacheStatus;
}

function updateArrayCacheStatus(array, notification) {
    let newArray = array.map((item) => {
        if (item.id === notification.resourceId) {
            return {
                ...item,
                cacheStatus: notification.cacheStatus
            };
        }
        return item;
    });

    return newArray;
}

function updateArrayWithCacheStatus(array, datasetId, cacheStatus) {
    let newArray = array.map((item) => {
        if (item.id === datasetId) {
            return {
                ...item,
                cacheStatus: cacheStatus
            };
        }
        return item;
    });

    return newArray;
}
