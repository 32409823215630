import GroupsListView from "features/groups/components/GroupsListView/GroupsListView";
import Header from "components/header/Header";
import HeaderButtons from "../../../../components/HeaderButtons/HeaderButtons";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getUnseenNotifications } from "selectors/notifications";

const GroupsRootView = () => {
    const unseenNotifications = useAppSelector(getUnseenNotifications);

    return (
        <div className="groups-rootview">
            <div className="sidebar">
                <div className="header">
                    <Header />
                    <HeaderButtons unseenNotifications={unseenNotifications} />
                </div>
                <GroupsListView />
            </div>
        </div>
    );
};

export default GroupsRootView;
