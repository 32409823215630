import { FC, useState } from "react";
import toastr from "../../../../components/CustomToastr/CustomToastr";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { metadataTypes, METADATA_SCHEMA_ID } from "../../../../utils/constants/metadata";
import { MetadataProperty } from "models/metadataSchema/MetdataProperty";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { deleteMetadataPropertyThunk } from "actions/metadataSchema";
import { unwrapResult } from "@reduxjs/toolkit";
import { TableCell, TableRow } from "@mui/material";
import { CELL_SIZES } from "utils/constants/cellSizes";
import OverflowCell from "components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import MenuButtonCell from "components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import BooleanIndicator from "components/BooleanIndicator/BooleanIndicator";
import { useMetadataRowStyles } from "./styles";

type Props = {
    property: MetadataProperty;
    onEditOpen: (property: MetadataProperty) => void;
};

const fieldTypePretty = {
    [metadataTypes.TAG_LIST]: "Tag List"
};

const MetadataRow: FC<Props> = ({ property, onEditOpen }) => {
    const classes = useMetadataRowStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useAppDispatch();

    const onMenuClick = (e: any) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const onMenuClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onDeleteClick = (e: any) => {
        onMenuClose(e);

        const toastrConfirmOptions = {
            onOk: () => {
                dispatch(deleteMetadataPropertyThunk({ metadataSchemaId: METADATA_SCHEMA_ID, metadataPropertyId: property.id }))
                    .then(unwrapResult)
                    .catch(() => toastr.error("Failed to delete property"));
            },
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to delete property: ${property.name}?`, toastrConfirmOptions);
    };

    const onEditClick = (e: any) => {
        onMenuClose(e);
        onEditOpen(property);
    };

    return (
        <TableRow onClick={onEditClick} data-testid="qa-metadata-table-row">
            <OverflowCell cellSize={CELL_SIZES.medium} color={undefined} size={undefined} className="">
                {fieldTypePretty[property.type] || property.type}
            </OverflowCell>

            <OverflowCell cellSize={CELL_SIZES.extraLarge} color={undefined} size={undefined} className="">
                {property.name}
            </OverflowCell>

            <TableCell>
                <BooleanIndicator boolValue={property.mandatory} className={classes.mandatoryIcon} />
            </TableCell>

            <TableCell>
                <BooleanIndicator boolValue={property.hidden} className={classes.hiddenIcon} />
            </TableCell>

            <MenuButtonCell onMenuClick={onMenuClick} />

            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onMenuClose}>
                <MenuItem data-testid={editMetadataItemTestId} onClick={onEditClick} className="menu-button">
                    <EditIcon className="icon" />
                    Edit
                </MenuItem>
                <MenuItem data-testid={deleteMetadataItemTestId} onClick={onDeleteClick} className="menu-button">
                    <DeleteIcon className="icon" />
                    Delete
                </MenuItem>
            </Menu>
        </TableRow>
    );
};

export default MetadataRow;

const editMetadataItemTestId = "qa-edit-metadata-menu-item";
const deleteMetadataItemTestId = "qa-delete-metadata-menu-item";
