import { List, ListItem } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import CustomTypography from "../../../../../components/CustomTypography/CustomTypography";
import { permissions } from "../../../../../utils/constants/permissionTypes";
import EditMetadataModal from "../EditMetadataModal/EditMetadataModal";
import useStyles from "./styles";

const MetadataModals = ({ selectedDatasets, onClose, open }) => {
    const classes = useStyles();
    const [datasets, setDatasets] = useState(selectedDatasets);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const hasMinimumEditPermission = useMemo(() => datasets.every((dataset) => dataset.permissionType >= permissions.WRITE.value), [datasets]);

    const datasetsWithoutRequiredPermissions = useMemo(() => datasets.filter((dataset) => dataset.permissionType < permissions.WRITE.value), [datasets]);

    useEffect(() => {
        if (!hasMinimumEditPermission) {
            setConfirmModalOpen(true);
        } else {
            setEditModalOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasets]);

    useEffect(() => {
        setDatasets(selectedDatasets);
    }, [selectedDatasets]);

    const hasNoPermissions = datasetsWithoutRequiredPermissions.length === datasets.length;

    const dialogText = hasNoPermissions
        ? "You do not have WRITE permissions for any of the datasets that you have picked"
        : "You do not have WRITE permissions for the following datasets:";

    const dialogType = hasNoPermissions ? "info" : "confirm";

    const onConfirm = () => {
        if (hasNoPermissions) {
            onClose();
        } else {
            setDatasets(datasets.filter((dataset) => !datasetsWithoutRequiredPermissions.includes(dataset)));
            setEditModalOpen(true);
            setConfirmModalOpen(false);
        }
    };

    if (!open) return null;

    return (
        <>
            <EditMetadataModal open={editModalOpen} onClose={onClose} datasetIds={datasets.map((dataset) => dataset.id)} />
            <CustomModal isOpen={confirmModalOpen} dialogTitle="Not enough permissions" dialogType={dialogType} onConfirm={onConfirm} handleClose={onClose}>
                <div className={classes.modalContainer}>
                    <CustomTypography textWeight="bold">{dialogText}</CustomTypography>
                    {!hasNoPermissions && (
                        <>
                            <div className={classes.listContainer}>
                                <List>
                                    {datasetsWithoutRequiredPermissions.map((dataset) => (
                                        <ListItem key={dataset.id}>
                                            <CustomTypography textWeight="semibold">{dataset.name}</CustomTypography>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            <CustomTypography textWeight="bold">Continue editing metadata for the rest of the datasets?</CustomTypography>
                        </>
                    )}
                </div>
            </CustomModal>
        </>
    );
};

export default MetadataModals;
