import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { resetProjectData } from "actions/globalActions";
import { Basemap } from "models/basemap/Basemap";
import { addLibraryBasemap, deleteLibraryBasemap, fetchLibraryBasemaps, patchLibraryBasemap } from "../actions/basemaps";

type SliceState = {
    loading: boolean;
    libraryBasemaps: Basemap[];
};

const initialState: SliceState = {
    loading: false,
    libraryBasemaps: []
};

const basemapsSlice = createSlice({
    name: "basemaps",
    initialState,
    reducers: {
        handleAddLibraryBasemap: (state, { payload: basemap }) => {
            state.libraryBasemaps.push(basemap);
        },
        handleDeleteLibraryBasemap: (state, { payload: basemapId }) => {
            state.libraryBasemaps = state.libraryBasemaps.filter((basemap) => basemap.id !== basemapId);
        },
        handleUpdateLibraryBasemap: (state, args: { payload: Basemap }) => {
            const basemapIndex = state.libraryBasemaps.findIndex((basemap) => basemap.id === args.payload.id);
            state.libraryBasemaps[basemapIndex] = args.payload;
        }
    },
    extraReducers: (builder) => {
        const thunks: [AnyAsyncThunk, ...AnyAsyncThunk[]] = [fetchLibraryBasemaps, addLibraryBasemap, patchLibraryBasemap, deleteLibraryBasemap];

        builder
            .addCase(fetchLibraryBasemaps.fulfilled, (state, { payload }) => {
                state.libraryBasemaps = payload;
            })
            .addCase(resetProjectData, () => initialState)
            .addMatcher(isPending(...thunks), (state) => {
                state.loading = true;
            })
            .addMatcher(isAnyOf(isFulfilled(...thunks), isRejected(...thunks)), (state) => {
                state.loading = false;
            });
    }
});

export const { handleAddLibraryBasemap, handleDeleteLibraryBasemap, handleUpdateLibraryBasemap } = basemapsSlice.actions;

export default basemapsSlice.reducer;
