import HistorySection from "./HistorySection/HistorySection";
import ColumnsSection from "./ColumnsSection/ColumnsSection";
import { useDatasetEditViewStyles } from "../../styles";
import { Dataset } from "models/Dataset";
import { FC } from "react";
import UploaderFooter from "../DatasetEditFooter/UploaderFooter";

type Props = {
    dataset: Dataset;
};

const UploaderTab: FC<Props> = ({ dataset }) => {
    const classes = useDatasetEditViewStyles();

    return (
        <>
            <div className={classes.page}>
                <ColumnsSection />
                <HistorySection />
            </div>
            <UploaderFooter dataset={dataset} />
        </>
    );
};

export default UploaderTab;
