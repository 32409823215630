import { PermissionType } from "features/groups/model/PermissionType";
import { OptionType } from "../../../components/CustomTable/types/OptionType";
import { GeometryIcons, ValidIcons } from "../../../utils/customIcons";
import { permisionPrettyNames } from "utils/constants/permissionTypes";
import KeyIcon from "@mui/icons-material/Key";

export const geometryTypesOptions: OptionType<string>[] = [
    {
        displayName: "Point(s)",
        value: "POINT(S)",
        icon: GeometryIcons.multipoint
    },
    {
        displayName: "Linestring(s)",
        value: "LINESTRING(S)",
        icon: GeometryIcons.multiline
    },
    {
        displayName: "Polygon(s)",
        value: "POLYGON(S)",
        icon: GeometryIcons.multipolygon
    }
];

export const metadataOptions: OptionType<boolean>[] = [
    {
        displayName: "Valid",
        value: true,
        icon: ValidIcons.valid
    },
    {
        displayName: "Invalid",
        value: false,
        icon: ValidIcons.invalid
    }
];

export const permissionOptions: OptionType<PermissionType>[] = [
    {
        displayName: permisionPrettyNames[PermissionType.READ_WITHOUT_DOWNLOAD],
        value: PermissionType.READ_WITHOUT_DOWNLOAD,
        icon: <KeyIcon />
    },
    {
        displayName: permisionPrettyNames[PermissionType.READ],
        value: PermissionType.READ,
        icon: <KeyIcon />
    },
    {
        displayName: permisionPrettyNames[PermissionType.WRITE_READ],
        value: PermissionType.WRITE_READ,
        icon: <KeyIcon />
    },
    {
        displayName: permisionPrettyNames[PermissionType.OWNER],
        value: PermissionType.OWNER,
        icon: <KeyIcon />
    }
];
