import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Group as GroupType } from "features/groups/model/Group";
import { TableRow } from "@mui/material";
import NameCell from "components/CustomTable/components/customCells/NameCell/NameCell";
import CustomTypography from "components/CustomTypography/CustomTypography";
import MenuButtonCell from "components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";

type GroupProps = {
    group: GroupType;
    key: number;
    onClick: (group: GroupType) => void;
    onDeleteClick: (group: GroupType) => void;
};

const GroupRow = ({ group, key, onClick, onDeleteClick }: GroupProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const onMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleDeleteClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        onDeleteClick(group);
    };

    return (
        <>
            <TableRow onClick={() => onClick(group)} data-testid={rowTestId} key={key}>
                <NameCell>
                    <CustomTypography textWeight="semibold" variant="body2">
                        {group.name}
                    </CustomTypography>
                </NameCell>
                <MenuButtonCell onMenuClick={onMenuClick} />
            </TableRow>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                <MenuItem onClick={handleDeleteClick} className="menu-button" data-testid={deleteBtnTestId}>
                    <DeleteIcon className="icon" />
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
};

export default GroupRow;

const rowTestId = "qa-group-row";
const deleteBtnTestId = "qa-delete-group-dropdown-button";
