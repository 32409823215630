export const validRasterPaints = {
    "raster-brightness-max": true,
    "raster-brightness-min": true,
    "raster-contrast": true,
    "raster-fade-duration": true,
    "raster-hue-rotate": true,
    "raster-opacity": true,
    "raster-resampling": true,
    "raster-saturation": true
};
