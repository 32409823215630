import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
    return {
        icon: {
            width: 24
        },
        marginTop: {
            marginTop: theme.spacing(4)
        },
        addMilestoneButton: {
            width: "fit-content",
            paddingInline: theme.spacing(2),
            minHeight: "50px",
            gap: theme.spacing(1)
        }
    };
});
