import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CustomTypography from "../CustomTypography/CustomTypography";
import AddIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import { useStyles } from "./styles";
import SaveIcon from "@mui/icons-material/Save";

const CustomModal = (props) => {
    const {
        isOpen,
        handleClose,
        onConfirm,
        dialogTitle,
        dialogType,
        disabled,
        hideActionContainer,
        disableCancel,
        dialogTitleProps,
        cancelButtonProps,
        confirmButtonProps,
        closeIconProps,
        maxWidth,
        PaperProps,
        ...dialogProps
    } = props;
    const classes = useStyles();

    const typesWithoutCancelButton = {
        info: true
    };

    const displayConfirmationButton = () => {
        const buttonText = {
            create: "Create",
            apply: "Apply",
            add: "Add",
            upload: "Upload",
            update: "Update",
            start: "Start",
            info: "Understood",
            confirm: "Confirm",
            save: "Save"
        };

        const buttonIcon = {
            create: <AddIcon fontSize="small" />,
            add: <AddIcon fontSize="small" />,
            upload: <PublishIcon fontSize="small" />,
            update: <EditIcon fontSize="small" />,
            save: <SaveIcon fontSize="small" />
        };

        return (
            <Button
                variant="contained"
                color="primary"
                onClick={onConfirm}
                disabled={disabled}
                startIcon={buttonIcon[dialogType]}
                data-testid={confirmationButtonTestId}
                {...confirmButtonProps}
            >
                {buttonText[dialogType]}
            </Button>
        );
    };
    const cancelButton = () => {
        return (
            typesWithoutCancelButton[dialogType] || (
                <Button
                    onClick={handleClose}
                    variant="text"
                    color="primary"
                    className={classes.bold}
                    disabled={disableCancel}
                    data-testid={cancelButtonTestId}
                    {...cancelButtonProps}
                >
                    Cancel
                </Button>
            )
        );
    };

    return (
        <Dialog
            {...dialogProps}
            aria-labelledby="atlas-dialog create-group-modal"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            maxWidth={maxWidth ?? "sm"}
            PaperProps={{ ...PaperProps, "data-testid": modalTestId }}
        >
            <DialogTitle>
                <div className={classes.flex}>
                    <CustomTypography variant="h2" textWeight="bold" {...dialogTitleProps}>
                        {dialogTitle}
                    </CustomTypography>
                    <CloseIcon className={classes.clickable} onClick={handleClose} data-testid={closeButtonTestId} {...closeIconProps} />
                </div>
            </DialogTitle>
            <DialogContent dividers>{props.children}</DialogContent>
            {!hideActionContainer && (
                <DialogActions>
                    <>
                        {displayConfirmationButton()}
                        {cancelButton()}
                    </>
                </DialogActions>
            )}
        </Dialog>
    );
};

CustomModal.defaultProps = {
    hideActionContainer: false,
    disableCancel: false
};

export default CustomModal;

const confirmationButtonTestId = "qa-custom-modal-confirmation-button";
const cancelButtonTestId = "qa-custom-modal-cancel-button";
const closeButtonTestId = "qa-custom-modal-close-button";
const modalTestId = "qa-modal";
