export type ColorPalette = {
    type: ColorPaletteType;
    colorTable: ColorTableEntry[];
};

export type ColorTableEntry = {
    id: string;
    label: string;
    color: string;
    value: number;
    alpha: number;
};

export enum ColorPaletteType {
    NONE = "NONE",
    INTERPOLATED = "INTERPOLATED",
    DISCRETE = "DISCRETE"
}

export const hasCPT = (colorPalette: ColorPalette) => Boolean(colorPalette) && colorPalette.type !== ColorPaletteType.NONE;
