import { Switch, TextField, Tooltip } from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateMetadataPropertyThunk } from "../../../../../actions/metadataSchema";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import CustomTypography from "../../../../../components/CustomTypography/CustomTypography";
import LoadingPlaceholder from "../../../../../components/LoadingPlaceholder/LoadingPlaceholder";
import { getIsMetadataLoading, getOtherPropertiesNamesSelector } from "../../../../../selectors/metadataSchema";
import { metadataTypes, METADATA_SCHEMA_ID } from "../../../../../utils/constants/metadata";
import { MetadataPropertySchema } from "../../../../../utils/validators/metadata";
import TagsEditor from "../tags/TagsEditor/TagsEditor";
import { unwrapResult } from "@reduxjs/toolkit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useStyles } from "../styles";

const EditPropertyModal = ({ open, onClose, property }) => {
    const classes = useStyles();

    const otherPropertiesNames = useSelector(getOtherPropertiesNamesSelector(property?.name));
    const loading = useSelector(getIsMetadataLoading);
    const dispatch = useDispatch();

    const onSubmit = (property) =>
        dispatch(updateMetadataPropertyThunk({ metadataSchemaId: METADATA_SCHEMA_ID, metadataProperty: property }))
            .then(unwrapResult)
            .catch(() => toastr.error("Failed to edit property"));

    const PropertyEditor = ({ property }) => (property.type === metadataTypes.TAG_LIST ? <TagsEditor tags={property.value} /> : null);

    if (!open) return null;

    return (
        <Formik initialValues={{ ...property }} onSubmit={onSubmit} validationSchema={MetadataPropertySchema(otherPropertiesNames)}>
            {(formikProps) => (
                <CustomModal handleClose={onClose} isOpen={open} onConfirm={formikProps.submitForm} dialogTitle="Edit property" disabled={!formikProps.isValid} dialogType="apply">
                    <div className="container">
                        <div className="content">
                            <LoadingPlaceholder loading={loading} message="Updating metadata property">
                                <div className="property">
                                    <TextField
                                        id="filled-required"
                                        value={formikProps.values.name}
                                        onChange={formikProps.handleChange}
                                        inputProps={{ "data-testid": inputFieldTestId }}
                                        label="Name"
                                        variant="filled"
                                        name="name"
                                        error={!!formikProps.errors.name}
                                        helperText={formikProps.errors.name || ""}
                                        fullWidth
                                    />
                                </div>
                                <PropertyEditor property={formikProps.values} />
                                <div className={classes.optionContainer}>
                                    <Switch data-testid={mandatorySwitchTestId} name="mandatory" checked={formikProps.values.mandatory} onChange={formikProps.handleChange} />
                                    <CustomTypography textWeight="semibold"> Mandatory </CustomTypography>
                                </div>
                                <div className={classes.optionContainer}>
                                    <Tooltip title="Set whether this property is visible to Viewer users">
                                        {formikProps.values.hidden ? (
                                            <VisibilityOffIcon
                                                className={classes.visibilityIcon}
                                                onClick={() => formikProps.setFieldValue("hidden", false)}
                                                data-testid={visibilityTestId}
                                            />
                                        ) : (
                                            <VisibilityIcon
                                                className={classes.visibilityIcon}
                                                onClick={() => formikProps.setFieldValue("hidden", true)}
                                                data-testid={visibilityTestId}
                                            />
                                        )}
                                    </Tooltip>
                                    <CustomTypography className={classes.visibilityText} textWeight="semibold">
                                        {formikProps.values.hidden ? "Hidden" : "Visible"}
                                    </CustomTypography>
                                </div>
                            </LoadingPlaceholder>
                        </div>
                    </div>
                </CustomModal>
            )}
        </Formik>
    );
};

export default EditPropertyModal;

const inputFieldTestId = "qa-edit-metadata-modal-input-field";
const mandatorySwitchTestId = "qa-edit-metadata-modal-mandatory";
const visibilityTestId = "qa-edit-metadata-modal-visibility";
