import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    cacheStatusChip: {
        "&.status-Cached": {
            backgroundColor: theme.customColors.successColorLight,
            color: theme.customColors.successColor,
            "& .MuiSvgIcon-root": { fill: theme.customColors.successColor }
        },
        "&.status-Caching": {
            backgroundColor: theme.customColors.warningColorLight,
            color: theme.customColors.warningColor,
            "& .MuiSvgIcon-root": { fill: theme.customColors.warningColor }
        },
        "&.status-Uncached": {
            backgroundColor: theme.customColors.errorColorLight,
            color: theme.customColors.errorColor,
            "& .MuiSvgIcon-root": { fill: theme.customColors.errorColor }
        }
    }
}));
