import { TextField, InputAdornment, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";
import { useSearchBarStyles } from "./styles";

const SearchBar = ({ searchPlaceholder, value, onChange, className = "", textFieldClassName = "", noBg, noPadding = false, size = "small" }) => {
    const classes = useSearchBarStyles();

    const onClearSearchText = () => {
        onChange("");
    };

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    const searchStyle = clsx(!noPadding && classes.searchContainer, !noBg && classes.grayBG, className);

    return (
        <div className={searchStyle}>
            <TextField
                className={clsx(textFieldClassName, classes.searchField)}
                id="full-width"
                inputProps={{ autoComplete: "off", "data-testid": searchbarTestId }}
                fullWidth
                size={size}
                placeholder={searchPlaceholder}
                value={value}
                onChange={handleChange}
                variant="filled"
                hiddenLabel
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton className={`${classes.clearTextButton} ${value.length > 0 ? classes.visible : ""}`} onClick={onClearSearchText} size="large">
                                <CloseIcon className={classes.searchIcon} />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};

export default SearchBar;

const searchbarTestId = "qa-searchbar";
