import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { resetProjectData, toggleGroupLayers } from "actions/globalActions";
import { APP_HIGH_PERSPECTIVE_CONFIG, CONFIG } from "utils/constants/appDefaults";
import moveResourceActionFunction from "./moveResource";
import {
    addAppSearchBarThunk,
    addDatasetToAppThunk,
    addRasterToAppThunk,
    makeAppPublicThunk,
    removeAppSearchBarThunk,
    removeDatasetFromAppThunk,
    removeRasterFromAppThunk,
    updateApp,
    updateAppSearchBarThunk,
    unpublishAppThunk
} from "actions/apps";
import { DATA_TYPES } from "utils/constants/dataType";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { Matcher } from "@reduxjs/toolkit/dist/tsHelpers";
import { GuidMap } from "common/types/GuidMap";

type IncludedResource = {
    included: boolean;
    name: string;
};
type SliceState = {
    app: any;
    lastSaveDatasetCount: number;
    layerGroups: any[];
    includedDatasets: GuidMap<IncludedResource>; // { datasetId: { included: true, name: datasetName } }
    includedRasters: GuidMap<IncludedResource>; // { rasterId: { included: true, name: rasterName } }
    layerVisibilityMap: GuidMap<boolean>; // { layerId: false/true }
    layerStylesMap: GuidMap<any[]>; // { layerId: [styleOne, ...] }
    layerStyleErrorsMap: GuidMap<boolean>; // { layerId: boolean }
    selectedLayer: any;
    selectedGroupId: any;
    selectedTool: any;
    appLayersCount: number;
    hasUnsavedChanges: boolean;
    fetching: boolean;
    layerMovedFlag: boolean;
};

const initialState: SliceState = {
    app: {
        configJson: { ...CONFIG },
        basemaps: [],
        languages: [],
        tools: [],
        widgets: [],
        mapBounds: APP_HIGH_PERSPECTIVE_CONFIG.mapBounds,
        restrictedView: null
    },

    lastSaveDatasetCount: 0,
    layerGroups: [],
    includedDatasets: {},
    includedRasters: {},
    layerVisibilityMap: {}, // { layerId: false/true }
    layerStylesMap: {}, // { layerId: [styleOne, ...] }
    layerStyleErrorsMap: {}, // { layerId: {styleId: boolean, ...} }
    selectedLayer: null,
    selectedGroupId: null,
    selectedTool: null,
    appLayersCount: 0,
    hasUnsavedChanges: false,
    fetching: false,
    layerMovedFlag: false
};

const appDataSlice = createSlice({
    name: "appData",
    initialState,
    reducers: {
        clearAppData: () => initialState,
        setAppDetails: (state, { payload: { app, layerGroups, includedDatasets, includedRasters, layerVisibilityMap, layerStylesMap, hasUnsavedChanges = true } }) => {
            if (app) state.app = app;
            if (layerGroups) {
                state.layerGroups = layerGroups;
                let layerCount = 0;
                layerGroups.forLayersRecursive(() => layerCount++);
                state.appLayersCount = layerCount;
            }

            state.hasUnsavedChanges = hasUnsavedChanges;
            if (includedDatasets) state.includedDatasets = includedDatasets;
            if (includedRasters) state.includedRasters = includedRasters;
            if (layerVisibilityMap) state.layerVisibilityMap = layerVisibilityMap;
            if (layerStylesMap) state.layerStylesMap = layerStylesMap;
        },
        setLastSaveDatasetCount: (state, { payload: datasetCount }) => {
            state.lastSaveDatasetCount = datasetCount;
        },
        setLayerStyleErrorsMap: (state, { payload: layerStyleErrorsMap }) => {
            state.layerStyleErrorsMap = layerStyleErrorsMap;
        },
        changeLayerStylesErrorStatus: (state, { payload: { layerId, erronousStyleIdsMap } }) => {
            if (erronousStyleIdsMap === undefined) {
                delete state.layerStyleErrorsMap[layerId];
            } else {
                state.layerStyleErrorsMap[layerId] = erronousStyleIdsMap;
            }
        },
        addStyleToAppLayer: (state, { payload: newStyle }) => {
            state.layerStylesMap[state.selectedLayer.resourceId].push(newStyle);
            state.layerMovedFlag = !state.layerMovedFlag;
        },
        removeStyleFromAppLayer: (state, { payload: styleId }) => {
            state.layerStylesMap[state.selectedLayer.resourceId] = state.layerStylesMap[state.selectedLayer.resourceId].filter((s) => s.styleId !== styleId);
            state.layerMovedFlag = !state.layerMovedFlag;
        },
        changeStyleTypeOfAppLayer: ({ selectedLayer: { resourceId }, layerStylesMap }, { payload: { styleId, properties, type } }) => {
            layerStylesMap[resourceId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.properties = properties;
                    style.type = type;
                }
            });
        },
        changeStyleOrder: ({ layerStylesMap }, { payload: { layerId, styleId, beforeStyleId } }) => {
            const styles = layerStylesMap[layerId];

            const movedStyleIndex = styles.findIndex((s) => s.styleId === styleId);
            const destinationIndex = styles.findIndex((s) => s.styleId === beforeStyleId);

            const style = styles.splice(movedStyleIndex, 1)[0];

            styles.splice(destinationIndex, 0, style);
        },
        changePropertiesOfAppLayer: ({ selectedLayer: { resourceId }, layerStylesMap }, { payload: { styleId, newProperties } }) => {
            layerStylesMap[resourceId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.properties = newProperties;
                }
            });
        },
        changeZoomLimitsOfAppLayer: ({ layerStylesMap, selectedLayer: { resourceId } }, { payload: { styleId, minZoom, maxZoom } }) => {
            layerStylesMap[resourceId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.minZoom = minZoom;
                    style.maxZoom = maxZoom;
                }
            });
        },
        moveResource: moveResourceActionFunction,
        addGroup: ({ layerGroups }, { payload: newGroup }) => {
            layerGroups.unshift(newGroup);
        },
        removeGroup: (state, { payload: groupId }) => {
            if (state.selectedGroupId === groupId) {
                state.selectedGroupId = null;
            }
            if (state.selectedLayer && state.layerGroups.isChildOf(state.selectedLayer.resourceId, groupId)) {
                state.selectedLayer = null;
            }
            const group = state.layerGroups.getRecursive(groupId);

            state.layerGroups.forParentsRecursive(groupId, (parent) => {
                parent.totalLayersCount -= group.totalLayersCount;
                parent.visibleLayersCount -= group.visibleLayersCount;
            });

            if (!!group.layers.length) {
                state.layerGroups.unshift(...group.layers);
                state.layerMovedFlag = !state.layerMovedFlag;
            }

            state.layerGroups.removeOneRecursive(groupId);
        },
        addAppLayer: (state, { payload: { newLayer, styles } }) => {
            const { layerGroups, includedRasters, includedDatasets, layerVisibilityMap, layerStylesMap } = state;
            layerStylesMap[newLayer.resourceId] = styles;
            layerGroups.push(newLayer);
            const dataMap = newLayer.type === DATA_TYPES.raster ? includedRasters : includedDatasets;
            dataMap[newLayer.resourceId] = { included: true, name: newLayer.name };
            layerVisibilityMap[newLayer.resourceId] = true;
            state.appLayersCount++;

            state.layerMovedFlag = !state.layerMovedFlag;
        },
        removeAppLayer: (state, { payload: layerId }) => {
            const { includedRasters, includedDatasets, layerGroups, layerVisibilityMap, layerStylesMap, layerStyleErrorsMap } = state;

            if (state.selectedLayer?.resourceId === layerId) {
                state.selectedLayer = null;
            }
            const layer = layerGroups.getRecursive(layerId);

            layerGroups.forParentsRecursive(layerId, (parent) => {
                parent.totalLayersCount -= 1;
                if (layerVisibilityMap[layerId]) parent.visibleLayersCount -= 1;
            });
            delete layerStylesMap[layerId];
            delete layerVisibilityMap[layerId];
            delete layerStyleErrorsMap[layerId];

            layerGroups.removeOneRecursive(layerId);
            const dataMap = layer.type === DATA_TYPES.raster ? includedRasters : includedDatasets;
            delete dataMap[layerId];
            state.appLayersCount--;

            state.layerMovedFlag = !state.layerMovedFlag;
        },
        changePropertyOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, styleId, property } }) => {
            layerStylesMap[layerId].forEach((style) => {
                if (style.styleId === styleId) {
                    const propIndex = style.properties.findIndex((p: any) => p.name === property.name);
                    if (propIndex !== -1) {
                        style.properties[propIndex] = property;
                    } else {
                        console.error("Property index could not be found");
                    }
                }
            });
        },
        setAppPublishedStatus: ({ app }, { payload: newPublishedStatus }) => {
            app.isPublished = newPublishedStatus;
        },
        setAppName: ({ app }, { payload: newAppName }) => {
            app.name = newAppName;
        },
        setAppConfig: ({ app }, { payload: newConfig }) => {
            app.configJson = newConfig;
        },
        toggleGroupCollapse: ({ layerGroups }, { payload: { groupId, newCollapseValue } }) => {
            const group = layerGroups.getRecursive(groupId);
            group.options.collapsed = newCollapseValue;
        },
        toggleGroupIsCollapsed: ({ layerGroups }, { payload: { groupId, newIsCollapsedValue } }) => {
            const group = layerGroups.getRecursive(groupId);
            group.options.isCollapsed = newIsCollapsedValue;
        },
        setResourceOptions: ({ layerGroups, selectedLayer }, { payload: { resourceId, newOptions } }) => {
            const resource = layerGroups.getRecursive(resourceId);
            resource.options = newOptions;
            if (selectedLayer?.resourceId === resourceId) {
                selectedLayer.options = newOptions;
            }
        },
        setLayerVisibility: ({ layerGroups, layerVisibilityMap }, { payload: { layerId, newVisibility } }) => {
            layerVisibilityMap[layerId] = newVisibility;
            layerGroups.forParentsRecursive(layerId, (parent) => {
                if (newVisibility) parent.visibleLayersCount += 1;
                else parent.visibleLayersCount -= 1;
            });
        },
        setSelectedLayer: (state, { payload: layer }) => {
            state.selectedLayer = layer;
            state.selectedTool = null;
            state.selectedGroupId = null;
        },
        setSelectedGroupId: (state, { payload: groupId }) => {
            state.selectedLayer = null;
            state.selectedTool = null;
            state.selectedGroupId = groupId;
        },
        deselectResources: (state) => {
            state.selectedLayer = null;
            state.selectedGroupId = null;
        },
        setResourceName: ({ layerGroups, selectedLayer }, { payload: { resourceId, newName } }) => {
            const resource = layerGroups.getRecursive(resourceId);
            resource.name = newName;
            if (selectedLayer?.resourceId === resourceId) {
                selectedLayer.name = newName;
            }
        },
        setSelectedTool: (state, { payload: toolName }) => {
            state.selectedTool = toolName;
            state.selectedLayer = null;
            state.selectedGroupId = null;
        },
        addAppBasemap: (state, { payload: basemap }) => {
            state.app.basemaps.push(basemap);
        },
        deleteAppBasemap: (state, { payload: basemapId }) => {
            state.app.basemaps = state.app.basemaps.filter((basemap: any) => basemap.id !== basemapId);
        },
        moveAppBasemap: (state, { payload: { sourceIndex, destinationIndex } }) => {
            const reorderedBasemap = state.app.basemaps[sourceIndex];

            state.app.basemaps.splice(sourceIndex, 1);
            state.app.basemaps.splice(destinationIndex, 0, reorderedBasemap);
        },
        setAppRestrictedView: (state, { payload }) => {
            state.app.restrictedView = payload;
        }
    },
    extraReducers: (builder) => {
        const thunks: [AnyAsyncThunk, ...AnyAsyncThunk[]] = [
            addDatasetToAppThunk,
            addRasterToAppThunk,
            removeRasterFromAppThunk,
            removeDatasetFromAppThunk,
            unpublishAppThunk,
            makeAppPublicThunk,
            addAppSearchBarThunk,
            updateAppSearchBarThunk,
            removeAppSearchBarThunk
        ];

        builder
            .addCase(toggleGroupLayers, ({ layerGroups, layerVisibilityMap }, { payload: { groupId, newVisibility } }) => {
                //It should be alright not to update the layout property "visibility".
                //Group toggle can't be used when a layer is selected so we can also omit that check
                const group = layerGroups.getRecursive(groupId);

                group.layers.forLayersRecursive((layer: any) => {
                    layerVisibilityMap[layer.resourceId] = newVisibility;
                });
                //Set the counts of the parent groups.
                layerGroups.forParentsRecursive(groupId, (parent) => {
                    if (newVisibility) {
                        parent.visibleLayersCount += group.totalLayersCount - group.visibleLayersCount;
                    } else {
                        parent.visibleLayersCount -= group.visibleLayersCount;
                    }
                });
                //Set the counts of the child groups.
                group.layers.forGroupsRecursive((subGroup: any) => {
                    subGroup.visibleLayersCount = newVisibility ? subGroup.totalLayersCount : 0;
                });
                //Set the count of the current group
                group.visibleLayersCount = newVisibility ? group.totalLayersCount : 0;
            })
            .addCase(updateApp.fulfilled, (state, { payload }) => {
                state.lastSaveDatasetCount = state.appLayersCount;
                state.hasUnsavedChanges = false;
                state.app.modifiedUtc = payload.modifiedUtc;
                state.app.configJson.layerGroups = payload.configJson.layerGroups;
            })
            .addCase(unpublishAppThunk.fulfilled, (state) => {
                state.app.published = false;
            })
            .addCase(makeAppPublicThunk.fulfilled, (state, { payload }) => {
                state.app.public = payload.public;
            })
            .addCase(addAppSearchBarThunk.fulfilled, (state, { payload }) => {
                state.app.searchBar = payload;
            })
            .addCase(updateAppSearchBarThunk.fulfilled, (state, { payload: { datasetId, columnName } }) => {
                state.app.searchBar = {
                    ...state.app.searchBar,
                    datasetId,
                    columnName
                };
            })
            .addCase(removeAppSearchBarThunk.fulfilled, (state) => {
                state.app.searchBar = null;
            })
            .addCase(removeDatasetFromAppThunk.fulfilled, (state, { payload: { datasetId } }) => {
                if (!!state.app.searchBar && state.app.searchBar.datasetId === datasetId) {
                    state.app.searchBar = null;
                }
            })
            .addCase(resetProjectData, () => initialState)
            .addMatcher(isPending(...thunks), (state) => {
                state.fetching = true;
            })
            .addMatcher(isAnyOf(isFulfilled(...thunks), isRejected(...thunks)), (state) => {
                state.fetching = false;
            })
            // Had problems importing the type Matcher so I decided not to lose any more time
            .addMatcher(isAnyOf(...appChangesReducers), (state) => {
                state.hasUnsavedChanges = true;
            });
    }
});

export const {
    clearAppData,
    setAppDetails,
    setLastSaveDatasetCount,
    setLayerStyleErrorsMap,
    changeLayerStylesErrorStatus,
    addStyleToAppLayer,
    removeStyleFromAppLayer,
    changeStyleTypeOfAppLayer,
    changePropertiesOfAppLayer,
    changeZoomLimitsOfAppLayer,
    changeStyleOrder,
    moveResource,
    addGroup,
    removeGroup,
    addAppLayer,
    removeAppLayer,
    setAppPublishedStatus,
    setAppName,
    setAppConfig,
    toggleGroupCollapse,
    toggleGroupIsCollapsed,
    setResourceOptions,
    setLayerVisibility,
    setSelectedLayer,
    setSelectedGroupId,
    setResourceName,
    deselectResources,
    setSelectedTool,
    addAppBasemap,
    deleteAppBasemap,
    moveAppBasemap,
    changePropertyOfLayerStyle,
    setAppRestrictedView
} = appDataSlice.actions;

const appChangesReducers: [Matcher<any>, ...Matcher<any>[]] = [
    addStyleToAppLayer,
    removeStyleFromAppLayer,
    changeStyleTypeOfAppLayer,
    changeStyleOrder,
    changePropertiesOfAppLayer,
    changeZoomLimitsOfAppLayer,
    moveResource,
    addGroup,
    removeGroup,
    addAppLayer,
    removeAppLayer,
    setAppName,
    setAppConfig,
    setResourceOptions,
    setResourceName,
    moveAppBasemap,
    changePropertyOfLayerStyle,
    setAppRestrictedView
];

export default appDataSlice.reducer;
