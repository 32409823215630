import { ReactElement, useContext, useEffect, useMemo, useRef } from "react";
import { OptionType } from "../types/OptionType";
import { TableContext } from "../providers/TableContextProvider";
import { SelectFilterType } from "../types/SelectFilterType";

const defaultFilterFunction = <T>(elem: T, selectedOptions: T[]) => selectedOptions.includes(elem);
export const defaultDisplayNameGenerator = <T>(value: T) => String(value);

const useRegisterSelectFilter = <TData>(
    name: string,
    columnName: keyof TData,
    isSearchable: boolean = true,
    data: TData[],
    icon: ReactElement | null = null,
    options: OptionType<TData[keyof TData]>[] | ((elem: TData) => TData[keyof TData]) = (data: TData) => data[columnName],

    displayNameGenerator = defaultDisplayNameGenerator,
    filterFunction: (elem: TData[keyof TData], selectedOptions: TData[keyof TData][]) => boolean = defaultFilterFunction
) => {
    const computedOptionsRef = useRef<OptionType<TData[keyof TData]>[]>([]);
    const tableContext = useContext(TableContext);

    const computedOptions = useMemo(() => {
        if (typeof options !== "function") {
            return options;
        } else {
            const optionsSet = new Set(data?.map((elem) => options(elem)));
            const optionsValues = Array.from(optionsSet);

            return optionsValues.map<OptionType<TData[keyof TData]>>((value) => ({ displayName: displayNameGenerator(value), icon, value }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        tableContext.registerFilter<TData[keyof TData], SelectFilterType<TData[keyof TData]>>({
            name,
            columnName,
            filterFunction,
            onApply: (selectedOptions: TData[keyof TData][]) => tableContext.activateFilter(name, selectedOptions),
            onClear: () => tableContext.deactivateFilter(name),
            options: computedOptions,
            isSearchable,
            selected: [],
            type: "select",
            active: false,
            defaultValue: []
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [computedOptions]);

    // Needed when removing a table row which was the
    // unique one having a computed option that was enabled
    // therefore, we will disable the filter, as one of the selected
    // options does no longer exist
    useEffect(() => {
        if (computedOptionsRef.current.length > computedOptions.length) {
            tableContext.deactivateFilter(name);
        }

        computedOptionsRef.current = computedOptions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [computedOptions]);
};

export default useRegisterSelectFilter;
