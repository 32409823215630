import { FC } from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useTableHeadStyles } from "./styles/enhancedTableHeadStyles";

type Props = {
    orderByDescending: boolean;
    orderBy: string;
    onRequestSort: (property: string) => void;
    headCells: HeadCell[];
};

export type HeadCell = {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
};

const EnhancedTableHead: FC<Props> = (props) => {
    const classes = useTableHeadStyles();
    const { orderByDescending, orderBy, onRequestSort, headCells } = props;

    const getDataTestId = (column: HeadCell) => {
        const columnName = column.label.toLocaleLowerCase().replaceAll(" ", "-");
        return columnTestId + columnName;
    };

    const order = orderByDescending ? "desc" : "asc";
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell: HeadCell) => (
                    <TableCell
                        className={classes.tableCell}
                        key={headCell.id}
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => onRequestSort(headCell.id)}
                            className={classes.tableCellLabel}
                            data-testid={getDataTestId(headCell)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default EnhancedTableHead;

const columnTestId = "qa-attribute-table-head-column-";
