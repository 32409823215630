import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

function difference(a, b) {
    return Math.abs(a - b);
}
//This tooltip will appear only as long the user holds the mouse still. It will dissappear if he moves the mouse, but it can re-appear
//if it is still again.
const StaticMouseTooltip = ({ children, title, enterDelay, arrow }) => {
    const [cursorPosition, setCursorPosition] = useState({ x: undefined, y: undefined });
    const [tooltipPosition, setTooltipPosition] = useState({ x: undefined, y: undefined });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let changed = false;

        setTimeout(() => {
            //this more or less checks that the mouse stayed in place
            if (!changed && cursorPosition.x && cursorPosition.y) {
                setOpen(true);
                setTooltipPosition(cursorPosition);
            }
        }, enterDelay); //timeout should match the delay for the tooltip in order to take the latest position

        return () => {
            changed = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cursorPosition]);

    return (
        <Tooltip
            title={title}
            open={open}
            arrow={arrow}
            onMouseMove={(e) => {
                //Signals that the mouse moved and hides the current tooltip
                const { pageX, pageY } = e;
                const newX = pageX;
                const newY = pageY + 6; //6px in order not to cover the text
                //If the tooltip isn't active, we want to take the latest position
                if (!open) {
                    setCursorPosition({ x: newX, y: newY });
                    return;
                }
                const { x, y } = tooltipPosition;

                //If the tooltip is active, we add a movement margin in order to feel more user friendly.
                //This way the user can move the mouse a bit before the tooltip dissapears.
                if (difference(x, newX) > 8 || difference(y, newY) > 8) {
                    setCursorPosition({ x: newX, y: newY });
                    setOpen(false);
                }
            }}
            onMouseOut={() => {
                setCursorPosition({});
                setOpen(false);
            }}
            PopperProps={{
                anchorEl: {
                    clientHeight: 0,
                    clientWidth: 0,
                    getBoundingClientRect: () => ({
                        top: tooltipPosition.y,
                        left: tooltipPosition.x,
                        right: tooltipPosition.x,
                        bottom: tooltipPosition.y,
                        width: 0,
                        height: 0
                    })
                }
            }}
        >
            {children}
        </Tooltip>
    );
};

StaticMouseTooltip.defaultProps = {
    title: "",
    enterDelay: 600,
    arrow: false
};

export default StaticMouseTooltip;
