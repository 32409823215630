import { CancelToken } from "axios";
import baseAtlasApi from "../../store/baseAtlasApi";
import { Dataset } from "../../models/Dataset";
import * as types from "../../actions/actionTypes";
import { Raster } from "../rasters/models/Raster";
import { rastersApi } from "features/rasters/api";
import { DatasetAppendResult } from "../fidu/models/DatasetAppendResult";
import { appendHistory } from "../fidu/slice";

type UploadRequest = {
    file: File;
    uploadProgressHandler: (progressEvent: ProgressEvent) => void;
    cancelToken: CancelToken;
};

type DatasetUploadRequest = {
    name: string;
    tableName: string;
    schemaName: string;
    extension: ".geojson" | ".csv" | ".zip" | ".gpkg";
} & UploadRequest;

type RasterUploadRequest = UploadRequest & {
    name: string;
    extension: ".tif" | ".tiff" | ".mbtiles";
};

type DatasetAppendRequest = UploadRequest & {
    extension: ".geojson" | ".zip" | ".csv";
    datasetId: string;
};

export const uploadApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        uploadDataset: build.mutation<Dataset, DatasetUploadRequest>({
            query: ({ file, name, tableName, schemaName, extension, uploadProgressHandler, cancelToken }) => {
                const formData = new FormData();

                formData.append("file", file);
                formData.append("name", name);
                formData.append("tableName", tableName);
                formData.append("schemaName", schemaName);
                formData.append("generateCache", "true");

                let url = "dataset";

                switch (extension) {
                    case ".zip":
                        url += "/zip";
                        break;
                    case ".gpkg":
                        url += "/gpkg";
                        break;
                    default:
                }

                return {
                    url,
                    method: "POST",
                    headers: { "Content-Type": "multipart/form-data" },
                    data: formData,
                    onUploadProgress: uploadProgressHandler,
                    cancelToken
                };
            },
            // TODO: Refactor this to use pessimistic cache updates
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    dispatch({ type: types.CREATE_DATASET_COMPLETE, result: result.data });
                } catch {}
            }
        }),
        uploadRaster: build.mutation<Raster, RasterUploadRequest>({
            query: ({ file, name, extension, uploadProgressHandler, cancelToken }) => {
                const formData = new FormData();

                formData.append("file", file);
                formData.append("name", name);

                const url = extension === ".mbtiles" ? "raster" : "raster/geotiff";

                return {
                    url,
                    method: "POST",
                    headers: { "Content-Type": "multipart/form-data" },
                    data: formData,
                    onUploadProgress: uploadProgressHandler,
                    cancelToken
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const queryResult = await queryFulfilled;

                    dispatch(
                        rastersApi.util.updateQueryData("fetchRasters", undefined, (draft) => {
                            draft.push(queryResult.data);
                        })
                    );
                } catch {}
            }
        }),
        appendDataset: build.mutation<DatasetAppendResult, DatasetAppendRequest>({
            query: ({ file, extension, datasetId, uploadProgressHandler, cancelToken }) => {
                const formData = new FormData();
                let url = `dataset/${datasetId}/append`;

                if (extension === ".geojson" || extension === ".csv") url += "/geojson";

                formData.append("file", file);
                formData.append("generateCache", "true");

                return {
                    url,
                    method: "POST",
                    headers: { "Content-Type": "multipart/form-data" },
                    data: formData,
                    onUploadProgress: uploadProgressHandler,
                    cancelToken
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled;

                    dispatch(appendHistory(result.data.generatedHistory));
                } catch {}
            }
        })
    })
});

export const { useUploadDatasetMutation, useUploadRasterMutation, useAppendDatasetMutation } = uploadApi;
