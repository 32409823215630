export const makeDefaultLayout = (layerId, layerType, styleConfig) => {
    const properties = styleConfig[layerType];
    const layoutProperties = properties.filter((x) => x.type === "layout");
    layoutProperties.push({
        type: "layout",
        name: "visibility",
        value: "visible"
    });
    const layout = {
        layerId,
        properties: layoutProperties
    };
    return layout;
};

export const makeLayoutFromStyle = (style, styleConfig, visible = false) => {
    const properties = !!styleConfig ? JSON.parse(JSON.stringify(styleConfig[style.type])) : [];
    const layoutProperties = properties.filter((x) => x.type === "layout");

    //Add any new paint/layout properties to style
    layoutProperties.forEach((property) => {
        let styleProperty = style.properties.find((x) => x.name === property.name);

        if (styleProperty) {
            property.value = styleProperty.value;
            property.expressionType = styleProperty.expressionType || "none";

            if (styleProperty.name === "raster-opacity") {
                styleProperty.propertyType = property.propertyType;
            }
        }
    });

    layoutProperties.push({
        type: "layout",
        name: "visibility",
        value: visible ? "visible" : "none"
    });

    const layout = {
        layerId: style.styleId,
        properties: layoutProperties
    };
    return layout;
};
