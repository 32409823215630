import { AnyAction, createAction, ThunkAction } from "@reduxjs/toolkit";
import { getStylesOfLayer } from "../selectors/appData";
import * as appDataActions from "../reducers/appData/appData";
import * as mapActions from "../reducers/map";
import { RootState } from "../store/models/RootState";
import { GuidMap } from "../common/types/GuidMap";

type ToggleGroupLayersPayload = { groupId: string; newVisibility: boolean; appLayersMap: GuidMap<boolean> };
export const toggleGroupLayers = createAction<ToggleGroupLayersPayload>("global/toggleGroupLayers");

export const toggleAppLayer =
    (layerId: string, newVisibility: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch, getState) => {
        const layerStyles = getStylesOfLayer(layerId)(getState());

        layerStyles.forEach((s: any) => {
            dispatch(
                mapActions.updateMapLayout({
                    layerId: s.styleId,
                    properties: [
                        {
                            name: "visibility",
                            value: newVisibility ? "visible" : "none"
                        }
                    ]
                })
            );
        });

        dispatch(appDataActions.setLayerVisibility({ layerId, newVisibility }));
    };

export const resetProjectData = createAction("global/resetProjectData");
