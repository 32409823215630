import React, { forwardRef, useImperativeHandle, useState } from "react";
import { UploadHandle } from "../../../models/UploadHandle";
import { useAppDispatch } from "../../../../../store/hooks/useAppDispatch";
import { useAppendDatasetMutation } from "../../../api";
import { getPercentageCompleted } from "../../../utils";
import useComponentCancelToken from "../../../../../app/hooks/useComponentCancelToken";
import { removeUpload, updateUpload } from "../../../slice";
import { UploadStatus } from "../../../models/Upload";
import { Grid, IconButton } from "@mui/material";
import TypeChip from "../../../../../components/TypeChip/TypeChip";
import ReadOnlyField from "../../../../../components/ReadOnlyField/ReadOnlyField";
import UploadProgress from "../../UploadView/UploadProgress/UploadProgress";
import { isApiError } from "../../../../../store/customBaseQuery";
import UploadIcon from "@mui/icons-material/Upload";
import CancelIcon from "@mui/icons-material/Close";
import RetryIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

type Props = {
    name: string;
    datasetId: string;
    file: File;
    extension: ".geojson" | ".zip" | ".csv";
};

export const AppendUpload = forwardRef<UploadHandle, Props>(({ name, file, datasetId, extension }, ref) => {
    const [progress, setProgress] = useState(0);
    const [appendDataset, { isLoading, error, isError, reset }] = useAppendDatasetMutation();
    const [cancelToken, regenerateCancelToken] = useComponentCancelToken();

    const dispatch = useAppDispatch();

    const isDoneUploading = progress === 100;
    const isPending = !isLoading && !isDoneUploading;

    const uploadProgressHandler = (e: ProgressEvent) => {
        const loaded = e.loaded;
        const total = e.total;

        setProgress(getPercentageCompleted(loaded, total));
    };

    const onDatasetAppend = () => {
        if (isLoading || isDoneUploading) return;

        dispatch(updateUpload({ id: name, changes: { status: UploadStatus.Uploading } }));

        appendDataset({
            file,
            extension,
            datasetId,
            cancelToken: cancelToken.token,
            uploadProgressHandler
        })
            .unwrap()
            .then(() =>
                dispatch(
                    updateUpload({
                        id: name,
                        changes: { status: UploadStatus.Uploaded }
                    })
                )
            )
            .catch(() => dispatch(updateUpload({ id: name, changes: { status: UploadStatus.Failed } })));
    };

    const onUploadCancel = () => {
        cancelToken.cancel("Upload cancelled");
    };

    useImperativeHandle(ref, () => ({
        startUpload: () => onDatasetAppend(),
        cancelUpload: onUploadCancel
    }));

    const onUploadRetry = () => {
        setProgress(0);
        reset();
        regenerateCancelToken();
    };

    return (
        <Grid container spacing={1} mt={1} alignItems="center">
            <Grid item xs={"auto"} marginTop={1}>
                <TypeChip type="vector" variant="large" />
            </Grid>

            {isPending && !isError && (
                <>
                    <Grid item xs>
                        <ReadOnlyField label="Raster Name" text={name} />
                    </Grid>
                    <Grid item xs></Grid>
                </>
            )}

            {(isLoading || isDoneUploading || isError) && (
                <>
                    <Grid item xs={4}>
                        <ReadOnlyField label="Name" text={name} />
                    </Grid>
                    <Grid item xs>
                        <UploadProgress
                            progress={progress}
                            isLoading={isLoading}
                            error={isError}
                            errorMessage={isApiError(error) ? error.message : "An unexpected error occured"}
                        />
                    </Grid>
                </>
            )}

            <Grid item xs="auto" sx={{ "&:empty": { paddingLeft: 0, flex: 0 } }}>
                {isPending && !isError && (
                    <IconButton size="small" color="primary" onClick={onDatasetAppend}>
                        <UploadIcon />
                    </IconButton>
                )}

                {isLoading && (
                    <IconButton size="small" color="error" onClick={onUploadCancel}>
                        <CancelIcon />
                    </IconButton>
                )}

                {isError && (
                    <IconButton size="small" color="primary" onClick={onUploadRetry}>
                        <RetryIcon />
                    </IconButton>
                )}

                {(isPending || isError) && (
                    <IconButton size="small" color="error" onClick={() => dispatch(removeUpload(name))}>
                        <DeleteIcon />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
});
