import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import SymbolIcon from "@mui/icons-material/FontDownload";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";

export const CustomIcons = {
    measure: (
        <SvgIcon>
            <path d="M 19.733237,5.4587218 14.586822,0.26606944 A 0.91736449,0.91742979 0 0 0 13.935493,1.516769e-5 0.91736449,0.91742979 0 0 0 13.293338,0.26606944 L 0.26676275,13.247701 a 0.91736449,0.91742979 0 0 0 0,1.293576 L 5.4590458,19.73393 A 0.91736449,0.91742979 0 0 0 6.1103746,19.999985 0.91736449,0.91742979 0 0 0 6.7525297,19.73393 L 9.3486712,17.137604 17.137095,9.3486248 19.733237,6.7522981 a 0.91736449,0.91742979 0 0 0 0,-1.2935763 z M 16.485766,7.4036733 15.834438,6.7522981 a 0.91736449,0.91742979 0 0 0 -1.293484,0 0.91736449,0.91742979 0 0 0 0,1.3027504 l 0.651328,0.6513751 -1.302657,1.2935762 -1.944813,-1.9449513 a 0.91736449,0.91742979 0 0 0 -1.293484,1.2935763 l 1.944813,1.9449512 -1.302658,1.30275 -0.642155,-0.651375 a 0.92111798,0.92118354 0 1 0 -1.3026568,1.30275 l 0.6513285,0.642201 -1.2934836,1.302751 -1.9539864,-1.944952 a 0.91736449,0.91742979 0 0 0 -1.2934839,0 0.91736449,0.91742979 0 0 0 0,1.293576 L 7.4038585,16.486229 6.1103746,17.788979 2.2115755,13.889902 13.889625,2.2110209 17.788424,6.1100971 Z" />
        </SvgIcon>
    ),
    legend: (
        <SvgIcon fontSize="small">
            <path d="M20,15H4v-2h16V15z M20,17H4v2h16V17z M15,11l5-3.55L20,5l-5,3.55L10,5L4,8.66L4,11l5.92-3.61L15,11z" />
        </SvgIcon>
    )
};

export const GeometryIcons = {
    point: (
        <SvgIcon>
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z" />
        </SvgIcon>
    ),
    multipoint: (
        <SvgIcon>
            <path d="M0,0h24v24h-24Z" fill="none" />
            <path
                d="M12,12c-1.1,0-2-.9-2-2s.9-2,2-2s2,.9,2,2-.9,2-2,2Zm6-1.8C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14c4.05-3.7,6-6.8,6-9.14ZM12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2Z"
                transform="translate(3-.95583)"
            />
            <ellipse rx="7.494569" ry="7.472846" transform="matrix(1.081159 0 0 1.148255 7.757422 12)" fill="#fff" strokeWidth="0" />
            <path d="" fill="none" stroke="#3f5787" strokeWidth="0.048" />
            <path
                d="M12,12c-1.1,0-2-.9-2-2s.9-2,2-2s2,.9,2,2-.9,2-2,2Zm6-1.8C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14c4.05-3.7,6-6.8,6-9.14ZM12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2Z"
                transform="translate(-3.650251 1.419267)"
            />
        </SvgIcon>
    ),
    polygon: (
        <SvgIcon>
            <svg id="eFhDSQVljBI1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                <g transform="matrix(2.099652 0 0 2.218184-317.371413-275.320739)">
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 185.82 221.889)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 318.767 148.03)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 421.301 272.034)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 249.252 316.603)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(1.08282-.604458 0.20088 0.359854 188.253 210.901)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(1.36812-.354239 0.103303 0.398969 260.947 305.283)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(-.471036-.773584 0.352005-.214337 235.473 316.997)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(-.824197-1.02015 0.320575-.258998 405.648 267.843)" strokeWidth="0" />
                </g>
            </svg>
        </SvgIcon>
    ),
    multipolygon: (
        <SvgIcon>
            <svg id="eFew2DpXnCh1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                <g transform="matrix(1.346577 0 0 1.436939 34.100117-158.854648)">
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 185.82 221.889)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 318.767 148.03)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 421.301 272.034)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 249.252 316.603)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(1.08282-.604458 0.20088 0.359854 188.253 210.901)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(1.36812-.354239 0.103303 0.398969 260.947 305.283)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(-.471036-.773584 0.352005-.214337 235.473 316.997)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(-.824197-1.02015 0.320575-.258998 405.648 267.843)" strokeWidth="0" />
                </g>
                <g transform="matrix(1.955927 0 0 2.225344-309.421125-276.984125)">
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 185.82 221.889)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 318.767 148.03)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 421.301 272.034)" strokeWidth="0" />
                    <ellipse rx="31.7161" ry="31.2817" transform="matrix(.612154 0 0 0.576498 249.252 316.603)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(1.08282-.604458 0.20088 0.359854 188.253 210.901)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(1.36812-.354239 0.103303 0.398969 260.947 305.283)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(-.471036-.773584 0.352005-.214337 235.473 316.997)" strokeWidth="0" />
                    <rect width="105.413" height="39.1021" rx="0" ry="0" transform="matrix(-.824197-1.02015 0.320575-.258998 405.648 267.843)" strokeWidth="0" />
                </g>
            </svg>
        </SvgIcon>
    ),
    line: (
        <SvgIcon>
            <path d="M23 8c0 1.1-.9 2-2 2-.18 0-.35-.02-.51-.07l-3.56 3.55c.05.16.07.34.07.52 0 1.1-.9 2-2 2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07l-4.55 4.56c.05.16.07.33.07.51 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.18 0 .35.02.51.07l4.56-4.55C8.02 9.36 8 9.18 8 9c0-1.1.9-2 2-2s2 .9 2 2c0 .18-.02.36-.07.52l2.55 2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56C19.02 8.35 19 8.18 19 8c0-1.1.9-2 2-2s2 .9 2 2z" />
        </SvgIcon>
    ),
    multiline: (
        <SvgIcon>
            <svg id="ekvOdtsi1a71" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                <g>
                    <rect width="24" height="24" rx="0" ry="0" fill="none" />
                </g>
                <g transform="translate(0-2.6937)">
                    <g>
                        <path d="M23,8c0,1.1-.9,2-2,2-.18,0-.35-.02-.51-.07l-3.56,3.55c.05.16.07.34.07.52c0,1.1-.9,2-2,2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07L4.93,15.49c.05.16.07.33.07.51c0,1.1-.9,2-2,2s-2-.9-2-2s.9-2,2-2c.18,0,.35.02.51.07L8.07,9.52C8.02,9.36,8,9.18,8,9c0-1.1.9-2,2-2s2,.9,2,2c0,.18-.02.36-.07.52l2.55,2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56C19.02,8.35,19,8.18,19,8c0-1.1.9-2,2-2s2,.9,2,2Z" />
                    </g>
                    <g transform="translate(0 5.3874)">
                        <g>
                            <path d="M23,8c0,1.1-.9,2-2,2-.18,0-.35-.02-.51-.07l-3.56,3.55c.05.16.07.34.07.52c0,1.1-.9,2-2,2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07L4.93,15.49c.05.16.07.33.07.51c0,1.1-.9,2-2,2s-2-.9-2-2s.9-2,2-2c.18,0,.35.02.51.07L8.07,9.52C8.02,9.36,8,9.18,8,9c0-1.1.9-2,2-2s2,.9,2,2c0,.18-.02.36-.07.52l2.55,2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56C19.02,8.35,19,8.18,19,8c0-1.1.9-2,2-2s2,.9,2,2Z" />
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    ),
    symbol: <SymbolIcon />,
    extrusion: <CropOriginalIcon />
};

export const VendorIcons = {
    googleDrive: (
        <SvgIcon viewBox="0 0 87.3 78">
            <path d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z" fill="#0066da" />
            <path d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z" fill="#00ac47" />
            <path d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z" fill="#ea4335" />
            <path d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z" fill="#00832d" />
            <path d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z" fill="#2684fc" />
            <path d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z" fill="#ffba00" />
        </SvgIcon>
    )
};

export const ValidIcons = {
    valid:(
        <CheckCircleRoundedIcon/>
    ),
    invalid:(
        <CancelIcon/>
    )
}