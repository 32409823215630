import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { fetchCurrentUser } from "./actions";
import { UserSession } from "./model/UserSession";

type AuthState = {
    token: string;
    fetching: boolean;
    user?: UserSession;
};

const initialState: AuthState = {
    token: "",
    fetching: false
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setToken: (state, { payload: newToken }: PayloadAction<string>) => {
            state.token = newToken;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
                state.user = payload;
            })
            .addCase(fetchCurrentUser.pending, (state) => {
                state.fetching = true;
            })
            .addMatcher(isAnyOf(fetchCurrentUser.fulfilled, fetchCurrentUser.rejected), (state) => {
                state.fetching = false;
            });
    }
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
