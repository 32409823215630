import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FC, useState } from "react";
import toastr from "components/CustomToastr/CustomToastr";
import { GisRole } from "features/users/model/GisRole";
import { useAppSelector } from "store/hooks/useAppSelector";
import { GisUser } from "features/users/model/GisUser";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { useUpdateUserByEmailMutation } from "../../../api";

type Props = {
    user: GisUser;
};

const EditRole: FC<Props> = ({ user }) => {
    const [selectedRole, setSelectedRole] = useState(user.role);
    const featureFlags = useAppSelector(getFeatureFlags);
    const [updateUserByEmail, { isLoading: isUpdating }] = useUpdateUserByEmailMutation();

    const roles = [
        { role: GisRole.Admin, testId: adminRoleTestId },
        { role: GisRole.User, testId: userRoleTestId }
    ];

    if (featureFlags.FIDU) {
        roles.push({ role: GisRole.Uploader, testId: uploaderRoleTestId });
    }

    const onUpdateUser = () =>
        updateUserByEmail({ username: user.username, role: selectedRole })
            .unwrap()
            .catch(err => toastr.error(err.message));

    const onRoleChange = (roleName: GisRole) => setSelectedRole(roleName);

    return (
        <RadioGroup row>
            {roles.map((role) =>
                (
                    <FormControlLabel
                        key={role.role}
                        control={<Radio checked={selectedRole === role.role}
                            onChange={() => onRoleChange(role.role)} />}
                        label={role.role}
                        data-testid={role.testId}
                    />
                ))}
            <Button variant="contained" color="primary" onClick={onUpdateUser}
                disabled={selectedRole === user.role || isUpdating} data-testid={updateRoleButtonTestId}>
                Update
            </Button>
        </RadioGroup>
    );
};

const adminRoleTestId = "qa-user-details-drawer-admin-role-radio-button";
const userRoleTestId = "qa-user-details-drawer-user-role-radio-button";
const uploaderRoleTestId = "qa-user-details-drawer-uploader-role-radio-button";
const updateRoleButtonTestId = "qa-user-details-drawer-update-role-button";

export default EditRole;