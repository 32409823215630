import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    filterButton: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "0.2s background-color",
        paddingLeft: 8,
        paddingRight: 8,
        maxWidth: 150,
        minWidth: 70,
        borderRadius: 4,

        "&:hover": {
            backgroundColor: theme.customColors.appBgColor
        }
    },
    label: {
        maxWidth: "70%",
        marginRight: 4
    },
    filterIcon: {
        height: 24,
        width: 24,
        marginRight: 4
    },
    active: {
        color: theme.customColors.successColor
    },
    inactive: {
        color: theme.customColors.primaryColor
    },
    buttonsContainer: {
        marginTop: "8px",
        paddingLeft: "4px"
    },
    divider: {
        marginBottom: "4px"
    },
    badge: {
        marginRight: 16,
        marginLeft: 12,
        color: theme.customColors.white,

        "& .MuiBadge-badge": {
            backgroundColor: theme.customColors.successColor
        }
    }
}));
