export const generateStyleErrorsMap = (errors, layerIdsList) => {
    const layerStyleErrorsMap = {}; //this refers to app layers, not map layers

    const addedStyles = {};

    errors.forEach(({ message }) => {
        const layerText = message.split(":")[0];

        // This is done for the situation when we have layers[128].paint.line-width[5]
        const layerNumberSection = layerText.split(".")[0];

        const numberBetweenSquareBracketsExpression = /\[(.*?)\]/;
        const layerIndex = layerNumberSection.match(numberBetweenSquareBracketsExpression)[1];

        const erronousLayerId = layerIdsList[layerIndex].layerId;
        const erronousResourceId = layerIdsList[layerIndex].resourceId;

        if (addedStyles[erronousLayerId]) return;

        addedStyles[erronousLayerId] = true;

        const currentLayerErrors = layerStyleErrorsMap[erronousResourceId];

        if (currentLayerErrors) {
            layerStyleErrorsMap[erronousResourceId][erronousLayerId] = true;
        } else {
            layerStyleErrorsMap[erronousResourceId] = { [erronousLayerId]: true };
        }
    });

    return layerStyleErrorsMap;
};
