import React, { useMemo, useState } from "react";
import CustomTable from "../CustomTable/CustomTable";
import SearchBar from "../SearchBar/SearchBar";
import { useTableViewStyles } from "./styles";

const TableView = ({
    searchPlaceholder,
    data,
    fetchingData,
    columns,
    generateItem,
    hideBottomBar,
    tableSize,
    filterFunction,
    onSelectAll,
    searchBarClassName = "",
    customTableSearchBar = "",
    disableSearch = false,
    placePagginationToLeft = false,
    placePagginationToBottom = false,
    additionalActionColumn = true,
    searchBarSize = "small",
    additionalStatusColumn = false,
    noDataText = undefined,
    errorText = undefined
}) => {
    const classes = useTableViewStyles();

    const [searchText, setSearchText] = useState("");

    const onSearchChange = (newValue) => {
        setSearchText(newValue);
    };

    const filteredData = useMemo(
        () => (!filterFunction ? data : data.filter((entity) => filterFunction(entity, searchText))),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, searchText]
    );

    return (
        <div className={classes.tableContainer}>
            {!disableSearch && (
                <SearchBar
                    searchPlaceholder={searchPlaceholder}
                    value={searchText}
                    onChange={onSearchChange}
                    textFieldClassName={classes.searchBarWidth}
                    className={searchBarClassName}
                    noBg
                    size={searchBarSize}
                />
            )}
            <CustomTable
                data={filteredData}
                fetchingData={fetchingData}
                columns={columns}
                generateItem={generateItem}
                hideBottomBar={hideBottomBar}
                tableSize={tableSize}
                onSelectAll={onSelectAll}
                className={customTableSearchBar}
                placePagginationToBottom={placePagginationToBottom}
                placePagginationToLeft={placePagginationToLeft}
                additionalActionColumn={additionalActionColumn}
                additionalStatusColumn={additionalStatusColumn}
                noDataText={noDataText}
                errorText={errorText}
            />
        </div>
    );
};

TableView.defaultProps = {
    tableSize: "medium",
    onSelectAll: () => {}
};

export default TableView;
