import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import useIfMounted from "../../../../../../utils/customHooks/useIfMounted";
import { useStyles } from "features/groups/components/GroupEditView/styles";
import { GroupUserWithMembership } from "features/groups/model/GroupUserWithMembership";

type UserMembershipProps = {
    user: GroupUserWithMembership;
    userMembershipChangePromise: (user: GroupUserWithMembership) => Promise<void>;
};

const UserMembership = ({ user, userMembershipChangePromise }: UserMembershipProps) => {
    const classes = useStyles();
    const [updatingMembership, setUpdatingMembership] = useState(false);
    const execIfMounted = useIfMounted();

    const handleUserMembershipChange = () => {
        setUpdatingMembership(true);
        userMembershipChangePromise(user).finally(() => execIfMounted(() => setUpdatingMembership(false)));
    };

    return (
        <div className={classes.resourcePermissionItem}>
            <div className="name" data-testid={userPermissionTestId}>
                {user.username}
            </div>
            <div className="checkbox-container">
                <div className="radio-label">Member</div>
                <Checkbox disabled={updatingMembership} checked={user.isMember} onChange={handleUserMembershipChange} data-testid={checkboxTestId} />
            </div>
        </div>
    );
};

export default UserMembership;

const userPermissionTestId = "qa-user-permission-row";
const checkboxTestId = "qa-user-permission-checkbox";
