import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    menu: {
        maxWidth: 300,
        alignItems: "center",
        justifyContext: "center",
        padding: 8
    },
    searchContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 8,

        "& .MuiFilledInput-input": {
            padding: 8,
            paddingLeft: 8
        }
    },
    searchIcon: {
        width: 16
    },
    optionList: {
        maxHeight: 200,
        overflow: "auto",
        display: "flex",
        flexWrap: "wrap",
        marginTop: 16,
        marginBottom: 4
    },
    optionContainer: {
        display: "flex",
        transition: "0.2s all",
        color: theme.customColors.primaryColor,
        border: "1px solid " + theme.customColors.primaryColor,
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        cursor: "pointer",
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "16px",
        margin: "0px 4px 4px 0px",

        "&.selected": {
            color: "white",
            backgroundColor: theme.customColors.primaryColor,

            "&:hover": {
                filter: "grayscale(0.5)",
                backgroundColor: theme.customColors.primaryColor
            }
        },
        "&:hover": {
            backgroundColor: theme.customColors.appBgColor
        },
        "& svg": {
            width: 16,
            marginRight: 4
        }
    },
    optionLabel: {
        maxWidth: 180,
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    actionButtons: {
        display: "flex"
    },
    clearButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "0.2s all",
        width: 48,
        height: 34,
        color: theme.customColors.primaryColor,
        marginRight: 8,
        borderBottom: "2px solid " + theme.customColors.primaryColor,
        cursor: "pointer",

        "&:hover": {
            backgroundColor: theme.customColors.fieldBackgroundColor
        }
    }
}));
