import { useRouteMatch } from "react-router-dom";
import { panelToggle } from "actions/apps";
import { Route, Switch } from "react-router-dom";

import Map from "components/map/mapComponent";
import AppsListView from "../AppsListView/AppsListView";
import AppEditView from "../appEditView/appEditView";

import MapTools from "components/map/mapTools/MapTools";
import ZoomLevelWidget from "components/map/mapTools/ZoomLevelWidget/ZoomLevelWidget";
import TogglePanelButton from "@mui/icons-material/NavigateNext";
import Header from "components/header/Header";
import HeaderButtons from "components/HeaderButtons/HeaderButtons";
import { getUnseenNotifications } from "selectors/notifications";
import { getIsPanelClosed } from "selectors/apps";
import { useAppSelector } from "store/hooks/useAppSelector";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useRef } from "react";

const AppsRootView = () => {
    const mapRef = useRef(null);

    const unseenNotifications = useAppSelector(getUnseenNotifications);
    const isPanelClosed = useAppSelector(getIsPanelClosed);

    const dispatch = useAppDispatch();

    const match = useRouteMatch();

    return (
        <div className="apps-rootview">
            <div className="apps-rootview__wrapper">
                <div className={`sidebar ${isPanelClosed ? `closed` : ``}`}>
                    <div className="collapse-button" onClick={() => dispatch(panelToggle())} data-testid={collapseButtonTestId}>
                        <TogglePanelButton className={`collapse-button__icon ${!isPanelClosed ? `rotate180` : ``}`} style={{ fontSize: "1.7rem" }}></TogglePanelButton>
                    </div>

                    <Header />

                    <Switch>
                        <Route exact path={`${match.path}/`} component={AppsListView} />
                        <Route path={`${match.path}/edit/:appId`}>
                            <AppEditView mapRef={mapRef} />
                        </Route>
                    </Switch>
                </div>
            </div>

            <div className="map">
                <HeaderButtons unseenNotifications={unseenNotifications} userMenuIsCollapsed />
                <Map mapRef={mapRef}>
                    <MapTools>
                        <ZoomLevelWidget />
                    </MapTools>
                </Map>
            </div>
        </div>
    );
};

export default AppsRootView;

const collapseButtonTestId = "qa-apps-root-view-collapse-button";
