import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    uploadContainer: {
        border: "1px solid" + theme.customColors.borderColor,
        borderRadius: 3,
        height: 160,
        minWidth: 400,
        marginTop: 20
    },
    flexBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    dragging: {
        background: theme.customColors.secondaryColor
    },
    pointerEventNone: {
        pointerEvents: "none"
    },
    buttonSpacing: {
        marginTop: 8
    },
    notVisible: {
        display: "none"
    }
}));
