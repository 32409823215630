import NavigationMenu from "components/NavigationMenu/NavigationMenu";
import GuardedRoute from "components/guardedRoute/GuardedRouteComponent";
import { Redirect, Switch } from "react-router-dom";
import { getNavigationState } from "selectors/navigation";
import { useAppSelector } from "store/hooks/useAppSelector";
import { checkCurrentUserRoles, checkFeatureFlag, firstAllowedRoute, LOCATIONS } from "utils/routeUtils";
import Notifications from "components/notifications/notifications";
import useProgressNotifications from "views/main/hooks/useProgressNotifications";
import { getCurrentUser } from "features/auth/selectors";
import { getFeatureFlags } from "features/featureFlags/selectors";

const MainView = () => {
    const currentUser = useAppSelector(getCurrentUser);
    const isNavigationOpen = useAppSelector(getNavigationState);
    const featureFlags = useAppSelector(getFeatureFlags);

    useProgressNotifications();

    return (
        <>
            <Notifications />
            <div className={`app-wrapper menu-is-${isNavigationOpen ? `open` : `closed`}`}>
                <NavigationMenu />
                <Switch>
                    {LOCATIONS.map((location) => (
                        <GuardedRoute
                            exact={location.exact}
                            path={"/:clientId/:projectId" + location.route}
                            component={location.component}
                            condition={checkCurrentUserRoles(location, currentUser) && checkFeatureFlag(location, featureFlags)}
                            key={location.route}
                        />
                    ))}
                    <Redirect to={"/:clientId/:projectId" + firstAllowedRoute(currentUser?.role ?? null)} />
                </Switch>
            </div>
        </>
    );
};

export default MainView;
