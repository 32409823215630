export const metadataTypes = {
    DATE: "date",
    TEXT: "text",
    NUMBER: "number",
    TAG_LIST: "tag_list"
};

export const editOperations = {
    ADD: "ADD",
    EDIT: "EDIT",
    REMOVE: "REMOVE"
};

//in the future multiple metadata schemas might be supported but we only have one for now
export const METADATA_SCHEMA_ID = 1;
