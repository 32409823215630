export const AddDataDNDJss = (theme) => ({
    depthZeroSpacing: {
        padding: "12px 0",
        margin: "0 20px"
    },

    rootContainer: {
        padding: "4px 0px",
        margin: "0px " + theme.customSpacing.generalSidePadding
    },

    isBeingDragged: {
        opacity: "0.5"
    }
});
