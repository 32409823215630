import { useStyles } from "./styles";
import { FCWC, useState } from "react";
import { Badge, Menu } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import OverflowTip from "components/OverflowTip/OverflowTip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DateRangeIcon from "@mui/icons-material/DateRange";
import clsx from "clsx";

type Props = {
    isActive: boolean;
    label: string;
    type: "select" | "date";
    selectedValuesCount?: number;
};

const FilterDropdownMenu: FCWC<Props> = ({ isActive, label, type, selectedValuesCount, children }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const iconClasses = clsx(classes.filterIcon, isActive ? classes.active : classes.inactive);

    const onFilterClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const onClose = () => setAnchorEl(null);

    const renderIcon = (type: string) => {
        if (type === "select") {
            if (selectedValuesCount && selectedValuesCount !== 0) {
                return <Badge className={classes.badge} badgeContent={selectedValuesCount} />;
            }

            return <FilterAltIcon className={iconClasses} />;
        }

        return <DateRangeIcon className={iconClasses} />;
    };

    return (
        <>
            <div onClick={onFilterClick} className={classes.filterButton} data-testid={`qa-${label.toLocaleLowerCase()}-filter-dropdown`}>
                {renderIcon(type)}
                <div className={classes.label}>
                    <OverflowTip variant="body2">{label}</OverflowTip>
                </div>
                <ArrowDropDownIcon className={isActive ? classes.active : classes.inactive} />
            </div>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                onKeyUp={(e) => e.stopPropagation()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                {children}
            </Menu>
        </>
    );
};

export default FilterDropdownMenu;
