import { FieldArray, useFormikContext } from "formik";

import SingleTagEditor from "../SingleTagEditor/SingleTagEditor";
import TagsCreator from "../TagsCreator/TagsCreator";

const TagsEditor = ({ tags }) => {
    const formikProps = useFormikContext();

    const hasError = (index) => !!formikProps.errors.value && !!formikProps.errors.value[index];

    const getErrorText = (index) => {
        if (!hasError(index)) return "";
        const errors = formikProps.errors.value[index];
        const keys = Object.keys(errors);

        return keys.reduce((prev, current) => prev + "\n" + errors[current], "");
    };

    return (
        <FieldArray
            name="value"
            render={(arrayHelpers) => (
                <>
                    <TagsCreator tags={tags} onAddTag={arrayHelpers.push} />
                    <div className="tag-list">
                        {tags.map((tag, index) => {
                            return (
                                <SingleTagEditor
                                    key={index}
                                    tag={tag}
                                    onEdit={(newTag) => arrayHelpers.replace(index, newTag)}
                                    onDelete={() => arrayHelpers.remove(index)}
                                    error={hasError(index)}
                                    errorHelper={getErrorText(index)}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        ></FieldArray>
    );
};

export default TagsEditor;
