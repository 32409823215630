import { Chip } from "@mui/material";
import { useStyles } from "./styles.js";
import clsx from "clsx";
import { DATA_TYPES } from "../../utils/constants/dataType.js";

const TypeChip = ({ type, variant }) => {
    const classes = useStyles();
    const chipStyles = clsx({
        [classes.typeChip]: true,
        [classes.variantSmall]: variant === "small",
        [classes.variantLarge]: variant === "large",
        [classes.vector]: type === DATA_TYPES.vector,
        [classes.raster]: type === DATA_TYPES.raster
    });

    return <Chip className={chipStyles} label={type.charAt(0).toUpperCase() + type.slice(1)} />;
};

TypeChip.defaultProps = {
    varinat: "small"
};

export default TypeChip;
