import { Backdrop, Dialog } from "@mui/material";
import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import clsx from "clsx";

type Props = {
    imageUrl: string;
    className?: string;
    defaultImage?: any;
};

const PreviewableImage: FC<Props> = ({ className, imageUrl, defaultImage }) => {
    const [openModal, setOpenModal] = useState(false);

    const classes = useStyles();

    const onError = ({ currentTarget }: any) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = defaultImage;
    };

    const onThumbnailClick = (e: any) => {
        preventDefaultStopPropagation(e);
        setOpenModal(true);
    };

    const onModalClose = (e: any) => {
        preventDefaultStopPropagation(e);
        setOpenModal(false);
    };

    const preventDefaultStopPropagation = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };
    return (
        <>
            <img className={clsx(className, classes.preview)} src={imageUrl} onError={onError} alt="" onClick={onThumbnailClick} data-testid={thumbnailTestId} />
            <Dialog
                aria-labelledby="atlas-dialog image-preview-modal"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                open={openModal}
                onClose={onModalClose}
                className={classes.dialog}
            >
                <CloseIcon onClick={onModalClose} className={classes.closeIcon} data-testid={closePreviewTestId} />
                <img src={imageUrl} onError={onError} alt="" onClick={preventDefaultStopPropagation} className={classes.image} data-testid={previewTestId} />
            </Dialog>
        </>
    );
};

export default PreviewableImage;

const thumbnailTestId = "qa-image-thumbnail";
const previewTestId = "qa-image-preview";
const closePreviewTestId = "qa-image-close-preview";
