import { useAppSelector } from "../../../../store/hooks/useAppSelector";
import { getUnseenNotifications } from "../../../../selectors/notifications";
import Header from "../../../../components/header/Header";
import HeaderButtons from "../../../../components/HeaderButtons/HeaderButtons";
import UsersListView from "features/users/components/UsersListView/UsersListView";


const UsersRootView = () => {
    const unseenNotifications = useAppSelector(getUnseenNotifications);

    return (
        <div className="users-rootview">
            <div className="sidebar">
                <div className="header">
                    <Header />
                    <HeaderButtons unseenNotifications={unseenNotifications} />
                </div>
                <UsersListView />
            </div>
        </div>
    );
};

export default UsersRootView;