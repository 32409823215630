import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import { DialogContentText, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Group } from "features/groups/model/Group";
import { GisUser } from "features/users/model/GisUser";
import { FC } from "react";

type Props = {
    users: GisUser[];
    groups: Group[];
};

const PendingUsersConfirmView: FC<Props> = ({ users, groups }) =>
    (
        <>
            <DialogContentText> Add the following users having the following roles </DialogContentText>
            <List>
                {users.map((user) => (
                    <ListItem key={user.coreAccountId} disablePadding>
                        <ListItemButton data-testid={userTestId}>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ noWrap: true }}>
                                {user.username} - {user.role}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {groups.length > 0 && (
                <>
                    <DialogContentText> and the non Admin users to the following groups </DialogContentText>
                    <List>
                        {groups.map((group) => (
                            <ListItem key={group.id} disablePadding>
                                <ListItemButton data-testid={groupTestId}>
                                    <ListItemIcon>
                                        <GroupIcon />
                                    </ListItemIcon>
                                    <ListItemText primaryTypographyProps={{ noWrap: true }}>{group.name}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}
        </>
    );

export default PendingUsersConfirmView;

const groupTestId = "qa-pending-users-modal-group-item";
const userTestId = "qa-pending-users-modal-user-item";
