import { FC, useEffect, useState } from "react";
import HistoryDetailsModal from "../HistoryDetailsModal/HistoryDetailsModal";
import toastr from "components/CustomToastr/CustomToastr";
import { DatasetHistory as DatasetHistoryType } from "features/fidu/models/DatasetHistory";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { deleteDatasetHistoryEntryThunk, fetchDatasetHistoryThunk } from "features/fidu/actions";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getDatasetHistory } from "features/fidu/selectors";
import CustomTypography from "components/CustomTypography/CustomTypography";
import historySortings from "../HistoryRow/historySortings";
import TableView from "components/TableView/TableView";
import HistoryRow from "../HistoryRow/HistoryRow";
import { useStyles } from "./styles";
import { unwrapResult } from "@reduxjs/toolkit";

type Props = {
    datasetId: string;
};

const DatasetHistory: FC<Props> = ({ datasetId }) => {
    const classes = useStyles();
    const [selectedHistory, setSelectedHistory] = useState<DatasetHistoryType | null>(null);
    const [loading, setLoading] = useState(true);

    const history = useAppSelector(getDatasetHistory);

    const dispatch = useAppDispatch();

    useEffect(() => {
        !!datasetId && dispatch(fetchDatasetHistoryThunk(datasetId)).finally(() => setLoading(false));
    }, [datasetId]);

    const onOpenDetailsModal = (history: DatasetHistoryType) => {
        setSelectedHistory(history);
    };

    const onCloseDetailsModal = () => {
        setSelectedHistory(null);
    };

    const onDeleteHistoryEntry = (history: DatasetHistoryType) => {
        const toastrConfirmOptions = {
            onOk: () =>
                dispatch(deleteDatasetHistoryEntryThunk(history))
                    .then(unwrapResult)
                    .then(() => toastr.success("History entry deleted succesfully"))
                    .catch(() => toastr.error("Encounterd an error when deleting the history entry.")),
            onCancel: () => console.log("CANCEL: clicked")
        };
        toastr.confirm("Delete history entry", toastrConfirmOptions);
    };

    const generateItem = (item: DatasetHistoryType, index: number) => {
        return <HistoryRow history={item} key={index} onDelete={onDeleteHistoryEntry} onDetails={onOpenDetailsModal} />;
    };

    const filterItem = (item: DatasetHistoryType, query: string) => item.fileName.toLowerCase().includes(query.toLowerCase());

    return (
        <div className={classes.datasetHistory}>
            <TableView
                searchPlaceholder={"Search by file name"}
                data={history}
                fetchingData={loading}
                columns={historySortings}
                generateItem={generateItem}
                filterFunction={filterItem}
                hideBottomBar
                searchBarClassName={classes.searchBar}
                customTableSearchBar={classes.customTable}
                disableSearch={true}
            />

            {selectedHistory && <HistoryDetailsModal history={selectedHistory} onClose={onCloseDetailsModal} />}
        </div>
    );
};

export default DatasetHistory;
