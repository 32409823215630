import { CoreRole } from "features/users/model/CoreRole";
import { RootState } from "./../../store/models/RootState";

export const getCurrentUser = (state: RootState) => state.auth.user;
export const getIsCurrentUserClientOrSystemAdmin = (state: RootState) =>
    state.auth.user?.coreAccountType === CoreRole.ClientAdmin || state.auth.user?.coreAccountType === CoreRole.SystemAdmin;

export const getIsUserFetching = (state: RootState) => state.auth.fetching;
export const getToken = (state: RootState) => state.auth.token;
export const getCurrentUserRole = (state: RootState) => state.auth.user?.role;
export const getCurrentUserCoreAccountType = (state: RootState) => state.auth.user?.coreAccountType;
