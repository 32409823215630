import csv2Geojson from "csv2geojson";

export default class CsvHandler {
    convertToGeoJson(file, options) {
        return new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = (theFile) => {
                csv2Geojson.csv2geojson(reader.result, options, (err, data) => {
                    resolve(data);
                });
            };
            reader.readAsText(file);
        });
    }
}
