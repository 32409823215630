import React, { useMemo, useState } from "react";
import withStyles from "@mui/styles/withStyles";

import IconButton from "@mui/material/IconButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import Typography from "@mui/material/Typography";

import Layer from "./layer";
import GroupNameInput from "./groupNameInput";

import LayerGroup from "./group";
import { groupJss } from "./jss/groupJss";
import { Checkbox, Tooltip, Divider, Collapse } from "@mui/material";

import { isGroup } from "@emblautec/rescursive-array-extensions";
import { getAppPermissionType, getLayerStyleErrorsMap, getSelectedGroupId } from "../../../../selectors/appData";
import { useDispatch, useSelector } from "react-redux";
import * as appDataActions from "../../../../reducers/appData/appData";
import { toggleGroupLayers } from "../../../../actions/globalActions";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import OverflowTip from "../../../../components/OverflowTip/OverflowTip";
import WarningIcon from "@mui/icons-material/WarningAmber";
import AddDataDND from "./AddDataDND/AddDataDND";
import { addDatasetToAppThunk, addRasterToAppThunk } from "../../../../actions/apps";
import { handleError } from "../../../../utils/networkErrorUtils";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { DATA_TYPES } from "../../../../utils/constants/dataType";
import layerGroupSettings from "../../../../utils/constants/layerGroupSettings";
import isNewDepthValid from "../../../../utils/layerGroups/isNewDepthValid";
import { unwrapResult } from "@reduxjs/toolkit";
import { PermissionType } from "features/groups/model/PermissionType";

const COLLAPSE_TIMEOUT = 200;

function Group({ classes, depth, group, vListGroupTools: { vListIndex, markIndexForRecomputation, recomputeRowHeights } }) {
    const [dragOver, setDragOver] = useState(false);
    const selectedGroupId = useSelector(getSelectedGroupId);
    const layerStyleErrorsMap = useSelector(getLayerStyleErrorsMap);
    const permissionType = useSelector(getAppPermissionType);
    const dispatch = useDispatch();

    const { appId } = useParams();

    const groupContainsLayerWithStyleErrors = useMemo(() => {
        let containsLayerWithErrors = false;
        group.layers.forLayersRecursive((layer) => {
            if (layerStyleErrorsMap[layer.resourceId]) containsLayerWithErrors = true;
        });
        return containsLayerWithErrors;
    }, [layerStyleErrorsMap, group]);

    function onStartsCollapsedChanged() {
        let newOptions = { ...group.options };

        newOptions.collapsed = !newOptions.collapsed;

        dispatch(appDataActions.setResourceOptions({ resourceId: group.resourceId, newOptions }));
    }

    const onToggleAllLayers = (e) => {
        e.stopPropagation();
        const newVisibility = group.totalLayersCount !== group.visibleLayersCount;
        const appLayersMap = {};

        group.layers.forLayersRecursive((layer) => {
            appLayersMap[layer.resourceId] = true;
        });

        dispatch(toggleGroupLayers({ groupId: group.resourceId, newVisibility, appLayersMap }));
    };

    // Remove drop indicator while over drop box
    function onDragOverDropBox(event) {
        event.stopPropagation();
        event.preventDefault();
        setDragOver(true);
    }

    function onDropInsideEmptyGroup(event, asChild) {
        event.preventDefault();
        event.stopPropagation();
        const dropData = JSON.parse(event.dataTransfer.getData("text"));

        if (!isNewDepthValid(depth, dropData.depth, asChild)) {
            toastr.error(`You can't nest groups deeper than ${layerGroupSettings.MAX_DEPTH} levels`);
            return;
        }

        if (dropData.type === "data") {
            onIncludeData(dropData, asChild);
            return;
        }

        markIndexForRecomputation(vListIndex);
        markIndexForRecomputation(dropData.vListIndex);
        dispatch(appDataActions.moveResource({ resourceId: dropData.resourceId, destinationId: group.resourceId, moveAsChild: asChild }));
    }

    const onIncludeData = (dropData, asChild) => {
        const dataset = dropData.data;
        const raster = dropData.data;

        const addToAppThunk = dropData.data.chipType === DATA_TYPES.vector ? addDatasetToAppThunk({ appId, dataset }) : addRasterToAppThunk({ appId, raster });

        dispatch(addToAppThunk)
            .then(unwrapResult)
            .then(() => {
                markIndexForRecomputation(vListIndex);
                markIndexForRecomputation(dropData.vListIndex);
                dispatch(appDataActions.moveResource({ resourceId: dropData.resourceId, destinationId: group.resourceId, moveAsChild: asChild }));
                toastr.success(`${dropData.data.chipType === DATA_TYPES.vector ? "Dataset" : "Raster"} added`);
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const onDragLeave = (event) => {
        event.preventDefault();
        setDragOver(false);
    };

    function onDeleteClick(e, group) {
        e.stopPropagation();
        const toastrConfirmOptions = {
            onOk: () => onDeleteGroup(e, group),
            onCancel: () => {}
        };
        toastr.confirm(`Are you sure you want to delete group: ${group.name}?`, toastrConfirmOptions);
    }

    function onDeleteGroup(e, group) {
        markIndexForRecomputation(vListIndex);
        dispatch(appDataActions.removeGroup(group.resourceId));
    }

    function onToggleOpen(e, group) {
        e.stopPropagation();
        dispatch(appDataActions.toggleGroupIsCollapsed({ groupId: group.resourceId, newIsCollapsedValue: !group.options.isCollapsed }));

        //This makes sure that virtualized list does not recalculate height until the group is collapsed
        setTimeout(
            () => {
                recomputeRowHeights(vListIndex);
            },
            group.options.isCollapsed ? 0 : COLLAPSE_TIMEOUT
        );
    }

    const onGroupSelected = (e, group) => {
        if (!group) {
            dispatch(appDataActions.setSelectedGroupId(null));
            return;
        }

        if (permissionType < PermissionType.WRITE_READ) {
            onToggleOpen(e, group);
            return;
        }

        dispatch(appDataActions.setSelectedGroupId(group.resourceId));
    };

    function onUpdateGroupName(groupName) {
        dispatch(appDataActions.setResourceName({ resourceId: group.resourceId, newName: groupName }));
        onGroupSelected(null);
    }

    function renderGroup(depth, selected) {
        if (depth === 0) {
            if (selected) {
                return (
                    <div className={selected ? "group selected" : "group"} onClick={(e) => onGroupSelected(e, group)}>
                        <GroupNameInput value={group.name} onChange={onUpdateGroupName} />
                    </div>
                );
            }

            return (
                <div className={selected ? "group selected" : "group"} onClick={(e) => onGroupSelected(e, group)}>
                    {groupContainsLayerWithStyleErrors && (
                        <Tooltip title="This group contains a layer with styling errors.">
                            <WarningIcon className={classes.iconSpaceRight} color="error" fontSize="small" />
                        </Tooltip>
                    )}
                    <div className={depth === 0 ? classes.groupName : classes.subGroupName} data-testid={groupSelectedTestId}>
                        <OverflowTip variant="body1" color="inherit" textWeight={depth === 0 && "bold"}>
                            {group.name}
                        </OverflowTip>
                    </div>
                    <IconButton onClick={(e) => onToggleAllLayers(e)} className={classes.squareBtn} size="large" data-testid={toggleGroupLayersButtonTestId}>
                        <Typography className={classes.layerCount}>
                            {group.visibleLayersCount}/{group.totalLayersCount}
                        </Typography>
                    </IconButton>
                    <div className={classes.layerCountSeperator}></div>
                    <IconButton
                        className={classes.deleteBtn}
                        onClick={(e) => onDeleteClick(e, group)}
                        size="large"
                        data-testid={deleteGroupButtonTestId}
                        disabled={permissionType < PermissionType.WRITE_READ}
                    >
                        <DeleteIcon className="icon" fontSize="small" />
                    </IconButton>
                    <Tooltip title={group.options.isCollapsed ? "Expand" : "Collapse"}>
                        <IconButton onClick={(e) => onToggleOpen(e, group)} className={classes.collapseBtn} size="large" data-testid={expandCollapseGroupButtonTestId}>
                            <ExpandLess className={group.options.isCollapsed ? classes.expandedRight : classes.collapsed} />
                        </IconButton>
                    </Tooltip>
                </div>
            );
        } else {
            if (selected) {
                return (
                    <div className={selected ? "sub-group selected" : "sub-group"} onClick={(e) => onGroupSelected(e, group)}>
                        <GroupNameInput value={group.name} onChange={onUpdateGroupName} />
                    </div>
                );
            }

            return (
                <div className={selected ? "sub-group selected" : "sub-group"} onClick={(e) => onGroupSelected(e, group)}>
                    <Tooltip title={group.options.collapsed ? "Expand" : "Collapse"}>
                        <IconButton onClick={(e) => onToggleOpen(e, group)} className={classes.innerCollapseBtn} size="large" data-testid={expandCollapseGroupButtonTestId}>
                            <ExpandLess className={group.options.isCollapsed ? classes.expandedLeft : classes.collapsed} />
                        </IconButton>
                    </Tooltip>
                    {groupContainsLayerWithStyleErrors && (
                        <Tooltip title="This group contains a layer with styling errors.">
                            <WarningIcon className={classes.iconSpaceRight} color="error" fontSize="small" />
                        </Tooltip>
                    )}
                    <OverflowTip variant="body1" color="inherit" className={depth === 0 ? classes.groupName : classes.subGroupName} data-testid={groupSelectedTestId}>
                        {group.name}
                    </OverflowTip>
                    <IconButton onClick={(e) => onToggleAllLayers(e)} className={classes.squareBtn} size="large" data-testid={toggleGroupLayersButtonTestId}>
                        <Typography className={classes.layerCount}>
                            {group.visibleLayersCount}/{group.totalLayersCount}
                        </Typography>
                    </IconButton>
                    <div className={classes.layerCountSeperator}></div>
                    <IconButton
                        className={classes.deleteBtn}
                        onClick={(e) => onDeleteClick(e, group)}
                        size="large"
                        data-testid={deleteGroupButtonTestId}
                        disabled={permissionType < PermissionType.WRITE_READ}
                    >
                        <DeleteIcon className="icon" fontSize="small" />
                    </IconButton>
                </div>
            );
        }
    }

    const selected = selectedGroupId && group.resourceId === selectedGroupId;
    const groupElement = renderGroup(depth, selected);
    const dropAreaStyle = clsx({
        [classes.dropZone]: depth === 0,
        [classes.subDropzone]: depth !== 0,
        [classes.dragOver]: dragOver
    });

    return (
        <AddDataDND
            data={group}
            vListIndex={vListIndex}
            markIndexForRecomputation={markIndexForRecomputation}
            depth={depth}
            isDraggedOver={dragOver}
            disabled={permissionType < PermissionType.WRITE_READ}
        >
            <div className={depth === 0 ? "group-root" : "sub-group-root"}>
                {groupElement}
                {group.layers.length === 0 && (
                    <div
                        onDrop={(e) => onDropInsideEmptyGroup(e, true)}
                        onDragOver={onDragOverDropBox}
                        className={dropAreaStyle}
                        onDragLeave={onDragLeave}
                        data-testid={groupDropInsideTestId}
                    >
                        Drop inside
                    </div>
                )}

                <div className={depth === 0 ? classes.optionsMenu : classes.subOptionsMenu} style={{ height: selected ? 42 : 0 }}>
                    <Checkbox
                        style={{ marginLeft: -2 }}
                        checked={group.options.collapsed}
                        onChange={onStartsCollapsedChanged}
                        inputProps={{ "data-testid": groupStartCollapsedCheckboxTestId }}
                    />
                    <Typography variant="body2">Start collapsed</Typography>
                </div>

                <Collapse in={!group.options.isCollapsed && group.layers.length !== 0} timeout={COLLAPSE_TIMEOUT} unmountOnExit>
                    {depth === 0 && <Divider className={classes.groupSeperator} />}
                    <div className={depth === 0 ? "" : classes.borderBox}>
                        <div className={depth === 0 ? classes.layerContainer : classes.innerLayerContainer}>
                            {group.layers.map((layer) => {
                                return isGroup(layer) ? (
                                    <LayerGroup
                                        group={layer}
                                        depth={depth + 1}
                                        vListGroupTools={{ vListIndex, markIndexForRecomputation, recomputeRowHeights }}
                                        key={layer.resourceId}
                                    />
                                ) : (
                                    <Layer layer={layer} depth={depth + 1} vListLayerTools={{ vListIndex, markIndexForRecomputation }} key={layer.resourceId} />
                                );
                            })}
                        </div>
                    </div>
                </Collapse>
            </div>
        </AddDataDND>
    );
}

export default withStyles(groupJss)(Group);

const groupStartCollapsedCheckboxTestId = "qa-app-edit-view-group-start-collapsed-checkbox";
const groupSelectedTestId = "qa-app-edit-view-group-selected";
const toggleGroupLayersButtonTestId = "qa-app-edit-view-toggle-group-layers-button";
const deleteGroupButtonTestId = "qa-app-edit-view-delete-group-button";
const expandCollapseGroupButtonTestId = "qa-app-edit-view-expand-collapse-group-button";
const groupDropInsideTestId = `qa-drop-inside-layer-group`;
