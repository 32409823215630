import { PermissionType } from "features/groups/model/PermissionType";

export type Permission = {
    name: string;
    value: number;
};

export const permissionTypes = [
    { name: "Owner", value: 4 },
    { name: "Write", value: 3 },
    { name: "Read", value: 2 },
    { name: "Read w/o download", value: 1 },
    { name: "None", value: 0 }
];

export const permissions = {
    OWNER: permissionTypes[0],
    WRITE: permissionTypes[1],
    READ: permissionTypes[2],
    READ_WO_DOWNLOAD: permissionTypes[3],
    NONE: permissionTypes[4]
};

export const resourceTypes = {
    DATASET: 1,
    RASTER: 2,
    APPLICATION: 3,
    MAP: 4
};

export const permisionPrettyNames = {
    [PermissionType.NONE]: "None",
    [PermissionType.READ_WITHOUT_DOWNLOAD]: "Read w/o download",
    [PermissionType.READ]: "Read",
    [PermissionType.WRITE_READ]: "Write",
    [PermissionType.OWNER]: "Owner"
};
