import { STYLE_TYPES } from "../constants/layers/styleTypes";
import { geometryTypeToMapboxType } from "../GeometryUtils";

export const makeMapLayerFromDataset = (dataset, layerId, sourceId, specifiedLayerType) => {
    const layerType = specifiedLayerType || geometryTypeToMapboxType(dataset.geometryType);
    const MAP_MAX_ZOOM = 24;
    const layer = {
        sourceId: sourceId ?? dataset.id,
        layerId,
        resourceId: dataset.id,
        sourceName: dataset.tileName,
        type: layerType,
        minZoom: dataset.minZoom,
        maxZoom: MAP_MAX_ZOOM
    };
    return layer;
};

export const makeMapLayerFromRaster = (raster, layerId, sourceId) => {
    const MAP_MAX_ZOOM = 24;
    const layer = {
        sourceId: sourceId ?? raster.id,
        layerId,
        resourceId: raster.id,
        sourceName: raster.name,
        type: STYLE_TYPES.raster,
        minZoom: raster.minZoom,
        maxZoom: MAP_MAX_ZOOM
    };
    return layer;
};

export const makeMapLayerFromStyle = (style, sourceInfo, metadata) => {
    const { sourceId, sourceName } = sourceInfo;
    const { resourceId, previousStyleId } = metadata;
    const mapLayer = {
        sourceId,
        layerId: style.styleId,
        resourceId,
        sourceName,
        type: style.type,
        minZoom: style.minZoom,
        maxZoom: style.maxZoom,
        drawBefore: previousStyleId
    };
    return mapLayer;
};
