export const groupJss = (theme) => ({
    root: {
        "&:not(:last-child)": {
            marginBottom: 8
        }
    },
    rootContainer: {
        padding: "4px 0px",
        margin: "0px " + theme.customSpacing.generalSidePadding
    },
    group: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 16,
        height: 52,
        backgroundColor: "white",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main
        },
        borderBottom: " 1px #d0c9c9 solid"
    },
    iconSpaceRight: { marginRight: 8 },
    borderBox: {
        borderLeft: "1px solid " + theme.customColors.lightGray,
        marginLeft: 12,
        marginTop: -8
    },
    layerContainer: {
        padding: 16,
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: "white"
    },
    innerLayerContainer: {
        paddingTop: 8,
        marginLeft: 4
    },
    groupName: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        minWidth: 200,
        flexGrow: 1,
        maxWidth: 320
    },
    subGroupName: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        minWidth: 200,
        flexGrow: 1,
        maxWidth: 320
    },
    layerCount: {
        color: theme.palette.primary.main,
        fontSize: 14
    },
    layerCountSeperator: {
        backgroundColor: theme.customColors.appBgColor,
        height: 18,
        width: 1,
        marginRight: 8,
        marginLeft: 8
    },
    inputButtonSperator: {
        backgroundColor: theme.customColors.appBgColor,
        height: 18,
        width: 1,
        marginRight: 8,
        marginLeft: 4
    },
    squareBtn: {
        borderRadius: 0,
        height: 24,
        width: 24,
        marginLeft: 8
    },
    collapseBtn: {
        borderRadius: 0,
        height: 24,
        width: 24,
        marginLeft: 8,
        color: "#000000"
    },
    innerCollapseBtn: {
        borderRadius: 0,
        height: 24,
        width: 24,
        marginRight: 8,
        color: "#000000"
    },
    deleteBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        color: theme.customColors.errorColor,
        backgroundColor: theme.customColors.errorColorLight
    },
    okBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        color: theme.customColors.primaryColor,
        backgroundColor: theme.customColors.secondaryColor
    },
    clearBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        color: theme.customColors.errorColor,
        backgroundColor: theme.customColors.errorColorLight,
        marginRight: 8
    },
    hover: {
        borderTop: "2px solid " + theme.customColors.primaryColor
    },
    actions: {},
    grow: {
        flexGrow: 1,
        marginRight: 8
    },
    groupSeperator: {
        backgroundColor: theme.customColors.lightGray
    },
    dropZone: {
        backgroundColor: theme.customColors.appBgColor,
        color: theme.customColors.gray,
        height: 32,
        paddingLeft: 12,
        fontSize: 14,
        display: "flex",
        alignItems: "center"
    },
    subDropzone: {
        backgroundColor: theme.customColors.appBgColor,
        color: theme.customColors.gray,
        height: 32,
        paddingLeft: 12,
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        marginTop: 8
    },
    optionsMenu: {
        display: "flex",
        alignItems: "center",
        padding: "0px 16px",
        overflow: "hidden",
        transition: "0.2s all",
        borderBottom: "0px solid " + theme.customColors.borderColor,
        transitionTimingFunction: "ease-in-out"
    },
    subOptionsMenu: {
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        marginLeft: "30px",
        transition: "0.2s all"
    },
    groupNameInput: {
        marginRight: 16
    },
    dragIndicatorIcon: {
        marginRight: 8,
        fontSize: 20
    },
    expandedRight: {
        transform: "rotate(-180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandedLeft: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    dragOver: {
        backgroundColor: theme.customColors.secondaryColor
    }
});
