import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from "@mui/icons-material/Settings";
import { LinearProgress } from "@mui/material";
import LayerEditView from "./components/LayerEditView/LayerEditView";
import AppSettingsView from "./components/AppSettingsView";
import { StyledTabs, StyledTab } from "../../../components/CustomTabs/CustomTabs";
import AppEditHeader from "./components/AppEditHeader/AppEditHeader";
import AppEditFooter from "./components/AppEditFooter/AppEditFooter";
import { setSelectedTool } from "../../../reducers/appData/appData";
import {
    getFetching,
    getHasUnsavedChanges,
    getLayerGroups,
    getLayerMovedFlag,
    getLayerStylesMap,
    getSelectedGroupId,
    getSelectedLayer,
    getSelectedTool
} from "../../../selectors/appData";
import VirtualizedLayerGroups from "./components/VirtualizedLayerGroups/VirtualizedLayerGroups";
import { useHistory, useParams } from "react-router-dom";
import { getIsPanelClosed } from "../../../selectors/apps";
import { isGroup } from "@emblautec/rescursive-array-extensions";
import { useAppInit } from "./logic/useAppInit";
import { APP_EDIT_TOOLS } from "../../../utils/constants/appEditTools";
import ActionsSection from "./components/ActionsSection/ActionsSection";
import { addDatasetToAppThunk, addRasterToAppThunk } from "../../../actions/apps";
import toastr from "../../../components/CustomToastr/CustomToastr";
import { handleError } from "../../../utils/networkErrorUtils";
import { DATA_TYPES } from "../../../utils/constants/dataType";
import { unwrapResult } from "@reduxjs/toolkit";
import AddDataTool from "./components/AddDataTool/AddDataTool";
import { getMapSources } from "selectors/map";
import useDidUpdateEffect from "app/hooks/useDidUpdateEffect";
import * as appDataActions from "reducers/appData/appData";

const PAGES = {
    layerGroups: "layerGroups",
    settings: "settings"
};

const AppEditView = (props) => {
    const { appId } = useParams();

    const [page, setPage] = useState(PAGES.layerGroups);
    const [searchText, setSearchText] = useState("");
    const [canDragAndDrop, setCanDragAndDrop] = useState(true);
    const [shouldRecompute, setShouldRecompute] = useState(false);

    const fetching = useSelector(getFetching);
    const layerGroups = useSelector(getLayerGroups);
    const selectedLayer = useSelector(getSelectedLayer);
    const selectedTool = useSelector(getSelectedTool);
    const isPanelClosed = useSelector(getIsPanelClosed);
    const selectedGroupId = useSelector(getSelectedGroupId);
    const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
    const layerMovedFlag = useSelector(getLayerMovedFlag);
    const mapSources = useSelector(getMapSources);
    const layerStyleMap = useSelector(getLayerStylesMap);
    const dispatch = useDispatch();
    const history = useHistory();
    const unblock = useRef();

    const { loading, initApp, layerGroupsToMapLayers } = useAppInit();

    useEffect(() => {
        const clearApp = initApp(appId);
        return () => clearApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isPanelClosed) {
            dispatch(setSelectedTool(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPanelClosed]);

    useDidUpdateEffect(() => {
        layerGroupsToMapLayers(layerGroups, mapSources, layerStyleMap);
    }, [layerMovedFlag]);

    useEffect(() => {
        window.addEventListener("beforeunload", handleWindowClose);

        if (hasUnsavedChanges) {
            unblock.current = history.block((_) => window.confirm(`You have unsaved changes. Are you sure you want to leave this page?`));
        } else {
            unblock.current && unblock.current();
        }

        return () => {
            window.removeEventListener("beforeunload", handleWindowClose);
            unblock.current && unblock.current();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUnsavedChanges]);

    useEffect(() => {
        if (!!groups && searchText !== "") {
            setCollapsedToFalse();
            setShouldRecompute(!shouldRecompute);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const handleWindowClose = (event) => {
        if (!hasUnsavedChanges) return;

        event.preventDefault();
        event.returnValue = "";
    };

    const changeTab = (_, newTab) => {
        setPage(newTab);
        dispatch(setSelectedTool(null));
    };

    const onCloseTool = () => {
        dispatch(setSelectedTool(null));
    };

    const getSearchedLayers = () => {
        const filteredData = layerGroups.filterLayersAndGroupsRecursive((layerOrGroup) => layerOrGroup.name.toLowerCase().includes(searchText.toLowerCase()));
        let cleanData = cleanEmptySubGroups({ layers: filteredData });
        return cleanData.layers;
    };

    const cleanEmptySubGroups = (group) => {
        //This function shouldn't modify the original object
        let mappedData = [];
        group.layers.forEach((layer) => {
            if (isGroup(layer)) {
                const cleanedGroup = cleanEmptySubGroups(layer);
                if (cleanedGroup.layers.length > 0 || cleanedGroup.name.toLowerCase().includes(searchText.toLowerCase())) {
                    mappedData.push(cleanedGroup);
                }
            } else mappedData.push(layer);
        });
        return { ...group, layers: mappedData };
    };

    const setCollapsedToFalse = () => {
        groups.forGroupsRecursive((group) => {
            dispatch(appDataActions.toggleGroupCollapse({ groupId: group.resourceId, newCollapseValue: false }));
        });
    };

    const groups = searchText !== "" ? getSearchedLayers() : layerGroups;

    const onSearchChange = (newValue) => {
        setSearchText(newValue);
    };

    function onDrop(event) {
        event.preventDefault();
        event.stopPropagation();

        const dropData = JSON.parse(event.dataTransfer.getData("text"));

        setCanDragAndDrop(false);
        onIncludeData(dropData.data, dropData.data.chipType);
    }

    const onIncludeData = (data, dataType) => {
        if (dataType === DATA_TYPES.vector) {
            addLayerFromDataset(data);
        } else {
            addLayerFromRaster(data);
        }
    };

    const addLayerFromDataset = (dataset) => {
        dispatch(addDatasetToAppThunk({ appId, dataset }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Dataset added");
            })
            .catch((err) => {
                handleError(err);
            })
            .finally(() => {
                setCanDragAndDrop(true);
            });
    };

    const addLayerFromRaster = (raster) => {
        dispatch(addRasterToAppThunk({ appId, raster }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Raster added");
            })
            .finally(() => {
                setCanDragAndDrop(true);
            });
    };

    const onDragOver = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <div className="sidebar-container edit-app-view">
            {selectedLayer !== null && <LayerEditView />}
            <div className="header">
                <AppEditHeader />
            </div>
            {(fetching || loading) && <LinearProgress className="no-margin-progress" />}
            <div className="content">
                <div className="layer-groups">
                    <StyledTabs value={page} TabIndicatorProps={<div />} onChange={changeTab}>
                        <StyledTab
                            label="Layer Groups"
                            icon={<FormatListBulletedIcon fontSize="small" />}
                            iconPosition="start"
                            value={PAGES.layerGroups}
                            data-testid={layerGroupsTabTestId}
                        />
                        <StyledTab label="Settings" icon={<SettingsIcon fontSize="small" />} iconPosition="start" value={PAGES.settings} data-testid={settingsTabTestId} />
                    </StyledTabs>
                    <div className={page === PAGES.layerGroups ? "list-container" : "hidden"}>
                        <ActionsSection onSearchChange={onSearchChange} searchText={searchText} />
                        <div className="list">
                            <div className="item-container" onDrop={onDrop} onDragOver={onDragOver}>
                                <VirtualizedLayerGroups layerGroups={groups} selectedGroupId={selectedGroupId} shouldRecompute={shouldRecompute} />
                            </div>
                        </div>
                    </div>

                    {page === PAGES.settings && <AppSettingsView mapRef={props.mapRef} />}
                    <div className="bottom-container">
                        <AppEditFooter />
                    </div>
                </div>
            </div>
            {selectedTool === APP_EDIT_TOOLS.addData && (
                <div className="tools">
                    <AddDataTool onClose={onCloseTool} canDragAndDrop={canDragAndDrop} />
                </div>
            )}
        </div>
    );
};

export default AppEditView;

const layerGroupsTabTestId = "qa-app-edit-view-layer-groups-tab";
const settingsTabTestId = "qa-app-edit-view-settings-tab";
