import makeStyles from "@mui/styles/makeStyles";

export const useAppEditHeaderStyles = makeStyles((theme) => ({
    successButton: {
        backgroundColor: theme.customColors.successColor,
        color: "white"
    },
    stylerHeader: {
        padding: "10px 0",
        maxWidth: 424,
        height: theme.customSpacing.generalSidePadding,
        alignItems: "center",
        display: "flex",
        boxSizing: "border-box",
        flexGrow: 1
    },
    backButton: {
        padding: 10,
        margin: "0 0 0 -10px"
    }
}));

export default useAppEditHeaderStyles;
