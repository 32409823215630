import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    filterDropdown: {
        padding: 8
    },
    toggleButton: {
        display: "flex",
        justifyContent: "center",
        lineHeight: "normal",
        borderColor: theme.customColors.primaryColor,
        color: theme.customColors.primaryColor,
        height: 30,
        padding: "4px 8px",
        borderRadius: 16,

        "& svg": {
            width: 16,
            marginRight: 4
        },

        "&.Mui-selected": {
            backgroundColor: theme.customColors.primaryColor,
            color: "white",

            "&:hover": {
                backgroundColor: theme.customColors.primaryColor
            }
        }
    }
}));
