import { FC } from "react";
import { Button } from "@mui/material";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import { getApp } from "selectors/appData";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import { getMapPosition } from "selectors/map";
import { setAppDetails } from "reducers/appData/appData";
import UpdateIcon from "@mui/icons-material/Save";
import { ComponentProps } from "../AppSettingsView";

const MapBoundsSection: FC<ComponentProps> = ({ disabled }) => {
    const app = useAppSelector(getApp);
    const mapPosition = useAppSelector(getMapPosition);

    const dispatch = useAppDispatch();

    const boundsToString = (bounds: any) => {
        return `${bounds[0][0].toFixed(2)} ${bounds[0][1].toFixed(2)}, ${bounds[1][0].toFixed(2)} ${bounds[1][1].toFixed(2)}`;
    };

    const onSetMapPosition = () => {
        dispatch(setAppDetails({ app: { ...app, mapBounds: mapPosition.bounds } }));
    };

    return (
        <>
            <InfoTextSection paddingBottom={2} iconSize="extra-small" textVariant="body2" textWeight="semibold">
                Drag the map until you're satisfied with the new bounds, then click the update button to apply them.
            </InfoTextSection>
            <div className="flex">
                <div className="flex-grow">
                    <ReadOnlyField label={"Bounds"} text={boundsToString(app.mapBounds)} />
                </div>
            </div>
            <Button className="update-button" variant="text" color="primary" disabled={disabled} onClick={onSetMapPosition} data-testid={updateBoundsButtonTestId}>
                <UpdateIcon className="left-icon" fontSize="small" /> Update Bounds
            </Button>
        </>
    );
};

export default MapBoundsSection;

const updateBoundsButtonTestId = "qa-app-edit-view-settings-update-bounds-button";
