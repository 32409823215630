import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    listViewPage: {
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },

    searchContainer: {
        padding: "12px " + theme.customSpacing.generalSidePadding,
        backgroundColor: theme.customColors.appBgColor
    },

    searchIcon: {
        color: theme.customColors.darkGrey,
        width: 20
    },
    searchField: {
        maxWidth: 400,
        "& .MuiFilledInput-input": {
            padding: 0,
            paddingLeft: 8
        },
        "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart": {
            marginTop: 0
        }
    },
    clearTextButton: {
        display: "none"
    },
    visible: {
        display: "flex"
    }
}));
