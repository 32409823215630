import { useState } from "react";

import CacheIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import { TableRow } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import clsx from "clsx";
import dayjs from "dayjs";
import ButtonLink from "../../../../../components/ButtonLink/ButtonLink";
import CheckboxCell from "../../../../../components/CustomTable/components/customCells/CheckboxCell/CheckboxCell";
import MenuButtonCell from "../../../../../components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import OverflowCell from "../../../../../components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import TooltipCell from "../../../../../components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import CustomTypography from "../../../../../components/CustomTypography/CustomTypography";
import { CELL_SIZES } from "../../../../../utils/constants/cellSizes";
import { getGeometryIcon } from "../../../../../utils/datasets/getGeometryIcon";
import * as EnumUtils from "../../../../../utils/enumUtils";
import { fromNowWithCheck } from "../../../../../utils/timeUtils";
import BooleanIndicator from "components/BooleanIndicator/BooleanIndicator";
import { useSelector } from "react-redux";
import { getCurrentUserRole } from "features/auth/selectors";
import { UPLOADER_ROLE } from "utils/accountUtils";
import { DETAILS_TAB, UPLOADER_TAB } from "views/datasets/datasetEditView/datasetEditView";
import CacheStatusChip from "../../../../../components/CacheStatusChip/CacheStatusChip";
import styles from "../../../../../scss/themeVariables.module.scss";
import { useDatasetRowStyles } from "./styles";
import { permisionPrettyNames } from "utils/constants/permissionTypes";
import { PermissionType } from "features/groups/model/PermissionType";

export default function DatasetRow({ dataset, onSelect, selected, ...props }) {
    const classes = useDatasetRowStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const userRole = useSelector(getCurrentUserRole);

    const tab = userRole === UPLOADER_ROLE ? UPLOADER_TAB : DETAILS_TAB;
    const pathname = `datasets/edit/${dataset.id}`;

    const onMenuClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const renderMenuItem = (text, Icon, onClickFunction, testId, hidden = false, disabled = false) => {
        const onClick = (e) => {
            onMenuClose(e);
            onClickFunction();
        };
        return (
            !hidden && (
                <MenuItem disabled={disabled} onClick={onClick} className="menu-button" data-testid={testId}>
                    <Icon className="icon" />
                    <CustomTypography variant="subtitle1" textWeight="semibold">
                        {text}
                    </CustomTypography>
                </MenuItem>
            )
        );
    };

    const renderDatasetMenu = () => (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
            <MenuItem onClick={onMenuClose} className="menu-button">
                <ButtonLink to={{ pathname, state: { tab } }} className={classes.link} data-testid={editButtonTestId}>
                    <EditIcon className="icon" />
                    <CustomTypography variant="subtitle1" textWeight="semibold">
                        Edit
                    </CustomTypography>
                </ButtonLink>
            </MenuItem>
            {renderMenuItem(
                "Generate",
                CacheIcon,
                () => props.onGenerateClick(dataset),
                generateButtonTestId,
                userRole === UPLOADER_ROLE,
                dataset.cacheStatus === 1 || dataset.permissionType < PermissionType.WRITE_READ
            )}
            {renderMenuItem(
                "Replace Data",
                PublishIcon,
                () => props.onReplaceDataset(dataset),
                replaceButtonTestId,
                userRole === UPLOADER_ROLE,
                dataset.permissionType < PermissionType.WRITE_READ
            )}
            {renderMenuItem(
                "Download Data",
                GetAppIcon,
                () => props.onDownloadDataset(dataset),
                downloadButtonTestId,
                userRole === UPLOADER_ROLE,
                dataset.permissionType < PermissionType.READ
            )}
            {renderMenuItem(
                "Delete",
                DeleteIcon,
                () => props.onDeleteClick(dataset),
                deleteButtonTestId,
                userRole === UPLOADER_ROLE,
                dataset.permissionType < PermissionType.OWNER
            )}
        </Menu>
    );

    const cacheStatus = EnumUtils.toCacheStatusString(dataset.cacheStatus);

    return (
        <TableRow component={ButtonLink} to={{ pathname, state: { tab } }} className={clsx(selected && classes.selectedRow)} data-testid={rowButtonTestId}>
            {userRole !== UPLOADER_ROLE && (
                <CheckboxCell checked={selected} cellSize={CELL_SIZES.extraSmall} onChange={onSelect} checkboxInputProps={{ "data-testid": checkboxTestId }} />
            )}

            <OverflowCell>
                <SvgIcon color={"primary"}>{getGeometryIcon(dataset.geometryType)}</SvgIcon>
            </OverflowCell>

            <TooltipCell tooltipTitle={cacheStatus}>
                <CacheStatusChip status={cacheStatus} size={"small"} />
            </TooltipCell>

            <OverflowCell cellSize={CELL_SIZES.small}>{dataset.schemaName?.replace(/_/g, " ")}</OverflowCell>

            <OverflowCell color={styles.primaryColor} className={classes.nameCell}>
                {dataset.name}
            </OverflowCell>

            <OverflowCell>{dataset.tableName}</OverflowCell>

            <OverflowCell cellSize={CELL_SIZES.small}>{dataset.projection}</OverflowCell>

            <OverflowCell cellSize={CELL_SIZES.small}>
                {dataset.minZoom} - {dataset.maxZoom}
            </OverflowCell>

            <OverflowCell cellSize={CELL_SIZES.small}> {dataset.databaseSize} </OverflowCell>

            <OverflowCell cellSize={CELL_SIZES.small}> {dataset.rowCount} </OverflowCell>

            <TooltipCell tooltipTitle={dataset.validMetadata ? "Valid metadata" : "Invalid metadata"}>
                <BooleanIndicator boolValue={dataset.validMetadata} className={classes.icon} />
            </TooltipCell>

            <TooltipCell tooltipTitle={dayjs(dataset.modifiedUtc).format("MMMM DD YYYY, h:mm:ss a")}>{fromNowWithCheck(dayjs(dataset.modifiedUtc))}</TooltipCell>

            <TooltipCell tooltipTitle={dayjs(dataset.createdUtc).format("MMMM DD YYYY, h:mm:ss a")}>{fromNowWithCheck(dayjs(dataset.createdUtc))}</TooltipCell>

            <OverflowCell cellSize={CELL_SIZES.small}> {permisionPrettyNames[dataset.permissionType]} </OverflowCell>

            <MenuButtonCell onMenuClick={onMenuClick} />

            {renderDatasetMenu()}
        </TableRow>
    );
}

const rowButtonTestId = "qa-dataset-row-row-button-link";
const checkboxTestId = "qa-dataset-row-checkbox";
const editButtonTestId = "qa-dataset-row-edit-button";
const generateButtonTestId = "qa-dataset-row-generate-button";
const replaceButtonTestId = "qa-dataset-row-replace-button";
const downloadButtonTestId = "qa-dataset-row-download-button";
const deleteButtonTestId = "qa-dataset-row-delete-button";
