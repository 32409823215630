import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";
import { PermissionType } from "features/groups/model/PermissionType";
import { Raster } from "features/rasters/models/Raster";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import GeotiffUploader from "../../RasterEditView/Modals/GeotiffUploader/GeotiffUploader";
import CPTUploader from "../../RasterEditView/Modals/CPTUploader/CPTUploader";
import GeotiffDownloader from "../../RasterEditView/Modals/GeotiffDownloader/GeotiffDownloader";

type ContextMenuProps = {
    anchorEl: Element | ((element: Element) => Element) | null | undefined;
    onMenuClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onDelete: (raster: Raster) => void;
    raster: Raster;
};

const ContextMenu = ({ anchorEl, onMenuClose, onDelete, raster }: ContextMenuProps) => {
    const [uploadGeotiffOpen, setUploadGeotiffOpen] = useState(false);
    const [uploadCPTOpen, setUploadCPTOpen] = useState(false);
    const [downloadGeotiffOpen, setDownloadGeotiffOpen] = useState(false);

    const featureFlags = useAppSelector(getFeatureFlags);

    const onOpenGeotiffUploader = (e: React.MouseEvent<any>) => {
        setUploadGeotiffOpen(true);
        onMenuClose(e);
    };
    const onOpenCPTUploader = (e: React.MouseEvent<any>) => {
        setUploadCPTOpen(true);
        onMenuClose(e);
    };

    const onCloseGeotiffUploader = () => {
        setUploadGeotiffOpen(false);
    };
    const onCloseCPTUploader = () => {
        setUploadCPTOpen(false);
    };

    const onOpenGeotiffDownloader = (e: React.MouseEvent<any>) => {
        setDownloadGeotiffOpen(true);
        onMenuClose(e);
    };

    const onCloseGeotiffDownloader = () => {
        setDownloadGeotiffOpen(false);
    };

    const onDeleteClick = (e: React.MouseEvent<any>) => {
        onMenuClose(e);
        e.stopPropagation();
        onDelete(raster);
    };

    return (
        <>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onMenuClose}>
                <MenuItem onClick={onOpenGeotiffUploader} className="menu-button" data-testid={uploadButtonTestId} disabled={raster.permissionType < PermissionType.WRITE_READ}>
                    <PublishIcon className="icon" />
                    Upload Geotiff
                </MenuItem>

                {raster.geoTiff && (
                    <MenuItem onClick={onOpenGeotiffDownloader} className="menu-button" data-testid={downloadButtonTestId} disabled={raster.permissionType < PermissionType.READ}>
                        <GetAppIcon className="icon" />
                        Download Geotiff
                    </MenuItem>
                )}

                {featureFlags.CPT && (
                    <MenuItem onClick={onOpenCPTUploader} className="menu-button" data-testid={uploadCptButtonTestId} disabled={raster.permissionType < PermissionType.WRITE_READ}>
                        <PublishIcon className="icon" />
                        Upload Color Palette
                    </MenuItem>
                )}

                <MenuItem onClick={onDeleteClick} className="menu-button" data-testid={deleteButtonTestId} disabled={raster.permissionType < PermissionType.OWNER}>
                    <DeleteIcon className="icon" />
                    Delete
                </MenuItem>
            </Menu>

            <GeotiffUploader open={uploadGeotiffOpen} raster={raster} handleClose={onCloseGeotiffUploader} />
            <CPTUploader open={uploadCPTOpen} raster={raster} handleClose={onCloseCPTUploader} />
            <GeotiffDownloader open={downloadGeotiffOpen} raster={raster} handleClose={onCloseGeotiffDownloader} />
        </>
    );
};

export default ContextMenu;

const uploadButtonTestId = "qa-raster-row-upload-button";
const uploadCptButtonTestId = "qa-raster-edit-view-cpt-upload-button";
const downloadButtonTestId = "qa-raster-row-download-button";
const deleteButtonTestId = "qa-raster-row-delete-button";
