import { createAsyncThunk } from "@reduxjs/toolkit";
import { addAppLayer, removeAppLayer } from "../reducers/appData/appData";
import { addMapLayer, addMapLayout, addMapPaint, addMapSource, addMapZoomRange, removeMapSource, removeResourceMapLayers } from "../reducers/map";
import * as types from "./actionTypes";
import axiosClient from "./apiClient";
import { DatasetAuxObjectsFactory } from "../utils/factories/DatasetAuxObjsFactory";
import { RasterAuxObjectsFactory } from "../utils/factories/RasterAuxObjsFactory";

export const getApps = () => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_APPS });

        return axiosClient
            .get("application")
            .then((res) => {
                return dispatch({ type: types.FETCH_APPS_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const getApp = createAsyncThunk("getApp", async ({ applicationId, cancelToken }, { dispatch }) => {
    return axiosClient
        .get(`application/${applicationId}`, { cancelToken })
        .then((res) => res.data)
        .catch((err) => {
            dispatch({ type: types.APP_ACTION_FAILED, result: err });
            throw err;
        });
});

export const createApp = (request) => {
    return (dispatch) => {
        dispatch({ type: types.CREATE_APP });

        return axiosClient
            .post(`application`, request)
            .then((res) => {
                return dispatch({ type: types.CREATE_APP_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const updateApp = createAsyncThunk("updateApp", ({ applicationId, request }, { rejectWithValue }) => {
    return axiosClient
        .put(`application/${applicationId}`, request)
        .then((res) => res.data)
        .catch((err) => rejectWithValue(err));
});

export const deleteApp = (applicationId) => {
    return (dispatch) => {
        dispatch({ type: types.DELETE_APP });

        return axiosClient
            .delete(`application/${applicationId}`)
            .then(() => {
                return dispatch({ type: types.DELETE_APP_COMPLETE, result: { id: applicationId } });
            })
            .catch((err) => {
                dispatch({ type: types.APP_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const addDatasetToAppThunk = createAsyncThunk("addDatasetToApp", async ({ appId, dataset }, { dispatch, getState, rejectWithValue }) => {
    const styleConfig = getState().style.config;

    const auxFactory = new DatasetAuxObjectsFactory(dataset);

    dispatch(addMapSource(auxFactory.makeSource()));
    dispatch(addMapLayer(auxFactory.makeMapLayer()));

    dispatch(addMapPaint(auxFactory.makePaint(styleConfig)));
    dispatch(addMapLayout(auxFactory.makeLayout(styleConfig)));

    const appLayer = auxFactory.makeAppLayer(dataset.id);
    const style = auxFactory.makeStyle(styleConfig);

    dispatch(addMapZoomRange(auxFactory.makeZoomRange()));
    dispatch(addAppLayer({ newLayer: appLayer, styles: [style] }));
});

export const removeDatasetFromAppThunk = createAsyncThunk("removeDatasetFromApp", async ({ appId, datasetId }, { dispatch, rejectWithValue }) => {
    dispatch(removeResourceMapLayers(datasetId));
    dispatch(removeAppLayer(datasetId));

    return { datasetId };
});

export const addRasterToAppThunk = createAsyncThunk("addRasterToApp", async ({ appId, raster }, { dispatch, getState, rejectWithValue }) => {
    const currentStyleConfig = [...getState().style.config["raster"]];
    const fillColor = { ...currentStyleConfig.find((p) => p.name === "fill-color") };

    if (raster.colorPalette.colorTable.length > 0) {
        fillColor.value = buildFillColor(raster.colorPalette.colorTable);
    }

    const newStyleConfig = currentStyleConfig.map((x) => (x.name === "fill-color" ? fillColor : x));
    const styles = { ...getState().style.config, raster: newStyleConfig };
    const auxFactory = new RasterAuxObjectsFactory(raster);

    dispatch(addMapSource(auxFactory.makeSource()));
    dispatch(addMapLayer(auxFactory.makeMapLayer()));

    dispatch(addMapPaint(auxFactory.makePaint(styles)));
    dispatch(addMapLayout(auxFactory.makeLayout(styles)));
    dispatch(addMapZoomRange(auxFactory.makeZoomRange()));

    const layer = auxFactory.makeAppLayer();
    const style = auxFactory.makeStyle(styles);

    dispatch(addAppLayer({ newLayer: layer, styles: [style] }));
});

export const removeRasterFromAppThunk = createAsyncThunk("removeRasterFromApp", async ({ appId, rasterId }, { dispatch, getState, rejectWithValue }) => {
    dispatch(removeResourceMapLayers(rasterId));
    dispatch(removeAppLayer(rasterId));
});

export const makeAppCopyThunk = createAsyncThunk("makeAppCopy", async ({ appId }, { rejectWithValue }) => {
    return axiosClient
        .post(`application/${appId}/makecopy`)
        .then((res) => res.data)
        .catch((err) => rejectWithValue(err));
});

export function generateCache(appId) {
    return (dispatch) => {
        dispatch({ type: types.GENERATE_APP_CACHE });

        return axiosClient.post("application/" + appId + "/generate").catch((_) => dispatch({ type: types.GENERATE_APP_CACHE_FAILED }));
    };
}

export const panelToggle = () => ({ type: types.PANEL_TOGGLE });

export const addAppBasemapThunk = createAsyncThunk("addAppBaseap", ({ appId, basemapId }) => axiosClient.post(`application/${appId}/basemap/${basemapId}`));

export const deleteAppBasemapThunk = createAsyncThunk("deleteAppBasemap", ({ appId, basemapId }) => axiosClient.delete(`application/${appId}/basemap/${basemapId}`));

export const unpublishAppThunk = createAsyncThunk("unpublishApp", ({ appId }, { rejectWithValue }) => {
    return axiosClient
        .put(`application/${appId}/unpublish`)
        .then((res) => res.data)
        .catch((err) => rejectWithValue(err));
});

export const makeAppPublicThunk = createAsyncThunk("makeAppPublic", ({ appId, ispublic }, { rejectWithValue }) => {
    const route = ispublic ? "makepublic" : "makeprivate";
    return axiosClient
        .post(`application/${appId}/${route}`)
        .then((res) => res.data)
        .catch((err) => rejectWithValue(err));
});

export const addAppSearchBarThunk = createAsyncThunk("addAppSearchBar", ({ appId, datasetId, columnName }, { rejectWithValue }) => {
    return axiosClient
        .post(`application/${appId}/searchbar`, {
            datasetId,
            columnName
        })
        .then((res) => res.data)
        .catch((err) => rejectWithValue(err));
});

export const updateAppSearchBarThunk = createAsyncThunk("updateAppSearchBar", ({ appId, datasetId, columnName }, { rejectWithValue }) => {
    return axiosClient
        .put(`application/${appId}/searchbar`, { datasetId, columnName })
        .then(() => ({
            datasetId,
            columnName
        }))
        .catch((err) => rejectWithValue(err));
});

export const removeAppSearchBarThunk = createAsyncThunk("removeAppSearchBar", ({ appId }, { rejectWithValue }) => {
    return axiosClient.delete(`application/${appId}/searchbar`).catch((err) => rejectWithValue(err));
});

export const setAppThumbnail = (appId, formData) => {
    return axiosClient.post(`application/${appId}/thumbnail`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

const buildFillColor = (colorTable) => {
    const arr = ["match", ["get", null]];

    for (const color of colorTable) {
        arr.push(color.label);
        arr.push(color.color);
    }

    return arr;
};
