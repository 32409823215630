import { Avatar, Drawer, IconButton } from "@mui/material";
import { FC, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import EditRole from "./EditRole/EditRole";
import { useAppSelector } from "store/hooks/useAppSelector";
import UserGroup from "./UserGroup/UserGroup";
import { sortByBoolReverse } from "utils/sortingFunctions";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import { GisRole } from "features/users/model/GisRole";
import SearchWithOptions from "./SearchWithOptions/SearchWithOptions";
import { GroupWithPermissions } from "features/groups/model/GroupWithPermissions";
import { getIsCurrentUserClientOrSystemAdmin } from "features/auth/selectors";
import { CoreRole } from "features/users/model/CoreRole";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import { useFetchUserByEmailQuery } from "../../api";

type Props = {
    onClose: () => void;
    username: string;
    coreAccountType?: string;
};

export enum Resource {
    Applications = "Applications",
    Datasets = "Datasets",
    Rasters = "Rasters",
    Groups = "Groups"
}

const UserGroupsDrawer: FC<Props> = ({ onClose, username, coreAccountType }) => {
    const classes = useStyles();
    const { data: user, isFetching, error, refetch } = useFetchUserByEmailQuery(username);
    const [searchBy, setSearchBy] = useState(Resource.Datasets);
    const [searchValue, setSearchValue] = useState<string>("");
    const isCurrentUserClientOrSystemAdmin = useAppSelector(getIsCurrentUserClientOrSystemAdmin);

    const filterFunction = () => {
        if (searchBy === Resource.Groups) {
            return (x: GroupWithPermissions) => x.name.toLocaleLowerCase().includes(searchValue.toLowerCase());
        }

        return (_: GroupWithPermissions) => true;
    };
    const renderUserGroups = () =>
        !!user ? (
            user.groupsWithPermissions
                .filter(filterFunction())
                .sort((a, b) => sortByBoolReverse(a.isIndividualGroup, b.isIndividualGroup))
                .map((group) => <UserGroup key={group.id} group={group} searchBy={searchBy} username={username} searchValue={searchValue} />)
        ) : (
            <CustomTypography variant="body1">User is not part of any groups</CustomTypography>
        );

    const renderUserGroupsWithPlaceholders = () => {
        if (user?.role === GisRole.Admin) {
            return (
                <CustomTypography marginTop={-1.5} variant="body1">
                    User is an admin and has Owner rights on all resources
                </CustomTypography>
            );
        }

        return (
            <LoadingPlaceholder loading={isFetching} message="Getting user groups" textVariant="h6" spinnerSize={30}>
                <ErrorPlaceholder error={!!error} message="Encountered an error while getting the user groups" textVariant="h6" onTryAgain={() => refetch()}>
                    <SearchWithOptions searchBy={searchBy} searchValue={searchValue} setSearchBy={setSearchBy} setSearchValue={setSearchValue} />
                    {renderUserGroups()}
                </ErrorPlaceholder>
            </LoadingPlaceholder>
        );
    };

    const renderRole = () => {
        if (!isCurrentUserClientOrSystemAdmin) {
            return <CustomTypography variant="body1">{user?.role}</CustomTypography>;
        }

        if (coreAccountType !== CoreRole.ClientUser) {
            return (
                <>
                    <CustomTypography variant="body1">{user?.role}</CustomTypography>
                    <InfoTextSection paddingTop={1.5} iconSize="extra-small" textVariant="body2">
                        Client Admins and System Admins are GIS Admins by default
                    </InfoTextSection>
                </>
            );
        }

        return !!user ? <EditRole user={user} /> : null;
    };
    return (
        <Drawer anchor="right" open onClose={onClose} PaperProps={{ className: classes.drawer }}>
            <div className={classes.header}>
                <CustomTypography variant="h6" textWeight="bold" sx={{ flexGrow: 1 }}>
                    WindGIS User
                </CustomTypography>
                <IconButton onClick={onClose} data-testid={closeButtonTestId}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.content}>
                <div className={classes.username}>
                    <Avatar>
                        <PersonIcon />
                    </Avatar>
                    <CustomTypography variant="h6">{user?.username}</CustomTypography>
                </div>
                <div>
                    <CustomTypography variant="h6" textWeight="bold">
                        Role
                    </CustomTypography>
                    {renderRole()}
                </div>
                <div className={classes.groups}>
                    <CustomTypography variant="h6" textWeight="bold">
                        Groups
                    </CustomTypography>
                    {renderUserGroupsWithPlaceholders()}
                </div>
            </div>
        </Drawer>
    );
};

export default UserGroupsDrawer;

const closeButtonTestId = "qa-user-details-drawer-close-drawer-button";
