import { createSelector } from "reselect";
import { CACHE_STATUSES } from "../utils/constants/cacheStates";
import { createParameterizedSelector, forwardArg } from "./common";

export const getMapSources = (state) => state.map.sources;
export const getMapPosition = (state) => state.map.mapPosition;
export const getMapZoom = (state) => state.map.mapPosition.zoom;

export const getAppCacheStatusSelector = createSelector([getMapSources], (sources) => {
    let cacheStatus = CACHE_STATUSES.uncached;
    const maps = sources.filter((s) => s.isMap);

    // This case covers apps with only rasters
    const appWithNoMaps = maps.length === 0;
    const appIsNotEmpty = sources.length > 0;
    if (appWithNoMaps && appIsNotEmpty) return CACHE_STATUSES.cached;

    const isCachingInProgress = maps.length && maps.some((s) => s.cacheStatus === CACHE_STATUSES.caching);

    if (isCachingInProgress) {
        cacheStatus = CACHE_STATUSES.caching;
    }

    const allMapsCached = maps.length && maps.every((s) => s.cacheStatus === CACHE_STATUSES.cached);
    if (allMapsCached) {
        cacheStatus = CACHE_STATUSES.cached;
    }

    return cacheStatus;
});

export const getSourceMinZoomSelector = createParameterizedSelector([getMapSources, forwardArg], (mapSources, sourceId) => {
    return mapSources.find((source) => source.id === sourceId)?.minZoom;
});
