import { FC, useEffect, useState } from "react";
import CustomModal from "../../../../../../../../../components/CustomModal/CustomModal";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { patchLibraryBasemap, PatchLibraryBasemapArg } from "../../../../../../../../../actions/basemaps";
import { handleUpdateLibraryBasemap } from "../../../../../../../../../reducers/basemaps";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { Basemap } from "models/basemap/Basemap";
import { unwrapResult } from "@reduxjs/toolkit";
import toastr from "components/CustomToastr/CustomToastr";
import { useEditBasemapModalStyles } from "./styles";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getBasemapLoading } from "selectors/basemaps";

type Props = {
    open: boolean;
    handleClose: any;
    basemap: Basemap;
};
const EditBasemapModal: FC<Props> = ({ open, handleClose, basemap }) => {
    const classes = useEditBasemapModalStyles();

    const [basemapTitle, setBasemapTitle] = useState(basemap?.title);
    const [basemapType, setBasemapType] = useState(basemap?.type);
    const [basemapUrl, setBasemapUrl] = useState(basemap?.url);

    const basemapLoading = useAppSelector(getBasemapLoading);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setBasemapTitle(basemap?.title);
        setBasemapType(basemap?.type);
        setBasemapUrl(basemap?.url);
    }, [basemap]);

    if (!open) return null;

    const handleUpdateBasemap = (e: any) => {
        handleClose(e);
        const patchLibraryBasemapArg: PatchLibraryBasemapArg = {
            newBasemap: {
                title: basemapTitle !== basemap.title ? basemapTitle : undefined,
                type: basemapType !== basemap.type ? basemapType : undefined,
                url: basemapUrl !== basemap.url ? basemapUrl : undefined
            },
            basemapId: basemap.id
        };

        dispatch(patchLibraryBasemap(patchLibraryBasemapArg))
            .then(unwrapResult)
            .then((res) => {
                const updatedBasemap: Basemap = {
                    id: basemap.id,
                    title: basemapTitle,
                    type: basemapType,
                    url: basemapUrl,
                    isDefault: basemap.isDefault
                };
                dispatch(handleUpdateLibraryBasemap(updatedBasemap));
            })
            .catch((err) => {
                toastr.error(err.message);
            });
    };

    const onBasemapTitleChange = (e: any) => {
        setBasemapTitle(e.target.value);
    };

    const onBasemapTypeChange = (e: any) => {
        setBasemapType(e.target.value);
    };

    const onBasemapUrlChange = (e: any) => {
        setBasemapUrl(e.target.value);
    };

    return (
        <CustomModal handleClose={handleClose} isOpen={open} dialogTitle={"Edit Basemap"} onConfirm={handleUpdateBasemap} dialogType="update" disabled={basemapLoading}>
            <div className={classes.container}>
                <TextField
                    id="filled-required"
                    label="Name"
                    placeholder="Name of basemap"
                    value={basemapTitle}
                    variant="filled"
                    onChange={onBasemapTitleChange}
                    fullWidth
                    inputProps={{ "data-testid": nameTestId }}
                />
                <TextField
                    id="filled-required"
                    label="Url"
                    placeholder="Basemap url"
                    value={basemapUrl}
                    variant="filled"
                    onChange={onBasemapUrlChange}
                    fullWidth
                    inputProps={{ "data-testid": urlTestId }}
                />

                <FormControl fullWidth>
                    <InputLabel id="select-label">Basemap Type</InputLabel>
                    <Select labelId="select-label" id="select" value={basemapType} label="Basemap Type" onChange={onBasemapTypeChange} data-testid={typeSelectTestId}>
                        <MenuItem value={"vector"} data-testid={vectorTestId}>
                            Vector
                        </MenuItem>
                        <MenuItem value={"raster"} data-testid={rasterTestId}>
                            Raster
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </CustomModal>
    );
};

export default EditBasemapModal;

const nameTestId = "qa-basemap-name-input";
const urlTestId = "qa-basemap-url-input";
const typeSelectTestId = "qa-basemap-type-select";
const rasterTestId = "qa-basemap-raster-type";
const vectorTestId = "qa-basemap-vector-type";
