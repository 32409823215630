import { FC, useState } from "react";
import FileLoader from "../FileLoader/FileLoader";
import { useUploadCPTMutation } from "features/rasters/api";
import { Raster } from "features/rasters/models/Raster";
import CustomModal from "components/CustomModal/CustomModal";
import { handleError } from "utils/networkErrorUtils";
import useComponentCancelToken from "app/hooks/useComponentCancelToken";
import toastr from "components/CustomToastr/CustomToastr";
import { hasCPT } from "components/ColorPaletteEditor/ColorPalette";

type Props = {
    raster: Raster;
    open: boolean;
    handleClose: () => void;
};

const CPTUploader: FC<Props> = ({ open, handleClose, raster }) => {
    const [file, setFile] = useState<File | null>(null);
    const [uploadProgressPercentage, setUploadProgressPercentage] = useState(0);
    const [cancelToken] = useComponentCancelToken();

    const [uploadCPT, { isLoading }] = useUploadCPTMutation();

    const isDoneUploading = uploadProgressPercentage === 100;

    const onCancelPreUpload = () => {
        setFile(null);
        handleClose();
    };

    const onUploadProgress = (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgressPercentage(percentCompleted);
    };

    const onUploadCPT = () => {
        if (isLoading || isDoneUploading) return;
        if (!file) return;

        uploadCPT({
            rasterId: raster.id,
            file: file,
            replaceExisting: hasCPT(raster.colorPalette),
            uploadProgressHandler: onUploadProgress,
            cancelToken: cancelToken.token
        })
            .unwrap()
            .then(() => {
                toastr.success("CPT Uploaded");
            })
            .catch((err: any) => {
                handleError(err);
            })
            .finally(() => {
                handleClose();
            });
    };

    const onFileChanged = (file: any) => {
        setFile(file);
    };

    return (
        <CustomModal handleClose={onCancelPreUpload} isOpen={open} onConfirm={onUploadCPT} dialogTitle={"Upload Color Palette"} dialogType={"upload"} disabled={!file || isLoading}>
            <FileLoader acceptedFiles={[".xml", ".qml"]} changeFile={onFileChanged} fileState={file} uploading={isLoading} uploadProgressPercentage={uploadProgressPercentage} />
        </CustomModal>
    );
};

export default CPTUploader;
