import { createSlice, isAnyOf, isFulfilled, isPending, isRejected, PayloadAction } from "@reduxjs/toolkit";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { resetProjectData } from "actions/globalActions";
import { DatasetHistory } from "./models/DatasetHistory";
import { appendDatasetGeojsonThunk, appendDatasetZipThunk, deleteDatasetHistoryEntryThunk, fetchDatasetHistoryThunk } from "./actions";

type SliceState = {
    fetching: boolean;
    datasetHistory: DatasetHistory[];
};

const initialState: SliceState = {
    fetching: false,
    datasetHistory: []
};

const datasetHistorySlice = createSlice({
    name: "datasetHistory",
    initialState,
    reducers: {
        appendHistory: (state, { payload }: PayloadAction<DatasetHistory>) => {
            state.datasetHistory = [payload, ...state.datasetHistory];
        }
    },
    extraReducers: (builder) => {
        const thunks: [AnyAsyncThunk, ...AnyAsyncThunk[]] = [fetchDatasetHistoryThunk, deleteDatasetHistoryEntryThunk, appendDatasetGeojsonThunk, appendDatasetZipThunk];

        builder
            .addCase(fetchDatasetHistoryThunk.fulfilled, (state, { payload }) => {
                state.datasetHistory = payload;
            })
            .addCase(deleteDatasetHistoryEntryThunk.fulfilled, (state, { payload }) => {
                state.datasetHistory = state.datasetHistory.filter((history) => history.id !== payload.deletedHistoryId);
            })
            .addCase(resetProjectData, () => initialState)
            .addMatcher(isPending(...thunks), (state) => {
                state.fetching = true;
            })
            .addMatcher(isAnyOf(isFulfilled(...thunks), isRejected(...thunks)), (state) => {
                state.fetching = false;
            });
    }
});

export const { appendHistory } = datasetHistorySlice.actions;

export default datasetHistorySlice.reducer;
