import { reducer as toastrReducer } from "react-redux-toastr";
import { datasetsReducer, datasetDetailsReducer } from "../reducers/datasets";
import { appsReducer } from "../reducers/apps";
import notificationReducer from "../reducers/notifications";
import metadataSchemaReducer from "../reducers/metadataSchema";
import authReducer from "../features/auth/slice";
import { uploaderReducer } from "../reducers/uploader";
import { schemasReducer } from "../reducers/schemas";
import { navigationReducer } from "../reducers/navigation";
import appDataReducer from "../reducers/appData/appData";
import mapReducer from "../reducers/map";
import styleReducer from "../reducers/style";
import basemapReducer from "../reducers/basemaps";
import coreReducer from "../features/core/slice";
import healthReducer from "../features/health/slice";
import datasetHistoryReducer from "../features/fidu/slice";
import { combineReducers } from "redux";
import featureFlagsReducer from "features/featureFlags/slice";
import baseAtlasApi from "./baseAtlasApi";
import uploadReducer from "features/upload/slice";
import baseDprApi from "./baseDprApi";

export const rootReducer = combineReducers({
    map: mapReducer,
    appData: appDataReducer,
    datasets: datasetsReducer,
    datasetDetails: datasetDetailsReducer,
    apps: appsReducer,
    notifications: notificationReducer,
    metadataSchema: metadataSchemaReducer,
    schemas: schemasReducer,
    toastr: toastrReducer,
    auth: authReducer,
    nav: navigationReducer,
    style: styleReducer,
    basemaps: basemapReducer,
    uploader: uploaderReducer,
    core: coreReducer,
    health: healthReducer,
    datasetHistory: datasetHistoryReducer,
    featureFlags: featureFlagsReducer,
    [baseAtlasApi.reducerPath]: baseAtlasApi.reducer,
    [baseDprApi.reducerPath]: baseDprApi.reducer,
    upload: uploadReducer
});
