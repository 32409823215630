import { resetProjectData } from "actions/globalActions";
import * as types from "../actions/actionTypes";

const schemasInit = {
    fetching: false,
    schemas: []
};

export function schemasReducer(state = schemasInit, action) {
    switch (action.type) {
        case types.FETCH_SCHEMAS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_SCHEMAS_COMPLETE:
            return {
                ...state,
                fetching: false,
                schemas: action.result
            };
        case types.CREATE_SCHEMA:
            return {
                ...state,
                fetching: true
            };
        case types.CREATE_SCHEMA_COMPLETE:
            return {
                ...state,
                fetching: false,
                schemas: [...state.schemas, action.result]
            };
        case types.DELETE_SCHEMA:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_SCHEMA_COMPLETE:
            return {
                ...state,
                fetching: false,
                schemas: removeFromArray(state.schemas, action.result)
            };
        case types.SCHEMAS_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        case resetProjectData.type:
            return schemasInit;
        default:
            return state;
    }
}

function removeFromArray(array, item) {
    return array.filter((x) => item.localeCompare(x));
}
