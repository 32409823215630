import { Box, Collapse, IconButton, Stack } from "@mui/material";
import CollapsibleCard from "components/CollapsibleCard/CollapsibleCard";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import { DprMilestoneType } from "features/dprIntegration/models/DprMilestoneType";
import { DprProgressMilestone } from "features/dprIntegration/models/DprProgressMilestone";
import { FC, MouseEvent, useEffect, useMemo, useState } from "react";
import { MATCH_ALL_MILESTONES_INFO_TEXT } from "../../utils";
import { TransitionGroup } from "react-transition-group";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStyles } from "./styles";

type Props = {
    milestones: DprProgressMilestone[];
    displayDelete?: boolean;
    onDelete?: (bitPosition: number) => void;
};

const Milestones: FC<Props> = ({ milestones, displayDelete = false, onDelete = () => {} }) => {
    const classes = useStyles();

    const [milestoneSectionOpenLookup, setMilestoneSectionOpenLookup] = useState(
        milestones.reduce((acc, val) => {
            acc[val.bitPosition] = false;
            return acc;
        }, {})
    );

    useEffect(() => {
        setMilestoneSectionOpenLookup(
            milestones.reduce((acc, val) => {
                acc[val.bitPosition] = false;
                return acc;
            }, {})
        );
    }, [milestones]);

    const toggleMilestoneSection = (bitPosition: number) => {
        setMilestoneSectionOpenLookup({ ...milestoneSectionOpenLookup, [bitPosition]: !milestoneSectionOpenLookup[bitPosition] });
    };

    const getOptionsDataTestId = (milestone: DprProgressMilestone) => {
        return optionsButtonTestId + milestone.displayName.toLocaleLowerCase().replaceAll(" ", "-");
    };

    const onDeleteClick = (e: MouseEvent<HTMLButtonElement>, bitPosition: number) => {
        e.stopPropagation();

        onDelete(bitPosition);
    };

    return (
        <TransitionGroup>
            {milestones.map((milestone) => {
                let subprojects = !milestone.subprojects.length
                    ? "*"
                    : milestone.subprojects.reduce((acc, subproject) => {
                          acc += `${subproject.scope}/${subproject.vessel}, `;
                          return acc;
                      }, "");

                if (subprojects !== "*") {
                    subprojects = subprojects.slice(0, -2);
                }

                return (
                    <Collapse key={milestone.displayName + milestone.bitPosition}>
                        <div className="shadow-section">
                            <CollapsibleCard
                                innerPadding={0}
                                onToggleClick={() => toggleMilestoneSection(milestone.bitPosition)}
                                id={milestone.displayName}
                                Component={
                                    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                                        {milestone.displayName}
                                        {displayDelete && (
                                            <IconButton
                                                onClick={(e) => onDeleteClick(e, milestone.bitPosition)}
                                                size="small"
                                                data-testid={getOptionsDataTestId(milestone)}
                                                className={classes.deleteIcon}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Box>
                                }
                                isToggled={milestoneSectionOpenLookup[milestone.bitPosition]}
                                unmountOnExit
                            >
                                <Stack gap={2} mt={2}>
                                    <ReadOnlyField label="Subproject(s)" text={subprojects} tooltip={subprojects === "*"} tooltipTitle={MATCH_ALL_MILESTONES_INFO_TEXT} />

                                    {milestone.milestoneType === DprMilestoneType.StatusTracker && (
                                        <ReadOnlyField label="Status Tracker Name" text={milestone.statusTrackerName ?? "*"} />
                                    )}
                                    <ReadOnlyField label={milestone.milestoneType === DprMilestoneType.StatusTracker ? "Column Group" : "Activity Group"} text={milestone.group} />
                                    <ReadOnlyField label={milestone.milestoneType === DprMilestoneType.StatusTracker ? "Column Name" : "Activity Name"} text={milestone.name} />
                                </Stack>
                            </CollapsibleCard>
                        </div>
                    </Collapse>
                );
            })}
        </TransitionGroup>
    );
};

export default Milestones;

const optionsButtonTestId = "qa-dpr-integration-milestone-collapsible-options-button-";
