import baseAtlasApi from "store/baseAtlasApi";
import { atlasApiTags as tags } from "store/atlasApiTags";
import { GroupPermissions } from "features/groups/model/GroupPermissions";

export const permissionsApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        addResourcePermission: build.mutation<GroupPermissions, GroupPermissions>({
            query: (groupPermissions) => ({
                url: `permission/${groupPermissions.groupId}/${groupPermissions.resourceId}`,
                method: "POST",
                data: { permissionType: groupPermissions.permissionType, resourceType: groupPermissions.resourceType }
            }),
            invalidatesTags: (_0, _1, arg) => [
                { type: tags.groupTags.APPLICATIONS_PERMISSIONS, id: arg.groupId },
                { type: tags.groupTags.DATASETS_PERMISSIONS, id: arg.groupId },
                { type: tags.groupTags.RASTERS_PERMISSIONS, id: arg.groupId }
            ]
        }),
        updateResourcePermission: build.mutation<GroupPermissions, GroupPermissions>({
            query: (groupPermissions) => ({
                url: `permission/${groupPermissions.groupId}/${groupPermissions.resourceId}`,
                method: "PUT",
                data: { permissionType: groupPermissions.permissionType, resourceType: groupPermissions.resourceType }
            }),
            invalidatesTags: (_0, _1, arg) => [
                { type: tags.groupTags.APPLICATIONS_PERMISSIONS, id: arg.groupId },
                { type: tags.groupTags.DATASETS_PERMISSIONS, id: arg.groupId },
                { type: tags.groupTags.RASTERS_PERMISSIONS, id: arg.groupId }
            ]
        }),
        removeResourcePermission: build.mutation<void, GroupPermissions>({
            query: (groupPermissions) => ({
                url: `permission/${groupPermissions.groupId}/${groupPermissions.resourceId}`,
                method: "DELETE",
                data: { resourceType: groupPermissions.resourceType }
            }),
            invalidatesTags: (_0, _1, arg) => [
                { type: tags.groupTags.APPLICATIONS_PERMISSIONS, id: arg.groupId },
                { type: tags.groupTags.DATASETS_PERMISSIONS, id: arg.groupId },
                { type: tags.groupTags.RASTERS_PERMISSIONS, id: arg.groupId }
            ]
        })
    })
});

export const { useAddResourcePermissionMutation, useRemoveResourcePermissionMutation, useUpdateResourcePermissionMutation } = permissionsApi;
