import React, { FC, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useInfoTableStyles } from "./styles/infoTableStyles";
import EnhancedTableHead, { HeadCell } from "./EnhancedTableHead";
import { Grid, IconButtonProps, LinearProgress, Paper, SelectProps } from "@mui/material";
import OverflowTip from "components/OverflowTip/OverflowTip";
import { useFetchDatasetPropertiesQuery, useFetchDatasetRowCountQuery } from "features/datasets/api";
import SearchField from "./SearchField";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";

type Props = {
    datasetId: string;
    open: boolean;
};

const InfoTable: FC<Props> = ({ datasetId, open }) => {
    const classes = useInfoTableStyles();

    const [orderByDescending, setOrderByDescending] = useState(false);
    const [orderBy, setOrderBy] = useState("ogc_fid");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState("");

    const {
        data: { properties: tableData } = { properties: [] },
        isLoading,
        isFetching
    } = useFetchDatasetPropertiesQuery({
        datasetId,
        page,
        rowsPerPage,
        searchText,
        orderBy,
        orderByDescending
    });

    const { data: totalRowCount = 0, isFetching: isCountFetching } = useFetchDatasetRowCountQuery(datasetId);

    const tableRef = useRef();

    const headCells: HeadCell[] =
        Boolean(tableData) && tableData.length > 0
            ? Object.getOwnPropertyNames(tableData[0])
                  .filter((x) => x !== "bounding_box")
                  .map((headCell) => ({
                      id: headCell.toString(),
                      numeric: typeof tableData[0][headCell] === "number",
                      disablePadding: false,
                      label: headCell.toString()
                  }))
            : [];

    const startSearch = (search: string) => {
        setSearchText(search);
        setPage(0);
    };

    const handleRequestSort = (property: string) => {
        const sortOrder = orderBy === property && !orderByDescending;
        setOrderByDescending(sortOrder);
        setOrderBy(property);
        setPage(0);
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        const newRowsPerPage = event?.target.value ?? "10";
        setRowsPerPage(parseInt(newRowsPerPage, 10));
        setPage(0);
    };

    if (!open) return <></>;

    return (
        <Paper className={classes.root}>
            <Grid item xs={12} className={classes.modalHeader}>
                <OverflowTip variant="h6">Attribute table</OverflowTip>
            </Grid>
            <SearchField startSearch={startSearch} searchText={searchText} />
            <LoadingPlaceholder loading={isLoading || isCountFetching} message="Loading data">
                <ErrorPlaceholder error={!tableData?.length} message="Layer data not available on the server." onTryAgain={null}>
                    {isFetching && <LinearProgress variant="indeterminate" />}
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-labelledby="tableTitle" size="small" aria-label="attributes table">
                            <EnhancedTableHead orderByDescending={orderByDescending} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
                            {tableData && (
                                <TableBody>
                                    {tableData.map((row, index) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={`row-${index}`} data-testid={rowTestId}>
                                                {headCells.map((headCell) => (
                                                    <TableCell
                                                        align={typeof row[headCell.id] === "number" ? "right" : "left"}
                                                        className={classes.tableCell}
                                                        ref={tableRef}
                                                        key={headCell.id}
                                                    >
                                                        <OverflowTip variant="body1">{row[headCell.id]}</OverflowTip>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={totalRowCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        className={classes.tablePagination}
                        backIconButtonProps={
                            {
                                "data-testid": backIconButtonTestId,
                                disabled: isFetching || page <= 0
                            } as DataTestIdProps
                        }
                        nextIconButtonProps={
                            {
                                "data-testid": nextIconButtonTestId,
                                disabled: isFetching || (page + 1) * rowsPerPage >= totalRowCount
                            } as DataTestIdProps
                        }
                        SelectProps={
                            {
                                "data-testid": rowsPerPageSelectTestId
                            } as DataTestIdSelectProps
                        }
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </ErrorPlaceholder>
            </LoadingPlaceholder>
        </Paper>
    );
};

export default InfoTable;

const backIconButtonTestId = "qa-attribute-table-pagination-back-icon-button";
const nextIconButtonTestId = "qa-attribute-table-pagination-next-icon-button";
const rowsPerPageSelectTestId = "qa-attribute-table-pagination-rows-per-page-select";

const rowTestId = "qa-attribute-table-row";

type DataTestIdProps = IconButtonProps & {
    "data-testid"?: string;
};

type DataTestIdSelectProps = SelectProps<unknown> & {
    "data-testid"?: string;
};
