import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import CustomTypography from "../../../../../../../components/CustomTypography/CustomTypography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@mui/material";
import { setSelectedTool } from "../../../../../../../reducers/appData/appData";
import CloseIcon from "@mui/icons-material/Close";
import InfoTextSection from "../../../../../../../components/InfoTextSection/InfoTextSection";
import SearchBar from "../../../../../../../components/SearchBar/SearchBar";
import { fetchLibraryBasemaps } from "../../../../../../../actions/basemaps";
import BasemapCard from "./components/BasemapCard/BasemapCard";
import SortIcon from "@mui/icons-material/Sort";
import FilterMenu from "./components/FilterMenu/FilterMenu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FILTER_OPTION } from "./constants/filterOptions";
import { useCheckVerticalOverflow } from "../../../../../../../utils/customHooks/useCheckVerticalOverflow";
import { getBasemapLoading, getLibraryBasemaps } from "../../../../../../../selectors/basemaps";
import { getAppBasemaps } from "../../../../../../../selectors/appData";
import UploadBasemapModal from "../../../../../../../features/settings/components/UploadBasemapModal/UploadBasemapModal";
import { useFetchRastersQuery } from "../../../../../../../features/rasters/api";

const AddBasemapTool = () => {
    const classes = useStyles();
    const [filterBy, setFilterBy] = useState(FILTER_OPTION.all);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [appBasemapsIds, setAppBasemapsIds] = useState({});
    const basemaps = useSelector(getLibraryBasemaps);
    const appBasemaps = useSelector(getAppBasemaps);

    const dispatch = useDispatch();

    const basemapsListRef = useRef();
    useEffect(() => {
        dispatch(fetchLibraryBasemaps());
    }, []);

    useEffect(() => {
        setAppBasemapsIds(getAppBasemapsIds());
    }, [appBasemaps]);

    const filterFunction = (item, query, filterBy) => {
        const searchfilter = item.title.toLowerCase().includes(query.toLowerCase());
        if (filterBy === FILTER_OPTION.all) return item.title.toLowerCase().includes(query.toLowerCase());
        return searchfilter && item.type === filterBy.toLowerCase();
    };

    const filteredData = useMemo(
        () => basemaps.filter((basemap) => filterFunction(basemap, searchText, filterBy)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [basemaps, searchText, filterBy]
    );

    const basemapsListOverflows = useCheckVerticalOverflow(basemapsListRef);

    const filterMenuOpen = Boolean(anchorEl);

    const onFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onFilterMenuClose = () => {
        setAnchorEl(null);
    };

    const onSearchChange = (newValue) => {
        setSearchText(newValue);
    };

    const onClickAddBasemap = () => {
        setOpenUploadModal(true);
    };
    const onCloseTool = () => {
        dispatch(setSelectedTool(null));
    };

    const getAppBasemapsIds = () => {
        const appBasemapsIds = {};
        appBasemaps.forEach((basemap) => {
            appBasemapsIds[basemap.id] = true;
        });

        return appBasemapsIds;
    };

    return (
        <div className={classes.addBasemapTool}>
            <div className={classes.header}>
                <CustomTypography variant="subtitle1" textWeight="bold">
                    Add Basemap
                </CustomTypography>
                <div onClick={onCloseTool} className={classes.closeButton} data-testid={closeButtonTestId}>
                    <CloseIcon />
                </div>
            </div>
            <div className={classes.searchSection}>
                <SearchBar searchPlaceholder="Search basemaps" value={searchText} onChange={onSearchChange} textFieldClassName={classes.searchBarWidth} noBg />
            </div>
            <InfoTextSection paddingX={2.5} paddingY={1.4} iconSize="extra-small" textVariant="body2" textWeight="semibold">
                Select Basemaps to add to your application
            </InfoTextSection>
            <div className={classes.sorting}>
                <SortIcon className={classes.sortinIcon} fontSize="small" color="primary" />
                <div className={classes.sortingDropdown} onClick={onFilterClick} data-testid={filterBasemapsTestId}>
                    <CustomTypography variant="body2" color="primary" textWeight="semibold">
                        Filter By: {filterBy}
                    </CustomTypography>
                    {filterMenuOpen ? <ArrowDropUpIcon color="primary" /> : <ArrowDropDownIcon color="primary" />}
                </div>

                <FilterMenu open={filterMenuOpen} onClose={onFilterMenuClose} anchor={anchorEl} setFilterBy={setFilterBy} filterBy={filterBy} />
            </div>
            <div className={classes.basemapsList} ref={basemapsListRef}>
                {filteredData.map((basemap) => (
                    <BasemapCard key={basemap.url} basemap={basemap} basemapAdded={appBasemapsIds[basemap.id]}></BasemapCard>
                ))}
            </div>
            <IconButton className={classes.addButton} onClick={onClickAddBasemap} data-testid={addBasemapTestId}>
                <AddCircleIcon color="primary" className={classes.addIcon} />
            </IconButton>
            {basemapsListOverflows && <div className={classes.bottomBar}></div>}
            <UploadBasemapModal dialogOpen={openUploadModal} setDialogOpen={setOpenUploadModal} />
        </div>
    );
};

export default AddBasemapTool;

const filterBasemapsTestId = "qa-app-edit-view-settings-add-basemap-tool-filter-basemaps";
const addBasemapTestId = "qa-app-edit-view-settings-add-basemap-tool-add-basemap";
const closeButtonTestId = "qa-app-edit-view-settings-add-basemap-tool-close-button";
