import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import CustomTypography from "../CustomTypography/CustomTypography";
import { useStyles } from "./style.js";
import StaticMouseTooltip from "../StaticMouseTooltip/StaticMouseTooltip";
import clsx from "clsx";

const OverflowTip = (props) => {
    const { variant, textWeight, color, className, staticMouseTooltip, rootElemClassName, ...otherProps } = props;

    const [isOverflowed, setIsOverflowed] = useState(true);
    const textElement = React.createRef();
    const classes = useStyles();

    useEffect(() => {
        const { current } = textElement;
        // This was added to improve performance by preventing forced reflow
        let t = setTimeout(() => setIsOverflowed(current.scrollWidth > current.clientWidth), 1);

        return () => {
            clearTimeout(t);
        };
    }, [textElement]);

    const TooltipType = staticMouseTooltip ? StaticMouseTooltip : Tooltip;

    return (
        <div className={clsx(rootElemClassName, classes.textDiv)}>
            {isOverflowed ? (
                <TooltipType title={<span className={classes.multipleLine}>{props.children}</span>}>
                    <div>
                        <CustomTypography ref={textElement} variant={variant} textWeight={textWeight} color={color} className={className} noWrap>
                            {props.children}
                        </CustomTypography>
                    </div>
                </TooltipType>
            ) : (
                <CustomTypography ref={textElement} variant={variant} textWeight={textWeight} color={color} className={className} {...otherProps}>
                    {props.children}
                </CustomTypography>
            )}
        </div>
    );
};

OverflowTip.defaultProps = {
    textWeight: "inherit",
    color: "inherit",
    noWrap: false
};

export default OverflowTip;
