import CustomTypography from "components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import FlagIcon from "@mui/icons-material/Flag";
import { Button } from "@mui/material";
import { FC } from "react";
import DprIntegrationDescription from "../DprIntegrationDescription/DprIntegrationDescription";

type Props = {
    isLoading: boolean;
    onConfigureClick: () => void;
};

const ConfigureIntegrationCta: FC<Props> = ({ isLoading, onConfigureClick }) => {
    const classes = useStyles();
    return (
        <>
            <DprIntegrationDescription />
            <div className={classes.configure}>
                <FlagIcon className={classes.configureIcon} />
                <CustomTypography variant="body1" className={classes.configureDescription}>
                    No integration configured yet
                </CustomTypography>
                <Button variant="contained" size="large" data-testid={configureButtonTestId} disabled={isLoading} onClick={onConfigureClick} className={classes.configureButton}>
                    Configure DPR Integration
                </Button>
            </div>
        </>
    );
};

export default ConfigureIntegrationCta;

const configureButtonTestId = "qa-dpr-integration-configure-button";
