import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Dayjs } from "dayjs";
import { FC } from "react";
import { DatePickerView } from "./types/DatePickerView";

type Props = {
    value: Dayjs;
    onChange: (value: Dayjs) => void;
    name?: string;
    views?: DatePickerView[];
    minDate?: Dayjs;
    maxDate?: Dayjs;
};

const CustomDatePicker: FC<Props> = ({ value, onChange, name, views = ["year", "month", "day"], minDate, maxDate }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
                views={views}
                displayStaticWrapperAs="desktop"
                onChange={(val, _) => onChange(val as Dayjs)}
                value={value}
                minDate={minDate}
                maxDate={maxDate}
                componentsProps={{
                    leftArrowButton: { "data-testid": getLeftArrowTestId(name) },
                    rightArrowButton: { "data-testid": getRightArrowTestId(name) }
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};

export default CustomDatePicker;

const getLeftArrowTestId = (name?: string) => (name ? `qa-${name}-date-picker-left-arrow` : "qa-date-picker-left-arrow");
const getRightArrowTestId = (name?: string) => (name ? `qa-${name}-date-picker-right-arrow` : "qa-date-picker-right-arrow");
