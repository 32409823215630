import { createParameterizedSelector, forwardArg } from "selectors/common";
import { RootState } from "store/models/RootState";
import { StatusType } from "./models/StatusType";
import { createSelector } from "reselect";
import { CoreModule } from "./models/CoreModule";

export const getActiveCoreClients = (state: RootState) => state.core.coreClients.filter((x) => x.status === StatusType.Active);
export const getCoreClientsAndProjectsFetching = (state: RootState) => state.core.fetchingClientsAndProjects;
export const getClientId = (state: RootState) => state.core.clientId;
export const getProjectId = (state: RootState) => state.core.projectId;
export const getModuleId = (state: RootState) => state.core.moduleId;
export const getIdsRetreived = (state: RootState) => !!state.core.clientId && !!state.core.projectId;
export const getModules = (state: RootState) => state.core.modules;
export const getCoreAccountPermissionsFetching = (state: RootState) => state.core.fetchingAccountPermissions;
export const getCoreAccountPermissions = (state: RootState) => state.core.accountPermissions;

export const getClientByIdSelector = createSelector([getActiveCoreClients, (_, id: string) => id], (clients, id) => clients.find((c) => c.id === id));

export const getClientById = (id: string) => (state: RootState) => getClientByIdSelector(state, id);

export const getModuleByName = createParameterizedSelector([getModules, forwardArg], (modules: CoreModule[], name: string) => {
    return modules.find((x) => x.name.toLocaleLowerCase() === name.toLocaleLowerCase());
});
