import { useStyles } from "./styles";

type StatusBadgeProps = {
    status: "published" | "unpublished";
};

const StatusBadge = (props: StatusBadgeProps) => {
    const classes = useStyles(props);
    return (
        <div className={classes.statusBadgeContainer}>
            <div className={classes.statusBadge} />
        </div>
    );
};

export default StatusBadge;
