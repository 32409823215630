import { FC } from "react";
import { useStyles } from "./styles";
import { ResourceWithPermission } from "features/groups/model/ResourceWithPermission";
import { permisionPrettyNames } from "utils/constants/permissionTypes";
import { Divider } from "@mui/material";
import CustomTypography from "components/CustomTypography/CustomTypography";
import OverflowTip from "components/OverflowTip/OverflowTip";

type Props = {
    resourcesWithPermissions?: ResourceWithPermission[];
    searching: boolean;
};

const GroupWithPermissions: FC<Props> = ({ resourcesWithPermissions, searching }) => {
    const classes = useStyles();

    const heightStyle = searching ? "auto" : "300px";

    return (
        <div className={classes.resources} style={{ height: heightStyle }}>
            {!!resourcesWithPermissions && !!resourcesWithPermissions.length ? (
                resourcesWithPermissions.map((resource, index) => (
                    <div key={resource.id}>
                        <div className={classes.resource}>
                            <OverflowTip variant="body1" textWeight="semibold" rootElemClassName={classes.name}>
                                {resource.name}
                            </OverflowTip>
                            <CustomTypography variant="body1" textWeight="semibold" textAlign="end">
                                {permisionPrettyNames[resource.permissionType]}
                            </CustomTypography>
                        </div>
                        {index !== resourcesWithPermissions.length - 1 && <Divider className={classes.divider} />}
                    </div>
                ))
            ) : (
                <CustomTypography variant="body1" textWeight="semibold" className={classes.noPermision}>
                    No permissions
                </CustomTypography>
            )}
        </div>
    );
};

export default GroupWithPermissions;
