import AddIcon from "@mui/icons-material/AddCircle";
import { Button, Checkbox, LinearProgress } from "@mui/material";
import { deleteDataset, generateCache, getDatasetColumns } from "actions/datasets";
import { useFilteredData } from "components/CustomTable/hooks/useFilteredData";
import useRegisterDateRangeFilter from "components/CustomTable/hooks/useRegisterDateRangeFilter";
import useRegisterSelectFilter, { defaultDisplayNameGenerator } from "components/CustomTable/hooks/useRegisterSelectFilter";
import useRegisterBooleanFilter from "components/CustomTable/hooks/useRegisterBooleanFilter";
import toastr from "components/CustomToastr/CustomToastr";
import CustomTypography from "components/CustomTypography/CustomTypography";
import DataReplacerModal from "components/DataReplacer/DataReplacerModal/DataReplacerModal";
import Downloader from "components/downloader/downloader";
import TableView from "components/TableView/TableView";
import StorageIcon from "@mui/icons-material/Storage";
import PublicIcon from "@mui/icons-material/Public";
import { useEffect, useMemo, useState } from "react";
import { getDatasets, getDatasetsFetching } from "selectors/datasets";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { handleError } from "utils/networkErrorUtils";
import * as datasetsActions from "../../../actions/datasets";
import DatasetRow from "./components/DatasetRow/DatasetRow";
import datasetsSortings from "./components/datasetsSorting";
import MetadataModals from "./components/MetadataModals/MetadataModals";
import { geometryTypesOptions, metadataOptions, permissionOptions } from "./constants";
import { useStyles } from "./styles";
import { getCurrentUserRole } from "features/auth/selectors";
import { GisRole } from "features/users/model/GisRole";
import Header from "components/header/Header";
import HeaderButtons from "components/HeaderButtons/HeaderButtons";
import { useSelector } from "react-redux";
import { getUnseenNotifications } from "selectors/notifications";
import UploaderModal from "features/upload/components/UploaderModal/UploaderModal";
import SearchBar from "components/SearchBar/SearchBar";

const DatasetsListView = () => {
    const classes = useStyles();

    const [selectedDatasetIds, setSelectedDatasetIds] = useState(new Set<string>());
    const [targetDataset, setTargetDataset] = useState<any | null>(null);
    const [isDownloaderOpen, setIsDownloaderOpen] = useState(false);
    const [isDataReplacerOpen, setIsDataReplacerOpen] = useState(false);
    const [isMetadataModalOpen, setIsMetadataModalOpen] = useState(false);
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const fetchedDatasets: any = useAppSelector(getDatasets);
    const datasets = useFilteredData(fetchedDatasets);
    const datasetsFetching = useAppSelector(getDatasetsFetching);
    const userRole = useAppSelector(getCurrentUserRole);
    const dispatch = useAppDispatch();

    const unseenNotifications = useSelector(getUnseenNotifications);
    const datasetFilterFunction = (dataset: any, query: string) => dataset.name.toLowerCase().includes(query.toLowerCase());

    const filteredDatasets = useMemo(
        () => datasets.filter((dataset) => datasetFilterFunction(dataset, searchText)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [datasets, searchText]
    );

    useEffect(() => {
        dispatch(datasetsActions.getDatasets(userRole === GisRole.Uploader ? 3 : 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useRegisterSelectFilter("Geometry", "geometryType", false, fetchedDatasets, null, geometryTypesOptions, defaultDisplayNameGenerator, (elem, selectedOptions) => {
        if (elem === "POINT" || elem === "MULTIPOINT") {
            return selectedOptions.includes("POINT(S)");
        }
        if (elem === "LINESTRING" || elem === "MULTILINESTRING") {
            return selectedOptions.includes("LINESTRING(S)");
        }
        if (elem === "POLYGON" || elem === "MULTIPOLYGON") {
            return selectedOptions.includes("POLYGON(S)");
        }
        return false;
    });
    useRegisterSelectFilter("Schema", "schemaName", true, fetchedDatasets, <StorageIcon />);
    useRegisterSelectFilter("CRS", "projection", true, fetchedDatasets, <PublicIcon />);
    useRegisterBooleanFilter("Metadata", "validMetadata", metadataOptions);
    useRegisterDateRangeFilter("Modified", "modifiedUtc");
    useRegisterDateRangeFilter("Created", "createdUtc");
    useRegisterSelectFilter("Permission", "permissionType", true, fetchedDatasets, null, permissionOptions);

    const onDatasetGenerateClick = (dataset: any) => {
        dispatch(generateCache(dataset.id))
            .then(() => toastr.success(`Caching has been queued for ${dataset.name} - Zoom Level ${dataset.minZoom} - ${dataset.maxZoom}`))
            .catch((error) => handleError(error));
    };

    const onDatasetDeleteClick = (dataset: any) => {
        const toastrConfirmOptions = {
            onOk: () => onDatasetDelete(dataset),
            onCancel: () => {}
        };

        toastr.confirm(`Are you sure you want to delete dataset: \n${dataset.name}?`, toastrConfirmOptions);
    };

    const onDatasetDelete = (dataset: any) =>
        dispatch(deleteDataset(dataset.id))
            .then(() => toastr.success("Dataset deleted"))
            .catch((error) => handleError(error));

    const onDatasetDownloadClick = (dataset: any) => {
        setIsDownloaderOpen(true);
        setTargetDataset(dataset);
    };

    const onDatasetReplaceClick = (dataset: any) =>
        dispatch(getDatasetColumns(dataset.id)).then((res) => {
            setTargetDataset(dataset);
            setIsDataReplacerOpen(true);
        });

    const onDatasetSelectClick = (datasetId: string) => {
        const isSelected = selectedDatasetIds.has(datasetId);
        const newSelectedDatasetIds = new Set(selectedDatasetIds);

        if (!isSelected) {
            newSelectedDatasetIds.add(datasetId);
        } else {
            newSelectedDatasetIds.delete(datasetId);
        }

        setSelectedDatasetIds(newSelectedDatasetIds);
    };

    const onSelectAllClick = () => {
        if (filteredDatasets.length === selectedDatasetIds.size) setSelectedDatasetIds(new Set());
        else setSelectedDatasetIds(new Set(filteredDatasets.map((dataset: any) => dataset.id)));
    };

    const datasetToTableRow = (dataset: any) => {
        return (
            <DatasetRow
                key={dataset.id}
                dataset={dataset}
                onGenerateClick={onDatasetGenerateClick}
                onDeleteClick={onDatasetDeleteClick}
                onReplaceDataset={onDatasetReplaceClick}
                onDownloadDataset={onDatasetDownloadClick}
                onSelect={() => onDatasetSelectClick(dataset.id)}
                selected={selectedDatasetIds.has(dataset.id)}
            />
        );
    };

    const sortings =
        userRole === GisRole.Uploader
            ? datasetsSortings
            : [
                  {
                      type: "checkbox",
                      name: "Select all",
                      component: (
                          <Checkbox
                              checked={filteredDatasets.length === selectedDatasetIds.size}
                              onChange={(e) => onSelectAllClick()}
                              // @ts-ignore
                              inputProps={{ "data-testid": selectAllTestId }}
                          />
                      )
                  },
                  ...datasetsSortings
              ];

    return (
        <>
            <div className="datasets-list-container">
                <div className="sidebar">
                    <div className="header">
                        <Header />
                        <HeaderButtons unseenNotifications={unseenNotifications} userMenuIsCollapsed={false} />
                    </div>
                    <div className="sidebar-container datasets-overview">
                        <div className="header">
                            <CustomTypography variant="h2" className={classes.title} data-testid={headerTestId}>
                                Vector Datasets
                            </CustomTypography>
                            {userRole !== GisRole.Uploader && (
                                <Button color="primary" variant="contained" onClick={() => setIsUploaderOpen(true)} data-testid={addDatasetButtonTestId}>
                                    <AddIcon fontSize="small" className={classes.icon} />
                                    Add Dataset
                                </Button>
                            )}
                        </div>
                        {datasetsFetching && <LinearProgress className="no-margin-progress" />}
                        <div className={classes.tableContainer}>
                            {/* We don't use the searchbar from TableView beacuse we need to use the searched data for bulk operations */}
                            <SearchBar
                                searchPlaceholder={"Search Vector Datasets by Name or Database Name"}
                                value={searchText}
                                onChange={(newValue: string) => setSearchText(newValue)}
                                textFieldClassName={classes.searchBarWidth}
                                noBg
                            />
                            <TableView
                                disableSearch
                                searchPlaceholder=""
                                data={filteredDatasets}
                                fetchingData={datasetsFetching}
                                columns={sortings}
                                generateItem={datasetToTableRow}
                                filterFunction={null}
                                hideBottomBar
                            />
                            {selectedDatasetIds.size !== 0 && userRole !== GisRole.Uploader && (
                                <div className={classes.contextActions}>
                                    <Button onClick={() => setIsMetadataModalOpen(true)} color="primary" variant="contained" className={classes.contextButton}>
                                        Update Metadata
                                    </Button>
                                    <Button variant="text" color="primary" className={classes.contextButton} onClick={() => setSelectedDatasetIds(new Set())}>
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <DataReplacerModal open={isDataReplacerOpen} datasetId={targetDataset?.id} handleClose={() => setIsDataReplacerOpen(false)} />
                <MetadataModals
                    open={isMetadataModalOpen}
                    onClose={() => setIsMetadataModalOpen(false)}
                    selectedDatasets={filteredDatasets?.filter((dataset: any) => selectedDatasetIds.has(dataset.id)) ?? []}
                />
                {targetDataset && <Downloader open={isDownloaderOpen} dataset={targetDataset} handleClose={() => setIsDownloaderOpen(false)} />}
                <UploaderModal open={isUploaderOpen} onClose={() => setIsUploaderOpen(false)} />
            </div>
        </>
    );
};

export default DatasetsListView;

const addDatasetButtonTestId = "qa-dataset-list-view-add-dataset-button";
const selectAllTestId = "qa-dataset-list-view-table-head-select-all";
const headerTestId = "qa-header";
