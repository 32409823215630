import { createTheme, adaptV4Theme } from "@mui/material";
import { lighten, darken } from "@mui/material/styles";
import CheckedIcon from "@mui/icons-material/CheckCircleOutline";

import styles from "../scss/themeVariables.module.scss";

const PRIMARY_COLOR = styles.primaryColor;
const SECONDARY_COLOR = styles.secondaryColor;
const APP_BG = styles.appBgColor;
const APP_BG_LIGHT = styles.appBgColorLight;
const APP_BG_DARK = styles.appBgColorDark;
const SUCCESS_COLOR = styles.successColor;
const SUCCESS_COLOR_LIGHT = styles.successColorLight;
const WARNING_COLOR = styles.warningColor;
const WARNING_COLOR_LIGHT = styles.warningColorLight;
const ERROR_COLOR = styles.errorColor;
const ERROR_COLOR_LIGHT = styles.errorColorLight;
const INFO_COLOR = styles.infoColor;
const APP_TEXT_COLOR = styles.appTextColor;
const APP_TEXT_COLOR_LIGHT = styles.appTextColorLight;
const TEXT_COLOR_WHITE = styles.whiteText;
const MUTED_COLOR = styles.mutedColor;
const WHITE = styles.white;
const LIGHT_GRAY = styles.lightGray;
const GRAY = styles.gray;
const DARK_GRAY = styles.darkGrey;
const BLACK = styles.black;
const FIELD_BG = styles.fieldBackgroundColor;
const BORDER_COLOR = styles.borderColor;
const CONTEXT_BUTTON_COLOR = styles.contextButtonColor;
const PRIMARY3 = styles.primary3;

const HEADER_HEIGHT = styles.headerHeight;
const MODAL_HEADER_HEIGHT = styles.modalHeaderHeight;
const GENERAL_SIDE_PADDING = styles.generalSidePadding;
const SIDE_PANEL_TOP_BOTTOM_PADDING = styles.sidePanelTopBottomPadding;
const TOOL_SIDE_DRAWER_WEIGHT = styles.toolSideDrawerWeight;
const SIDE_NAV_COLLAPSE_WIDTH = styles.sidenavCollapsedWidth;
const SIDE_NAV_PINNED_WIDTH = styles.sidenavPinnedWidth;
const DATASETS_BOTTOM_SECTION_HEIGHT = styles.datasetsBottomSectionHeight;

let theme = {
    customColors: {
        primaryColor: PRIMARY_COLOR,
        secondaryColor: SECONDARY_COLOR,
        appBgColor: APP_BG,
        appBgColorLight: APP_BG_LIGHT,
        appBgColorDark: APP_BG_DARK,
        appTextColor: APP_TEXT_COLOR,
        appTextColorLight: APP_TEXT_COLOR_LIGHT,
        whiteText: TEXT_COLOR_WHITE,
        successColor: SUCCESS_COLOR,
        successColorLight: SUCCESS_COLOR_LIGHT,
        warningColor: WARNING_COLOR,
        warningColorLight: WARNING_COLOR_LIGHT,
        errorColor: ERROR_COLOR,
        errorColorLight: ERROR_COLOR_LIGHT,
        infoColor: INFO_COLOR,
        white: WHITE,
        gray: GRAY,
        lightGray: LIGHT_GRAY,
        darkGrey: DARK_GRAY,
        black: BLACK,
        borderColor: BORDER_COLOR,
        fieldBackgroundColor: FIELD_BG,
        contextButtonColor: CONTEXT_BUTTON_COLOR,
        mutedColor: MUTED_COLOR,
        primary3: PRIMARY3
    },
    customSpacing: {
        generalSidePadding: GENERAL_SIDE_PADDING,
        sidePanelTopBottomPadding: SIDE_PANEL_TOP_BOTTOM_PADDING,
        headerHeight: HEADER_HEIGHT,
        modalHeaderHeight: MODAL_HEADER_HEIGHT,
        toolSideDrawerWeight: TOOL_SIDE_DRAWER_WEIGHT,
        sidenavCollapsedWidth: SIDE_NAV_COLLAPSE_WIDTH,
        sidenavPinnedWidth: SIDE_NAV_PINNED_WIDTH,
        datasetsBottomSectionHeight: DATASETS_BOTTOM_SECTION_HEIGHT
    },
    typography: {
        useNextVariants: true,
        fontFamily: ['"Nunito Sans"', "-apple-system", "BlinkMacSystemFont", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
        h1: {
            fontSize: 24,
            lineHeight: "36px"
        },
        h2: {
            fontSize: 20,
            lineHeight: "30px"
        },
        h3: {
            fontSize: 18,
            lineHeight: "26px"
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: "24px"
        },
        body1: {
            fontSize: 16,
            lineHeight: "22px"
        },
        body2: {
            fontSize: 14,
            lineHeight: "20px"
        },
        caption: {
            fontSize: 12,
            lineHeight: "18px"
        }
    },
    palette: {
        mode: "light",
        background: { main: APP_BG },
        secondaryBackground: {
            main: APP_BG_LIGHT
        },
        primary: {
            main: PRIMARY_COLOR,
            light: lighten(PRIMARY_COLOR, 0.2),
            dark: darken(PRIMARY_COLOR, 0.2)
        },
        secondary: {
            main: SECONDARY_COLOR,
            light: lighten(SECONDARY_COLOR, 0.2),
            dark: darken(SECONDARY_COLOR, 0.2)
        },
        success: {
            main: SUCCESS_COLOR,
            light: lighten(SUCCESS_COLOR, 0.2),
            dark: darken(SUCCESS_COLOR, 0.2)
        },
        text: {
            main: APP_TEXT_COLOR
        },
        info: {
            main: PRIMARY_COLOR
        },
        warning: {
            main: WARNING_COLOR
        },
        error: {
            main: ERROR_COLOR
        }
    },
    overrides: {
        MuiCardContent: {
            root: {
                "&:last-child": {
                    paddingBottom: 0
                }
            }
        },
        MuiSelect: {
            root: {
                width: "100%"
            },
            select: {
                color: APP_TEXT_COLOR,
                width: "100%"
            },
            icon: {
                color: APP_TEXT_COLOR
            }
        },
        MuiTableCell: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
                height: 52
            },
            body: {
                color: APP_TEXT_COLOR
            },
            sizeSmall: {
                padding: "0 8px"
            }
        },
        MuiTableRow: {
            root: {
                cursor: "pointer"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: APP_TEXT_COLOR
            }
        },
        MuiTablePagination: {
            root: {
                color: APP_TEXT_COLOR
            },
            actions: {
                color: APP_TEXT_COLOR
            }
        },
        MuiFormControlLabel: {
            label: {
                color: APP_TEXT_COLOR
            }
        },
        MuiInput: {
            root: {
                color: APP_TEXT_COLOR
            },
            underline: {
                "&:before": {
                    borderBottom: GRAY + " 1px solid"
                },
                "&:hover:before": {
                    borderBottom: LIGHT_GRAY + " 1px solid !important"
                },
                "&:after": {
                    borderBottom: PRIMARY_COLOR + " 2px solid"
                }
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: FIELD_BG,
                height: 56
            },
            sizeSmall: {
                height: 40
            },
            input: {
                fontSize: 14
            }
        },
        MuiFormLabel: {
            root: {
                color: APP_TEXT_COLOR,
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: PRIMARY_COLOR
                }
            }
        },
        MuiFormControl: {
            root: {
                color: "gray"
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: APP_BG
            }
        },
        MuiPickersModal: {
            withAdditionalAction: {
                backgroundColor: APP_BG
            }
        },
        MuiPopover: {
            paper: {
                maxHeight: "90vh",

                "@media (min-height: 900px)": {
                    maxHeight: "70vh"
                }
            }
        },
        MuiMenuItem: {
            root: {
                color: APP_TEXT_COLOR,
                "&.Mui-selected": { color: PRIMARY_COLOR, background: "transparent" }
            }
        },
        MuiButton: {
            root: {
                padding: "8px 10px",
                minHeight: 36,
                lineHeight: 0,
                fontSize: 16,
                textTransform: "capitalize"
            },
            text: {
                padding: "8px 10px",
                fontWeight: 700
            },
            containedPrimary: {
                fontWeight: 600,

                "&:hover": {
                    backgroundColor: lighten(PRIMARY_COLOR, 0.2)
                },
                "&.Mui-disabled": {
                    backgroundColor: lighten(PRIMARY_COLOR, 0.5),
                    color: WHITE
                }
            },
            containedSecondary: {
                color: PRIMARY_COLOR,
                backgroundColor: SECONDARY_COLOR,
                fontWeight: 700,

                "&:hover": {
                    backgroundColor: lighten(SECONDARY_COLOR, 0.2)
                },
                "&.Mui-disabled": {
                    backgroundColor: lighten(SECONDARY_COLOR, 0.5),
                    color: lighten(PRIMARY_COLOR, 0.5)
                }
            },

            sizeSmall: {
                fontSize: 14
            }
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.07)"
                }
            }
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: "transparent"
            }
        },
        MuiMenu: {
            list: {
                padding: 0
            },
            "menu-button": {
                padding: 8,
                fontWeight: 600
            }
        },
        MuiAppBar: {
            root: {
                boxShadow: "none",
                height: HEADER_HEIGHT,
                minHeight: HEADER_HEIGHT
            }
        },
        MuiToolbar: {
            root: {
                padding: "0 20px !important",
                height: HEADER_HEIGHT,
                minHeight: HEADER_HEIGHT + " !important",
                borderBottom: `1px solid ${BORDER_COLOR}`
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
                maxWidth: 500,
                padding: 10,
                backgroundColor: MUTED_COLOR
            },
            tooltipPlacementBottom: {
                margin: "6px 0px 0px 0px !important"
            },
            tooltipPlacementTop: {
                margin: "4px 0px 0px 0px !important"
            }
        },
        MuiTab: {
            wrapper: {
                color: APP_TEXT_COLOR
            },
            root: {
                backgroundColor: APP_BG_LIGHT,
                textTransform: "capitalize",
                fontSize: "14px",
                lineHeight: "20px"
            }
        },
        MuiDialog: {
            paper: {
                minWidth: 400,
                borderRadius: 8
            }
        },
        MuiDialogTitle: {
            root: {
                padding: GENERAL_SIDE_PADDING,
                "& .MuiFormControl-root": {
                    marginTop: 6,
                    marginBottom: 6,
                    borderRadius: 5
                }
            }
        },
        MuiDialogContent: {
            dividers: {
                padding: GENERAL_SIDE_PADDING
            }
        },
        MuiDialogActions: {
            root: {
                padding: GENERAL_SIDE_PADDING,
                justifyContent: "flex-start"
            }
        },
        MuiChip: {
            label: {
                paddingLeft: 8,
                paddingRight: 8
            }
        },

        MuiRadio: {
            root: {
                background: "transparent",
                "&:hover": { background: "transparent", color: PRIMARY_COLOR },

                "&.Mui-checked": {
                    background: `radial-gradient(${SECONDARY_COLOR} 30%,transparent 0,transparent)`,

                    "&:hover": {
                        color: lighten(PRIMARY_COLOR, 0.2),
                        background: `radial-gradient(${lighten(SECONDARY_COLOR, 0.2)} 30%,transparent 0,transparent)`
                    },
                    "&.Mui-disabled": {
                        background: `radial-gradient(${lighten(MUTED_COLOR, 0.8)} 30%,transparent 0,transparent)`
                    }
                },

                "&.Mui-disabled": {
                    cursor: "not-allowed"
                }
            }
        },
        MuiCheckbox: {
            root: {
                color: MUTED_COLOR,
                padding: 0,

                "&:hover": { background: "transparent", color: PRIMARY_COLOR },

                "&.Mui-checked": {
                    "&:hover": {
                        outlineColor: lighten(PRIMARY_COLOR, 0.2),
                        background: `radial-gradient(${lighten(PRIMARY_COLOR, 0.2)} 50%,transparent 0,transparent)`
                    }
                }
            },
            colorSecondary: {
                borderRadius: 5,
                outlineOffset: -5,

                "&.Mui-checked": {
                    outline: `2px solid ${PRIMARY_COLOR}`,
                    background: `radial-gradient(${PRIMARY_COLOR} 50%,transparent 0,transparent)`,
                    color: SECONDARY_COLOR,

                    "&.Mui-disabled": {
                        background: `radial-gradient(${lighten(MUTED_COLOR, 0.5)} 50%,transparent 0,transparent)`
                    }
                },

                "&.Mui-disabled": {
                    outline: `2px solid ${lighten(MUTED_COLOR, 0.5)}`,
                    color: FIELD_BG,
                    cursor: "not-allowed"
                }
            }
        }
    },
    props: {
        MuiButton: {
            disableElevation: true,
            TouchRippleProps: {
                style: { color: WHITE }
            }
        },
        MuiIconButton: {
            TouchRippleProps: {
                style: { color: WHITE }
            }
        },
        MuiTooltip: {
            arrow: true,
            disableInteractive: true
        },
        MuiMenu: {
            disableAutoFocusItem: true
        },

        MuiRadio: {
            checkedIcon: <CheckedIcon />,
            disableRipple: true
        },
        MuiCheckbox: {
            color: "secondary"
        },
        MuiDialog: {
            root: {
                maxWidth: "sm"
            }
        },
        // For the table related components, the component:"div" prop was added so that the Link component can be used as a child of TableRow component
        // In the future these might not be needed and can be removed
        MuiTable: {
            component: "div"
        },
        MuiTableBody: {
            component: "div"
        },
        MuiTableRow: {
            component: "div"
        },
        MuiTableCell: {
            component: "div"
        },
        MuiTableHead: {
            component: "div"
        }
    }
};

export default createTheme(adaptV4Theme(theme));
