import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { KeyboardBackspace, Palette, Settings } from "@mui/icons-material";
import { validate } from "@emblautec/mapbox-gl/dist/style-spec/index.es";
import { StyledTab, StyledTabs } from "../../../../../components/CustomTabs/CustomTabs";
import LayerStyler from "../LayerStyler/LayerStyler";
import OverflowTip from "../../../../../components/OverflowTip/OverflowTip";
import LayerRasterEditView from "./../../components/layerRasterEditView";
import LayerSettings from "./../../components/LayerSettings";
import * as appDataActions from "../../../../../reducers/appData/appData";

import { getSelectedLayer, getStylesOfLayer } from "../../../../../selectors/appData";
import { buildValidationStyleFromLayerStyles } from "../../../../../utils/styleValidation/buildValidationStyleFromLayerStyles";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import { DATA_TYPES } from "../../../../../utils/constants/dataType";
import { generateStyleErrorsMap } from "../../../../../utils/generateStyleErrorsMap";
import { useLayerEditViewStyles } from "./styles";

const STYLE_TAB = "style";
const SETTINGS_TAB = "settings";

const LayerEditView = () => {
    const [tab, setTab] = useState(STYLE_TAB);
    const classes = useLayerEditViewStyles();

    const selectedLayer = useSelector(getSelectedLayer);
    const layerStyles = useSelector(getStylesOfLayer(selectedLayer.resourceId));

    const dispatch = useDispatch();

    const renderLayerEditView = (selectedLayer) => {
        return selectedLayer.type === DATA_TYPES.raster ? <LayerRasterEditView /> : <LayerSettings />;
    };

    const onBack = () => {
        const errors = getStylesErrors();
        const layerHasStylingErrors = errors.length !== 0;
        const styleErrorsMap = generateStyleErrorsMap(
            errors,
            layerStyles.map((ls) => ({ layerId: ls.styleId, resourceId: selectedLayer.resourceId }))
        )[selectedLayer.resourceId];

        if (layerHasStylingErrors) {
            const toastrConfirmOptions = {
                onOk: () => clearEditView(styleErrorsMap),
                onCancel: () => {}
            };
            toastr.confirm("Some styles seem to still contain some errors. Keep in mind that you won't be able to save the app until these are fixed. ", toastrConfirmOptions);
        } else {
            clearEditView();
        }
    };

    const getStylesErrors = () => {
        const styleForValidation = buildValidationStyleFromLayerStyles(layerStyles);
        const errors = validate(styleForValidation);
        return errors;
    };

    const clearEditView = (erronousStyleIdsMap) => {
        dispatch(appDataActions.changeLayerStylesErrorStatus({ layerId: selectedLayer.resourceId, erronousStyleIdsMap }));
        dispatch(appDataActions.deselectResources());
    };

    return (
        <div className="edit-view-container">
            <div className={classes.stylerHeader}>
                <IconButton onClick={onBack} style={{ paddingLeft: "-12px" }} size="large">
                    <KeyboardBackspace className={classes.backIcon} />
                </IconButton>
                <OverflowTip variant="h2">{selectedLayer.name}</OverflowTip>
            </div>
            <div className={`"tabs " ${classes.stylerTabs}`}>
                <StyledTabs value={tab} TabIndicatorProps={<div />}>
                    <StyledTab label={<div>Style</div>} icon={<Palette />} iconPosition="start" onClick={() => setTab(STYLE_TAB)} value={STYLE_TAB} />
                    <StyledTab label={<div>Settings</div>} icon={<Settings />} iconPosition="start" onClick={() => setTab(SETTINGS_TAB)} value={SETTINGS_TAB} />
                </StyledTabs>
            </div>
            {tab === STYLE_TAB && (
                <div className={classes.layerStyler}>
                    <LayerStyler />
                </div>
            )}
            {tab === SETTINGS_TAB && <div>{renderLayerEditView(selectedLayer)}</div>}
        </div>
    );
};

export default LayerEditView;
