import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => {
    return {
        marginBottom: {
            marginBottom: theme.spacing(2)
        },
        icon: {
            width: 24,
            marginRight: 10
        },
        title: {
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            color: theme.customColors.darkGrey
        }
    };
});
