export const CELL_SIZES = {
    extraSmall: "extra-small",
    small: "small",
    medium: "medium",
    large: "large",
    extraLarge: "extraLarge"
};

const cellSizes = {
    [CELL_SIZES.extraSmall]: {
        xs: 20,
        sm: 25,
        md: 25,
        lg: 50,
        xl: 50
    },
    [CELL_SIZES.small]: {
        xs: 75,
        sm: 100,
        md: 125,
        lg: 150,
        xl: 150
    },
    [CELL_SIZES.medium]: {
        xs: 125,
        sm: 150,
        md: 175,
        lg: 225,
        xl: 275
    },
    [CELL_SIZES.large]: {
        xs: 175,
        sm: 225,
        md: 300,
        lg: 350,
        xl: 400
    },
    [CELL_SIZES.extraLarge]: {
        xs: 275,
        sm: 325,
        md: 400,
        lg: 500,
        xl: 750
    }
};

export const getCellSizeWidth = (cellSize, breakpoint) => {
    return cellSizes[cellSize][breakpoint];
};
