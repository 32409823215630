import { makeStyles } from "@mui/styles";

export const useDatasetsTableStyles = makeStyles((theme: any) => ({
    searchBar: {
        flexGrow: 1
    },
    tableContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        height: `calc(100vh - ${theme.customSpacing.headerHeight})`
    },
    marginLeft: {
        marginLeft: 4
    },
    infoIcon: {
        marginRight: 8,
        fontSize: 16
    },
    infoColor: {
        opacity: 0.5
    },
    errorInfoColor: {
        color: theme.palette.error.main,
        opacity: 0.8
    },
    countText: {
        fontSize: 17
    },
    searchContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    infoContainer: {
        display: "flex",
        alignItems: "center",
        padding: `0 ${theme.customSpacing.generalSidePadding}`
    },
    list: {
        padding: `0 ${theme.customSpacing.generalSidePadding}`,
        overflow: "auto",
        height: "100%"
    },
    divider: {
        margin: `${theme.customSpacing.generalSidePadding} ${theme.customSpacing.generalSidePadding} 0`
    }
}));
