import { makeStyles } from "@mui/styles";

export const useBooleanIndicatorStyles = makeStyles((theme: any) => ({
    icon: {
        width: 24
    },
    isTrue: {
        background: `radial-gradient(${theme.customColors.successColor} 50%,transparent 0,transparent)`,
        color: theme.customColors.successColorLight
    },
    isFalse: {
        background: `radial-gradient(${theme.customColors.errorColor} 50%,transparent 0,transparent)`,
        color: theme.customColors.errorColorLight
    }
}));
