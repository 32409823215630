import { FC, useEffect, useState } from "react";

import { useAppDispatch } from "../../../../store/hooks/useAppDispatch";
import { useAppSelector } from "../../../../store/hooks/useAppSelector";
import { getLibraryBasemaps, getBasemapLoading } from "../../../../selectors/basemaps";
import { basemapsColumns } from "./basemapsColumns";
import { fetchLibraryBasemaps } from "../../../../actions/basemaps";
import { Basemap } from "../../../../models/basemap/Basemap";
import BasemapRow from "./BasemapRow";
import TableView from "../../../../components/TableView/TableView";
import EditBasemapModal from "views/apps/appEditView/components/BasemapPicker/components/AddBasemapTool/components/EditBasemapModal/EditBasemapModal";

const EditBasemapsView: FC = () => {
    const [editModalBasemap, setEditModalBasemap] = useState<Basemap | null>(null);

    const basemaps = useAppSelector(getLibraryBasemaps);
    const loading = useAppSelector(getBasemapLoading);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchLibraryBasemaps());
    }, []);

    const generateItem = (basemap: Basemap, index: number) => {
        return <BasemapRow basemap={basemap} key={index} onEditOpen={onEditOpen} />;
    };

    const filterItem = (item: Basemap, query: string) => item.title.toLowerCase().includes(query.toLowerCase());

    const onEditClose = () => {
        setEditModalBasemap(null);
    };

    const onEditOpen = (basemap: Basemap) => {
        setEditModalBasemap(basemap);
    };

    return (
        <>
            <TableView
                searchPlaceholder={"Search basemaps"}
                data={basemaps}
                fetchingData={loading}
                columns={basemapsColumns}
                generateItem={generateItem}
                filterFunction={filterItem}
                hideBottomBar
                tableSize={undefined}
                onSelectAll={undefined}
            />
            <EditBasemapModal open={!!editModalBasemap} handleClose={onEditClose} basemap={editModalBasemap as Basemap} />
        </>
    );
};

export default EditBasemapsView;
