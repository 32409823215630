import { Grid } from "@mui/material";
import React, { Children } from "react";
import { useMapToolsStyles } from "./styles";

const MapTools = ({ children, direction = "row", alignItems = "center", justifyContent = "flex-end" }) => {
    const classes = useMapToolsStyles();
    const arrayChildren = Children.toArray(children);
    return (
        <Grid container direction={direction} alignItems={alignItems} justifyContent={justifyContent} spacing={1} className={classes.root}>
            {arrayChildren?.map((child) => (
                <Grid item key={child.key}>
                    {child}
                </Grid>
            ))}
        </Grid>
    );
};

export default MapTools;
