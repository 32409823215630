import CustomTypography from "components/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import FlagIcon from "@mui/icons-material/Flag";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FC, useMemo, useState } from "react";
import SectionHeader from "../SectionHeader/SectionHeader";
import LocationAndStatus from "./LocationAndStatus/LocationAndStatus";
import { DprLocationMapping } from "features/dprIntegration/models/DprLocationMapping";
import Milestones from "./Milestones/Milestones";
import { DprProgressMilestone } from "features/dprIntegration/models/DprProgressMilestone";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddMilestoneModal from "../AddMilestoneModal/AddMilestoneModal";

type Props = {
    statusColumnId: number | null;
    setStatusColumnId: (statusColumnId: number | null) => void;
    locationColumnId: number | null;
    setLocationColumnId: (locationColumnId: number | null) => void;
    locationMappings: DprLocationMapping[];
    setLocationMappings: (locationMappings: DprLocationMapping[]) => void;
    milestones: DprProgressMilestone[];
    setMilestones: (milestones: DprProgressMilestone[]) => void;
};

const ConfigureIntegration: FC<Props> = ({
    statusColumnId,
    setStatusColumnId,
    locationColumnId,
    setLocationColumnId,
    locationMappings,
    setLocationMappings,
    milestones,
    setMilestones
}) => {
    const classes = useStyles();
    const [addMilestoneModalOpen, setAddMilestoneModalOpen] = useState(false);

    const nextBitPosition = useMemo(() => {
        if (!milestones.length) return 0;

        return Math.max(...milestones.map((x) => x.bitPosition)) + 1;
    }, [milestones.length]);

    const deleteMilestone = (bitPosition: number) => {
        const newMilestones = milestones
            .filter((x) => x.bitPosition !== bitPosition)
            .map((x) =>
                x.bitPosition > bitPosition
                    ? {
                          ...x,
                          bitPosition: x.bitPosition - 1
                      }
                    : x
            )
            .sort((a, b) => a.bitPosition - b.bitPosition);

        setMilestones(newMilestones);
    };
    return (
        <>
            <CustomTypography textWeight="bold" variant="subtitle1">
                Setup Integration
            </CustomTypography>
            <SectionHeader
                className={classes.marginTop}
                IconComponent={LocationOnIcon}
                title="Location & Status"
                description="Select which column contains the names of the locations and the column that indicates the status."
            />
            <LocationAndStatus
                statusColumnId={statusColumnId}
                onConfigureStatusColumn={setStatusColumnId}
                locationColumnId={locationColumnId}
                onConfigureLocationColumn={setLocationColumnId}
                locationMappings={locationMappings}
                onConfigureLocationMappings={(locationMappings) => setLocationMappings(locationMappings)}
            />
            <SectionHeader
                className={classes.marginTop}
                IconComponent={FlagIcon}
                title="Milestones"
                description="Provide a name for your milestone(s) and choose the scope, vessel(s), activity type and name to match the DPR subproject."
            />
            <Button
                onClick={() => setAddMilestoneModalOpen(true)}
                variant="contained"
                className={classes.addMilestoneButton}
                data-testid={addMilestoneButtonTestId}
                color="secondary"
                sx={{ my: 2 }}
            >
                <AddIcon />
                Add milestone
            </Button>

            <Milestones milestones={milestones} displayDelete={true} onDelete={deleteMilestone} />

            <AddMilestoneModal
                open={addMilestoneModalOpen}
                handleClose={() => setAddMilestoneModalOpen(false)}
                onAdd={(newMilestone) => Promise.resolve(setMilestones([...milestones, newMilestone]))}
                newBitPosition={nextBitPosition}
            />
        </>
    );
};

export default ConfigureIntegration;

const addMilestoneButtonTestId = "qa-dpr-integration-add-milestone-button";
