import * as Yup from "yup";
import { metadataTypes } from "../constants/metadata";
import { postgresReservedWords } from "../constants/postgresReservedWords";
import { MIN_TAGS } from "./metadata";

export const maxDate = new Date("2099-12-31");
export const minDate = new Date("1900-01-01");

const metadataTypesValidators = {
    [metadataTypes.TEXT]: Yup.string().nullable(),
    [metadataTypes.DATE]: Yup.date()
        .nullable()
        .typeError("Invalid date")
        .min(minDate, `Date should not be before ${minDate.toLocaleDateString()}`)
        .max(maxDate, `Date should not be after ${maxDate.toLocaleDateString()}`),
    [metadataTypes.TAG_LIST]: Yup.array(),
    [metadataTypes.NUMBER]: Yup.number().nullable().typeError("Field needs to be a number.")
};

const getMetadataPropertyValidator = (mandatory, type) => {
    let validator = metadataTypesValidators[type];

    if (mandatory) {
        validator = validator.required("Field is mandatory");

        if (type === metadataTypes.TAG_LIST) {
            validator = validator.compact((tag) => !tag.enabled).min(MIN_TAGS, "Must enable at least one tag");
        }
    }

    return validator;
};

const getMetadataValidators = (metadata, filterFunc = () => true) =>
    metadata.filter(filterFunc).reduce((acc, metadata) => {
        acc[metadata.name] = getMetadataPropertyValidator(metadata.mandatory, metadata.type);
        return acc;
    }, {});

const columnsValidator = Yup.array(Yup.object({ prettyName: Yup.string().required("Field required") })) //In this part we validate that no names are empty
    .unique({ prettyName: "Column names must be unique" }, (metaTag) => metaTag.prettyName); //unique function def can be found in ./utilFunctions

export const datasetNameValidator = Yup.string().required("Filed required").min(2, "Dataset name is too short!").max(150, "Dataset name is too long!");

export const tableNameValidator = Yup.string()
    .required("Filed required")
    .max(63, "Table name is too long!")
    .test("Test that it isn't reserved word", "This is a reserved word", (val) => !postgresReservedWords.includes(val.toLowerCase()))
    .matches(/^[a-z_][a-z0-9_]*$/, "Can only contain a-z, 0-9 or _ and cannot start with a number");

export const DatasetEditViewSchema = (metadata) =>
    Yup.object().shape({
        columns: columnsValidator,
        datasetName: datasetNameValidator,
        //this is a dynamic schema because of the fact that the form is also dynamic
        ...getMetadataValidators(metadata)
    });

export const datasetsBulkEditMetadataSchema = (metadata) =>
    Yup.object().shape({
        selectedProperties: Yup.array().min(1, "Must select at least one property"),
        propertyValues: Yup.object().when("selectedProperties", (selectedProperties, schema) =>
            schema.shape({
                ...getMetadataValidators(metadata, (prop) => selectedProperties.includes(prop.id))
            })
        )
    });
