import { useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import Map from "components/map/mapComponent";
import Header from "components/header/Header";
import HeaderButtons from "components/HeaderButtons/HeaderButtons";
import MapTools from "components/map/mapTools/MapTools";
import ZoomLevelWidget from "components/map/mapTools/ZoomLevelWidget/ZoomLevelWidget";
import { getNavigationState } from "selectors/navigation";
import { getUnseenNotifications } from "selectors/notifications";
import RastersListView from "../RastersListView/RastersListView";
import RasterEditViewContainer from "../RasterEditView/RasterEditViewContainer";

const RasterRootView = () => {
    const unseenNotifications = useSelector(getUnseenNotifications);
    const navigationState = useSelector(getNavigationState);

    const location = useLocation();
    const match = useRouteMatch();

    const isMapShown = location.pathname.includes("edit");
    return (
        <div className="rasters-rootview">
            <div className={navigationState ? "sidebar" : "sidebar closed"}>
                <div className="header">
                    <Header />
                    <HeaderButtons unseenNotifications={unseenNotifications} userMenuIsCollapsed={isMapShown} />
                </div>
                <Switch>
                    <Route exact path={match.path} component={RastersListView} />
                    <Route path={`${match.path}/edit/:rasterId`} component={RasterEditViewContainer} />
                </Switch>
            </div>
            <div className={isMapShown ? "map" : "map hidden"}>
                <Map>
                    <MapTools>
                        <ZoomLevelWidget />
                    </MapTools>
                </Map>
            </div>
        </div>
    );
};

export default RasterRootView;
