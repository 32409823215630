import { FC } from "react";
import { MenuItem } from "@mui/material";
import { CoreClient } from "features/core/models/CoreClient";
import ButtonLink from "components/ButtonLink/ButtonLink";
import { clientIdParam } from "utils/constants/queryParams";
import config from "config";
import { useStyles } from "./styles";
import OverflowTip from "components/OverflowTip/OverflowTip";
import { MENU_ITEM_HEIGHT } from "components/UserMenu/UserMenu";
import clsx from "clsx";
import CustomTypography from "components/CustomTypography/CustomTypography";

type Props = {
    activeCoreClients: CoreClient[];
    clientId: string;
};

const ClientsPicker: FC<Props> = ({ activeCoreClients, clientId }) => {
    const classes = useStyles();

    const getPathname = (client: CoreClient) => {
        const queryParams = new URLSearchParams();
        queryParams.append(clientIdParam, client.id);

        return `${config.landingUrl}?${queryParams.toString()}`;
    };

    const renderCoreClients = () => {
        return activeCoreClients.map((client) => (
            <MenuItem
                key={client.id}
                className={clsx(classes.client, client.id === clientId && classes.selected)}
                component={ButtonLink}
                to={getPathname(client)}
                divider
                sx={{ height: MENU_ITEM_HEIGHT }}
            >
                <div className={classes.clientName}>
                    <OverflowTip variant="body2">{client.name}</OverflowTip>
                    {client.id === clientId && (
                        <CustomTypography className={classes.activeSession} variant="caption">
                            Active session
                        </CustomTypography>
                    )}
                </div>
                {!!client.logoUrl && <img src={client.logoUrl} className={classes.clientLogo} alt="Client logo" />}
            </MenuItem>
        ));
    };

    return <div className={classes.clients}>{renderCoreClients()}</div>;
};

export default ClientsPicker;
