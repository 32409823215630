import { FC } from "react";
import { setAppDetails } from "reducers/appData/appData";
import { getApp } from "selectors/appData";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import LanguagePicker from "../languagePicker";
import { ComponentProps } from "../AppSettingsView";

const MapLanguagesSection: FC<ComponentProps> = ({ disabled }) => {
    const app = useAppSelector(getApp);
    const dispatch = useAppDispatch();

    const onChangeLanguages = (languages: any) => {
        dispatch(setAppDetails({ app: { ...app, languages } }));
    };

    return <LanguagePicker languages={app.languages} onChange={onChangeLanguages} disabled={disabled} />;
};

export default MapLanguagesSection;
