import { Collapse, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { DprMilestoneType } from "features/dprIntegration/models/DprMilestoneType";
import { TransitionGroup } from "react-transition-group";
import DprCustomAutocomplete from "../../DprCustomAutocomplete/DprCustomAutocomplete";
import { ChangeEvent, FC, useMemo } from "react";
import { useStyles } from "../styles";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { DprSubproject } from "features/dprIntegration/models/DprSubproject";
import {
    useFetchActivitiesQuery,
    useFetchActivityGroupsQuery,
    useFetchStatusTrackerColumnGroupsQuery,
    useFetchStatusTrackerColumnsQuery,
    useFetchStatusTrackerNamesQuery
} from "features/dprIntegration/dprApi";

type Props = {
    milestoneType: DprMilestoneType;
    setMilestoneType: (milestoneType: DprMilestoneType) => void;
    statusTrackerName: string;
    setStatusTrackerName: (statusTrackerName: string) => void;
    group: string;
    setGroup: (group: string) => void;
    name: string;
    setName: (name: string) => void;
    subprojects: DprSubproject[];
};

const DprMilestoneSetupStep: FC<Props> = ({ milestoneType, setMilestoneType, statusTrackerName, setStatusTrackerName, group, setGroup, name, setName, subprojects }) => {
    const classes = useStyles();

    const csvSubprojectIds = useMemo(() => subprojects.map((x) => x.id).join(","), [subprojects]);

    const {
        data: activityGroups = [],
        isLoading: activityGroupsLoading,
        isError: activityGroupsError
    } = useFetchActivityGroupsQuery({ csvSubprojectIds }, { skip: milestoneType !== DprMilestoneType.Activity });
    const {
        data: activities = [],
        isLoading: activitiesLoading,
        isError: activitiesError
    } = useFetchActivitiesQuery({ csvSubprojectIds, activityGroupName: group }, { skip: milestoneType !== DprMilestoneType.Activity });

    const {
        data: statusTrackerNames = [],
        isLoading: statusTrackerNamesLoading,
        isError: statusTrackerNamesError
    } = useFetchStatusTrackerNamesQuery({ csvSubprojectIds }, { skip: milestoneType !== DprMilestoneType.StatusTracker });

    const {
        data: statusTrackerColumnGroups = [],
        isLoading: statusTrackerColumnGroupsLoading,
        isError: statusTrackerColumnGroupsError
    } = useFetchStatusTrackerColumnGroupsQuery({ csvSubprojectIds, statusTrackerName: statusTrackerName }, { skip: milestoneType !== DprMilestoneType.StatusTracker });

    const {
        data: statusTrackerColumns = [],
        isLoading: statusTrackerColumnsLoading,
        isError: statusTrackerColumnsError
    } = useFetchStatusTrackerColumnsQuery(
        { csvSubprojectIds, statusTrackerName: statusTrackerName, columnGroupName: group },
        { skip: milestoneType !== DprMilestoneType.StatusTracker }
    );

    const onMilestoneTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newMilestoneType = e.target.value as DprMilestoneType;

        if (newMilestoneType !== DprMilestoneType.StatusTracker) {
            setStatusTrackerName("");
        }
        setMilestoneType(newMilestoneType);
        setGroup("");
        setName("");
    };

    return (
        <>
            <CustomTypography variant="subtitle2" textWeight="bold" className={classes.description} fontSize={12}>
                3: Please specify how your milestones are setup in DPR and provide the required details:
            </CustomTypography>
            <RadioGroup row defaultValue={DprMilestoneType.Activity} name="dpr-milestone-type" value={milestoneType} onChange={onMilestoneTypeChange}>
                <FormControlLabel value={DprMilestoneType.Activity} control={<Radio size="small" data-testid={activityRadioTestId} />} label="Activity" />
                <FormControlLabel value={DprMilestoneType.StatusTracker} control={<Radio size="small" data-testid={statusTrackerRadioTestId} />} label="Status Tracker" />
            </RadioGroup>

            <TransitionGroup>
                {milestoneType === DprMilestoneType.StatusTracker && (
                    <Collapse sx={{ mb: 2 }}>
                        <DprCustomAutocomplete
                            id="status-tracker"
                            labelName="Status Tracker Name"
                            value={statusTrackerName}
                            options={statusTrackerNames}
                            isLoading={statusTrackerNamesLoading}
                            isError={statusTrackerNamesError}
                            onChange={(value) => setStatusTrackerName(value)}
                            dataTestId={selectStatusTrackerNameTestId}
                            required={false}
                            freeSolo
                        />
                    </Collapse>
                )}
                <Collapse sx={{ mb: 2 }}>
                    <DprCustomAutocomplete
                        id="group"
                        labelName={milestoneType === DprMilestoneType.StatusTracker ? "Column Group" : "Activity Group"}
                        value={group}
                        options={milestoneType === DprMilestoneType.StatusTracker ? statusTrackerColumnGroups : activityGroups}
                        isLoading={milestoneType === DprMilestoneType.StatusTracker ? statusTrackerColumnGroupsLoading : activityGroupsLoading}
                        isError={milestoneType === DprMilestoneType.StatusTracker ? statusTrackerColumnGroupsError : activityGroupsError}
                        onChange={(value) => setGroup(value)}
                        dataTestId={selectGroupTestId}
                        freeSolo
                    />
                </Collapse>
                <Collapse>
                    <DprCustomAutocomplete
                        id="name"
                        labelName={milestoneType === DprMilestoneType.StatusTracker ? "Column Name" : "Activity Name"}
                        value={name}
                        options={milestoneType === DprMilestoneType.StatusTracker ? statusTrackerColumns : activities}
                        isLoading={milestoneType === DprMilestoneType.StatusTracker ? statusTrackerColumnsLoading : activitiesLoading}
                        isError={milestoneType === DprMilestoneType.StatusTracker ? statusTrackerColumnsError : activitiesError}
                        onChange={(value) => setName(value)}
                        dataTestId={selectNameTestId}
                        freeSolo
                    />
                </Collapse>
            </TransitionGroup>
        </>
    );
};

export default DprMilestoneSetupStep;

const activityRadioTestId = "qa-dpr-integration-add-milestone-modal-activity-radio";
const statusTrackerRadioTestId = "qa-dpr-integration-add-milestone-modal-status-tracker-radio";

const selectStatusTrackerNameTestId = "qa-dpr-integration-add-milestone-modal-select-status-tracker-name";
const selectGroupTestId = "qa-dpr-integration-add-milestone-modal-select-group";
const selectNameTestId = "qa-dpr-integration-add-milestone-modal-select-name";
