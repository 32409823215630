import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    optionContainer: {
        display: "flex",
        alignItems: "center"
    },
    visibilityText: {
        paddingLeft: 12
    },
    visibilityIcon: {
        height: 32,
        width: 43,
        color: theme.customColors.primaryColor,
        cursor: "pointer",
        padding: "0 8px 0 8px",
        "&:hover": {
            backgroundColor: theme.customColors.appBgColor,
            borderRadius: 50
        }
    }
}));

export const useMetadataRowStyles = makeStyles(() => ({
    hiddenIcon: {
        marginLeft: 12
    },
    mandatoryIcon: {
        marginLeft: 24
    }
}));
