import GeometryTypeIcon from "@mui/icons-material/Category";
import { sortByBool, sortByBoolReverse, sortByDate, sortByDateReverse, sortByNumber, sortByNumberReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";
import CachedIcon from "@mui/icons-material/Autorenew";

const unhumanize = (text) => {
    var powers = { k: 1, m: 2, g: 3, t: 4 };
    var regex = /(\d+(?:\.\d+)?)\s?(k|m|g|t)?b?/i;

    var res = regex.exec(text);

    return res[1] * Math.pow(1024, powers[res[2].toLowerCase()]);
};

const sortByName = (dataset1, dataset2) => sortByString(dataset1.name, dataset2.name);
const sortByNameReverse = (dataset1, dataset2) => sortByStringReverse(dataset1.name, dataset2.name);

const sortBySize = (dataset1, dataset2) => sortByNumber(unhumanize(dataset1.databaseSize), unhumanize(dataset2.databaseSize));
const sortBySizeReverse = (dataset1, dataset2) => sortByNumberReverse(unhumanize(dataset1.databaseSize), unhumanize(dataset2.databaseSize));

const sortBySchema = (dataset1, dataset2) => sortByString(dataset1.schemaName, dataset2.schemaName);
const sortBySchemaReverse = (dataset1, dataset2) => sortByStringReverse(dataset1.schemaName, dataset2.schemaName);

const sortByCacheStatus = (dataset1, dataset2) => sortByNumber(dataset1.cacheStatus, dataset2.cacheStatus);
const sortByCacheStatusReverse = (dataset1, dataset2) => sortByNumberReverse(dataset1.cacheStatus, dataset2.cacheStatus);

const sortByGeometryType = (dataset1, dataset2) => sortByString(dataset1.geometryType, dataset2.geometryType);
const sortByGeometryTypeReverse = (dataset1, dataset2) => sortByStringReverse(dataset1.geometryType, dataset2.geometryType);

const sortByCRS = (dataset1, dataset2) => sortByNumber(dataset1.projection, dataset2.projection);
const sortByCRSReverse = (dataset1, dataset2) => sortByNumberReverse(dataset1.projection, dataset2.projection);

const sortByModified = (dataset1, dataset2) => sortByDate(new Date(dataset1.modifiedUtc), new Date(dataset2.modifiedUtc));
const sortByModifiedReverse = (dataset1, dataset2) => sortByDateReverse(new Date(dataset1.modifiedUtc), new Date(dataset2.modifiedUtc));

const sortByCreated = (dataset1, dataset2) => sortByDate(new Date(dataset1.createdUtc), new Date(dataset2.createdUtc));
const sortByCreatedReverse = (dataset1, dataset2) => sortByDateReverse(new Date(dataset1.createdUtc), new Date(dataset2.createdUtc));

const sortByZoom = (dataset1, dataset2) => sortByNumber(dataset1.maxZoom, dataset2.maxZoom);
const sortByZoomReverse = (dataset1, dataset2) => sortByNumberReverse(dataset1.maxZoom, dataset2.maxZoom);

const sortByRowCount = (dataset1, dataset2) => sortByNumber(dataset1.rowCount, dataset2.rowCount);
const sortByRowCountReverse = (dataset1, dataset2) => sortByNumberReverse(dataset1.rowCount, dataset2.rowCount);

const sortByValidMetadata = (dataset1, dataset2) => sortByBool(dataset1.validMetadata, dataset2.validMetadata);
const sortByValidMetadataReverse = (dataset1, dataset2) => sortByBoolReverse(dataset1.validMetadata, dataset2.validMetadata);

const sortByTableName = (dataset1, dataset2) => sortByString(dataset1.tableName, dataset2.tableName);
const sortByTableNameReverse = (dataset1, dataset2) => sortByStringReverse(dataset1.tableName, dataset2.tableName);

const sortByPermission = (dataset1, dataset2) => sortByNumber(dataset1.permissionType, dataset2.permissionType);
const sortByPermissionReverse = (dataset1, dataset2) => sortByNumberReverse(dataset1.permissionType, dataset2.permissionType);

const datasetsSortings = [
    {
        type: "geometry",
        component: <GeometryTypeIcon />,
        name: "Geometry Type",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByGeometryTypeReverse : sortByGeometryType)
    },
    {
        type: "cache",
        component: <CachedIcon />,
        name: "Cache Status",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCacheStatusReverse : sortByCacheStatus)
    },
    {
        type: "schema",
        name: "Schema",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortBySchemaReverse : sortBySchema)
    },
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName)
    },
    {
        type: "table-name",
        name: "Database Name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByTableNameReverse : sortByTableName)
    },
    {
        type: "text",
        name: "CRS",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCRSReverse : sortByCRS)
    },
    {
        type: "text",
        name: "Zoom",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByZoomReverse : sortByZoom)
    },
    {
        type: "text",
        name: "Size",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortBySizeReverse : sortBySize)
    },
    {
        type: "text",
        name: "Row Count",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByRowCountReverse : sortByRowCount)
    },
    {
        type: "bool",
        name: "Metadata",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByValidMetadataReverse : sortByValidMetadata)
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByModifiedReverse : sortByModified),
        start: true
    },
    {
        type: "date",
        name: "Created",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCreatedReverse : sortByCreated)
    },
    {
        type: "text",
        name: "Permission",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByPermissionReverse : sortByPermission)
    }
];

export default datasetsSortings;
