import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { unpublishAppThunk } from "actions/apps";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import { getApp, getFetching } from "selectors/appData";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { handleError } from "utils/networkErrorUtils";
import toastr from "components/CustomToastr/CustomToastr";
import { ComponentProps } from "../AppSettingsView";
import { FC, useState } from "react";
import AppPublishModal from "../AppEditFooter/AppPublishModal";
import { setAppPublishedStatus } from "reducers/appData/appData";

const PublishUnpublishAppSection: FC<ComponentProps> = ({ disabled }) => {
    const app = useAppSelector(getApp);
    const fetching = useAppSelector(getFetching);
    const [appPublishModalOpen, setAppPublishModalOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const togglePublish = () => {
        if (app.isPublished) {
            // TODO: the ts-ignore should be removed after refactoring to ts the file where unpublishAppThunk is
            // @ts-ignore
            dispatch(unpublishAppThunk({ appId: app.id }))
                .then(unwrapResult)
                .then(() => {
                    dispatch(setAppPublishedStatus(false));
                    toastr.success(`Application is now unpublished`);
                })
                .catch((err) => handleError(err));
        } else {
            setAppPublishModalOpen(true);
        }
    };

    return (
        <>
            <InfoTextSection paddingBottom={2} iconSize="extra-small" textVariant="body2" textWeight="semibold">
                Unpublished applications cannot be seen by any user with a user role
            </InfoTextSection>
            <Button onClick={togglePublish} disabled={fetching || disabled} variant="text" color="primary" data-testid={publishUnpublishButtonTestId}>
                {app.isPublished ? (
                    <>
                        <VisibilityOff className="left-icon" />
                        Unpublish Application
                    </>
                ) : (
                    <>
                        <Visibility className="left-icon" />
                        Publish Application
                    </>
                )}
            </Button>
            <AppPublishModal open={appPublishModalOpen} handleClose={() => setAppPublishModalOpen(false)} appId={app.id} />
        </>
    );
};

export default PublishUnpublishAppSection;

const publishUnpublishButtonTestId = "qa-app-edit-view-settings-publish-unpublish-button";
