import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import CancelIcon from "@mui/icons-material/Close";

import toastr from "../CustomToastr/CustomToastr";

import { LinearProgress } from "@mui/material";
import CustomTypography from "../CustomTypography/CustomTypography";
import * as datasetsActions from "../../actions/datasets";

import NotificationButton from "./notificationButton";
import { removeNotification } from "../../reducers/notifications";

const styles = () => ({});

const Notifications = (props) => {
    const onCancelClick = (notification) => {
        const toastrConfirmOptions = {
            onOk: () => props.cancelJob({ datasetId: notification.resourceId, generationProcessId: notification.generationProcessId }),
            onCancel: () => {}
        };
        toastr.confirm("Stop " + notification.name, toastrConfirmOptions);
    };

    function toCacheStatusString(cacheStatus) {
        switch (cacheStatus) {
            case 0:
                return "Queued";
            case 1:
                return "Caching";
            case 2:
                return "Completed";
            default:
                console.error("Cache status error");
        }
    }

    return (
        <div className={"notifications " + (props.open ? "open" : "")}>
            <div className="notifications-title">
                <CustomTypography variant="h2" textWeight="bold">
                    Notifications
                </CustomTypography>
                <NotificationButton notificationOpen={props.open} />
            </div>
            <div className="notifications-container">
                {props.notifications.length === 0 && (
                    <CustomTypography style={{ textAlign: "center" }} variant="body2">
                        No new notifications
                    </CustomTypography>
                )}
                {props.notifications.map((notification, index) => {
                    let percentage = (notification.tileCount / notification.tileGoal || 0) * 100;

                    let statusText = toCacheStatusString(notification.cacheStatus);

                    if (notification.error) {
                        statusText = "Failed";
                    } else if (notification.cancelling && !notification.wasCancelled) {
                        statusText = "Cancelling";
                    } else if (notification.wasCancelled) {
                        statusText = "Cancelled";
                    }

                    return (
                        <div className="notification" key={notification.generationProcessId}>
                            <div className="title-container">
                                <div className="title">{notification.name}</div>

                                <div className="grow"></div>
                                {notification.error && (
                                    <Tooltip title={notification.errorTitle + ": " + notification.errorMessage}>
                                        <ErrorIcon className="error-icon" />
                                    </Tooltip>
                                )}
                                {notification.cacheStatus === 1 ? (
                                    <Tooltip title="Cancel">
                                        <div onClick={() => onCancelClick(notification)} className="cancel-button">
                                            <CancelIcon />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Dismiss Notification">
                                        <div onClick={() => props.removeNotification(notification.generationProcessId)} className="cancel-button">
                                            <CancelIcon />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="info">
                                <CustomTypography className="text" variant="subtitle2">
                                    {statusText}
                                </CustomTypography>
                                <CustomTypography variant="subtitle2">{Math.min(percentage.toFixed(2), 100)}%</CustomTypography>
                            </div>
                            <div className="progress-bar">
                                <LinearProgress className="progress" variant="determinate" value={Math.min(percentage.toFixed(2), 100)} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    notifications: state.notifications.notifications,
    unseenNotifications: state.notifications.unseenNotifications,
    open: state.notifications.open
});

const mapDispatchToProps = {
    cancelJob: datasetsActions.cancelGenerateCache,
    removeNotification: removeNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notifications));
