import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { createApp } from "../../../../../actions/apps";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import { APP_HIGH_PERSPECTIVE_CONFIG, CONFIG, SUPPORTED_LANGUAGES, TOOLS, WIDGETS } from "../../../../../utils/constants/appDefaults";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import { handleError } from "../../../../../utils/networkErrorUtils";
import { validateAppName } from "../../../../../utils/validationUtils";

const CreateAppModal = ({ open, handleClose }) => {
    const [name, setName] = useState("");
    const [error, setError] = useState("");

    const dispatch = useDispatch();

    const history = useHistory();

    const changeName = (e) => {
        setName(e.target.value);
        setError(validateAppName(e.target.value));
    };

    const onCreateApp = () => {
        const appRequest = {
            name: name,
            configJson: JSON.stringify({
                ...CONFIG
            }),
            mapBounds: APP_HIGH_PERSPECTIVE_CONFIG.mapBounds,
            tools: TOOLS.map(t => t.name),
            widgets: WIDGETS.map(w => w.name),
            languages: SUPPORTED_LANGUAGES.filter(l => l.default).map(l => l.code)
        };
        dispatch(createApp(appRequest))
            .then((res) => {
                const appResult = res.result;

                toastr.success("App created");
                history.push("applications/edit/" + appResult.id);
                handleClose();
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const onCloseModal = () => {
        handleClose();
        setName("");
        setError("");
    };

    return (
        <div>
            <CustomModal handleClose={onCloseModal} isOpen={open} onConfirm={onCreateApp} dialogTitle={"Create App"} dialogType={"create"}>
                <TextField
                    id="filled-required"
                    placeholder="Name of the Application"
                    value={name}
                    onChange={changeName}
                    fullWidth
                    error={!!error}
                    helperText={error}
                    margin="none"
                    variant="filled"
                    label="Application Name"
                />
            </CustomModal>
        </div>
    );
};

export default CreateAppModal;
