export const makeSourceFromMap = (map) => ({
    id: map.id,
    minZoom: map.minZoom,
    maxZoom: map.maxZoom,
    bounds:PolygonToBounds(map.bounds),
    type: "vector",
    cacheStatus: map.cacheStatus,
    isMap: true
});

export const makeSourceFromDataset = (dataset) => ({
    id: dataset.id,
    minZoom: dataset.minZoom,
    maxZoom: dataset.maxZoom,
    bounds:PolygonToBounds(dataset.bounds),
    type: "vector",
    isMap: false
});

export const makeSourceFromRaster = (raster) => ({
    id: raster.id,
    minZoom: raster.minZoom,
    maxZoom: raster.maxZoom,
    bounds:PolygonToBounds(raster.bounds),
    type: "raster",
    isMap: false
});

const PolygonToBounds = (poly) => {
    const minLng = poly.coordinates[0][1][0]
    const minLat = poly.coordinates[0][1][1]

    const maxLng = poly.coordinates[0][3][0]
    const maxLat = poly.coordinates[0][3][1]

    return [minLng,maxLat,maxLng,minLat];
}