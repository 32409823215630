import { useContext, useEffect } from "react";
import { BooleanFilterType } from "../types/BooleanFilterType";
import { OptionType } from "../types/OptionType";
import { TableContext } from "../providers/TableContextProvider";

const defaultFilterFunction = (elem: boolean, selectedOptions: boolean[]) => (elem ? selectedOptions.some((x) => x === elem) : true);

const useRegisterBooleanFilter = (
    name: string,
    columnName: string,
    options: OptionType<boolean>[],
    filterFunction: (elem: boolean, selectedOptions: boolean[]) => boolean = defaultFilterFunction
) => {
    const tableContext = useContext(TableContext);

    useEffect(() => {
        tableContext.registerFilter<boolean, BooleanFilterType>({
            name,
            columnName,
            filterFunction,
            selected: [],
            onApply: (selectedOptions) => tableContext.activateFilter(name, selectedOptions),
            onClear: () => tableContext.deactivateFilter(name),
            options: options,
            type: "boolean",
            active: false,
            defaultValue: []
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);
};

export default useRegisterBooleanFilter;
