import { FC, useState } from "react";
import { useColorTableEntryStyles } from "./styles";
import { ColorTableEntry } from "./ColorPalette";
import { TextField, Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ColorPopover from "./ColorPopover";
import { ColorResult } from "react-color";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

type Props = {
    entry: ColorTableEntry;
    onColorChange: (entry: ColorTableEntry, color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => void;
    onLabelChange: (entry: ColorTableEntry, label: string) => void;
    onValueChange: (entry: ColorTableEntry, value: number) => void;
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

const ColorTableItem: FC<Props> = ({ entry, onColorChange, onLabelChange, onValueChange, dragHandleProps }) => {
    const classes = useColorTableEntryStyles();

    const [anchorEl, setAnchorEl] = useState<Element>();
    const [value, setValue] = useState<string>(entry.value.toString());

    const onColorPickerClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onColorPickerClosed = () => {
        setAnchorEl(undefined);
    };

    const onValueInteralChange = (entry: ColorTableEntry, value: string) => {
        setValue(value);

        if (!value.endsWith(".")) {
            onValueChange(entry, parseFloat(value));
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.dragButton} {...dragHandleProps}>
                <DragIndicatorIcon />
            </div>

            <div className={classes.textfieldContainer}>
                <div className={classes.textContainer}>
                    <Typography variant="caption" className={classes.label}>
                        Value:
                    </Typography>
                    <TextField className={classes.textField} value={value} onChange={(e) => onValueInteralChange(entry, e.target.value)} variant="standard"></TextField>
                </div>
                <div className={classes.textContainer}>
                    <Typography variant="caption" className={classes.label}>
                        Label:
                    </Typography>
                    <TextField
                        className={classes.textField}
                        defaultValue={entry.label.toString()}
                        onChange={(e) => onLabelChange(entry, e.target.value)}
                        variant="standard"
                    ></TextField>
                </div>
            </div>

            <div onClick={onColorPickerClicked} className={classes.colorIndicator} style={{ backgroundColor: entry.color }}></div>
            <ColorPopover color={entry.color} anchorEl={anchorEl} onClose={onColorPickerClosed} onChange={(color, e) => onColorChange(entry, color, e)} />
        </div>
    );
};

export default ColorTableItem;
