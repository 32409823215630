import axiosClient from "./apiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getStyleConfig = createAsyncThunk("getStyleConfig", async () => {
    return axiosClient.get(`config/styles`).then((res) => res.data);
});

type GetProjectionsArg = {
    searchText: string;
    limit: number;
};
export const getProjections = createAsyncThunk("getProjections", async ({ searchText = "", limit = 100 }: GetProjectionsArg) => {
    const query = new URLSearchParams();
    query.append("searchText", searchText);
    query.append("limit", limit.toString());
    return axiosClient.get(`config/projections?${query.toString()}`).then((res) => res.data);
});
