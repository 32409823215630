import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    groupEditView: {
        minWidth: 672,
        '& .container': {
            '& .users': {
                padding: 16,
                '& .user': {
                    cursor: 'pointer',
                    padding: 16,
                    color: 'white',
                    border: '1px solid grey',
                    '&:hover': {
                        border: '1px solid #008b8b'
                    }
                }
            },
            '& .list': {
                backgroundColor: '$app-bg-color',
                height: 500,
                overflow: 'auto'
            },
            '& .section': {
                marginBottom: 16,
                '&.flex': {
                    display: 'flex'
                }
            }
        }
    },
    resourcePermissionItem: {
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        paddingLeft: 20,
        paddingRight: 16,
        color: '$app-text-color',
        backgroundColor: '$app-bg-color-light',
        borderBottom: '$border-color solid 1px',
        '& .name': {
            fontSize: 16,
            flexGrow: 1,
            width: 200,
            marginRight: 16
        },
        '& .checkbox-container': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& .radio-label': {
                fontSize: 12,
                width: 'max-content'
            }
        }
    },
    buttonMargin: { marginBottom: 10 }
}));
