import { resetProjectData } from "actions/globalActions";
import * as types from "../actions/actionTypes";

const uploaderInit = {
    open: false,
    dataset: null
};

export function uploaderReducer(state = uploaderInit, action) {
    switch (action.type) {
        case types.OPEN_UPLOADER:
            return {
                ...state,
                open: true,
                dataset: action.result.dataset
            };
        case types.CLOSE_UPLOADER:
        case resetProjectData.type:
            return uploaderInit;
        default:
            return state;
    }
}
