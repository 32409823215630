import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import RetryIcon from "@mui/icons-material/Replay";
import CancelIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { useFormik } from "formik";
import { formatTableName, getPercentageCompleted } from "features/upload/utils";
import UploadProgress from "../UploadProgress/UploadProgress";
import VectorUploadSchema from "../../../validators/vectorUploadSchema";
import { useUploadDatasetMutation } from "../../../api";
import TypeChip from "components/TypeChip/TypeChip";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import { isApiError } from "../../../../../store/customBaseQuery";
import { UploadHandle } from "features/upload/models/UploadHandle";
import useComponentCancelToken from "app/hooks/useComponentCancelToken";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { removeUpload, updateUpload } from "../../../slice";
import { UploadStatus } from "../../../models/Upload";

type Props = {
    name: string;
    file: File;
    extension: ".geojson" | ".csv" | ".zip" | ".gpkg";
    schemas: string[];
};

const VectorUpload = forwardRef<UploadHandle, Props>(({ name, file, schemas, extension }, ref) => {
    const [progress, setProgress] = useState(0);
    const [uploadDataset, { isLoading, error, isError, reset }] = useUploadDatasetMutation();
    const dispatch = useAppDispatch();
    const [cancelToken, regenerateCancelToken] = useComponentCancelToken();

    const isDoneUploading = progress === 100;
    const isPending = !isLoading && !isDoneUploading;

    const uploadProgressHandler = (e: ProgressEvent) => {
        const loaded = e.loaded;
        const total = e.total;

        setProgress(getPercentageCompleted(loaded, total));
    };

    const formik = useFormik({
        initialValues: {
            tableName: formatTableName(name),
            schema: schemas[0],
            datasetName: name
        },
        validateOnChange: true,
        validationSchema: VectorUploadSchema,
        onSubmit: (values) => {
            if (isLoading || isDoneUploading) return;

            dispatch(updateUpload({ id: name, changes: { status: UploadStatus.Uploading } }));

            uploadDataset({
                file: file,
                name: values.datasetName,
                tableName: values.tableName,
                schemaName: values.schema,
                uploadProgressHandler,
                extension: extension,
                cancelToken: cancelToken.token
            })
                .unwrap()
                .then((dataset) =>
                    dispatch(
                        updateUpload({
                            id: name,
                            changes: { status: UploadStatus.Uploaded, id: dataset.id }
                        })
                    )
                )
                .catch(() => dispatch(updateUpload({ id: name, changes: { status: UploadStatus.Failed } })));
        }
    });

    const onUploadCancel = () => {
        cancelToken.cancel("Upload cancelled");
    };

    useImperativeHandle(ref, () => ({
        startUpload: () => formik.submitForm(),
        cancelUpload: onUploadCancel
    }));

    const onUploadRetry = () => {
        setProgress(0);
        formik.resetForm();
        reset();
        regenerateCancelToken();
    };

    return (
        <Grid container spacing={1} mt={1} alignItems="start">
            <Grid item xs={"auto"} marginTop={1}>
                <TypeChip type="vector" variant="large" />
            </Grid>

            {isPending && !isError && (
                <>
                    <Grid item xs>
                        <TextField
                            id="datasetName"
                            name="datasetName"
                            label="Dataset Name"
                            inputProps={{ autoComplete: "no" }}
                            variant="filled"
                            size="small"
                            fullWidth
                            margin="none"
                            error={formik.touched.datasetName && Boolean(formik.errors.datasetName)}
                            helperText={formik.touched.datasetName && formik.errors.datasetName}
                            onBlur={formik.handleBlur}
                            placeholder="Dataset Name"
                            value={formik.values.datasetName}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="schema">Schema</InputLabel>
                            <Select
                                labelId="schema"
                                label="Schema"
                                name="schema"
                                size="small"
                                value={formik.values.schema}
                                error={formik.touched.schema && Boolean(formik.errors.schema)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: `calc((100vh - 230px)*0.7)`
                                        }
                                    }
                                }}
                            >
                                {schemas.map((schema, index) => (
                                    <MenuItem key={index} value={schema}>
                                        {schema}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs>
                        <TextField
                            id="tableName"
                            name="tableName"
                            inputProps={{ autoComplete: "no" }}
                            label="Table Name"
                            fullWidth
                            size="small"
                            margin="none"
                            variant="filled"
                            error={formik.touched.tableName && Boolean(formik.errors.tableName)}
                            helperText={formik.touched.tableName && formik.errors.tableName}
                            placeholder="Name of the map"
                            value={formik.values.tableName}
                            onChange={(e) => formik.setFieldValue("tableName", e.target.value.toLowerCase())}
                            onBlur={formik.handleBlur}
                        />
                    </Grid>
                </>
            )}

            {(isLoading || isDoneUploading || isError) && (
                <>
                    <Grid item xs={4}>
                        <ReadOnlyField label="Dataset Name" text={formik.values.datasetName} />
                    </Grid>
                    <Grid item xs>
                        <UploadProgress
                            progress={progress}
                            isLoading={isLoading}
                            error={isError}
                            errorMessage={isApiError(error) ? error.message : "An unexpected error occurred"}
                        />
                    </Grid>
                </>
            )}

            <Grid item xs="auto" sx={{ "&:empty": { paddingLeft: 0, flex: 0 } }} marginTop={0.5}>
                {isPending && !isError && (
                    <IconButton size="small" color="primary" onClick={() => formik.submitForm()}>
                        <UploadIcon />
                    </IconButton>
                )}

                {isLoading && (
                    <IconButton size="small" color="error" onClick={onUploadCancel}>
                        <CancelIcon />
                    </IconButton>
                )}

                {isError && (
                    <IconButton size="small" color="primary" onClick={onUploadRetry}>
                        <RetryIcon />
                    </IconButton>
                )}

                {(isPending || isError) && (
                    <IconButton size="small" color="error" onClick={() => dispatch(removeUpload(name))}>
                        <DeleteIcon />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
});

export default VectorUpload;
