import { makeStyles } from "@mui/styles";

export const useCustomTableStyles = makeStyles((theme) => ({
    visible: {
        display: "flex"
    },
    pagination: {
        backgroundColor: theme.customColors.appBgColorLight,
        display: "flex"
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.customColors.borderColor}`
    },
    borderTop: {
        borderTop: `1px solid ${theme.customColors.borderColor}`
    },
    textIndicator: {
        cursor: "default",
        fontWeight: 700,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "14px 0",
        borderBottom: "1px solid rgba(224, 224, 224, 1);"
    },
    errorText: {
        color: theme.customColors.errorColor
    }
}));
