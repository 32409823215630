import { Button } from "@mui/material";
import { useStyles } from "./styles";
import { FC } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
    hasIntegration: boolean;
    configureIntegration: boolean;
    setupDprDataset: () => void;
    isSetupValid: boolean;
    cancelDprDatasetSetup: () => void;
    deleteDprDatasetSetup: () => void;
    addMilestone: () => void;
};

const DprFooter: FC<Props> = ({ hasIntegration, configureIntegration, setupDprDataset, isSetupValid, cancelDprDatasetSetup, deleteDprDatasetSetup, addMilestone }) => {
    const classes = useStyles();

    if (hasIntegration) {
        return (
            <div className={classes.footer}>
                <Button onClick={addMilestone} variant="contained" color="primary" className={classes.buttonSpacing} data-testid={addMilestoneButtonTestId}>
                    <AddIcon />
                    Add milestone
                </Button>
                <Button variant="contained" color="secondary" onClick={deleteDprDatasetSetup} data-testid={undoSetupButtonTestId}>
                    <DeleteIcon className="button-icon" />
                    Undo setup
                </Button>
            </div>
        );
    }

    if (configureIntegration) {
        return (
            <div className={classes.footer}>
                <Button className={classes.buttonSpacing} color="primary" variant="contained" onClick={setupDprDataset} disabled={!isSetupValid} data-testid={saveButtonTestId}>
                    <SaveIcon className="button-icon" />
                    Save Changes
                </Button>
                <Button variant="contained" color="secondary" onClick={cancelDprDatasetSetup} data-testid={cancelButtonTestId}>
                    <CancelOutlinedIcon className="button-icon" />
                    Cancel
                </Button>
            </div>
        );
    }

    return null;
};

export default DprFooter;

const saveButtonTestId = "qa-dpr-integration-save-changes-button";
const cancelButtonTestId = "qa-dpr-integration-cancel-button";
const addMilestoneButtonTestId = "qa-dpr-integration-add-milestone-button";
const undoSetupButtonTestId = "qa-dpr-integration-undo-setup-button";
