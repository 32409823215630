import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { OptionType } from "components/CustomTable/types/OptionType";
import FilterDropdownMenu from "../FilterDropdownMenu/FilterDropdownMenu";

import { useStyles } from "./styles";

type Props = {
    name: string;
    options: OptionType<boolean>[];
    selected: boolean[];
    isActive: boolean;
    onApply: (selected: boolean[]) => void;
    onClear: () => void;
};

const BooleanFilter: React.FC<Props> = ({ name, options, selected, isActive, onApply, onClear }) => {
    const classes = useStyles();

    const onSelectionChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, newValue: boolean) => {
        if (newValue === null || selected.some((s) => s === newValue)) {
            onClear();
        } else {
            onApply([newValue]);
        }
    };

    return (
        <FilterDropdownMenu isActive={isActive} label={name} selectedValuesCount={selected?.length > 0 ? 1 : 0} type="select">
            <div className={classes.filterDropdown}>
                <Typography variant="body2" mt={0} mb={1}>
                    Select one option:
                </Typography>
                <ToggleButtonGroup color="primary" value={selected} exclusive onChange={onSelectionChange}>
                    {options.map((item, index) => (
                        <ToggleButton key={index} value={item.value} className={classes.toggleButton} color={"primary"} data-testid={getDataTestId(item.displayName)}>
                            {item.icon}
                            {item.displayName}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
        </FilterDropdownMenu>
    );
};

export default BooleanFilter;

const getDataTestId = (name: string) => `qa-${name.toLocaleLowerCase()}-boolean-filter-option`;
