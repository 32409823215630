import axios from "axios";

export const MAPBOX_BASE_URL = "https://api.mapbox.com/";

export default axios.create({
    baseURL: MAPBOX_BASE_URL,
    timeout: 20000,
    crossDomain: true,
    "Content-Type": "application/json"
});
