import { FC, MouseEventHandler } from "react";
import { Button, Tooltip } from "@mui/material";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getCurrentUserRole } from "features/auth/selectors";
import { useStyles } from "./styles";
import SaveIcon from "@mui/icons-material/Save";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ADVANCED_TAB, DETAILS_TAB, METADATA_TAB } from "../../datasetEditView";
import { GisRole } from "features/users/model/GisRole";

type Props = {
    saveButtonTitle: string;
    saveButtonDisabled: boolean;
    onSaveButtonClick: MouseEventHandler<HTMLButtonElement>;
    onActionsButtonClick: MouseEventHandler<HTMLButtonElement>;
    openedTab: "details" | "metadata" | "advanced" | "uploader" | "dpr";
};

const DatasetEditFooter: FC<Props> = ({ saveButtonTitle, saveButtonDisabled, onSaveButtonClick, onActionsButtonClick, openedTab }) => {
    const classes = useStyles();
    const userRole = useAppSelector(getCurrentUserRole);

    if (openedTab !== DETAILS_TAB && openedTab !== METADATA_TAB && openedTab !== ADVANCED_TAB) {
        return null;
    }

    if (userRole === GisRole.Uploader) return null;
    return (
        <div className={classes.footer}>
            <Tooltip title={saveButtonTitle}>
                <div>
                    <Button
                        disabled={saveButtonDisabled}
                        className={classes.buttonSpacing}
                        color="primary"
                        variant="contained"
                        onClick={onSaveButtonClick}
                        data-testid={saveButtonTestId}
                    >
                        <SaveIcon className="button-icon" />
                        Save Changes
                    </Button>
                </div>
            </Tooltip>
            <Button variant="contained" color="secondary" onClick={onActionsButtonClick} data-testid={actionsButtonTestId}>
                <MoreHorizIcon className="button-icon" />
                Actions
            </Button>
        </div>
    );
};

export default DatasetEditFooter;

const saveButtonTestId = "qa-dataset-edit-view-save-button";
const actionsButtonTestId = "qa-dataset-edit-view-actions-button";
