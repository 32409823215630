import React from "react";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useStyles } from "./styles";
import selectFromComp from "../../utils/icons/select_from_comp.svg";
import CustomTypography from "../CustomTypography/CustomTypography";
import { useTheme } from "@mui/styles";
import clsx from "clsx";

const DndUploadContainer = ({ dragging, acceptedFormats, onFileDrop, onFileDragLeave, onFileDragOver, onFileChanged }) => {
    const classes = useStyles();

    const theme = useTheme();
    return (
        <div
            onDrop={onFileDrop}
            onDragLeave={onFileDragLeave}
            onDragOver={onFileDragOver}
            className={clsx(classes.uploadContainer, classes.flexBox, { [classes.dragging]: dragging })}
        >
            <div className={clsx(classes.flexBox, { [classes.pointerEventNone]: dragging })}>
                <CloudUploadIcon fontSize="large" color="primary" />

                <CustomTypography variant="subtitle1" textWeight="bold">
                    {!dragging ? "Drag and drop file here" : "Drop file here"}
                </CustomTypography>
                {!dragging && (
                    <CustomTypography variant="subtitle1" textWeight="semibold" color={theme.customColors.appTextColorLight}>
                        - or -
                    </CustomTypography>
                )}
                {!dragging && (
                    <Button
                        className={classes.buttonSpacing}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            document.getElementById("file-button").click();
                        }}
                        startIcon={<img alt="" src={selectFromComp} className={classes.icon} />}
                        data-testid={selectFileButtonTestId}
                    >
                        Select from computer
                    </Button>
                )}
                <input
                    accept={acceptedFormats}
                    className={classes.notVisible}
                    multiple={true}
                    id="file-button"
                    type="file"
                    onChange={onFileChanged}
                    data-testid={uploadFileInputTestId}
                />
            </div>
        </div>
    );
};

export default DndUploadContainer;

const uploadFileInputTestId = "qa-upload-container-upload-file-input";
const selectFileButtonTestId = "qa-upload-container-select-file-button";
