import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    row: {
        backgroundColor: theme.customColors.appBgColorLight,
        cursor: "grab",
        "&:hover": {
            position: "relative",
            boxShadow: "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px"
        }
    },
    isDragged: {
        opacity: 0.5
    }
}));
