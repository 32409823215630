import { FC } from "react";
import ModulesPicker from "features/core/components/ModulesPicker/ModulesPicker";
import NotificationButton from "../notifications/notificationButton";
import { useStyles } from "./style";
import UserMenu from "components/UserMenu/UserMenu";

type Props = {
    unseenNotifications: any[];
    userMenuIsCollapsed?: boolean;
};

const HeaderButtons: FC<Props> = ({ unseenNotifications, userMenuIsCollapsed = false }) => {
    const classes = useStyles();

    return (
        <div className={classes.headerButtons}>
            <NotificationButton unseenNotifications={unseenNotifications} />
            <ModulesPicker />
            <UserMenu collapsed={userMenuIsCollapsed} />
        </div>
    );
};

export default HeaderButtons;
