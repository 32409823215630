import { sortByString, sortByStringReverse } from "utils/sortingFunctions";
import { GisUser } from "./model/GisUser";


const sortByName = (user1: GisUser, user2: GisUser) => sortByString(user1.username, user2.username);
const sortByNameReverse = (user1: GisUser, user2: GisUser) => sortByStringReverse(user1.username, user2.username);
const sortByRoles = (user1: GisUser, user2: GisUser) => sortByString(user1.role, user2.role);
const sortByRolesReverse = (user1: GisUser, user2: GisUser) => sortByStringReverse(user1.role, user2.role);

export const usersSorting = [
    {
        type: "name",
        name: "username",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByNameReverse : sortByName),
        start: true
    },
    {
        type: "roles",
        name: "role",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByRolesReverse : sortByRoles)
    }
];
