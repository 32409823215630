import { useHistory, useParams } from "react-router-dom";
import { IconButton, Tooltip, Box } from "@mui/material";
import useAppEditHeaderStyles from "./styles";
import { useSelector } from "react-redux";
import { getApp } from "../../../../../selectors/appData";
import keyboardBackspace from "../../../../../utils/icons/keyboard-backspace.svg";
import OverflowTip from "../../../../../components/OverflowTip/OverflowTip";
import StatusBadge from "../../../../../components/StatusBadge/StatusBadge";

const AppEditHeader = () => {
    const classes = useAppEditHeaderStyles();

    const app = useSelector(getApp);
    const { clientId, projectId } = useParams();

    const history = useHistory();
    const prevLocation = history.location.state?.prevLocation;

    const onBack = () => {
        if (prevLocation) {
            history.push(prevLocation, { ...history.location.state, prevLocation: null });
        } else {
            history.push(`/${clientId}/${projectId}/applications`);
        }
    };

    return (
        <>
            <div className={classes.stylerHeader}>
                <IconButton className={classes.backButton} onClick={onBack} size="large" data-testid={backButtonTestId}>
                    <img alt="" src={keyboardBackspace} />
                </IconButton>
                <Tooltip id="tooltip-top" title={app.isPublished ? "Published" : "Unpublished"}>
                    <Box mr={1}>
                        <StatusBadge status={app.isPublished ? "published" : "unpublished"} />
                    </Box>
                </Tooltip>
                <OverflowTip variant="h2" className={classes.headerText}>
                    {app.name}
                </OverflowTip>
            </div>
        </>
    );
};

export default AppEditHeader;

const backButtonTestId = "qa-app-edit-view-back-button";
