import { useFetchGroupRastersPermissionsQuery } from "features/groups/api";
import { permissionTypes, resourceTypes } from "utils/constants/permissionTypes";
import ResourcePermissionsList from "features/groups/components/GroupEditView/components/ResourcePermissionsList/ResourcePermissionsList";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";

type GroupRastersPermissionsContainerProps = {
    groupId: string;
    onChangesMade: () => void;
};

const GroupRastersPermissionsContainer = ({ groupId, onChangesMade }: GroupRastersPermissionsContainerProps) => {
    const {
        data: rasterPermissions = [],
        isFetching: areRasterPermissionsLoading,
        refetch: refetchRasterPermissions,
        isError: isRasterPermissionsError
    } = useFetchGroupRastersPermissionsQuery(groupId);

    return (
        <LoadingPlaceholder loading={areRasterPermissionsLoading} linearProgress>
            <ErrorPlaceholder error={isRasterPermissionsError} message="Encountered an error while getting your data" textVariant="h5" onTryAgain={refetchRasterPermissions}>
                <ResourcePermissionsList
                    resources={rasterPermissions}
                    permissions={permissionTypes}
                    groupId={groupId}
                    onChangesMade={onChangesMade}
                    resourceType={resourceTypes.RASTER}
                />
            </ErrorPlaceholder>
        </LoadingPlaceholder>
    );
};

export default GroupRastersPermissionsContainer;
