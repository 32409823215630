import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { FeatureProperties } from "features/fidu/models/Feature";
import { FC } from "react";

type Props = {
    featureProperties: FeatureProperties[];
};
const FeaturesTable: FC<Props> = ({ featureProperties }) => {
    const sampleFeature = featureProperties[0];
    const columns = Object.keys(sampleFeature).map((propName) => ({
        field: propName,
        header: (
            <Typography textTransform="capitalize" fontWeight="bold" fontSize={15}>
                {propName}
            </Typography>
        ),
        minWidth: 150,
        flex: 1
    }));

    const rows = featureProperties;
    return <DataGrid columns={columns} rows={rows} getRowId={(row) => row.ogc_fid} disableSelectionOnClick />;
};

export default FeaturesTable;
