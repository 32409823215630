import { Backdrop, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { generateCsvColumnNames, guessCsvDelimiter } from "../../../utils";
import Button from "@mui/material/Button";
import toastr from "components/CustomToastr/CustomToastr";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { removeUpload, updateUpload } from "../../../slice";
import { Call } from "@mui/icons-material";

const csv2Geojson = require("csv2geojson");

type Props = {
    file: File;
};

type CsvInfoType = {
    delimiter: string;
    content: string;
    columns: string[];
};

const CsvModal = ({ file }: Props) => {
    const [csvInfo, setCsvInfo] = useState<CsvInfoType>({ delimiter: "", content: "", columns: [] });
    const [xColumn, setXColumn] = useState<string | null>(null);
    const [yColumn, setYColumn] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const reader = useMemo(() => new FileReader(), []);
    const fileName = file.name.split(".")[0];

    useEffect(() => {
        const callback = () => {
            const result = reader.result as string;
            const header = result.split("\n")[0];
            const delimiter = guessCsvDelimiter(header);
            const columnNames = generateCsvColumnNames(header, delimiter);

            setCsvInfo({
                delimiter,
                content: result,
                columns: columnNames
            });
        };

        reader.addEventListener("load", callback);

        return () => reader.removeEventListener("load", callback);
    }, []);

    useEffect(() => {
        reader.readAsText(file);
    }, [file]);

    const onFileSubmit = () => {
        csv2Geojson.csv2geojson(
            csvInfo.content,
            {
                latfield: yColumn,
                lonfield: xColumn,
                delimiter: csvInfo.delimiter
            },
            (err: any, data: File) => {
                if (err) {
                    toastr.error(err[0].message);
                    return;
                }

                const blob = new Blob([JSON.stringify(data)]);
                const newFile = new File([blob], `${fileName}.geojson`);

                dispatch(
                    updateUpload({
                        id: fileName,
                        changes: { extension: ".geojson", file: newFile }
                    })
                );
            }
        );
    };

    return (
        <Dialog closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} open={true}>
            <DialogTitle>{file.name}</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <FormControl variant="filled" size="small" fullWidth>
                        <InputLabel>X Column</InputLabel>
                        <Select value={xColumn} onChange={(e) => setXColumn(e.target.value)}>
                            {csvInfo.columns.map((c) => (
                                <MenuItem value={c}>{c}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="filled" size="small" fullWidth>
                        <InputLabel>Y Column</InputLabel>
                        <Select value={yColumn} onChange={(e) => setYColumn(e.target.value)}>
                            {csvInfo.columns.map((c) => (
                                <MenuItem value={c}>{c}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onFileSubmit}>
                    Submit
                </Button>
                <Button onClick={() => dispatch(removeUpload(fileName))}> Discard </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CsvModal;
