import makeStyles from "@mui/styles/makeStyles";

export const useTableHeadStyles = makeStyles(() => ({
    tableCell: {
        borderRight: "1px solid lightgrey"
    },
    tableCellLabel: {
        fontWeight: "bold"
    }
}));
