import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    pendingUsersModal: {
        height: "60vh",
        maxWidth: "45vw",
        minWidth: "45vw",
        "@media (max-width: 1400px)": {
            height: "70vh",
            maxWidth: "60vw",
            minWidth: "60vw"
        }
    },
    loadingContainer: {
        overflow: "hidden"
    },
    stepperContainer: {
        paddingTop: 12,
        paddingBottom: 8
    },
    flexGrow: {
        flexGrow: 1
    },
    flex: {
        display: "flex",
        justifyContent: "space-between"
    },
    clickable: {
        cursor: "pointer"
    }
}));
    