import { MetadataProperty } from "../../models/MetadataProperty";
import { ChangeEvent } from "react";
import { TagValue } from "../../models/TagValue";
import CustomMobileDatePicker from "../../../../components/CustomMobileDatePicker/CustomMobileDatePicker";
import CustomTypography from "../../../../components/CustomTypography/CustomTypography";
import { ButtonBase, Card, CardContent, TextField } from "@mui/material";

type Props = {
    property: MetadataProperty;
    error: boolean;
    helperText?: string;
} & (
    | {
          type: "text" | "number";
          onChange: (e: ChangeEvent<HTMLElement>) => void;
          value: string;
          onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
      }
    | {
          type: "date";
          onChange: (e: ChangeEvent<HTMLInputElement> | null) => void;
          value: Date | null;
          onBlur: () => void;
      }
    | {
          type: "tag_list";
          onChange: (tags: TagValue[]) => void;
          value: TagValue[];
          onBlur: () => void;
      }
);

const MetadataValueEditor = ({ property, error, helperText, type, onChange, value, onBlur }: Props) => {
    const getDataTestId = () => {
        const name = property.type.toLocaleLowerCase().replaceAll(" ", "-");
        return metadataPropertyTestId + name;
    };

    const onTagChange = (tagId: string) => {
        if (type !== "tag_list") return;
        onBlur();

        const tags = value.map((tag) => {
            if (tag.id === tagId) {
                return {
                    ...tag,
                    enabled: !tag.enabled
                };
            }

            return tag;
        });

        onChange(tags);
    };

    const tagsLookup: Record<string, boolean> =
        type === "tag_list"
            ? value.reduce((acc, tag) => {
                  acc[tag.id] = tag.enabled;

                  return acc;
              }, {})
            : {};

    return (
        <>
            {type === "date" && (
                <CustomMobileDatePicker
                    label={property.name}
                    required={property.mandatory}
                    dataTestId={getDataTestId()}
                    value={value}
                    onChange={(e) => {
                        onChange(e);
                        onBlur();
                    }}
                    error={error}
                    helperText={helperText}
                />
            )}

            {type === "tag_list" && property.type === "tag_list" && (
                <Card variant="outlined">
                    <CardContent>
                        <div className="field">
                            <CustomTypography variant="body1" className="tag-list-header" color={error ? "red" : ""}>
                                {property.name + (property.mandatory ? " *" : "")}
                            </CustomTypography>
                            <div className="tag-list">
                                {property.value.map((tag) => (
                                    <ButtonBase
                                        key={tag.id}
                                        className={tagsLookup[tag.id] ? "tag enabled" : "tag"}
                                        onClick={() => onTagChange(tag.id)}
                                        style={tagsLookup[tag.id] ? { borderColor: tag.color, backgroundColor: tag.color } : { borderColor: tag.color }}
                                        data-testid={getDataTestId()}
                                    >
                                        <CustomTypography variant="caption" className="text">
                                            {tag.name}
                                        </CustomTypography>
                                    </ButtonBase>
                                ))}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            )}

            {(type === "text" || type === "number") && (
                <TextField
                    id="filled-requried"
                    label={property.name}
                    name={property.name}
                    variant="filled"
                    fullWidth
                    error={error}
                    onChange={onChange}
                    required={property.mandatory}
                    helperText={helperText}
                    inputProps={{ "data-testid": getDataTestId() }}
                    onBlur={onBlur}
                />
            )}
        </>
    );
};

const metadataPropertyTestId = "qa-dataset-edit-view-metadata-tab-metadata-property-";

export default MetadataValueEditor;
