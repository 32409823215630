import { TableCell, TableRow } from "@mui/material";
import { Raster } from "features/rasters/models/Raster";
import { useState, useMemo } from "react";
import { humanFileSize } from "utils/humanFileSize";
import NameCell from "components/CustomTable/components/customCells/NameCell/NameCell";
import clsx from "clsx";
import dayjs from "dayjs";
import TooltipCell from "components/CustomTable/components/customCells/TooltipCell/TooltipCell";
import CustomTypography from "components/CustomTypography/CustomTypography";
import OverflowCell from "components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import MenuButtonCell from "components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import ContextMenu from "../ContextMenu/ContextMenu";
import ButtonLink from "components/ButtonLink/ButtonLink";
import { CELL_SIZES } from "utils/constants/cellSizes";
import { permisionPrettyNames } from "utils/constants/permissionTypes";
import { fromNowWithCheck } from "utils/timeUtils";
import BooleanIndicator from "components/BooleanIndicator/BooleanIndicator";
import CacheStatusChip from "../../../../../components/CacheStatusChip/CacheStatusChip";
import { CacheStatus } from "../../../models/CacheStatus";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import { hasCPT } from "components/ColorPaletteEditor/ColorPalette";

type RasterRowProps = {
    onDelete: (raster: Raster) => void;
    raster: Raster;
    selected: boolean | undefined;
};

const RasterRow = ({ onDelete, raster, selected }: RasterRowProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);

    const featureFlags = useAppSelector(getFeatureFlags);

    const humanReadableSize = useMemo(() => humanFileSize(raster.fileSize), [raster.fileSize]);

    const onMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    const onMenuClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const pathname = "rasters/edit/" + raster.id;

    const getCacheStatus = () => {
        switch (raster.cacheStatus) {
            case CacheStatus.Uncached:
                return "Uncached";
            case CacheStatus.Caching:
                return "Caching";
            case CacheStatus.Cached:
                return "Cached";
            default:
                return "Uncached";
        }
    };

    return (
        <>
            <TableRow component={ButtonLink} to={pathname} className={clsx(selected && "selected")} data-testid={rowButtonTestId}>
                <TooltipCell tooltipTitle={getCacheStatus()}>
                    <CacheStatusChip status={getCacheStatus()} size={"small"} />
                </TooltipCell>

                <NameCell cellSize={CELL_SIZES.large}>{raster.name} </NameCell>

                <TableCell>{`${raster.minZoom} - ${raster.maxZoom}`}</TableCell>

                <TableCell>{humanReadableSize}</TableCell>

                <TooltipCell tooltipTitle={raster.geoTiff ? "Has GeoTiff" : "Doesn't have GeoTiff"}>
                    <BooleanIndicator boolValue={Boolean(raster.geoTiff)} />
                </TooltipCell>

                {featureFlags.CPT && (
                    <TooltipCell paddingRight="20px" tooltipTitle={hasCPT(raster.colorPalette) ? "Has Color Palette" : "Doesn't have Color Palette"}>
                        <BooleanIndicator boolValue={hasCPT(raster.colorPalette)} />
                    </TooltipCell>
                )}

                <TooltipCell tooltipTitle={dayjs(raster.createdUtc).format("MMMM DD YYYY, h:mm:ss a")}>
                    <CustomTypography variant="body2">{fromNowWithCheck(dayjs(raster.createdUtc))}</CustomTypography>
                </TooltipCell>

                <TooltipCell tooltipTitle={dayjs(raster.modifiedUtc).format("MMMM DD YYYY, h:mm:ss a")}>
                    <CustomTypography variant="body2">{fromNowWithCheck(dayjs(raster.modifiedUtc))}</CustomTypography>
                </TooltipCell>

                <OverflowCell cellSize={CELL_SIZES.small}> {permisionPrettyNames[raster.permissionType]} </OverflowCell>

                <MenuButtonCell onMenuClick={onMenuClick} />
            </TableRow>

            <ContextMenu anchorEl={anchorEl} onMenuClose={onMenuClose} onDelete={onDelete} raster={raster} />
        </>
    );
};

export default RasterRow;

const rowButtonTestId = "qa-raster-row-row-button-link";
