import Tooltip from "@mui/material/Tooltip";
import CustomTypography from "../CustomTypography/CustomTypography";
import OverflowTip from "../OverflowTip/OverflowTip";

function ReadOnlyField(props) {
    return (
        <Tooltip placement="top-start" title={props.tooltipTitle || ""} disableHoverListener={!props.tooltip}>
            <div className="readonly-field">
                <CustomTypography variant="body2" textWeight="bold">
                    {props.label}
                </CustomTypography>
                <OverflowTip variant="body2">{props.text}</OverflowTip>
            </div>
        </Tooltip>
    );
}
export default ReadOnlyField;
