import { sortByDate, sortByDateReverse, sortByNumber, sortByNumberReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";

const sortByName = (app1, app2) => sortByString(app1.name, app2.name);
const sortByNameReverse = (app1, app2) => sortByStringReverse(app1.name, app2.name);

const sortByModified = (app1, app2) => sortByDate(new Date(app1.modifiedUtc), new Date(app2.modifiedUtc));
const sortByModifiedReverse = (app1, app2) => sortByDateReverse(new Date(app1.modifiedUtc), new Date(app2.modifiedUtc));

const sortByCreated = (app1, app2) => sortByDate(new Date(app1.createdUtc), new Date(app2.createdUtc));
const sortByCreatedReverse = (app1, app2) => sortByDateReverse(new Date(app1.createdUtc), new Date(app2.createdUtc));

const sortByPermission = (app1, app2) => sortByNumber(app1.permissionType, app2.permissionType);
const sortByPermissionReverse = (app1, app2) => sortByNumberReverse(app1.permissionType, app2.permissionType);

const appsSorting = [
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByNameReverse : sortByName)
    },
    {
        type: "date",
        name: "Created",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByCreatedReverse : sortByCreated)
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByModifiedReverse : sortByModified),
        start: true
    },
    {
        type: "text",
        name: "Permission",
        sortingFunction: (sortingReverse) => (sortingReverse ? sortByPermissionReverse : sortByPermission)
    }
];

export default appsSorting;
