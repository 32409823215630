import { datasetNameValidator, tableNameValidator } from "./validators/dataset";
import { appNameValidator } from "./validators/app";

export const validatePostgresIdentifier = (name) => {
    try {
        tableNameValidator.validateSync(name);
    } catch (err) {
        return err.message;
    }
};

export const validateDatasetName = (name) => {
    try {
        datasetNameValidator.validateSync(name);
    } catch (err) {
        return err.message;
    }
};

export const validateAppName = (name) => {
    try {
        appNameValidator.validateSync(name);
    } catch (err) {
        return err.message;
    }
};
