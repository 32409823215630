import TableView from "components/TableView/TableView";
import AppRow from "../AppRow/AppRow";
import appsSorting from "../AppRow/appSortings";

const AppsSimpleTableView = ({ apps, loading, tab }: { apps: any[]; loading: boolean; tab: string }) => {
    const generateItem = (app: any) => <AppRow key={app.id} app={app} tab={tab} />;

    const filterItem = (item: any, query: string) => {
        return item.name.toLowerCase().includes(query.toLowerCase());
    };

    return (
        <TableView
            searchPlaceholder={"Search apps"}
            data={apps}
            fetchingData={loading}
            columns={appsSorting}
            generateItem={generateItem}
            filterFunction={filterItem}
            hideBottomBar={undefined}
            additionalActionColumn={false}
            searchBarClassName="cardSearchBar"
            searchBarSize="small"
        />
    );
};

export default AppsSimpleTableView;
