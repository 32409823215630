export const TOOLS = [
    {
        name: "legend",
        namePretty: "Legend",
        enabled: true
    },
    {
        name: "digitize",
        namePretty: "Digitize",
        enabled: true
    }
];

export const WIDGETS = [
    {
        name: "search",
        namePretty: "Search",
        enabled: true
    },
    {
        name: "measure",
        namePretty: "Measure",
        enabled: true
    },
    {
        name: "print",
        namePretty: "Print",
        enabled: true
    }
];

export const SUPPORTED_LANGUAGES = [
    { name: "Arabic", code: "ar" },
    { name: "English", code: "en", default: true },
    { name: "Spanish", code: "es" },
    { name: "French", code: "fr" },
    { name: "German", code: "de" },
    { name: "Italian", code: "it" },
    { name: "Portuguese", code: "pt" },
    { name: "Russian", id: "ru" },
    { name: "Simplified Chinese", code: "zh-Hans" },
    { name: "Traditional Chinese", code: "zh-Hant" },
    { name: "Japanese", code: "ja" },
    { name: "Korean", code: "ko" },
    { name: "Vietnamese", code: "vi" }
];

export const DEFAULT_PROJECTION = "EPSG:4326";

export const CONFIG = {
    projections: [{ name: "wgs 84", crs: "EPSG:4326", value: "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs" }],
    layerGroups: []
};

export const APP_HIGH_PERSPECTIVE_CONFIG = {
    mapBounds: [
        [-188.17, -75.01],
        [229.43, 84.28]
    ]
};
