import React from "react";
import dayjs from "dayjs";

import { Tooltip } from "@mui/material";
import { fromNowWithCheck } from "../../../../utils/timeUtils";
import OverflowTip from "../../../../components/OverflowTip/OverflowTip";

export default function RasterListItem(props) {
    return (
        <div
            className={props.selected ? "item selected" : "item"}
            onDoubleClick={() => props.onDoubleClick(props.raster)}
            onClick={(e) => props.onClick(e, props.raster)}
            data-testid={rasterTestId}
        >
            <div className="container">
                <div className="row-container">
                    <div className={"row-1"}>
                        <div className="name">
                            <div className="overflow-text">
                                <OverflowTip variant="body2">{props.raster.name}</OverflowTip>
                            </div>
                        </div>
                        <Tooltip id="tooltip-top" title={dayjs(props.raster.modifiedUtc).format("LLL")}>
                            <div className="date">{fromNowWithCheck(dayjs(props.raster.modifiedUtc))}</div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}

const rasterTestId = "qa-raster-list-item";
