import baseAtlasApi from "../../store/baseAtlasApi";
import { CreateMilestoneRequest } from "./models/CreateMilestoneRequest";
import { DprDataset } from "./models/DprDataset";
import { DprProgressMilestone } from "./models/DprProgressMilestone";
import { SetupDprDatasetRequest } from "./models/SetupDprDatasetRequest";
import { atlasApiTags as tags } from "store/atlasApiTags";

export const dprIntegrationApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        fetchDprDataset: build.query<DprDataset, string>({
            query: (datasetId) => ({
                url: `dpr/milestones/datasets/${datasetId}`,
                method: "GET"
            }),
            providesTags: [tags.dprDatasetTags.DPR_DATASET]
        }),
        setupDprDataset: build.mutation<void, { datasetId: string; request: SetupDprDatasetRequest }>({
            query: ({ datasetId, request }) => ({
                url: `dpr/milestones/datasets/${datasetId}`,
                method: "POST",
                data: request
            }),
            invalidatesTags: [tags.dprDatasetTags.DPR_DATASET]
        }),
        deleteDprDataset: build.mutation<void, string>({
            query: (datasetId) => ({
                url: `dpr/milestones/datasets/${datasetId}`,
                method: "DELETE"
            }),
            invalidatesTags: [tags.dprDatasetTags.DPR_DATASET]
        }),
        createMilestone: build.mutation<DprProgressMilestone, CreateMilestoneRequest>({
            query: (request) => ({
                url: `dpr/milestones`,
                method: "POST",
                data: request
            }),
            async onQueryStarted(request, { dispatch, queryFulfilled }) {
                const res = await queryFulfilled;
                dispatch(
                    dprIntegrationApi.util.updateQueryData("fetchDprDataset", request.datasetId, (dprDataset) => {
                        if (dprDataset) {
                            dprDataset.milestones = [...dprDataset.milestones, res.data];
                        }
                    })
                );
            }
        })
    })
});

export const { useFetchDprDatasetQuery, useSetupDprDatasetMutation, useDeleteDprDatasetMutation, useCreateMilestoneMutation, usePrefetch: useDatasetsPrefetch } = dprIntegrationApi;
