import useUploadInfoViewStyles from "./styles";
import { Box, DialogContent } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import React from "react";
import Typography from "@mui/material/Typography";
import { acceptedRasterFormats, acceptedVectorFormats } from "../../utils";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { Alert } from "@mui/lab";
import UploadContainer from "components/UploadContainer/UploadContainer";

type Props = {
    fileHandler: (files: FileList) => void;
    acceptedFormats: [...typeof acceptedVectorFormats, ...typeof acceptedRasterFormats];
};

const descriptions = {
    ".zip": "Shapefile (ZIP archive containing all shapefile files)",
    ".csv": "CSV Files (comma, semi-colon or tab delimited)",
    ".geojson": "GeoJSON (open standard format for simple geographical features)",
    ".gpkg": "GeoPackage (data format implemented as a SQLite database container)"
};

export const UploadInfoView = ({ fileHandler, acceptedFormats }: Props) => {
    const classes = useUploadInfoViewStyles();

    return (
        <DialogContent dividers>
            <div className={classes.beforeUploadInfo}>
                <Box mb={2}>
                    <CustomTypography variant="h3" fontWeight="bold" gutterBottom>
                        Supported Vector Types:
                    </CustomTypography>

                    {acceptedFormats.map((format) => {
                        const description = descriptions[format.trim()];

                        if (!description) return null;

                        return (
                            <Typography key={format} gutterBottom display="flex">
                                <DescriptionIcon className={classes.iconSpacing} fontSize="small" />
                                {description}
                            </Typography>
                        );
                    })}
                </Box>

                <Box display="inline-flex">
                    <Alert severity="info">Invalid column names will be formatted</Alert>
                </Box>

                {acceptedFormats.includes(".mbtiles") && (
                    <Box mt={2}>
                        <CustomTypography variant="h3" fontWeight="bold" gutterBottom>
                            Supported Raster Types:
                        </CustomTypography>
                        <Typography gutterBottom display="flex">
                            <DescriptionIcon className={classes.iconSpacing} fontSize="small" />
                            MBTiles (open standard based on sqlite)
                        </Typography>
                        <Typography gutterBottom display="flex">
                            <DescriptionIcon className={classes.iconSpacing} fontSize="small" />
                            Geotiff (geo-referenced raster imagery )
                        </Typography>
                    </Box>
                )}
            </div>
            <UploadContainer acceptedFormats={acceptedFormats} fileHandler={fileHandler} />
        </DialogContent>
    );
};

export default UploadInfoView;
