import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper } from "@mui/material";
import toastr from "components/CustomToastr/CustomToastr";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { Group } from "features/groups/model/Group";
import { GisRole } from "features/users/model/GisRole";
import { GisUser } from "features/users/model/GisUser";
import SwipeableViews from "react-swipeable-views";
import PendingUsersConfirmView from "./PendingUsersConfirmView/PendingUsersConfirmView";
import PendingUsersGroupsAssignView from "./PendingUsersGroupsAssignView/PendingUsersGroupsAssignView";
import PendingUsersListView from "./PendingUsersListView/PendingUsersListView";
import { CoreUser } from "features/users/model/CoreUser";
import { invalidateGisUserCache, useBulkCreateUsersMutation } from "../../api";
import { useStyles } from "./styles";
import { useBulkAddUsersToGroupsMutation, useFetchAllGroupsQuery } from "features/groups/api";
import { useDispatch } from "react-redux";

type Props = {
    open: boolean;
    onClose: () => void;
};

enum StepEnum {
    ASSIGN_ROLES,
    ADD_TO_GROUP,
    CONFIRM
}

const stepTitles = {
    [StepEnum.ASSIGN_ROLES]: "Assign roles to users",
    [StepEnum.ADD_TO_GROUP]: "Add users to groups",
    [StepEnum.CONFIRM]: "Confirm selection"
};

const PendingUsersModal: FC<Props> = ({ open, onClose }) => {
    const classes = useStyles();
    const [bulkCreateUsers, { isLoading: usersLoading }] = useBulkCreateUsersMutation();
    const { data: groups, isLoading: groupsLoading } = useFetchAllGroupsQuery();
    const [bulkAddUsersToGroups] = useBulkAddUsersToGroupsMutation();
    const [modifiedUsers, setModifiedUsers] = useState<GisUser[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
    const [currentStep, setCurrentStep] = useState(StepEnum.ASSIGN_ROLES);
    const dispatch = useDispatch();
    const loading = usersLoading || groupsLoading;

    const onRoleChange = (newRole: GisRole, user: CoreUser) => {
        const modifiedUser = modifiedUsers.find((u) => u.username === user.username);

        if (modifiedUser) {
            modifiedUser.role = newRole;
            setModifiedUsers([...modifiedUsers]);
        } else {
            setModifiedUsers([
                ...modifiedUsers,
                {
                    id: 0,
                    username: user.username,
                    role: newRole,
                    coreAccountId: user.coreAccountId
                }
            ]);
        }
    };

    const onGroupToggle = (group: Group) => {
        const selectedGroup = selectedGroups.find((g) => g.id === group.id);

        if (selectedGroup) {
            setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id));
        } else {
            setSelectedGroups([...selectedGroups, group]);
        }
    };

    const onNextClick = () => {
        // Skip adding users to groups if all users are admins
        if (currentStep === StepEnum.ASSIGN_ROLES) {
            if (modifiedUsers.every((user) => user.role === GisRole.Admin)) {
                setCurrentStep(StepEnum.CONFIRM);
                return;
            }
        }
        setCurrentStep(currentStep + 1);
    };

    const onSkipClick = () => {
        setCurrentStep(currentStep + 1);
        setSelectedGroups([]);
    };

    const onBackClick = () => {
        // Go back to role assign if all users are admins
        if (currentStep === StepEnum.CONFIRM) {
            if (modifiedUsers.every((user) => user.role === GisRole.Admin)) {
                setCurrentStep(StepEnum.ASSIGN_ROLES);
                return;
            }
        }
        setCurrentStep(currentStep - 1);
    };

    const onConfirmClick = () => {
        onClose();

        bulkCreateUsers(modifiedUsers)
            .unwrap()
            .then((res) => {
                toastr.success("Added users to WindGIS");

                if (selectedGroups.length === 0) {
                    onCloseClick();
                    return;
                }

                const groupIds = selectedGroups.map((group) => group.id);
                const userEmails = res.filter((user) => user.role !== GisRole.Admin).map((user) => user.username);

                bulkAddUsersToGroups({ groupIds, userEmails })
                    .unwrap()
                    .then(() => {
                        dispatch(invalidateGisUserCache());
                        toastr.success("Added users to groups");
                        onCloseClick();
                    })
                    .catch(() => toastr.error("An error occured while adding users to groups"));
            })
            .catch((err) => toastr.error(err.message));
    };

    const onCloseClick = () => {
        onClose();
        setSelectedGroups([]);
        setModifiedUsers([]);
        setCurrentStep(StepEnum.ASSIGN_ROLES);
    };

    const renderActions = () => {
        switch (currentStep) {
            case StepEnum.ASSIGN_ROLES:
                return (
                    <Button variant="contained" color="primary" onClick={onNextClick} disabled={modifiedUsers.length === 0} data-testid={nextButtonTestId}>
                        Next
                    </Button>
                );
            case StepEnum.ADD_TO_GROUP:
                return (
                    <>
                        <Button variant="contained" color="primary" onClick={onNextClick} disabled={selectedGroups.length === 0} data-testid={nextButtonTestId}>
                            Next
                        </Button>
                        <Button variant="contained" color="secondary" onClick={onSkipClick} data-testid={skipButtonTestId}>
                            Skip
                        </Button>
                        <Button variant="text" color="primary" onClick={onBackClick} data-testid={backButtonTestId}>
                            Back
                        </Button>
                    </>
                );
            case StepEnum.CONFIRM:
                return (
                    <>
                        <Button variant="contained" color="primary" onClick={onConfirmClick} data-testid={confirmButtonTestId}>
                            Confirm
                        </Button>
                        <Button variant="text" color="primary" onClick={onBackClick} dadta-testid={backButtonTestId}>
                            Back
                        </Button>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onCloseClick}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            classes={{
                paper: classes.pendingUsersModal
            }}
            PaperProps={{ "data-testid": modalTestId } as React.HTMLAttributes<HTMLElement>}
        >
            <DialogTitle>
                <div className={classes.flex}>
                    <CustomTypography variant="h2" textWeight="bold">
                        Pending Users
                    </CustomTypography>
                    <CloseIcon className={classes.clickable} onClick={onCloseClick} data-testid={closeIconTestId} />
                </div>
                <Stepper activeStep={currentStep} className={classes.stepperContainer}>
                    {Object.values(StepEnum)
                        .filter((x) => !isNaN(Number(x)))
                        .map((step) => {
                            return (
                                <Step key={stepTitles[step]}>
                                    <StepLabel>{stepTitles[step]}</StepLabel>
                                </Step>
                            );
                        })}
                </Stepper>
            </DialogTitle>
            <DialogContent>
                <SwipeableViews index={currentStep}>
                    <div hidden={currentStep !== StepEnum.ASSIGN_ROLES}>
                        <PendingUsersListView modifiedUsers={modifiedUsers} onRoleChange={onRoleChange} />
                    </div>
                    <div hidden={currentStep !== StepEnum.ADD_TO_GROUP}>
                        <PendingUsersGroupsAssignView
                            onGroupToggle={onGroupToggle}
                            selectedGroups={selectedGroups}
                            groupsFetching={groupsLoading}
                            groups={groups ? groups.filter((group) => !group.isIndividualGroup) : []}
                        />
                    </div>
                    <div hidden={currentStep !== StepEnum.CONFIRM} className={loading ? classes.loadingContainer : ""}>
                        <PendingUsersConfirmView users={modifiedUsers} groups={selectedGroups} />
                    </div>
                </SwipeableViews>
            </DialogContent>
            <DialogActions>{renderActions()}</DialogActions>
        </Dialog>
    );
};

export default PendingUsersModal;

const confirmButtonTestId = "qa-pending-users-modal-confirm-button";
const nextButtonTestId = "qa-pending-users-modal-next-button";
const skipButtonTestId = "qa-pending-users-modal-skip-button";
const backButtonTestId = "qa-pending-users-modal-back-button";
const closeIconTestId = "qa-pending-users-modal-close-icon";
const modalTestId = "qa-modal";
