import React, { useState } from "react";
import { useDispatch } from "react-redux";

import toastr from "../../CustomToastr/CustomToastr";
import { handleError } from "../../../utils/networkErrorUtils";
import { replaceDataset } from "../../../actions/datasets";

import FileLoader from "../FileLoader/FileLoader";
import CustomTypography from "../../CustomTypography/CustomTypography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomModal from "../../CustomModal/CustomModal";

const DataReplacerModal = ({ datasetId, open, handleClose }) => {
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgressPercentage, setUploadProgressPercentage] = useState(0);

    const dispatch = useDispatch();

    const onFileChanged = (file, data) => {
        setFile(file);
        setErrors([]);
    };

    const onhandleClose = () => {
        if (uploading) return;

        handleClose();

        setFile(null);
    };

    const onReplaceDataset = () => {
        setUploading(true);

        const formData = new FormData();

        formData.append("file", file);
        formData.append("generateCache", true);

        const config = {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgressPercentage(percentCompleted);
            }
        };

        dispatch(replaceDataset(datasetId, formData, config))
            .then((res) => {
                toastr.success("Data Replaced");
                handleClose();
                setUploading(false);
                setFile(null);
                setUploadProgressPercentage(0);
            })
            .catch((err) => {
                handleError(err);

                setUploading(false);
                setFile(null);
                setUploadProgressPercentage(0);
            });
    };

    const errorsList = errors.map((error) => {
        return (
            <div className="error">
                <CustomTypography variant="body2">Missing column: {error}</CustomTypography>
                <ErrorOutlineIcon />
            </div>
        );
    });

    return (
        <CustomModal handleClose={onhandleClose} isOpen={open} onConfirm={onReplaceDataset} dialogTitle={"Replace Dataset"} dialogType={"upload"} disabled={!file}>
            <div className="errors">{errorsList}</div>
            <FileLoader acceptedFiles={[".geojson"]} changeFile={onFileChanged} uploading={uploading} uploadProgressPercentage={uploadProgressPercentage} />
        </CustomModal>
    );
};

export default DataReplacerModal;
