import { SvgIcon, TableRow, TableCell } from "@mui/material";
import React, { FC, useState } from "react";
import OverflowCell from "components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import TooltipCell from "components/CustomTable/components/customCells/TooltipCell/TooltipCell";

import NameCell from "components/CustomTable/components/customCells/NameCell/NameCell";
import { CELL_SIZES } from "utils/constants/cellSizes";
import * as EnumUtils from "utils/enumUtils";
import { getGeometryIcon } from "utils/datasets/getGeometryIcon";
import { cacheStateColor } from "utils/datasets/cacheStateColor";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useStyles } from "./styles";
import clsx from "clsx";
import ImageIcon from "@mui/icons-material/Image";
import { DATA_TYPES } from "utils/constants/dataType";
import dayjs from "dayjs";
import { fromNowWithCheck } from "utils/timeUtils";

type Props = {
    data: any;
    index: number;
    canDragAndDrop?: boolean;
};
const DatasetRow: FC<Props> = ({ data, index, canDragAndDrop = true }) => {
    const classes = useStyles();

    const [isBeingDragged, setIsBeingDragged] = useState(false);

    const cacheStatusToClass = (cacheStatus: number) => {
        return EnumUtils.toCacheStatusString(cacheStatus);
    };

    const onDragStart: React.DragEventHandler<HTMLTableRowElement> = (e) => {
        e.stopPropagation();

        const dropData = {
            data: data,
            resourceId: data.id,
            type: "data",
            depth: 0,
            index
        };
        e.dataTransfer.setData("text/plain", JSON.stringify(dropData));

        setIsBeingDragged(true);

        return false;
    };

    const onDragEnd: React.DragEventHandler<HTMLTableRowElement> = (e) => {
        e.stopPropagation();
        setIsBeingDragged(false);
    };

    const cacheStatus = cacheStatusToClass(data.cacheStatus);
    const geometryTypeTooltip = data.chipType === DATA_TYPES.vector ? cacheStatusToClass(data.cacheStatus) + " " + data.geometryType : "Raster";

    if (data.included) return null;

    return (
        <TableRow
            draggable={canDragAndDrop}
            className={clsx(classes.row, { [classes.isDragged]: isBeingDragged })}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            data-testid={rowButtonTestId}
        >
            <TableCell>
                <DragIndicatorIcon fontSize="small" />
            </TableCell>

            <TooltipCell tooltipTitle={geometryTypeTooltip}>
                {data.chipType === DATA_TYPES.vector ? <SvgIcon color={cacheStateColor[cacheStatus]}>{getGeometryIcon(data.geometryType)}</SvgIcon> : <ImageIcon color="primary" />}
            </TooltipCell>

            <NameCell size="small" cellSize={CELL_SIZES.small}>
                {data.name}
            </NameCell>

            <TooltipCell tooltipTitle={dayjs(data.modifiedUtc).format("MMMM DD YYYY, h:mm:ss a")}>{fromNowWithCheck(dayjs(data.modifiedUtc))}</TooltipCell>

            <OverflowCell size="small" cellSize={CELL_SIZES.extraSmall}>
                {!!data.schemaName && data.schemaName?.replace(/_/g, " ")}
            </OverflowCell>
        </TableRow>
    );
};

export default DatasetRow;

const rowButtonTestId = "qa-app-edit-view-add-tool-add-dataset-row-button";
