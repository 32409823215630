import { TextField } from "@mui/material";
import { FC } from "react";
import { useStyles } from "../styles";
import CustomTypography from "components/CustomTypography/CustomTypography";

type Props = {
    displayName: string;
    setDisplayName: (displayName: string) => void;
};

const MilestoneDisplayNameStep: FC<Props> = ({ displayName, setDisplayName }) => {
    const classes = useStyles();

    return (
        <>
            <CustomTypography variant="subtitle2" textWeight="bold" className={classes.description} fontSize={12}>
                1: Provide a suggestive name for your milestone to help you track it efficiently within GIS:
            </CustomTypography>
            <TextField
                label="Milestone display name"
                variant="filled"
                required
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                inputProps={displayNameInputProps}
                fullWidth
                InputLabelProps={{ className: classes.inputLabel }}
            />
        </>
    );
};

export default MilestoneDisplayNameStep;

const displayNameInputProps = { "data-testid": "qa-dpr-integration-add-milestone-modal-display-name-input" };
