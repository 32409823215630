import { FC, useRef, useState } from "react";
import SearchBarComponent from "components/SearchBar/SearchBar";
import { useStyles } from "./styles";
import { Resource } from "../UserGroupsDrawer";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type Props = {
    searchBy: Resource;
    searchValue: string;
    setSearchBy: (resource: Resource) => void;
    setSearchValue: (value: string) => void;
};

const SearchWithOptions: FC<Props> = ({ searchBy, searchValue, setSearchBy, setSearchValue }) => {
    const classes = useStyles();

    const anchorRef = useRef<HTMLButtonElement>(null);

    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleMenuItemClick = (_: React.MouseEvent<HTMLLIElement, MouseEvent>, resource: Resource) => {
        setSearchBy(resource);
        setOpen(false);
    };

    const getDataTestId = (options: string) => {
        const title = options.toLocaleLowerCase().replaceAll(" ", "-");
        return optionsTestId + title;
    };

    return (
        <>
            <ButtonGroup className={classes.container}>
                <SearchBarComponent
                    searchPlaceholder={"Search by " + searchBy}
                    value={searchValue}
                    className={classes.searchBar}
                    onChange={setSearchValue}
                    textFieldClassName={undefined}
                    noPadding
                    noBg
                />
                <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select search by"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    ref={anchorRef}
                    data-testid={moreButtonTestId}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 2
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {Object.keys(Resource).map((option) => (
                                        <MenuItem
                                            key={option}
                                            selected={Resource[option] === searchBy}
                                            onClick={(event) => handleMenuItemClick(event, Resource[option])}
                                            data-testid={getDataTestId(option)}
                                        >
                                            Search by {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default SearchWithOptions;

const moreButtonTestId = "qa-user-details-drawer-search-more-options-button";
const optionsTestId = "qa-user-details-drawer-search-option-";
