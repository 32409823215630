import makeStyles from "@mui/styles/makeStyles";

const useAddDataToolStyles = makeStyles((theme: any) => ({
    addDataTool: {
        width: 550,
        backgroundColor: theme.customColors.appBgColorLight,
        overflow: "auto"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `0 ${theme.customSpacing.generalSidePadding}`,
        height: theme.customSpacing.headerHeight
    },
    closeButton: {
        cursor: "pointer"
    },
    addIcon: {
        fontSize: 60
    },
    addButton: {
        position: "absolute",
        bottom: 15,
        right: 25
    }
}));

export default useAddDataToolStyles;
