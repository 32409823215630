import { unwrapResult } from "@reduxjs/toolkit";
import { resetProjectData } from "actions/globalActions";
import { useIdsRequestInterceptor } from "app/hooks/useIdsRequestInterceptor";
import { useTokenRequestInterceptor } from "app/hooks/useTokenRequestInterceptor";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import { fetchCurrentUser } from "features/auth/actions";
import { fetchAccountPermissions, fetchCoreClientsWithProjects, fetchModules } from "features/core/actions";
import { getModuleId } from "features/core/selectors";
import { setIds } from "features/core/slice";
import { getFeatureFlags } from "features/featureFlags/actions";
import { fetchHealth } from "features/health/actions";
import { HealthEnum } from "features/health/model/HealthEnum";
import { getHealth } from "features/health/selectors";
import { GisRole } from "features/users/model/GisRole";
import { FCWC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { saveCoreIds } from "utils/coreInfo";
import { decryptId } from "utils/cryptoUtils";
import { ErrorTypes } from "views/error/ErrorView";
import { getStyleConfig } from "./actions/config";
import { StatusType } from "features/core/models/StatusType";
import { resetApiState } from "./store/baseAtlasApi";

type Params = {
    clientId: string;
    projectId: string;
};

const Bootstrap: FCWC = ({ children }) => {
    const [criticalResourcesLoaded, setCriticalResourcesLoaded] = useState(false);
    const [coreInfoLoaded, setCoreInfoLoaded] = useState(false);

    const moduleId = useAppSelector(getModuleId);
    const health = useAppSelector(getHealth);

    const { clientId, projectId } = useParams<Params>();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const tokenInterceptorLoading = useTokenRequestInterceptor();
    const idsInterceptorLoading = useIdsRequestInterceptor();
    const interceptorsLoading = tokenInterceptorLoading || idsInterceptorLoading;

    useEffect(() => {
        dispatch(resetProjectData());
        dispatch(resetApiState());
        setCoreInfoLoaded(false);
        setCriticalResourcesLoaded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, projectId]);

    useEffect(() => {
        if (!coreInfoLoaded) {
            saveCoreIds(clientId, projectId);
            dispatch(setIds({ clientId: decryptId(clientId), projectId: decryptId(projectId) }));
            setCoreInfoLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coreInfoLoaded]);

    useEffect(() => {
        if (!coreInfoLoaded) return;

        switch (health) {
            case HealthEnum.Unknown:
                dispatch(fetchHealth());
                loadCoreResources();
                break;
            case HealthEnum.Degraded:
            case HealthEnum.Unhealthy:
                errorRedirect(ErrorTypes.NoResources);
                break;
            case HealthEnum.Healthy:
                dispatch(fetchCurrentUser())
                    .then(unwrapResult)
                    .then((res) => {
                        if (res.role === GisRole.Pending) {
                            return errorRedirect(ErrorTypes.PendingUser);
                        }
                        loadResources();
                    })
                    .catch(() => errorRedirect(ErrorTypes.NoPermission));

                break;
            default:
                return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [health, coreInfoLoaded]);

    const loadResources = () => {
        // Non Critical

        // Critical
        const styleConfigPromise = dispatch(getStyleConfig());
        const flagsPromise = dispatch(getFeatureFlags());

        const promises = [styleConfigPromise, flagsPromise].map((x) => x.then(unwrapResult));

        Promise.all(promises)
            .then(() => setCriticalResourcesLoaded(true))
            .catch(() => errorRedirect(ErrorTypes.NoPermission));
    };

    const loadCoreResources = () => {
        dispatch(fetchModules());
        dispatch(fetchAccountPermissions());
        dispatch(fetchCoreClientsWithProjects(moduleId))
            .then(unwrapResult)
            .then((clients) => {
                const client = clients.find((x) => x.id === clientId);
                const project = client?.projects?.find((x) => x.id === projectId);
                if (client?.status === StatusType.Inactive || project?.status === StatusType.Inactive) {
                    errorRedirect(ErrorTypes.NoPermission);
                }
            });
    };

    const errorRedirect = (errorType: ErrorTypes) => {
        history.push({
            pathname: `/${clientId}/${projectId}/error`,
            state: { errorType }
        });
    };

    return (
        <LoadingPlaceholder loading={!criticalResourcesLoaded || !coreInfoLoaded || interceptorsLoading} message="Getting user data">
            {children}
        </LoadingPlaceholder>
    );
};

export default Bootstrap;
