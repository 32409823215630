import { useState, useMemo } from "react";
import { useTheme } from "@mui/styles";
import { Button, LinearProgress } from "@mui/material";
import toastr from "components/CustomToastr/CustomToastr";
import { useStyles } from "./styles";
import selectFromComp from "utils/icons/select_from_comp.svg";
import clsx from "clsx";
import OverflowTip from "components/OverflowTip/OverflowTip";
import CustomTypography from "components/CustomTypography/CustomTypography";
import DndUploadContainer from "components/DndUploadContainer/DndUploadContainer";

const FileLoader = ({ acceptedFiles, changeFile, fileState, uploading, uploadProgressPercentage }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [dragging, setDragging] = useState(false);

    const acceptedFileTypes = useMemo(() => acceptedFiles.join(","), [acceptedFiles]);

    const onFileDragOver = (e) => {
        e.preventDefault();
        !dragging && setDragging(true);
    };

    const onFileDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const onFileDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        handleFile(e.dataTransfer.files[0]);
    };

    const onFileChanged = (e) => {
        if (e.target.files.length === 0) return;
        handleFile(e.target.files[0]);
    };

    const handleFile = (file) => {
        const fileExtension = "." + file.name.split(".").pop();

        if (!acceptedFiles.includes(fileExtension)) return toastr.error("Invalid file type");

        changeFile(file);
    };

    return (
        <>
            {!fileState ? (
                <DndUploadContainer
                    dragging={dragging}
                    acceptedFormats={acceptedFiles}
                    onFileDrop={onFileDrop}
                    onFileDragLeave={onFileDragLeave}
                    onFileDragOver={onFileDragOver}
                    onFileChanged={onFileChanged}
                />
            ) : (
                <div
                    className={clsx(classes.uploadContainer, classes.flexBox, { [classes.dragging]: dragging })}
                    onDrop={onFileDrop}
                    onDragOver={onFileDragOver}
                    onDragLeave={onFileDragLeave}
                >
                    <div className={clsx(classes.flexBox, { [classes.pointerEventNone]: dragging })}>
                        {!uploading && <CustomTypography variant="h3">Selected file:</CustomTypography>}
                        <OverflowTip variant="h3" textWeight="bold" color={theme.customColors.appTextColorLight}>
                            {fileState.name}
                        </OverflowTip>
                        {uploading && (
                            <>
                                <CustomTypography variant="body1" className={classes.percentage}>
                                    {uploadProgressPercentage}%
                                </CustomTypography>
                                <LinearProgress className={classes.progressBar} variant="determinate" value={uploadProgressPercentage} />
                            </>
                        )}
                        {!dragging && !uploading && (
                            <Button
                                className={classes.buttonSpacing}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    document.getElementById("file-button").click();
                                }}
                                startIcon={<img alt="" src={selectFromComp} />}
                            >
                                Change file
                            </Button>
                        )}
                    </div>
                    <div>
                        <input accept={acceptedFileTypes} className={classes.notVisible} multiple={false} id="file-button" type="file" onChange={onFileChanged} />
                    </div>
                </div>
            )}
        </>
    );
};

export default FileLoader;
