import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    deleteBtn: {
        color: theme.customColors.errorColor,
        width: 24,
        height: 24,
        borderRadius: 3,
        backgroundColor: theme.customColors.errorColorLight,
        cursor: "pointer"
    },
    disabled: {
        cursor: "default",
        opacity: 0.7
    }
}));
