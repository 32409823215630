import axiosClient from "./apiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PatchBasemap } from "models/basemap/PatchBasemap";

export const fetchLibraryBasemaps = createAsyncThunk("fetchLibraryBasemaps", () => axiosClient.get("basemap").then((res) => res.data));

export const addLibraryBasemap = createAsyncThunk("addLibraryBasemap", (newBasemap: any) => axiosClient.post(`basemap`, newBasemap));

export type PatchLibraryBasemapArg = { basemapId: string; newBasemap: PatchBasemap };
export const patchLibraryBasemap = createAsyncThunk("patchLibraryBasemap", ({ basemapId, newBasemap }: PatchLibraryBasemapArg) =>
    axiosClient.patch(`basemap/${basemapId}`, newBasemap)
);

export const deleteLibraryBasemap = createAsyncThunk("deleteLibraryBasemap", (basemapId: string) => axiosClient.delete(`basemap/${basemapId}`));
