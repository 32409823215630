import { makeStyles } from "@mui/styles";
import { auto } from "csv2geojson";

export const useStyles = makeStyles((theme) => ({
    basemapCard: {
        display: "flex",
        margin: "10px 0",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
    },
    image: { height: 92, width: 92 },
    marginRight: { marginRight: 12 },
    paddingTop: { paddingTop: 8 },
    menuButton: {
        borderRadius: 3,
        height: 23.5,
        width: 25,
        backgroundColor: theme.customColors.contextButtonColor,
        color: theme.customColors.black
    },
    topSection: {
        display: "flex",
        alignItems: "center",
        height: "fit-content",
        width: "100%"
    },
    basemapDetailes: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%"
    },
    addedTag: {
        display: "flex",
        alignItems: "center",
        padding: "6px 1px" // this padding is to make the tag take as much space in the layout as the add button
    },
    checkIcon: {
        width: 18,
        marginRight: 8
    },
    marginLeftAuto: {
        marginLeft: "auto"
    },
    basemapUrl: {
        width: 288,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    basemapTitle: {
        width: 128,
        textTransform: "capitalize"
    },
    urlContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    copyButton: {
        minWidth: 24,
        width: 24,
        minHeight: 32,
        height: 32
    },
    relative: {
        position: "relative"
    },
    absolute: {
        position: "absolute",
        top: 16
    }
}));
