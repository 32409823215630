import { FC, useState, useEffect } from "react";

import toastr from "components/CustomToastr/CustomToastr";
import { handleError } from "utils/networkErrorUtils";

import CustomTypography from "components/CustomTypography/CustomTypography";
import CustomModal from "components/CustomModal/CustomModal";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { publishAppThunk } from "actions/applications";
import { setAppPublishedStatus } from "reducers/appData/appData";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { unwrapResult } from "@reduxjs/toolkit";
import * as types from "../../../../../actions/actionTypes";

type Props = {
    open: boolean;
    handleClose: () => void;
    appId: string;
};

const AppPublishModal: FC<Props> = ({ open, handleClose, appId }) => {
    const [sendEmailNotification, setSendEmailNotification] = useState(false);

    const featureFlags = useAppSelector(getFeatureFlags);

    const calculateRows = (text: string) => {
        const lineCount = text.split("\n").length;
        const minRows = 3;
        return Math.max(minRows, lineCount);
    };

    const [emailContents, setEmailContents] = useState("");

    const dispatch = useAppDispatch();

    const onHandleClose = () => {
        setSendEmailNotification(false);
        handleClose();
    };

    const publish = () => {
        onHandleClose();
        dispatch(publishAppThunk({ applicationId: appId, sendEmailNotification: sendEmailNotification, message: emailContents }))
            .then(unwrapResult)
            .then(() => {
                dispatch(setAppPublishedStatus(true));
                dispatch({ type: types.UPDATE_APP_PUBLISHED, result: { id: appId } });
                toastr.success("App published");
            })
            .catch((err: any) => handleError(err));
    };

    return (
        <CustomModal handleClose={onHandleClose} isOpen={open} onConfirm={publish} dialogTitle={"Publish Application"} dialogType={"confirm"}>
            <Box gap={2} display="flex" flexDirection="column">
                <CustomTypography variant="h6" textWeight="semibold">
                    Are you sure you want to publish?
                </CustomTypography>
                {featureFlags.NOTIFICATIONS && (
                    <>
                        <FormControlLabel
                            control={<Checkbox size="small" />}
                            label="Send email notification"
                            sx={{ marginLeft: 0 }}
                            checked={sendEmailNotification}
                            onChange={(_, checked) => {
                                setSendEmailNotification(checked);
                            }}
                        />
                        {sendEmailNotification && (
                            <TextField
                                multiline
                                value={emailContents}
                                variant="filled"
                                label="Email content"
                                rows={calculateRows(emailContents)}
                                sx={{ height: "fit-content", "& .MuiInputBase-root": { height: "fit-content" } }}
                                inputProps={{ style: { height: "fit-content" } }}
                                onChange={(e) => {
                                    setEmailContents(e.target.value);
                                }}
                            />
                        )}
                    </>
                )}
            </Box>
        </CustomModal>
    );
};

export default AppPublishModal;
