import { FC, useEffect, useState } from "react";

import { ComponentProps } from "../AppSettingsView";

import { applicationEmailNotificationOptIn, applicationEmailNotificationOptOut, getApplicationEmailNotifications } from "features/emailNotifications/actions";
import { useParams } from "react-router-dom";
import { NotificationOperation } from "features/emailNotifications/models/NotificationOperation";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import { Box, FormControlLabel, Switch } from "@mui/material";
import toastr from "components/CustomToastr/CustomToastr";

type Params = {
    appId: string;
};

type Notification = {
    operation: NotificationOperation;
    title: string;
    description: string;
};

const notifications: Notification[] = [
    {
        operation: NotificationOperation.ApplicationPublish,
        title: "Application Publish",
        description: "Receive an email in case the admin notifies users when a new version of the application is published."
    }
];

const EmailNotificationsSection: FC<ComponentProps> = ({ disabled }) => {
    const [emailNotifications, setEmailNotifications] = useState<Set<NotificationOperation>>(new Set());
    const [loading, setLoading] = useState<boolean>(false);

    const { appId } = useParams<Params>();

    useEffect(() => {
        setLoading(true);
        getApplicationEmailNotifications(appId)
            .then((res) => {
                setEmailNotifications(new Set(res.map((x: string) => NotificationOperation[x])));
            })
            .finally(() => setLoading(false));
    }, [appId]);

    const optIn = (operation: NotificationOperation) => {
        setLoading(true);

        applicationEmailNotificationOptIn({ appId, operation })
            .then(() => {
                setEmailNotifications((prev) => new Set(prev.add(operation)));
                toastr.success("Successfully opted-in");
            })
            .catch((err) => toastr.error("Failed to opt-in"))
            .finally(() => setLoading(false));
    };

    const optOut = (operation: NotificationOperation) => {
        setLoading(true);

        applicationEmailNotificationOptOut({ appId, operation })
            .then(() => {
                setEmailNotifications((prev) => {
                    prev.delete(operation);
                    return new Set(prev);
                });
                toastr.success("Successfully opted-out");
            })
            .catch(() => toastr.error("Failed to opt-out"))
            .finally(() => setLoading(false));
    };

    const toggle = (operation: NotificationOperation) => {
        emailNotifications.has(operation) ? optOut(operation) : optIn(operation);
    };

    const getTestId = (title: string) => {
        return switchButtonTestId + title.toLocaleLowerCase().replaceAll(" ", "-");
    };

    return (
        <>
            {notifications.map((notification) => (
                <div key={notification.title}>
                    <FormControlLabel
                        disabled={loading || disabled}
                        control={
                            <Switch
                                edge="end"
                                checked={emailNotifications.has(notification.operation)}
                                inputProps={{ "data-testid": getTestId(notification.title) } as DataTestIdProps}
                                onClick={() => toggle(notification.operation)}
                            />
                        }
                        label={<Box marginLeft={1}>{notification.title}</Box>}
                    />
                    <InfoTextSection paddingTop={0.5} paddingBottom={2} iconSize="extra-small" textVariant="body2" textWeight="semibold">
                        {notification.description}
                    </InfoTextSection>
                </div>
            ))}
        </>
    );
};

export default EmailNotificationsSection;

const switchButtonTestId = "qa-app-edit-view-settings-email-notifications-switch-";
type DataTestIdProps = React.InputHTMLAttributes<HTMLInputElement> & {
    "data-testid"?: string;
};
