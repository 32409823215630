import PersonIcon from "@mui/icons-material/Person";
import {
    Avatar,
    Box,
    DialogContentText,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    TextField,
    Tooltip
} from "@mui/material";
import { CoreUser } from "features/users/model/CoreUser";
import { GisRole } from "features/users/model/GisRole";
import { FC, useMemo, useState } from "react";
import { useStyles } from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { GisUser } from "features/users/model/GisUser";
import { useFetchPendingUsersQuery } from "features/users/api";
import LoadingPlaceholder from "../../../../../components/LoadingPlaceholder/LoadingPlaceholder";
import ErrorPlaceholder from "../../../../../components/ErrorPlaceholder/ErrorPlaceholder";

type Props = {
    onRoleChange: (role: GisRole, user: CoreUser) => void;
    modifiedUsers: GisUser[];
};

const PendingUsersListView: FC<Props> = ({ onRoleChange, modifiedUsers }) => {
    const classes = useStyles();
    const [searchString, setSearchString] = useState("");

    const { data: users = [], isFetching, isError, refetch } = useFetchPendingUsersQuery();

    const assignableRoles = Object.keys(GisRole).filter((role) => role !== "Pending");

    const sortedUsers = useMemo(
        () =>
            users.slice().sort((user1: CoreUser, user2: CoreUser) => {
                const firstNameCompareResult = user1.firstName.toLocaleLowerCase().localeCompare(user2.firstName.toLocaleLowerCase());

                if (firstNameCompareResult) return firstNameCompareResult;

                return user1.lastName.toLocaleLowerCase().localeCompare(user2.lastName.toLocaleLowerCase());
            }),
        [users]
    );

    const filteredUsers = sortedUsers.filter(
        (user) =>
            `${user.firstName} ${user.lastName}`.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
            user.username.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    );

    const getRadioValue = (user: CoreUser) => {
        const modifiedUser = modifiedUsers.find((u) => u.coreAccountId === user.coreAccountId);

        return modifiedUser?.role ?? null;
    };

    return (
        <>
            <DialogContentText>Assign a WindGIS role to users that are currently not active in WindGIS, but are present in Core</DialogContentText>
            <Box width="100%">
                <LoadingPlaceholder loading={isFetching} message="Fetching pending users">
                    <ErrorPlaceholder error={isError} message="Failed to fetch pending users" onTryAgain={() => refetch()}>
                        <List>
                            <div className={classes.searchFieldContainer}>
                                <TextField
                                    className={classes.searchField}
                                    fullWidth
                                    placeholder="Search users"
                                    value={searchString}
                                    variant="filled"
                                    size="small"
                                    onChange={(e) => setSearchString(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {searchString === "" ? null : (
                                                    <IconButton onClick={() => setSearchString("")}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                            {filteredUsers.map((user) => (
                                <ListItem key={user.coreAccountId} disablePadding>
                                    <ListItemAvatar>
                                        <Tooltip title={user.isActive ? "User is active" : "User is inactive"}>
                                            <Avatar className={user.isActive ? classes.activeUser : classes.inactiveUser}>
                                                <PersonIcon />
                                            </Avatar>
                                        </Tooltip>
                                    </ListItemAvatar>
                                    <Tooltip title={user.username} placement="left-start">
                                        <ListItemText
                                            primary={`${user.firstName} ${user.lastName}`}
                                            secondary={user.username}
                                            primaryTypographyProps={{
                                                noWrap: true,
                                                style: {
                                                    maxWidth: "calc(100% - 260px)",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }
                                            }}
                                            secondaryTypographyProps={{
                                                noWrap: true,
                                                style: {
                                                    maxWidth: "calc(100% - 260px)",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                    <ListItemSecondaryAction>
                                        <FormControl>
                                            <RadioGroup row className={classes.roleRadioGroup} value={getRadioValue(user)}>
                                                {assignableRoles.map((assignableRole) => (
                                                    <FormControlLabel
                                                        key={assignableRole}
                                                        label={assignableRole}
                                                        value={assignableRole}
                                                        control={<Radio data-testid={roleTestIds[assignableRole]} onChange={() => onRoleChange(GisRole[assignableRole], user)} />}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </ErrorPlaceholder>
                </LoadingPlaceholder>
            </Box>
        </>
    );
};

export default PendingUsersListView;

const adminRoleTestId = "qa-pending-users-modal-admin-role-radio-button";
const userRoleTestId = "qa-pending-users-modal-user-role-radio-button";
const uploaderRoleTestId = "qa-pending-users-modal-uploader-role-radio-button";

const roleTestIds = {
    [GisRole.Admin]: adminRoleTestId,
    [GisRole.User]: userRoleTestId,
    [GisRole.Uploader]: uploaderRoleTestId
};
