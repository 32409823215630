import { Menu, MenuItem } from "@mui/material";
import { useStyles } from "./styles";
import { FILTER_OPTION } from "../../constants/filterOptions";
import clsx from "clsx";

const FilterMenu = ({ open, onClose, anchor, setFilterBy, filterBy }) => {
    const classes = useStyles();

    const filterOptionsKeys = Object.keys(FILTER_OPTION);

    const getDataTestId = (option) => {
        const title = option.toLocaleLowerCase().replaceAll(" ", "-");
        return filterOptionTestId + title;
    };

    return (
        <Menu open={open} onClose={onClose} anchorEl={anchor}>
            {filterOptionsKeys.map((option) => {
                return (
                    <MenuItem
                        key={option}
                        className={clsx(classes.sortingOption, { [classes.selected]: filterBy === FILTER_OPTION[option] })}
                        onClick={() => {
                            setFilterBy(FILTER_OPTION[option]);
                            onClose();
                        }}
                        data-testid={getDataTestId(FILTER_OPTION[option])}
                    >
                        {FILTER_OPTION[option]}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};

export default FilterMenu;

const filterOptionTestId = "qa-app-edit-view-settings-add-basemap-tool-filter-option-";
