import { createSlice } from "@reduxjs/toolkit";
import { resetProjectData } from "actions/globalActions";
import { getStyleConfig } from "../actions/config";

type SliceState = {
    config: any;
};

const initialState: SliceState = {
    config: null
};

const styleSlice = createSlice({
    name: "style",
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(getStyleConfig.fulfilled, (state, { payload }) => {
                state.config = payload;
            })
            .addCase(resetProjectData, () => initialState)
});

export default styleSlice.reducer;
