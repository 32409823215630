import makeStyles from "@mui/styles/makeStyles";

export const useInfoTableStyles = makeStyles((theme: any) => ({
    root: {
        position: "absolute",
        top: theme.customSpacing.headerHeight,
        left: "16px",
        right: "16px",
        height: "70%",
        display: "flex",
        flexDirection: "column"
    },
    tableContainer: {
        overflowY: "auto",
        marginLeft: 8,
        maxWidth: "calc(100% - 8px)",
        flexGrow: 1
    },
    modalHeader: {
        backgroundColor: "#024F79",
        padding: theme.spacing(1),
        color: "#fff",
        textAlign: "center"
    },

    tableCell: {
        borderRight: "1px solid lightgrey",
        cursor: "pointer",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 200
    },
    tablePagination: {
        flexShrink: 0
    }
}));
