import { useParams } from "react-router-dom";
import ConfigureIntegrationCta from "./ConfigureIntegrationCta/ConfigureIntegrationCta";
import { useDeleteDprDatasetMutation, useFetchDprDatasetQuery, useSetupDprDatasetMutation } from "features/dprIntegration/api";
import { FC, useMemo, useState } from "react";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";
import DprIntegration from "./DprIntegration/DprIntegration";
import ConfigureIntegration from "./ConfigureIntegration/ConfigureIntegration";
import { useDatasetEditViewStyles } from "../../styles";
import DprFooter from "../DatasetEditFooter/DprFooter";
import { useFormikContext } from "formik";
import { Column } from "features/datasets/models/Column";
import { DprLocationMapping } from "features/dprIntegration/models/DprLocationMapping";
import { DprProgressMilestone } from "features/dprIntegration/models/DprProgressMilestone";
import toastr from "components/CustomToastr/CustomToastr";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import { useCreateOrReplaceInfoboxMutation, useDeleteInfoboxMutation } from "features/infobox/api";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getFeatureFlags } from "features/featureFlags/selectors";

type Params = {
    datasetId: string;
};

const DprTab: FC = () => {
    const classes = useDatasetEditViewStyles();

    const {
        values: { columns }
    } = useFormikContext<{ columns: Column[] }>();

    const { datasetId } = useParams<Params>();

    const { data: dprDataset = null, isLoading: fetchLoading, isError, error, refetch } = useFetchDprDatasetQuery(datasetId);
    const [setupDprDatasetMutation, { isLoading: setupLoading }] = useSetupDprDatasetMutation();
    const [deleteDprDatasetMutation, { isLoading: deleteLoading }] = useDeleteDprDatasetMutation();

    const [createOrReplaceInfoboxMutation] = useCreateOrReplaceInfoboxMutation();
    const [deleteInfoboxMutation] = useDeleteInfoboxMutation();

    const [configureIntegration, setConfigureIntegration] = useState(false);

    const [statusColumnId, setStatusColumnId] = useState(columns.find((x) => x.name === "progress" || x.name === "status")?.id ?? null);
    const [locationColumnId, setLocationColumnId] = useState(columns.find((x) => x.name === "name" || x.name === "location")?.id ?? null);
    const [locationMappings, setLocationMappings] = useState<DprLocationMapping[]>([]);
    const [milestones, setMilestones] = useState<DprProgressMilestone[]>([]);

    const [addMilestoneModalOpen, setAddMilestoneModalOpen] = useState(false);

    const features = useAppSelector(getFeatureFlags);

    const hasIntegration = useMemo(() => !!dprDataset && !!Object.keys(dprDataset).length && !isError, [dprDataset, isError]);
    const isSetupValid = !!statusColumnId && !!locationColumnId;

    const setupDprDataset = () => {
        if (!isSetupValid) return;

        setupDprDatasetMutation({
            datasetId,
            request: {
                progressColumnId: statusColumnId,
                locationColumnId,
                locationMappings,
                milestones
            }
        }).then((res) => {
            if ("error" in res) {
                toastr.error(res.error.message || "Failed to configure the DPR Integration");
                return;
            }

            if (features.INFOBOX) {
                createOrReplaceInfoboxMutation({ datasetId, applicationId: null, name: "dpr" });
            }

            toastr.success("DPR Integration Configured");
            resetState();
            refetch();
        });
    };

    const deleteDprDataset = () => {
        const toastrConfirmOptions = {
            onOk: () =>
                deleteDprDatasetMutation(datasetId).then((res) => {
                    if ("error" in res) {
                        toastr.error(res.error.message || "Failed to undo the DPR Integration Setup");
                        return;
                    }

                    if (features.INFOBOX) {
                        deleteInfoboxMutation({ datasetId, applicationId: null });
                    }

                    toastr.success("Sucesfully deleted the DPR Integration Setup");

                    refetch();
                }),
            onCancel: () => {}
        };
        toastr.confirm("Are you sure you want to undo the DPR Integration Setup?", toastrConfirmOptions);
    };

    const resetState = () => {
        setStatusColumnId(columns.find((x) => x.name === "progress" || x.name === "status")?.id ?? null);
        setLocationColumnId(columns.find((x) => x.name === "name" || x.name === "location")?.id ?? null);
        setLocationMappings([]);
        setMilestones([]);
        setConfigureIntegration(false);
    };

    return (
        <>
            <div className={classes.page}>
                <LoadingPlaceholder loading={fetchLoading || setupLoading || deleteLoading}>
                    <ErrorPlaceholder error={isError && error?.code !== "404"} message="Encountered an error" textVariant="h3" onTryAgain={refetch}>
                        {hasIntegration ? (
                            <DprIntegration dprDataset={dprDataset!} addMilestoneModalOpen={addMilestoneModalOpen} setAddMilestoneModalOpen={setAddMilestoneModalOpen} />
                        ) : configureIntegration ? (
                            <ConfigureIntegration
                                statusColumnId={statusColumnId}
                                setStatusColumnId={setStatusColumnId}
                                locationColumnId={locationColumnId}
                                setLocationColumnId={setLocationColumnId}
                                locationMappings={locationMappings}
                                setLocationMappings={setLocationMappings}
                                milestones={milestones}
                                setMilestones={setMilestones}
                            />
                        ) : (
                            <ConfigureIntegrationCta isLoading={fetchLoading} onConfigureClick={() => setConfigureIntegration(true)} />
                        )}
                    </ErrorPlaceholder>
                </LoadingPlaceholder>
            </div>
            <DprFooter
                hasIntegration={hasIntegration}
                configureIntegration={configureIntegration}
                setupDprDataset={setupDprDataset}
                isSetupValid={isSetupValid}
                cancelDprDatasetSetup={resetState}
                deleteDprDatasetSetup={deleteDprDataset}
                addMilestone={() => setAddMilestoneModalOpen(true)}
            />
        </>
    );
};

export default DprTab;
