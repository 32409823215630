import makeStyles from "@mui/styles/makeStyles";

const useUploadInfoViewStyles = makeStyles(() => ({
    centered: {
        textAlign: "center"
    },

    infoTextSpacing: {
        marginBottom: 16,
        display: "flex",
        alignItems: "center"
    },

    iconSpacing: {
        marginRight: 8
    },
    beforeUploadInfo: {
        maxHeight: 636,
        overflow: "auto"
    }
}));

export default useUploadInfoViewStyles;
