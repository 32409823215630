import { useMediaQuery, useTheme } from "@mui/material";

export const useCurrentBreakpoint = (breakpointsOfInterest) => {
    const theme = useTheme();
    let breakpoints = ["xs", "sm", "md", "lg", "xl"];

    if (breakpointsOfInterest) {
        breakpoints = breakpointsOfInterest;
    }

    return (
        breakpoints.reverse().reduce((output, breakpoint) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(breakpoint));
            return !output && matches ? breakpoint : output;
        }, null) || "xs"
    );
};
