import { FC, useState } from "react";
import CustomModal from "components/CustomModal/CustomModal";
import FileLoader from "../FileLoader/FileLoader";
import toastr from "components/CustomToastr/CustomToastr";
import { handleError } from "utils/networkErrorUtils";
import { useUploadGeotiffMutation } from "features/rasters/api";
import { Raster } from "features/rasters/models/Raster";
import useComponentCancelToken from "app/hooks/useComponentCancelToken";

type Props = {
    raster: Raster;
    open: boolean;
    handleClose: () => void;
};

const GeotiffUploader: FC<Props> = ({ open, handleClose, raster }) => {
    const [file, setFile] = useState<File | null>(null);
    const [uploadProgressPercentage, setUploadProgressPercentage] = useState(0);
    const [cancelToken] = useComponentCancelToken();

    const [uploadGeotiff, { isLoading }] = useUploadGeotiffMutation();

    const isDoneUploading = uploadProgressPercentage === 100;

    const onCancelPreUpload = () => {
        handleClose();
        setFile(null);
    };

    const onUploadProgress = (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgressPercentage(percentCompleted);
    };

    const onUploadGeotiff = () => {
        if (isLoading || isDoneUploading) return;
        if (!file) return;

        uploadGeotiff({
            rasterId: raster.id,
            file: file,
            replaceExisting: Boolean(raster.geoTiff) || false,
            uploadProgressHandler: onUploadProgress,
            cancelToken: cancelToken.token
        })
            .unwrap()
            .then(() => {
                toastr.success("Geotiff Uploaded");
            })
            .catch((err) => {
                handleError(err);
            })
            .finally(() => {
                handleClose();
            });
    };

    const onFileChanged = (file: any) => {
        setFile(file);
    };

    return (
        <CustomModal
            handleClose={onCancelPreUpload}
            isOpen={open}
            onConfirm={onUploadGeotiff}
            dialogTitle={"Upload Geotiff"}
            dialogType={"upload"}
            disabled={!file}
            disableCancel={isLoading}
        >
            <FileLoader acceptedFiles={[".tif", ".tiff"]} changeFile={onFileChanged} fileState={file} uploading={isLoading} uploadProgressPercentage={uploadProgressPercentage} />
        </CustomModal>
    );
};

export default GeotiffUploader;
