import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as datasetActions from "../../../../../actions/datasets";
import { LayerStyleEditor } from "@emblautec/layer-styler-component";
import { getMapSources, getSourceMinZoomSelector } from "../../../../../selectors/map";
import { getDatasets } from "../../../../../selectors/datasets";
import * as mapActions from "../../../../../reducers/map";
import * as appDataActions from "../../../../../reducers/appData/appData";
import { getLayerVisibility, getSelectedLayer, getStylesOfLayer } from "../../../../../selectors/appData";
import * as GeometryUtils from "../../../../../utils/GeometryUtils";
import { getStyleConfig } from "../../../../../selectors/style";
import { makeDefaultStyle } from "../../../../../utils/creators/StyleCreators";
import { makeMapLayerFromDataset, makeMapLayerFromRaster } from "../../../../../utils/creators/MapLayerCreators";
import { makeZoomRange } from "../../../../../utils/creators/ZoomRangeCreators";
import { makeDefaultPaint } from "../../../../../utils/creators/PaintCreators";
import { makeDefaultLayout } from "../../../../../utils/creators/LayoutCreators";
import { STYLE_TYPES } from "../../../../../utils/constants/layers/styleTypes";
import { useFetchRastersQuery } from "../../../../../features/rasters/api";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { useFetchDprDatasetQuery } from "features/dprIntegration/api";

const CHARACTER_VARYING = "character varying";
const DOUBLE_PRECISION = "double precision";

const LayerStyler = () => {
    const [datasetColumns, setDatasetColumns] = useState([]);
    const [selectedDatasetColumn, setSelectedDatasetColumn] = useState(null);
    const [distinctColumnValues, setDistinctColumnValues] = useState([]);
    const [minMaxColumnValues, setMinMaxColumnValues] = useState([]); //[min,max]

    const datasets = useSelector(getDatasets);
    const { data: rasters = [] } = useFetchRastersQuery();
    const selectedLayer = useSelector(getSelectedLayer);
    const sources = useSelector(getMapSources);
    const sourceMinZoomLimit = useSelector(getSourceMinZoomSelector(selectedLayer.sourceId));
    const styles = useSelector(getStylesOfLayer(selectedLayer.resourceId));
    const visible = useSelector(getLayerVisibility(selectedLayer.resourceId));
    const styleConfig = useSelector(getStyleConfig);
    const features = useSelector(getFeatureFlags);

    const { data: dprDataset = null } = useFetchDprDatasetQuery(selectedLayer.resourceId, { skip: !features.DPR || selectedLayer.type !== "vector" });

    const dispatch = useDispatch();

    useEffect(() => {
        //Based on the selected layer we bring the dataset columns and by default select the first column
        if (!selectedLayer) {
            setDatasetColumns([]);
            setSelectedDatasetColumn(null);
            setDistinctColumnValues([]);
            setMinMaxColumnValues([]);
        }
        if (selectedLayer && selectedLayer.type === "vector") {
            dispatch(datasetActions.getDatasetColumns(selectedLayer.resourceId)).then((res) => {
                setDatasetColumns(res.result);
                setSelectedDatasetColumn(res.result[0]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLayer.resourceId]);

    useEffect(() => {
        //Based on the selected column we bring the distinct values and min,max pair
        if (selectedDatasetColumn) {
            const columnName = selectedDatasetColumn.name;
            const selectedDatasetId = selectedLayer.resourceId;
            if (selectedDatasetColumn.type !== DOUBLE_PRECISION) {
                dispatch(datasetActions.getDistinctColumnValues(selectedDatasetId, columnName)).then((res) => {
                    setDistinctColumnValues(res.result);
                });
            }

            if (selectedDatasetColumn.type !== CHARACTER_VARYING) {
                dispatch(datasetActions.getMinMaxColumnValues(selectedDatasetId, columnName)).then((res) => {
                    setMinMaxColumnValues([res.result.minValue, res.result.maxValue]);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDatasetColumn]);

    const onAddStyleToLayer = (symbolStyle = false) => {
        if (selectedLayer.type === STYLE_TYPES.raster) addStyleToRasterLayer();
        else addStyleToVectorLayer(symbolStyle);
    };

    const getLayerCachedSourceId = (layer) => {
        const layerSource = sources.find((source) => source.id === layer.sourceId);
        return layerSource.cacheStatus === 2 ? layer.sourceId : layer.resourceId;
    };

    const addStyleToVectorLayer = (symbolStyle) => {
        const dataset = datasets.find((x) => x.id === selectedLayer.resourceId);
        const layerId = uuidv4();
        const maxZoom = 24;

        const styleType = symbolStyle ? STYLE_TYPES.symbol : GeometryUtils.geometryTypeToMapboxType(selectedLayer.geometryType);

        const newStyleSourceId = getLayerCachedSourceId(selectedLayer);

        dispatch(mapActions.addMapLayer(makeMapLayerFromDataset(dataset, layerId, newStyleSourceId, styleType)));
        dispatch(mapActions.addMapPaint(makeDefaultPaint(layerId, styleType, styleConfig)));
        dispatch(mapActions.addMapLayout(makeDefaultLayout(layerId, styleType, styleConfig)));
        dispatch(mapActions.addMapZoomRange(makeZoomRange(layerId, dataset.minZoom, maxZoom)));
        dispatch(appDataActions.addStyleToAppLayer(makeDefaultStyle(layerId, dataset.minZoom, styleType, styleConfig)));
    };

    const addStyleToRasterLayer = () => {
        const raster = rasters.filter((x) => x.id === selectedLayer.resourceId)[0];
        const layerId = uuidv4();

        const maxZoom = 24;
        const newStyleSourceId = getLayerCachedSourceId(selectedLayer);

        dispatch(mapActions.addMapLayer(makeMapLayerFromRaster(raster, layerId, newStyleSourceId)));
        dispatch(mapActions.addMapLayout(makeDefaultLayout(layerId, STYLE_TYPES.raster, styleConfig)));
        dispatch(mapActions.addMapZoomRange(makeZoomRange(layerId, raster.minZoom, maxZoom)));
        dispatch(appDataActions.addStyleToAppLayer(makeDefaultStyle(layerId, raster.minZoom, STYLE_TYPES.raster, styleConfig)));
    };

    const onRemoveStyleFromLayer = (styleLayer) => {
        dispatch(mapActions.removeMapLayer(styleLayer.styleId));
        dispatch(appDataActions.removeStyleFromAppLayer(styleLayer.styleId));
    };

    const getStyleConfigWithAutoSelectDprMilestones = (type) => {
        if (!dprDataset) return styleConfig;

        if (type !== "box-chart" && type !== "pie-chart") return styleConfig;

        const milestoneNames = dprDataset.milestones.map((x) => x.displayName);

        const getValue = (name, value) => {
            switch (name) {
                case `${type}-labels`:
                    return milestoneNames;
                case `${type}-colors`:
                    return value.slice(0, milestoneNames.length);
                case `${type}-mask`:
                    return ["get", dprDataset.progressColumn.name];
                default:
                    return value;
            }
        };

        return {
            ...styleConfig,
            [type]: styleConfig[type].map((x) => ({
                ...x,
                value: getValue(x.name, x.value)
            }))
        };
    };

    const onStyleTypeChanged = (type, style) => {
        dispatch(
            mapActions.updateMapLayer({
                layerId: style.styleId,
                type
            })
        );

        const styleConfig = getStyleConfigWithAutoSelectDprMilestones(type);

        dispatch(mapActions.addMapPaint(makeDefaultPaint(style.styleId, type, styleConfig)));
        dispatch(mapActions.addMapLayout(makeDefaultLayout(style.styleId, type, styleConfig)));

        const properties = styleConfig[type];

        dispatch(appDataActions.changeStyleTypeOfAppLayer({ styleId: style.styleId, properties, type }));
    };

    const onZoomSliderChange = (style, zoom) => {
        dispatch(mapActions.updateMapZoomRange({ layerId: style.styleId, minZoom: zoom[0], maxZoom: zoom[1] }));

        dispatch(
            appDataActions.changeZoomLimitsOfAppLayer({
                styleId: style.styleId,
                minZoom: zoom[0],
                maxZoom: zoom[1]
            })
        );
    };

    const onStyleDrop = (styleId, beforeStyleId) => {
        dispatch(mapActions.moveLayer({ layerId: styleId, beforeLayerId: beforeStyleId }));

        dispatch(
            appDataActions.changeStyleOrder({
                layerId: selectedLayer.resourceId,
                styleId,
                beforeStyleId
            })
        );
    };

    const onPropertyChanged = (styleId, property) => {
        dispatch(
            mapActions.updateMapProperty({
                layerId: styleId,
                property
            })
        );

        dispatch(
            appDataActions.changePropertyOfLayerStyle({
                layerId: selectedLayer.resourceId,
                styleId,
                property
            })
        );
    };

    const onPropertiesChanged = (style, newProperties) => {
        dispatch(appDataActions.changePropertiesOfAppLayer({ styleId: style.styleId, newProperties }));
        updateMap(style, newProperties);
    };

    const updateMap = (style, properties) => {
        const paintProperties = properties.filter((x) => x.type === "paint");

        const paint = {
            layerId: style.styleId,
            properties: paintProperties
        };

        const layoutProperties = properties.filter((x) => x.type === "layout");
        //This is for copy pasted styles
        const containsVisibility = layoutProperties.find((property) => property.name === "visibility");

        if (!containsVisibility) {
            layoutProperties.push({
                type: "layout",
                name: "visibility",
                value: visible ? "visible" : "none"
            });
        }

        const layout = {
            layerId: style.styleId,
            properties: layoutProperties
        };
        dispatch(mapActions.updateMapPaint(paint));
        dispatch(mapActions.updateMapLayout(layout));
    };

    return (
        <LayerStyleEditor
            layer={{ ...selectedLayer, styles: styles.map((style, index) => ({ ...style, index })) }}
            isDigitizeLayer={false}
            styleConfig={styleConfig}
            //column stuff
            minZoomLimit={sourceMinZoomLimit ?? 0}
            columns={datasetColumns}
            selectedDatasetColumn={selectedDatasetColumn}
            setSelectedDatasetColumn={setSelectedDatasetColumn}
            distinctColumnValues={distinctColumnValues}
            minMaxColumnValues={minMaxColumnValues}
            //onHandlers
            onPropertyChanged={onPropertyChanged}
            onPropertiesChanged={onPropertiesChanged}
            onPropertyExpressionTypeChanged={onStyleTypeChanged}
            onTypeChanged={onStyleTypeChanged}
            onZoomSliderChange={onZoomSliderChange}
            onAddStyleToLayer={onAddStyleToLayer}
            onRemoveStyleFromLayer={onRemoveStyleFromLayer}
            onStyleDrop={onStyleDrop}
        />
    );
};

export default LayerStyler;
