import { object, SchemaOf, string } from "yup";
import { VectorUploadType } from "../models/VectorUploadType";
import { postgresReservedWords } from "../../../utils/constants/postgresReservedWords";

const datasetUploadSchema: SchemaOf<VectorUploadType> = object({
    datasetName: string()
        .required("Field required")
        .test("Test that it isn't reserved word", "This is a reserved word", (val) => !postgresReservedWords.includes(val?.toLowerCase() ?? ""))
        .min(2, "Dataset name is too short!")
        .max(150, "Dataset name is too long!"),
    schema: string().required().defined(),
    tableName: string()
        .required("Field required")
        .max(63, "Table name is too long!")
        .test("Test that it isn't reserved word", "This is a reserved word", (val) => !postgresReservedWords.includes(val?.toLowerCase() ?? ""))
        .matches(/^[a-z_][a-z0-9_]*$/, "Can only contain a-z, 0-9 or _ and cannot start with a number")
});

export default datasetUploadSchema;
