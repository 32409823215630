import ListView from "../../../../../../components/listView";
import UserMembership from "../UserMembership/UserMembership";
import { GroupUserWithMembership } from "features/groups/model/GroupUserWithMembership";

const sortByUsername = (user1: GroupUserWithMembership, user2: GroupUserWithMembership) => {
    return user1.username.toLowerCase().localeCompare(user2.username.toLowerCase());
};

const sortByUsernameReverse = (user1: GroupUserWithMembership, user2: GroupUserWithMembership) => {
    return user2.username.toLowerCase().localeCompare(user1.username.toLowerCase());
};

const userPermissionsColumns = () => [
    {
        name: "username",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByUsernameReverse : sortByUsername),
        start: true,
        otherProps: columnProps
    }
];

type UserPermissionsListProps = {
    users: GroupUserWithMembership[];
    userMembershipChangePromise: (user: GroupUserWithMembership) => Promise<void>;
};

const UserPermissionsList = ({ users, userMembershipChangePromise }: UserPermissionsListProps) => {
    const userFilterFunction = (user: GroupUserWithMembership, searchedUsername: string) => {
        return user.username.toLowerCase().includes(searchedUsername.toLowerCase());
    };

    const generateUser = (user: GroupUserWithMembership) => {
        return <UserMembership key={user.id} user={user} userMembershipChangePromise={userMembershipChangePromise} />;
    };

    return (
        <ListView
            searchPlaceholder="Search user"
            data={users}
            columns={userPermissionsColumns()}
            filterFunction={userFilterFunction}
            generateItem={generateUser}
            hideBottomBar
            searchBarProps={searchBarProps as any}
            tablePaginationProps={tablePaginationProps as any}
            showContextMenuFiller={true}
        />
    );
};

export default UserPermissionsList;

const tablePaginationProps = {
    backIconButtonProps: { "data-testid": "qa-user-permission-table-back-button" },
    nextIconButtonProps: { "data-testid": "qa-user-permission-table-next-button" }
};

const searchBarProps = { "data-testid": "qa-user-permission-list-searchbar" };

const columnProps = { "data-testid": "qa-user-permission-table-head-name" };
