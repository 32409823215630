export const sortByStringReverse = (item1: string, item2: string) => item2.toLowerCase().localeCompare(item1.toLowerCase());
export const sortByString = (item1: string, item2: string) => item1.toLowerCase().localeCompare(item2.toLowerCase());

export const sortByBoolReverse = (item1: boolean, item2: boolean) => Number(item1) - Number(item2);
export const sortByBool = (item1: boolean, item2: boolean) => Number(item2) - Number(item1);

export const sortByNumberReverse = (item1: number, item2: number) => item1 - item2;
export const sortByNumber = (item1: number, item2: number) => item2 - item1;

export const sortByDateReverse = (item1: Date, item2: Date) => item1.getTime() - item2.getTime();
export const sortByDate = (item1: Date, item2: Date) => item2.getTime() - item1.getTime();
