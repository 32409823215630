import { array, date, number, object, string } from "yup";
import { MetadataProperty } from "../models/MetadataProperty";
import { TagValue } from "../models/TagValue";
import { MIN_TAGS } from "../../../utils/validators/metadata";

const maxDate = new Date("2099-12-31");
const minDate = new Date("1900-01-01");
const numberRegEx = /^[+-]?([0-9]*[.])?[0-9]+$/;

const buildTextValidator = (mandatory: boolean) => (mandatory ? string().required() : string().optional());

const buildNumberValidator = (mandatory: boolean) =>
    mandatory ? string().required().matches(numberRegEx, "Field needs to be a number") : string().optional().matches(numberRegEx, "Field needs to be a number");

const buildDateValidator = (mandatory: boolean) => {
    let validator = date()
        .nullable()
        .typeError("Invalid date")
        .min(minDate, `Date should not be before ${minDate.toLocaleDateString()}`)
        .max(maxDate, `Date should not be after ${maxDate.toLocaleDateString()}`);

    if (mandatory) return validator.required();

    return validator;
};

const buildTagValidator = (mandatory: boolean) =>
    mandatory
        ? array<TagValue>()
              .required()
              .compact((tag) => !tag.enabled)
              .min(MIN_TAGS, "Must enable at least one tag")
        : array<TagValue>();

const buildPropertySchema = (property: MetadataProperty) => {
    switch (property.type) {
        case "text":
            return buildTextValidator(property.mandatory);
        case "number":
            return buildNumberValidator(property.mandatory);
        case "date":
            return buildDateValidator(property.mandatory);
        case "tag_list":
            return buildTagValidator(property.mandatory);
    }
};

export const buildPropertyValuesSchema = (schema: MetadataProperty[]) =>
    object(
        schema.reduce((acc, property) => {
            acc[property.name] = buildPropertySchema(property);
            return acc;
        }, {})
    );
