import baseAtlasApi from "store/baseAtlasApi";
import { Raster } from "./models/Raster";
import { atlasApiTags as tags } from "store/atlasApiTags";
import { CancelToken, Method } from "axios";
import { ColorPalette } from "components/ColorPaletteEditor/ColorPalette";
import { v4 as uuidv4 } from "uuid";
import axiosClient from "actions/apiClient";
import { GeoTiff } from "./models/GeoTiff";

type UploadRequest = {
    rasterId: string;
    replaceExisting: boolean;
    file: File;
    uploadProgressHandler: (progressEvent: ProgressEvent) => void;
    cancelToken: CancelToken;
};

type GeotiffDownloadRequest = {
    rasterId: string;
    fileName: string;
    downloadProgressHandler: (progressEvent: ProgressEvent) => void;
    cancelToken?: CancelToken;
};

const computeColor = (color: string, alpha: number): string => {
    const isAlphaEqualToOne = alpha === 255;
    const alphaHex = alpha.toString(16).padStart(2, "0");
    return isAlphaEqualToOne ? color : `${color}${alphaHex}`;
};

const addColorTableEntryIds = (raster: Raster): Raster => ({
    ...raster,
    colorPalette: {
        ...raster.colorPalette,
        colorTable: raster.colorPalette.colorTable.map((entry) => ({ ...entry, color: computeColor(entry.color, entry.alpha), id: uuidv4() }))
    }
});

const buildUploadQuery = (url: string, method: Method, request: UploadRequest) => {
    const formData = new FormData();
    formData.append("replaceExisting", request.replaceExisting.toString());
    formData.append("file", request.file);
    formData.append("fileName", request.file.name);

    return {
        url,
        method: method,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        onUploadProgress: request.uploadProgressHandler,
        cancelToken: request.cancelToken
    };
};

export const downloadGeotiff = (request: GeotiffDownloadRequest) =>
    axiosClient
        .get(`raster/${request.rasterId}/download/`, {
            responseType: "blob",
            onDownloadProgress: request.downloadProgressHandler
        })
        .then((res: any) => res.data);

export const rastersApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        fetchRasters: build.query<Raster[], void>({
            query: () => ({ url: "raster", method: "GET" }),
            providesTags: [tags.rasterTags.RASTER_LIST],
            transformResponse: (response: Raster[]) => response.map((raster) => addColorTableEntryIds(raster))
        }),
        fetchRasterById: build.query<Raster, string>({
            query: (rasterId) => ({ url: `raster/${rasterId}`, method: "GET" }),
            transformResponse: (response: Raster) => addColorTableEntryIds(response),
            providesTags: (_0, _1, rasterId) => [{ type: tags.rasterTags.RASTER, id: rasterId }]
        }),
        fetchAssociatedApps: build.query<Raster[], string>({
            query: (rasterId) => ({ url: `raster/${rasterId}/apps`, method: "GET" })
        }),
        deleteRaster: build.mutation<void, string>({
            query: (rasterId) => ({
                url: `raster/${rasterId}`,
                method: "DELETE"
            }),
            async onQueryStarted(rasterId, { dispatch, queryFulfilled }) {
                const deleteUpdate = dispatch(rastersApi.util.updateQueryData("fetchRasters", undefined, (draft) => (draft = draft.filter((raster) => raster.id !== rasterId))));
                try {
                    await queryFulfilled;
                } catch {
                    deleteUpdate.undo();
                }
            }
        }),
        editRaster: build.mutation<Raster, Pick<Raster, "id" | "name" | "colorPalette">>({
            query: ({ id, name, colorPalette }) => ({
                url: `raster/{${id}}`,
                method: "PUT",
                data: { id, name, colorPalette }
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const res = await queryFulfilled;
                dispatch(
                    rastersApi.util.updateQueryData("fetchRasterById", id, (raster) => {
                        if (raster) {
                            const updatedRaster = addColorTableEntryIds(res.data);
                            Object.assign(raster, updatedRaster);
                        }
                    })
                );
            },
            invalidatesTags: [tags.rasterTags.RASTER_LIST]
        }),
        uploadGeotiff: build.mutation<GeoTiff, UploadRequest>({
            query: (request) => {
                let url = `raster/${request.rasterId}/geotiff`;
                return buildUploadQuery(url, "POST", request);
            },
            async onQueryStarted({ rasterId }, { dispatch, queryFulfilled }) {
                const res = await queryFulfilled;
                dispatch(
                    rastersApi.util.updateQueryData("fetchRasterById", rasterId, (draft) => {
                        if (draft) {
                            draft.geoTiff = res.data;
                        }
                    })
                );
            },
            invalidatesTags: () => [{ type: tags.rasterTags.RASTER_LIST }]
        }),
        uploadCPT: build.mutation<ColorPalette, UploadRequest>({
            query: (request) => {
                let url = `raster/${request.rasterId}/color-palette`;
                return buildUploadQuery(url, "POST", request);
            },
            invalidatesTags: (_0, _1, req) => [{ type: tags.rasterTags.RASTER_LIST }, { type: tags.rasterTags.RASTER, id: req.rasterId }]
        }),
        generateCache: build.mutation<void, string>({
            query: (rasterId) => ({
                url: `raster/${rasterId}/generate`,
                method: "POST"
            })
        })
    })
});

export const updateRastersQueryData = (raster: Pick<Raster, "id"> & Partial<Raster>) =>
    rastersApi.util.updateQueryData("fetchRasters", undefined, (rasters) => {
        if (!rasters) return;

        const foundRaster = rasters.find((r) => r.id === raster.id);
        if (foundRaster) {
            Object.assign(foundRaster, raster);
        }
    });

export const updateRasterQueryDataById = (raster: Pick<Raster, "id"> & Partial<Raster>) =>
    rastersApi.util.updateQueryData("fetchRasterById", raster.id, (foundRaster) => {
        if (foundRaster) {
            Object.assign(foundRaster, raster);
        }
    });

export const invalidateRaster = (rasterId: string) =>
    rastersApi.util.invalidateTags([
        {
            type: tags.rasterTags.RASTER,
            id: rasterId
        }
    ]);

export const {
    useFetchRastersQuery,
    useFetchRasterByIdQuery,
    useFetchAssociatedAppsQuery,
    useDeleteRasterMutation,
    useEditRasterMutation,
    useUploadCPTMutation,
    useUploadGeotiffMutation,
    useGenerateCacheMutation
} = rastersApi;
