import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    headerIconButton: {
        width: 30,
        height: 30,
        borderRadius: 4,
        color: theme.customColors.appTextColor
    }
}));
