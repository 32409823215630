import { FC } from "react";
import { useColorPaletteStyles } from "./styles";
import { ColorPalette, ColorTableEntry } from "./ColorPalette";
import ColorTableItem from "./ColorTableItem";
import { ColorResult } from "react-color";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Divider } from "@mui/material";
import rgbHex from "rgb-hex";

type Props = {
    palette: ColorPalette;
    onChange: (palette: ColorPalette) => void;
};

const getItemStyle = (draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // styles we need to apply on draggables
    ...draggableStyle
});

const updateColorTableProperty = <K extends keyof ColorTableEntry>(colorTable: ColorTableEntry[], property: K, entry: ColorTableEntry, value: ColorTableEntry[K]) =>
    colorTable.map((item) => (item.id === entry.id ? { ...entry, [property]: value } : item));

const ColorPaletteEditor: FC<Props> = ({ palette, onChange }) => {
    const classes = useColorPaletteStyles();

    const onColorChange = (entry: ColorTableEntry, color: ColorResult) =>
        onChange({
            ...palette,
            colorTable: updateColorTableProperty(palette.colorTable, "color", entry, `#${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)}`)
        });

    const onLabelChange = (entry: ColorTableEntry, label: string) =>
        onChange({
            ...palette,
            colorTable: updateColorTableProperty(palette.colorTable, "label", entry, label)
        });

    const onValueChange = (entry: ColorTableEntry, value: number) =>
        onChange({
            ...palette,
            colorTable: updateColorTableProperty(palette.colorTable, "value", entry, value)
        });

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination || result.destination.index === result.source.index) return;
        const orderedColorTable = [...palette.colorTable];
        const { sourceIndexColor, sourceIndexAlpha } = {
            sourceIndexColor: orderedColorTable[result.source.index].color,
            sourceIndexAlpha: orderedColorTable[result.source.index].alpha
        };
        if (result.destination.index >= result.source.index) {
            for (let i = result.source.index; i < result.destination.index; i++) {
                orderedColorTable[i] = { ...orderedColorTable[i], color: orderedColorTable[i + 1].color, alpha: orderedColorTable[i + 1].alpha };
            }
        } else {
            for (let i = result.source.index; i > result.destination.index; i--) {
                orderedColorTable[i] = { ...orderedColorTable[i], color: orderedColorTable[i - 1].color, alpha: orderedColorTable[i - 1].alpha };
            }
        }
        orderedColorTable[result.destination.index] = { ...orderedColorTable[result.destination.index], color: sourceIndexColor, alpha: sourceIndexAlpha };

        onChange({ ...palette, colorTable: orderedColorTable });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" key="droppable">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={classes.dragContainer}>
                        {palette.colorTable.map((item, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} style={getItemStyle(provided.draggableProps.style)}>
                                        <Divider />
                                        <ColorTableItem
                                            key={item.id}
                                            entry={item}
                                            dragHandleProps={provided.dragHandleProps}
                                            onColorChange={onColorChange}
                                            onLabelChange={onLabelChange}
                                            onValueChange={onValueChange}
                                        ></ColorTableItem>
                                        <Divider />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default ColorPaletteEditor;
