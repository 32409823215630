import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export const getSchemas = () => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_SCHEMAS });

        return axiosClient
            .get("schema")
            .then((res) => {
                return dispatch({ type: types.FETCH_SCHEMAS_COMPLETE, result: res.data });
            })
            .catch((err) => {
                dispatch({ type: types.SCHEMAS_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const createSchema = (schemaName) => {
    return (dispatch) => {
        dispatch({ type: types.CREATE_SCHEMA });

        return axiosClient
            .post("schema", { schemaName })
            .then(() => {
                return dispatch({ type: types.CREATE_SCHEMA_COMPLETE, result: schemaName });
            })
            .catch((err) => {
                dispatch({ type: types.SCHEMAS_ACTION_FAILED, result: err });
                throw err;
            });
    };
};

export const deleteSchema = (schemaName) => {
    return (dispatch) => {
        dispatch({ type: types.DELETE_SCHEMA });

        return axiosClient
            .delete("schema/" + schemaName)
            .then(() => {
                return dispatch({ type: types.DELETE_SCHEMA_COMPLETE, result: schemaName });
            })
            .catch((err) => {
                dispatch({ type: types.SCHEMAS_ACTION_FAILED, result: err });
                throw err;
            });
    };
};
