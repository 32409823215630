import makeStyles from "@mui/styles/makeStyles";

export const useColorPaletteStyles = makeStyles((theme: any) => ({
    chip: {
        fontWeight: "bold",
        padding: "0px 3px"
    },
    dragContainer:{
        marginTop:16
    }
}));

export const useColorTableEntryStyles = makeStyles((theme: any) => ({
    paper: {
        backgroundColor: theme.customColors.fieldBackgroundColor
    },
    root: {
        display: "flex",
        alignItems: "center",
        height: 80,
        margin: "8px 0px"
    },
    dragButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 8px",
        height: "100%",

        "&:hover": {
            color: theme.palette.primary.main
        }
    },
    textfieldContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: "0px 16px"
    },
    textContainer: {
        display: "flex",
        alignItems: "center"
    },
    label: {
        paddingRight: 16
    },
    textField: {
        flexGrow: 1
    },
    colorIndicator: {
        width: 64,
        height: 80,
        borderRadius: 4,
        border: " 2px solid #e0e0e0",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.8
        }
    }
}));
