import { Autocomplete, TextField } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./styles";

type Props = {
    id: string;
    labelName: string;
    value: string;
    options: string[];
    isLoading: boolean;
    isError: boolean;
    onChange: (newValue: string) => void;
    dataTestId: string;
    required?: boolean;
    freeSolo?: boolean;
};

const DprCustomAutocomplete: FC<Props> = ({ id, labelName, value, options, isError, isLoading, onChange, dataTestId, required = true, freeSolo = false }) => {
    const classes = useStyles();
    return (
        <Autocomplete
            id={id}
            key={id}
            loading={isLoading || isError}
            options={options}
            clearOnBlur={!freeSolo}
            disableClearable={value !== "" && required && !freeSolo}
            value={value === "" && !freeSolo ? null : value}
            freeSolo={freeSolo}
            onChange={(_, newValue) => {
                onChange(newValue ?? "");
            }}
            onInputChange={(_, newValue) => {
                freeSolo && onChange(newValue);
            }}
            sx={{ flexGrow: 1 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    id={id}
                    key={id}
                    label={labelName}
                    required={required}
                    variant="filled"
                    inputProps={{ ...params.inputProps, "data-testid": dataTestId }}
                    InputLabelProps={{ className: classes.inputLabel }}
                    fullWidth
                />
            )}
        />
    );
};

export type GroupOption = {
    group: string;
    option: string;
};

type GroupedProps = {
    id: string;
    labelName: string;
    value: string;
    options: GroupOption[];
    isLoading: boolean;
    isError: boolean;
    onChange: (newValue: string) => void;
    dataTestId: string;
    required?: boolean;
};

export const DprCustomGroupedAutocomplete: FC<GroupedProps> = ({ id, labelName, value, options, isError, isLoading, onChange, dataTestId, required = true }) => {
    const classes = useStyles();
    return (
        <Autocomplete
            id={id}
            key={id}
            loading={isLoading || isError}
            options={options}
            clearOnBlur
            disableClearable={value !== "" && required}
            value={value === "" ? null : { option: value, group: "" }}
            onChange={(_, newValue) => {
                onChange(newValue?.option ?? "");
            }}
            getOptionLabel={(option) => option.option}
            groupBy={(option) => option.group}
            sx={{ flexGrow: 1 }}
            isOptionEqualToValue={(option, value) => option.option === value.option}
            renderInput={(params) => (
                <TextField
                    {...params}
                    id={id}
                    key={id}
                    label={labelName}
                    required={required}
                    variant="filled"
                    inputProps={{ ...params.inputProps, "data-testid": dataTestId }}
                    InputLabelProps={{ className: classes.inputLabel }}
                    fullWidth
                />
            )}
        />
    );
};

export default DprCustomAutocomplete;
