import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Upload } from "./models/Upload";

export const uploadsAdapter = createEntityAdapter<Upload>({
    selectId: (upload) => upload.name
});

const uploadSlice = createSlice({
    name: "upload",
    initialState: uploadsAdapter.getInitialState(),
    reducers: {
        updateUpload: uploadsAdapter.updateOne,
        removeUpload: uploadsAdapter.removeOne,
        addManyUploads: uploadsAdapter.addMany,
        removeAllUploads: uploadsAdapter.removeAll
    }
});

export const { updateUpload, removeUpload, addManyUploads, removeAllUploads } = uploadSlice.actions;

export default uploadSlice.reducer;
