import React from "react";
import withStyles from "@mui/styles/withStyles";

import Typography from "@mui/material/Typography";

import { TextField, Checkbox, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setResourceOptions, setResourceName } from "../../../../reducers/appData/appData";
import { getSelectedLayer } from "../../../../selectors/appData";
import ReadOnlyField from "../../../../components/ReadOnlyField/ReadOnlyField";
import { useFetchRastersQuery } from "../../../../features/rasters/api";

const styles = () => ({});

const LayerRasterEditView = () => {
    const { data: rasters = [] } = useFetchRastersQuery();
    const layer = useSelector(getSelectedLayer);

    const dispatch = useDispatch();

    const onStartsEnabledChanged = () => {
        const newOptions = { ...layer.options };

        newOptions.enabled = !newOptions.enabled;

        dispatch(setResourceOptions({ resourceId: layer.resourceId, newOptions }));
    };

    function onDownloadableChanged() {
        const newOptions = { ...layer.options };

        newOptions.downloadableGeoTiff = !newOptions.downloadableGeoTiff;
        dispatch(setResourceOptions({ resourceId: layer.resourceId, newOptions }));
    }

    const onNameChange = (e) => {
        const newName = e.target.value;
        dispatch(setResourceName({ resourceId: layer.resourceId, newName }));
    };

    const raster = rasters.find((x) => x.id === layer.resourceId);

    const zoom = `${raster.minZoom} - ${raster.maxZoom}`;

    return (
        <div className="edit-page">
            <div className="field">
                <TextField value={layer.name} variant="filled" fullWidth label="Display name" onChange={onNameChange} />
            </div>
            <div className="field light-grey-border">
                <ReadOnlyField label={"Original name"} text={raster.name} />
            </div>
            <div className="field flex light-grey-border">
                <ReadOnlyField label={"Zoom"} text={zoom} />
                <ReadOnlyField label={"Rows"} text={raster.fileSize} />
            </div>
            <div className="container">
                <div className="checkbox">
                    <Typography variant="subtitle1">Starts enabled</Typography>
                    <Checkbox style={{ marginLeft: -12 }} checked={layer.options.enabled} onChange={() => onStartsEnabledChanged(layer)} />
                </div>
                <div className="checkbox">
                    <Typography variant="subtitle1">Geotiff downloadable</Typography>
                    {!raster.geoTiff ? (
                        <Tooltip title="This raster doesn't have a geotiff">
                            <div>
                                <Checkbox
                                    style={{ marginLeft: -12 }}
                                    checked={layer.options.downloadableGeoTiff}
                                    disabled={!raster.geoTiff}
                                    onChange={() => onDownloadableChanged(layer)}
                                />
                            </div>
                        </Tooltip>
                    ) : (
                        <Checkbox
                            style={{ marginLeft: -12 }}
                            checked={layer.options.downloadableGeoTiff}
                            disabled={!raster.geoTiff}
                            onChange={() => onDownloadableChanged(layer)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(LayerRasterEditView);
