export const acceptedVectorFormats = [".geojson", ".csv", ".zip", ".gpkg"];

export const acceptedRasterFormats = [".mbtiles", ".tiff", ".tif"];

export const formatTableName = (tableName: string) =>
    tableName
        .toLowerCase()
        .replace(/[\s-]/g, "_") // this replaces any whitespace character and the hyphen character with underscore
        .replace(/_{2,}/g, "_"); // this replaces repeated underscores with one underscore

export const getPercentageCompleted = (loaded: number, total: number) => Math.round((loaded * 100) / total);

export const guessCsvDelimiter = (line: string) => {
    const array: { length: number; delimiter: string }[] = [];

    array.push(
        {
            length: line.split(",").length,
            delimiter: ","
        },
        {
            length: line.split(";").length,
            delimiter: ";"
        },
        {
            length: line.split("\t").length,
            delimiter: "\t"
        },
        {
            length: line.split("|").length,
            delimiter: "|"
        }
    );

    array.sort((a, b) => b.length - a.length);

    return array[0].delimiter;
};

export const generateCsvColumnNames = (header: string, delimiter: string) =>
    header.split(delimiter).map((col) => {
        const trimmedCol = col.trim();

        //Csv column names read from files can look like this:
        //['"test""bl""""asd"','oksadasd', '"dascasd"']
        //Excel saves csv for col names in the following ways:
        //"asd'casdad""casd"  -> """asd'casdad""""casd"""
        //"basd"  -> """basd"""
        //basd  -> basd
        //Besides this rule, there can be external csv which have cols saved like:
        //"col". The library won't split properly only if we give the name as col (no "")

        let columnName = trimmedCol.replaceAll(/(^"{1})|("{1}$)/g, "");
        columnName = columnName.replaceAll(/""/g, '"');

        return columnName;
    });
