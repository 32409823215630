import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    columnsSection: {
        display: "flex",
        flexDirection: "column",
        margin: 16
    },
    columnInfoHeader: {
        display: "flex"
    },
    columnNameHeader: {
        flexGrow: 1,
        fontWeight: 700,
        marginRight: 8,
        padding: "8px 0"
    },
    columnTypeHeader: {
        fontWeight: 700,
        padding: "8px 4px"
    },
    columnsContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto"
    },
    columnsInfoContainer: {
        display: "flex",
        borderBottom: `1px solid #e7e7e7`
    },
    columnName: {
        display: "flex",
        alignItems: "center",
        marginRight: 8,
        maxWidth: 200,
        overflow: "hidden",
        padding: 8,
        textOverflow: "ellipsis"
    },
    grow: {
        flexGrow: 1
    },
    columnType: {
        display: "flex",
        alignItems: "center",
        padding: 8
    },
    chip: {
        backgroundColor: theme.customColors.primaryColor,
        color: "white",
        marginLeft: 8,
        height: 22
    }
}));
