import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    tools: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        position: "absolute",
        left: 501,
        top: 0,
        height: "100%",
        zIndex: 10,
        background: theme.customColors.appBgColorLight
    }
}));
