import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    profileInfo: {
        display: "flex",
        textAlign: "center",
        flexDirection: "column"
    },
    signOut: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    paperRoot: {
        backgroundColor: theme.customColors.appBgColorLight
    },
    button: {
        padding: 0,
        minWidth: "initial",
        width: "100%"
    },
    link: {
        width: "100%",
        height: 50,
        fontSize: 14,
        display: "flex",
        backgroundColor: theme.customColors.appPrimaryColor,
        alignItems: "center",
        transition: "0.2s all",
        opacity: 0.7,
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.04)"
        }
    },
    isActive: {
        opacity: 1,
        backgroundColor: "rgba(255, 255, 255, 0.04)"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifyStart: {
        justifyContent: "start"
    },
    logoutButton: {
        position: "absolute",
        left: 0,
        bottom: 0
    },
    itemWrapper: {
        width: "100%"
    },
    icon: {
        display: "flex",
        padding: "0 15px",
        color: theme.customColors.whiteText
    },
    text: {
        display: "flex",
        alignItems: "center",
        color: theme.customColors.whiteText
    }
}));
