import React, { useEffect, useState, useRef } from "react";
import withStyles from "@mui/styles/withStyles";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

import { Input, InputAdornment } from "@mui/material";
import { Tooltip } from "@mui/material";

const styles = (theme) => ({
    root: {},
    okBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        color: theme.customColors.primaryColor,
        backgroundColor: theme.customColors.secondaryColor
    },
    clearBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        color: theme.customColors.errorColor,
        backgroundColor: theme.customColors.errorColorLight,
        marginRight: 8
    }
});

function Layer({ value, onChange, classes }) {
    const [groupName, setGroupName] = useState(value);
    const inputRef = useRef();
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [groupName]);

    function onClearGroupName(e) {
        e.stopPropagation();
        onChange(value);
    }

    function onInputKeyUp(e) {
        //If enter is pressed
        if (e.keyCode === 13) {
            onChange(groupName);
        }
    }

    function onUpdateGroupName(e) {
        e.stopPropagation();
        onChange(groupName);
    }

    function preventDrag(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <Input
            autoFocus
            onKeyUp={onInputKeyUp}
            onChange={(e) => setGroupName(e.target.value)}
            draggable
            onDragStart={preventDrag}
            value={groupName}
            inputRef={inputRef}
            fullWidth
            className={classes.root}
            inputProps={{
                "data-testid": groupNameInputTestId
            }}
            endAdornment={
                <InputAdornment position="end">
                    <Tooltip title="Discard">
                        <IconButton className={classes.clearBtn} onMouseDown={onClearGroupName} size="large" data-testid={groupNameDiscardButtonTestId}>
                            <ClearIcon className="icon" fontSize="small" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Save">
                        <IconButton className={classes.okBtn} onClick={onUpdateGroupName} size="large" data-testid={groupNameSaveButtonTestId}>
                            <CheckIcon className="icon" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            }
        />
    );
}

export default withStyles(styles)(Layer);

const groupNameInputTestId = "qa-app-edit-view-group-name-input";
const groupNameDiscardButtonTestId = "qa-app-edit-view-group-name-discard-button";
const groupNameSaveButtonTestId = "qa-app-edit-view-group-name-save-button";
