export const makeDefaultStyle = (styleId, minZoom, layerType, styleConfig) => {
    const properties = styleConfig[layerType];
    const maxZoom = 24;
    const style = {
        styleId,
        properties,
        minZoom,
        maxZoom,
        type: layerType
    };
    return style;
};
