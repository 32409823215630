import dayjs, { Dayjs } from "dayjs";
import { useContext, useEffect } from "react";
import { DateRangeFilterType } from "../types/DateRangeFilterType";
import { TableContext } from "./../providers/TableContextProvider";

const defaultDateRange: [Dayjs, Dayjs] = [dayjs(), dayjs().add(1, "day")];

const defaultFilterFunction = (elem: string | Dayjs, selectedOptions: Dayjs[]) => dayjs(elem).isBetween(selectedOptions[0], selectedOptions[1], "day", "[]");

const useRegisterDateRangeFilter = <TData>(
    name: string,
    columnName: keyof TData,
    filterFunction: (elem: string | Dayjs, selectedOptions: Dayjs[]) => boolean = defaultFilterFunction
) => {
    const tableContext = useContext(TableContext);

    useEffect(() => {
        tableContext.registerFilter<Dayjs, DateRangeFilterType>({
            name,
            columnName,
            type: "date",
            filterFunction,
            selected: defaultDateRange,
            onApply: (selectedOptions) => tableContext.activateFilter(name, selectedOptions),
            onClear: () => tableContext.deactivateFilter(name),
            active: false,
            defaultValue: [dayjs(), dayjs().add(1, "day")]
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useRegisterDateRangeFilter;
