import { axiosClient } from "actions/apiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DatasetHistory } from "./models/DatasetHistory";
import { DatasetAppendResult } from "./models/DatasetAppendResult";
import { CancelToken } from "axios";
import fileDownload from "js-file-download";
import { Dataset } from "models/Dataset";
import { FeatureProperties } from "./models/Feature";

export const fetchDatasetHistoryThunk = createAsyncThunk<DatasetHistory[], string>("fetchDatasetHistory", (datasetId: string) =>
    axiosClient.get(`dataset/${datasetId}/history`).then((res) => res.data)
);

export type FetchDatasetHistoryEntryFeaturesArg = { datasetId: string; historyId: number };
export const fetchDatasetHistoryEntryFeatures = ({ datasetId, historyId }: FetchDatasetHistoryEntryFeaturesArg): Promise<{ properties: FeatureProperties[] }> =>
    axiosClient.get(`dataset/${datasetId}/history/${historyId}/features`).then((res) => res.data);

export type AppendDatasetArgConfig = { cancelToken: CancelToken; onUploadProgress: (progress: ProgressEvent) => void };
export type AppendDatasetArg = { datasetId: string; formData: FormData; config: AppendDatasetArgConfig };

export const appendDatasetZipThunk = createAsyncThunk<DatasetAppendResult, AppendDatasetArg>("appendDatasetZipThunk", ({ datasetId, formData, config }) =>
    axiosClient
        .post(`dataset/${datasetId}/append`, formData, {
            ...config,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then((res) => res.data)
);

export const appendDatasetGeojsonThunk = createAsyncThunk<DatasetAppendResult, AppendDatasetArg>("appendDatasetGeojsonThunk", ({ datasetId, formData, config }) =>
    axiosClient
        .post(`dataset/${datasetId}/append/geojson`, formData, {
            ...config,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then((res) => res.data)
);

export type DownloadDatasetTemplateArg = { datasetId: string; fileName: string; format: string; config: any };
export const downloadDatasetTemplate = ({ datasetId, fileName, format, config }: DownloadDatasetTemplateArg) =>
    axiosClient
        .get(`dataset/${datasetId}/download/${format}/template`, {
            ...config,
            responseType: "blob"
        })
        .then((res) => {
            fileDownload(res.data, fileName);
        });

export type DeleteDatasetHistoryResponse = { updatedDataset: Dataset; deletedHistoryId: number };
export const deleteDatasetHistoryEntryThunk = createAsyncThunk<DeleteDatasetHistoryResponse, DatasetHistory>("deleteDatasetHistoryEntryThunk", (history) =>
    axiosClient.delete(`dataset/${history.datasetId}/history/${history.id}`).then((res) => ({ updatedDataset: res.data as Dataset, deletedHistoryId: history.id }))
);
