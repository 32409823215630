import { FC, useState } from "react";
import { useStyles } from "./styles";
import { DprProgressMilestone } from "features/dprIntegration/models/DprProgressMilestone";
import FlagIcon from "@mui/icons-material/Flag";
import { LinearProgress, Stack } from "@mui/material";
import { DprMilestoneType } from "features/dprIntegration/models/DprMilestoneType";
import MilestoneDisplayNameStep from "./MilestoneDisplayNameStep/MilestoneDisplayNameStep";
import SubprojectsStep from "./SubprojectsStep/SubprojectsStep";
import DprMilestoneSetupStep from "./DprMilestoneSetupStep/DprMilestoneSetupStep";
import CustomModal from "components/CustomModal/CustomModal";
import { DprSubproject } from "features/dprIntegration/models/DprSubproject";
import { DprMilestoneSubproject } from "features/dprIntegration/models/DprMilestoneSubproject";

type Props = {
    open: boolean;
    handleClose: () => void;
    onAdd: (milestone: DprProgressMilestone) => Promise<any>;
    newBitPosition: number;
    dialogType?: string;
};

const AddMilestoneModal: FC<Props> = ({ open, handleClose, onAdd, newBitPosition, dialogType = "Add" }) => {
    const classes = useStyles();

    const [displayName, setDisplayName] = useState("");

    const [selectAllSubprojects, setSelectAllSubprojects] = useState(false);
    const [subprojects, setSubprojects] = useState<DprSubproject[]>([]);

    const [milestoneType, setMilestoneType] = useState(DprMilestoneType.Activity);

    const [statusTrackerName, setStatusTrackerName] = useState("");
    const [group, setGroup] = useState("");
    const [name, setName] = useState("");

    const resetState = () => {
        setDisplayName("");
        setSelectAllSubprojects(false);
        setSubprojects([]);
        setMilestoneType(DprMilestoneType.Activity);
        setStatusTrackerName("");
        setGroup("");
        setName("");
    };

    const onConfirm = () => {
        const milestone: DprProgressMilestone = {
            displayName,
            bitPosition: newBitPosition,
            name,
            group,
            milestoneType,
            subprojects: mapSubprojects(),
            ...(!!statusTrackerName && { statusTrackerName })
        };

        onAdd(milestone).then((_) => resetState());
    };

    const mapSubprojects = () => {
        return selectAllSubprojects
            ? []
            : subprojects
                  .map((subproject) => subproject.name.split("/"))
                  .filter((subprojectSplitted) => subprojectSplitted.length === 3)
                  .map(
                      (subprojectSplitted) =>
                          ({
                              scope: subprojectSplitted[1],
                              vessel: subprojectSplitted[2]
                          }) as DprMilestoneSubproject
                  );
    };

    const validSubprojects = selectAllSubprojects || (!selectAllSubprojects && !!subprojects.length);
    const disabled = !displayName || !validSubprojects || !group || !name;

    return (
        <>
            <CustomModal
                handleClose={() => {
                    resetState();
                    handleClose();
                }}
                isOpen={open}
                onConfirm={onConfirm}
                disabled={disabled}
                dialogTitle={
                    <>
                        <FlagIcon sx={{ fontSize: "inherit" }} />
                        Add milestone
                    </>
                }
                dialogType={dialogType}
                dialogTitleProps={{ ...dialogTitleProps, className: classes.dialogTitle }}
                closeIconProps={closeIconProps}
                cancelButtonProps={cancelButtonProps}
                confirmButtonProps={confirmButtonProps}
                PaperProps={{ className: classes.dialog }}
            >
                <Stack gap={2}>
                    <MilestoneDisplayNameStep displayName={displayName} setDisplayName={setDisplayName} />
                    <SubprojectsStep
                        selectAllSubprojects={selectAllSubprojects}
                        setSelectAllSubprojects={setSelectAllSubprojects}
                        subprojects={subprojects}
                        setSubprojects={setSubprojects}
                    />

                    <DprMilestoneSetupStep
                        milestoneType={milestoneType}
                        setMilestoneType={setMilestoneType}
                        statusTrackerName={statusTrackerName}
                        setStatusTrackerName={setStatusTrackerName}
                        group={group}
                        setGroup={setGroup}
                        name={name}
                        setName={setName}
                        subprojects={subprojects}
                    />
                </Stack>
            </CustomModal>
        </>
    );
};

export default AddMilestoneModal;

const dialogTitleProps = { "data-testid": "qa-dpr-integration-add-milestone-modal-header" };
const closeIconProps = { "data-testid": "qa-dpr-integration-add-milestone-modal-close-icon" };
const cancelButtonProps = { "data-testid": "qa-dpr-integration-add-milestone-modal-cancel-button" };
const confirmButtonProps = { "data-testid": "qa-dpr-integration-add-milestone-modal-confirm-button" };
