import { Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import toastr from "components/CustomToastr/CustomToastr";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import { fromNowWithCheck } from "utils/timeUtils";
import fileCopyIcon from "utils/icons/copy-icon.svg";
import BooleanIndicator from "components/BooleanIndicator/BooleanIndicator";
import ColorPaletteEditor from "components/ColorPaletteEditor/ColorPaletteEditor";
import { FC, useMemo } from "react";
import { PermissionType } from "features/groups/model/PermissionType";
import { permisionPrettyNames } from "utils/constants/permissionTypes";
import CPTVisualization from "features/rasters/components/RastersListView/CPTVisualization/CPTVisualization";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import { humanFileSize } from "utils/humanFileSize";
import { ColorPalette } from "../../../../../components/ColorPaletteEditor/ColorPalette";
import { GeoTiff } from "../../../models/GeoTiff";

type Props = {
    name: string;
    id: string;
    minZoom: number;
    maxZoom: number;
    permissionType: PermissionType;
    fileSize: number;
    createdUtc: string;
    modifiedUtc: string;
    colorPalette: ColorPalette;
    geoTiff?: GeoTiff;
    onNameChange: (name: string) => void;
    onColorPaletteChange: (newPalette: ColorPalette) => void;
};

const InfoTab: FC<Props> = ({ id, name, minZoom, maxZoom, permissionType, fileSize, modifiedUtc, createdUtc, colorPalette, geoTiff, onNameChange, onColorPaletteChange }) => {
    const zoom = `${minZoom} - ${maxZoom}`;
    const featureFlags = useAppSelector(getFeatureFlags);
    const humanReadableSize = useMemo(() => (Boolean(fileSize) ? humanFileSize(fileSize) : "0 B"), [fileSize]);

    return (
        <>
            <div className="field copyable">
                <TextField
                    id="filled-required"
                    label="Raster Name"
                    placeholder="Name of raster"
                    value={name}
                    variant="filled"
                    onChange={(e) => onNameChange(e.target.value)}
                    fullWidth
                    inputProps={{ "data-testid": rasterNameTextTestId }}
                    disabled={permissionType < PermissionType.WRITE_READ}
                />
                <Button
                    className="copy-button"
                    onClick={() => {
                        navigator.clipboard.writeText(name);
                        toastr.success("The text has been copied");
                    }}
                    data-testid={copyRasterNameButtonTestId}
                >
                    <img alt="" src={fileCopyIcon} />
                </Button>
            </div>

            <div className="field flex border-bottom">
                <ReadOnlyField label={"Creation Date"} text={fromNowWithCheck(dayjs(createdUtc))} tooltipTitle={dayjs(createdUtc).format("LLL")} tooltip={true} />
                <ReadOnlyField label={"Modified"} text={fromNowWithCheck(dayjs(modifiedUtc))} tooltipTitle={dayjs(modifiedUtc).format("LLL")} tooltip={true} />
            </div>

            <div className="field border-bottom copyable">
                <ReadOnlyField label={"ID"} text={id} />
                <Button
                    className="copy-button"
                    onClick={() => {
                        navigator.clipboard.writeText(id);
                        toastr.success("The text has been copied");
                    }}
                    data-testid={copyRasterIdButtonTestId}
                >
                    <img alt="" src={fileCopyIcon} />
                </Button>
            </div>

            <div className="field flex border-bottom">
                <ReadOnlyField label={"Size"} text={humanReadableSize} tooltip tooltipTitle={`${fileSize ?? 0} B`} />
                <ReadOnlyField label={"Zoom"} text={zoom} />
            </div>

            <div className="field flex border-bottom">
                <ReadOnlyField label="Geotiff" text={<BooleanIndicator boolValue={Boolean(geoTiff)} />} />
                <ReadOnlyField label="Permission" text={permisionPrettyNames[permissionType]} />
            </div>

            {featureFlags.CPT && (
                <div className="field ">
                    <CPTVisualization cpt={colorPalette.colorTable} />
                    <ColorPaletteEditor palette={colorPalette} onChange={onColorPaletteChange} />
                </div>
            )}
        </>
    );
};

export default InfoTab;

const rasterNameTextTestId = "qa-raster-edit-view-raster-name-text";
const copyRasterNameButtonTestId = "qa-raster-edit-view-copy-raster-name-button";
const copyRasterIdButtonTestId = "qa-raster-edit-view-copy-raster-id-button";
