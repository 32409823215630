import { FC } from "react";
import { MenuItem, Typography, Select } from "@mui/material";
import { useStyles } from "./style";
import { useParams } from "react-router-dom";
import ButtonLink from "components/ButtonLink/ButtonLink";
import { CoreProject } from "features/core/models/CoreProject";
import { useAppSelector } from "store/hooks/useAppSelector";
import config from "config";
import { appParam, clientIdParam, projectIdParam } from "utils/constants/queryParams";
import { getClientById } from "features/core/selectors";
import { StatusType } from "features/core/models/StatusType";

type Params = {
    clientId: string;
    projectId: string;
};

const ProjectsPicker: FC = () => {
    const classes = useStyles();

    const { clientId, projectId } = useParams<Params>();
    const currentClient = useAppSelector(getClientById(clientId));

    const currentProject = currentClient?.projects?.find((x) => x.id === projectId);

    const getPathname = (project: CoreProject) => {
        const redirectToLanding = project.region?.id !== currentProject?.region?.id;

        if (!redirectToLanding) return `/${project.clientId}/${project.id}`;

        const queryParams = new URLSearchParams();
        queryParams.append(clientIdParam, project.clientId);
        queryParams.append(projectIdParam, project.id);
        queryParams.append(appParam, "admin");

        return `${config.landingUrl}?${queryParams.toString()}`;
    };

    const renderCoreProjects = () => {
        return currentClient?.projects
            ?.filter((x) => x.status === StatusType.Active)
            .map((project) => (
                <MenuItem key={project.id} className={classes.project} component={ButtonLink} to={getPathname(project)} value={project.id}>
                    <Typography className={classes.projectName} variant="body2">
                        {project.name}
                    </Typography>
                    {!!project.logoUrl && <img src={project.logoUrl} className={classes.projectLogo} alt="" />}
                </MenuItem>
            ));
    };

    return (
        <div className={classes.projectsPickerWrapper}>
            <Select
                labelId="projects-label"
                inputProps={{ className: classes.projectsPicker }}
                id="projects-picker-select"
                value={currentProject?.id ?? "--noproject--"}
                variant="standard"
                disableUnderline
            >
                {renderCoreProjects()}
                <MenuItem className={classes.selectProjectMenuItem} value={"--noproject--"}>
                    <span className={classes.selectProjectText}>Select project</span>
                </MenuItem>
            </Select>
        </div>
    );
};

export default ProjectsPicker;
