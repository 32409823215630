import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    textDiv: {
        width: "100%",
        whiteSpace: "nowrap",
        minWidth: 0 // fix the flex item's grow width when ellipsis in applied
    },
    multipleLine: { whiteSpace: "normal" }
}));
