import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 12,
        height: 300,
        maxHeight: 300,
        overflowY: "auto",
        width: 400,
        maxWidth: 400
    },
    selectContainer: {
        display: "block",
        width: "100%",
        paddingBottom: 12
    },
    selectItem: {
        width: 390,
        maxWidth: 390,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    field: {
        paddingBottom: 12,

        "& .tag-list": { paddingBottom: 0 }
    }
}));

export default useStyles;
