import { Button } from "@mui/material";
import { useContext } from "react";
import { TableContext } from "../../providers/TableContextProvider";
import { BooleanFilterType } from "../../types/BooleanFilterType";
import { DateRangeFilterType } from "../../types/DateRangeFilterType";
import { SelectFilterType } from "../../types/SelectFilterType";
import BooleanFilter from "./components/BooleanFilter/BooleanFilter";
import DateRangeFilter from "./components/DateRangeFilter/DateRangeFilter";
import SelectFilter from "./components/SelectFilter/SelectFilter";
import { useStyles } from "./styles";

const TableFilters = () => {
    const classes = useStyles();
    const tableContext = useContext(TableContext);

    return (
        <div className={classes.container}>
            {tableContext.filters.map((filter) => {
                switch (filter.type) {
                    case "select":
                        const selectFilter = filter as SelectFilterType<any>;

                        return (
                            <SelectFilter
                                key={selectFilter.name}
                                name={selectFilter.name}
                                options={selectFilter.options}
                                selected={selectFilter.selected}
                                isActive={selectFilter.active}
                                isSearchable={selectFilter.isSearchable}
                                onApply={selectFilter.onApply}
                                onClear={selectFilter.onClear}
                            />
                        );

                    case "boolean":
                        const booleanFilter = filter as BooleanFilterType;

                        return (
                            <BooleanFilter
                                key={booleanFilter.name}
                                name={booleanFilter.name}
                                options={booleanFilter.options}
                                selected={booleanFilter.selected}
                                isActive={booleanFilter.active}
                                onApply={booleanFilter.onApply}
                                onClear={booleanFilter.onClear}
                            />
                        );

                    case "date":
                        const dateRangeFilter = filter as DateRangeFilterType;

                        return (
                            <DateRangeFilter
                                key={dateRangeFilter.name}
                                name={dateRangeFilter.name}
                                selected={dateRangeFilter.selected}
                                isActive={dateRangeFilter.active}
                                onApply={dateRangeFilter.onApply}
                                onClear={dateRangeFilter.onClear}
                            />
                        );

                    default:
                        return null;
                }
            })}
            {!!tableContext.filters.length && (
                <Button onClick={tableContext.clearFilters} disabled={!tableContext.filters.some((x) => x.active)}>
                    Clear
                </Button>
            )}
        </div>
    );
};

export default TableFilters;
