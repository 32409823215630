import baseDprApi from "store/baseDprApi";
import { DprSubproject } from "./models/DprSubproject";

type FetchActivityGroupsQuery = {
    csvSubprojectIds: string;
};

type FetchActivitiesQuery = {
    csvSubprojectIds: string;
    activityGroupName?: string;
};

type FetchStatusTrackerNamesQuery = {
    csvSubprojectIds: string;
};

type FetchStatusTrackerColumnGroupsQuery = {
    csvSubprojectIds: string;
    statusTrackerName?: string;
};

type FetchStatusTrackerColumnsQuery = {
    csvSubprojectIds: string;
    statusTrackerName?: string;
    columnGroupName?: string;
};

export const dprApi = baseDprApi.injectEndpoints({
    endpoints: (build) => ({
        fetchLocationNames: build.query<string[], void>({
            query: () => ({
                url: `location-names`,
                method: "GET"
            })
        }),
        fetchStatusTrackerRows: build.query<string[], void>({
            query: () => ({
                url: `status-tracker-rows`,
                method: "GET"
            })
        }),
        fetchSubprojects: build.query<DprSubproject[], void>({
            query: () => ({
                url: `subprojects`,
                method: "GET"
            })
        }),
        fetchActivityGroups: build.query<string[], FetchActivityGroupsQuery>({
            query: (params) => ({
                url: `activity-group-names`,
                method: "GET",
                params
            })
        }),
        fetchActivities: build.query<string[], FetchActivitiesQuery>({
            query: (params) => ({
                url: `activity-names`,
                method: "GET",
                params
            })
        }),
        fetchStatusTrackerNames: build.query<string[], FetchStatusTrackerNamesQuery>({
            query: (params) => ({
                url: `status-tracker-names`,
                method: "GET",
                params
            })
        }),
        fetchStatusTrackerColumnGroups: build.query<string[], FetchStatusTrackerColumnGroupsQuery>({
            query: (params) => ({
                url: `status-tracker-columngroups`,
                method: "GET",
                params
            })
        }),
        fetchStatusTrackerColumns: build.query<string[], FetchStatusTrackerColumnsQuery>({
            query: (params) => ({
                url: `status-tracker-columns`,
                method: "GET",
                params
            })
        })
    })
});

export const {
    useFetchLocationNamesQuery,
    useFetchStatusTrackerRowsQuery,
    useFetchSubprojectsQuery,
    useFetchActivityGroupsQuery,
    useFetchActivitiesQuery,
    useFetchStatusTrackerNamesQuery,
    useFetchStatusTrackerColumnGroupsQuery,
    useFetchStatusTrackerColumnsQuery,
    usePrefetch: useDatasetsPrefetch
} = dprApi;
