import { makeStyles } from "@mui/styles";

export const useMenuButtonCellStyles = makeStyles(() => ({
    iconContainer: {
        width: "fit-content",
        height: "100%"
    },
    buttonContainer: {
        backgroundColor: "white",
        boxShadow: "-2px 10px 15px 0 rgba(0, 0, 0, 0.04)",
        display: "flex",
        alignContent: "center",
        justifyContent: "center"
    },
    menuIcon: {
        "&:hover": {
            cursor: "pointer"
        },
        background: "#e3e4e5",
        color: "black",
        margin: "14px 16px"
    },
    stickyContainer: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));
