import { metadataTypes } from "./constants/metadata";

export const lowerTagOpacity = (rgbaString, opacity = "0.15") => {
    if (rgbaString === "red") {
        //for backwards compatibility
        rgbaString = "rgba(255,0,0,1)";
    }

    let splitted = rgbaString.split(",");
    splitted[3] = opacity + ")";
    return splitted.join(",");
};

const filterPropertyValue = (value, type) => {
    if (type === metadataTypes.TAG_LIST) {
        return value.map((val) => ({
            id: val.id,
            enabled: val.enabled
        }));
    }

    return value;
};

export const getFilteredSchemaPropertyValues = (selectedIds, schema, propertyValues) => {
    const propertyNameTypeDict = schema.reduce((acc, property) => {
        acc[property.id] = { name: property.name, type: property.type };
        return acc;
    }, {});

    return selectedIds.map((id) => {
        const { name, type } = propertyNameTypeDict[id];

        return {
            id,
            value: filterPropertyValue(propertyValues[name], type)
        };
    });
};

export const getColorString = (color) => `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
