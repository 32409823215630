import CollapsibleCard from "components/CollapsibleCard/CollapsibleCard";
import { TableContextProvider } from "components/CustomTable/providers/TableContextProvider";
import DatasetHistory from "features/fidu/components/DatasetHistory/DatasetHistory";
import { useState } from "react";
import { useParams } from "react-router-dom";

type Params = {
    datasetId: string;
};

const HistorySection = () => {
    const [isOpen, setIsOpen] = useState(true);

    const { datasetId } = useParams<Params>();

    const togglePropertiesSection = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="shadow-section scroll">
            <CollapsibleCard maxHeight={undefined} paddingBottom={0} innerPadding={0} onToggleClick={togglePropertiesSection} title="History" isToggled={isOpen}>
                <TableContextProvider>
                    <DatasetHistory datasetId={datasetId} />
                </TableContextProvider>
            </CollapsibleCard>
        </div>
    );
};
export default HistorySection;
