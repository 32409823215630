import { FC, useState } from "react";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import { getSchemas } from "../../../../../selectors/schemas";
import { useAppDispatch } from "../../../../../store/hooks/useAppDispatch";
import { useAppSelector } from "../../../../../store/hooks/useAppSelector";
import * as schemaService from "../../../../../actions/schemaService";
import * as NetworkErrorUtils from "../../../../../utils/networkErrorUtils";
import * as ValidationUtils from "../../../../../utils/validationUtils";
import { TextField } from "@mui/material";
import toastr from "../../../../../components/CustomToastr/CustomToastr";

type Props = {
    setModalOpen: (open: boolean) => void;
    open: boolean;
};

const AddDatabaseSchemaModal: FC<Props> = ({ setModalOpen, open }) => {
    const [newDatabaseSchemaName, setNewDatabaseSchemaName] = useState("");
    const [nameError, setNameError] = useState("");

    const databaseSchemas = useAppSelector(getSchemas);
    const dispatch = useAppDispatch();

    const onCloseAddDatabaseModal = () => {
        setModalOpen(false);
        setNameError("");
        setNewDatabaseSchemaName("");
    };

    const onAddSchema = () => {
        const alreadyExisting = databaseSchemas.find((x: any) => newDatabaseSchemaName === x);
        if (alreadyExisting) {
            setNameError("Duplicate name");
            return;
        }
        dispatch(schemaService.createSchema(newDatabaseSchemaName.toLowerCase())).then(
            (res: any) => {
                setNewDatabaseSchemaName("");
                toastr.success("Schema created");
            },
            (err: any) => NetworkErrorUtils.handleError(err)
        );
        onCloseAddDatabaseModal();
    };

    const onDatabaseSchemaNameChange = (e: any) => {
        const value = e.target.value;
        const error = ValidationUtils.validatePostgresIdentifier(value);
        setNewDatabaseSchemaName(value);
        setNameError(error);
    };

    return (
        <CustomModal handleClose={onCloseAddDatabaseModal} isOpen={open} onConfirm={onAddSchema} dialogTitle={"Add New Schema"} dialogType={"add"} disabled={Boolean(nameError)}>
            <TextField
                id="filled-required"
                placeholder="Name"
                value={newDatabaseSchemaName}
                onChange={onDatabaseSchemaNameChange}
                fullWidth
                inputProps={{ "data-testid": inputFieldTestId }}
                error={Boolean(nameError)}
                helperText={nameError}
                variant="filled"
                label="Schema name"
            />
        </CustomModal>
    );
};

export default AddDatabaseSchemaModal;

const inputFieldTestId = "qa-add-schema-input";
