import { Button, Menu, MenuItem } from "@mui/material";
import { useStyles } from "./styles";
import { FC, useState } from "react";
import { Dataset } from "models/Dataset";
import { PermissionType } from "features/groups/model/PermissionType";
import AddIcon from "@mui/icons-material/AddCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { FiduUploaderModal } from "features/upload/components/FiduUploaderModal/FiduUploaderModal";
import { downloadDatasetTemplate } from "features/fidu/actions";

type Props = {
    dataset: Dataset;
};

const UploaderFooter: FC<Props> = ({ dataset }) => {
    const classes = useStyles();
    const [isUploaderOpen, setIsUploaderOpen] = useState(false);
    const [templateMenuAnchor, setTemplateMenuAnchor] = useState<Element | null>();

    const downloadTemplate = (format: string) =>
        downloadDatasetTemplate({
            datasetId: dataset.id,
            fileName: "template.zip",
            format,
            config: {}
        });

    return (
        <div className={classes.footer}>
            <Button
                className={classes.buttonSpacing}
                color="primary"
                variant="contained"
                onClick={() => setIsUploaderOpen(true)}
                data-testid={addFilesButtonTestId}
                disabled={dataset.permissionType < PermissionType.WRITE_READ}
            >
                <AddIcon className="button-icon" />
                Add Files
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={(e) => setTemplateMenuAnchor(e.currentTarget)}
                data-testid={downloadTemplateButtonTestId}
                disabled={dataset.permissionType < PermissionType.READ}
            >
                <CloudDownloadIcon className="button-icon" />
                Download template
            </Button>

            <Menu id="simple-menu" anchorEl={templateMenuAnchor} keepMounted open={Boolean(templateMenuAnchor)} onClose={() => setTemplateMenuAnchor(null)}>
                <MenuItem onClick={() => downloadTemplate("ESRI Shapefile")} data-testid={downloadShapefileButtonTestId}>
                    Download as Shapefile
                </MenuItem>
                {dataset.geometryType.includes("POINT") && (
                    <MenuItem onClick={() => downloadTemplate("CSV")} data-testid={downloadCSVButtonTestId}>
                        Download as Csv
                    </MenuItem>
                )}
            </Menu>
            <FiduUploaderModal open={isUploaderOpen} onClose={() => setIsUploaderOpen(false)} datasetId={dataset.id} />
        </div>
    );
};

export default UploaderFooter;

const addFilesButtonTestId = "qa-scope-details-view-add-files-button";
const downloadTemplateButtonTestId = "qa-scope-details-view-download-template-button";
const downloadShapefileButtonTestId = "qa-scope-details-view-download-shapefile-button";
const downloadCSVButtonTestId = "qa-scope-details-view-download-csv-button";
