import { sortByBool, sortByBoolReverse, sortByDate, sortByDateReverse, sortByNumber, sortByNumberReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";
import { Raster } from "./models/Raster";
import CachedIcon from "@mui/icons-material/Autorenew";
import { hasCPT } from "components/ColorPaletteEditor/ColorPalette";

const sortByCacheStatus = (raster1: Raster, raster2: Raster) => sortByNumber(raster1.cacheStatus, raster2.cacheStatus);
const sortByCacheStatusReverse = (raster1: Raster, raster2: Raster) => sortByNumberReverse(raster1.cacheStatus, raster2.cacheStatus);

const sortByName = (raster1: Raster, raster2: Raster) => sortByString(raster1.name, raster2.name);
const sortByNameReverse = (raster1: Raster, raster2: Raster) => sortByStringReverse(raster1.name, raster2.name);

const sortByModified = (raster1: Raster, raster2: Raster) => sortByDate(new Date(raster1.modifiedUtc), new Date(raster2.modifiedUtc));
const sortByModifiedReverse = (raster1: Raster, raster2: Raster) => sortByDateReverse(new Date(raster1.modifiedUtc), new Date(raster2.modifiedUtc));

const sortByCreated = (raster1: Raster, raster2: Raster) => sortByDate(new Date(raster1.modifiedUtc), new Date(raster2.modifiedUtc));
const sortByCreatedReverse = (raster1: Raster, raster2: Raster) => sortByDateReverse(new Date(raster1.modifiedUtc), new Date(raster2.modifiedUtc));

const sortByZoom = (raster1: Raster, raster2: Raster) => sortByNumber(raster1.maxZoom, raster2.maxZoom);
const sortByZoomReverse = (raster1: Raster, raster2: Raster) => sortByNumberReverse(raster1.maxZoom, raster2.maxZoom);

const sortBySize = (raster1: Raster, raster2: Raster) => sortByNumber(raster1.fileSize, raster2.fileSize);
const sortBySizeReverse = (raster1: Raster, raster2: Raster) => sortByNumberReverse(raster1.fileSize, raster2.fileSize);

const sortByPermission = (raster1: Raster, raster2: Raster) => sortByNumber(raster1.permissionType, raster2.permissionType);
const sortByPermissionReverse = (raster1: Raster, raster2: Raster) => sortByNumberReverse(raster1.permissionType, raster2.permissionType);

const sortByGeotiff = (raster1: Raster, raster2: Raster) => sortByBool(Boolean(raster1.geoTiff), Boolean(raster2.geoTiff));
const sortByGeotiffReverse = (raster1: Raster, raster2: Raster) => sortByBoolReverse(Boolean(raster1.geoTiff), Boolean(raster2.geoTiff));

const sortByCpt = (raster1: Raster, raster2: Raster) => sortByBool(hasCPT(raster1.colorPalette), hasCPT(raster2.colorPalette));
const sortByCptReverse = (raster1: Raster, raster2: Raster) => sortByBoolReverse(hasCPT(raster1.colorPalette), hasCPT(raster2.colorPalette));

const rastersSorting = [
    {
        type: "cache",
        component: <CachedIcon />,
        name: "Cache Status",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByCacheStatusReverse : sortByCacheStatus)
    },
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByNameReverse : sortByName)
    },
    {
        type: "text",
        name: "Zoom",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByZoomReverse : sortByZoom)
    },
    {
        type: "text",
        name: "Size",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortBySizeReverse : sortBySize)
    },
    {
        type: "bool",
        name: "Geotiff",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByGeotiffReverse : sortByGeotiff)
    },
    {
        type: "bool",
        name: "Color Palette",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByCptReverse : sortByCpt)
    },
    {
        type: "date",
        name: "Created",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByCreatedReverse : sortByCreated)
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByModifiedReverse : sortByModified),
        start: true
    },
    {
        type: "text",
        name: "Permission",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByPermissionReverse : sortByPermission)
    }
];

export default rastersSorting;
