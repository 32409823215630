import { toastr } from "react-redux-toastr";
import { mapboxAttribution } from "../constants/attributions";
import blankBasemapStyle from "../constants/blankBasemapStyle";
import mapboxClient from "../../../actions/mapboxClient";
import config from "../../../config";
import { HelperLayers } from "../constants/helperLayers";
import { validate } from "@emblautec/mapbox-gl/dist/style-spec/index.es";

export const getBasemapStyle = (basemap) => {
    return new Promise((resolve) => {
        switch (basemap.type) {
            case "vector":
                resolve(getVectorBaseMap(basemap));
                break;
            case "raster":
                resolve(getRasterBasemap(basemap));
                break;
            case "none":
                resolve(getEmptyBaseMap(basemap));
                break;
            default:
                throw new Error("Invalid basemap type");
        }
    });
};

const getEmptyBaseMap = (basemap) => {
    const newStyle = JSON.parse(JSON.stringify(blankBasemapStyle));
    const basemapStyle = initBasemapStyle(newStyle, basemap);
    return basemapStyle;
};

export const getVectorBasemapStylePath = (basemapUrl) => {
    const basemapUrlSplit = basemapUrl.split("://");
    const protocol = basemapUrlSplit[0];

    if (protocol !== "mapbox") {
        toastr.error("Unsupported map type");
        return;
    }

    const path = basemapUrlSplit[1];
    let stylePath = path.split("styles/")[1] ?? "";
    if (stylePath.slice(-1) === "/") {
        // remove last character if it's /
        stylePath = stylePath.slice(0, -1);
    }
    return "styles/v1/" + stylePath;
};

const getStyle = (stylePath) => mapboxClient.get(stylePath + "?access_token=" + config.mapboxApiKey).then((res) => res.data);

const getVectorBaseMap = (basemap) => {
    const stylePath = getVectorBasemapStylePath(basemap.url);

    return getStyle(stylePath)
        .then((style) => {
            const basemapStyle = initBasemapStyle(style, basemap);
            return basemapStyle;
        })
        .catch((err) => {
            toastr.error(err.message);
        });
};

const getRasterBasemap = (basemap) => {
    const newStyle = JSON.parse(JSON.stringify(blankBasemapStyle));

    newStyle.layers = [
        {
            id: "raster-basemap-layer",
            type: basemap.type,
            source: basemap.title,
            paint: {}
        }
    ];

    const basemapStyle = initBasemapStyle(newStyle, basemap);
    return basemapStyle;
};

const initBasemapStyle = (newStyle, basemap) => {
    delete newStyle.zoom;
    delete newStyle.center;

    if (basemap.type === "raster") {
        newStyle.sources[basemap.title] = {
            type: basemap.type === "raster" ? "raster" : "vector",
            tiles: [basemap.url],
            tileSize: 256,
            attribution: mapboxAttribution
        };
    }

    newStyle.sources["mapbox-dem"] = {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        tileSize: 512,
        maxzoom: 14
    };

    newStyle.sprite = config.apiUrl + "sprite/";

    addHelperLayers(newStyle);
    removeInvalidLayers(newStyle);
    return newStyle;
};

const addHelperLayers = (newStyle) => {
    const layers = newStyle.layers;
    let lastSymbolIndex = layers.length;

    for (let i = layers.length - 1; i >= 0; i--) {
        if (layers[i].type !== "symbol") break;
        lastSymbolIndex = i;
    }

    // Added under the last symbol
    newStyle.layers.splice(lastSymbolIndex, 0, {
        type: "background",
        layout: { visibility: "none" },
        paint: {},
        id: HelperLayers.GeometricStartLayer
    });

    // This is added at the top
    newStyle.layers.push({
        type: "background",
        layout: { visibility: "none" },
        paint: {},
        id: HelperLayers.SymbolStartLayer
    });
};

export const removeInvalidLayers = (newStyle) => {
    const errors = validate(newStyle);

    const indexMap = {};

    errors.forEach((error) => {
        // Error message looks like "layers[128].paint.line-width: number expected, null found"
        // So we split on : to get the first part
        const layerText = error.message.split(":")[0];

       // Then we find the index by splitting with a regular expression that searches for "[ ]"
       const layerIndex  = layerText.split(/[[\]]+/g)[1];

        console.error(`GIS_DEV: Layer:${layerIndex} has invalid styling \n Specific error:${error.message}`);

        indexMap[layerIndex] = true;
    });

    newStyle.layers = newStyle.layers.filter((_, index) => !indexMap[index]);
    return errors;
};
