import { makeStyles } from "@mui/styles";

export const useRasterEditViewStyles = makeStyles((theme:any) => ({
    headerText: {
        lineHeight: "30px",
        fontWeight: "400",
        color: theme.customColors.appTextColor
    },
    stylerHeader: {
        padding: "10px 0",
        maxWidth: 320,
        height: theme.customSpacing.header,
        alignItems: "center",
        display: "flex",
        boxSizing: "border-box",
        flexGrow: 1
    },
    backButton: {
        padding: 10,
        margin: "0 0 0 -10px"
    },
    icon: {
        marginRight: 8
    },
    buttonSpacing: {
        marginRight: 20
    }
}));
