import { FC, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import EditIcon from "@mui/icons-material/Edit";
import { Basemap } from "../../../../models/basemap/Basemap";
import { deleteLibraryBasemap } from "../../../../actions/basemaps";
import { handleDeleteLibraryBasemap } from "../../../../reducers/basemaps";
import { useAppDispatch } from "../../../../store/hooks/useAppDispatch";
import { handleError } from "../../../../utils/networkErrorUtils";
import BasemapThumbnail from "../../../../components/BasemapThumbnail/BasemapThumbnail";
import TypeChip from "../../../../components/TypeChip/TypeChip";
import { TableRow, TableCell } from "@mui/material";
import MenuButtonCell from "../../../../components/CustomTable/components/customCells/MenuButtonCell/MenuButtonCell";
import OverflowCell from "../../../../components/CustomTable/components/customCells/OverflowCell/OverflowCell";
import { CELL_SIZES } from "../../../../utils/constants/cellSizes";
import { useBasemapRowStyles } from "./styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BooleanIndicator from "components/BooleanIndicator/BooleanIndicator";

type Props = {
    basemap: Basemap;
    onEditOpen: (property: Basemap) => void;
};

const BasemapRow: FC<Props> = ({ basemap, onEditOpen }) => {
    const classes = useBasemapRowStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useAppDispatch();

    const onMenuClick = (e: any) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const onMenuClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onDeleteLibraryBasemap = (e: any) => {
        onMenuClose(e);
        const toastrConfirmOptions = {
            onOk: () =>
                dispatch(deleteLibraryBasemap(basemap.id))
                    .then((res) => {
                        dispatch(handleDeleteLibraryBasemap(basemap.id));
                    })
                    .catch((err) => {
                        handleError(err);
                    }),
            onCancel: () => {}
        };
        toastr.confirm(
            `This action will delete the basemap from all the applications in which it is used. Are you sure you want to delete basemap: ${basemap.title}?`,
            toastrConfirmOptions
        );
    };

    const onEditClick = (e: any) => {
        onMenuClose(e);
        !basemap.isDefault && onEditOpen(basemap);
    };

    const onClickCopy = (e: any) => {
        onMenuClose(e);
        navigator.clipboard.writeText(basemap.url);
        toastr.success("The text has been copied");
    };

    return (
        <TableRow onClick={onEditClick} data-testid={basemapRowTestId}>
            <TableCell>
                <BasemapThumbnail className={classes.thumbnail} basemapUrl={basemap.url} basemapType={basemap.type} />
            </TableCell>
            <OverflowCell cellSize={CELL_SIZES.medium} color={undefined} size={undefined} className="">
                {basemap.title}
            </OverflowCell>

            <OverflowCell cellSize={CELL_SIZES.extraLarge} color={undefined} size={undefined} className="">
                {basemap.url}
            </OverflowCell>

            <TableCell>
                <TypeChip type={basemap.type} variant="large" />
            </TableCell>

            <TableCell>
                <BooleanIndicator boolValue={basemap.isDefault} className={classes.icon} />
            </TableCell>

            <MenuButtonCell onMenuClick={onMenuClick} />

            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onMenuClose}>
                <Tooltip title={basemap.isDefault ? "Action not allowed for default basemaps" : ""}>
                    <div>
                        <MenuItem onClick={onEditClick} className="menu-button" disabled={basemap.isDefault} data-testid={editMenuItemTestId}>
                            <EditIcon className="icon" />
                            Edit
                        </MenuItem>
                    </div>
                </Tooltip>
                <Tooltip title={basemap.isDefault ? "Action not allowed for default basemaps" : ""}>
                    <div>
                        <MenuItem onClick={onDeleteLibraryBasemap} className="menu-button" disabled={basemap.isDefault} data-testid={deleteMenuItemTestId}>
                            <DeleteIcon className="icon" />
                            Delete
                        </MenuItem>
                    </div>
                </Tooltip>
                <MenuItem onClick={onClickCopy} className="menu-button" data-testid={copyMenuItemTestId}>
                    <ContentCopyIcon className="icon" />
                    Copy url
                </MenuItem>
            </Menu>
        </TableRow>
    );
};

export default BasemapRow;

const basemapRowTestId = "qa-basemap-table-row";
const editMenuItemTestId = "qa-basemap-edit-menu-item";
const deleteMenuItemTestId = "qa-basemap-delete-menu-item";
const copyMenuItemTestId = "qa-basemap-copy-menu-item";
