import CustomTypography from "components/CustomTypography/CustomTypography";
import { getModuleByName } from "features/core/selectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import { useStyles } from "./styles";
import { FC } from "react";

type Props = {
    className?: string;
};

const DprIntegrationDescription: FC<Props> = ({ className = "" }) => {
    const classes = useStyles();
    const dprModule = useAppSelector(getModuleByName("DPR"));
    return (
        <div className={className}>
            <div className={classes.title}>
                <img className={classes.icon} src={dprModule.favIconUrl} alt="DPR icon" />
                <CustomTypography textWeight="semibold" variant="h3">
                    DPR Integration
                </CustomTypography>
            </div>
            <CustomTypography variant="body1" textWeight="bold" fontSize={14} className={classes.description}>
                By adding milestones, you will be able to track the progress of milestones in the locations relevant to you.
            </CustomTypography>
        </div>
    );
};

export default DprIntegrationDescription;
