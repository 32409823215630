import { FC, useEffect, useState } from "react";
import { getMetadataSchemaThunk } from "../../../../actions/metadataSchema";
import { reset } from "../../../../reducers/metadataSchema";
import { getIsMetadataLoading, getMetadataSchema } from "../../../../selectors/metadataSchema";
import { metadataColumns } from "./metadataColumns";

import { METADATA_SCHEMA_ID } from "../../../../utils/constants/metadata";
import { useAppSelector } from "store/hooks/useAppSelector";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { MetadataProperty } from "models/metadataSchema/MetdataProperty";
import MetadataRow from "./MetadataRow";
import TableView from "components/TableView/TableView";
import EditPropertyModal from "./EditPropertyModal/EditPropertyModal";

const EditMetadataSchemaView: FC = () => {
    const [editModalProperty, setEditModalProperty] = useState<MetadataProperty | null>(null);

    const metadataSchema = useAppSelector(getMetadataSchema);
    const loading = useAppSelector(getIsMetadataLoading);
    const dispatch = useAppDispatch();

    const onEditClose = () => {
        setEditModalProperty(null);
    };

    const onEditOpen = (property: MetadataProperty) => {
        setEditModalProperty(property);
    };

    useEffect(() => {
        dispatch(getMetadataSchemaThunk(METADATA_SCHEMA_ID));
        return () => {
            dispatch(reset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterItem = (item: MetadataProperty, query: string) => {
        return item.name.toLowerCase().includes(query.toLowerCase());
    };

    const generateItem = (field: MetadataProperty) => <MetadataRow property={field} key={field.id} onEditOpen={onEditOpen} />;

    return (
        <>
            <TableView
                searchPlaceholder={"Search metadata schemas"}
                data={metadataSchema}
                fetchingData={loading}
                columns={metadataColumns}
                generateItem={generateItem}
                filterFunction={filterItem}
                hideBottomBar
                tableSize={undefined}
                onSelectAll={undefined}
            />

            <EditPropertyModal open={!!editModalProperty} onClose={onEditClose} property={editModalProperty} />
        </>
    );
};

export default EditMetadataSchemaView;
