export const AUTHENTICATING = "AUTHENTICATING";
export const AUTHENTICATED = "AUTHENTICATED";
export const AUTHENTICATING_FAILED = "AUTHENTICATING_FAILED";

export const FETCH_MAPS = "FETCH_MAPS";
export const FETCH_MAPS_COMPLETE = "FETCH_MAPS_COMPLETE";

export const GENERATE_APP_CACHE = "GENERATE_APP_CACHE";
export const GENERATE_APP_CACHE_FAILED = "GENERATE_APP_CACHE_FAILED";
export const DOWNLOAD_DATASET = "DOWNLOAD_DATASET";
export const DOWNLOAD_DATASET_COMPLETE = "DOWNLOAD_DATASET_COMPLETE";

export const FETCH_DATASETS = "FETCH_DATASETS";
export const FETCH_DATASETS_COMPLETE = "FETCH_DATASETS_COMPLETE";

export const FETCH_DATASET_DETAILS = "FETCH_DATASET_DETAILS";
export const FETCH_DATASET_DETAILS_COMPLETE = "FETCH_DATASET_DETAILS_COMPLETE";
export const UPDATE_DATASET_SCHEMA = "UPDATE_DATASET_SCHEMA";
export const UPDATE_DATASET_SCHEMA_COMPLETE = "UPDATE_DATASET_SCHEMA_COMPLETE";
export const DATASET_ACTION_FAILED = "DATASET_ACTION_FAILED";

export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_COMPLETE = "FETCH_GROUPS_COMPLETE";
export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUP_COMPLETE = "FETCH_GROUP_COMPLETE";
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_COMPLETE = "CREATE_GROUP_COMPLETE";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_COMPLETE = "UPDATE_GROUP_COMPLETE";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_COMPLETE = "DELETE_GROUP_COMPLETE";
export const GROUP_ACTION_FAILED = "GROUP_ACTION_FAILED";
export const GROUP_STOP_LOADER = "GROUP_STOP_LOADER";

export const FETCH_APPS = "FETCH_APPS";
export const FETCH_APPS_COMPLETE = "FETCH_APPS_COMPLETE";
export const CREATE_APP = "CREATE_APP";
export const CREATE_APP_COMPLETE = "CREATE_APP_COMPLETE";
export const ADD_RASTER_TO_APP = "ADD_RASTER_TO_APP";
export const ADD_RASTER_TO_APP_COMPLETE = "ADD_RASTER_TO_APP_COMPLETE";
export const REMOVE_RASTER_FROM_APP = "REMOVE_RASTER_FROM_APP";
export const REMOVE_RASTER_FROM_APP_COMPLETE = "REMOVE_RASTER_FROM_APP_COMPLETE";
export const DELETE_APP = "DELETE_APP";
export const DELETE_APP_COMPLETE = "DELETE_APP_COMPLETE";
export const PUBLISH_APP = "PUBLISH_APP";
export const PUBLISH_APP_COMPLETE = "PUBLISH_APP";
export const APP_ACTION_FAILED = "APP_ACTION_FAILED";
export const SET_RASTER_COLOR_TABLE_COLOR = "SET_RASTER_COLOR_TABLE_COLOR";
export const SET_RASTER_COLOR_TABLE_VALUE = "SET_RASTER_COLOR_TABLE_VALUE";
export const SET_RASTER_COLOR_TABLE_LABEL = "SET_RASTER_COLOR_TABLE_LABEL";
export const REORDER_RASTER_COLOR_TABLE = "REORDER_RASTER_COLOR_TABLE";

export const CREATE_DATASET_COMPLETE = "CREATE_DATASET_COMPLETE";

export const UPDATE_DATASET = "UPDATE_DATASET";
export const UPDATE_DATASET_COMPLETE = "UPDATE_DATASET_COMPLETE";
export const UPDATE_DATASET_ADVANCED = "UPDATE_DATASET_ADVANCED";
export const UPDATE_DATASET_ADVANCED_COMPLETE = "UPDATE_DATASET_ADVANCED_COMPLETE";

export const REPLACE_DATASET = "REPLACE_DATASET_ADVANCED";
export const REPLACE_DATASET_COMPLETE = "REPLACE_DATASET_ADVANCED_COMPLETE";

export const DELETE_DATASET = "DELETE_DATASET";
export const DELETE_DATASET_COMPLETE = "DELETE_DATASET_COMPLETE";

export const GENERATE_DATASET_CACHE = "GENERATE_DATASET_CACHE";
export const GENERATE_DATASET_CACHE_COMPLETE = "GENERATE_DATASET_CACHE_COMPLETE";

export const CANCEL_GENERATE_DATASET_CACHE = "CANCEL_GENERATE_DATASET_CACHE";
export const CANCEL_GENERATE_DATASET_CACHE_COMPLETE = "CANCEL_GENERATE_DATASET_CACHE_COMPLETE";

export const FETCH_SCHEMA_NAMES = "FETCH_SCHEMA_NAMES";
export const FETCH_SCHEMA_NAMES_COMPLETE = "FETCH_SCHEMA_NAMES_COMPLETE";

export const SIGNALR_PROGRESS_UPDATE = "SIGNALR_PROGRESS_UPDATE";
export const SIGNALR_ERROR_UPDATE = "SIGNALR_ERROR_UPDATE";

export const LINK_DATASET_GOOGLE_DRIVE = "LINK_DATASET_GOOGLE_DRIVE";
export const LINK_DATASET_GOOGLE_DRIVE_COMPLETE = "LINK_DATASET_GOOGLE_DRIVE_COMPLETE";

export const OPEN_UPLOADER = "OPEN_UPLOADER";
export const CLOSE_UPLOADER = "CLOSE_UPLOADER";

export const FETCH_SCHEMAS = "FETCH_SCHEMAS";
export const FETCH_SCHEMAS_COMPLETE = "FETCH_SCHEMAS_COMPLETE";
export const CREATE_SCHEMA = "CREATE_SCHEMA";
export const CREATE_SCHEMA_COMPLETE = "CREATE_SCHEMA_COMPLETE";
export const DELETE_SCHEMA = "DELETE_SCHEMA";
export const DELETE_SCHEMA_COMPLETE = "DELETE_SCHEMA_COMPLETE";
export const SCHEMAS_ACTION_FAILED = "SCHEMAS_ACTION_FAILED";

export const PANEL_TOGGLE = "PANEL_TOGGLE";
export const NAVIGATION_TOGGLE = "NAVIGATION_TOGGLE";

export const UPDATE_APP_PUBLISHED = "UPDATE_APP_PUBLISHED";
