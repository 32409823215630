import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./customBaseQuery";
import config from "../config";
import { atlasApiTags } from "./atlasApiTags";
import apiClient from "../actions/apiClient";

const baseAtlasApi = createApi({
    baseQuery: axiosBaseQuery(config.apiUrl + "api/", apiClient),
    tagTypes: Object.values(atlasApiTags).reduce<string[]>((acc, tagGroup) => {
        return acc.concat(Object.values(tagGroup));
    }, []),
    endpoints: () => ({})
});

export default baseAtlasApi;

export const resetApiState = baseAtlasApi.util?.resetApiState;
