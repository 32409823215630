import baseAtlasApi from "../../store/baseAtlasApi";
import { MetadataPropertyValue } from "../metadata/models/MetadataPropertyValue";
import { METADATA_SCHEMA_ID } from "../../utils/constants/metadata";
import { DatasetProperties } from "./models/DatasetProperties";

type GetDatasetPropertiesQuery = {
    datasetId: string;
    page: number;
    searchText?: string;
    rowsPerPage?: number;
    orderBy?: string;
    orderByDescending?: boolean;
};
type GetDatasetPropertiesResult = { properties: DatasetProperties[] };

type GetDatasetValuesByColumnQuery = {
    datasetId: string;
    columnId: number;
};

export const datasetsApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        fetchSchemas: build.query<string[], void>({
            query: () => ({ url: "dataset/schema", method: "GET" })
        }),
        bulkUpdateMetadata: build.mutation<void, { datasetIds: string[]; metadata: MetadataPropertyValue[] }>({
            query: ({ datasetIds, metadata }) => ({
                url: "dataset/meta",
                method: "PUT",
                data: { datasetIds, metadata, metadataSchemaId: METADATA_SCHEMA_ID }
            })
        }),
        fetchDatasetProperties: build.query<GetDatasetPropertiesResult, GetDatasetPropertiesQuery>({
            query: ({ datasetId, page, rowsPerPage, searchText, orderBy, orderByDescending }) => {
                let params = {
                    ...(!!searchText && { searchText }),
                    ...(!!rowsPerPage && { rowsPerPage }),
                    ...(!!orderBy && { orderBy }),
                    ...(!!orderByDescending && { orderByDescending })
                };

                return {
                    url: `dataset/${datasetId}/json/${page}`,
                    method: "GET",
                    params
                };
            }
        }),
        fetchDatasetRowCount: build.query<number, string>({
            query: (datasetId) => ({ url: `dataset/${datasetId}/json/count`, method: "GET" })
        }),
        fetchDatasetValuesByColumn: build.query<string[], GetDatasetValuesByColumnQuery>({
            query: ({ datasetId, columnId }) => ({ url: `dataset/${datasetId}/columns/${columnId}/values`, method: "GET" })
        })
    })
});

export const {
    useFetchSchemasQuery,
    useBulkUpdateMetadataMutation,
    useFetchDatasetPropertiesQuery,
    useFetchDatasetRowCountQuery,
    useFetchDatasetValuesByColumnQuery,
    usePrefetch: useDatasetsPrefetch
} = datasetsApi;
