import { makeStyles } from "@mui/styles";

export const useRastersListViewStyles = makeStyles((theme: any) => ({
    title: {
        flexGrow: 1
    },
    icon: {
        marginRight: 8
    },
    sidebarContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%"
    },
    rasterOverview: {
        width: `calc(100vw - ${theme.customSpacing.sidenavCollapsedWidth})`,
        height: "100%",
        overflow: "auto"
    },
    menuIsOpen: {
        width: `calc(100vw - ${theme.customSpacing.sidenavPinnedWidth})`
    }
}));