import makeStyles from "@mui/styles/makeStyles";
import { lighten } from "@mui/material/styles";

export const useDatasetEditViewStyles = makeStyles((theme: any) => ({
    backButton: {
        padding: 10,
        margin: "0 0 0 -10px"
    },
    cacheIcon: {
        fontSize: 21.5,
        marginRight: 5,
        marginLeft: -5
    },
    stylerHeader: {
        padding: "10px 0",
        maxWidth: 320,
        height: 52,
        alignItems: "center",
        display: "flex",
        boxSizing: "border-box",
        flexGrow: 1
    },
    page: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        height: "100%",
        padding: `${theme.customSpacing.sidePanelTopBottomPadding} ${theme.customSpacing.generalSidePadding}`,
        overflow: "auto"
    },
    copyButton: {
        minWidth: "31px",
        height: "30px",
        marginLeft: "20px",
        padding: "0 5px",
        borderRadius: "3px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.customColors.infoColor,
        "& img": {
            width: "20px",
            height: "20px"
        },
        "&:hover": {
            cursor: "pointer",
            backgroundColor: lighten(theme.customColors.infoColor, 0.2)
        }
    },
    field: {
        padding: "8px 0",

        "& .tag-list": { paddingBottom: 0 }
    },
    flexField: {
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
            width: "48%"
        }
    },
    copyable: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    fullWidth: {
        width: "100%"
    },
    lightGrayBorder: {
        borderBottom: `1px solid ${theme.customColors.borderColor}`
    }
}));
