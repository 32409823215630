import React from "react";
import { CircularProgress, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import CustomTypography from "../CustomTypography/CustomTypography";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "rgb(245, 247, 248)",
        height: "100%",
        width: "100%"
    }
}));

const LoaderComponent = ({ text }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} justifyContent="center" alignItems="center">
            <Grid item xs={12} className={classes.loaderContainer}>
                <Grid container flexDirection={"column"} justifyContent="center" alignItems="center" className={classes.container}>
                    <CircularProgress size={60} />
                    <CustomTypography variant="h6">{text}</CustomTypography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LoaderComponent;
