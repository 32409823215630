import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "./apiClient";

type PublishAppParams = {
    applicationId: string,
    sendEmailNotification: boolean,
    message: string
};

export const publishAppThunk = createAsyncThunk("publishApp", ({ applicationId, sendEmailNotification, message }: PublishAppParams, { rejectWithValue }) => {
    return axiosClient
        .post(`application/${applicationId}/publish`, { sendEmailNotification, message })
        .then((res) => res.data)
        .catch((err) => rejectWithValue(err));
});