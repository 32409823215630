import InfoTextSection from "components/InfoTextSection/InfoTextSection";

const DefaultInfoText = () => {
    return (
        <InfoTextSection iconSize="extra-small" textVariant="body2" textWeight="semibold">
            The changes made to this dataset will impact all the applications that contain this dataset.
        </InfoTextSection>
    );
};

export default DefaultInfoText;
