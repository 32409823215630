import { FC, useState } from "react";
import { useTheme } from "@mui/styles";
import { useStyles } from "./styles";
import { LinearProgress } from "@mui/material";
import { Raster } from "features/rasters/models/Raster";
import CustomModal from "components/CustomModal/CustomModal";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { downloadGeotiff } from "features/rasters/api";
import useComponentCancelToken from "app/hooks/useComponentCancelToken";
import fileDownload from "js-file-download";
import toastr from "components/CustomToastr/CustomToastr";

type Props = {
    raster: Raster;
    open: boolean;
    handleClose: () => void;
};

const GeotiffDownloader: FC<Props> = ({ open, handleClose, raster }) => {
    const classes = useStyles();
    const theme: any = useTheme();

    const [isLoading, setIsLoading] = useState(false);

    const [downloadProgressPercentage, setDownloadProgressPercentage] = useState(0);
    const [cancelToken] = useComponentCancelToken();

    const onDownloadGeotiff = () => {
        const fileName = `${raster.name}.tif`;
        const request = {
            rasterId: raster.id,
            fileName: fileName,
            downloadProgressHandler: (progressEvent: ProgressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setDownloadProgressPercentage(percentCompleted);
            },
            cancelToken: cancelToken.token
        };

        setIsLoading(true);
        downloadGeotiff(request).then((res) => {
            fileDownload(res, fileName);
            setIsLoading(false);
            toastr.success("Geotiff downloaded");
        });
    };

    return (
        <CustomModal handleClose={handleClose} isOpen={open} onConfirm={onDownloadGeotiff} dialogTitle={"Download"} dialogType={"start"} disableCancel={isLoading}>
            {!isLoading && (
                <CustomTypography variant="subtitle1" textWeight="semibold" color={theme.customColors.appTextColorLight}>
                    {raster.name}.geotiff
                </CustomTypography>
            )}
            {isLoading && (
                <div className="progress-page">
                    {downloadProgressPercentage === 0 && (
                        <div>
                            <CustomTypography variant="h6" id="transition-modal-title" className="text">
                                Generating File
                            </CustomTypography>
                            <LinearProgress className={classes.progressBar} variant="indeterminate" />
                        </div>
                    )}
                    {downloadProgressPercentage > 0 && (
                        <div className={classes.downloadContainer}>
                            <CustomTypography variant="h2" textWeight="bold" color={theme.customColors.appTextColorLight}>
                                Downloading {raster.name}
                            </CustomTypography>
                            <CustomTypography variant="body1" className={classes.percentage}>
                                {downloadProgressPercentage}%
                            </CustomTypography>
                            <LinearProgress className={classes.progressBar} variant="determinate" value={downloadProgressPercentage} />
                        </div>
                    )}
                </div>
            )}
        </CustomModal>
    );
};

export default GeotiffDownloader;
