import { makeStyles } from "@mui/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";

export const useStyles = makeStyles((theme:any) => ({
    progressBar: {
        height: 10,
        width: 350,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.customColors.borderColor
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5
        }
    },
    percentage: {
        marginTop: 16,
        marginBottom: 8
    },
    downloadContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}));
