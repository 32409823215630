import { Dispatch, FC, SetStateAction, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import GeotiffUploader from "./GeotiffUploader/GeotiffUploader";
import GeotiffDownloader from "./GeotiffDownloader/GeotiffDownloader";
import CPTUploader from "./CPTUploader/CPTUploader";
import toastr from "components/CustomToastr/CustomToastr";
import { PermissionType } from "features/groups/model/PermissionType";
import CachedIcon from "@mui/icons-material/Autorenew";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getFeatureFlags } from "features/featureFlags/selectors";
import { Raster } from "features/rasters/models/Raster";
import { useGenerateCacheMutation } from "features/rasters/api";

type Props = {
    raster: Raster;
    actionsAnchor: Element | null;
    setActionsAnchor: Dispatch<SetStateAction<Element | null>>;
    onCloseActions: () => void;
};

const MenuAndModals: FC<Props> = ({ raster, actionsAnchor, setActionsAnchor, onCloseActions }) => {
    const [uploadGeotiffOpen, setUploadGeotiffOpen] = useState(false);
    const [uploadCPTOpen, setUploadCPTOpen] = useState(false);
    const [downloadGeotiffOpen, setDownloadGeotiffOpen] = useState(false);

    const [generateCache] = useGenerateCacheMutation();

    const featureFlags = useAppSelector(getFeatureFlags);

    const onOpenGeotiffUploader = () => {
        setUploadGeotiffOpen(true);
        setActionsAnchor(null);
    };

    const onOpenCPTUploader = () => {
        setUploadCPTOpen(true);
        setActionsAnchor(null);
    };

    const onCloseGeotiffUploader = () => {
        setUploadGeotiffOpen(false);
    };

    const onCloseCPTUploader = () => {
        setUploadCPTOpen(false);
    };

    const onOpenGeotiffDownloader = () => {
        setDownloadGeotiffOpen(true);
    };

    const onCloseGeotiffDownloader = () => {
        setDownloadGeotiffOpen(false);
    };

    const onGenerateClick = () => {
        const toastrOption = {
            onOk: () => generateCache(raster.id),
            onCancel: () => {}
        };

        onCloseActions();
        toastr.confirm("Are you sure you want to regenerate the mbtiles?", toastrOption);
    };

    return (
        <>
            <Menu
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                anchorEl={actionsAnchor}
                open={Boolean(actionsAnchor)}
                onClose={onCloseActions}
                PaperProps={{ square: true }}
            >
                {raster.geoTiff && (
                    <MenuItem onClick={onGenerateClick} className="menu-button" data-testid={regenerateButtonTestId} disabled={raster.permissionType < PermissionType.WRITE_READ}>
                        <CachedIcon className={`icon flip`} />
                        Cache Raster
                    </MenuItem>
                )}

                <MenuItem onClick={onOpenGeotiffUploader} className="menu-button" data-testid={uploadButtonTestId} disabled={raster.permissionType < PermissionType.WRITE_READ}>
                    <PublishIcon className="icon" />
                    Upload Geotiff
                </MenuItem>

                {raster.geoTiff && (
                    <MenuItem onClick={onOpenGeotiffDownloader} className="menu-button" data-testid={downloadButtonTestId} disabled={raster.permissionType < PermissionType.READ}>
                        <GetAppIcon className="icon" />
                        Download Geotiff
                    </MenuItem>
                )}

                {featureFlags.CPT && (
                    <MenuItem onClick={onOpenCPTUploader} className="menu-button" data-testid={uploadCptButtonTestId} disabled={raster.permissionType < PermissionType.WRITE_READ}>
                        <PublishIcon className="icon" />
                        Upload Color Palette
                    </MenuItem>
                )}
            </Menu>
            <GeotiffUploader open={uploadGeotiffOpen} raster={raster} handleClose={onCloseGeotiffUploader} />
            <CPTUploader open={uploadCPTOpen} raster={raster} handleClose={onCloseCPTUploader} />
            <GeotiffDownloader open={downloadGeotiffOpen} raster={raster} handleClose={onCloseGeotiffDownloader} />
        </>
    );
};

export default MenuAndModals;

const uploadButtonTestId = "qa-raster-edit-view-upload-button";
const uploadCptButtonTestId = "qa-raster-edit-view-cpt-upload-button";
const downloadButtonTestId = "qa-raster-edit-view-download-button";
const regenerateButtonTestId = "qa-raster-edit-view-regenerate-geotiff-button";
