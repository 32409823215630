import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
    groupOverview: {
        width: `calc(100vw - ${theme.customSpacing.sidenavCollapsedWidth})`,
        overflow: 'auto',
        '& .group': {
            color: theme.customColors.appTextColor,
            userSelect: 'none',
            transition: 'all 0.2s',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: theme.customColors.appBgColorLight,
            marginBottom: 4,
            borderRight: `2px solid transparent`,
            cursor: 'pointer',
            '& .name': {
                flexGrow: 1,
                paddingLeft: 16,
                '&:hover': {
                    color: theme.customColors.primaryColor,
                },
            },
            '& .button': {
                transition: 'all 0.2s',
                //TODO: see what you can do about this
                color: theme.iconColor,
                fontSize: 16,
                borderLeft: `4px solid ${theme.customColors.appBgColor}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 16,
                '&:hover': {
                    color: 'white',
                    backgroundColor: 'rgb(88, 88, 88)',
                },
            },
            '&.selected': {
                borderColor: theme.customColors.primaryColor,
            },
            '&:hover': {
                borderColor: theme.customColors.primaryColor,
            },
        },
    },
    menuIsOpen: {
        width: `calc(100vw - ${theme.customSpacing.sidenavPinnedWidth})`,
    },
}));
