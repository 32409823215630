import React from "react";
import { Route, useRouteMatch, Switch as RouterSwitch } from "react-router-dom";
import DatasetsListView from "../datasetsListView/DatasetsListView";
import DatasetEditView from "../datasetEditView/datasetEditView";
import { TableContextProvider } from "components/CustomTable/providers/TableContextProvider";

const DatasetRootViewFunctional = () => {
    const match = useRouteMatch();

    return (
        <div className="datasets-rootview">
            <RouterSwitch>
                <Route path={match.path} exact>
                    <TableContextProvider>
                        <DatasetsListView />
                    </TableContextProvider>
                </Route>
                <Route path={`${match.path}/edit/:datasetId`} component={DatasetEditView} />
            </RouterSwitch>
        </div>
    );
};

export default DatasetRootViewFunctional;

