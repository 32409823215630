import { useContext, useMemo } from "react";
import { TableContext } from "../providers/TableContextProvider";

export const useFilteredData = <TData>(data: TData[]) => {
    const activeFilters = useContext(TableContext).filters.filter((f) => f.active);

    const applyFilters = (data: TData[]) => {
        const filteredData = data.filter(filterData);

        return filteredData;
    };

    const filterData = (row: TData) => {
        let valid = true;

        for (const filter of activeFilters) {
            valid = valid && filter.filterFunction(row[filter.columnName], filter.selected);
        }

        return valid;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filteredData = useMemo(() => applyFilters(data), [data, activeFilters]);

    return filteredData;
};
