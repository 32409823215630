import { Button } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { makeAppPublicThunk } from "actions/apps";
import InfoTextSection from "components/InfoTextSection/InfoTextSection";
import { getApp, getFetching } from "selectors/appData";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { handleError } from "utils/networkErrorUtils";
import toastr from "components/CustomToastr/CustomToastr";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import { FC } from "react";
import { ComponentProps } from "../AppSettingsView";

const ChangeAppVisibilitySection: FC<ComponentProps> = ({ disabled }) => {
    const app = useAppSelector(getApp);
    const fetching = useAppSelector(getFetching);

    const dispatch = useAppDispatch();

    const togglePublic = () => {
        // TODO: the ts-ignore should be removed after refactoring to ts the file where updateEnabledAppThunk is
        // @ts-ignore
        dispatch(makeAppPublicThunk({ appId: app.id, ispublic: !app.public }))
            .then(unwrapResult)
            .then(() => {
                const message = app.public ? "private" : "public";
                toastr.success(`Application is now ${message}`);
            })
            .catch((err) => {
                handleError(err);
            });
    };

    const onClickTogglePublic = () => {
        const toastrConfirmOptions = {
            onOk: togglePublic,
            onCancel: () => {}
        };

        const togglePublicConfirmationMessage = app.public
            ? "You are about to make the application private. Do you want to proceed?"
            : "You are about to make the application public.  Anyone with the link can access public applications. Do you want to proceed?";

        toastr.confirm(togglePublicConfirmationMessage, toastrConfirmOptions);
    };

    return (
        <>
            <InfoTextSection paddingBottom={2} iconSize="extra-small" textVariant="body2" textWeight="semibold">
                Anyone with the link can access public applications
            </InfoTextSection>
            <Button onClick={onClickTogglePublic} disabled={fetching || disabled} variant="text" color="primary" data-testid={changeVisibilityButtonTestId}>
                {app.public ? (
                    <>
                        <PublicOffIcon className="left-icon" />
                        Make private
                    </>
                ) : (
                    <>
                        <PublicIcon className="left-icon" />
                        Make public
                    </>
                )}
            </Button>
        </>
    );
};

export default ChangeAppVisibilitySection;

const changeVisibilityButtonTestId = "qa-app-edit-view-settings-change-visibility-button";
