import { FC } from "react";
import clsx from "clsx";
import { useBooleanIndicatorStyles } from "./style";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";

type Props = {
    boolValue: Boolean;
    className?: string;
};

const BooleanIndicator: FC<Props> = ({ boolValue, className }) => {
    const classes = useBooleanIndicatorStyles();

    return boolValue ? (
        <CheckCircleRoundedIcon className={clsx(className, classes.icon, classes.isTrue)} />
    ) : (
        <CancelIcon className={clsx(className, classes.icon, classes.isFalse)} />
    );
};

export default BooleanIndicator;
