import { TextField, Button, Tooltip } from "@mui/material";
import ReadOnlyField from "components/ReadOnlyField/ReadOnlyField";
import { getCurrentUserRole } from "features/auth/selectors";
import { PermissionType } from "features/groups/model/PermissionType";
import { useFormikContext } from "formik";
import { Dataset } from "models/Dataset";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import toastr from "../../../../../components/CustomToastr/CustomToastr";
import { ADMIN_ROLE, UPLOADER_ROLE } from "utils/accountUtils";
import fileCopyIcon from "../../../../../utils/icons/copy-icon.svg";
import InfoSection from "../InfoSection/InfoSection";
import AppSection from "../AppSection/AppSection";
import { useDatasetEditViewStyles } from "../../styles";
import clsx from "clsx";
import DefaultInfoText from "../DefaultInfoText/DefaultInfoText";

type DetailsTabFormType = {
    datasetName: string;
};

type DetailsTabProps = {
    dataset: Dataset;
    setBasicDataChanged: Dispatch<SetStateAction<boolean>>;
};

const DetailsTab = ({ dataset, setBasicDataChanged }: DetailsTabProps) => {
    const classes = useDatasetEditViewStyles();
    const { values, errors, handleChange } = useFormikContext<DetailsTabFormType>();
    const userRole = useSelector(getCurrentUserRole);

    return (
        <div className={classes.page}>
            <DefaultInfoText />
            <div className={clsx(classes.field, classes.copyable)}>
                {userRole === UPLOADER_ROLE ? (
                    <ReadOnlyField label="Dataset Name" text={values.datasetName} />
                ) : (
                    <TextField
                        id="filled-required"
                        label="Dataset Name"
                        placeholder="Name of dataset"
                        variant="filled"
                        fullWidth
                        name="datasetName"
                        value={values.datasetName}
                        onChange={(e) => {
                            handleChange(e);
                            setBasicDataChanged(true);
                        }}
                        helperText={errors.datasetName}
                        error={!!errors.datasetName}
                        inputProps={{ "data-testid": datasetNameTextTestId }}
                        disabled={dataset.permissionType < PermissionType.WRITE_READ}
                    />
                )}
                <Tooltip title="Copy to clipboard">
                    <Button
                        className={classes.copyButton}
                        onClick={() => {
                            navigator.clipboard.writeText(values.datasetName);
                            toastr.success("The text has been copied");
                        }}
                        data-testid={copyDatasetNameButtonTestId}
                    >
                        <img alt="" src={fileCopyIcon} />
                    </Button>
                </Tooltip>
            </div>
            <InfoSection dataset={dataset} />
            {userRole === ADMIN_ROLE && <AppSection />}
        </div>
    );
};

export default DetailsTab;

const datasetNameTextTestId = "qa-dataset-edit-view-info-tab-dataset-name-text";
const copyDatasetNameButtonTestId = "qa-dataset-edit-view-info-tab-copy-dataset-name-button";
