import { FC, useMemo } from "react";
import { MAPBOX_BASE_URL } from "../../actions/mapboxClient";
import config from "../../config";
import { DATA_TYPES } from "../../utils/constants/dataType";
import mapPreview from "../../utils/icons/mapImage.png";
import { getVectorBasemapStylePath } from "../map/utils/basemapUtils";
import PreviewableImage from "components/PreviewableImage/PreviewableImage";

type Props = {
    basemapUrl: string;
    basemapType: string;
    className?: string;
};

const BasemapThumbnail: FC<Props> = ({ className, basemapUrl, basemapType }) => {
    const thumbnailUrl = useMemo(() => {
        if (basemapType === DATA_TYPES.vector) {
            const stylePath = getVectorBasemapStylePath(basemapUrl);
            return MAPBOX_BASE_URL + stylePath + "/tiles/0/0/0" + "?access_token=" + config.mapboxApiKey;
        }
        return basemapUrl.replace(/{z}/g, "0").replace(/{x}/g, "0").replace(/{y}/g, "0");
    }, [basemapUrl]);

    return <PreviewableImage className={className} imageUrl={thumbnailUrl} defaultImage={mapPreview} />;
};

export default BasemapThumbnail;
