import { useFetchGroupDatasetsPermissionsQuery } from "features/groups/api";
import { permissionTypes, resourceTypes } from "utils/constants/permissionTypes";
import ResourcePermissionsList from "features/groups/components/GroupEditView/components/ResourcePermissionsList/ResourcePermissionsList";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";

type GroupDatasetsPermissionsContainerProps = {
    groupId: string;
    onChangesMade: () => void;
};

const GroupDatasetsPermissionsContainer = ({ groupId, onChangesMade }: GroupDatasetsPermissionsContainerProps) => {
    const {
        data: datasetPermissions = [],
        isFetching: areDatasetPermissionsLoading,
        refetch: refetchDatasetPermissions,
        isError: isDatasetPermissionsError
    } = useFetchGroupDatasetsPermissionsQuery(groupId);

    return (
        <LoadingPlaceholder loading={areDatasetPermissionsLoading} linearProgress>
            <ErrorPlaceholder error={isDatasetPermissionsError} message="Encountered an error while getting your data" textVariant="h5" onTryAgain={refetchDatasetPermissions}>
                <ResourcePermissionsList
                    resources={datasetPermissions}
                    permissions={permissionTypes}
                    groupId={groupId}
                    onChangesMade={onChangesMade}
                    resourceType={resourceTypes.DATASET}
                />
            </ErrorPlaceholder>
        </LoadingPlaceholder>
    );
};

export default GroupDatasetsPermissionsContainer;
