import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    container: {
        display: "flex",
        color: theme.customColors.appTextColor,
        maxHeight: 340,
        minHeight: 340
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(1.5)
    },
    button: {
        display: "flex",
        justifyContent: "flex-start",
        color: theme.customColors.appTextColorLight,
        marginInline: theme.spacing(0.5),

        "& svg": { marginRight: theme.spacing(0.5) }
    },
    isSelected: {
        color: theme.customColors.primaryColor
    }
}));
