import { createSlice } from "@reduxjs/toolkit";
import { resetProjectData } from "actions/globalActions";
import { getFeatureFlags } from "./actions";

export type FeatureFlagName = "FIDU" | "PUBLIC" | "NOTIFICATIONS" | "CPT" | "DPR" | "INFOBOX";

export type FeatureFlags = {
    FIDU: boolean;
    PUBLIC: boolean;
    NOTIFICATIONS: boolean;
    CPT: boolean;
    DPR: boolean;
    INFOBOX: boolean;
};

const initialState: FeatureFlags = {
    FIDU: false,
    PUBLIC: false,
    NOTIFICATIONS: false,
    CPT: false,
    DPR: false,
    INFOBOX: false
};

export const featureFlagsSlice = createSlice({
    name: "featureFlags",
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(getFeatureFlags.fulfilled, (state, { payload: featureFlags }) => {
                Object.keys(state).forEach((feature) => {
                    // did this to support features that are only defined on the frontend
                    const featureNotDefinedOnBackend = featureFlags[feature] === undefined;
                    if (featureNotDefinedOnBackend) return;

                    state[feature] = featureFlags[feature];
                });
            })
            .addCase(resetProjectData, () => initialState)
});

export const {} = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
