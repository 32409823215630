import { useAddGroupUserMutation, useRemoveGroupUserMutation } from "features/groups/api";
import UserPermissionsList from "features/groups/components/GroupEditView/components/UserPermissionsList/UserPermissionsList";
import { handleError } from "../../../../../../utils/networkErrorUtils";
import toastr from "components/CustomToastr/CustomToastr";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import ErrorPlaceholder from "components/ErrorPlaceholder/ErrorPlaceholder";
import useGroupUsersManager from "features/groups/hooks/useGroupUsersManager";
import { GroupUserWithMembership } from "features/groups/model/GroupUserWithMembership";

type GroupUsersContainerProps = {
    groupId: string;
};

const GroupUsersContainer = ({ groupId }: GroupUsersContainerProps) => {
    const { data: users = [], isLoading: areUsersLoading, isError: isUsersError, refetch: refetchUsers } = useGroupUsersManager(groupId);

    const [addGroupUser] = useAddGroupUserMutation();
    const [removeGroupUser] = useRemoveGroupUserMutation();

    const handleGroupUserChange = async (user: GroupUserWithMembership) => {
        const payload = { groupId: groupId, userEmail: user.username };
        const promise = user.isMember ? removeGroupUser(payload) : addGroupUser(payload);

        try {
            await promise;
            toastr.success("User permission updated");
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <LoadingPlaceholder loading={areUsersLoading} linearProgress>
            <ErrorPlaceholder error={isUsersError} message="Encountered an error while getting your data" textVariant="h5" onTryAgain={refetchUsers}>
                <UserPermissionsList users={users} userMembershipChangePromise={handleGroupUserChange} />
            </ErrorPlaceholder>
        </LoadingPlaceholder>
    );
};

export default GroupUsersContainer;
