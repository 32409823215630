import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    subHeader: {
        margin: `-${theme.customSpacing.generalSidePadding} -${theme.customSpacing.generalSidePadding} ${theme.customSpacing.generalSidePadding}`,
        width: "auto"
    },
    modalContent: {
        display: "flex",
      
        width: 1060,
        minHeight: 600,
        maxHeight: 600,
        marginBottom: `-${theme.customSpacing.generalSidePadding}`,
        overflow: "auto",
        flexDirection:'column',

        "& .search": {
            backgroundColor: "initial",
            padding: "0px 0px"
        },

        "& .scroll-container": {
            marginLeft: -20
        }
    },
    fieldsContainer: {
        margin: "22px 0"
    },
    selectField: {
        width: 168,
        marginRight: 20
    },
    rasters:{
        marginTop:16,
       overflow:'auto',
       display: "flex",
       flexDirection: 'column'
    },
    errorPopover:{
        backgroundColor: theme.customColors.errorColor,
        color:'white'
    },
    pulse: {
        boxShadow: "0 0 0 0 rgba(236, 60, 62, 1)",
        transform: "scale(1)",
        animationDelay: "10ms",
        animation: "$pulse 1s 1"
    },
    "@keyframes pulse": {
        "0%": {
            transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(236, 60, 62, 0.7)"
        },
        "70%": {
            transform: "scale(1)",
            boxShadow: `0 0 0 5px rgba(236, 60, 62,0)`
        },
        "100%": {
            transform: "scale(1)",
            boxShadow: "0 0 0 0 rgba(236, 60, 62, 0)"
        }
    }
}));
