import { Button, Divider, Grid, Tab, Tabs, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import toastr from "../../../../components/CustomToastr/CustomToastr";
import { handleError } from "../../../../utils/networkErrorUtils";
import { useStyles } from "./styles";
import GroupUsersContainer from "features/groups/components/GroupEditView/components/GroupUsersContainer/GroupUsersContainer";
import GroupDatasetsPermissionsContainer from "features/groups/components/GroupEditView/components/GroupDatasetsPermissionsContainer/GroupDatasetsPermissionsContainer";
import GroupRastersPermissionsContainer from "features/groups/components/GroupEditView/components/GroupRastersPermissionsContainer/GroupRastersPermissionsContainer";
import GroupApplicationsPermissionsContainer from "features/groups/components/GroupEditView/components/GroupApplicationsPermissionsContainer/GroupApplicationsPermissionsContainer";
import { useUpdateGroupMutation } from "features/groups/api";
import { Group } from "features/groups/model/Group";

type GroupEditViewProps = {
    group: Group;
};

const GroupEditView = ({ group }: GroupEditViewProps) => {
    const classes = useStyles();
    const [page, setPage] = useState("users");

    const [name, setName] = useState(group.name);
    const [hasNameChanged, setHasNameChanged] = useState(false);

    const [updateGroup] = useUpdateGroupMutation();

    const changePage = (e: ChangeEvent<{}>, value: string) => {
        setPage(value);
    };

    const changeGroupName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        setHasNameChanged(true);
    };

    const handleUpdateGroup = () => {
        updateGroup({ id: group.id, name: name })
            .unwrap()
            .then(() => {
                setHasNameChanged(false);
                toastr.success("Group name updated");
            })
            .catch((err) => {
                handleError(err);
            });
    };

    return (
        <div className={classes.groupEditView}>
            <div className="container">
                <div className="section">
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                placeholder="Name of the group"
                                value={name}
                                onChange={changeGroupName}
                                variant="filled"
                                label="Group Name"
                                data-testid={searchbarTestId}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!hasNameChanged}
                                onClick={handleUpdateGroup}
                                className={classes.buttonMargin}
                                data-testid={updateButtonTestId}
                            >
                                Update Name
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                <Tabs value={page} onChange={changePage} variant="fullWidth">
                    <Tab label="users" value="users" data-testid={userTabTestId} />
                    <Tab label="datasets" value="datasets" data-testid={datasetTabTestId} />
                    <Tab label="rasters" value="rasters" data-testid={rasterTabTestId} />
                    <Tab label="apps" value="apps" data-testid={appTabTestId} />
                </Tabs>
                <Divider />
                <div className="list">
                    {page === "users" && <GroupUsersContainer groupId={group.id} />}
                    {page === "datasets" && <GroupDatasetsPermissionsContainer groupId={group.id} onChangesMade={() => {}} />}
                    {page === "rasters" && <GroupRastersPermissionsContainer groupId={group.id} onChangesMade={() => {}} />}
                    {page === "apps" && <GroupApplicationsPermissionsContainer groupId={group.id} onChangesMade={() => {}} />}
                </div>
            </div>
        </div>
    );
};

export default GroupEditView;

const searchbarTestId = "qa-edit-group-modal-searchbar";
const updateButtonTestId = "qa-edit-group-modal-update-name-button";
const userTabTestId = "qa-edit-group-modal-users-tab";
const datasetTabTestId = "qa-edit-group-modal-datasets-tab";
const rasterTabTestId = "qa-edit-group-modal-rasters-tab";
const appTabTestId = "qa-edit-group-modal-apps-tab";
