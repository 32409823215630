import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    title: {
        flexGrow: 1
    },
    icon: {
        marginRight: 8
    },
    contextActions: {
        height: 80,
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        backgroundColor: theme.customColors.appBgColorLight,
        width: "100%",
        marginTop: 4
    },
    contextButton: {
        marginTop: 16,
        marginLeft: 16
    },
    tableContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        height: "100%"
    },
    searchBarWidth: {
        maxWidth: "35%"
    }
}));
