export function getTileCount(bbox, minZoom, maxZoom) {
    let specifications = generateZoomSpecification(bbox, minZoom, maxZoom);

    let count = 0;
    for (let i = 0; i < specifications.length; i++) {
        let spec = specifications[i];
        let xLength = spec.max.x + 1 - spec.min.x;
        let yLength = spec.max.y + 1 - spec.min.y;
        count += xLength * yLength;
    }

    return count;
}

export function calculateMinZoom(bbox, maxTiles, minZoom = 0, maxZoom = 18) {
    let specifications = generateZoomSpecification(bbox, minZoom, maxZoom);

    let count = 0,
        currentMinZoom = minZoom,
        currentMaxZoom = maxZoom;
    for (let i = 0; i < specifications.length; i++) {
        let spec = specifications[i];
        currentMinZoom = spec.zoom;
        let xLength = spec.max.x + 1 - spec.min.x;
        let yLength = spec.max.y + 1 - spec.min.y;
        count += xLength * yLength;
        if (count >= maxTiles) {
            break;
        }
    }

    return {
        minZoom: currentMinZoom,
        maxZoom: currentMaxZoom
    };
}

export function calculateZoomLevels(bbox, maxTiles, minZoom = 0, maxZoom = 18) {
    let specifications = generateZoomSpecification(bbox, minZoom, maxZoom);

    let count = 0,
        currentMinZoom = minZoom,
        currentMaxZoom = maxZoom;
    for (let i = 0; i < specifications.length; i++) {
        let spec = specifications[i];
        currentMinZoom = spec.zoom;
        let xLength = spec.max.x + 1 - spec.min.x;
        let yLength = spec.max.y + 1 - spec.min.y;
        count += xLength * yLength;
        if (count >= maxTiles) {
            break;
        }
    }

    return {
        minZoom: currentMinZoom,
        maxZoom: currentMaxZoom
    };
}

function generateZoomSpecification(bbox, minZoom, maxZoom) {
    let specifications = [];

    for (let zoom = minZoom; zoom <= maxZoom; zoom++) {
        specifications.push({
            max: worldToTilePos(bbox.maxLon, bbox.minLat, zoom),
            min: worldToTilePos(bbox.minLon, bbox.maxLat, zoom),
            zoom
        });
    }

    return specifications;
}

function worldToTilePos(lon, lat, zoom) {
    if (lat > 85) {
        lat = 85;
    } else if (lat < -85) {
        lat = -85;
    }

    if (lon === 180.0) {
        lon = 179.9;
    } else if (lon === -180.0) {
        lon = -179.9;
    }

    return {
        x: parseInt(((lon + 180.0) / 360.0) * (1 << zoom)),
        y: parseInt(((1.0 - Math.log(Math.tan((lat * Math.PI) / 180.0) + 1.0 / Math.cos((lat * Math.PI) / 180.0)) / Math.PI) / 2.0) * (1 << zoom))
    };
}
