import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    thumbnailImage: {
        width: "100%",
        borderRadius: 8,
        marginBottom: 8
    },

    sliderContainer: {
        padding: "0 23px 16px 23px",
        "& .slider-footer": {
            display: "flex",
            marginTop: "8px",
            justifyContent: "space-between"
        }
    },

    maxBoundsButton: {
        marginTop: 1
    }
}));
