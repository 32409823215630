import { Table, TableBody, TablePagination } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import CustomTableHeader from "./components/CustomTableHead/CustomTableHead";
import TableFilters from "./components/TableFilters/TableFilters";
import { useCustomTableStyles } from "./styles";
import clsx from "clsx";

const CustomTable = ({
    data,
    fetchingData,
    columns,
    generateItem,
    hideBottomBar = false,
    tableSize,
    additionalActionColumn = true,
    className = null,
    placePagginationToLeft = false,
    placePagginationToBottom = false,
    additionalStatusColumn = false,
    noDataText = "No data",
    errorText = undefined
}) => {
    const classes = useCustomTableStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [sorting, setSorting] = useState(columns.filter((x) => x.start)[0].name);
    const [sortingReverse, setSortingReverse] = useState(false);

    const [isBarVisible, setIsBarVisible] = useState(false);
    const bottomBarRef = React.createRef();

    const sortings = useMemo(
        () =>
            columns.reduce((a, b) => {
                a[b.name] = b.sortingFunction;
                return a;
            }, {}),
        [columns]
    );

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const bottomBar = entries[0];
            if (bottomBar.target.scrollHeight && bottomBar.target.clientHeight && bottomBar.target.scrollWidth && bottomBar.target.clientWidth)
                setIsBarVisible(bottomBar.target.scrollHeight > bottomBar.target.clientHeight && !(bottomBar.target.scrollWidth > bottomBar.target.clientWidth));
        });
        const oldRef = bottomBarRef.current;
        observer.observe(oldRef);
        return () => oldRef && observer.unobserve(oldRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBarVisible]);

    useEffect(() => {
        if (page > 0) setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.length]);

    const onSetSort = (column) => {
        setSortingReverse(sorting === column.name ? !sortingReverse : false);
        setSorting(column.name);
    };

    const onRowsPerPageChange = (e) => {
        setPage(Math.floor((page * rowsPerPage) / e.target.value));
        setRowsPerPage(e.target.value);
        bottomBarRef.current.scrollTop = 0;
    };

    const handlePageChange = (page) => {
        setPage(page);
        bottomBarRef.current.scrollTop = 0;
    };

    const sliceStart = page * rowsPerPage;
    const sliceEnd = page * rowsPerPage + rowsPerPage;

    const items = data
        .sort(sortings[sorting](sortingReverse))
        .slice(sliceStart, sliceEnd)
        .map((item, index) => generateItem(item, index));

    const pagination = (
        <TablePagination
            rowsPerPageOptions={[10, 20, 30, 100, 250]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                "aria-label": "Previous Page",
                "data-testid": backIconButtonTestId
            }}
            nextIconButtonProps={{
                "aria-label": "Next Page",
                "data-testid": nextIconButtonTestId
            }}
            SelectProps={{
                "data-testid": rowsPerPageSelectTestId
            }}
            onPageChange={(e, page) => handlePageChange(page)}
            onRowsPerPageChange={onRowsPerPageChange}
            sx={{
                marginLeft: placePagginationToLeft ? 0 : "auto",
                ...(!placePagginationToBottom && {
                    "& .MuiToolbar-root": { borderBottom: 0 }
                })
            }}
        />
    );
    return (
        <div className={clsx(className, "list-view")}>
            <div className={clsx(classes.pagination, classes.borderBottom)}>
                <TableFilters />
                {!placePagginationToBottom && pagination}
            </div>
            <div className="scroll-container" ref={bottomBarRef}>
                <Table stickyHeader size={tableSize}>
                    <CustomTableHeader
                        columns={columns}
                        sorting={sorting}
                        onSetSort={onSetSort}
                        sortingReverse={sortingReverse}
                        additionalActionColumn={additionalActionColumn}
                        additionalStatusColumn={additionalStatusColumn}
                    />
                    <TableBody>{!fetchingData && !!items.length && items}</TableBody>
                </Table>
                {!items.length && !errorText && !fetchingData && <div className={classes.textIndicator}>{noDataText}</div>}
                {!!errorText && <div className={clsx(classes.textIndicator, classes.errorText)}>{errorText}</div>}

                <LoadingPlaceholder loading={fetchingData} message={"Getting your data"} />
            </div>
            {isBarVisible && !hideBottomBar && <div className="bottom-container" />}
            {placePagginationToBottom && <div className={clsx(classes.pagination, classes.borderTop)}>{pagination}</div>}
        </div>
    );
};

CustomTable.defaultProps = {
    hideBottomBar: false,
    tableSize: "medium",
    additionalActionColumn: true,
    tableFilters: []
};

export default CustomTable;

const backIconButtonTestId = "qa-custom-table-pagination-back-icon-button";
const nextIconButtonTestId = "qa-custom-table-pagination-next-icon-button";
const rowsPerPageSelectTestId = "qa-custom-table-pagination-rows-per-page-select";
