import { sortByDate, sortByDateReverse, sortByString, sortByStringReverse } from "utils/sortingFunctions";

const sortByName = (app1: any, app2: any) => sortByString(app1.name, app2.name);
const sortByNameReverse = (app1: any, app2: any) => sortByStringReverse(app1.name, app2.name);

const sortByModified = (app1: any, app2: any) => sortByDate(new Date(app1.modifiedUtc), new Date(app2.modifiedUtc));
const sortByModifiedReverse = (app1: any, app2: any) => sortByDateReverse(new Date(app1.modifiedUtc), new Date(app2.modifiedUtc));

const appsSorting = [
    {
        type: "name",
        name: "Name",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByNameReverse : sortByName)
    },
    {
        type: "date",
        name: "Modified",
        sortingFunction: (sortingReverse: boolean) => (sortingReverse ? sortByModifiedReverse : sortByModified),
        start: true
    }
];

export default appsSorting;
