import { IconButton } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { StyledTab, StyledTabs } from "../../../components/CustomTabs/CustomTabs";
import toastr from "../../../components/CustomToastr/CustomToastr";
import * as mapActions from "../../../reducers/map";
import { getCacheStatus, getDataset, getDatasetFetching } from "../../../selectors/datasetDetails";
import usePrevious from "../../../utils/customHooks/usePrevious";
import * as NetworkErrorUtils from "../../../utils/networkErrorUtils";

import MetadataTab from "./components/MetadataTab/MetadataTab";

import { useTheme } from "@mui/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik } from "formik";
import { generateCache, getDatasetColumns, getDatasetDetails, updateDataset, updateDatasetAdvanced, updateDatasetColumns } from "../../../actions/datasets";
import { getMetadataSchemaThunk } from "../../../actions/metadataSchema";
import OverflowTip from "../../../components/OverflowTip/OverflowTip";
import { metadataTypes, METADATA_SCHEMA_ID } from "../../../utils/constants/metadata";
import * as EnumUtils from "../../../utils/enumUtils";
import keyboardBackspace from "../../../utils/icons/keyboard-backspace.svg";
import { DatasetEditViewSchema } from "../../../utils/validators/dataset";
import AdvancedTab from "./components/AdvancedTab/AdvancedTab";
import UploaderTab from "./components/UploaderTab/UploaderTab";
import MenuAndModals from "./components/MenuAndModals/MenuAndModals";
import { useDatasetEditViewStyles } from "./styles";
import { getCurrentUserRole } from "features/auth/selectors";
import { ADMIN_ROLE, UPLOADER_ROLE } from "utils/accountUtils";
import { getFeatureFlags } from "features/featureFlags/selectors";
import DatasetEditFooter from "./components/DatasetEditFooter/DatasetEditFooter";

import { makeSourceFromDataset } from "../../../utils/creators/SourceCreators";
import CacheStatusChip from "../../../components/CacheStatusChip/CacheStatusChip";
import { PermissionType } from "features/groups/model/PermissionType";

import Header from "components/header/Header";
import MapTools from "../../../components/map/mapTools/MapTools";
import ZoomLevelWidget from "../../../components/map/mapTools/ZoomLevelWidget/ZoomLevelWidget";
import HeaderButtons from "../../../components/HeaderButtons/HeaderButtons";
import { getUnseenNotifications } from "../../../selectors/notifications";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Map from "../../../components/map/mapComponent";
import DetailsTab from "./components/DetailsTab/DetailsTab";

import { IsResourceModified } from "utils/localStorageUtils";
import { CACHE_STATUSES } from "utils/constants/cacheStates";
import InfoTable from "./components/attributes/InfoTable";
import DprTab from "./components/DprTab/DprTab";

export const DETAILS_TAB = "details";
export const METADATA_TAB = "metadata";
export const ADVANCED_TAB = "advanced";
export const UPLOADER_TAB = "uploader";
export const DPR_TAB = "dpr";

const DatasetEditView = () => {
    const classes = useDatasetEditViewStyles();
    const theme = useTheme();

    const [columns, setColumns] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [basicDataChanged, setBasicDataChanged] = useState(false);
    const [tileDataChanged, setTileDataChanged] = useState(false);
    const [columnsDataChanged, setColumnsDataChanged] = useState(false);

    const [permissionType, setPermissionType] = useState(0);

    const [actionsAnchor, setActionsAnchor] = useState(null);

    const [bbox, setBbox] = useState();

    const [datasetLoaded, setDatasetLoaded] = useState(false);

    const dispatch = useDispatch();
    const { datasetId } = useParams();

    const dataset = useSelector(getDataset);
    const cacheStatus = useSelector(getCacheStatus);
    const fetching = useSelector(getDatasetFetching);
    const userRole = useSelector(getCurrentUserRole);
    const features = useSelector(getFeatureFlags);

    const history = useHistory();
    const prevLocation = history.location.state?.prevLocation;
    const initialTab = history.location.state?.tab;
    const [tab, setTab] = useState(initialTab ?? DETAILS_TAB);

    const previousCacheStatus = usePrevious(cacheStatus);

    const { clientId, projectId } = useParams();

    const unseenNotifications = useSelector(getUnseenNotifications);

    useEffect(() => {
        const datasetPromise = dispatch(getDatasetDetails(datasetId));
        const columnsPromise = dispatch(getDatasetColumns(datasetId));
        const metadataSchemaPromise = userRole === UPLOADER_ROLE ? new Promise((resolve, _) => resolve(null)) : dispatch(getMetadataSchemaThunk(1)).then(unwrapResult);

        Promise.all([datasetPromise, columnsPromise, metadataSchemaPromise]).then(
            (res) => {
                let datasetResult = res[0].result;
                let columnsResult = res[1].result;
                let metadataSchemaResult = res[2]?.data;

                !!metadataSchemaResult && initMetadata(datasetResult.metadata, metadataSchemaResult);
                init(datasetResult, columnsResult);

                setPermissionType(datasetResult.permissionType);
            },
            (err) => {
                history.push(`/${clientId}/${projectId}/datasets`);
                NetworkErrorUtils.handleError(err);
            }
        );

        return () => {
            dispatch(mapActions.removeMapLayer(datasetId));
            dispatch(mapActions.removeMapSource(datasetId));
            dispatch(mapActions.resetMapFlags());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cacheStatus === CACHE_STATUSES.cached && previousCacheStatus === CACHE_STATUSES.caching) refreshMapLayer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cacheStatus]);

    const getLayerType = (type) => {
        switch (type) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return "fill";
            case "POINT":
            case "MULTIPOINT":
                return "circle";
            case "LINESTRING":
            case "MULTILINESTRING":
                return "line";
            default:
                return "circle";
        }
    };

    const init = (paramDataset, paramColumns) => {
        let layerType = getLayerType(paramDataset.geometryType);

        let bbox = {
            minLon: paramDataset.bounds.coordinates[0][0][0],
            minLat: paramDataset.bounds.coordinates[0][0][1],
            maxLon: paramDataset.bounds.coordinates[0][2][0],
            maxLat: paramDataset.bounds.coordinates[0][2][1]
        };

        setColumns(paramColumns);
        setBbox(bbox);

        loadMapLayer(paramDataset);
        setDatasetLoaded(true);

        dispatch(
            mapActions.fitMapBounds({
                bounds: [paramDataset.bounds.coordinates[0][0], paramDataset.bounds.coordinates[0][2]],
                options: {
                    padding: { top: 45, bottom: 45, left: 45, right: 45 },
                    animate: false
                }
            })
        );
    };

    const initMetadata = (datasetMetadata, metadataSchema) => {
        const datasetMetadataMap = datasetMetadata.reduce((acc, property) => {
            acc[property.id] = property;

            return acc;
        }, {});

        const metadataSchemaMap = metadataSchema.reduce((acc, property) => {
            acc[property.id] = property;

            return acc;
        }, {});

        const datasetMetadataTagMap = datasetMetadata.reduce((acc, property) => {
            if (!(metadataSchemaMap[property.id].type === metadataTypes.TAG_LIST)) {
                return acc;
            }

            property.value.forEach((tag) => {
                acc[tag.id] = tag;
            });

            return acc;
        }, {});

        metadataSchema = metadataSchema.map((property) => {
            //if value is of type TAG_LIST,
            //the value will be an array of tags
            const value =
                property.type === metadataTypes.TAG_LIST
                    ? property.value.map((val) => ({ ...val, enabled: datasetMetadataTagMap[val.id].enabled }))
                    : datasetMetadataMap[property.id].value;

            return { ...property, value };
        });
        setMetadata(metadataSchema);
    };

    const refreshMapLayer = () => {
        caches.delete(dataset.id).then((X) => {
            localStorage.setItem(dataset.id, dataset.modifiedUtc);

            let layerType = getLayerType(dataset.geometryType);

            dispatch(mapActions.addMapSource(makeSourceFromDataset(dataset)));
            dispatch(
                mapActions.addMapLayer({
                    sourceId: dataset.id,
                    layerId: dataset.id,
                    sourceName: dataset.tileName,
                    type: layerType,
                    sourceMinZoom: dataset.minZoom,
                    sourceMaxZoom: dataset.maxZoom,
                    minZoom: dataset.minZoom,
                    maxZoom: 24
                })
            );
            dispatch(
                mapActions.addMapPaint({
                    layerId: dataset.id,
                    properties: [
                        {
                            name: layerType + "-color",
                            value: theme.customColors.primaryColor
                        }
                    ]
                })
            );
        });
    };

    const loadMapLayer = (dataset) => {
        const modifiedDate = new Date(dataset.modifiedUtc);

        if (IsResourceModified(dataset.id, modifiedDate)) {
            caches.delete(dataset.id).then((x) => {
                localStorage.setItem(dataset.id, dataset.modifiedUtc);
                addLayerAndSource(dataset);
            });
        } else {
            addLayerAndSource(dataset);
        }
    };

    const addLayerAndSource = (dataset) => {
        let layerType = getLayerType(dataset.geometryType);
        dispatch(mapActions.addMapSource({ ...makeSourceFromDataset(dataset), cacheStatus: CACHE_STATUSES.cached }));
        dispatch(
            mapActions.addMapLayer({
                sourceId: dataset.id,
                layerId: dataset.id,
                sourceName: dataset.tileName,
                type: layerType,
                sourceMinZoom: dataset.minZoom,
                sourceMaxZoom: dataset.maxZoom,
                minZoom: dataset.minZoom,
                maxZoom: 24
            })
        );
        dispatch(
            mapActions.addMapPaint({
                layerId: dataset.id,
                properties: [
                    {
                        name: layerType + "-color",
                        value: theme.customColors.primaryColor
                    }
                ]
            })
        );
    };

    const changePage = (page) => {
        setTab(page);
    };

    const onOpenActions = (e) => {
        setActionsAnchor(e.currentTarget);
    };

    const onCloseActions = () => {
        setActionsAnchor(null);
    };

    const cacheStatusToClass = (cacheStatus) => {
        return EnumUtils.toCacheStatusString(cacheStatus);
    };

    const onBack = () => {
        if (prevLocation) {
            history.push(prevLocation);
        } else {
            history.push(`/${clientId}/${projectId}/datasets`);
        }
    };

    const cleanupMetadata = (metadata) => {
        return metadata.map((m) => {
            let value = m.value;

            if (m.type === metadataTypes.TAG_LIST) {
                value = value.map((tag) => {
                    return {
                        id: tag.id,
                        enabled: !!tag.enabled
                    };
                });
            }
            if (m.type === metadataTypes.DATE) {
                value = value?.toString() || null;
            }
            return {
                id: m.id,
                value
            };
        });
    };

    const basicChangesPromise = ({ name, metadata }) => {
        return dispatch(updateDataset(dataset.id, METADATA_SCHEMA_ID, { name, metadata: cleanupMetadata(metadata) })).then(() => {
            setMetadata(metadata);
        });
    };

    const tileChangesPromise = ({ minZoom, maxZoom }) => {
        return dispatch(
            updateDatasetAdvanced(dataset.id, {
                minZoom,
                maxZoom
            })
        );
    };

    const onDatasetSave = ({ columns, datasetName, minZoom, maxZoom, ...metadataValues }) => {
        const updatedMetadata = metadata.map((m) => {
            let value = metadataValues[m.name];
            if (m.type === metadataTypes.DATE) {
                value = value?.toString() || null;
            }
            return {
                ...m,
                value
            };
        });

        const promises = [];

        if (basicDataChanged) {
            const datasetInfoPromise = basicChangesPromise({
                name: datasetName,
                metadata: updatedMetadata
            });
            promises.push(datasetInfoPromise);
        }
        if (tileDataChanged) {
            const datasetTilePromise = tileChangesPromise({ minZoom, maxZoom });
            promises.push(datasetTilePromise);
        }
        if (columnsDataChanged) {
            const columnsPromise = dispatch(updateDatasetColumns(dataset.id, columns));
            promises.push(columnsPromise);
        }

        Promise.all(promises)
            .then(() => {
                if (tileDataChanged || columnsDataChanged) {
                    dispatch(generateCache(dataset.id));
                }

                toastr.success(`${dataset.name} has been successfully saved`);
                setBasicDataChanged(false);
                setTileDataChanged(false);
                setColumnsDataChanged(false);
            })
            .catch((err) => NetworkErrorUtils.handleError(err));
    };

    const getMetadataInitialValue = (metadata) => {
        if (metadata.type === metadataTypes.DATE) return metadata.value ? new Date(metadata.value) : null;
        return metadata.value;
    };

    const saveButtonTooltip = (errors) => {
        if (!basicDataChanged && !columnsDataChanged && !tileDataChanged) return "No changes were made";
        const { columns, datasetName, minZoom, maxZoom, ...metadata } = errors;

        let errorText = "Before saving, correct the errors on the following tabs:";
        const tabsWithProblems = [];
        if (Object.keys(errors).length) {
            if (errors.datasetName) {
                tabsWithProblems.push("Details");
            }

            if (Object.keys(metadata).length) tabsWithProblems.push("Metadata");

            if (minZoom || maxZoom || columns) tabsWithProblems.push("Advanced");
            return (
                <div>
                    {errorText}
                    {tabsWithProblems.map((tab) => (
                        <div key={tab}>- {tab}</div>
                    ))}
                </div>
            );
        }
        return "";
    };

    const [showDataView, setShowDataView] = useState(false);

    const onToggleDataView = () => {
        setShowDataView(!showDataView);
    };

    const renderTableSwitcher = () =>
        datasetId && (
            <div className="map-data-switch">
                <Typography color="inherit" variant="subtitle1">
                    Map
                </Typography>
                <Switch
                    checked={showDataView}
                    onChange={onToggleDataView}
                    value="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox", "data-testid": mapDataSwitchTestId }}
                />
                <Typography color="inherit" variant="subtitle1">
                    Data
                </Typography>
            </div>
        );

    return (
        <div className="dataset-details-container">
            <div className="sidebar">
                <div className="header">
                    <Header />
                    <HeaderButtons unseenNotifications={unseenNotifications} userMenuIsCollapsed={true} />
                </div>
                <div className="sidebar-container dataset-details">
                    <div className="header">
                        <div className={classes.stylerHeader}>
                            <IconButton className={classes.backButton} onClick={onBack} size="large" data-testid={backButtonTestId}>
                                <img alt="" src={keyboardBackspace} />
                            </IconButton>
                            <OverflowTip variant="h2">{dataset.name}</OverflowTip>
                        </div>
                        <CacheStatusChip status={cacheStatusToClass(cacheStatus)} expanded />
                    </div>
                    {fetching && <LinearProgress className="no-margin-progress" />}
                    <StyledTabs value={tab} TabIndicatorProps={<div />}>
                        <StyledTab label={DETAILS_TAB} onClick={() => changePage(DETAILS_TAB)} value={DETAILS_TAB} data-testid={detailsTabTestId} />
                        {userRole !== UPLOADER_ROLE && (
                            <StyledTab
                                label={METADATA_TAB}
                                onClick={() => changePage(METADATA_TAB)}
                                value={METADATA_TAB}
                                data-testid={metadataTabTestId}
                                disabled={dataset.permissionType < PermissionType.WRITE_READ}
                            />
                        )}
                        {userRole !== UPLOADER_ROLE && (
                            <StyledTab
                                label={ADVANCED_TAB}
                                onClick={() => changePage(ADVANCED_TAB)}
                                value={ADVANCED_TAB}
                                data-testid={advancedTabTestId}
                                disabled={dataset.permissionType < PermissionType.OWNER}
                            />
                        )}
                        {features.FIDU && (
                            <StyledTab
                                label={UPLOADER_TAB}
                                onClick={() => changePage(UPLOADER_TAB)}
                                value={UPLOADER_TAB}
                                data-testid={uploaderTabTestId}
                                disabled={dataset.permissionType < PermissionType.WRITE_READ}
                            />
                        )}
                        {features.DPR && userRole === ADMIN_ROLE && (
                            <StyledTab
                                label={"DPR"}
                                onClick={() => changePage(DPR_TAB)}
                                value={DPR_TAB}
                                data-testid={dprTabTestId}
                                disabled={dataset.permissionType < PermissionType.WRITE_READ}
                            />
                        )}
                    </StyledTabs>
                    <Formik
                        enableReinitialize={!datasetLoaded}
                        initialValues={{
                            columns,
                            datasetName: dataset.name,
                            minZoom: dataset.minZoom,
                            maxZoom: dataset.maxZoom,
                            ...metadata.reduce((acc, metadata) => {
                                acc[metadata.name] = getMetadataInitialValue(metadata);
                                return acc;
                            }, {})
                        }}
                        onSubmit={(values) => {
                            if (columnsDataChanged || tileDataChanged) {
                                const toastrConfirmOptions = {
                                    onOk: () => onDatasetSave(values),
                                    onCancel: () => {}
                                };
                                toastr.confirm("The changes will require tile regeneration in order tot take effect.", toastrConfirmOptions);
                            } else {
                                onDatasetSave(values);
                            }
                        }}
                        validationSchema={DatasetEditViewSchema(metadata)}
                    >
                        {({ submitForm, errors }) => (
                            <>
                                {tab === DETAILS_TAB && <DetailsTab dataset={dataset} setBasicDataChanged={setBasicDataChanged} />}
                                {tab === METADATA_TAB && userRole !== UPLOADER_ROLE && <MetadataTab metadata={metadata} setBasicDataChanged={setBasicDataChanged} />}
                                {tab === ADVANCED_TAB && userRole !== UPLOADER_ROLE && (
                                    <AdvancedTab bbox={bbox} setTileDataChanged={setTileDataChanged} setColumnsDataChanged={setColumnsDataChanged} tileName={dataset.tileName} />
                                )}
                                {tab === UPLOADER_TAB && features.FIDU && <UploaderTab dataset={dataset} />}
                                {tab === DPR_TAB && features.DPR && userRole === ADMIN_ROLE && <DprTab />}

                                <DatasetEditFooter
                                    saveButtonTitle={saveButtonTooltip(errors)}
                                    saveButtonDisabled={
                                        !!Object.keys(errors).length ||
                                        (!basicDataChanged && !columnsDataChanged && !tileDataChanged) ||
                                        dataset.permissionType < PermissionType.WRITE_READ
                                    }
                                    onSaveButtonClick={submitForm}
                                    onActionsButtonClick={onOpenActions}
                                    openedTab={tab}
                                />
                            </>
                        )}
                    </Formik>
                    {userRole !== UPLOADER_ROLE && (
                        <MenuAndModals dataset={dataset} columns={columns} actionsAnchor={actionsAnchor} setActionsAnchor={setActionsAnchor} onCloseActions={onCloseActions} />
                    )}
                </div>
            </div>

            <div className="map container">
                <Map>
                    <MapTools direction="column" alignItems="flex-end">
                        <ZoomLevelWidget />
                        {userRole !== UPLOADER_ROLE && permissionType >= PermissionType.READ && renderTableSwitcher()}
                    </MapTools>
                </Map>
                <InfoTable open={showDataView} datasetId={datasetId} />
            </div>
        </div>
    );
};

export default DatasetEditView;

const backButtonTestId = "qa-dataset-edit-view-back-button";
const detailsTabTestId = "qa-dataset-edit-view-details-tab";
const metadataTabTestId = "qa-dataset-edit-view-metadata-tab";
const advancedTabTestId = "qa-dataset-edit-view-advanced-tab";
const uploaderTabTestId = "qa-dataset-edit-view-uploader-tab";
const dprTabTestId = "qa-dataset-edit-view-dpr-tab";
const mapDataSwitchTestId = "qa-dataset-edit-view-map-data-switch";
