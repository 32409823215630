import { isGroup } from "@emblautec/rescursive-array-extensions";

const findDepth = (elements) => {
    let deepest = 0;
    for (let elem of elements) {
        if (isGroup(elem)) {
            deepest = Math.max(deepest, findDepth(elem.layers));
        }
    }
    return deepest + 1;
};

export default findDepth;
