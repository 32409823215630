import { LinearProgress, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import clsx from "clsx";
import useUploadProgressStyles from "./styles";

type Props = {
    error?: boolean;
    errorMessage?: string;
    progress: number;
    isLoading: boolean;
};

const UploadProgress = ({ error, errorMessage, progress, isLoading }: Props) => {
    const classes = useUploadProgressStyles();
    const progressStyle = clsx(classes.progress, classes.flexGrow, { [classes.error]: error });
    const isProcessing = isLoading && progress === 100;
    const isUploadSuccessful = progress === 100 && !error && !isProcessing;
    const shouldShowProgress = progress < 100 && progress > 0 && !error;
    const statusStyle = clsx(classes.status, { [classes.errorStatus]: error });

    return (
        <div className={progressStyle}>
            <LinearProgress className={clsx(classes.progressBar, classes.flexGrow)} variant="determinate" value={progress} />
            {shouldShowProgress && (
                <Typography variant="body2" className={statusStyle}>
                    {progress}%
                </Typography>
            )}

            {isProcessing && (
                <Typography variant="body2" className={statusStyle}>
                    Processing...
                </Typography>
            )}

            {isUploadSuccessful && (
                <Typography variant="body2" className={statusStyle}>
                    <DoneIcon fontSize="small" />
                    Uploaded
                </Typography>
            )}

            {error && (
                <Typography variant="body2" className={statusStyle}>
                    {errorMessage}
                </Typography>
            )}
        </div>
    );
};

export default UploadProgress;
